import * as GQTypes from '@watershed/shared-universal/generated/graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const ParquetDataFieldsFragmentDoc = gql`
    fragment ParquetDataFields on ParquetData {
  id
  numRecords
  ndjsonSignedUrl
  ndjsonSize
  ndjsonByteBoundaries
  columns {
    name
    sqlType
    minValue
    maxValue
    numNulls
    approxDistinctValues
    sampleValues
  }
}
    `;
export const ActivityDataExtractorFieldsFragmentDoc = gql`
    fragment ActivityDataExtractorFields on ActivityDataExtractor {
  id
  createdAt
  kind
  user {
    id
    name
  }
  sql
  userUploadedTables {
    id
    parentFileMetadata {
      id
      name
      category
    }
  }
  warnings
  error
  published
  userVisible
  generated
  runSqlOutput {
    ...ParquetDataFields
  }
  transformedOutput {
    ...ParquetDataFields
  }
  cleanAdtOutput {
    ...ParquetDataFields
  }
  duckdbVersion
  bartActivityType
  generated
  facilitiesSurveyStatus
}
    ${ParquetDataFieldsFragmentDoc}`;
export const PermissionFieldsFragmentDoc = gql`
    fragment PermissionFields on PermissionItem {
  id
  objectId
  permission
  object {
    __typename
    id
    name
  }
  revokedAt
}
    `;
export const RoleInfoFragmentDoc = gql`
    fragment RoleInfo on Role {
  id
  name
  description
  permissions {
    ...PermissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;
export const UserRoleFieldsFragmentDoc = gql`
    fragment UserRoleFields on UserRoleAssignment {
  id
  revokedAt
  role {
    id
    ...RoleInfo
  }
}
    ${RoleInfoFragmentDoc}`;
export const AssigneeForEditDataIssuesPageFieldsFragmentDoc = gql`
    fragment AssigneeForEditDataIssuesPageFields on User {
  id
  name
  permissions(orgId: $orgId, includeDeletedOrgAccess: true) {
    ...PermissionFields
  }
  roles(orgId: $orgId, includeDeletedOrgAccess: true) {
    ...UserRoleFields
  }
}
    ${PermissionFieldsFragmentDoc}
${UserRoleFieldsFragmentDoc}`;
export const UserUploadTaskForAssigneePermissionCheckFragmentDoc = gql`
    fragment UserUploadTaskForAssigneePermissionCheck on UserUploadTask {
  id
  assignee {
    ...AssigneeForEditDataIssuesPageFields
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
}
    ${AssigneeForEditDataIssuesPageFieldsFragmentDoc}`;
export const CommentForCommentDisplayFieldsFragmentDoc = gql`
    fragment CommentForCommentDisplayFields on Comment {
  id
  person {
    id
    name
    isWatershedEmployee
    isWatershedContractor
  }
  message
  createdAt
  isPublished
}
    `;
export const DataIssueLinkedObjectFieldsFragmentDoc = gql`
    fragment DataIssueLinkedObjectFields on DataIssueLinkedObject {
  __typename
  ... on UserUploadTask {
    ...UserUploadTaskForAssigneePermissionCheck
  }
  ... on MeasurementVerificationItemQuestion {
    id
    item {
      id
      datasetName
    }
  }
  ... on FinancialsReviewItem {
    id
  }
  ... on ValueMappingTask {
    id
  }
}
    ${UserUploadTaskForAssigneePermissionCheckFragmentDoc}`;
export const ContextRelationFieldsFragmentDoc = gql`
    fragment ContextRelationFields on ContextRelationInterface {
  __typename
  id
  name
  adminUrl
}
    `;
export const ContextSeedObjectFieldsFragmentDoc = gql`
    fragment ContextSeedObjectFields on ContextRelationInterface {
  ...ContextRelationFields
  ancestorRelations {
    ...ContextRelationFields
  }
}
    ${ContextRelationFieldsFragmentDoc}`;
export const DataIssueForEditDataIssuesPageFieldsFragmentDoc = gql`
    fragment DataIssueForEditDataIssuesPageFields on DataIssue {
  __typename
  id
  title
  description
  state
  isPublished
  isCustomerInitiated
  createdAt
  tags
  assignee {
    ...AssigneeForEditDataIssuesPageFields
  }
  comments {
    edges {
      node {
        ...CommentForCommentDisplayFields
      }
    }
  }
  files {
    edges {
      node {
        id
      }
    }
  }
  linkedObject {
    ...DataIssueLinkedObjectFields
  }
  ...ContextSeedObjectFields
}
    ${AssigneeForEditDataIssuesPageFieldsFragmentDoc}
${CommentForCommentDisplayFieldsFragmentDoc}
${DataIssueLinkedObjectFieldsFragmentDoc}
${ContextSeedObjectFieldsFragmentDoc}`;
export const AdtEditorDatasourceFragmentDoc = gql`
    fragment AdtEditorDatasource on Datasource {
  id
  name
  dataset {
    id
    name
  }
  userUploadTasks {
    edges {
      node {
        ...UserUploadTaskForAssigneePermissionCheck
        issues {
          edges {
            node {
              ...DataIssueForEditDataIssuesPageFields
            }
          }
        }
        userUploads(categories: [User, BuildingSurvey, Integration]) {
          edges {
            node {
              id
              allAttempts {
                id
                description
                userUploadedTables {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${UserUploadTaskForAssigneePermissionCheckFragmentDoc}
${DataIssueForEditDataIssuesPageFieldsFragmentDoc}`;
export const ActivityDataTableFieldsForEditorFragmentDoc = gql`
    fragment ActivityDataTableFieldsForEditor on ActivityDataTable {
  id
  name
  filterInterval
  publishedExtractor {
    ...ActivityDataExtractorFields
  }
  draftBartExtractor {
    ...ActivityDataExtractorFields
  }
  draftCadtExtractor {
    ...ActivityDataExtractorFields
  }
  draftScratchpadExtractor {
    ...ActivityDataExtractorFields
  }
  latestBartActivityType
  datasources {
    ...AdtEditorDatasource
  }
  datasets {
    id
    name
  }
  primaryDatasource {
    ...AdtEditorDatasource
  }
  recommendedActivityTypes
  connectedToAutoBart
  autoBartKind
  autoBartActivityType
  draftAutoBartHasPreviousCustomExtractor
  reviews {
    id
    reviewer {
      id
      name
    }
  }
  measurementProject {
    id
  }
  ...ContextSeedObjectFields
}
    ${ActivityDataExtractorFieldsFragmentDoc}
${AdtEditorDatasourceFragmentDoc}
${ContextSeedObjectFieldsFragmentDoc}`;
export const OrganizationForAdtEditorFragmentDoc = gql`
    fragment OrganizationForAdtEditor on Organization {
  id
  name
  demoOrg
  testOrg
}
    `;
export const UserUploadedTablesByIdFieldsFragmentDoc = gql`
    fragment UserUploadedTablesByIdFields on UserUploadedTable {
  id
  sheetName
  sqlTableName
  shouldIgnore
  numRows
  gcloudStorageUrl
  sourceFileDeleted
  userReviewMetadata {
    columnMappings {
      watershedColumnName
      sourceFileColumnName
    }
    currencyOverride {
      selectedCurrency
    }
    buildingAreaUnitOverride
  }
  userIngestionReview {
    id
    ingestionSchemaId
  }
  parentFileMetadata {
    id
    name
    notesForWatershed: description
    category
  }
}
    `;
export const BuildingSurveyStatusForAdtEditorFragmentDoc = gql`
    fragment BuildingSurveyStatusForAdtEditor on BuildingSurveyStatus {
  latestSnapshotUut {
    id
  }
  latestSnapshotUpToDate
  latestSnapshotIsEmptyFile
  numChangedBuildingsSinceUutGenerated
  measurementProjectIntervalMatches
  numAddedBuildingsSinceUutGenerated
  numDeletedBuildingsSinceUutGenerated
  bartActivityType
  numOmittedPartialBuildings
  latestSnapshotIsEmptyFile
}
    `;
export const FootprintPinForDialogFragmentDoc = gql`
    fragment FootprintPinForDialog on FootprintVersion {
  id
  pinInfo {
    id
    name
    description
  }
}
    `;
export const AddOrgPointOfContactDialog_ExistingOrgPointsOfContactFragmentDoc = gql`
    fragment AddOrgPointOfContactDialog_ExistingOrgPointsOfContact on OrgPointOfContact {
  id
  watershedEmployee {
    id
  }
}
    `;
export const OrgFieldsForAdminNavFragmentDoc = gql`
    fragment OrgFieldsForAdminNav on Organization {
  id
  name
  hasUsers
  demoOrg
  testOrg
  watershedPlanLegacy
}
    `;
export const UserUploadFieldsForActionsFragmentDoc = gql`
    fragment UserUploadFieldsForActions on UserUpload {
  id
  name
  latestAttempt {
    id
    userUploadedTables {
      id
      shouldIgnore
    }
  }
}
    `;
export const AddSupplierPointOfContactDialog_MarketplacePointOfContactFragmentDoc = gql`
    fragment AddSupplierPointOfContactDialog_MarketplacePointOfContact on MarketplacePointOfContact {
  id
  name
  email
}
    `;
export const MarketplacePurchaseFieldsForDocumentsTableCardFragmentDoc = gql`
    fragment MarketplacePurchaseFieldsForDocumentsTableCard on MarketplacePurchase {
  id
  org {
    id
  }
  lineItems {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const MarketplacePurchaseLineItemFieldsForDocumentsTableCardFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFieldsForDocumentsTableCard on MarketplacePurchaseLineItem {
  id
  name
  org {
    id
  }
}
    `;
export const MarketplaceProjectArchetypesForLineItemDialogFragmentDoc = gql`
    fragment MarketplaceProjectArchetypesForLineItemDialog on MarketplaceProjectArchetype {
  id
  name
  mechanism
  unit
}
    `;
export const MarketplacePortfolioItemForLineItemDialogFragmentDoc = gql`
    fragment MarketplacePortfolioItemForLineItemDialog on MarketplacePortfolioItem {
  id
  amount
  priceUsdCents
  archetype {
    id
  }
  portfolio {
    id
    interval
  }
}
    `;
export const MeasurementProjectsForMeasurementProjectSelectorFragmentDoc = gql`
    fragment MeasurementProjectsForMeasurementProjectSelector on MeasurementProject {
  id
  name
  coverageInterval
  active
}
    `;
export const FileMetadataFieldsForOrgFilesDialogFragmentDoc = gql`
    fragment FileMetadataFieldsForOrgFilesDialog on FileMetadata {
  id
  name
  description
  directory
  size
  createdAt
  gcloudStorageUrl
  revisionRoot
  deletedAt
  uploader {
    id
    name
    email
  }
}
    `;
export const PipelineResultForCreateFootprintSnapshotDialogFragmentDoc = gql`
    fragment PipelineResultForCreateFootprintSnapshotDialog on PipelineResult {
  id
  name
  pipelineKind
  orgId
  createdAt
  coverageInterval
  runUserId
  pipelineRunConfig
  deletedAt
  summary {
    format
    emissions {
      month
      kgCo2e
      businessCategory
      adtName
      footprintKind
    }
    warnings {
      blocking
      message
    }
  }
  isUsedInLatestDraft
  isUsedInLatestPublish
}
    `;
export const PipelineRunForCreateFootprintDialogFragmentDoc = gql`
    fragment PipelineRunForCreateFootprintDialog on PipelineRun {
  id
  name
  createdAt
  pipelineResults {
    ...PipelineResultForCreateFootprintSnapshotDialog
  }
}
    ${PipelineResultForCreateFootprintSnapshotDialogFragmentDoc}`;
export const FootprintForCreateFootprintSnapshotDialogFragmentDoc = gql`
    fragment FootprintForCreateFootprintSnapshotDialog on Footprint {
  id
  name
}
    `;
export const AdtFieldsForRunPipelineDialogFragmentDoc = gql`
    fragment AdtFieldsForRunPipelineDialog on ActivityDataTable {
  id
  lastRefreshedAt
  name
  publishedExtractor {
    id
    createdAt
    kind
    userVisible
    facilitiesSurveyStatus
    bartId
  }
  draftCadtExtractor {
    id
    createdAt
  }
  draftBartExtractor {
    id
    createdAt
  }
  latestBartActivityType
  filterInterval
  stage
}
    `;
export const GetFootprintSnapshotDetailsSnapshotFragmentDoc = gql`
    fragment GetFootprintSnapshotDetailsSnapshot on FootprintSnapshot {
  id
  publishedAt
  publishedHiddenAt
  publishedDraftAt
  summary {
    emissions {
      month
      businessCategory
      pipeline
      kgCo2e
      footprintKind
    }
  }
  marketplaceAllocationInstructionIds
  tags {
    id
    tagName
  }
  categoryRules {
    id
  }
  exclusionRules {
    id
  }
}
    `;
export const DataIssueForOrgInboxFieldsFragmentDoc = gql`
    fragment DataIssueForOrgInboxFields on DataIssue {
  ...DataIssueForEditDataIssuesPageFields
  createdAt
  ...ContextSeedObjectFields
}
    ${DataIssueForEditDataIssuesPageFieldsFragmentDoc}
${ContextSeedObjectFieldsFragmentDoc}`;
export const InboxItemFieldsFragmentDoc = gql`
    fragment InboxItemFields on OrganizationInboxItem {
  __typename
  ... on DataIssue {
    ...DataIssueForOrgInboxFields
  }
  ... on UserUploadTask {
    id
    createdAt
    datasource {
      id
      name
    }
    measurementProject {
      id
      name
    }
    taskState: state
    userUploads(excludeProcessingModes: [IdiRawFile, NoProcessing]) {
      edges {
        node {
          id
          name
          status
          processingMode
          userVisibleAttempt {
            id
            createdAt
            gcloudStorageUrl
            size
            userUploadedTables {
              id
            }
          }
          latestAttempt {
            id
            userUploadedTables {
              id
              shouldIgnore
            }
          }
        }
      }
    }
  }
}
    ${DataIssueForOrgInboxFieldsFragmentDoc}`;
export const UserUploadedTableDataPreviewFieldsFragmentDoc = gql`
    fragment UserUploadedTableDataPreviewFields on UserUploadedTable {
  id
  sqlTableName
  shouldIgnore
  parseConfig {
    noHeader
    skipTopNRows
    skipBottomNRows
    meltIdVars
  }
  dataPreview {
    schema {
      fields {
        name
        type
      }
    }
    dataUntyped
  }
}
    `;
export const SerializableErrorFieldsFragmentDoc = gql`
    fragment SerializableErrorFields on SerializableError {
  message
  code
  stackTrace
  errorType
  details
}
    `;
export const PlaygroundSqlRunForPlaygroundFragmentDoc = gql`
    fragment PlaygroundSqlRunForPlayground on PlaygroundSqlRun {
  id
  createdAt
  state
  user {
    id
    name
  }
  error
  jobError {
    ...SerializableErrorFields
  }
  sql
  output {
    ...ParquetDataFields
  }
}
    ${SerializableErrorFieldsFragmentDoc}
${ParquetDataFieldsFragmentDoc}`;
export const ReferenceDataCitationFieldsFragmentDoc = gql`
    fragment ReferenceDataCitationFields on ReferenceDataCitation {
  id
  displayName
  slug
  internalSourceUrl
  internalSourceNotes
  externalSourceUrl
  externalSourceNotes
  hasLicenseRestrictions
  publicDisclosureId
  privateDisclosureId
  isCustomerProvided
  isSupplierSpecific
  createdAt
  updatedAt
  archivedAt
  isArchived
  orgId
  author {
    id
    name
  }
  org {
    id
    name
  }
}
    `;
export const DatasourceForShareFilesFragmentDoc = gql`
    fragment DatasourceForShareFiles on Datasource {
  id
  name
  dataset {
    id
    name
  }
}
    `;
export const UserUploadForShareFilesFragmentDoc = gql`
    fragment UserUploadForShareFiles on UserUpload {
  id
  name
  deletedAt
}
    `;
export const MeasurementProjectForShareFilesFragmentDoc = gql`
    fragment MeasurementProjectForShareFiles on MeasurementProject {
  id
  name
  coverageStartDate
  userUploadTasks {
    edges {
      node {
        id
        datasource {
          ...DatasourceForShareFiles
        }
        userUploads(includeDeleted: true) {
          edges {
            node {
              ...UserUploadForShareFiles
            }
          }
        }
      }
    }
  }
}
    ${DatasourceForShareFilesFragmentDoc}
${UserUploadForShareFilesFragmentDoc}`;
export const SupplierColumnFieldsAdminFragmentDoc = gql`
    fragment SupplierColumnFieldsAdmin on SupplierTableColumn {
  id
  field
  headerName
}
    `;
export const TargetForTargetsFormFragmentDoc = gql`
    fragment TargetForTargetsForm on Target {
  id
  title
  descriptor
  kind
  unit
  targetAmount
}
    `;
export const OrganizationForTargetsFormFragmentDoc = gql`
    fragment OrganizationForTargetsForm on Organization {
  id
  targets {
    edges {
      node {
        ...TargetForTargetsForm
      }
    }
  }
}
    ${TargetForTargetsFormFragmentDoc}`;
export const UserUploadedTableForAdtSelectorDialogFragmentDoc = gql`
    fragment UserUploadedTableForAdtSelectorDialog on UserUploadedTable {
  __typename
  id
  error
  errorType
  sqlTableName
  status
  shouldIgnore
  isUsingLatestUIR
  integrationDataPull {
    id
    integrationConnection {
      id
    }
  }
  parentFileMetadata {
    id
    category
    userUploadTask {
      id
      state
    }
  }
}
    `;
export const UserUploadAttemptForAdtSelectorDialogFragmentDoc = gql`
    fragment UserUploadAttemptForAdtSelectorDialog on UserUploadAttempt {
  __typename
  id
  name
  createdAt
  size
  uploader {
    id
    name
    email
  }
  gcloudStorageUrl
  deletedAt
  userUploadedTables {
    ...UserUploadedTableForAdtSelectorDialog
  }
}
    ${UserUploadedTableForAdtSelectorDialogFragmentDoc}`;
export const UserUploadForAdtSelectorDialogFragmentDoc = gql`
    fragment UserUploadForAdtSelectorDialog on UserUpload {
  __typename
  id
  name
  userUploadTask {
    id
    datasource {
      id
      name
    }
    measurementProject {
      id
      name
    }
  }
  userVisibleAttempt {
    id
    description
  }
  allAttempts {
    ...UserUploadAttemptForAdtSelectorDialog
  }
}
    ${UserUploadAttemptForAdtSelectorDialogFragmentDoc}`;
export const CanonicalClimateProgramProjectRequirementGhgpFieldsFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectRequirementGhgpFields on CanonicalClimateProgramProjectRequirementGhgp {
  id
  ghgScope
  ghgScope3Category
  minimumFidelity
  additionalNotesMd
}
    `;
export const CompanyRelationshipsCardFragmentDoc = gql`
    fragment CompanyRelationshipsCard on Company {
  id
  name
  isDemo
  companyRelationships {
    id
    companyA {
      id
      name
    }
    companyB {
      id
      name
    }
    relationshipType
    relationshipStartDate
    relationshipEndDate
  }
}
    `;
export const EngagementTaskAllFieldsFragmentDoc = gql`
    fragment EngagementTaskAllFields on EngagementTask {
  id
  companyId
  rootCompanyId
  approvedAt
  createdAt
  crossOrgModelId
  deletedAt
  dueDate
  expiresAt
  id
  initialSupplierAssigneeEmail
  latestCeeWorkflowId
  proposeTargetIntensityType
  reportingYear
  rootAssignee
  status
  statusChangedAt
  submittedAt
  supplierAssignee
  supplierFacingDescription
  supplierOrgId
  surveyKind
  watershedGeneric
}
    `;
export const SharedMeasurementProjectAllFieldsFragmentDoc = gql`
    fragment SharedMeasurementProjectAllFields on SharedMeasurementProject {
  id
  measurementProjectId
  orgId
}
    `;
export const PublicDisclosureAllFieldsFragmentDoc = gql`
    fragment PublicDisclosureAllFields on PublicDisclosure {
  id
  companyId
  reportType
  publishingYear
  publicUrl
  gsUrl
  createdAt
  externalScore
  source
  sourceRecordId
  updatedAt
  qualityScore
}
    `;
export const CompanyClimateCommitmentRawAllFieldsFragmentDoc = gql`
    fragment CompanyClimateCommitmentRawAllFields on CompanyClimateCommitmentRaw {
  commitmentMadeDate
  commitmentPeriodEnd
  commitmentPeriodStart
  createdAt
  deletedAt
  description
  publicDisclosureId
  externalUrl
  id
  kind
  orgId
  privateDisclosureId
  sbtBaselineYear
  sbtCommitmentStage
  sbtCommitmentType
  sbtCommitmentDeadline
  sbtLongTermTargetYear
  sbtNearTermTargetYear
  sbtNetZeroTargetYear
  sbtNetZeroCommitted
  sbtTargetClassification
  targetPercentageCleanEnergy
  targetYear
  updatedAt
  userEditorId
  watershedEditorId
}
    `;
export const FilterExpressionGroupAllFieldsFragmentDoc = gql`
    fragment FilterExpressionGroupAllFields on FilterExpressionGroup {
  conjunction
  expressions {
    field
    operator
    value
  }
}
    `;
export const DisclosureTargetDetailsAllFieldsFragmentDoc = gql`
    fragment DisclosureTargetDetailsAllFields on DisclosureTargetDetails {
  id
  name
  description
  baseYear
  emissionsType
  reductionRate
  intensityType
  publicDisclosureId
  privateDisclosureId
  companyClimateCommitmentId
  createdAt
  deletedAt
  emissionsTargetId
  filters {
    ...FilterExpressionGroupAllFields
  }
  orgId
  updatedAt
  unit
  unitDescription
}
    ${FilterExpressionGroupAllFieldsFragmentDoc}`;
export const DisclosureInitiativeAllFieldsFragmentDoc = gql`
    fragment DisclosureInitiativeAllFields on DisclosureInitiative {
  createdAt
  description
  endYearMonth
  filters {
    ...FilterExpressionGroupAllFields
  }
  id
  initiativeType
  name
  orgId
  privateDisclosureId
  publicDisclosureId
  startYearMonth
  updatedAt
  startDate
  endDate
  emissionsTimeseriesId
  intensityType
}
    ${FilterExpressionGroupAllFieldsFragmentDoc}`;
export const PrivateDisclosureAllFieldsFragmentDoc = gql`
    fragment PrivateDisclosureAllFields on PrivateDisclosure {
  id
  companyId
  assetCorporateId
  assetPersonalMotorVehicleInsuranceId
  orgId
  footprintDisclosureId
  surveyId
  privateDisclosureType
  createdAt
  disclosureQualityScore
  disclosureQualityExplanation
  thirdPartyVerified
  updatedAt
  userEditorId
  watershedEditorId
  engagementTaskId
  sourceId
  companyIngestionRecordUpdateId
}
    `;
export const CdpVendorDataFieldsFragmentDoc = gql`
    fragment CdpVendorDataFields on CdpVendorData {
  id
  publishingYear
  reportingYear
  scope1Nonzero
  scope2Nonzero
  scope3Nonzero
  totalEmissionsNonzero
  scope301Or302Nonzero
  scope1Verified
  scope2Verified
  scope3Verified
  pctEvaluationStatusesMatchResponse
  disclosureQualityScore
  scope301EvaluationStatus
  scope302EvaluationStatus
  scope303EvaluationStatus
  scope304EvaluationStatus
  scope305EvaluationStatus
  scope306EvaluationStatus
  scope307EvaluationStatus
  scope308EvaluationStatus
  scope309EvaluationStatus
  scope310EvaluationStatus
  scope311EvaluationStatus
  scope312EvaluationStatus
  scope313EvaluationStatus
  scope314EvaluationStatus
  scope315EvaluationStatus
  scope316EvaluationStatus
  scope317EvaluationStatus
}
    `;
export const CompanyListFieldsFragmentDoc = gql`
    fragment CompanyListFields on Company {
  id
  name
  description
  url
  fullLogoUrl
  naicsCode
  naicsCodeSource
  countryAlpha2
  sfdcOpportunityId
  sfdcStage
  isDemo
  sustainabilityWebsiteUrl
}
    `;
export const CompanyChangeRequestFieldsFragmentDoc = gql`
    fragment CompanyChangeRequestFields on CompanyChangeRequest {
  id
  org {
    id
    name
    demoOrg
  }
  action
  deletedAt
  payload
  userEditor {
    id
    name
  }
  createdAt
}
    `;
export const CompanyNameFieldsFragmentDoc = gql`
    fragment CompanyNameFields on CompanyName {
  id
  name
  cleanedName
  isPrimary
  sourceId
}
    `;
export const CompanyIngestionDiffFieldsFragmentDoc = gql`
    fragment CompanyIngestionDiffFields on CompanyIngestionDiff {
  id
  primaryCompanyName
  sourceRecord {
    __typename
    ... on CdpVendorData {
      ...CdpVendorDataFields
    }
    ... on SbtTarget {
      id
    }
    ... on Company {
      ...CompanyListFields
    }
    ... on CompanyChangeRequest {
      ...CompanyChangeRequestFields
    }
    ... on IngestedSbtiCompanyUpdate {
      id
    }
  }
  company {
    id
    name
    names {
      ...CompanyNameFields
    }
  }
  companyMatchScore
  companyMatchResults
  processedAt
  createdAt
  updatedAt
  isCompanyDemo
}
    ${CdpVendorDataFieldsFragmentDoc}
${CompanyListFieldsFragmentDoc}
${CompanyChangeRequestFieldsFragmentDoc}
${CompanyNameFieldsFragmentDoc}`;
export const PublicDisclosureForAdminEditFragmentDoc = gql`
    fragment PublicDisclosureForAdminEdit on PublicDisclosure {
  id
  reportType
  companyId
  publishingYear
  reportingYears
  publicUrl
  qualityScore
  cdpVendorData {
    ...CdpVendorDataFields
  }
  externalScore
  gsUrl
  source
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const EmissionsYearAllFieldsFragmentDoc = gql`
    fragment EmissionsYearAllFields on EmissionsYear {
  id
  createdAt
  publicDisclosureId
  privateDisclosureId
  footprintEstimateOutputId
  buildingEstimateOutputId
  assetCorporateId
  expenseCategory
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  currencyConversionRate
  scope1
  scope2
  scope2Market
  scope2Location
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
  units
  percentageCleanEnergy
  totalMwh
  orgId
  scenario
  updatedAt
  pcafScoreScope1
}
    `;
export const CompanyClimateCommitmentFieldsFragmentDoc = gql`
    fragment CompanyClimateCommitmentFields on CompanyClimateCommitment {
  __typename
  id
  kind
  description
  externalUrl
  commitmentMadeDate
  commitmentPeriodStart
  commitmentPeriodEnd
  ... on ScienceBasedTargetCommitment {
    submittedToSBTi
    commitment {
      id
      nearTermTargetYear
      longTermTargetYear
      baselineYear
      stage
      targetClassification
      netZeroTargetYear
      commitmentDeadline
      commitmentType
      netZeroCommitted
    }
  }
  ... on NetZeroCommitment {
    id
    targetYear
  }
  ... on CarbonNeutralCommitment {
    id
    targetYear
  }
  ... on CleanEnergyCommitment {
    id
    targetYear
    targetPercentageCleanEnergy
  }
}
    `;
export const CompanyUrlFieldsFragmentDoc = gql`
    fragment CompanyUrlFields on CompanyUrl {
  id
  url
  source {
    id
    sourceKey
  }
  sourceId
}
    `;
export const CompanySourceKeyFieldsFragmentDoc = gql`
    fragment CompanySourceKeyFields on CompanySourceKey {
  id
  sourceKey
  sourceUrl
  sourceNotes
  priority
  userVisibleSourceUrl
  userVisibleSourceNotes
  createdAt
  updatedAt
  editor {
    id
    name
  }
}
    `;
export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on Company {
  id
  name
  names {
    ...CompanyNameFields
  }
  isins {
    id
    isin
    sourceId
  }
  beas {
    id
    bea
    sourceId
  }
  leis {
    id
    lei
    sourceId
  }
  tickerSymbols {
    id
    tickerSymbol
    exchangeSymbol
    isSourcePrimary
    sourceId
  }
  sAndP {
    id
    sAndPId
    sourceId
  }
  countries {
    id
    countryAlpha2
    isPrimary
    sourceId
  }
  disclosures(opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}) {
    id
    publicDisclosure {
      ...PublicDisclosureForAdminEdit
    }
    historicalEmissionsYears {
      ...EmissionsYearAllFields
    }
    climateCommitments {
      ...CompanyClimateCommitmentFields
    }
  }
  description
  logo {
    ...CompanyUrlFields
  }
  companyWebsite {
    ...CompanyUrlFields
  }
  sustainabilityWebsite {
    ...CompanyUrlFields
  }
  isDemo
  editor {
    id
    name
  }
  sourceKey {
    ...CompanySourceKeyFields
  }
  replacedBy {
    __typename
    ... on Company {
      id
      name
    }
  }
  createdAt
  updatedAt
}
    ${CompanyNameFieldsFragmentDoc}
${PublicDisclosureForAdminEditFragmentDoc}
${EmissionsYearAllFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsFragmentDoc}
${CompanyUrlFieldsFragmentDoc}
${CompanySourceKeyFieldsFragmentDoc}`;
export const CompanyIngestionRecordUpdateFieldsFragmentDoc = gql`
    fragment CompanyIngestionRecordUpdateFields on CompanyIngestionRecordUpdate {
  id
  diff {
    ...CompanyIngestionDiffFields
  }
  tableName
  record {
    __typename
    ...CompanyFields
  }
  oldValue
  newValue
  status
  createdAt
  updatedAt
}
    ${CompanyIngestionDiffFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;
export const SbtTargetFieldsFragmentDoc = gql`
    fragment SbtTargetFields on SbtTarget {
  id
  companyName
  isin
  lei
  location
  region
  sector
  organizationType
  action
  commitmentType
  commitmentDeadline
  status
  reasonForCommitmentExtensionOrRemoval
  fullTargetLanguage
  companyTemperatureAlignment
  target
  targetWording
  scope
  targetValue
  targetType
  targetSubtype
  targetClassification
  baseYear
  targetYear
  yearType
  datePublished
  createdAt
  updatedAt
  dt
}
    `;
export const IngestionExampleDataFieldsFragmentDoc = gql`
    fragment IngestionExampleDataFields on IngestionExampleData {
  id
  title
  columns {
    fieldName
    title
    description
    examples
    required
    optionalToMap
    defaultValue
  }
  rows {
    fields {
      fieldName
      value
    }
  }
  datasetId
}
    `;
export const CtsTransformRunFieldsFragmentDoc = gql`
    fragment CtsTransformRunFields on CustomerTargetSchemaTransformRun {
  id
  sql
  transform {
    id
    transformTarget
    businessActivityTypeName
  }
  input {
    id
    name
    category
    revisionRootFileMetadata {
      id
      name
    }
  }
  output {
    ...ParquetDataFields
  }
  standardizedOutput {
    ...ParquetDataFields
  }
  warnings {
    message
    blocking
  }
  error
  createdAt
  runner {
    id
    name
  }
}
    ${ParquetDataFieldsFragmentDoc}`;
export const CtsTransformForEditorFragmentDoc = gql`
    fragment CtsTransformForEditor on CustomerTargetSchemaTransform {
  id
  deletedAt
  sql
  transformTarget
  businessActivityTypeName
  businessActivityTypeVersionId
  businessActivityTypeVersion {
    id
    name
  }
  runs {
    ...CtsTransformRunFields
  }
}
    ${CtsTransformRunFieldsFragmentDoc}`;
export const CtsTestFragmentDoc = gql`
    fragment CtsTest on CustomerTargetSchemaTransformTestCase {
  id
  name
  inputJson
  kind
  expectedOutput {
    ...ParquetDataFields
  }
  latestRun {
    id
    status
    testDiffResults
    transformRun {
      id
      standardizedOutput {
        ...ParquetDataFields
      }
      error
    }
  }
}
    ${ParquetDataFieldsFragmentDoc}`;
export const CtsTransformForTestsFragmentDoc = gql`
    fragment CtsTransformForTests on CustomerTargetSchemaTransform {
  id
  runs {
    ...CtsTransformRunFields
  }
  testCases {
    ...CtsTest
  }
}
    ${CtsTransformRunFieldsFragmentDoc}
${CtsTestFragmentDoc}`;
export const CtsVersionAdminFragmentDoc = gql`
    fragment CtsVersionAdmin on CustomerTargetSchemaVersion {
  id
  schemaJson
  semverVersion
  versionNotesMd
  exampleData
  formattingNotesMd
  createdAt
  publishedAt
  publishedForTestingAt
  unpublishedAt
  schema {
    id
    name
    versions {
      id
      publishedAt
      publishedForTestingAt
      unpublishedAt
    }
  }
  globalIngestionExampleData {
    ...IngestionExampleDataFields
  }
  transformsIncludingDeleted {
    ...CtsTransformForEditor
    ...CtsTransformForTests
  }
  lastEditedByUser {
    id
    name
  }
  schemaVersionId
}
    ${IngestionExampleDataFieldsFragmentDoc}
${CtsTransformForEditorFragmentDoc}
${CtsTransformForTestsFragmentDoc}`;
export const CtsInfoHeaderFragmentDoc = gql`
    fragment CtsInfoHeader on CustomerTargetSchema {
  id
  name
  descriptionMd
  rank
  versions {
    ...CtsVersionAdmin
  }
  latestPublishedVersion {
    id
    publishedAt
  }
  canonicalDatasets {
    id
    name
  }
  schemaId
}
    ${CtsVersionAdminFragmentDoc}`;
export const FileMetadataForCtsVersionEditorFragmentDoc = gql`
    fragment FileMetadataForCtsVersionEditor on FileMetadata {
  id
  remotePath
  gcloudStorageUrl
}
    `;
export const MeasurementProjectFieldsForCreateDataIssueFragmentDoc = gql`
    fragment MeasurementProjectFieldsForCreateDataIssue on MeasurementProject {
  id
  name
  active
  coverageInterval
  userUploadTasks {
    edges {
      node {
        __typename
        id
        state
        datasource {
          id
          name
          dataset {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export const ReferenceDataSourceFieldsForPickerFragmentDoc = gql`
    fragment ReferenceDataSourceFieldsForPicker on ReferenceDataSource {
  id
  name
  latestPublishedVersion {
    id
    versionName
    latestSignedParquetOutputUrl(orgId: $orgId)
    latestSchema {
      id
      schemaJson
    }
  }
}
    `;
export const EmissionsModelAncestorFieldsFragmentDoc = gql`
    fragment EmissionsModelAncestorFields on EmissionsModelAncestor {
  rootIsLatestPublishedVersion
  ancestorStableModel {
    id
    title
    description
  }
  ancestorForkOriginStableModelId
  relationship {
    parentId
    childId
  }
}
    `;
export const EmissionsModelReleaseOrgAccessFieldsFragmentDoc = gql`
    fragment EmissionsModelReleaseOrgAccessFields on EmissionsModelReleaseOrgAccess {
  id
  org {
    id
    name
    demoOrg
    testOrg
  }
  createdAt
}
    `;
export const EmissionsModelReleaseMetadataFragmentDoc = gql`
    fragment EmissionsModelReleaseMetadata on EmissionsModelRelease {
  id
  updatedAt
  userAuthorId
  user {
    id
    name
  }
  org {
    id
    name
    demoOrg
    testOrg
  }
  displayName
  businessActivityTypeName
  internalNotes
  externalNotes
  orgId
  lifecyclePhase
  isCustomToUserOrg
  latestCompatibleBatVersion {
    id
    name
  }
  isOrgGated
  orgAccess {
    ...EmissionsModelReleaseOrgAccessFields
  }
}
    ${EmissionsModelReleaseOrgAccessFieldsFragmentDoc}`;
export const EmissionsModelStableFieldsForReleasesFragmentDoc = gql`
    fragment EmissionsModelStableFieldsForReleases on EmissionsModelStable {
  id
  title
  description
  org {
    id
    name
  }
  latestPublishedVersion {
    id
    createdAt
    changelog
    valid
    author {
      id
      name
    }
  }
  matchers {
    id
    businessActivityType
    filterExpression
    keepBatVersionAtLatest
  }
  releases {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;
export const GlobalFootprintTagFieldsForReleasesFragmentDoc = gql`
    fragment GlobalFootprintTagFieldsForReleases on GlobalFootprintTag {
  id
  createdAt
  tagName
  description
  watershedEditor {
    id
    displayName
  }
}
    `;
export const EmissionsModelReleaseDetailsPageFragmentDoc = gql`
    fragment EmissionsModelReleaseDetailsPage on EmissionsModelRelease {
  ...EmissionsModelReleaseMetadata
  emissionsModels {
    ...EmissionsModelStableFieldsForReleases
  }
  globalFootprintTags {
    ...GlobalFootprintTagFieldsForReleases
  }
  measurementTestSuites {
    id
    title
    description
    kind
    createdAt
    updatedAt
    status
  }
  org {
    id
    name
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}
${EmissionsModelStableFieldsForReleasesFragmentDoc}
${GlobalFootprintTagFieldsForReleasesFragmentDoc}`;
export const EmissionsModelReleaseListEntryFragmentDoc = gql`
    fragment EmissionsModelReleaseListEntry on EmissionsModelRelease {
  ...EmissionsModelReleaseMetadata
  emissionsModels {
    id
    title
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;
export const OrganizationListPageOrganizationFragmentDoc = gql`
    fragment OrganizationListPageOrganization on Organization {
  id
  name
  domains
  demoOrg
  testOrg
  stagingOrg
  canAccessFinance
  hasUsers
  watershedPlanLegacy
}
    `;
export const GlobalFootprintTagsForGlobalTagsEditorFragmentDoc = gql`
    fragment GlobalFootprintTagsForGlobalTagsEditor on GlobalFootprintTag {
  id
  tagName
  description
  rulesList
  recordEndAt
  createdAt
  watershedEditor {
    id
    name
  }
}
    `;
export const IndustryClassificationDetailsFragmentDoc = gql`
    fragment IndustryClassificationDetails on IndustryClassification {
  id
  shortDescription
  longDescription
}
    `;
export const FinancialsReviewItemAdminDialogFragmentDoc = gql`
    fragment FinancialsReviewItemAdminDialog on FinancialsReviewItem {
  id
  createdAt
  account {
    accountId
    description
  }
  status
  initiallyIncluded
  exclusionReason
  initialIndustryClassification {
    ...IndustryClassificationDetails
  }
  prompt
  userResponse
  respondingUser {
    id
    name
  }
  discussion {
    id
    state
  }
  group {
    id
    measurementProject {
      id
    }
  }
  ...ContextSeedObjectFields
}
    ${IndustryClassificationDetailsFragmentDoc}
${ContextSeedObjectFieldsFragmentDoc}`;
export const MeasurementContextItemFieldsForListFragmentDoc = gql`
    fragment MeasurementContextItemFieldsForList on MeasurementContextItem {
  id
  title
  descriptionMd
  author {
    id
    name
  }
  org {
    id
  }
  tags
  urls
  updatedAt
  history {
    id
    title
    descriptionMd
    author {
      id
      name
    }
    tags
    urls
    createdAt
    relations {
      ...ContextRelationFields
    }
  }
  relations {
    ...ContextRelationFields
  }
}
    ${ContextRelationFieldsFragmentDoc}`;
export const ContextRelationFieldsForSearchFragmentDoc = gql`
    fragment ContextRelationFieldsForSearch on ContextRelationInterface {
  id
  name
  ancestorRelations {
    id
  }
}
    `;
export const MeasurementTestSuiteMetadataLiteFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteMetadataLiteFields on MeasurementTestSuite {
  id
  title
  kind
  status
  organization {
    id
    name
  }
  calculationTags {
    id
    name
  }
  emissionsModelReleases {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;
export const MeasurementTestSuiteMetadataFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteMetadataFields on MeasurementTestSuite {
  ...MeasurementTestSuiteMetadataLiteFields
  description
  createdAt
  deletedAt
}
    ${MeasurementTestSuiteMetadataLiteFieldsFragmentDoc}`;
export const MeasurementTestSuiteChangelogFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteChangelogFields on MeasurementTestSuiteChangelog {
  id
  author {
    id
    name
  }
  measurementResourceEvent {
    id
    createdAt
    eventKind
    eventData
    author {
      id
      name
    }
  }
  changelog
  createdAt
}
    `;
export const MeasurementTestSuiteBartMetadataFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteBartMetadataFields on MeasurementTestSuiteBart {
  id
  title
  activityTypeName
  fieldDefaults
  hiddenFields
  businessActivityTypeVersion {
    id
    name
  }
}
    `;
export const MeasurementTestRowFieldsFragmentDoc = gql`
    fragment MeasurementTestRowFields on MeasurementTestRow {
  id
  description
  bartRowId
  activityRow
}
    `;
export const FootprintTestSuiteConfigFieldsFragmentDoc = gql`
    fragment FootprintTestSuiteConfigFields on FootprintTestSuiteConfig {
  id
  pipelineStartDate
  pipelineEndDate
  customTagNames
  marketplaceAllocationInstructionIds
  exclusionRuleIds
  categoryRuleIds
  shouldExclude
}
    `;
export const MeasurementTestSuiteDetailsWithoutExecutionsFragmentDoc = gql`
    fragment MeasurementTestSuiteDetailsWithoutExecutions on MeasurementTestSuite {
  ...MeasurementTestSuiteMetadataFields
  latestChangelogEntry {
    ...MeasurementTestSuiteChangelogFields
  }
  measurementTestSuiteBarts {
    ...MeasurementTestSuiteBartMetadataFields
    measurementTestRows {
      ...MeasurementTestRowFields
    }
    activityTypeFields {
      id
      description
      fieldName
      fieldType
      fieldValueOneOf
      isRequired
    }
  }
  footprintTestSuiteConfig {
    ...FootprintTestSuiteConfigFields
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${MeasurementTestSuiteChangelogFieldsFragmentDoc}
${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}
${MeasurementTestRowFieldsFragmentDoc}
${FootprintTestSuiteConfigFieldsFragmentDoc}`;
export const MethodologyTestSuiteExecutionMetadataFieldsFragmentDoc = gql`
    fragment MethodologyTestSuiteExecutionMetadataFields on MethodologyTestSuiteExecution {
  id
  createdAt
  status
  author {
    id
    name
  }
}
    `;
export const FootprintTestExecutionMetadataFieldsFragmentDoc = gql`
    fragment FootprintTestExecutionMetadataFields on FootprintTestSuiteExecution {
  id
  status
  error
  warnings
  failedStep
}
    `;
export const PrepublicationTestSuiteExecutionFieldsFragmentDoc = gql`
    fragment PrepublicationTestSuiteExecutionFields on PrepublicationTestSuiteExecution {
  measurementTestSuite {
    ...MeasurementTestSuiteDetailsWithoutExecutions
  }
  methodologyTestSuiteExecution {
    ...MethodologyTestSuiteExecutionMetadataFields
  }
  footprintTestSuiteExecution {
    ...FootprintTestExecutionMetadataFields
  }
  errorMessage
}
    ${MeasurementTestSuiteDetailsWithoutExecutionsFragmentDoc}
${MethodologyTestSuiteExecutionMetadataFieldsFragmentDoc}
${FootprintTestExecutionMetadataFieldsFragmentDoc}`;
export const MeasurementTestSuiteListFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteListFields on MeasurementTestSuite {
  ...MeasurementTestSuiteMetadataFields
  measurementTestSuiteBarts {
    ...MeasurementTestSuiteBartMetadataFields
    measurementTestRowCount
  }
  latestMethodologyTestSuiteExecution {
    id
    status
  }
  latestFootprintTestSuiteExecution {
    id
    status
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}`;
export const MeasurementTestSuiteLiteListFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteLiteListFields on MeasurementTestSuite {
  ...MeasurementTestSuiteMetadataLiteFields
  measurementTestSuiteBarts {
    id
    activityTypeName
  }
}
    ${MeasurementTestSuiteMetadataLiteFieldsFragmentDoc}`;
export const MethodologyTestRowResultFieldsFragmentDoc = gql`
    fragment MethodologyTestRowResultFields on MethodologyTestRowResult {
  id
  measurementTestRowId
  emissionsModelStable {
    id
    title
  }
  measurementTestRow {
    id
    bartRowId
    activityRow
  }
  frozenExpectation
  result
  diff
  status
  resultAccepted
}
    `;
export const MethodologyTestSuiteExecutionWithResultsFieldsFragmentDoc = gql`
    fragment MethodologyTestSuiteExecutionWithResultsFields on MethodologyTestSuiteExecution {
  ...MethodologyTestSuiteExecutionMetadataFields
  methodologyTestRowResults {
    ...MethodologyTestRowResultFields
  }
}
    ${MethodologyTestSuiteExecutionMetadataFieldsFragmentDoc}
${MethodologyTestRowResultFieldsFragmentDoc}`;
export const FootprintTestExecutionStepResultFieldsFragmentDoc = gql`
    fragment FootprintTestExecutionStepResultFields on FootprintTestExecutionStepResult {
  id
  resultRecords {
    id
    month
    bartRowId
    bartName
    emStableId
    emStableTitle
    emVariableName
    rowSplitKey
    ghgCategoryId
    categoryId
    subcategoryId
    description
    quantityKgCo2e
    allocationLocationQuantityKgCo2E
    fullDiff
    status
    inActual
    inExpected
    resultAccepted
  }
}
    `;
export const VersionFieldsForGlobalEmissionsModelTreeFragmentDoc = gql`
    fragment VersionFieldsForGlobalEmissionsModelTree on EmissionsModelVersion {
  id
  title
  emissionsModelJson
  referenceDataVersions {
    id
    slug
    citations {
      id
      slug
    }
  }
}
    `;
export const EmissionsModelMatcherFieldsFragmentDoc = gql`
    fragment EmissionsModelMatcherFields on EmissionsModelMatcher {
  id
  kind
  emissionsModelStableId
  businessActivityType
  businessActivityTypeVersionId
  filterExpression
  quantitySpecifier
  bindings
  keepBatVersionAtLatest
}
    `;
export const StableFieldsForGlobalEmissionsModelTreeFragmentDoc = gql`
    fragment StableFieldsForGlobalEmissionsModelTree on EmissionsModelStable {
  id
  latestPublishedVersion {
    ...VersionFieldsForGlobalEmissionsModelTree
    importedDescendants {
      ...VersionFieldsForGlobalEmissionsModelTree
    }
  }
  matchers {
    ...EmissionsModelMatcherFields
  }
  releases {
    id
    businessActivityTypeName
    displayName
    lifecyclePhase
    latestCompatibleBatVersion {
      id
    }
  }
}
    ${VersionFieldsForGlobalEmissionsModelTreeFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}`;
export const SchemaRegistrySchemaSchemaFragmentDoc = gql`
    fragment SchemaRegistrySchemaSchema on SchemaRegistrySchema {
  id
  name
  createdAt
  latestSchemaVersion {
    id
    version
  }
}
    `;
export const SchemaRegistrySchemaFragmentFragmentDoc = gql`
    fragment SchemaRegistrySchemaFragment on SchemaRegistrySchema {
  id
  name
}
    `;
export const SchemaRegistrySchemaVersionFragmentFragmentDoc = gql`
    fragment SchemaRegistrySchemaVersionFragment on SchemaRegistrySchemaVersion {
  id
  schema {
    ...SchemaRegistrySchemaFragment
  }
  version
}
    ${SchemaRegistrySchemaFragmentFragmentDoc}`;
export const DataRegistryBatchMigratorRunSchemaFragmentDoc = gql`
    fragment DataRegistryBatchMigratorRunSchema on DataRegistryBatchMigratorRun {
  id
  author {
    id
    name
  }
  description
  createdAt
  status
  fromSchemaVersion {
    ...SchemaRegistrySchemaVersionFragment
  }
  toSchemaVersion {
    ...SchemaRegistrySchemaVersionFragment
  }
  workflowId
  filters
  successCount
  failureCount
  error
  deletedAt
  region
}
    ${SchemaRegistrySchemaVersionFragmentFragmentDoc}`;
export const DataRegistryMigratorSchemaFragmentDoc = gql`
    fragment DataRegistryMigratorSchema on DataRegistryMigrator {
  id
  createdAt
  migratorType
  migrationCode
  inputVersion {
    id
    version
  }
  outputVersion {
    id
    version
  }
}
    `;
export const SchemaRegistrySchemaVersionSchemaFragmentDoc = gql`
    fragment SchemaRegistrySchemaVersionSchema on SchemaRegistrySchemaVersion {
  id
  version
  createdAt
  schemaJson
  schemaCount
}
    `;
export const FootprintDisclosureShareLogFieldsFragmentDoc = gql`
    fragment FootprintDisclosureShareLogFields on FootprintDisclosureShareLog {
  id
  orgId
  footprintDisclosureId
  sharedToOrgId
  sharedByUserId
}
    `;
export const FootprintDisclosureFieldsFragmentDoc = gql`
    fragment FootprintDisclosureFields on FootprintDisclosure {
  id
  createdAt
  reportingYear
  footprintSnapshot {
    id
    configs {
      coverageInterval
    }
  }
  shareLogs {
    ...FootprintDisclosureShareLogFields
  }
}
    ${FootprintDisclosureShareLogFieldsFragmentDoc}`;
export const UserFieldsForFinancePageFragmentDoc = gql`
    fragment UserFieldsForFinancePage on User {
  id
  name
  displayName
}
    `;
export const PortcoOrgFieldsForFinancePageFragmentDoc = gql`
    fragment PortcoOrgFieldsForFinancePage on Organization {
  id
  name
}
    `;
export const HistoricalEmissionsYearFieldsFragmentDoc = gql`
    fragment HistoricalEmissionsYearFields on EmissionsYear {
  id
  publicDisclosureId
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  revenueUsd
  scope1
  scope2Market
  scope2Location
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
  units
  percentageCleanEnergy
  totalMwh
  scope123RevenueIntensityKgco2e {
    id
    scope1
    scope2Market
    scope2Location
    scope3
  }
}
    `;
export const PublicDisclosureTableFragmentDoc = gql`
    fragment PublicDisclosureTable on PublicDisclosure {
  id
  company {
    id
    name
  }
  publicUrl
  publishingYear
  reportType
  gsUrl
  historicalEmissionsYearsDetails {
    ...HistoricalEmissionsYearFields
  }
}
    ${HistoricalEmissionsYearFieldsFragmentDoc}`;
export const CustomReportFieldsFragmentDoc = gql`
    fragment CustomReportFields on FootprintCustomReport {
  id
  name
  description
  downloadUrl
  published
  footprintSnapshotId
  lastRefreshedAt
  archivedAt
}
    `;
export const FormalReportFieldsAdminFragmentDoc = gql`
    fragment FormalReportFieldsAdmin on Report {
  id
  name
  isHidden
  reportKind
  footprintSnapshot {
    id
  }
  createdAt
  deletedAt
  config {
    id
    reportType
  }
}
    `;
export const ExternalReportQuestionFieldsFragmentDoc = gql`
    fragment ExternalReportQuestionFields on ExternalReportQuestion {
  id
  type {
    id
    type
    revision
    specUrl
  }
  reportQuestionIdentifier {
    id
    identifier
  }
  question
}
    `;
export const ExternalReportAnswerFieldsFragmentDoc = gql`
    fragment ExternalReportAnswerFields on ExternalReportAnswer {
  id
  questionPhrasing
  answer
  answerType
  index
  watershedQualityScore
  question {
    ...ExternalReportQuestionFields
  }
  publicDisclosure {
    id
    company {
      id
      name
    }
  }
}
    ${ExternalReportQuestionFieldsFragmentDoc}`;
export const VendorMatchingTaskFieldsForAdminFragmentDoc = gql`
    fragment VendorMatchingTaskFieldsForAdmin on VendorMatchingTask {
  id
  measurementProject {
    id
    name
  }
  wasAutomapped
}
    `;
export const SupplierChartConfigFieldsAdminFragmentDoc = gql`
    fragment SupplierChartConfigFieldsAdmin on SupplierChartConfig {
  id
  orgId
  title
  description
  chartType
  ... on SupplierStandardChartConfig {
    colorScale
    xField
    aggregateType
  }
  ... on SupplierEngagementFunnelChartConfig {
    funnels {
      id
    }
  }
  ... on SupplierTaskStatusChangeOverTimeChartConfig {
    colorScale
    tasksStatusChangeConfig {
      status
      engagementTaskConfigId
    }
    isCumulative
  }
  ... on SupplierMetricsChartConfig {
    metricsTypes
  }
  ... on SupplierSbtCommitmentOverTimeChartConfig {
    colorScale
  }
}
    `;
export const SupplierViewFieldsAdminFragmentDoc = gql`
    fragment SupplierViewFieldsAdmin on SupplierView {
  id
  charts: chartsV2 {
    __typename
    ...SupplierChartConfigFieldsAdmin
  }
  chartOrder {
    chartId
    visible
  }
}
    ${SupplierChartConfigFieldsAdminFragmentDoc}`;
export const ActiveWatershedEmployeeFieldsFragmentDoc = gql`
    fragment ActiveWatershedEmployeeFields on WatershedEmployee {
  id
  name
  handle
  email
  user {
    id
    createdAt
    accessibleOrgs {
      id
      name
    }
    primaryLoginOrgId
  }
  orgPointsOfContact {
    id
    orgId
    kind
  }
}
    `;
export const InstructionsStepBaseFieldsFragmentDoc = gql`
    fragment InstructionsStepBaseFields on InstructionsStep {
  descriptionMd
  fileMetadataId
  remotePath
  publicUrl
}
    `;
export const SdiInstructionsStepBaseFieldsFragmentDoc = gql`
    fragment SdiInstructionsStepBaseFields on SdiInstructionsStep {
  title
  descriptionMd
}
    `;
export const SdiInstructionsFaqStepBaseFieldsFragmentDoc = gql`
    fragment SdiInstructionsFaqStepBaseFields on SdiInstructionsFaqType {
  question
  answerMd
}
    `;
export const InstructionsBundleBaseFieldsFragmentDoc = gql`
    fragment InstructionsBundleBaseFields on InstructionsBundle {
  instructionsDataFormattingMd
  instructionsIntroMd
  sdiInstructionsDataFormattingMd
  sdiInstructionsIntroMd
  ghgScopes
  usedFor
  potentialOverlaps
  instructionsSteps {
    ...InstructionsStepBaseFields
  }
  sdiInstructionsSteps {
    ...SdiInstructionsStepBaseFields
  }
  sdiInstructionsFaq {
    ...SdiInstructionsFaqStepBaseFields
  }
}
    ${InstructionsStepBaseFieldsFragmentDoc}
${SdiInstructionsStepBaseFieldsFragmentDoc}
${SdiInstructionsFaqStepBaseFieldsFragmentDoc}`;
export const CanonicalDatasetBaseFieldsFragmentDoc = gql`
    fragment CanonicalDatasetBaseFields on CanonicalDataset {
  id
  name
  description
  usedFor
  potentialOverlaps
  kind
  instructions {
    ...InstructionsBundleBaseFields
  }
  selfServeDisabledReason
}
    ${InstructionsBundleBaseFieldsFragmentDoc}`;
export const CanonicalDatasourceBaseFieldsFragmentDoc = gql`
    fragment CanonicalDatasourceBaseFields on CanonicalDatasource {
  id
  name
  instructions {
    ...InstructionsBundleBaseFields
  }
}
    ${InstructionsBundleBaseFieldsFragmentDoc}`;
export const IntegrationPartnerBaseFieldsFragmentDoc = gql`
    fragment IntegrationPartnerBaseFields on IntegrationPartner {
  id
  name
}
    `;
export const CanonicalProjectRequirementDatasetFieldsFragmentDoc = gql`
    fragment CanonicalProjectRequirementDatasetFields on CanonicalClimateProgramProjectRequirementDataset {
  id
  canonicalDataset {
    id
    name
  }
  additionalNotesMd
}
    `;
export const CanonicalProjectRequirementGhgpFieldsFragmentDoc = gql`
    fragment CanonicalProjectRequirementGhgpFields on CanonicalClimateProgramProjectRequirementGhgp {
  id
  ghgScope
  ghgScope3Category
  minimumFidelity
  additionalNotesMd
}
    `;
export const CanonicalClimateProgramProjectFieldsFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectFields on CanonicalClimateProgramProject {
  id
  name
  description
  hidden
  hiddenFromMeasurement
  kind
  timeEstimate
  costEstimate
  iconTypeOverride
  iconType
  measurementObjectCopy
  matchObjectType
  commitmentType
  reportKind
  routeType
  datasetRequirements {
    ...CanonicalProjectRequirementDatasetFields
  }
  ghgpRequirements {
    ...CanonicalProjectRequirementGhgpFields
  }
}
    ${CanonicalProjectRequirementDatasetFieldsFragmentDoc}
${CanonicalProjectRequirementGhgpFieldsFragmentDoc}`;
export const DisclosureForAdminEditFragmentDoc = gql`
    fragment DisclosureForAdminEdit on Disclosure {
  id
  publicDisclosure {
    ...PublicDisclosureForAdminEdit
  }
  privateDisclosure {
    id
    privateDisclosureType
    companyId
    disclosureQualityScore
  }
  historicalEmissionsYears {
    ...EmissionsYearAllFields
  }
  climateCommitments {
    ...CompanyClimateCommitmentFields
  }
}
    ${PublicDisclosureForAdminEditFragmentDoc}
${EmissionsYearAllFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsFragmentDoc}`;
export const CompanyMatchFieldsFragmentDoc = gql`
    fragment CompanyMatchFields on CompanyMatch {
  id
  score
  nameMatch
  beaMatch
  leiMatch
  isinMatch
  tickerMatch
  countryMatch
  sAndPIdMatch
  company {
    __typename
    ... on Company {
      id
      name
      replacedBy {
        __typename
        ... on Company {
          id
          name
        }
      }
    }
  }
}
    `;
export const CompanyFieldsWithMatchesFragmentDoc = gql`
    fragment CompanyFieldsWithMatches on Company {
  ...CompanyFields
  bestMatchesForCompany {
    ...CompanyMatchFields
  }
}
    ${CompanyFieldsFragmentDoc}
${CompanyMatchFieldsFragmentDoc}`;
export const CustomerTargetSchemaForListViewFragmentDoc = gql`
    fragment CustomerTargetSchemaForListView on CustomerTargetSchema {
  id
  name
  descriptionMd
  rank
  deletedAt
  versions {
    id
    createdAt
    publishedAt
    publishedForTestingAt
    unpublishedAt
    lastEditedByUser {
      id
      name
    }
    transforms {
      id
      testCases {
        id
        kind
      }
    }
  }
  canonicalDatasets {
    id
    name
  }
}
    `;
export const EmailSentForAdminTableFragmentDoc = gql`
    fragment EmailSentForAdminTable on EmailSent {
  id
  kind
  fromEmail
  subject
  htmlContent
  createdAt
  users {
    id
    recipientUser {
      id
      name
      email
    }
    relationshipKind
    recipientEmail
  }
  org {
    id
    name
    demoOrg
  }
  openCount
  clickCount
}
    `;
export const EmeMissingReferenceValueMetadataFieldsFragmentDoc = gql`
    fragment EmeMissingReferenceValueMetadataFields on EmeMissingReferenceValueMetadata {
  slug
  revisionId
  column
  rowId
}
    `;
export const EmeMissingTraceMetadataFieldsFragmentDoc = gql`
    fragment EmeMissingTraceMetadataFields on EmeMissingTraceMetadata {
  missingReferenceValueCitations {
    ...EmeMissingReferenceValueMetadataFields
  }
  missingReferenceValueDescriptions {
    ...EmeMissingReferenceValueMetadataFields
  }
  missingBartValueMetadata {
    businessActivityTypeName
    column
  }
}
    ${EmeMissingReferenceValueMetadataFieldsFragmentDoc}`;
export const EmissionsModelStableBaseFieldsFragmentDoc = gql`
    fragment EmissionsModelStableBaseFields on EmissionsModelStable {
  id
  title
  description
  scope
  createdAt
  tags {
    id
    name
  }
}
    `;
export const EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc = gql`
    fragment EmissionsModelVersionFieldsForImportedDescendants on EmissionsModelVersion {
  id
  title
  description
  changelog
  createdAt
  schemaVersion
  emissionsModelJson
  parameterResolverJson
  stableModel {
    id
    latestPublishedVersion {
      id
      changelog
      createdAt
    }
  }
}
    `;
export const EmissionsModelActiveDraftFieldsForEditorFragmentDoc = gql`
    fragment EmissionsModelActiveDraftFieldsForEditor on EmissionsModelActiveDraft {
  id
  activeAuthor {
    id
    name
  }
  isCheckedOut
  autosavedAt
  emissionsModelJson
  parameterResolverJson
  importedDescendants {
    ...EmissionsModelVersionFieldsForImportedDescendants
  }
  matchers
}
    ${EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc}`;
export const EmissionsModelVersionFieldsForEditorFragmentDoc = gql`
    fragment EmissionsModelVersionFieldsForEditor on EmissionsModelVersion {
  id
  kind
  changelog
  createdAt
  author {
    id
    name
  }
  schemaVersion
  emissionsModelJson
  parameterResolverJson
  activeDraftForVersion {
    ...EmissionsModelActiveDraftFieldsForEditor
  }
  importedDescendants {
    ...EmissionsModelVersionFieldsForImportedDescendants
  }
}
    ${EmissionsModelActiveDraftFieldsForEditorFragmentDoc}
${EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc}`;
export const EmissionsModelReleaseFieldsForEmEditorFragmentDoc = gql`
    fragment EmissionsModelReleaseFieldsForEMEditor on EmissionsModelRelease {
  id
  businessActivityTypeName
  displayName
  lifecyclePhase
  internalNotes
  externalNotes
}
    `;
export const EmissionsModelStableFieldsFragmentDoc = gql`
    fragment EmissionsModelStableFields on EmissionsModelStable {
  ...EmissionsModelStableBaseFields
  latestVersion {
    ...EmissionsModelVersionFieldsForEditor
  }
  org {
    id
    name
  }
  matchers {
    ...EmissionsModelMatcherFields
  }
  releases {
    ...EmissionsModelReleaseFieldsForEMEditor
  }
  isMutable
  isArchived
}
    ${EmissionsModelStableBaseFieldsFragmentDoc}
${EmissionsModelVersionFieldsForEditorFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}
${EmissionsModelReleaseFieldsForEmEditorFragmentDoc}`;
export const EngagementTaskQuestionFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskQuestionFieldsAdmin on EngagementTaskQuestion {
  id
  label
  description
  componentId
  editable
  deletable
  answerIsPrivate
  questionKey
  statusJson
  whyCollectThis
  videoLink
}
    `;
export const EngagementTaskContainerFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskContainerFieldsAdmin on EngagementTaskContainer {
  id
  label
  description
  componentId
  questions {
    ...EngagementTaskQuestionFieldsAdmin
  }
  editable
  statusJson
  sidebarStepLabelOverride
  eyebrowText
  headerImage
}
    ${EngagementTaskQuestionFieldsAdminFragmentDoc}`;
export const EngagementTaskContainerWithSubSectionFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskContainerWithSubSectionFieldsAdmin on EngagementTaskContainer {
  ...EngagementTaskContainerFieldsAdmin
  containers {
    ...EngagementTaskContainerFieldsAdmin
  }
}
    ${EngagementTaskContainerFieldsAdminFragmentDoc}`;
export const EngagementTaskConfigFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskConfigFieldsAdmin on EngagementTaskConfig {
  id
  name
  sidebarTitle
  type
  category
  order
  publishedAt
  rootFacingDescription
  defaultSupplierFacingDescription
  engagementTaskInputs
  optionalEngagementTaskInputs
  canDeleteInAdmin
  containers {
    ...EngagementTaskContainerWithSubSectionFieldsAdmin
  }
  timeNeeded
  configGroup
}
    ${EngagementTaskContainerWithSubSectionFieldsAdminFragmentDoc}`;
export const FeatureFlagDetailsFragmentDoc = gql`
    fragment FeatureFlagDetails on FeatureFlag {
  id
  name
  description
  enabledForAllOrgs
  enabledForOrgs {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const GlobalTagsForFootprintDebuggerFragmentDoc = gql`
    fragment GlobalTagsForFootprintDebugger on GlobalFootprintTag {
  id
  tagName
  recordStartAt
  recordEndAt
}
    `;
export const ReportsForFootprintDebuggerFragmentDoc = gql`
    fragment ReportsForFootprintDebugger on Report {
  id
  reportKind
  publishedAt
  isHidden
}
    `;
export const FootprintSnapshotForFootprintDebuggerFragmentDoc = gql`
    fragment FootprintSnapshotForFootprintDebugger on FootprintSnapshot {
  id
  orgId
  createdAt
  versions {
    id
    isUserVisible
    kind
    newDataCoverageInterval
  }
  footprintInterval
  footprintAssemblyRun {
    id
    workflowId
  }
  marketplaceAllocationInstructions {
    id
  }
  tags {
    id
    tagName
  }
  globalTags {
    ...GlobalTagsForFootprintDebugger
  }
  categoryRules {
    id
  }
  exclusionRules {
    id
  }
  intermediateFileIds
  createdBy {
    id
    name
    isWatershed
  }
  versions {
    id
  }
  stitchedFootprintSnapshotId
  reportsUsingFootprint {
    ...ReportsForFootprintDebugger
  }
  excludedScopesWithPriceGating
  assemblyDebugJson
}
    ${GlobalTagsForFootprintDebuggerFragmentDoc}
${ReportsForFootprintDebuggerFragmentDoc}`;
export const FootprintSnapshotExclusionRuleForFootprintDebuggerFragmentDoc = gql`
    fragment FootprintSnapshotExclusionRuleForFootprintDebugger on FootprintSnapshotExclusionRule {
  id
  footprintExclusionRule {
    id
    recordStartAt
    recordEndAt
  }
  impactedEmissions {
    excludedTco2eQuantity
    excludedMwhConsumed
  }
}
    `;
export const InstructionImpactForFootprintDebuggerFragmentDoc = gql`
    fragment InstructionImpactForFootprintDebugger on MarketplaceAllocationInstructionImpact {
  id
  createdAt
  updatedAt
  amountKwh
  allocatedKwh
}
    `;
export const CleanPowerImpactForFootprintDebuggerFragmentDoc = gql`
    fragment CleanPowerImpactForFootprintDebugger on CleanPowerImpact {
  appliedInstructions {
    ...InstructionImpactForFootprintDebugger
  }
}
    ${InstructionImpactForFootprintDebuggerFragmentDoc}`;
export const IntegrationDataPullFieldsFragmentDoc = gql`
    fragment IntegrationDataPullFields on IntegrationDataPull {
  id
  createdAt
  createdBy {
    id
    name
  }
  totalChunksToFetch
  chunksFetched
  totalFilesToProcess
  filesProcessed
  userUploadedTable {
    id
    sqlTableName
  }
  completed
  backgroundJob {
    id
    state
    error {
      ...SerializableErrorFields
    }
  }
  adtName
  adtUrl
}
    ${SerializableErrorFieldsFragmentDoc}`;
export const DatasourceFieldsFragmentDoc = gql`
    fragment DatasourceFields on Datasource {
  id
  name
  dataset {
    id
    name
  }
}
    `;
export const IntegrationConnectionFieldsFragmentDoc = gql`
    fragment IntegrationConnectionFields on IntegrationConnection {
  id
  createdAt
  updatedAt
  deletedAt
  isActive
  org {
    id
    name
  }
  integrationPartner {
    id
    name
    externalId
  }
  integrationDataPulls {
    edges {
      node {
        ...IntegrationDataPullFields
      }
    }
  }
  datasources {
    edges {
      node {
        ...DatasourceFields
      }
    }
  }
  netsuiteIntegrationSettings {
    id
    transactionTypeFilters
    accountNamesToExclude
    usePostingPeriodForDateFilters
  }
}
    ${IntegrationDataPullFieldsFragmentDoc}
${DatasourceFieldsFragmentDoc}`;
export const IntegrationRequestFieldsFragmentDoc = gql`
    fragment IntegrationRequestFields on IntegrationRequest {
  id
  createdAt
  updatedAt
  requestMethod
  requestUrl
  responseStatus
  integrationConnectionId
  orgId
  requestBody
  responseFileMetadata {
    id
    gcloudStorageUrl
  }
}
    `;
export const IntegrationConnectionListFieldsFragmentDoc = gql`
    fragment IntegrationConnectionListFields on IntegrationConnection {
  id
  org {
    id
    name
  }
  integrationPartner {
    id
    name
    dataType
  }
  createdAt
  updatedAt
  deletedAt
  isActive
}
    `;
export const MarketplaceProjectForArchetypeFieldsFragmentDoc = gql`
    fragment MarketplaceProjectForArchetypeFields on MarketplaceProject {
  id
  name
}
    `;
export const MarketplaceProjectOfferingFieldsFragmentDoc = gql`
    fragment MarketplaceProjectOfferingFields on MarketplaceProjectOffering {
  __typename
  id
  supplier {
    id
    name
  }
  project {
    id
    name
    deletedAt
    status
    archetype {
      id
      name
      unit
      mechanism
    }
  }
  vintage
  createdAt
  availability
  priceUsdCents
  priceWithFeeUsdCents
  noteMd
}
    `;
export const MarketplaceProjectArchetypeFieldsFragmentDoc = gql`
    fragment MarketplaceProjectArchetypeFields on MarketplaceProjectArchetype {
  id
  kind
  unit
  isUserVisible
  name
  descriptionMd
  summaryMd
  imageUrl
  bannerImageUrl
  certificateBlurb
  certificateColor
  additionalityRating
  permanenceRating
  projects {
    edges {
      node {
        ...MarketplaceProjectForArchetypeFields
      }
    }
  }
  offerings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
}
    ${MarketplaceProjectForArchetypeFieldsFragmentDoc}
${MarketplaceProjectOfferingFieldsFragmentDoc}`;
export const MarketplaceProjectArchetypeListFieldsFragmentDoc = gql`
    fragment MarketplaceProjectArchetypeListFields on MarketplaceProjectArchetype {
  __typename
  id
  name
  isUserVisible
  mechanism
  kind
  unit
  summaryMd
  priceUsdCentsMin
  priceUsdCentsMax
  projects {
    edges {
      node {
        ...MarketplaceProjectForArchetypeFields
      }
    }
  }
}
    ${MarketplaceProjectForArchetypeFieldsFragmentDoc}`;
export const MarketplaceDeveloperFieldsFragmentDoc = gql`
    fragment MarketplaceDeveloperFields on MarketplaceDeveloper {
  id
  name
  imageUrl
  url
  descriptionMd
  deletedAt
}
    `;
export const MarketplaceDeveloperWithProjectsFieldsFragmentDoc = gql`
    fragment MarketplaceDeveloperWithProjectsFields on MarketplaceDeveloper {
  ...MarketplaceDeveloperFields
  projects {
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}`;
export const MarketplaceDeveloperListFieldsFragmentDoc = gql`
    fragment MarketplaceDeveloperListFields on MarketplaceDeveloper {
  ...MarketplaceDeveloperFields
}
    ${MarketplaceDeveloperFieldsFragmentDoc}`;
export const MarketplaceDocumentForDetailPageFragmentDoc = gql`
    fragment MarketplaceDocumentForDetailPage on MarketplaceDocument {
  id
  name
  createdAt
  deletedAt
  kind
  noteMd
  uploader {
    id
    name
  }
  downloadUrl
  purchase {
    id
    name
  }
  purchaseLineItem {
    id
    purchase {
      id
      name
    }
    offering {
      id
      vintage
      project {
        id
        name
      }
      supplier {
        id
        name
      }
    }
  }
}
    `;
export const MarketplaceSupplierForMarketplacePurchaseLineItemFragmentDoc = gql`
    fragment MarketplaceSupplierForMarketplacePurchaseLineItem on MarketplaceSupplier {
  id
  name
}
    `;
export const MarketplacePurchaseLineItemFieldsForMarketplaceDocumentListFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFieldsForMarketplaceDocumentList on MarketplacePurchaseLineItem {
  id
  offering {
    id
    supplier {
      ...MarketplaceSupplierForMarketplacePurchaseLineItem
    }
  }
}
    ${MarketplaceSupplierForMarketplacePurchaseLineItemFragmentDoc}`;
export const MarketplaceDocumentListFieldsFragmentDoc = gql`
    fragment MarketplaceDocumentListFields on MarketplaceDocument {
  createdAt
  deletedAt
  id
  kind
  name
  downloadUrl
  org {
    id
    name
  }
  uploader {
    id
    name
  }
  purchaseLineItem {
    ...MarketplacePurchaseLineItemFieldsForMarketplaceDocumentList
  }
  purchase {
    id
    lineItems {
      edges {
        node {
          ...MarketplacePurchaseLineItemFieldsForMarketplaceDocumentList
        }
      }
    }
  }
}
    ${MarketplacePurchaseLineItemFieldsForMarketplaceDocumentListFragmentDoc}`;
export const MarketplacePurchaseListFieldsFragmentDoc = gql`
    fragment MarketplacePurchaseListFields on MarketplacePurchase {
  id
  org {
    id
    name
    demoOrg
  }
  createdAt
  updatedAt
  name
  totalCostUsdCents
  totalFeeUsdCents
  status
  offerExpiration
  eacMetadata {
    id
    requestNotes
    interval
    ghgCategoryIds
    marketTco2e
  }
  waitingOnParty
  customerStatus
  noteMd
  documents {
    edges {
      node {
        id
        kind
      }
    }
  }
}
    `;
export const EacPriceEstimateFieldsFragmentDoc = gql`
    fragment EacPriceEstimateFields on EacPriceEstimate {
  id
  updatedAt
  country
  alternativeCountries
  minCreditPurchase
  standard
  priceEstimateUsdCents
  defaultProject {
    id
    name
  }
}
    `;
export const MarketplaceSupplierForProjectFieldsFragmentDoc = gql`
    fragment MarketplaceSupplierForProjectFields on MarketplaceSupplier {
  id
  name
}
    `;
export const MarketplaceProjectFieldsFragmentDoc = gql`
    fragment MarketplaceProjectFields on MarketplaceProject {
  id
  name
  deletedAt
  status
  internalNotesMd
  registryId
  archetype {
    id
    name
  }
  developer {
    ...MarketplaceDeveloperFields
  }
  supplier {
    ...MarketplaceSupplierForProjectFields
  }
  offerings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}
${MarketplaceSupplierForProjectFieldsFragmentDoc}
${MarketplaceProjectOfferingFieldsFragmentDoc}`;
export const MarketplaceProjectListFieldsFragmentDoc = gql`
    fragment MarketplaceProjectListFields on MarketplaceProject {
  __typename
  id
  name
  status
  registryId
  archetype {
    id
    name
  }
  developer {
    ...MarketplaceDeveloperFields
  }
  supplier {
    ...MarketplaceSupplierForProjectFields
  }
  offerings(first: 1) {
    edges {
      node {
        id
      }
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}
${MarketplaceSupplierForProjectFieldsFragmentDoc}`;
export const MarketplacePortfolioItemFieldsForDetailPageFragmentDoc = gql`
    fragment MarketplacePortfolioItemFieldsForDetailPage on MarketplacePortfolioItem {
  id
  amount
  priceUsdCents
  fraction
  archetype {
    id
    name
    unit
  }
  portfolio {
    id
    interval
  }
}
    `;
export const TrackedEditorFieldsFragmentDoc = gql`
    fragment TrackedEditorFields on TrackedEditor {
  __typename
  ... on Person {
    id
    name
  }
}
    `;
export const MarketplacePurchaseLineItemFieldsFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFields on MarketplacePurchaseLineItem {
  id
  org {
    id
    name
    demoOrg
  }
  createdAt
  updatedAt
  purchase {
    id
    name
  }
  archetype {
    id
    name
  }
  project {
    id
    name
    supplier {
      id
      name
    }
  }
  offering {
    id
    vintage
  }
  documents {
    edges {
      node {
        id
        kind
      }
    }
  }
  cleanPowerContractualInstrument
  vintage
  amount
  amountUnit
  mechanism
  coverageInterval
  unitPriceUsdCents
  unitFeeUsdCents
  deliveryDate
  closeDate
  status
  waitingOnParty
  noteMd
  eacMetadata {
    id
    countryWithEmissions
    countryWithEacs
    numEacsRequested
    mwh
    marketTco2e
  }
  appliedToFootprint
}
    `;
export const MarketplacePurchaseFieldsFragmentDoc = gql`
    fragment MarketplacePurchaseFields on MarketplacePurchase {
  id
  org {
    id
    name
    productWaitlistTypes
  }
  portfolio {
    id
    name
    targetAmount
    notes
    footprintTco2e
    budgetUsdCents
    interval
    investInRd
    targetAmount
    targetFraction
    offsetTargetAmount
    offsetTargetFraction
    cleanPowerTargetAmount
    portfolioItems {
      edges {
        node {
          ...MarketplacePortfolioItemFieldsForDetailPage
        }
      }
    }
  }
  createdAt
  updatedAt
  deletedAt
  offerExpiration
  name
  status
  waitingOnParty
  customerStatus
  noteMd
  totalCostUsdCents
  totalFeeUsdCents
  editor {
    ...TrackedEditorFields
  }
  customerUser {
    id
    name
  }
  history {
    edges {
      node {
        id
        editor {
          ...TrackedEditorFields
        }
        name
        status
        offerExpiration
        recordStartAt
        recordEndAt
      }
    }
  }
  lineItems {
    edges {
      node {
        ...MarketplacePurchaseLineItemFields
      }
    }
  }
  eacMetadata {
    id
    updatedAt
    requestNotes
    interval
    ghgCategoryIds
    marketTco2e
  }
}
    ${MarketplacePortfolioItemFieldsForDetailPageFragmentDoc}
${TrackedEditorFieldsFragmentDoc}
${MarketplacePurchaseLineItemFieldsFragmentDoc}`;
export const MarketplaceDocumentFieldsForTableCardFragmentDoc = gql`
    fragment MarketplaceDocumentFieldsForTableCard on MarketplaceDocument {
  id
  name
  createdAt
  kind
  uploader {
    id
    name
  }
}
    `;
export const MarketplacePurchaseFieldsForDetailPageFragmentDoc = gql`
    fragment MarketplacePurchaseFieldsForDetailPage on MarketplacePurchase {
  ...MarketplacePurchaseFields
  lineItems {
    edges {
      node {
        id
        name
      }
    }
  }
  documents {
    edges {
      node {
        ...MarketplaceDocumentFieldsForTableCard
      }
    }
  }
}
    ${MarketplacePurchaseFieldsFragmentDoc}
${MarketplaceDocumentFieldsForTableCardFragmentDoc}`;
export const AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc = gql`
    fragment AllocationInstructionFieldsForMarketplacePurchaseLineItem on MarketplaceAllocationInstruction {
  id
  createdAt
  deletedAt
  amountKwh
  isZeroedOut
  interval
  locationCity
  locationGrid
  locationState
  locationCountry
  buildingName
  vendorEntity
  vendorSubentity
  product
  categoryId
  ghgCategoryId
  footprintKind
  subcategoryId
  results {
    edges {
      node {
        id
        isZeroedOut
        amountAllocatedKwh
      }
    }
  }
}
    `;
export const MarketplacePurchaseLineItemForPurchaseLineItemPageFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemForPurchaseLineItemPage on MarketplacePurchaseLineItem {
  id
  name
  org {
    id
    name
  }
  createdAt
  updatedAt
  deletedAt
  purchase {
    id
    name
  }
  archetype {
    id
    name
  }
  project {
    id
    name
  }
  offering {
    id
    vintage
  }
  cleanPowerContractualInstrument
  vintage
  amount
  amountUnit
  mechanism
  coverageInterval
  unitPriceUsdCents
  unitFeeUsdCents
  deliveryDate
  closeDate
  status
  waitingOnParty
  noteMd
  documents {
    edges {
      node {
        ...MarketplaceDocumentFieldsForTableCard
      }
    }
  }
  allocations {
    edges {
      node {
        ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
      }
    }
  }
  eacMetadata {
    id
    updatedAt
    countryWithEmissions
    countryWithEacs
    numEacsRequested
    mwh
    marketTco2e
  }
}
    ${MarketplaceDocumentFieldsForTableCardFragmentDoc}
${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;
export const MarketplaceProjectForSupplierPageFragmentDoc = gql`
    fragment MarketplaceProjectForSupplierPage on MarketplaceProject {
  id
  name
  archetype {
    id
    name
  }
  status
}
    `;
export const MarketplaceSupplierFieldsFragmentDoc = gql`
    fragment MarketplaceSupplierFields on MarketplaceSupplier {
  id
  name
  projects {
    edges {
      node {
        ...MarketplaceProjectForSupplierPage
      }
    }
  }
  offerings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
  pointsOfContact {
    __typename
    id
    name
    email
  }
  watershedEmployees {
    __typename
    id
    name
    email
  }
}
    ${MarketplaceProjectForSupplierPageFragmentDoc}
${MarketplaceProjectOfferingFieldsFragmentDoc}`;
export const MarketplaceSupplierListFieldsFragmentDoc = gql`
    fragment MarketplaceSupplierListFields on MarketplaceSupplier {
  __typename
  id
  name
  offerings {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const DataRegistryMigratorRunSchemaFragmentDoc = gql`
    fragment DataRegistryMigratorRunSchema on DataRegistryMigratorRun {
  id
  status
  createdAt
  migrator {
    id
  }
  inputTable {
    id
    name
    isDraft
    createdAt
  }
  outputTable {
    id
    name
    isDraft
  }
  organization {
    id
    name
  }
  errorMessage
}
    `;
export const MeasurementVerificationItemForQuestionContentFragmentDoc = gql`
    fragment MeasurementVerificationItemForQuestionContent on MeasurementVerificationItem {
  id
  datasetName
  dataset {
    id
    datasources {
      id
      name
      summaryWithMetadata {
        id
        summaries {
          id
          signedUrl
        }
      }
    }
  }
  measurementProject {
    id
    coverageInterval
  }
}
    `;
export const DataIssueWithCommentsFieldsFragmentDoc = gql`
    fragment DataIssueWithCommentsFields on DataIssue {
  id
  title
  description
  state
  createdAt
  isCustomerInitiated
  measurementProject {
    id
    completedAt
  }
  comments(isPublished: true) {
    edges {
      node {
        id
        person {
          id
          name
          displayName
          isWatershedEmployee
          isWatershedContractor
        }
        message
        isPublished
        createdAt
      }
    }
  }
}
    `;
export const FrozenDatasourceSummaryContentFragmentDoc = gql`
    fragment FrozenDatasourceSummaryContent on FrozenDatasourceSummary {
  datasource {
    id
    name
  }
  isStale
  signedUrl
}
    `;
export const FileForMeasurementVerificationQuestionContentFragmentDoc = gql`
    fragment FileForMeasurementVerificationQuestionContent on FileMetadata {
  id
  name
  uploader {
    id
    name
  }
  userUploadTask {
    id
    datasource {
      id
      name
    }
  }
}
    `;
export const MeasurementVerificationItemQuestionForQuestionContentFragmentDoc = gql`
    fragment MeasurementVerificationItemQuestionForQuestionContent on MeasurementVerificationItemQuestion {
  id
  contentKind
  contentTitle
  contentSubtitle
  discussion {
    ...DataIssueWithCommentsFields
  }
  questionKind
  questionPrompt
  answerText
  status
  publisher {
    id
    name
  }
  rank
  contentMetadata {
    ... on MeasurementVerificationItemContentMetadataQuantityTrend {
      __typename
      id
      aggregateField
      filters {
        field
        operator
        value
      }
      anomaly {
        highlightMonth
      }
      frozenDatasourceSummaries {
        ...FrozenDatasourceSummaryContent
      }
    }
    ... on MeasurementVerificationItemContentMetadataTopAccounts {
      __typename
      id
      frozenDatasourceSummaries {
        ...FrozenDatasourceSummaryContent
      }
    }
    ... on MeasurementVerificationItemContentMetadataFileOverlaps {
      __typename
      id
      files {
        ...FileForMeasurementVerificationQuestionContent
      }
    }
  }
}
    ${DataIssueWithCommentsFieldsFragmentDoc}
${FrozenDatasourceSummaryContentFragmentDoc}
${FileForMeasurementVerificationQuestionContentFragmentDoc}`;
export const MeasurementVerificationQuestionForAdminFragmentDoc = gql`
    fragment MeasurementVerificationQuestionForAdmin on MeasurementVerificationItemQuestion {
  id
  section
  questionKind
  questionPrompt
  answerText
  status
  contentKind
  contentMetadataJson
  contentTitle
  contentSubtitle
  createdAt
  updatedAt
  ...MeasurementVerificationItemQuestionForQuestionContent
  ...ContextSeedObjectFields
}
    ${MeasurementVerificationItemQuestionForQuestionContentFragmentDoc}
${ContextSeedObjectFieldsFragmentDoc}`;
export const MeasurementVerificationItemDetailForAdminFragmentDoc = gql`
    fragment MeasurementVerificationItemDetailForAdmin on MeasurementVerificationItem {
  id
  name
  description
  datasetName
  dataset {
    id
    name
    canonicalDataset {
      id
      kind
    }
  }
  measurementProject {
    id
    name
  }
  assignee {
    id
    name
  }
  ...MeasurementVerificationItemForQuestionContent
  questions(includeDrafts: true) {
    edges {
      node {
        ...MeasurementVerificationQuestionForAdmin
      }
    }
  }
}
    ${MeasurementVerificationItemForQuestionContentFragmentDoc}
${MeasurementVerificationQuestionForAdminFragmentDoc}`;
export const DatasetForDataReviewPageFragmentDoc = gql`
    fragment DatasetForDataReviewPage on Dataset {
  id
  name
  datasources {
    id
    name
  }
  canonicalDataset {
    id
    kind
  }
}
    `;
export const MeasurementProjectForDataReviewPageFragmentDoc = gql`
    fragment MeasurementProjectForDataReviewPage on MeasurementProject {
  id
  name
  coverageInterval
  ...ContextSeedObjectFields
}
    ${ContextSeedObjectFieldsFragmentDoc}`;
export const MeasurementVerificationItemForAdminFragmentDoc = gql`
    fragment MeasurementVerificationItemForAdmin on MeasurementVerificationItem {
  id
  name
  description
  dataset {
    id
    name
  }
  assignee {
    id
    name
  }
  questions(includeDrafts: true) {
    edges {
      node {
        id
        status
      }
    }
  }
}
    `;
export const MeasurementProjectFieldsForMeasurementVerificationCardFragmentDoc = gql`
    fragment MeasurementProjectFieldsForMeasurementVerificationCard on MeasurementProject {
  id
  name
  active
  completedAt
  measurementVerificationItems(includeDrafts: true) {
    edges {
      node {
        ...MeasurementVerificationItemForAdmin
      }
    }
  }
}
    ${MeasurementVerificationItemForAdminFragmentDoc}`;
export const UserUploadFieldsForTaskTableFragmentDoc = gql`
    fragment UserUploadFieldsForTaskTable on UserUpload {
  id
  name
  deletedAt
  allAttempts {
    id
    createdAt
    size
    gcloudStorageUrl
    deletedAt
    uploader {
      id
      name
    }
    userUploadedTables {
      id
      sqlTableName
      shouldIgnore
    }
  }
  latestAttempt {
    id
    name
    gcloudStorageUrl
    userUploadedTables {
      id
      shouldIgnore
    }
  }
}
    `;
export const UserUploadTaskUploadsFieldsFragmentDoc = gql`
    fragment UserUploadTaskUploadsFields on UserUploadTask {
  id
  state
  measurementProject {
    id
    name
    coverageInterval
  }
  userUploads(
    includeDeleted: true
    excludeProcessingModes: [IdiRawFile, NoProcessing]
  ) {
    edges {
      node {
        ...UserUploadFieldsForTaskTable
      }
    }
  }
}
    ${UserUploadFieldsForTaskTableFragmentDoc}`;
export const DatasourceForDatasetPageFragmentDoc = gql`
    fragment DatasourceForDatasetPage on Datasource {
  id
  name
  importInstructionsMd
  refreshCadence
  isUserConfirmed
  customUsedFor
  customPotentialOverlaps
  dataset {
    id
  }
  canonicalDatasource {
    id
    name
  }
  userUploadTasks {
    edges {
      node {
        id
        ...UserUploadTaskUploadsFields
      }
    }
  }
  instructions {
    ...InstructionsBundleBaseFields
  }
  notesFromWatershedMd
  customerTargetSchemaIds
}
    ${UserUploadTaskUploadsFieldsFragmentDoc}
${InstructionsBundleBaseFieldsFragmentDoc}`;
export const MeasurementTimelineForOrgPageFragmentDoc = gql`
    fragment MeasurementTimelineForOrgPage on MeasurementTimeline {
  id
  kickoff
  deadline
}
    `;
export const DatasetForMeasurementPageFragmentDoc = gql`
    fragment DatasetForMeasurementPage on Dataset {
  id
  name
  materialCsrdStandards
}
    `;
export const MeasurementTaskObjectDatasetFieldsFragmentDoc = gql`
    fragment MeasurementTaskObjectDatasetFields on Dataset {
  ...DatasetForMeasurementPage
  datasources {
    id
    name
  }
}
    ${DatasetForMeasurementPageFragmentDoc}`;
export const DataIssueWithCommentsForMeasurementPageFragmentDoc = gql`
    fragment DataIssueWithCommentsForMeasurementPage on DataIssue {
  comments {
    edges {
      node {
        id
        person {
          id
          isWatershedEmployee
          isWatershedContractor
        }
        createdAt
        isPublished
      }
    }
  }
}
    `;
export const DatasourceForMeasurementPageFragmentDoc = gql`
    fragment DatasourceForMeasurementPage on Datasource {
  id
  name
  dataset {
    ...DatasetForMeasurementPage
  }
}
    ${DatasetForMeasurementPageFragmentDoc}`;
export const FinancialsReviewItemForAssignmentPermissionCheckFragmentDoc = gql`
    fragment FinancialsReviewItemForAssignmentPermissionCheck on FinancialsReviewItem {
  id
  group {
    __typename
    id
    datasources {
      ...DatasourceForMeasurementPage
    }
  }
}
    ${DatasourceForMeasurementPageFragmentDoc}`;
export const ApproverFieldsForMeasurementPageFragmentDoc = gql`
    fragment ApproverFieldsForMeasurementPage on UserWithApprovalStatus {
  user {
    id
    name
    displayName
  }
  approvalStatus
}
    `;
export const MeasurementObjectForMeasurementPageFragmentDoc = gql`
    fragment MeasurementObjectForMeasurementPage on MeasurementTaskObject {
  __typename
  id
  ... on MeasurementProjectDataset {
    modelId
    isCompleted
    dataset {
      ...MeasurementTaskObjectDatasetFields
    }
  }
  ... on MeasurementVerificationItem {
    maybeDataset: dataset {
      ...MeasurementTaskObjectDatasetFields
    }
    mviStatus: status
  }
  ... on DataIssue {
    ...DataIssueWithCommentsForMeasurementPage
    linkedObject {
      __typename
      ... on UserUploadTask {
        id
        datasource {
          ...DatasourceForMeasurementPage
        }
      }
      ... on MeasurementVerificationItemQuestion {
        id
        item {
          __typename
          id
          maybeDataset: dataset {
            ...DatasetForMeasurementPage
            datasources {
              ...DatasourceForMeasurementPage
            }
          }
        }
      }
      ... on FinancialsReviewItem {
        ...FinancialsReviewItemForAssignmentPermissionCheck
      }
    }
  }
  ... on UserUploadTask {
    state
    approvers {
      ...ApproverFieldsForMeasurementPage
    }
    datasource {
      ...DatasourceForMeasurementPage
      hasIntegration
      dataset {
        ...DatasetForMeasurementPage
        canonicalDataset {
          id
          kind
        }
      }
    }
  }
  ... on FinancialsReviewItem {
    ...FinancialsReviewItemForAssignmentPermissionCheck
  }
  ... on FinancialsReviewGroup {
    datasources {
      ...DatasourceForMeasurementPage
    }
  }
  ... on MeasurementDataMappingTask {
    dataset {
      ...MeasurementTaskObjectDatasetFields
    }
  }
}
    ${MeasurementTaskObjectDatasetFieldsFragmentDoc}
${DataIssueWithCommentsForMeasurementPageFragmentDoc}
${DatasourceForMeasurementPageFragmentDoc}
${DatasetForMeasurementPageFragmentDoc}
${FinancialsReviewItemForAssignmentPermissionCheckFragmentDoc}
${ApproverFieldsForMeasurementPageFragmentDoc}`;
export const MeasurementTaskForOrgPageFragmentDoc = gql`
    fragment MeasurementTaskForOrgPage on MeasurementTask {
  id
  title
  link
  assignees {
    id
    name
  }
  isAssignedToWatershed
  statusSimplified
  object {
    ...MeasurementObjectForMeasurementPage
  }
}
    ${MeasurementObjectForMeasurementPageFragmentDoc}`;
export const MeasurementParentTaskForOrgPageFragmentDoc = gql`
    fragment MeasurementParentTaskForOrgPage on MeasurementParentTask {
  ...MeasurementTaskForOrgPage
  subTasks {
    ...MeasurementTaskForOrgPage
  }
  taskGroup
  dataset {
    id
    name
  }
}
    ${MeasurementTaskForOrgPageFragmentDoc}`;
export const MeasurementVerificationItemForOrgPageFragmentDoc = gql`
    fragment MeasurementVerificationItemForOrgPage on MeasurementVerificationItem {
  id
  status
  dataset {
    id
    canonicalDataset {
      id
      kind
    }
  }
  assignee {
    id
  }
}
    `;
export const UserUploadTaskFieldsForOrgPageFragmentDoc = gql`
    fragment UserUploadTaskFieldsForOrgPage on UserUploadTask {
  __typename
  id
  state
  datasource {
    id
    name
  }
  userUploads {
    edges {
      node {
        id
      }
    }
  }
  assignee {
    id
    name
  }
}
    `;
export const OrganizationDatasetsPageDatasetFragmentDoc = gql`
    fragment OrganizationDatasetsPageDataset on Dataset {
  id
  name
  explainer
  customUsedFor
  customPotentialOverlaps
  canonicalDataset {
    id
    name
    kind
  }
  datasources {
    id
    name
    customUsedFor
    customPotentialOverlaps
    customerTargetSchemaIds
  }
}
    `;
export const MeasurementProjectDatasetFieldsForOrgPageFragmentDoc = gql`
    fragment MeasurementProjectDatasetFieldsForOrgPage on MeasurementProjectDataset {
  id
  dataset {
    ...OrganizationDatasetsPageDataset
  }
  isCompleted
}
    ${OrganizationDatasetsPageDatasetFragmentDoc}`;
export const MeasurementProjectFieldsForOrgPageFragmentDoc = gql`
    fragment MeasurementProjectFieldsForOrgPage on MeasurementProject {
  __typename
  id
  name
  active
  status
  completedAt
  coverageStartDate
  coverageEndDate
  coverageInterval
  deadline
  measurementTimeline {
    ...MeasurementTimelineForOrgPage
  }
  measurementTasks {
    ...MeasurementParentTaskForOrgPage
  }
  measurementVerificationItems {
    edges {
      node {
        ...MeasurementVerificationItemForOrgPage
      }
    }
  }
  userUploadTasks {
    edges {
      node {
        ...UserUploadTaskFieldsForOrgPage
      }
    }
  }
  measurementProjectDatasets {
    ...MeasurementProjectDatasetFieldsForOrgPage
  }
  supportedValueMappingRules
}
    ${MeasurementTimelineForOrgPageFragmentDoc}
${MeasurementParentTaskForOrgPageFragmentDoc}
${MeasurementVerificationItemForOrgPageFragmentDoc}
${UserUploadTaskFieldsForOrgPageFragmentDoc}
${MeasurementProjectDatasetFieldsForOrgPageFragmentDoc}`;
export const EmployeeReportForAdminFragmentDoc = gql`
    fragment EmployeeReportForAdmin on EmployeeReport {
  id
  programKind
  visibility
  footprintInterval
  marketplaceInterval
  markdownCardInfo(shouldInterpolate: false) {
    title
    contentMd
  }
  climateProgramInfo(shouldInterpolate: false) {
    hasCustomizedSteps
    steps {
      title
      descriptionMd
    }
  }
  climateProgramInfoDefault: climateProgramInfo(
    shouldInterpolate: false
    includeCustomSteps: false
  ) {
    steps {
      title
      descriptionMd
    }
  }
  netZeroYear
}
    `;
export const SharedFileForFilesTableFragmentDoc = gql`
    fragment SharedFileForFilesTable on SharedFile {
  id
  createdAt
  expiresAt
  user {
    id
    name
  }
  watershedEmployee {
    id
    name
  }
  fileMetadata {
    id
    name
    deletedAt
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
  measurementProject {
    id
    name
  }
}
    `;
export const SharedFileRequestForTableFragmentDoc = gql`
    fragment SharedFileRequestForTable on SharedFileRequest {
  id
  createdAt
  user {
    id
    name
  }
  fulfilledBy {
    id
    name
  }
  fulfilledAt
  fileMetadata {
    id
    name
    userUploadTask {
      id
      measurementProject {
        id
        name
      }
    }
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
}
    `;
export const ActivityDataTableFieldsFragmentDoc = gql`
    fragment ActivityDataTableFields on ActivityDataTable {
  id
  name
  primaryDatasource {
    id
    name
    hasIntegration
    activeIntegrationConnection {
      id
    }
    hasIntegrationOption
  }
  datasets {
    name
    id
    name
    canonicalDataset {
      id
      kind
    }
  }
  datasources {
    id
    name
    hasIntegration
    activeIntegrationConnection {
      id
    }
    hasIntegrationOption
  }
  lastRefreshedAt
  draftCadtExtractor {
    id
    createdAt
  }
  draftBartExtractor {
    id
    createdAt
    pushedBuildings
  }
  publishedExtractor {
    id
    createdAt
    userVisible
    facilitiesSurveyStatus
    bartActivityType
  }
  filterInterval
  connectedToAutoBart
  autoBartKind
  autoBartTaskApprovalState
  measurementProject {
    id
    name
  }
  reviews {
    id
    reviewer {
      id
      name
    }
  }
  stage
}
    `;
export const FootprintSnapshotOrgFootprintsFieldsFragmentDoc = gql`
    fragment FootprintSnapshotOrgFootprintsFields on FootprintSnapshot {
  id
  createdAt
  descriptionMd
  publishedAt
  publishedHiddenAt
  publishedDraftAt
  dataRegistryTableName
  configs {
    pipelineResultId
    coverageInterval
  }
  footprint {
    id
    name
    nextFootprintVersionIdentifier
  }
  versions {
    id
    createdAt
    isUserVisible
    kind
    userChangelogMd
    publishableUserChangelogMd
    internalNotesMd
    isUserChangelogHidden
    originalFootprintSnapshotId
  }
  isStableRefreshAvailable
  extraAdeIdsForIntDenoms
  extraAdeIdsForNonEmissiveActivity
  marketplaceAllocationInstructionIds
  isManuallySaved
  tags {
    id
    tagName
    description
    watershedEditor {
      id
      name
      email
    }
  }
}
    `;
export const FootprintVersionFieldsFragmentDoc = gql`
    fragment FootprintVersionFields on FootprintVersion {
  id
  orgId
  createdAt
  kind
  originalFootprintSnapshotId
  internalNotesMd
  userChangelogMd
  publishableUserChangelogMd
  isUserChangelogHidden
  isUserVisible
  originalFootprintSnapshot {
    ...FootprintSnapshotOrgFootprintsFields
  }
}
    ${FootprintSnapshotOrgFootprintsFieldsFragmentDoc}`;
export const DatasetFieldsForOrgPageFragmentDoc = gql`
    fragment DatasetFieldsForOrgPage on Dataset {
  id
  name
  datasources {
    id
    name
  }
  canonicalDataset {
    id
    kind
  }
}
    `;
export const MeasurementProjectForFootprintPageFragmentDoc = gql`
    fragment MeasurementProjectForFootprintPage on MeasurementProject {
  id
  name
  active
  coverageInterval
  ...ContextSeedObjectFields
}
    ${ContextSeedObjectFieldsFragmentDoc}`;
export const PipelineResultForOrgFragmentDoc = gql`
    fragment PipelineResultForOrg on PipelineResult {
  id
  name
  pipelineKind
  orgId
  createdAt
  coverageInterval
  runUserId
  pipelineRunConfig
  deletedAt
  summary {
    format
    emissions {
      month
      kgCo2e
      businessCategory
      adtName
      footprintKind
    }
    warnings {
      blocking
      message
    }
  }
  parquetDataId
  prkFileMetadataId
  isUsedInLatestDraft
  isUsedInLatestPublish
  emissionsModelEvaluations {
    id
    emissionsModelVersion {
      id
      stableModel {
        id
        title
      }
    }
    activityDataExtractor {
      id
      activityDataTable {
        id
        name
      }
    }
    evaluatedExtendedBart {
      fileMetadataId
    }
    uniqueBartRowCount
    bartTotalRowCount
    evaluatedBartId
    evaluatedEmtaFmdId
  }
}
    `;
export const AdminUserFieldsForOrgPageFragmentDoc = gql`
    fragment AdminUserFieldsForOrgPage on User {
  id
  name
  displayName
  email
  loginActivated
  consecutiveLoginFailures
  didLatestEmailBounce
  orgAccessId(orgId: $id)
}
    `;
export const AdminUsersForOrgPageFragmentDoc = gql`
    fragment AdminUsersForOrgPage on Organization {
  __typename
  adminUsers: users(filters: {isAdmin: true, includeWatershedEmployees: true}) {
    edges {
      node {
        ...AdminUserFieldsForOrgPage
      }
    }
  }
}
    ${AdminUserFieldsForOrgPageFragmentDoc}`;
export const WatershedEmployeeFieldsForOrgPageFragmentDoc = gql`
    fragment WatershedEmployeeFieldsForOrgPage on WatershedEmployee {
  __typename
  id
  name
  email
}
    `;
export const OrgPointOfContactFieldsForOrgPageFragmentDoc = gql`
    fragment OrgPointOfContactFieldsForOrgPage on OrgPointOfContact {
  __typename
  id
  kind
  watershedEmployee {
    ...WatershedEmployeeFieldsForOrgPage
  }
  createdAt
  deletedAt
}
    ${WatershedEmployeeFieldsForOrgPageFragmentDoc}`;
export const OrgProfileForOnboardingFlowFragmentDoc = gql`
    fragment OrgProfileForOnboardingFlow on OrgProfile {
  id
  name
  fiscalYearStartMonth
  country
  industry
  isCompanyDataConfirmed
  hasMeasuredFootprintOutsideOfWatershed
  lastNonWatershedMeasurementYearString
  hasClimateCommitments
  climateCommitments
  otherClimateCommitment
  climateGoals
  climateMotivation
  completedAt
  completedBy {
    id
    name
  }
  updatedAt
  updatedBy {
    id
    name
  }
}
    `;
export const OrganizationFieldsForOrgPageFragmentDoc = gql`
    fragment OrganizationFieldsForOrgPage on Organization {
  __typename
  id
  name
  bigqueryDatasetName
  legalName
  helpChannel
  domains
  passwordAuthDisabled
  notionUrl
  magicLinkDisabled
  demoOrg
  region
  isWatershed
  watershedPlan
  watershedPlanLegacy
  workosOrgId
  fiscalYearStartMonth
  currency
  iconRemotePath
  logoRemotePath
  country
  productWaitlistTypes
  lifecycle {
    nextStage
    completedStage
    kickoffBlurb
  }
  pointsOfContact {
    ...OrgPointOfContactFieldsForOrgPage
  }
  pointsOfContactIncludingDeleted {
    ...OrgPointOfContactFieldsForOrgPage
  }
  apiKeys {
    id
    secret
  }
  workosConnections {
    id
    name
    connectionType
  }
  company {
    id
    name
  }
  canAccessCorporate
  canAccessFinance
  testOrg
  stagingOrg
  watershedSlackChannelId
  deletedAt
  profile {
    ...OrgProfileForOnboardingFlow
  }
}
    ${OrgPointOfContactFieldsForOrgPageFragmentDoc}
${OrgProfileForOnboardingFlowFragmentDoc}`;
export const MeasurementProjectForEditDataIssuesPageFragmentDoc = gql`
    fragment MeasurementProjectForEditDataIssuesPage on MeasurementProject {
  id
  name
  coverageInterval
  active
  dataIssues {
    ...DataIssueForEditDataIssuesPageFields
  }
}
    ${DataIssueForEditDataIssuesPageFieldsFragmentDoc}`;
export const EmailContentsFieldsFragmentDoc = gql`
    fragment EmailContentsFields on EmailContents {
  recipients {
    email
    name
  }
  ccs {
    email
    name
  }
  sender {
    email
    name
  }
  subject
  bodyHtml
  bccs {
    email
    name
  }
}
    `;
export const EmailContentWithUserRecipientFieldsFragmentDoc = gql`
    fragment EmailContentWithUserRecipientFields on EmailContentsWithUserRecipient {
  userId
  emailContents {
    ...EmailContentsFields
  }
}
    ${EmailContentsFieldsFragmentDoc}`;
export const MappingAssumptionFragmentDoc = gql`
    fragment MappingAssumption on AssumptionRecordForAdmin {
  id
  assumptionId
  authorExplanation: assumptionSourceMemo
  inputEmissionsSpecifier
  inputEmissionsSubspecifier
  outputEmissionsSpecifier
  outputEmissionsSubspecifier
  vendorEntity
  startMonth
  endMonth
  excludeFromResults
  ghgCategoryId
  categoryId
  subcategoryId
  description
}
    `;
export const MeasurementMappingFieldsFragmentFragmentDoc = gql`
    fragment MeasurementMappingFieldsFragment on MeasurementMappingFields {
  author {
    id
    name
  }
  authorExplanation
  inputEmissionsSpecifier
  inputEmissionsSubspecifier
  outputEmissionsSpecifier
  outputEmissionsSubspecifier
  vendorEntity
  startMonth
  endMonth
  shouldInclude
  ghgCategoryId
  categoryId
  subcategoryId
  description
  updatedAt
}
    `;
export const CadtRowsToMapFieldsFragmentDoc = gql`
    fragment CadtRowsToMapFields on RawCadtRowToMap {
  emissions_specifier
  emissions_subspecifier
  unnormalized_emissions_subspecifier
  unit
  month_earliest
  month_latest
  top_vendors {
    vendor_entity
    amount
  }
  total_amount
  description_from_cadt
  adt_ids
}
    `;
export const PlanTargetForOrgPlanCategoriesPageFragmentDoc = gql`
    fragment PlanTargetForOrgPlanCategoriesPage on PlanTarget {
  id
  description
  reportDescription
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
}
    `;
export const PlanForPlanPageFragmentDoc = gql`
    fragment PlanForPlanPage on Plan {
  id
  name
  createdAt
  deletedAt
  updatedAt
  updatedByName
  state
  baselineYearStart
  isHidden
  isSbtValidated
  variables {
    commitmentsSBTTermLength
  }
}
    `;
export const EngagementTaskFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskFieldsAdmin on EngagementTask {
  id
  name
  crossOrgModelId
  status
  deletedAt
  company {
    id
  }
  engagementTaskConfig {
    id
    name
  }
}
    `;
export const SuppliersSettingsFieldsAdminFragmentDoc = gql`
    fragment SuppliersSettingsFieldsAdmin on SuppliersSettings {
  id
  includedGhgCategories
  viewLatestSupplierMapping
  suppressNotifications
}
    `;
export const UserTaskPermissionsFragmentDoc = gql`
    fragment UserTaskPermissions on UserUploadTask {
  id
  measurementProject {
    id
    name
    active
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
}
    `;
export const UserFieldsForOrgPageFragmentDoc = gql`
    fragment UserFieldsForOrgPage on User {
  id
  name
  displayName
  email
  loginActivated
  signupLink(orgId: $orgId)
  orgAccessId(orgId: $orgId)
  orgAccessCreatedAt(orgId: $orgId)
  consecutiveLoginFailures
  userUploadTasks {
    ...UserTaskPermissions
  }
  roles(orgId: $orgId) {
    ...UserRoleFields
  }
  permissions(orgId: $orgId) {
    id
    ...PermissionFields
  }
  didLatestEmailBounce
  preferredLocale
}
    ${UserTaskPermissionsFragmentDoc}
${UserRoleFieldsFragmentDoc}
${PermissionFieldsFragmentDoc}`;
export const ReferenceDataRevisionSchemaFragmentDoc = gql`
    fragment ReferenceDataRevisionSchema on ReferenceDataRevision {
  id
  revisionName
  author {
    id
    name
  }
  updates
  outputFileId
  sourceFileId
  internalSourceNotes
  createdAt
  archivedAt
  signedParquetOutputUrl
  signedSourceUrl
  organization {
    id
    name
  }
  state
  schemaJson
  assumptionSource {
    id
  }
  kind
  schemaLastCheckedAgainst
  lastCompatibleSchema
  isLatestForOrgAndState
  isOverlay
}
    `;
export const CompositeDataOrgDetailSchemaFragmentDoc = gql`
    fragment CompositeDataOrgDetailSchema on CompositeDataOrgDetail {
  organization {
    id
    name
  }
  latestPublishedRevision {
    ...ReferenceDataRevisionSchema
  }
}
    ${ReferenceDataRevisionSchemaFragmentDoc}`;
export const ReferenceDataSourceSchemaFragmentDoc = gql`
    fragment ReferenceDataSourceSchema on ReferenceDataSource {
  id
  name
  author {
    id
    name
  }
  orgId
  organization {
    id
    name
  }
  externalSourceUrl
  externalSourceNotes
  kind
  isCompositeData
  createdAt
  updatedAt
  archivedAt
  tags {
    id
    name
  }
  assumptionSources {
    id
    name
  }
  isSyncedWithCliq
}
    `;
export const ReferenceDataSourceSchemaWithOutputUrlFragmentDoc = gql`
    fragment ReferenceDataSourceSchemaWithOutputUrl on ReferenceDataSource {
  ...ReferenceDataSourceSchema
  latestSignedParquetOutputUrl(orgId: $orgId)
}
    ${ReferenceDataSourceSchemaFragmentDoc}`;
export const ReferenceDataVersionSchemaFragmentDoc = gql`
    fragment ReferenceDataVersionSchema on ReferenceDataVersion {
  id
  sourceId
  author {
    id
    name
  }
  versionName
  versionVintage
  createdAt
  updatedAt
  archivedAt
  state
  hasPublishedRevision
  canPublish
  latestSchema {
    id
    schemaJson
  }
}
    `;
export const ReferenceDataVersionSchemaWithOutputUrlFragmentDoc = gql`
    fragment ReferenceDataVersionSchemaWithOutputUrl on ReferenceDataVersion {
  ...ReferenceDataVersionSchema
  latestSignedParquetOutputUrl(orgId: $orgId)
}
    ${ReferenceDataVersionSchemaFragmentDoc}`;
export const ReportQuestionForCopyFragmentDoc = gql`
    fragment ReportQuestionForCopy on ReportQuestion {
  id
  identifier
}
    `;
export const ReportAnswerForAdminTableFragmentDoc = gql`
    fragment ReportAnswerForAdminTable on ReportAnswer {
  id
  outputJson
  updatedAt
  question {
    id
    identifier
    componentId
    isComputed
  }
  report {
    id
    name
    organization {
      id
      name
      demoOrg
    }
    config {
      id
      reportType
      shortName
    }
    isMigratedFromStaticConfig
    createdAt
  }
}
    `;
export const CdpQuestionIdMappingFragmentDoc = gql`
    fragment CdpQuestionIdMapping on CdpQuestionIdMapping {
  id
  cdpRefId
  reportQuestionIdentifier {
    id
    identifier
  }
}
    `;
export const CdpColumnIdMappingFragmentDoc = gql`
    fragment CdpColumnIdMapping on CdpColumnIdMapping {
  id
  cdpRefId
  watershedColumnId
  otherColumnIdMapping
}
    `;
export const CdpIdMappingsFieldsFragmentDoc = gql`
    fragment CdpIdMappingsFields on CdpIdMappingsPayload {
  __typename
  id
  questionIdMappings {
    ...CdpQuestionIdMapping
  }
  columnIdMappings {
    ...CdpColumnIdMapping
  }
}
    ${CdpQuestionIdMappingFragmentDoc}
${CdpColumnIdMappingFragmentDoc}`;
export const CandidateQuestionMappingFieldsFragmentDoc = gql`
    fragment CandidateQuestionMappingFields on CdpCandidateQuestionIdMapping {
  cdpRefId
  cdpQuestionJson
  reportQuestion {
    id
    identifier
    identifierId
  }
}
    `;
export const CandidateColumnMappingFieldsFragmentDoc = gql`
    fragment CandidateColumnMappingFields on CdpCandidateColumnIdMapping {
  cdpRefId
  cdpParentRefId
  cdpColumnQuestionJson
  cdpParentQuestionJson
  columnId
  columnLabel
  otherColumnIdMapping
  parentReportQuestion {
    id
    identifier
    identifierId
  }
}
    `;
export const UnmappedWatershedQuestionFieldsFragmentDoc = gql`
    fragment UnmappedWatershedQuestionFields on ReportQuestion {
  id
  identifier
  componentId
  isComputed
}
    `;
export const ReportQuestionContainerFieldsFragmentDoc = gql`
    fragment ReportQuestionContainerFields on ReportQuestionContainer {
  id
  label
  description
  statusJson
  guidanceJson
  fullWidth
  itemIds
}
    `;
export const ReportQuestionRawFieldsFragmentDoc = gql`
    fragment ReportQuestionRawFields on ReportQuestion {
  id
  createdAt
  updatedAt
  deletedAt
  identifier
  componentId
  staticInputJson
  dynamicInputJson
  statusJson
  guidanceJson
  isComputed
  isDynamic
  allowNotes
  allowedAttachmentKinds
}
    `;
export const ReportConfigFieldsForAdminTableFragmentDoc = gql`
    fragment ReportConfigFieldsForAdminTable on ReportConfig {
  id
  createdAt
  updatedAt
  deletedAt
  reportType
  shortName
  longName
  description
  brandColor
  isHiddenFromOverview
  isCreationBlocked
  isRegulatoryExposureBlocked
  useTableDisplay
  useMateriality
  useReportingIntention
  requiredPermissions
  reportObjectives
  isCorporateReport
  isFinanceReport
  status
}
    `;
export const ReportQuestionIdentifierFieldsFragmentDoc = gql`
    fragment ReportQuestionIdentifierFields on ReportQuestionIdentifier {
  id
  identifier
}
    `;
export const CommonOpportunityFieldsFragmentDoc = gql`
    fragment CommonOpportunityFields on ITcfdOpportunity {
  id
  title
  userDescription
}
    `;
export const ArchetypeOpportunityFieldsFragmentDoc = gql`
    fragment ArchetypeOpportunityFields on TcfdArchetypeOpportunity {
  ...CommonOpportunityFields
  geographies
  sectors
  distributionModel
}
    ${CommonOpportunityFieldsFragmentDoc}`;
export const RiskCommonFieldsFragmentDoc = gql`
    fragment RiskCommonFields on ITcfdRisk {
  id
  title
  userDescription
  kind
  possibleImpactDescription
  lowTrajectoryImpact
  mediumTrajectoryImpact
  highTrajectoryImpact
  physicalRiskSeverity
}
    `;
export const RiskArchetypeFieldsFragmentDoc = gql`
    fragment RiskArchetypeFields on TcfdArchetypeRisk {
  ...RiskCommonFields
  geographies
  sectors
  distributionModel
  whyWeChoseThisMd
}
    ${RiskCommonFieldsFragmentDoc}`;
export const QueryRunFieldsFragmentDoc = gql`
    fragment QueryRunFields on QueryRunInterface {
  id
  state
  jobError {
    ...SerializableErrorFields
  }
  error
}
    ${SerializableErrorFieldsFragmentDoc}`;
export const UserContactFieldsFragmentDoc = gql`
    fragment UserContactFields on User {
  id
  name
  displayName
  email
  createdAt
  loginActivated
}
    `;
export const ActivityDataUserUploadFragmentDoc = gql`
    fragment ActivityDataUserUpload on UserUpload {
  id
  category
  name
  createdAt
  uploader {
    id
    name
  }
  revisionRoot
  userUploadTask {
    id
    datasource {
      id
      name
    }
  }
  deletedAt
}
    `;
export const UploadSchemaDetailVersionFragmentDoc = gql`
    fragment UploadSchemaDetailVersion on CustomerTargetSchemaVersion {
  id
  schemaJson
  semverVersion
  versionNotesMd
  formattingNotesMd
  deprecatedAt
  plannedRemovedAt
  removedAt
  createdAt
  orgDatasetExampleData {
    id
    ...IngestionExampleDataFields
  }
  transforms {
    id
    businessActivityTypeName
  }
}
    ${IngestionExampleDataFieldsFragmentDoc}`;
export const UploadSchemaExplorerDatasetFragmentDoc = gql`
    fragment UploadSchemaExplorerDataset on Dataset {
  id
  name
  canonicalDataset {
    id
    customerTargetSchemas(hasPublishedVersion: true) {
      id
      name
    }
  }
}
    `;
export const GroupedAuditLogEntryFieldsFragmentDoc = gql`
    fragment GroupedAuditLogEntryFields on GroupedAuditLogEntry {
  id
  createdAt
  eventKind
  numEvents
  firstEventMetadata
  user {
    id
    displayName
  }
  objectKind
}
    `;
export const FootprintExclusionRuleForMethodologyExplainerFragmentDoc = gql`
    fragment FootprintExclusionRuleForMethodologyExplainer on FootprintExclusionRule {
  id
  stableId
  deduplicationNote
  outputValue
}
    `;
export const CalculationTraceForMethodologyExplainerFragmentDoc = gql`
    fragment CalculationTraceForMethodologyExplainer on MethodologyExplainerCalculationTrace {
  __typename
  id
  fingerprint
  sumOfFunctionalUnit
  sumOfConvertedActivity
  sumOfMeasure
  inputUnit
  convertedUnit
  quantitySpecifier
  emissionsModelVersionId
  emissionsModelEvaluationId
  footprintExclusionRule {
    ...FootprintExclusionRuleForMethodologyExplainer
  }
}
    ${FootprintExclusionRuleForMethodologyExplainerFragmentDoc}`;
export const DisclosureForMethodologyExplainerFragmentDoc = gql`
    fragment DisclosureForMethodologyExplainer on Disclosure {
  id
  qualityScore
  historicalEmissionsYears {
    id
    reportingYear
  }
  privateDisclosure {
    id
    privateDisclosureType
  }
  publicDisclosure {
    id
    reportType
    publicUrl
  }
  climateCommitments {
    id
    externalUrl
    kind
    ... on NetZeroCommitment {
      targetYear
    }
    ... on CarbonNeutralCommitment {
      targetYear
    }
    ... on ScienceBasedTargetCommitment {
      commitment {
        id
        stage
        targetClassification
      }
    }
  }
}
    `;
export const CompanyForMethodologyExplainerFragmentDoc = gql`
    fragment CompanyForMethodologyExplainer on Company {
  id
  name
  description
  url
  fullLogoUrl
  climateProgress
  disclosuresV2(opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}) {
    ...DisclosureForMethodologyExplainer
  }
}
    ${DisclosureForMethodologyExplainerFragmentDoc}`;
export const BiBaseFieldMetaFragmentFragmentDoc = gql`
    fragment BiBaseFieldMetaFragment on BiBaseFieldMeta {
  __typename
  fieldId
  displayName
  fieldFamily
  description
  hiddenInFilters
  hiddenInGroupBy
  emptyStringType
  isMultiValue
  isCustomField
}
    `;
export const BiStringFieldMetaFragmentFragmentDoc = gql`
    fragment BiStringFieldMetaFragment on BiFieldStringMeta {
  shouldSentenceCaseValues
  shouldNullifyPseudoNulls
  knownDisplayValueMappingType
  staticListOptions {
    value
    label
  }
}
    `;
export const BiNumberFieldMetaFragmentFragmentDoc = gql`
    fragment BiNumberFieldMetaFragment on BiFieldNumberMeta {
  precision
  disableCommas
  includeUnit
  shouldFormatToPercent
  unit
  unitDimension
  unitUserFacing
  dependentDimensions
  aggregatedUnitDimension
  aggregateType
  divideBy
  isCurrency
}
    `;
export const BiDateFieldMetaFragmentFragmentDoc = gql`
    fragment BiDateFieldMetaFragment on BiFieldDateMeta {
  format {
    day
    month
    year
  }
}
    `;
export const BiYearMonthFieldMetaFragmentFragmentDoc = gql`
    fragment BiYearMonthFieldMetaFragment on BiFieldYearMonthMeta {
  format {
    day
    month
    year
  }
}
    `;
export const BiQueryMetadataFieldsFragmentDoc = gql`
    fragment BiQueryMetadataFields on BiQueryMetadataResult {
  id
  dimensions {
    fieldMeta {
      ...BiBaseFieldMetaFragment
      ...BiStringFieldMetaFragment
      ...BiNumberFieldMetaFragment
      ...BiDateFieldMetaFragment
      ...BiYearMonthFieldMetaFragment
    }
  }
  measures {
    supportedDimensions
    fieldMeta {
      ...BiBaseFieldMetaFragment
      ...BiNumberFieldMetaFragment
    }
  }
  timeInterval
}
    ${BiBaseFieldMetaFragmentFragmentDoc}
${BiStringFieldMetaFragmentFragmentDoc}
${BiNumberFieldMetaFragmentFragmentDoc}
${BiDateFieldMetaFragmentFragmentDoc}
${BiYearMonthFieldMetaFragmentFragmentDoc}`;
export const BiQueryFilterFieldsFragmentDoc = gql`
    fragment BiQueryFilterFields on BiQueryFilter {
  dimension
  operator
  value
}
    `;
export const BiNormalizedMeasureFieldsFragmentDoc = gql`
    fragment BiNormalizedMeasureFields on BiDynamicNormalizingMeasure {
  baseMeasure
  normalizingQuery {
    measure
    computedDimensionType
    computedFiltersType
    filters {
      ...BiQueryFilterFields
    }
    dimensions
  }
}
    ${BiQueryFilterFieldsFragmentDoc}`;
export const BiSavedViewFragmentDoc = gql`
    fragment BiSavedView on BiSavedView {
  id
  stableId
  name
  dimensions
  chartKind
  editVisibility
  viewVisibility
  updatedAt
  createdAt
  owner {
    id
    name
    displayName
  }
  order {
    dimensionOrMeasure
    direction
  }
  filters {
    ...BiQueryFilterFields
  }
  normalizedMeasures {
    ...BiNormalizedMeasureFields
  }
  timeIntervals
  customMetrics {
    id
    stableId
  }
  searchTerm
  queryContext {
    ... on BiSavedViewSnapshotQueryContext {
      footprintKind
    }
  }
}
    ${BiQueryFilterFieldsFragmentDoc}
${BiNormalizedMeasureFieldsFragmentDoc}`;
export const FootprintKindMetaForDrilldownFragmentDoc = gql`
    fragment FootprintKindMetaForDrilldown on SnapshotFootprintKindMeta {
  id
  footprintKind
  name
  description
  footprintInterval
}
    `;
export const LinkedReportFieldsFragmentDoc = gql`
    fragment LinkedReportFields on Report {
  id
  name
  createdAt
  reportKind
  config {
    id
    reportType
  }
}
    `;
export const MeasurementTimelineForMeasurementPageFragmentDoc = gql`
    fragment MeasurementTimelineForMeasurementPage on MeasurementTimeline {
  id
  kickoff
  deadline
}
    `;
export const LinkedMeasurementFieldsFragmentDoc = gql`
    fragment LinkedMeasurementFields on MeasurementProject {
  id
  name
  setupAt
  completedAt
  measurementTimeline {
    ...MeasurementTimelineForMeasurementPage
  }
}
    ${MeasurementTimelineForMeasurementPageFragmentDoc}`;
export const ClimateProgramTimelineProjectFieldsFragmentDoc = gql`
    fragment ClimateProgramTimelineProjectFields on ClimateProgramTimelineProject {
  __typename
  id
  name
  description
  kind
  startDate
  endDate
  plannedYear
  completedAt
  iconType
  object {
    __typename
    id
    ... on Report {
      ...LinkedReportFields
    }
    ... on MeasurementProject {
      ...LinkedMeasurementFields
    }
  }
  objectType
  canonicalClimateProgramProject {
    id
    name
    routeType
  }
}
    ${LinkedReportFieldsFragmentDoc}
${LinkedMeasurementFieldsFragmentDoc}`;
export const ClimateProgramProjectFieldsFragmentDoc = gql`
    fragment ClimateProgramProjectFields on ClimateProgramProject {
  __typename
  id
  name
  description
  kind
  startDate
  endDate
  plannedYear
  completedAt
  status
  iconType
  measurementProject {
    id
    name
  }
  canonicalClimateProgramProject {
    id
    routeType
    name
  }
}
    `;
export const CanonicalClimateProgramProjectMeasurementCopyFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectMeasurementCopy on CanonicalClimateProgramProject {
  __typename
  id
  kind
  measurementObjectCopy
  routeType
  name
}
    `;
export const ClimateProgramProjectMeasurementCopyFragmentDoc = gql`
    fragment ClimateProgramProjectMeasurementCopy on ClimateProgramProject {
  __typename
  id
  canonicalClimateProgramProject {
    ...CanonicalClimateProgramProjectMeasurementCopy
  }
}
    ${CanonicalClimateProgramProjectMeasurementCopyFragmentDoc}`;
export const ClimateProgramProjectRequirementDatasetFieldsFragmentDoc = gql`
    fragment ClimateProgramProjectRequirementDatasetFields on ClimateProgramProjectRequirementDataset {
  id
  dataset {
    id
    name
    instructions {
      instructionsIntroMd
    }
    canonicalDataset {
      id
      kind
      instructions {
        sdiInstructionsIntroMd
      }
    }
  }
  additionalNotesMd
  canonicalGhgpRequirement {
    id
    ghgScope
    ghgScope3Category
    minimumFidelity
  }
}
    `;
export const ClimateProgramProjectMeasurementConcernsFieldsFragmentDoc = gql`
    fragment ClimateProgramProjectMeasurementConcernsFields on ClimateProgramProject {
  ...ClimateProgramProjectMeasurementCopy
  datasetRequirements {
    ...ClimateProgramProjectRequirementDatasetFields
  }
}
    ${ClimateProgramProjectMeasurementCopyFragmentDoc}
${ClimateProgramProjectRequirementDatasetFieldsFragmentDoc}`;
export const ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc = gql`
    fragment ClimateProgramProjectWithMeasurementConcernsFields on ClimateProgramProject {
  __typename
  ...ClimateProgramProjectFields
  ...ClimateProgramProjectMeasurementConcernsFields
}
    ${ClimateProgramProjectFieldsFragmentDoc}
${ClimateProgramProjectMeasurementConcernsFieldsFragmentDoc}`;
export const CanonicalClimateProgramProjectForAddProjectFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectForAddProject on CanonicalClimateProgramProject {
  id
  name
  description
  costEstimate
  timeEstimate
  iconType
  hasBenchmarkMapping
  matchObjectType
  reportKind
  routeType
  peerBenchmarkCompanies {
    id
    name
    fullLogoUrl
  }
  kind
}
    `;
export const CanonicalClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectWithMeasurementConcernsFields on CanonicalClimateProgramProject {
  ...CanonicalClimateProgramProjectMeasurementCopy
  ...CanonicalClimateProgramProjectForAddProject
}
    ${CanonicalClimateProgramProjectMeasurementCopyFragmentDoc}
${CanonicalClimateProgramProjectForAddProjectFragmentDoc}`;
export const ClimateProgramCalendarEventFieldsFragmentDoc = gql`
    fragment ClimateProgramCalendarEventFields on ClimateProgramCalendarEvent {
  __typename
  id
  name
  startDate
  endDate
}
    `;
export const InstructionsStepForImportInstructionsFragmentDoc = gql`
    fragment InstructionsStepForImportInstructions on InstructionsStep {
  descriptionMd
  publicUrl
}
    `;
export const SdiInstructionsStepForImportInstructionsFragmentDoc = gql`
    fragment SDIInstructionsStepForImportInstructions on SdiInstructionsStep {
  title
  descriptionMd
}
    `;
export const SdiInstructionsFaqForImportInstructionsFragmentDoc = gql`
    fragment SDIInstructionsFaqForImportInstructions on SdiInstructionsFaqType {
  question
  answerMd
}
    `;
export const InstructionsBundleForImportInstructionsFragmentDoc = gql`
    fragment InstructionsBundleForImportInstructions on InstructionsBundle {
  instructionsIntroMd
  instructionsDataFormattingMd
  sdiInstructionsIntroMd
  sdiInstructionsDataFormattingMd
  ghgScopes
  instructionsSteps {
    ...InstructionsStepForImportInstructions
  }
  sdiInstructionsSteps {
    ...SDIInstructionsStepForImportInstructions
  }
  sdiInstructionsFaq {
    ...SDIInstructionsFaqForImportInstructions
  }
}
    ${InstructionsStepForImportInstructionsFragmentDoc}
${SdiInstructionsStepForImportInstructionsFragmentDoc}
${SdiInstructionsFaqForImportInstructionsFragmentDoc}`;
export const CustomerTargetSchemaVersionForDataFormatFragmentDoc = gql`
    fragment CustomerTargetSchemaVersionForDataFormat on CustomerTargetSchemaVersion {
  id
  formattingNotesMd
  orgDatasetExampleData {
    ...IngestionExampleDataFields
  }
  transforms {
    id
    businessActivityTypeName
    businessActivityTypeVersion {
      id
    }
  }
}
    ${IngestionExampleDataFieldsFragmentDoc}`;
export const CustomerTargetSchemaForDataFormatFragmentDoc = gql`
    fragment CustomerTargetSchemaForDataFormat on CustomerTargetSchema {
  id
  name
  utilityType
  rank
  latestPublishedVersion {
    ...CustomerTargetSchemaVersionForDataFormat
  }
}
    ${CustomerTargetSchemaVersionForDataFormatFragmentDoc}`;
export const CanonicalDatasetFieldsForImportFragmentDoc = gql`
    fragment CanonicalDatasetFieldsForImport on CanonicalDataset {
  id
  name
  kind
  instructions {
    ...InstructionsBundleForImportInstructions
  }
  customerTargetSchemas(measurementProjectId: $measurementProjectId) {
    ...CustomerTargetSchemaForDataFormat
  }
}
    ${InstructionsBundleForImportInstructionsFragmentDoc}
${CustomerTargetSchemaForDataFormatFragmentDoc}`;
export const UutSchemaForIngestionFragmentDoc = gql`
    fragment UutSchemaForIngestion on UserUploadedTableSchemaColumn {
  index
  kind
  name
  alias
}
    `;
export const UserReviewMetadataForIngestionFragmentDoc = gql`
    fragment UserReviewMetadataForIngestion on UserUploadedTableUserReviewMetadata {
  headerRowIndex
}
    `;
export const UserUploadedTableFieldsWithoutDataPreviewFragmentDoc = gql`
    fragment UserUploadedTableFieldsWithoutDataPreview on UserUploadedTable {
  id
  sheetName
  sheetIndex
  errorMessage
  errorType
  numRows
  schema {
    ...UutSchemaForIngestion
  }
  uirSchema {
    ...UutSchemaForIngestion
  }
  parseConfig {
    noHeader
    skipTopNRows
    skipBottomNRows
    meltIdVars
  }
  roboCleanerResult {
    skipTopNRows
  }
  userReviewMetadata {
    ...UserReviewMetadataForIngestion
  }
  status
  parentFileMetadata {
    id
    name
  }
}
    ${UutSchemaForIngestionFragmentDoc}
${UserReviewMetadataForIngestionFragmentDoc}`;
export const UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc = gql`
    fragment UserUploadedTableWithOnlyDataPreviewFields on UserUploadedTable {
  status
  dataPreview {
    dataUntyped
  }
  rawDataPreview {
    dataUntyped
  }
  rawDataUnshiftedPreview {
    dataUntyped
  }
}
    `;
export const UserUploadedTableForDatasetPageFragmentDoc = gql`
    fragment UserUploadedTableForDatasetPage on UserUploadedTable {
  ...UserUploadedTableFieldsWithoutDataPreview
  ...UserUploadedTableWithOnlyDataPreviewFields
  extBartId
  businessActivityType
}
    ${UserUploadedTableFieldsWithoutDataPreviewFragmentDoc}
${UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc}`;
export const UserUploadAttemptFieldsForDatasetPageFragmentDoc = gql`
    fragment UserUploadAttemptFieldsForDatasetPage on UserUploadAttempt {
  id
  name
  createdAt
  size
  uploader {
    id
    name
  }
  description
  userUploadedTables {
    ...UserUploadedTableForDatasetPage
  }
  latestUutsIfRevisionExists {
    ...UserUploadedTableForDatasetPage
  }
  remoteWritten
}
    ${UserUploadedTableForDatasetPageFragmentDoc}`;
export const UserUploadForDatasetPageFragmentDoc = gql`
    fragment UserUploadForDatasetPage on UserUpload {
  id
  name
  category
  createdAt
  measurementProject {
    id
    name
    deletedAt
  }
  processingMode
  uploader {
    id
    name
  }
  userVisibleAttempt {
    ...UserUploadAttemptFieldsForDatasetPage
  }
  revisionRoot
  userUploadTask {
    id
    datasource {
      id
      name
    }
  }
}
    ${UserUploadAttemptFieldsForDatasetPageFragmentDoc}`;
export const DatasetForSpecificDatasetPageFragmentDoc = gql`
    fragment DatasetForSpecificDatasetPage on Dataset {
  id
  name
  explainer
  usedFor
  potentialOverlaps
  canonicalDataset {
    ...CanonicalDatasetFieldsForImport
  }
  datasources {
    id
    name
    userUploadTasks {
      edges {
        node {
          id
          measurementProject {
            id
            name
          }
          measurementTask {
            id
            statusSimplified
            title
          }
          assignees {
            id
            name
            displayName
            didLatestEmailBounce
          }
          userUploads {
            edges {
              node {
                ...UserUploadForDatasetPage
              }
            }
          }
        }
      }
    }
  }
}
    ${CanonicalDatasetFieldsForImportFragmentDoc}
${UserUploadForDatasetPageFragmentDoc}`;
export const DiscussionAnchorFieldsFragmentDoc = gql`
    fragment DiscussionAnchorFields on DiscussionAnchorPayload {
  userUploadTaskId
  valueMappingTaskId
  measurementVerificationItemQuestionId
  financialsReviewItemId
  productSection
}
    `;
export const DiscussionCommentFieldsFragmentDoc = gql`
    fragment DiscussionCommentFields on Comment {
  id
  person {
    id
    name
    isWatershedEmployee
    isWatershedContractor
    displayName
  }
  message
  createdAt
}
    `;
export const DiscussionFieldsFragmentDoc = gql`
    fragment DiscussionFields on Discussion {
  id
  title
  description
  state
  createdAt
  isCustomerInitiated
  anchor {
    ...DiscussionAnchorFields
  }
  comments(isPublished: true) {
    edges {
      node {
        ...DiscussionCommentFields
      }
    }
  }
}
    ${DiscussionAnchorFieldsFragmentDoc}
${DiscussionCommentFieldsFragmentDoc}`;
export const CommentDiscussionsForAnchorFieldsFragmentDoc = gql`
    fragment CommentDiscussionsForAnchorFields on DiscussionsPayload {
  id
  discussions {
    ...DiscussionFields
  }
}
    ${DiscussionFieldsFragmentDoc}`;
export const TaskStatusDumpAllFieldsFragmentDoc = gql`
    fragment TaskStatusDumpAllFields on TaskStatusDump {
  taskId
  taskName
  assigneeNames
  datasetName
  status
}
    `;
export const MeasurementProjectFieldsForShareStatusEngagementTaskFragmentDoc = gql`
    fragment MeasurementProjectFieldsForShareStatusEngagementTask on MeasurementProject {
  id
  name
  coverageStartDate
  coverageEndDate
  taskStatusDumps {
    ...TaskStatusDumpAllFields
  }
}
    ${TaskStatusDumpAllFieldsFragmentDoc}`;
export const FinanceChangelogLineItemFragmentDoc = gql`
    fragment FinanceChangelogLineItem on FinanceChangelogLineItem {
  id
  field
  oldValue
  newValue
}
    `;
export const FinanceChangelogEntryFragmentDoc = gql`
    fragment FinanceChangelogEntry on FinanceChangelogEntry {
  id
  createdAt
  eventKind
  userDisplayName
  objectLabel
  assetId
  fundId
  lineItems {
    id
    ...FinanceChangelogLineItem
  }
}
    ${FinanceChangelogLineItemFragmentDoc}`;
export const AssetHoldingAllFieldsFragmentDoc = gql`
    fragment AssetHoldingAllFields on FinanceAssetHolding {
  __typename
  id
  orgId
  createdAt
  updatedAt
  assetYearId
  fundId
  yearOfInitialInvestment
  outstandingAmountNative
  outstandingAmountNativeQ1
  outstandingAmountNativeQ2
  outstandingAmountNativeQ3
  outstandingAmountNativeQ4
  currencyCode
  currencyConversionRate
  assetClass
  currencyCode
  currencyConversionRate
  tagData
  premiumNative
  emissionsYearId
}
    `;
export const AssetCorporateAllFieldsFragmentDoc = gql`
    fragment AssetCorporateAllFields on AssetCorporate {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  naicsCode
  companyIsin
  companyLei
  companyUrl
  companyTicker
  currencyCode
  countryAlpha2
  buildingSizeUnit
  companyId
  orgId
  otherData
  tagData
  externalId
  assetGroupId
  anonymousCompanyUuid
}
    `;
export const AssetRealEstateAllFieldsFragmentDoc = gql`
    fragment AssetRealEstateAllFields on AssetRealEstate {
  __typename
  id
  orgId
  createdAt
  updatedAt
  buildingKind
  name
  streetAddress
  streetAddress2
  city
  state
  countryAlpha2
  postalCode
  currencyCode
  buildingSizeUnit
  otherData
  tagData
  externalId
  assetGroupId
  sizeUnit
  country
  buildingName
}
    `;
export const AssetGroupAllFieldsFragmentDoc = gql`
    fragment AssetGroupAllFields on AssetGroup {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  currencyCode
  otherData
  tagData
  externalId
  orgId
}
    `;
export const AssetGroupAssetAllFieldsFragmentDoc = gql`
    fragment AssetGroupAssetAllFields on AssetGroupAsset {
  __typename
  ... on AssetCorporate {
    ...AssetCorporateAllFields
  }
  ... on AssetRealEstate {
    ...AssetRealEstateAllFields
  }
  ... on AssetGroup {
    ...AssetGroupAllFields
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}
${AssetRealEstateAllFieldsFragmentDoc}
${AssetGroupAllFieldsFragmentDoc}`;
export const AssetSovereignBondAllFieldsFragmentDoc = gql`
    fragment AssetSovereignBondAllFields on AssetSovereignBond {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  sovereignEntity
  currencyCode
  tagData
}
    `;
export const AssetPersonalMotorVehicleInsuranceFieldsFragmentDoc = gql`
    fragment AssetPersonalMotorVehicleInsuranceFields on AssetPersonalMotorVehicleInsurance {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  currencyCode
  tagData
}
    `;
export const AssetAllFieldsFragmentDoc = gql`
    fragment AssetAllFields on IFinanceAsset {
  __typename
  ... on AssetCorporate {
    ...AssetCorporateAllFields
  }
  ... on AssetRealEstate {
    ...AssetRealEstateAllFields
  }
  ... on AssetGroup {
    ...AssetGroupAllFields
  }
  ... on AssetSovereignBond {
    ...AssetSovereignBondAllFields
  }
  ... on AssetPersonalMotorVehicleInsurance {
    ...AssetPersonalMotorVehicleInsuranceFields
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}
${AssetRealEstateAllFieldsFragmentDoc}
${AssetGroupAllFieldsFragmentDoc}
${AssetSovereignBondAllFieldsFragmentDoc}
${AssetPersonalMotorVehicleInsuranceFieldsFragmentDoc}`;
export const AssetYearAllFieldsFragmentDoc = gql`
    fragment AssetYearAllFields on FinanceAssetYearNew {
  __typename
  id
  orgId
  createdAt
  updatedAt
  year
  assetCommercialRealEstateId
  assetCorporateId
  assetGroupId
  assetSovereignBondId
  assetPersonalMotorVehicleInsuranceId
  buildingSizeNative
  estimationMethodology
  headcount
  percentRenewable
  cloudSpendNative
  valueNative
  revenueNative
  flightMiles
  percentEmployeesWfh
  currencyCode
  currencyConversionRate
  totalMwh
  revenueSourceEmissionsYearId
  emissionsYearId
  scope1OverrideTco2e
  scope1OverridePcaf
  scope2LocationOverrideTco2e
  scope2LocationOverridePcaf
  scope2MarketOverrideTco2e
  scope2MarketOverridePcaf
  scope3OverrideKind
  scope3OverrideTco2e
  scope3OverridePcaf
  scope301OverrideTco2e
  scope302OverrideTco2e
  scope303OverrideTco2e
  scope304OverrideTco2e
  scope305OverrideTco2e
  scope306OverrideTco2e
  scope307OverrideTco2e
  scope308OverrideTco2e
  scope309OverrideTco2e
  scope310OverrideTco2e
  scope311OverrideTco2e
  scope312OverrideTco2e
  scope313OverrideTco2e
  scope314OverrideTco2e
  scope315OverrideTco2e
  buildingEstimationErr
  companyEstimationErr
  measuringScope1And2Emissions
  measuringMaterialScope3Emissions
  initiatedDecarbonizationPlan
  committedToNearTermSbtAlignedNetZero
  demonstratingYoyEmissionsInLineWithNetZero
  iciAlignmentOverride
  scenario
  tagData
}
    `;
export const FinanceTagForChartSelectorFragmentDoc = gql`
    fragment FinanceTagForChartSelector on FinanceTag {
  id
  name
  columnFormat
}
    `;
export const FinanceAggregateMetricMoreFragmentDoc = gql`
    fragment FinanceAggregateMetricMore on FinanceAggregateMetric {
  __typename
  metric
  name
  description
  quantity
  unit
  additionalInfo
  isMissingInputs
}
    `;
export const FinanceStringMetricMoreFragmentDoc = gql`
    fragment FinanceStringMetricMore on FinanceStringMetric {
  __typename
  metric
  name
  description
  value
  unit
  additionalInfo
  isMissingInputs
}
    `;
export const FinanceAggregateEmptyMetricFragmentDoc = gql`
    fragment FinanceAggregateEmptyMetric on FinanceAggregateMetric {
  __typename
  metric
  name
  description
}
    `;
export const FinanceStringEmptyMetricFragmentDoc = gql`
    fragment FinanceStringEmptyMetric on FinanceStringMetric {
  __typename
  metric
  name
  description
}
    `;
export const FinanceMetricBarMinimalFragmentDoc = gql`
    fragment FinanceMetricBarMinimal on FinanceMetricBar {
  id
  metrics {
    ... on FinanceAggregateMetric {
      ...FinanceAggregateMetricMore
    }
    ... on FinanceStringMetric {
      ...FinanceStringMetricMore
    }
  }
  emptyMetrics {
    ... on FinanceAggregateMetric {
      ...FinanceAggregateEmptyMetric
    }
    ... on FinanceStringMetric {
      ...FinanceStringEmptyMetric
    }
  }
}
    ${FinanceAggregateMetricMoreFragmentDoc}
${FinanceStringMetricMoreFragmentDoc}
${FinanceAggregateEmptyMetricFragmentDoc}
${FinanceStringEmptyMetricFragmentDoc}`;
export const FinanceChartAllFieldsFragmentDoc = gql`
    fragment FinanceChartAllFields on FinanceChart {
  id
  title
  subtitle
  chartKind
  tooltipText
  data
  isWideFormat
  activeObjectType
  highchartsKind
  highchartsData
  highchartsUnit
  metric
}
    `;
export const SupplierContactFieldsFragmentDoc = gql`
    fragment SupplierContactFields on SupplierContact {
  id
  name
  email
  role
  userId
  companyId
}
    `;
export const PortcoFootprintReviewRequestMetadataAllFieldsFragmentDoc = gql`
    fragment PortcoFootprintReviewRequestMetadataAllFields on PortcoFootprintReviewRequestMetadata {
  id
  footprintVersionId
  footprintSnapshotId
  footprintVersionKind
  status
}
    `;
export const FinanceMeasurementProgressRowAllFieldsFragmentDoc = gql`
    fragment FinanceMeasurementProgressRowAllFields on FinanceMeasurementProgressRow {
  assetId
  assetName
  companyId
  projectName
  hasRequestedProgress
  hasRequestedFootprint
  coverageStartDate
  coverageEndDate
  contacts {
    ...SupplierContactFields
  }
  taskStatusDumps {
    ...TaskStatusDumpAllFields
  }
  portcoFootprintReviewRequestMetadata {
    ...PortcoFootprintReviewRequestMetadataAllFields
  }
  updatedAt
  measuredFootprintDetails {
    footprintSharedAt
    emissionsTco2e
  }
  fundIds
  hasApprovedProgressRequest
}
    ${SupplierContactFieldsFragmentDoc}
${TaskStatusDumpAllFieldsFragmentDoc}
${PortcoFootprintReviewRequestMetadataAllFieldsFragmentDoc}`;
export const FinanceAutomaticReminderAllFieldsFragmentDoc = gql`
    fragment FinanceAutomaticReminderAllFields on FinanceAutomaticReminder {
  id
  enabled
  reminderConfig
  reminderType
  deadline
}
    `;
export const CompanyFieldsForCcisFragmentDoc = gql`
    fragment CompanyFieldsForCCIS on Company {
  id
  naicsCode
  sbtiStage
  climateProgress
  sustainabilityWebsiteUrl
  isWatershedCorporateCustomer
}
    `;
export const CompanyPublicDisclosureFieldsFragmentDoc = gql`
    fragment CompanyPublicDisclosureFields on PublicDisclosure {
  id
  publishingYear
  reportType
  publicUrl
  qualityScore
  cdpVendorData {
    ...CdpVendorDataFields
  }
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const SupplierPrivateDisclosureFieldsFragmentDoc = gql`
    fragment SupplierPrivateDisclosureFields on PrivateDisclosure {
  id
  companyId
  orgId
  orgName
  surveyId
  privateDisclosureType
  createdAt
  disclosureQualityScore
  disclosureQualityExplanation
  thirdPartyVerified
  cdpVendorData {
    ...CdpVendorDataFields
  }
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const EmissionsYearScope1FieldsFragmentDoc = gql`
    fragment EmissionsYearScope1Fields on EmissionsYear {
  scope1
}
    `;
export const EmissionsYearScope2FieldsFragmentDoc = gql`
    fragment EmissionsYearScope2Fields on EmissionsYear {
  scope2Market
  scope2Location
}
    `;
export const EmissionsYearScope3FieldsFragmentDoc = gql`
    fragment EmissionsYearScope3Fields on EmissionsYear {
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
}
    `;
export const SupplierDisclosureHistoricalEmissionsYearFieldsFragmentDoc = gql`
    fragment SupplierDisclosureHistoricalEmissionsYearFields on EmissionsYear {
  id
  publicDisclosureId
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  revenueUsd
  percentageCleanEnergy
  totalMwh
  units
  expenseCategory
  ...EmissionsYearScope1Fields
  ...EmissionsYearScope2Fields
  ...EmissionsYearScope3Fields
}
    ${EmissionsYearScope1FieldsFragmentDoc}
${EmissionsYearScope2FieldsFragmentDoc}
${EmissionsYearScope3FieldsFragmentDoc}`;
export const SimpleTimeseriesForDisclosuresFragmentDoc = gql`
    fragment SimpleTimeseriesForDisclosures on SimpleTimeseries {
  id
  base
  frequency
  values
}
    `;
export const SupplierDisclosureTargetFieldsFragmentDoc = gql`
    fragment SupplierDisclosureTargetFields on DisclosureTargetDetails {
  id
  name
  description
  baseYear
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  emissionsTarget {
    ...SimpleTimeseriesForDisclosures
  }
  emissionsType
  reductionRate
  intensityType
  unit
  unitDescription
}
    ${SimpleTimeseriesForDisclosuresFragmentDoc}`;
export const SupplierDisclosureInitiativeFieldsFragmentDoc = gql`
    fragment SupplierDisclosureInitiativeFields on DisclosureInitiative {
  id
  name
  initiativeType
  description
  startYearMonth
  endYearMonth
  emissionsTimeseries {
    ...SimpleTimeseriesForDisclosures
  }
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  intensityType
}
    ${SimpleTimeseriesForDisclosuresFragmentDoc}`;
export const SbtCommitmentFieldsFragmentDoc = gql`
    fragment SBTCommitmentFields on SBTCommitment {
  id
  stage
  targetClassification
  nearTermTargetYear
  longTermTargetYear
  baselineYear
  netZeroCommitted
  commitmentType
  commitmentDeadline
  netZeroTargetYear
}
    `;
export const CompanyClimateCommitmentFieldsForCcisFragmentDoc = gql`
    fragment CompanyClimateCommitmentFieldsForCCIS on CompanyClimateCommitment {
  id
  kind
  description
  externalUrl
  commitmentMadeDate
  commitmentPeriodStart
  commitmentPeriodEnd
  ... on NetZeroCommitment {
    targetYear
  }
  ... on CarbonNeutralCommitment {
    targetYear
  }
  ... on ScienceBasedTargetCommitment {
    commitment {
      ...SBTCommitmentFields
    }
    submittedToSBTi
  }
  ... on CleanEnergyCommitment {
    targetYear
    targetPercentageCleanEnergy
  }
}
    ${SbtCommitmentFieldsFragmentDoc}`;
export const SupplierDisclosureFieldsFragmentDoc = gql`
    fragment SupplierDisclosureFields on Disclosure {
  id
  company {
    id
  }
  publicDisclosure {
    ...CompanyPublicDisclosureFields
  }
  privateDisclosure {
    ...SupplierPrivateDisclosureFields
  }
  historicalEmissionsYears {
    ...SupplierDisclosureHistoricalEmissionsYearFields
  }
  targets {
    ...SupplierDisclosureTargetFields
  }
  initiatives {
    ...SupplierDisclosureInitiativeFields
  }
  climateCommitments {
    ...CompanyClimateCommitmentFieldsForCCIS
  }
}
    ${CompanyPublicDisclosureFieldsFragmentDoc}
${SupplierPrivateDisclosureFieldsFragmentDoc}
${SupplierDisclosureHistoricalEmissionsYearFieldsFragmentDoc}
${SupplierDisclosureTargetFieldsFragmentDoc}
${SupplierDisclosureInitiativeFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsForCcisFragmentDoc}`;
export const ParentCompanyWithDisclosuresFieldsFragmentDoc = gql`
    fragment ParentCompanyWithDisclosuresFields on Company {
  id
  name
  disclosuresV2(
    opts: {publicDisclosureFilters: {}, targetFilters: {}, climateCommitmentFilters: {}, initiativeFilters: {}, historicalYearFilters: {allScopes: true}}
  ) {
    ...SupplierDisclosureFields
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;
export const ParentRelationshipFieldsFragmentDoc = gql`
    fragment ParentRelationshipFields on ParentRelationship {
  id
  parent {
    ...ParentCompanyWithDisclosuresFields
  }
  relationshipStartDate
  relationshipEndDate
}
    ${ParentCompanyWithDisclosuresFieldsFragmentDoc}`;
export const ParentRelationshipForSidebarFieldsFragmentDoc = gql`
    fragment ParentRelationshipForSidebarFields on ParentRelationship {
  id
  parent {
    id
    name
  }
  relationshipStartDate
  relationshipEndDate
}
    `;
export const EngagementTaskQuestionFieldsFragmentDoc = gql`
    fragment EngagementTaskQuestionFields on EngagementTaskQuestion {
  id
  label
  description
  componentId
  editable
  deletable
  questionKey
  statusJson
  whyCollectThis
  answerIsPrivate
  videoLink
}
    `;
export const EngagementTaskContainerFieldsFragmentDoc = gql`
    fragment EngagementTaskContainerFields on EngagementTaskContainer {
  id
  label
  description
  componentId
  editable
  statusJson
  sidebarStepLabelOverride
  questions {
    ...EngagementTaskQuestionFields
  }
  eyebrowText
  headerImage
}
    ${EngagementTaskQuestionFieldsFragmentDoc}`;
export const EngagementTaskContainerWithSubSectionFieldsFragmentDoc = gql`
    fragment EngagementTaskContainerWithSubSectionFields on EngagementTaskContainer {
  ...EngagementTaskContainerFields
  containers {
    ...EngagementTaskContainerFields
  }
}
    ${EngagementTaskContainerFieldsFragmentDoc}`;
export const EngagementTaskConfigFieldsFragmentDoc = gql`
    fragment EngagementTaskConfigFields on EngagementTaskConfig {
  id
  name
  sidebarTitle
  defaultSupplierFacingDescription
  rootFacingDescription
  orgId
  type
  configHasTaskAssignedToCompany
  containers {
    ...EngagementTaskContainerWithSubSectionFields
  }
  baseConfig {
    id
    containers {
      ...EngagementTaskContainerWithSubSectionFields
    }
  }
  timeNeeded
  category
  engagementTaskInputs
  optionalEngagementTaskInputs
}
    ${EngagementTaskContainerWithSubSectionFieldsFragmentDoc}`;
export const CompanyDisclosureHistoricalEmissionsYearFieldsFragmentDoc = gql`
    fragment CompanyDisclosureHistoricalEmissionsYearFields on EmissionsYear {
  id
  publicDisclosureId
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  revenueUsd
  percentageCleanEnergy
  totalMwh
  ...EmissionsYearScope1Fields
  ...EmissionsYearScope2Fields
  ...EmissionsYearScope3Fields
}
    ${EmissionsYearScope1FieldsFragmentDoc}
${EmissionsYearScope2FieldsFragmentDoc}
${EmissionsYearScope3FieldsFragmentDoc}`;
export const ProposeTargetFieldsFragmentDoc = gql`
    fragment ProposeTargetFields on EngagementTask {
  proposeTargetFilters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  proposeTargetEmissionsTarget {
    id
    base
    frequency
    values
  }
  proposeTargetIntensityType
}
    `;
export const ExpenseCategoryFieldsFragmentDoc = gql`
    fragment ExpenseCategoryFields on DiscloseEmissionsExpenseCategories {
  beaCode
  inputUnit
  inputQuantity
  kgco2e
}
    `;
export const EngagementTaskFieldsFragmentDoc = gql`
    fragment EngagementTaskFields on EngagementTask {
  id
  crossOrgModelId
  reportingYear
  facilityName
  submittedAt
  createdAt
  dueDate
  status
  surveyKind
  supplierFacingDescription
  watershedGeneric
  engagementTaskConfig {
    ...EngagementTaskConfigFields
  }
  currentAssignee
  rootAssignee
  supplierAssignee
  initialSupplierAssigneeEmail
  approvedAt
  statusChangedAt
  rootOrgId
  rootCompany {
    id
    name
    fullLogoUrl
    url
  }
  supplierOrgId
  latestCeeWorkflowId
  latestPleeWorkflowId
  latestFootprintDisclosureWorkflowId
  company {
    id
    name
    naicsCode
    sbtiStage
    publicDisclosures: disclosuresV2(
      opts: {publicDisclosureFilters: {excludeCdp: true}, historicalYearFilters: {}}
    ) {
      id
      publicDisclosure {
        id
        publishingYear
      }
      historicalEmissionsYears {
        ...CompanyDisclosureHistoricalEmissionsYearFields
      }
    }
  }
  ...ProposeTargetFields
  expenseCategories {
    ...ExpenseCategoryFields
  }
  engagementTaskBatchId
}
    ${EngagementTaskConfigFieldsFragmentDoc}
${CompanyDisclosureHistoricalEmissionsYearFieldsFragmentDoc}
${ProposeTargetFieldsFragmentDoc}
${ExpenseCategoryFieldsFragmentDoc}`;
export const AssetCorporateDataForTasksFragmentDoc = gql`
    fragment AssetCorporateDataForTasks on AssetCorporate {
  id
  name
  companyId
  company {
    ...CompanyFieldsForCCIS
    parentRelationships(onlyGetActiveRelationships: true) {
      ...ParentRelationshipFields
    }
    parentRelationshipsForSidebar: parentRelationships {
      ...ParentRelationshipForSidebarFields
    }
    contacts {
      ...SupplierContactFields
    }
  }
  engagementTasks {
    ...EngagementTaskFields
  }
}
    ${CompanyFieldsForCcisFragmentDoc}
${ParentRelationshipFieldsFragmentDoc}
${ParentRelationshipForSidebarFieldsFragmentDoc}
${SupplierContactFieldsFragmentDoc}
${EngagementTaskFieldsFragmentDoc}`;
export const CompanyAllFieldsFragmentDoc = gql`
    fragment CompanyAllFields on Company {
  id
  alternateNames
  betterCompanyId
  country
  createdAt
  deletedAt
  description
  isDemo
  logoUrl
  naicsCode
  naicsCodeSource
  name
  sfdcOpportunityId
  sfdcStage
  source
  sustainabilityWebsiteUrl
  updatedAt
  url
  userEditorId
  logoUrlId
  sustainabilityWebsiteUrlId
  companyWebsiteUrlId
  companyIngestionRecordUpdateId
  sourceId
}
    `;
export const AssetCorporateForMatchingFragmentDoc = gql`
    fragment AssetCorporateForMatching on AssetCorporate {
  id
  name
  company {
    id
    ...CompanyAllFields
  }
}
    ${CompanyAllFieldsFragmentDoc}`;
export const FinanceDataFlagForFlagViewFragmentDoc = gql`
    fragment FinanceDataFlagForFlagView on FinanceDataFlag {
  id
  finSnapAssetId
  finSnapYearId
  finSnapFundId
  finSnapHoldingId
  assetYearId
  rowData
  checkKind
  objectKind
  category
  title
  description
  stepsToResolve
  columns
}
    `;
export const FinanceImportsForRenderFragmentDoc = gql`
    fragment financeImportsForRender on FinanceImport {
  id
  createdAt
  errorMessages
  fileMetadata {
    id
    name
    uploader {
      id
      displayName
    }
  }
  dataFormat
  recordsCreated
  recordsUpdated
  accepted
}
    `;
export const FinanceSandboxHistoryFieldsFragmentDoc = gql`
    fragment FinanceSandboxHistoryFields on EmissionsYear {
  id
  createdAt
  scope1
  scope2Location
  scope2Market
  scope3
  footprintEstimateOutputId
  buildingEstimateOutputId
  reportingYear
  revenue
  revenueCurrency
  numEmployees
  priorEstimateInputs
}
    `;
export const FinanceSettingsFieldsFragmentDoc = gql`
    fragment FinanceSettingsFields on FinanceSettings {
  id
  estimateDownstreamScope3
  disabledFinanceDataFlags
}
    `;
export const WaterfallBlockAllFieldsFragmentDoc = gql`
    fragment WaterfallBlockAllFields on WaterfallBlock {
  id
  label
  value
  isIntermediateSum
  isSum
}
    `;
export const FootprintVersionForDrilldownFragmentDoc = gql`
    fragment FootprintVersionForDrilldown on FootprintVersion {
  id
  kind
  createdAt
  originalFootprintSnapshotId
}
    `;
export const HasAccessToSummaryReport_QueryFragmentDoc = gql`
    fragment hasAccessToSummaryReport_query on Query {
  activeOrganization {
    employeeReportId
    footprintKindsMeta {
      footprintInterval
    }
  }
}
    `;
export const NextStepsSection_QueryFragmentDoc = gql`
    fragment NextStepsSection_query on Query {
  ...hasAccessToSummaryReport_query
}
    ${HasAccessToSummaryReport_QueryFragmentDoc}`;
export const FootprintAssemblyRunForChangelogFragmentDoc = gql`
    fragment FootprintAssemblyRunForChangelog on FootprintAssemblyRun {
  id
  createdAt
  status
  generatedBy {
    id
    displayName
  }
  workflowId
  footprintSnapshotId
}
    `;
export const FootprintVersionForChangelogV3FragmentDoc = gql`
    fragment FootprintVersionForChangelogV3 on FootprintVersion {
  id
  createdAt
  kind
  pinInfo {
    id
    name
  }
  userVisibleFootprintSnapshotId
  measurementProjects {
    id
    coverageInterval
  }
  footprintInterval
}
    `;
export const FootprintVersionForTimelineFragmentDoc = gql`
    fragment FootprintVersionForTimeline on FootprintVersion {
  id
  createdAt
  versionName
  versionIdentifier
  userChangelogMd
  publishableUserChangelogMd
  kind
  pinInfo {
    id
    name
    description
  }
  canUserPublish
  isGeneratedFromProduct
  isAutoGenerated
  userVisibleFootprintSnapshotId
  originalFootprintSnapshotId
  generatedBy {
    id
    displayName
  }
  measurementProjects {
    id
    name
    coverageInterval
  }
  footprintInterval
  watershedFootprintReviewRequest {
    id
    status
  }
}
    `;
export const FootprintExclusionRuleForEditorFragmentDoc = gql`
    fragment FootprintExclusionRuleForEditor on FootprintExclusionRule {
  id
  filtersSourceOne {
    dimension
    operator
    value
  }
  filtersSourceTwo {
    dimension
    operator
    value
  }
  timeIntervals
  ruleOrder
  stableId
  outputValue
  selectedSource
  deduplicationNote
}
    `;
export const FootprintCategoryRuleForEditorFragmentDoc = gql`
    fragment FootprintCategoryRuleForEditor on FootprintCategoryRule {
  id
  filters {
    dimension
    operator
    value
  }
  outputColumn
  outputValue
  ruleOrder
  stableId
}
    `;
export const DrilldownSavedViewFieldsFragmentDoc = gql`
    fragment DrilldownSavedViewFields on DrilldownSavedView {
  id
  name
  description
  isPrivate
  footprintKind
  aggregateKind
  inclusionKind
  intensityDenominatorName
  timeSeriesKind
  hasPieChart
  hasTimeSeriesChart
  hasDataTable
  filterExpressions {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  groupBy
  searchTerm
  defaultTimeInterval
  biSavedViewId
}
    `;
export const ExtBartRowsDataLineageFragmentDoc = gql`
    fragment ExtBartRowsDataLineage on FileDataLineage {
  batType
  fileMetadataId
  rows
}
    `;
export const UserUploadedTableForSourceFileDataLineageFragmentDoc = gql`
    fragment UserUploadedTableForSourceFileDataLineage on UserUploadedTable {
  id
  sheetName
  numRows
  schema {
    ...UutSchemaForIngestion
  }
  userVisibleAttempt(includeDeleted: true) {
    id
    name
    category
    createdAt
    size
    uploader {
      id
      name
    }
    userUpload {
      id
      revisionRoot
      measurementProject(includeDeleted: true) {
        id
        name
        deletedAt
      }
      userUploadTask {
        id
        datasource {
          id
          name
        }
      }
    }
  }
}
    ${UutSchemaForIngestionFragmentDoc}`;
export const UutRowsDataLineageFragmentDoc = gql`
    fragment UutRowsDataLineage on UutRowsDataLineage {
  fileMetadataId
  rows
  userUploadedTable {
    ...UserUploadedTableForSourceFileDataLineage
  }
}
    ${UserUploadedTableForSourceFileDataLineageFragmentDoc}`;
export const SelectedCalculationDataLineageFragmentDoc = gql`
    fragment SelectedCalculationDataLineage on CalculationDataLineagePayload {
  extBartRows {
    ...ExtBartRowsDataLineage
  }
  fileMetadataIds
  uutRows {
    ...UutRowsDataLineage
  }
  isTruncated
}
    ${ExtBartRowsDataLineageFragmentDoc}
${UutRowsDataLineageFragmentDoc}`;
export const FilesForMethodologyExplainerFragmentDoc = gql`
    fragment FilesForMethodologyExplainer on FootprintAnalysisFile {
  id
  name
  category
  createdAt
  uploader {
    id
    name
  }
  userUploadTask {
    id
    datasource {
      id
      name
    }
  }
}
    `;
export const UnitConversionForMethodologyExplainerFragmentDoc = gql`
    fragment UnitConversionForMethodologyExplainer on UnitConversionRecord {
  assumptionId
  assumptionUuid
  conversionDescription
  conversionMultiplier
  inputUnit
  outputUnit
}
    `;
export const AssumptionSourceForMethodologyExplainerFragmentDoc = gql`
    fragment AssumptionSourceForMethodologyExplainer on AssumptionSource {
  id
  externalSourceNotes
  userVisibleSourceUrl
  hasMultiplierLicenseRestrictions
}
    `;
export const AssumptionForMethodologyExplainerFragmentDoc = gql`
    fragment AssumptionForMethodologyExplainer on AssumptionRecordForDashboard {
  id
  conversionDescription
  conversionMultiplier
  inputUnit
  outputUnit
  assumptionSource {
    ...AssumptionSourceForMethodologyExplainer
  }
  assumptionDescription {
    id
    updatedAt
    assumptionId
    userVisibleConversionDescription
  }
}
    ${AssumptionSourceForMethodologyExplainerFragmentDoc}`;
export const ConversionChainItemForMethodologyExplainerFragmentDoc = gql`
    fragment ConversionChainItemForMethodologyExplainer on ConversionChainItemForDashboard {
  __typename
  ... on UnitConversionRecord {
    ...UnitConversionForMethodologyExplainer
  }
  ... on AssumptionRecordForDashboard {
    ...AssumptionForMethodologyExplainer
  }
}
    ${UnitConversionForMethodologyExplainerFragmentDoc}
${AssumptionForMethodologyExplainerFragmentDoc}`;
export const ConversionFactorForMethodologyExplainerFragmentDoc = gql`
    fragment ConversionFactorForMethodologyExplainer on ConversionFactorForDashboard {
  id
  conversionFactorId
  inputUnit
  outputUnit
  conversionChain {
    ...ConversionChainItemForMethodologyExplainer
  }
}
    ${ConversionChainItemForMethodologyExplainerFragmentDoc}`;
export const EmissionsFactorForMethodologyExplainerFragmentDoc = gql`
    fragment EmissionsFactorForMethodologyExplainer on EmissionsFactorRecordForDashboard {
  id
  emissionsFactorId
  kgCo2e
  unit
  efName
  assumptionSource {
    ...AssumptionSourceForMethodologyExplainer
  }
  emissionsFactorDescription {
    id
    updatedAt
    emissionsFactorId
    userVisibleEfName
  }
  supplierDisclosure(
    opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}
  ) {
    ...DisclosureForMethodologyExplainer
  }
  isVendorSpecific
  startMonth
  endMonth
}
    ${AssumptionSourceForMethodologyExplainerFragmentDoc}
${DisclosureForMethodologyExplainerFragmentDoc}`;
export const CalculationChainForMethodologyExplainerFragmentDoc = gql`
    fragment CalculationChainForMethodologyExplainer on CalculationChain {
  __typename
  conversionFactor {
    ...ConversionFactorForMethodologyExplainer
  }
  emissionsFactor {
    ...EmissionsFactorForMethodologyExplainer
  }
  locationEmissionsFactor {
    ...EmissionsFactorForMethodologyExplainer
  }
  inputQuantity
  inputUnit
  convertedQuantity
  tco2eQuantity
  tco2eLocationQuantity
  sumOfMeasure
  emissionsModelVersionId
}
    ${ConversionFactorForMethodologyExplainerFragmentDoc}
${EmissionsFactorForMethodologyExplainerFragmentDoc}`;
export const SingleRowTraceFragmentFragmentDoc = gql`
    fragment SingleRowTraceFragment on SingleRowTrace {
  id
  trace
  traceDetails {
    referenceValueDetails
    emissionsModelVariableDetails
    bartValueDetails
    emissionsModelVersionId
    emissionsModelEvaluationId
  }
  ghgCategoryId
}
    `;
export const SingleRowMethodologyExplainerFieldsFragmentDoc = gql`
    fragment SingleRowMethodologyExplainerFields on SingleRowMethodologyExplainer {
  id
  files {
    ...FilesForMethodologyExplainer
  }
  pipelineV2Chain {
    ...CalculationChainForMethodologyExplainer
  }
  marketTrace {
    ...SingleRowTraceFragment
  }
  locationTrace {
    ...SingleRowTraceFragment
  }
  biogenicCo2Trace {
    ...SingleRowTraceFragment
  }
  biogenicCo2LocationTrace {
    ...SingleRowTraceFragment
  }
  cleanPowerAvoidedEmissionsTrace {
    ...SingleRowTraceFragment
  }
  wasteEmissionsTrace {
    ...SingleRowTraceFragment
  }
  microplasticsInflowEmissionsTrace {
    ...SingleRowTraceFragment
  }
  microplasticsOutflowEmissionsTrace {
    ...SingleRowTraceFragment
  }
  substanceInflowEmissionsTrace {
    ...SingleRowTraceFragment
  }
  substanceOutflowEmissionsTrace {
    ...SingleRowTraceFragment
  }
  waterConsumptionEmissionsTrace {
    ...SingleRowTraceFragment
  }
  footprintExclusionRule {
    ...FootprintExclusionRuleForMethodologyExplainer
  }
}
    ${FilesForMethodologyExplainerFragmentDoc}
${CalculationChainForMethodologyExplainerFragmentDoc}
${SingleRowTraceFragmentFragmentDoc}
${FootprintExclusionRuleForMethodologyExplainerFragmentDoc}`;
export const FootprintVersionForProductsFragmentDoc = gql`
    fragment FootprintVersionForProducts on FootprintVersion {
  id
  kind
  createdAt
  originalFootprintSnapshotId
}
    `;
export const EmissionsSliceMaterialFootprintRowUpdatedTaxonomyFragmentDoc = gql`
    fragment EmissionsSliceMaterialFootprintRowUpdatedTaxonomy on MaterialFootprintRowUpdatedTaxonomy {
  id
  orgMaterial
  vectors {
    vectorName
    vectorValues
  }
  product {
    id
    product
    totalEmissions
  }
  emissionsPerKg
  inputQuantity
  totalEmissions
  totalEmissionsPercent
}
    `;
export const EmissionsSliceProductFootprintRowUpdatedTaxonomyFragmentDoc = gql`
    fragment EmissionsSliceProductFootprintRowUpdatedTaxonomy on ProductFootprintRowUpdatedTaxonomy {
  id
  product
  vectors {
    vectorName
    vectorValues
  }
  emissionsPerUnit
  unitsProduced
  totalEmissions
  totalEmissionsPercent
  categoryEmissionsPercent
  relatedMaterials {
    id
    material
    percentageOfProduct
  }
}
    `;
export const FootprintFieldsFragmentDoc = gql`
    fragment FootprintFields on Footprint {
  id
  name
  orgId
  locked
  createdAt
  createdBy {
    id
    name
    displayName
  }
  deletedAt
  updatedAt
  defaultFootprintSnapshot {
    id
    footprintInterval
    isLatest
  }
  status
}
    `;
export const FootprintVersionForFootprintSelectorFragmentDoc = gql`
    fragment FootprintVersionForFootprintSelector on FootprintVersion {
  id
  versionName
  userChangelogMd
  kind
  createdAt
  versionIdentifier
  generatedBy {
    id
    displayName
  }
  userVisibleFootprintSnapshot {
    id
  }
  configuration {
    id
  }
  footprintInterval
  watershedFootprintReviewRequest {
    id
    user {
      id
      displayName
    }
    status
  }
  status
}
    `;
export const FootprintFieldsForFootprintSelectorFragmentDoc = gql`
    fragment FootprintFieldsForFootprintSelector on Footprint {
  ...FootprintFields
  footprintVersions {
    ...FootprintVersionForFootprintSelector
  }
}
    ${FootprintFieldsFragmentDoc}
${FootprintVersionForFootprintSelectorFragmentDoc}`;
export const SupplierAttachmentFieldsFragmentDoc = gql`
    fragment SupplierAttachmentFields on SupplierAttachment {
  id
  fileMetadataId
  fileName
  companyId
  isImported
  crossOrgModelId
}
    `;
export const TargetsDialogDisclosureTargetDetailsFragmentDoc = gql`
    fragment TargetsDialogDisclosureTargetDetails on DisclosureTargetDetails {
  id
  name
  description
  baseYear
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  reductionRate
  intensityType
  emissionsTarget {
    id
    frequency
    base
    values
  }
  unit
  unitDescription
}
    `;
export const DisclosureForTargetsDialogFragmentDoc = gql`
    fragment DisclosureForTargetsDialog on Disclosure {
  id
  company {
    id
  }
  publicDisclosure {
    id
    reportType
  }
  privateDisclosure {
    id
    privateDisclosureType
  }
  targets {
    ...TargetsDialogDisclosureTargetDetails
  }
}
    ${TargetsDialogDisclosureTargetDetailsFragmentDoc}`;
export const CompanyEngagementTaskFieldsForOverviewFragmentDoc = gql`
    fragment CompanyEngagementTaskFieldsForOverview on EngagementTask {
  id
  createdAt
  approvedAt
  submittedAt
  companyId
  dueDate
  rootAssignee
  rootAssigneeDisplayName
  company {
    id
    name
  }
  rootCompanyId
  status
  statusChangedAt
  engagementTaskConfigId
  engagementTaskConfig {
    id
    name
    category
    rootFacingDescription
    baseConfig {
      id
    }
  }
  engagementTaskBatchId
  initialSupplierAssigneeEmail
}
    `;
export const EngagementTaskTypeAndStatusFieldsFragmentDoc = gql`
    fragment EngagementTaskTypeAndStatusFields on EngagementTask {
  id
  submittedAt
  engagementTaskConfig {
    type
  }
}
    `;
export const EngagementNotificationSettingFieldsFragmentDoc = gql`
    fragment EngagementNotificationSettingFields on EngagementNotificationSetting {
  id
  userId
  companyId
  engagementTaskId
  shouldNotify
}
    `;
export const EngagementCohortWithCompanyIdsFieldsFragmentDoc = gql`
    fragment EngagementCohortWithCompanyIdsFields on EngagementCohortWithCompanyIds {
  id
  name
  companyIds
}
    `;
export const EngagementTaskConfigForEditingFragmentDoc = gql`
    fragment EngagementTaskConfigForEditing on EngagementTaskConfig {
  id
  name
  orgId
  canDelete
  type
  category
  order
  publishedAt
  archivedAt
  baseConfig {
    id
  }
}
    `;
export const SelfServeSupplierColumnFieldsFragmentDoc = gql`
    fragment SelfServeSupplierColumnFields on SupplierTableColumn {
  id
  field
  headerName
  questionKey
  columnFormat
  baseEngagementTaskConfigId
  isSupplierScore
  isSupplierScoreCriteria
}
    `;
export const EngagementTaskDeployStatusFieldsFragmentDoc = gql`
    fragment EngagementTaskDeployStatusFields on EngagementTaskDeployStatus {
  id
  success
  company {
    id
    name
    contacts {
      id
      name
      role
      email
    }
    isWatershedCorporateCustomer
  }
  latestContactEmail
  latestEmailSent {
    id
    users {
      id
      recipientEmail
      latestEmailStatus
    }
  }
}
    `;
export const EngagementTaskBatchFieldsForBulkDeployStatusFragmentDoc = gql`
    fragment EngagementTaskBatchFieldsForBulkDeployStatus on EngagementTaskBatch {
  id
  deployStatuses {
    ...EngagementTaskDeployStatusFields
  }
}
    ${EngagementTaskDeployStatusFieldsFragmentDoc}`;
export const SupplierCategorizedEmissionDataFieldsFragmentDoc = gql`
    fragment SupplierCategorizedEmissionDataFields on CategorizedEmissionData {
  businessCategory
  businessSubcategory
  proportion
  amountKgco2e
}
    `;
export const SupplierCustomDataFieldsFragmentDoc = gql`
    fragment SupplierCustomDataFields on SupplierCustomData {
  id
  priority
  notes
  customData
}
    `;
export const SupplierRowFieldsFragmentDoc = gql`
    fragment SupplierRowFields on SupplierRow {
  id
  name
  groupingKey
  categoryId
  subcategoryId
  kgco2eQuantity
  convertedUnit
  convertedQuantity
  month
  ghgCategoryId
  beaCode
}
    `;
export const CompanyFieldsForCcisAndParentsFragmentDoc = gql`
    fragment CompanyFieldsForCCISAndParents on Company {
  ...CompanyFieldsForCCIS
  parentRelationships(onlyGetActiveRelationships: true) {
    ...ParentRelationshipFields
  }
  parentRelationshipsForSidebar: parentRelationships {
    ...ParentRelationshipForSidebarFields
  }
}
    ${CompanyFieldsForCcisFragmentDoc}
${ParentRelationshipFieldsFragmentDoc}
${ParentRelationshipForSidebarFieldsFragmentDoc}`;
export const SupplierHistoricalEmissionsFieldsFragmentDoc = gql`
    fragment SupplierHistoricalEmissionsFields on SupplierHistoricalEmissions {
  scope1
  scope2
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
  scope1Ratio
  scope2Ratio
  scope3Ratio
  publishingYear
  reportingYear
  surveyId
  source
  revenueUsd
  revenue
  revenueCurrency
  publicUrl
  units
  expenseCategory
}
    `;
export const SupplierFieldsFragmentDoc = gql`
    fragment SupplierFields on Supplier {
  id
  name
  footprintVendorNames
  supplierType
  totalKgco2e
  totalKgco2eFromFootprint
  totalSpending
  totalSpendUsd
  isTotalSpendingPartial
  latestDisclosureDateTime
  latestCdpDisclosurePublishingYear
  percentEmissions
  climateProgress
  sbtiStage
  company {
    ...CompanyFieldsForCCISAndParents
  }
  historicalEmissions {
    ...SupplierHistoricalEmissionsFields
  }
  industryAverageAllocatedEmissions {
    ...SupplierHistoricalEmissionsFields
  }
  customDataV2
  notes
  footprintTags {
    key
    value
  }
  ghgCategoryIds
}
    ${CompanyFieldsForCcisAndParentsFragmentDoc}
${SupplierHistoricalEmissionsFieldsFragmentDoc}`;
export const SupplierEmissionsFactorsFragmentDoc = gql`
    fragment SupplierEmissionsFactors on EmissionsFactorRecordForDashboard {
  id
  isVendorSpecific
  efName
  supplierDisclosure(opts: {historicalYearFilters: {}}) {
    id
    historicalEmissionsYears {
      id
      reportingYear
    }
    publicDisclosure {
      id
      reportType
    }
    privateDisclosure {
      id
      privateDisclosureType
    }
  }
  emissionsFactorDescription {
    id
    userVisibleEfName
  }
}
    `;
export const SupplierEmissionsModelEmissionsFactorsFragmentDoc = gql`
    fragment SupplierEmissionsModelEmissionsFactors on EmissionsModelEmissionsFactor {
  id
  isSupplierSpecific
  externalDescription
  supplierDisclosure(opts: {historicalYearFilters: {}}) {
    id
    historicalEmissionsYears {
      id
      reportingYear
    }
    publicDisclosure {
      id
      reportType
    }
    privateDisclosure {
      id
      privateDisclosureType
    }
  }
}
    `;
export const SupplierEmissionsByMonthFieldsFragmentDoc = gql`
    fragment SupplierEmissionsByMonthFields on LabeledTimeseries {
  label
  timeseries {
    base
    values
  }
}
    `;
export const PlanForPlanTabFragmentDoc = gql`
    fragment PlanForPlanTab on Plan {
  id
  name
  state
  createdAt
  isHidden
  forecast {
    id
    footprintSnapshotId
    overrideFootprintKind
    referencePeriodInterval
  }
  relevantFootprintFields
}
    `;
export const SupplierTableColumnFieldsFragmentDoc = gql`
    fragment SupplierTableColumnFields on SupplierTableColumn {
  id
  field
  headerName
  description
  visible
  columnFormat
  selectOptions
  footprintTagName
  csatTagName
  isSupplierScore
  isSupplierScoreCriteria
  questionKey
}
    `;
export const SupplierTableFieldsFragmentDoc = gql`
    fragment SupplierTableFields on SupplierTable {
  columns {
    ...SupplierTableColumnFields
  }
}
    ${SupplierTableColumnFieldsFragmentDoc}`;
export const EngagementFunnelStepFieldsFragmentDoc = gql`
    fragment EngagementFunnelStepFields on EngagementFunnelStep {
  id
  stepName
  stepType
  sbtStage
  engagementTaskConfigId
  supplierTableColumn {
    id
    field
  }
  supplierTableColumnValues
}
    `;
export const EngagementFunnelsChartFieldsFragmentDoc = gql`
    fragment EngagementFunnelsChartFields on EngagementFunnel {
  id
  name
  steps {
    ...EngagementFunnelStepFields
  }
}
    ${EngagementFunnelStepFieldsFragmentDoc}`;
export const EngagementFunnelsProgressTrackerFieldsFragmentDoc = gql`
    fragment EngagementFunnelsProgressTrackerFields on EngagementFunnel {
  id
  name
  displayOnSupplierDetailsPage
  steps {
    id
    stepName
    stepType
    sbtStage
    engagementTaskConfigId
    supplierTableColumn {
      id
      field
    }
    supplierTableColumnValues
  }
}
    `;
export const SupplierChartConfigFieldsForMetricsChartFragmentDoc = gql`
    fragment SupplierChartConfigFieldsForMetricsChart on SupplierMetricsChartConfig {
  id
  title
  metricsTypes
}
    `;
export const SupplierChartConfigFieldsFragmentDoc = gql`
    fragment SupplierChartConfigFields on SupplierChartConfig {
  __typename
  id
  orgId
  title
  description
  chartType
  ... on SupplierStandardChartConfig {
    colorScale
    xField
    aggregateType
  }
  ... on SupplierEngagementFunnelChartConfig {
    funnels {
      id
      ...EngagementFunnelsChartFields
      ...EngagementFunnelsProgressTrackerFields
    }
  }
  ... on SupplierTaskStatusChangeOverTimeChartConfig {
    colorScale
    tasksStatusChangeConfig {
      status
      engagementTaskConfigId
    }
    isCumulative
  }
  ... on SupplierMetricsChartConfig {
    id
    ...SupplierChartConfigFieldsForMetricsChart
  }
  ... on SupplierSbtCommitmentOverTimeChartConfig {
    colorScale
  }
}
    ${EngagementFunnelsChartFieldsFragmentDoc}
${EngagementFunnelsProgressTrackerFieldsFragmentDoc}
${SupplierChartConfigFieldsForMetricsChartFragmentDoc}`;
export const SupplierViewFieldsFragmentDoc = gql`
    fragment SupplierViewFields on SupplierView {
  id
  reductionPlan {
    ...PlanForPlanTab
  }
  table {
    ...SupplierTableFields
  }
  columnOrder {
    columnId
    visible
    width
  }
  charts: chartsV2 {
    ...SupplierChartConfigFields
  }
  chartOrder {
    chartId
    visible
  }
  name
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  isDefault
}
    ${PlanForPlanTabFragmentDoc}
${SupplierTableFieldsFragmentDoc}
${SupplierChartConfigFieldsFragmentDoc}`;
export const EmissionsYearForSuppliersTableFragmentDoc = gql`
    fragment EmissionsYearForSuppliersTable on EmissionsYear {
  id
  reportingYear
  expenseCategory
  units
  ...EmissionsYearScope1Fields
  ...EmissionsYearScope2Fields
  ...EmissionsYearScope3Fields
}
    ${EmissionsYearScope1FieldsFragmentDoc}
${EmissionsYearScope2FieldsFragmentDoc}
${EmissionsYearScope3FieldsFragmentDoc}`;
export const DisclosureForSuppliersTableFragmentDoc = gql`
    fragment DisclosureForSuppliersTable on Disclosure {
  id
  publicDisclosure {
    publishingYear
    reportType
    publicUrl
    cdpVendorData {
      id
      reportingYear
    }
  }
  privateDisclosure {
    privateDisclosureType
    createdAt
    orgName
  }
  targets {
    id
    name
    description
    baseYear
    filters {
      conjunction
      expressions {
        field
        operator
        value
      }
    }
    emissionsTarget {
      ...SimpleTimeseriesForDisclosures
    }
    intensityType
    unit
  }
  climateCommitments {
    ...CompanyClimateCommitmentFieldsForCCIS
  }
  initiatives {
    ...SupplierDisclosureInitiativeFields
  }
  historicalEmissionsYears {
    ...EmissionsYearForSuppliersTable
  }
}
    ${SimpleTimeseriesForDisclosuresFragmentDoc}
${CompanyClimateCommitmentFieldsForCcisFragmentDoc}
${SupplierDisclosureInitiativeFieldsFragmentDoc}
${EmissionsYearForSuppliersTableFragmentDoc}`;
export const SuppliersDataFieldsFragmentDoc = gql`
    fragment SuppliersDataFields on SuppliersData {
  id
  data
}
    `;
export const EngagementCohortFieldsFragmentDoc = gql`
    fragment EngagementCohortFields on EngagementCohort {
  id
  name
}
    `;
export const SuppliersSettingsFieldsFragmentDoc = gql`
    fragment SuppliersSettingsFields on SuppliersSettings {
  id
  includedGhgCategories
  viewLatestSupplierMapping
  footprintSnapshotIds
  footprintInterval
}
    `;
export const CompanyPortalSettingsFieldsFragmentDoc = gql`
    fragment CompanyPortalSettingsFields on CompanyPortalSettings {
  id
  portalCallout
  sidebarContent
  portalTitle
  toggleHeaderImage
}
    `;
export const BulkDeployStatusCompanyFieldsFragmentDoc = gql`
    fragment BulkDeployStatusCompanyFields on Company {
  id
  name
}
    `;
export const SupplierScoreCriteriaFieldsFragmentDoc = gql`
    fragment SupplierScoreCriteriaFields on SupplierScoreCriteria {
  id
  label
  supplierScoreId
  filters {
    dimension
    operator
    value
  }
  field
  weight
}
    `;
export const SupplierScorecardFieldsFragmentDoc = gql`
    fragment SupplierScorecardFields on SupplierScorecard {
  id
  name
  criteria {
    ...SupplierScoreCriteriaFields
  }
  field
}
    ${SupplierScoreCriteriaFieldsFragmentDoc}`;
export const SupplierForReductionsForecastFragmentDoc = gql`
    fragment SupplierForReductionsForecast on Supplier {
  id
  name
  company {
    id
  }
  disclosures(
    opts: {targetFilters: {}, climateCommitmentFilters: {}, historicalYearFilters: {allScopes: true}, publicDisclosureFilters: {}, privateDisclosureFilters: {orgId: $orgId}, initiativeFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
  industryAverageAllocatedEmissions {
    ...SupplierHistoricalEmissionsFields
  }
  footprintVendorNames
  cleanedFootprintVendorNames
  totalSpending
}
    ${SupplierDisclosureFieldsFragmentDoc}
${SupplierHistoricalEmissionsFieldsFragmentDoc}`;
export const BiSavedViewForLandingPageFragmentDoc = gql`
    fragment BiSavedViewForLandingPage on BiSavedView {
  id
  stableId
  name
  owner {
    id
    name
    displayName
  }
  updatedAt
  createdAt
}
    `;
export const BenchmarkFieldsForExplorerFragmentDoc = gql`
    fragment BenchmarkFieldsForExplorer on Benchmark {
  id
  reportId
  name
  reportingYear
  kgco2eByScope {
    scope
    kgco2e
  }
  companyId
}
    `;
export const ContactFieldsForEmailFragmentDoc = gql`
    fragment ContactFieldsForEmail on IContact {
  id
  email
  name
  role
  companyId
}
    `;
export const FinanceWorksheetColumnAssetGridFragmentDoc = gql`
    fragment FinanceWorksheetColumnAssetGrid on FinanceWorksheetColumn {
  id
  financeWorksheetId
  field
  columnFormat
  columnConfiguration {
    ... on GridColumnConfigurationSingleSelect {
      __typename
      columnFormat
      options
    }
    ... on GridColumnConfigurationMultiSelect {
      __typename
      columnFormat
      options
    }
  }
  headerName
  description
  width
}
    `;
export const FinanceColumnAssetGridFragmentDoc = gql`
    fragment FinanceColumnAssetGrid on FinanceColumn {
  id
  field
  columnFormat
  columnConfiguration {
    ... on GridColumnConfigurationSingleSelect {
      __typename
      columnFormat
      options
    }
  }
  headerName
  description
  width
}
    `;
export const FinanceWorksheetAssetGridFragmentDoc = gql`
    fragment FinanceWorksheetAssetGrid on FinanceWorksheet {
  id
  hiddenColumns
  orgColumns {
    id
    ...FinanceColumnAssetGrid
  }
  standardView
  sortModel {
    field
    sort
  }
}
    ${FinanceColumnAssetGridFragmentDoc}`;
export const FinanceTagForGridFragmentDoc = gql`
    fragment FinanceTagForGrid on FinanceTag {
  id
  name
  description
  columnFormat
  columnConfiguration
  tagType
}
    `;
export const AssetPersonalMotorVehicleInsuranceAllFieldsFragmentDoc = gql`
    fragment AssetPersonalMotorVehicleInsuranceAllFields on AssetPersonalMotorVehicleInsurance {
  __typename
  id
  orgId
  createdAt
  updatedAt
  name
  currencyCode
  tagData
}
    `;
export const FinanceTagAllFieldsFragmentDoc = gql`
    fragment FinanceTagAllFields on FinanceTag {
  __typename
  id
  orgId
  createdAt
  updatedAt
  createdBy
  updatedBy
  name
  description
  columnFormat
  tagType
  columnConfiguration
  tagCategory
}
    `;
export const CategorizedEmissionDataFieldsFragmentDoc = gql`
    fragment CategorizedEmissionDataFields on CategorizedEmissionData {
  businessCategory
  businessSubcategory
  proportion
  amountKgco2e
}
    `;
export const GhgCategorizedEmissionDataFieldsFragmentDoc = gql`
    fragment GhgCategorizedEmissionDataFields on GhgCategorizedEmissionData {
  ghgCategoryId
  businessSubcategory
  proportion
  amountKgco2e
}
    `;
export const AssetCommentAttachmentForSidebarFragmentDoc = gql`
    fragment AssetCommentAttachmentForSidebar on AssetCommentAttachment {
  id
  assetCommentId
  fileMetadata {
    id
    name
  }
}
    `;
export const AssetCommentAllFieldsFragmentDoc = gql`
    fragment AssetCommentAllFields on AssetComment {
  orgId
  id
  createdAt
  updatedAt
  commentText
  userId
  lastEdited
  assetCorporateId
  assetCommercialRealEstateId
  assetGroupId
  deletedAt
  commentHistory
  user {
    id
    name
  }
  attachments {
    id
    ...AssetCommentAttachmentForSidebar
  }
}
    ${AssetCommentAttachmentForSidebarFragmentDoc}`;
export const FinanceHeaderCurrentFragmentDoc = gql`
    fragment FinanceHeaderCurrent on FinanceHeader {
  title
  fundExcluded
  isAssetGroupItem
}
    `;
export const FinanceSavedViewAllFieldsFragmentDoc = gql`
    fragment FinanceSavedViewAllFields on FinanceSavedView {
  id
  name
  standardView
  fundId
  layout
  metrics
  charts
  filters
}
    `;
export const DatasetWithStageForMeasurementPageFragmentDoc = gql`
    fragment DatasetWithStageForMeasurementPage on DatasetWithStage {
  id
  dataset {
    ...DatasetForMeasurementPage
    canonicalDataset {
      id
      kind
    }
  }
  areAllTasksComplete
  areUploadsComplete
  isUsableInFootprint
}
    ${DatasetForMeasurementPageFragmentDoc}`;
export const MeasurementTaskForMeasurementPageFragmentDoc = gql`
    fragment MeasurementTaskForMeasurementPage on MeasurementTask {
  id
  title
  link
  assignees {
    id
    name
    displayName
    didLatestEmailBounce
  }
  approvers {
    id
    name
    displayName
  }
  lockState
  isAssignedToWatershed
  statusSimplified
  object {
    ...MeasurementObjectForMeasurementPage
  }
}
    ${MeasurementObjectForMeasurementPageFragmentDoc}`;
export const NextMeasurementProjectDataFragmentDoc = gql`
    fragment NextMeasurementProjectData on MeasurementProject {
  id
  name
  active
  coverageStartDate
  footprintStatus
  measurementTimeline {
    ...MeasurementTimelineForMeasurementPage
  }
  datasetsWithStages {
    ...DatasetWithStageForMeasurementPage
  }
  measurementTasks {
    ...MeasurementTaskForMeasurementPage
  }
}
    ${MeasurementTimelineForMeasurementPageFragmentDoc}
${DatasetWithStageForMeasurementPageFragmentDoc}
${MeasurementTaskForMeasurementPageFragmentDoc}`;
export const ActiveMeasurementProjectForOnboardingHomePageFragmentDoc = gql`
    fragment ActiveMeasurementProjectForOnboardingHomePage on MeasurementProject {
  id
  name
  measurementTasks {
    ...MeasurementTaskForMeasurementPage
  }
  footprintStatus
}
    ${MeasurementTaskForMeasurementPageFragmentDoc}`;
export const OrganizationForOnboardingHomePageFragmentDoc = gql`
    fragment OrganizationForOnboardingHomePage on Organization {
  id
  users {
    edges {
      node {
        id
      }
    }
  }
  hasActiveWorkosConnection
}
    `;
export const ValueMappingOutputOptionFieldsFragmentDoc = gql`
    fragment ValueMappingOutputOptionFields on ValueMappingOutputOption {
  id
  title
  subtitle
  descriptionLong
  isSuggested
  category
  extraData
}
    `;
export const VisibleMarketplaceDeveloperFieldsFragmentDoc = gql`
    fragment VisibleMarketplaceDeveloperFields on MarketplaceDeveloper {
  id
  name
  url
  descriptionMd
  imageUrl
}
    `;
export const VisibleMarketplaceProjectArchetypeFieldsFragmentDoc = gql`
    fragment VisibleMarketplaceProjectArchetypeFields on MarketplaceProjectArchetype {
  __typename
  id
  kind
  unit
  name
  isUserVisible
  descriptionMd
  summaryMd
  imageUrl
  bannerImageUrl
  additionalityRating
  permanenceRating
  priceUsdCentsMin
  priceUsdCentsMax
  visibleDevelopers {
    edges {
      node {
        ...VisibleMarketplaceDeveloperFields
      }
    }
  }
}
    ${VisibleMarketplaceDeveloperFieldsFragmentDoc}`;
export const UserUploadedTableWithDataPreviewFragmentDoc = gql`
    fragment UserUploadedTableWithDataPreview on UserUploadedTable {
  ...UserUploadedTableFieldsWithoutDataPreview
  ...UserUploadedTableWithOnlyDataPreviewFields
}
    ${UserUploadedTableFieldsWithoutDataPreviewFragmentDoc}
${UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc}`;
export const UserUploadAttemptFieldsWithDataPreviewFragmentDoc = gql`
    fragment UserUploadAttemptFieldsWithDataPreview on UserUploadAttempt {
  id
  name
  createdAt
  size
  uploader {
    id
    name
  }
  description
  userUploadedTables {
    ...UserUploadedTableWithDataPreview
  }
  latestUutsIfRevisionExists {
    ...UserUploadedTableWithDataPreview
  }
  remoteWritten
}
    ${UserUploadedTableWithDataPreviewFragmentDoc}`;
export const UserUploadFieldsForFilePreviewFragmentDoc = gql`
    fragment UserUploadFieldsForFilePreview on UserUpload {
  id
  processingMode
  userVisibleAttempt {
    ...UserUploadAttemptFieldsWithDataPreview
  }
  userUploadTask {
    id
    measurementProject {
      id
    }
    datasource {
      id
      name
      dataset {
        id
        name
      }
    }
  }
  revisionRoot
}
    ${UserUploadAttemptFieldsWithDataPreviewFragmentDoc}`;
export const UserUploadedTableForTablePreviewDialogFragmentDoc = gql`
    fragment UserUploadedTableForTablePreviewDialog on UserUploadedTable {
  ...UserUploadedTableFieldsWithoutDataPreview
  ...UserUploadedTableWithOnlyDataPreviewFields
}
    ${UserUploadedTableFieldsWithoutDataPreviewFragmentDoc}
${UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc}`;
export const CustomActivityUserUploadTasksFieldsFragmentDoc = gql`
    fragment CustomActivityUserUploadTasksFields on UserUploadTask {
  id
  state
  userUploads {
    edges {
      node {
        id
      }
    }
  }
  createdAt
  datasource {
    id
  }
}
    `;
export const AuditLogEntryFieldsFragmentDoc = gql`
    fragment AuditLogEntryFields on AuditLogEntry {
  id
  createdAt
  eventKind
  eventMetadata
  userDisplayName
  appType
}
    `;
export const FacilityPeriodFragmentDoc = gql`
    fragment FacilityPeriod on BuildingPeriod {
  id
  kind
  sizeQuantity
  customFieldId
  customFieldValue
  orgUnitStableId
  orgUnitTypeStableId
}
    `;
export const FacilitiesForAddFacilitiesGridFragmentDoc = gql`
    fragment FacilitiesForAddFacilitiesGrid on Facility {
  id
  buildingName
  streetAddress
  streetAddress2
  city
  state
  country
  leaseType
  startYearMonth
  endYearMonth
  buildingKind
  sizeUnit
  periods {
    ...FacilityPeriod
  }
  tasks(measurementProjectId: $measurementProjectId) {
    id
    datasource {
      id
      name
      dataset {
        id
        name
      }
    }
  }
}
    ${FacilityPeriodFragmentDoc}`;
export const BuildingPeriodInfoFragmentDoc = gql`
    fragment BuildingPeriodInfo on BuildingPeriodInterface {
  kind
  sizeQuantity
  startYearMonth
  endYearMonth
  partialCleanPowerPercent
  cleanPowerContractualInstrument
  powerUsageEffectiveness
  isBuildingEmpty
  customFieldValue
  customFieldId
  orgUnitTypeStableId
  orgUnitStableId
}
    `;
export const BuildingInfoFragmentDoc = gql`
    fragment BuildingInfo on BuildingInterface {
  streetAddress
  streetAddress2
  latitude
  longitude
  postalCode
  city
  state
  country
  startYearMonth
  endYearMonth
  sizeUnit
  buildingKind
  buildingSubkind
  leaseType
  buildingName
  buildingUniqueId
  isWithoutNaturalGas
  isWithoutRefrigerants
  periods {
    ...BuildingPeriodInfo
  }
}
    ${BuildingPeriodInfoFragmentDoc}`;
export const FacilityFragmentFragmentDoc = gql`
    fragment FacilityFragment on Facility {
  id
  buildingName
  streetAddress
  streetAddress2
  city
  state
  country
  sizeUnit
  startYearMonth
  endYearMonth
  editState
  ...BuildingInfo
  periods {
    id
    ...BuildingPeriodInfo
  }
  latestApprovedVersion {
    id
    ...BuildingInfo
  }
}
    ${BuildingInfoFragmentDoc}
${BuildingPeriodInfoFragmentDoc}`;
export const UserUploadTaskForDataIssueFragmentDoc = gql`
    fragment UserUploadTaskForDataIssue on UserUploadTask {
  id
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueWithCommentsFields
      }
    }
  }
  datasource {
    id
    name
  }
  measurementProject {
    id
    active
  }
}
    ${DataIssueWithCommentsFieldsFragmentDoc}`;
export const ValueMappingTaskForDataIssueFragmentDoc = gql`
    fragment ValueMappingTaskForDataIssue on ValueMappingTask {
  id
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueWithCommentsFields
      }
    }
  }
  measurementProject {
    id
    active
  }
}
    ${DataIssueWithCommentsFieldsFragmentDoc}`;
export const GetDatasourceSummaryFieldsFragmentDoc = gql`
    fragment GetDatasourceSummaryFields on Datasource {
  id
  name
  canonicalDatasource {
    id
    name
  }
  dataset {
    id
    canonicalDataset {
      id
      kind
    }
  }
  userUploadTaskForMeasurementProject(measurementProjectId: $measurementProjectId) {
    id
    userUploads {
      edges {
        node {
          id
          userVisibleAttempt {
            id
          }
          isBeingValueMapped
        }
      }
    }
  }
  userUploadTasks {
    edges {
      node {
        id
        userUploads {
          edges {
            node {
              id
              createdAt
              revisionRoot
              status
              processingMode
              isBeingValueMapped
              userVisibleAttempt {
                id
                name
                remoteWritten
                userUploadedTables {
                  id
                  status
                }
              }
            }
          }
        }
      }
    }
  }
  firstUnansweredMeasurementVerificationQuestion(
    measurementProjectId: $measurementProjectId
  ) {
    id
    verificationItemId
  }
}
    `;
export const ApprovalTargetFieldsForChangingStatusFragmentDoc = gql`
    fragment ApprovalTargetFieldsForChangingStatus on ApprovalTarget {
  id
  approvalStatus
  lockState
  approvers {
    approvalStatus
    user {
      id
    }
  }
}
    `;
export const ChangelogEventPartsFragmentDoc = gql`
    fragment ChangelogEventParts on DataApprovalChangelogEvent {
  id
  activityType
  actorUser {
    id
    displayName
  }
  eventTime
  moreDetails
}
    `;
export const UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc = gql`
    fragment UserUploadTaskFieldsForBuildingMutationResponse on UserUploadTask {
  id
  state
  importState
  buildingIds
}
    `;
export const DataCollectionCommandPaletteCustomFieldFragmentDoc = gql`
    fragment DataCollectionCommandPaletteCustomField on IngestionCustomField {
  id
  name
  type
  possibleValues
  description
}
    `;
export const CustomFieldForBuildingUtilitiesDialogFragmentDoc = gql`
    fragment CustomFieldForBuildingUtilitiesDialog on IngestionCustomField {
  id
  name
  description
  type
  possibleValues
  specificUtilityTypes
}
    `;
export const CustomFieldForBuildingsGridPageFragmentDoc = gql`
    fragment CustomFieldForBuildingsGridPage on IngestionCustomField {
  ...DataCollectionCommandPaletteCustomField
  ...CustomFieldForBuildingUtilitiesDialog
  id
  name
  description
  type
  possibleValues
  specificDatasets {
    id
  }
  appliesToAllDatasets
}
    ${DataCollectionCommandPaletteCustomFieldFragmentDoc}
${CustomFieldForBuildingUtilitiesDialogFragmentDoc}`;
export const DatasetFieldsForCustomFieldEditDialogFragmentDoc = gql`
    fragment DatasetFieldsForCustomFieldEditDialog on Dataset {
  id
  name
  canonicalDataset {
    id
    kind
  }
}
    `;
export const CustomFieldForEditDialogFragmentDoc = gql`
    fragment CustomFieldForEditDialog on IngestionCustomField {
  id
  name
  createdAt
  type
  description
  possibleValues
  exampleValues
  appliesToAllDatasets
  specificDatasets {
    ...DatasetFieldsForCustomFieldEditDialog
  }
  specificUtilityTypes
  ingestionCustomFieldDatasources {
    id
  }
}
    ${DatasetFieldsForCustomFieldEditDialogFragmentDoc}`;
export const OrganizationWithIngestionCustomFieldsFragmentDoc = gql`
    fragment OrganizationWithIngestionCustomFields on Organization {
  id
  ingestionCustomFields {
    ...CustomFieldForBuildingsGridPage
    ...CustomFieldForEditDialog
  }
}
    ${CustomFieldForBuildingsGridPageFragmentDoc}
${CustomFieldForEditDialogFragmentDoc}`;
export const BuildingFormSupportingDocumentFragmentDoc = gql`
    fragment BuildingFormSupportingDocument on SupportingDocument {
  id
  file {
    id
    name
    remoteWritten
  }
  createdAt
  utilityType
  buildingUtilityId
}
    `;
export const BuildingUtilityFieldsForUtilitiesGridPageFragmentDoc = gql`
    fragment BuildingUtilityFieldsForUtilitiesGridPage on BuildingUtility {
  id
  periodStart
  periodEnd
  quantity
  unit
  utilityType
  fuelKind
  fuelCombustionReason
  notes
  provider
  wasteTreatment
  wasteMaterial
  isWasteHazardous
  isWasteRadioactive
  waterUseKind
  refrigerantName
  refrigerantLeakageSource
  cleanPowerPercent
  cleanPowerContractualInstrument
  onsiteCleanFuelKind
  supportingDocuments {
    ...BuildingFormSupportingDocument
  }
  customFieldValues {
    id
    ingestionCustomFieldId
    customFieldValue
  }
}
    ${BuildingFormSupportingDocumentFragmentDoc}`;
export const BuildingUtilityConnectionFragoFragmentDoc = gql`
    fragment BuildingUtilityConnectionFrago on BuildingUtilityConnection {
  edges {
    node {
      ...BuildingUtilityFieldsForUtilitiesGridPage
    }
  }
  pageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
    totalRowCount
  }
}
    ${BuildingUtilityFieldsForUtilitiesGridPageFragmentDoc}`;
export const UserUploadTaskForUtilitiesMutationResponseFragmentDoc = gql`
    fragment UserUploadTaskForUtilitiesMutationResponse on UserUploadTask {
  id
  state
}
    `;
export const CompletionIntervalFieldsFragmentDoc = gql`
    fragment CompletionIntervalFields on BuildingCompletionIntervals {
  id
  data {
    utilityType
    fuelKind
    hasOnsiteCleanElectricity
    periodsWithActuals {
      start
      end
    }
  }
}
    `;
export const BuildingUtilityFieldsForDirectEntryPreviewFragmentDoc = gql`
    fragment BuildingUtilityFieldsForDirectEntryPreview on BuildingUtility {
  id
  periodStart
  periodEnd
  quantity
  unit
  utilityType
  fuelKind
  fuelCombustionReason
  notes
  provider
  wasteTreatment
  wasteMaterial
  isWasteHazardous
  isWasteRadioactive
  waterUseKind
  refrigerantName
  refrigerantLeakageSource
  cleanPowerPercent
  cleanPowerContractualInstrument
  onsiteCleanFuelKind
  supportingDocuments {
    id
  }
  customFieldValues {
    id
    ingestionCustomFieldId
    customFieldValue
  }
}
    `;
export const FacilitiesWithUtilitiesFragmentDoc = gql`
    fragment FacilitiesWithUtilities on Facility {
  id
  buildingName
  buildingWithUtilities {
    id
    completionIntervals(separateOnsiteCleanElectricity: true) {
      ...CompletionIntervalFields
    }
    utilitiesPaginated(
      utilityType: $utilityType
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
        totalRowCount
      }
      edges {
        node {
          ...BuildingUtilityFieldsForDirectEntryPreview
        }
      }
    }
  }
  periods {
    id
    startYearMonth
    endYearMonth
  }
  isWithoutRefrigerants
  startYearMonth
  endYearMonth
}
    ${CompletionIntervalFieldsFragmentDoc}
${BuildingUtilityFieldsForDirectEntryPreviewFragmentDoc}`;
export const CustomColumnsForDirectEntryFragmentDoc = gql`
    fragment CustomColumnsForDirectEntry on IngestionCustomField {
  id
  name
  specificUtilityTypes
}
    `;
export const ImportImport_UserUploadFragmentDoc = gql`
    fragment ImportImport_UserUpload on UserUpload {
  id
  name
  status
  userVisibleAttempt {
    id
    createdAt
    size
    userUploadedTables {
      id
      status
      sheetName
      sheetIndex
      errorType
      errorMessage
      ...UserUploadedTableWithOnlyDataPreviewFields
    }
  }
  ctsVersion {
    id
  }
}
    ${UserUploadedTableWithOnlyDataPreviewFieldsFragmentDoc}`;
export const UserUploadTaskStatusFragmentDoc = gql`
    fragment UserUploadTaskStatus on UserUploadTask {
  id
  state
  userUploads {
    edges {
      node {
        ...ImportImport_UserUpload
      }
    }
  }
}
    ${ImportImport_UserUploadFragmentDoc}`;
export const SharedFileForImportImportFragmentDoc = gql`
    fragment SharedFileForImportImport on SharedFile {
  id
  expiresAt
  fileMetadata {
    id
    name
    size
  }
}
    `;
export const CustomFieldForImporterPageFragmentDoc = gql`
    fragment CustomFieldForImporterPage on IngestionCustomField {
  id
  name
  type
  description
  bartTagName
  possibleValues
  specificDatasets {
    id
  }
  appliesToAllDatasets
}
    `;
export const OrganizationCustomFieldsForImporterPageFragmentDoc = gql`
    fragment OrganizationCustomFieldsForImporterPage on Organization {
  id
  ingestionCustomFields {
    ...CustomFieldForImporterPage
  }
}
    ${CustomFieldForImporterPageFragmentDoc}`;
export const UserUploadCandidateRowFieldsFragmentDoc = gql`
    fragment UserUploadCandidateRowFields on UserUploadCandidateRowFields {
  id
  data
  citation
}
    `;
export const UserUploadCandidateFieldsFragmentDoc = gql`
    fragment UserUploadCandidateFields on UserUploadCandidateEntry {
  ctsVersion {
    id
    schemaJson
  }
  candidateRows {
    ...UserUploadCandidateRowFields
  }
  id
}
    ${UserUploadCandidateRowFieldsFragmentDoc}`;
export const DocumentCandidateFieldsFragmentDoc = gql`
    fragment DocumentCandidateFields on DocumentCandidate {
  pdfUrl
  fileName
  id
}
    `;
export const CustomFieldForBuldingDiffFragmentDoc = gql`
    fragment CustomFieldForBuldingDiff on IngestionCustomField {
  id
  name
}
    `;
export const OrgUnitTypeForBuildingDiffFragmentDoc = gql`
    fragment OrgUnitTypeForBuildingDiff on OrgUnitType {
  id
  name
  stableId
}
    `;
export const OrgUnitForBuildingDiffFragmentDoc = gql`
    fragment OrgUnitForBuildingDiff on OrgUnit {
  id
  name
  stableId
}
    `;
export const BuildingDiffFragmentDoc = gql`
    fragment BuildingDiff on CadtBuildingDiff {
  old {
    ...BuildingInfo
  }
  new {
    ...BuildingInfo
  }
  errors {
    message
    adminMessage
    code
  }
  buildingPresentInBart
  warnings {
    adminMessage
    message
    code
  }
}
    ${BuildingInfoFragmentDoc}`;
export const BuildingDiffSetFragmentDoc = gql`
    fragment BuildingDiffSet on CadtBuildingDiffSet {
  diffs {
    ...BuildingDiff
  }
  added
  removed
  changed
  unchanged
}
    ${BuildingDiffFragmentDoc}`;
export const ValueMappingPageValueMappingRowFragmentDoc = gql`
    fragment ValueMappingPageValueMappingRow on ValueMappingRow {
  id
  baseRowJson
}
    `;
export const ValueMappingPageValueMappingFragmentDoc = gql`
    fragment ValueMappingPageValueMapping on ValueMapping {
  id
  revisionState
  valueMappingRows {
    id
    ...ValueMappingPageValueMappingRow
  }
}
    ${ValueMappingPageValueMappingRowFragmentDoc}`;
export const ValueMappingPageValueMappingTaskFragmentDoc = gql`
    fragment ValueMappingPageValueMappingTask on ValueMappingTask {
  id
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueWithCommentsFields
      }
    }
  }
  publishStatus
}
    ${DataIssueWithCommentsFieldsFragmentDoc}`;
export const DatasourceValueMappingRuleFragmentDoc = gql`
    fragment DatasourceValueMappingRule on DatasourceValueMappingRule {
  id
  featureFlag
  valueMappingConfigId
  applicableDatasetKinds
  revisionData {
    id
    revisionState
  }
  valueMapping {
    ...ValueMappingPageValueMapping
  }
  valueMappingTask {
    ...ValueMappingPageValueMappingTask
  }
}
    ${ValueMappingPageValueMappingFragmentDoc}
${ValueMappingPageValueMappingTaskFragmentDoc}`;
export const DatasourceValueMappingCanonicalDatasetFragmentDoc = gql`
    fragment DatasourceValueMappingCanonicalDataset on CanonicalDataset {
  id
  kind
  name
}
    `;
export const DatasourceValueMappingMeasurementProjectFragmentDoc = gql`
    fragment DatasourceValueMappingMeasurementProject on MeasurementProject {
  id
  orgId
  datasetsWithStages {
    id
    dataset {
      id
      canonicalDataset {
        id
        ...DatasourceValueMappingCanonicalDataset
      }
    }
  }
  active
  valueMappingTask(valueMappingRuleId: $valueMappingRuleId) {
    id
    statusSimplified
    publishStatus
  }
}
    ${DatasourceValueMappingCanonicalDatasetFragmentDoc}`;
export const FinancialsReviewAccountDetailsFragmentDoc = gql`
    fragment FinancialsReviewAccountDetails on FinancialsAccount {
  displayAccountId
  description
}
    `;
export const FinancialsReviewItemInboxDetailsFragmentDoc = gql`
    fragment FinancialsReviewItemInboxDetails on FinancialsReviewItem {
  id
  account {
    ...FinancialsReviewAccountDetails
  }
  status
}
    ${FinancialsReviewAccountDetailsFragmentDoc}`;
export const FinancialsReviewItemDetailsFragmentDoc = gql`
    fragment FinancialsReviewItemDetails on FinancialsReviewItem {
  id
  account {
    ...FinancialsReviewAccountDetails
  }
  status
  kind
  initialIndustryClassification {
    ...IndustryClassificationDetails
  }
  initiallyIncluded
  exclusionReason
  prompt
  userResponse
  askingEmployee {
    id
    name
  }
  discussion {
    ...DataIssueWithCommentsFields
  }
}
    ${FinancialsReviewAccountDetailsFragmentDoc}
${IndustryClassificationDetailsFragmentDoc}
${DataIssueWithCommentsFieldsFragmentDoc}`;
export const FinancialsReviewInboxItemFragmentDoc = gql`
    fragment FinancialsReviewInboxItem on FinancialsReviewItem {
  id
  account {
    displayAccountId
    description
  }
  status
  initialIndustryClassification {
    id
    shortDescription
  }
}
    `;
export const DataIssueForMeasurementPageFragmentDoc = gql`
    fragment DataIssueForMeasurementPage on DataIssue {
  id
  state
  title
}
    `;
export const UserUploadTaskForMeasurementPageFragmentDoc = gql`
    fragment UserUploadTaskForMeasurementPage on UserUploadTask {
  id
  datasource {
    id
    name
    userCanAccessDetails
    userCanApproveDatasource
    userCanManageDatasource
    isUserConfirmed
    dataset {
      ...DatasetForMeasurementPage
      canonicalDataset {
        id
        kind
      }
    }
    hasIntegration
  }
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueForMeasurementPage
      }
    }
  }
}
    ${DatasetForMeasurementPageFragmentDoc}
${DataIssueForMeasurementPageFragmentDoc}`;
export const MeasurementParentTaskForMeasurementPageFragmentDoc = gql`
    fragment MeasurementParentTaskForMeasurementPage on MeasurementParentTask {
  ...MeasurementTaskForMeasurementPage
  subTasks {
    ...MeasurementTaskForMeasurementPage
    taskGroup
    dataset {
      ...DatasetForMeasurementPage
    }
  }
  taskGroup
  dataset {
    ...DatasetForMeasurementPage
  }
}
    ${MeasurementTaskForMeasurementPageFragmentDoc}
${DatasetForMeasurementPageFragmentDoc}`;
export const MeasurementVerificationItemForMeasurementPageFragmentDoc = gql`
    fragment MeasurementVerificationItemForMeasurementPage on MeasurementVerificationItem {
  id
  status
  dataset {
    id
    canonicalDataset {
      id
      kind
    }
  }
}
    `;
export const MeasurementProjectForSendEmailsButtonFragmentDoc = gql`
    fragment MeasurementProjectForSendEmailsButton on MeasurementProject {
  id
  deadline
  active
}
    `;
export const ClimateProgramProjectForMeasurementPageFragmentDoc = gql`
    fragment ClimateProgramProjectForMeasurementPage on ClimateProgramProject {
  __typename
  id
  name
  canonicalClimateProgramProject {
    __typename
    id
    measurementObjectCopy
    kind
    routeType
    name
  }
}
    `;
export const MeasurementProjectForMeasurementPageFragmentDoc = gql`
    fragment MeasurementProjectForMeasurementPage on MeasurementProject {
  id
  name
  active
  setupAt
  completedAt
  footprintStatus
  coverageInterval
  userUploadTasks {
    edges {
      node {
        ...UserUploadTaskForMeasurementPage
      }
    }
  }
  measurementTimeline {
    ...MeasurementTimelineForMeasurementPage
  }
  measurementTasks {
    ...MeasurementParentTaskForMeasurementPage
  }
  datasetsWithStages {
    ...DatasetWithStageForMeasurementPage
  }
  measurementVerificationItems {
    edges {
      node {
        ...MeasurementVerificationItemForMeasurementPage
      }
    }
  }
  deadline
  ...MeasurementProjectForSendEmailsButton
  climateProgramProjects {
    ...ClimateProgramProjectForMeasurementPage
  }
}
    ${UserUploadTaskForMeasurementPageFragmentDoc}
${MeasurementTimelineForMeasurementPageFragmentDoc}
${MeasurementParentTaskForMeasurementPageFragmentDoc}
${DatasetWithStageForMeasurementPageFragmentDoc}
${MeasurementVerificationItemForMeasurementPageFragmentDoc}
${MeasurementProjectForSendEmailsButtonFragmentDoc}
${ClimateProgramProjectForMeasurementPageFragmentDoc}`;
export const UserFieldsForFacilitiesTasksFragmentDoc = gql`
    fragment UserFieldsForFacilitiesTasks on User {
  id
  name
  displayName
}
    `;
export const FacilitiesUserUploadTaskForTasksPageFragmentDoc = gql`
    fragment FacilitiesUserUploadTaskForTasksPage on UserUploadTask {
  id
  state
  assignees {
    ...UserFieldsForFacilitiesTasks
  }
  datasource {
    id
    name
  }
  measurementProject {
    id
    active
  }
  buildingIds
  approvers {
    approvalStatus
    user {
      ...UserFieldsForFacilitiesTasks
    }
  }
  facilitiesPreview {
    preview {
      buildingName
      id
    }
    totalCount
  }
  userUploads {
    edges {
      node {
        id
      }
    }
  }
  lockState
}
    ${UserFieldsForFacilitiesTasksFragmentDoc}`;
export const MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc = gql`
    fragment MeasurementProjectForConfigureFacilitiesTasksPage on MeasurementProject {
  ...MeasurementProjectForMeasurementPage
  userUploadTasks {
    edges {
      node {
        ...FacilitiesUserUploadTaskForTasksPage
      }
    }
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${FacilitiesUserUploadTaskForTasksPageFragmentDoc}`;
export const DatasetForImporterFragmentDoc = gql`
    fragment DatasetForImporter on Dataset {
  id
  name
  datasources {
    id
    name
    customerTargetSchemaIds
  }
  canonicalDataset {
    id
    kind
    customerTargetSchemas {
      ...CustomerTargetSchemaForDataFormat
    }
  }
}
    ${CustomerTargetSchemaForDataFormatFragmentDoc}`;
export const InstructionsBundleForImporterFragmentDoc = gql`
    fragment InstructionsBundleForImporter on InstructionsBundle {
  sdiInstructionsSteps {
    ...SDIInstructionsStepForImportInstructions
  }
  sdiInstructionsFaq {
    ...SDIInstructionsFaqForImportInstructions
  }
}
    ${SdiInstructionsStepForImportInstructionsFragmentDoc}
${SdiInstructionsFaqForImportInstructionsFragmentDoc}`;
export const FacilitiesMetadataForFacilitiesTasksFragmentDoc = gql`
    fragment FacilitiesMetadataForFacilitiesTasks on FacilitiesMetadata {
  buildingsDataset {
    id
    name
    ...DatasetForImporter
    instructions {
      ...InstructionsBundleForImporter
    }
  }
  utilitiesDataset {
    id
    name
  }
}
    ${DatasetForImporterFragmentDoc}
${InstructionsBundleForImporterFragmentDoc}`;
export const OrganizationForConfigureFacilitiesTasksPageFragmentDoc = gql`
    fragment OrganizationForConfigureFacilitiesTasksPage on Organization {
  id
  facilitiesMetadata {
    ...FacilitiesMetadataForFacilitiesTasks
  }
  ingestionCustomFields {
    ...CustomFieldForBuildingsGridPage
    ...CustomFieldForEditDialog
    bartTagName
    ingestionCustomFieldDatasources {
      id
    }
  }
}
    ${FacilitiesMetadataForFacilitiesTasksFragmentDoc}
${CustomFieldForBuildingsGridPageFragmentDoc}
${CustomFieldForEditDialogFragmentDoc}`;
export const FacilitiesForTasksPageFragmentDoc = gql`
    fragment FacilitiesForTasksPage on Facility {
  id
  buildingName
  streetAddress
  streetAddress2
  city
  state
  country
  sizeUnit
  startYearMonth
  endYearMonth
  leaseType
  buildingKind
  periods {
    ...FacilityPeriod
  }
  editState
  tasks(measurementProjectId: $measurementProjectId) {
    id
    datasource {
      id
      name
      dataset {
        id
        name
      }
    }
  }
}
    ${FacilityPeriodFragmentDoc}`;
export const OrgUnitTypeWithOrgUnitsForFacilitiesFragmentDoc = gql`
    fragment OrgUnitTypeWithOrgUnitsForFacilities on OrgUnitType {
  id
  name
  stableId
  orgUnits {
    id
    name
    stableId
  }
}
    `;
export const UserForFacilitiesImportFragmentDoc = gql`
    fragment UserForFacilitiesImport on User {
  id
  name
  displayName
  email
}
    `;
export const OrganizationFieldsForFacilitiesImportFragmentDoc = gql`
    fragment OrganizationFieldsForFacilitiesImport on Organization {
  id
  users {
    edges {
      node {
        ...UserForFacilitiesImport
      }
    }
  }
}
    ${UserForFacilitiesImportFragmentDoc}`;
export const FacilitiesUserUploadTaskFragmentDoc = gql`
    fragment FacilitiesUserUploadTask on UserUploadTask {
  id
  state
  assignees {
    ...UserFieldsForFacilitiesTasks
  }
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueWithCommentsFields
      }
    }
  }
  datasource {
    id
    name
    dataset {
      id
      canonicalDataset {
        id
        kind
      }
    }
    isApi
  }
  measurementProject {
    id
    active
  }
  buildingIds
  lockState
}
    ${UserFieldsForFacilitiesTasksFragmentDoc}
${DataIssueWithCommentsFieldsFragmentDoc}`;
export const MeasurementProjectForManageFacilitiesPageFragmentDoc = gql`
    fragment MeasurementProjectForManageFacilitiesPage on MeasurementProject {
  ...MeasurementProjectForMeasurementPage
  userUploadTasks {
    edges {
      node {
        ...FacilitiesUserUploadTask
      }
    }
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${FacilitiesUserUploadTaskFragmentDoc}`;
export const OrganizationCustomFieldsForManagePageFragmentDoc = gql`
    fragment OrganizationCustomFieldsForManagePage on Organization {
  id
  ingestionCustomFields {
    ...CustomFieldForBuildingsGridPage
    ...CustomFieldForEditDialog
    bartTagName
    ingestionCustomFieldDatasources {
      id
    }
  }
  datasets {
    id
    ...DatasetFieldsForCustomFieldEditDialog
    ...DatasetForImporter
    instructions {
      ...InstructionsBundleForImporter
    }
  }
}
    ${CustomFieldForBuildingsGridPageFragmentDoc}
${CustomFieldForEditDialogFragmentDoc}
${DatasetFieldsForCustomFieldEditDialogFragmentDoc}
${DatasetForImporterFragmentDoc}
${InstructionsBundleForImporterFragmentDoc}`;
export const OrganizationFacilitiesUntypedFragmentDoc = gql`
    fragment OrganizationFacilitiesUntyped on Organization {
  id
  buildingsUntyped {
    id
    data
  }
}
    `;
export const SingleFacilityBuildingPropertiesFragmentDoc = gql`
    fragment SingleFacilityBuildingProperties on Facility {
  id
  createdAt
  updatedAt
  editState
  ...BuildingInfo
  periods {
    id
    ...BuildingPeriodInfo
  }
  supportingDocuments {
    ...BuildingFormSupportingDocument
  }
  latestApprovedVersion {
    id
    ...BuildingInfo
  }
  history {
    revision
    createdAt
  }
}
    ${BuildingInfoFragmentDoc}
${BuildingPeriodInfoFragmentDoc}
${BuildingFormSupportingDocumentFragmentDoc}`;
export const FootprintConfigurationWithReleasesFragmentDoc = gql`
    fragment FootprintConfigurationWithReleases on FootprintConfiguration {
  id
  releasesByBat {
    batName
    release {
      id
      updatedAt
    }
  }
}
    `;
export const DatasetAndDatasetGroupsForGenerateFootprintFragmentDoc = gql`
    fragment DatasetAndDatasetGroupsForGenerateFootprint on FootprintDatasetOrDatasetGroup {
  id
  datasetGroupName
  dataset {
    id
    name
    canonicalDataset {
      id
      kind
    }
  }
}
    `;
export const FootprintConfigurationForGenerateFootprintFragmentDoc = gql`
    fragment FootprintConfigurationForGenerateFootprint on FootprintConfiguration {
  id
  methodologyConfig {
    id
    spendMethodology
    wasteEmissionsExclusions {
      wasteEmissionsMethodology
      shouldExclude
    }
  }
  measurementProjectDatasets {
    id
    measurementProject {
      id
      name
      createdAt
    }
    datasetsAndDatasetGroups {
      ...DatasetAndDatasetGroupsForGenerateFootprint
    }
  }
  exclusionRules {
    id
    latestActiveRule {
      id
    }
  }
  categoryRules {
    id
    latestActiveRule {
      id
    }
  }
}
    ${DatasetAndDatasetGroupsForGenerateFootprintFragmentDoc}`;
export const PipelineConfigsForFootprintSnapshotFragmentDoc = gql`
    fragment PipelineConfigsForFootprintSnapshot on FootprintSnapshot {
  id
  configs {
    pipelineResultId
    coverageInterval
  }
}
    `;
export const FootprintVersionForNameGenFragmentDoc = gql`
    fragment FootprintVersionForNameGen on FootprintVersion {
  id
  kind
}
    `;
export const DatasetWithStageForGenerateFootprintFragmentDoc = gql`
    fragment DatasetWithStageForGenerateFootprint on DatasetWithStage {
  id
  dataset {
    id
    name
    canonicalDataset {
      id
      kind
    }
  }
  isUsableInFootprint
  hasUnsupportedBarts
  largeUserUploadedFiles
  isNonEmissive
}
    `;
export const MeasurementProjectForGenerateFootprintFragmentDoc = gql`
    fragment MeasurementProjectForGenerateFootprint on MeasurementProject {
  id
  name
  createdAt
  datasetsWithStages {
    ...DatasetWithStageForGenerateFootprint
  }
  coverageInterval
}
    ${DatasetWithStageForGenerateFootprintFragmentDoc}`;
export const CompanyForVendorMatchingFragmentDoc = gql`
    fragment CompanyForVendorMatching on Company {
  id
  name
  country
  url
  description
  naicsCode
  countryAlpha2
}
    `;
export const OrgCompanyForSupplierMappingFragmentDoc = gql`
    fragment OrgCompanyForSupplierMapping on OrgCompany {
  id
  name
  company {
    ...CompanyForVendorMatching
  }
  user {
    id
    name
    isWatershedEmployee
    isWatershedContractor
  }
  updatedAt
}
    ${CompanyForVendorMatchingFragmentDoc}`;
export const CompanyChangeRequestForVendorMatchingFragmentDoc = gql`
    fragment CompanyChangeRequestForVendorMatching on CompanyChangeRequest {
  id
  action
  payload
}
    `;
export const SupplierMappingTableSubRowFieldsFragmentDoc = gql`
    fragment SupplierMappingTableSubRowFields on SupplierMappingTableSubRow {
  id
  category
  subCategory
  currencyQuantity
  currency
  spendUsd
  datasetNames
}
    `;
export const CompanyAutoMatchFieldsFragmentDoc = gql`
    fragment CompanyAutoMatchFields on CompanyAutoMatch {
  id
  confidenceScore
  company {
    id
    name
    country
    naicsCode
  }
}
    `;
export const SupplierMappingTableRowFieldsFragmentDoc = gql`
    fragment SupplierMappingTableRowFields on SupplierMappingTableRow {
  id
  rawVendorEntity
  orgCompany {
    ...OrgCompanyForSupplierMapping
  }
  pendingCompanyCreateRequest {
    ...CompanyChangeRequestForVendorMatching
  }
  totalSpendUsd
  subRows {
    ...SupplierMappingTableSubRowFields
  }
  autoMatches {
    ...CompanyAutoMatchFields
  }
  autoMatched {
    confidenceScore
  }
  ranAutoMapping
}
    ${OrgCompanyForSupplierMappingFragmentDoc}
${CompanyChangeRequestForVendorMatchingFragmentDoc}
${SupplierMappingTableSubRowFieldsFragmentDoc}
${CompanyAutoMatchFieldsFragmentDoc}`;
export const SupplierMappingTableRowsPayloadFieldsFragmentDoc = gql`
    fragment SupplierMappingTableRowsPayloadFields on SupplierMappingTableRowsPayload {
  id
  totalNumVendorRows
  vendorRows {
    edges {
      node {
        ...SupplierMappingTableRowFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
  autoMappingButtonConditions {
    isDemoOrTestOrg
    hasInProgressRequestLog
    hasErroredRequestLog
    alreadyAutoMappedForVendorMatchingTask
    noEligibleSuppliersByEmissions
  }
}
    ${SupplierMappingTableRowFieldsFragmentDoc}`;
export const VendorMatchingTaskFieldsFragmentDoc = gql`
    fragment VendorMatchingTaskFields on VendorMatchingTask {
  id
  measurementProject {
    id
    name
  }
}
    `;
export const OrgCompanyForVendorMatchingFragmentDoc = gql`
    fragment OrgCompanyForVendorMatching on OrgCompany {
  id
  name
  company {
    ...CompanyForVendorMatching
  }
}
    ${CompanyForVendorMatchingFragmentDoc}`;
export const OrganizationForVendorMatchingFragmentDoc = gql`
    fragment OrganizationForVendorMatching on Organization {
  id
  orgCompanies {
    ...OrgCompanyForVendorMatching
  }
}
    ${OrgCompanyForVendorMatchingFragmentDoc}`;
export const VendorMatchingTableSubRowFieldsFragmentDoc = gql`
    fragment VendorMatchingTableSubRowFields on VendorMatchingTableSubRow {
  id
  category
  subCategory
  currencyQuantity
  currency
  spendUsd
}
    `;
export const VendorMatchingTableRowFieldsFragmentDoc = gql`
    fragment VendorMatchingTableRowFields on VendorMatchingTableRow {
  id
  rawVendorEntity
  autoMatchedCompany {
    ...CompanyForVendorMatching
  }
  orgCompany {
    ...OrgCompanyForVendorMatching
  }
  pendingCompanyCreateRequest {
    ...CompanyChangeRequestForVendorMatching
  }
  totalSpendUsd
  subRows {
    ...VendorMatchingTableSubRowFields
  }
}
    ${CompanyForVendorMatchingFragmentDoc}
${OrgCompanyForVendorMatchingFragmentDoc}
${CompanyChangeRequestForVendorMatchingFragmentDoc}
${VendorMatchingTableSubRowFieldsFragmentDoc}`;
export const VendorMatchingTableRowsPayloadFieldsFragmentDoc = gql`
    fragment VendorMatchingTableRowsPayloadFields on VendorMatchingTableRowsPayload {
  id
  totalNumVendorRows
  vendorRows {
    edges {
      node {
        ...VendorMatchingTableRowFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${VendorMatchingTableRowFieldsFragmentDoc}`;
export const SupplierMappingV1AnalyticsFieldsFragmentDoc = gql`
    fragment SupplierMappingV1AnalyticsFields on SupplierMappingV1Analytics {
  mappedSupplierSpendUsd
  totalSupplierSpendUsd
}
    `;
export const MeasurementVerificationItemQuestionForSummariesFragmentDoc = gql`
    fragment MeasurementVerificationItemQuestionForSummaries on MeasurementVerificationItemQuestion {
  id
  section
  status
  ...MeasurementVerificationItemQuestionForQuestionContent
}
    ${MeasurementVerificationItemQuestionForQuestionContentFragmentDoc}`;
export const MeasurementVerificationItemForSummariesFragmentDoc = gql`
    fragment MeasurementVerificationItemForSummaries on MeasurementVerificationItem {
  id
  name
  datasetName
  description
  status
  measurementProject {
    id
    name
    active
  }
  assignee {
    id
    name
    email
  }
  dataset {
    id
    canonicalDataset {
      id
      kind
    }
  }
  ...MeasurementVerificationItemForQuestionContent
  questions {
    edges {
      node {
        ...MeasurementVerificationItemQuestionForSummaries
      }
    }
  }
}
    ${MeasurementVerificationItemForQuestionContentFragmentDoc}
${MeasurementVerificationItemQuestionForSummariesFragmentDoc}`;
export const OrganizationForOnboardingFlowFragmentDoc = gql`
    fragment OrganizationForOnboardingFlow on Organization {
  id
  name
  country
  fiscalYearStartMonth
}
    `;
export const OrgStructureVersionForSidebarFragmentDoc = gql`
    fragment OrgStructureVersionForSidebar on OrgStructureVersion {
  id
  name
  isPublished
  effectiveFromDate
  effectiveToDate
}
    `;
export const FootprintAnalysisTopVendorFieldsFragmentDoc = gql`
    fragment FootprintAnalysisTopVendorFields on FootprintAnalysisTopVendor {
  proportion
  series {
    data {
      date
      value
    }
  }
  vendorInfo {
    vendor
    program
    programUrl
    targetYear
    sbtTarget
  }
}
    `;
export const SupplierForTeamsTableFieldsFragmentDoc = gql`
    fragment SupplierForTeamsTableFields on Supplier {
  id
  name
  company {
    id
  }
}
    `;
export const UserPermissionRequestFieldsFragmentDoc = gql`
    fragment UserPermissionRequestFields on UserPermissionRequest {
  id
  orgId
  name
  email
  state
  userId
  responderUser
  respondedAt
  creatorOrgId
  creatorUser
  permission
}
    `;
export const PortfolioItemForPortfolioBuilderFragmentDoc = gql`
    fragment PortfolioItemForPortfolioBuilder on MarketplacePortfolioItem {
  id
  amount
  priceUsdCents
  fraction
  archetype {
    id
  }
}
    `;
export const PortfolioForPortfolioBuilderFragmentDoc = gql`
    fragment PortfolioForPortfolioBuilder on MarketplacePortfolio {
  id
  name
  notes
  budgetUsdCents
  footprintTco2e
  interval
  investInRd
  targetAmount
  targetFraction
  offsetTargetAmount
  offsetTargetFraction
  cleanPowerTargetAmount
  portfolioItems {
    edges {
      node {
        ...PortfolioItemForPortfolioBuilder
      }
    }
  }
  purchase {
    id
    customerStatus
  }
}
    ${PortfolioItemForPortfolioBuilderFragmentDoc}`;
export const ProjectArchetypeForPortfolioBuilderFragmentDoc = gql`
    fragment ProjectArchetypeForPortfolioBuilder on MarketplaceProjectArchetype {
  id
  name
  mechanism
  kind
  unit
  descriptionMd
  summaryMd
  imageUrl
  bannerImageUrl
  additionalityRating
  permanenceRating
  medianPriceWithFeeUsdCents
  availability
  isUserVisible
}
    `;
export const VendorFieldsFragmentDoc = gql`
    fragment VendorFields on Vendor {
  vendor
  program
  targetYear
  sbtTarget
}
    `;
export const HistoricalUserInputtedGrowthFactorDataFieldsFragmentDoc = gql`
    fragment HistoricalUserInputtedGrowthFactorDataFields on HistoricalUserInputtedGrowthFactorDataPoint {
  date
  growthFactors {
    growthFactorType
    customIntensityConfigId
    value
  }
}
    `;
export const SimpleTimeseriesFieldsFragmentDoc = gql`
    fragment SimpleTimeseriesFields on SimpleTimeseries {
  base
  frequency
  values
}
    `;
export const GrowthForecastFieldsFragmentDoc = gql`
    fragment GrowthForecastFields on GrowthForecast {
  id
  forecast {
    ...SimpleTimeseriesFields
  }
  growthFactorType
  customIntensityConfigId
  scalingFactorFilters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  isDefaultScalingFactor
  hasUserInputtedHistoricalPeriod
}
    ${SimpleTimeseriesFieldsFragmentDoc}`;
export const ForecastScenarioFieldsFragmentDoc = gql`
    fragment ForecastScenarioFields on ForecastScenario {
  id
  name
  description
  isDefault
  updatedByName
  updatedAt
  historicalUserInputtedGrowthFactorDataPoints {
    ...HistoricalUserInputtedGrowthFactorDataFields
  }
  growthForecasts {
    ...GrowthForecastFields
  }
}
    ${HistoricalUserInputtedGrowthFactorDataFieldsFragmentDoc}
${GrowthForecastFieldsFragmentDoc}`;
export const ForecastPeriodFieldsFragmentDoc = gql`
    fragment ForecastPeriodFields on ForecastPeriod {
  interval
  data {
    date
    headcount
    revenue
    customGrowthFactors {
      customIntensityId
      value
    }
  }
}
    `;
export const CustomIntensityConfigFieldsFragmentDoc = gql`
    fragment CustomIntensityConfigFields on CustomIntensityConfig {
  id
  name
  description
  unit
  humanReadable
  quantityType
  isSbtValidated
}
    `;
export const ForecastFieldsForPlanFragmentDoc = gql`
    fragment ForecastFieldsForPlan on Forecast {
  id
  interval
  scenarios {
    ...ForecastScenarioFields
  }
  referencePeriodInterval
  historicalPeriod {
    ...ForecastPeriodFields
  }
  overrideFootprintKind
  validReferenceInterval
  customIntensityConfigs {
    ...CustomIntensityConfigFields
  }
  footprintSnapshotId
  businessCategories {
    businessCategory
  }
}
    ${ForecastScenarioFieldsFragmentDoc}
${ForecastPeriodFieldsFragmentDoc}
${CustomIntensityConfigFieldsFragmentDoc}`;
export const PlanVariablesFieldsFragmentDoc = gql`
    fragment PlanVariablesFields on PlanVariables {
  commitmentsHasNetZero
  commitmentsNetZeroYear
  cleanPowerStart
  commitmentsHasSBT
  commitmentsSBTTargetYear
  commitmentsSBTScope12
  commitmentsSBTScope3
  commitmentsSBTTermLength
  commitmentsSBTSubmissionDate
  flightsPerEmployeeForecastEnd
  percentWorkFromHomeForecastEnd
  netZeroSuppliersReductionPercent
}
    `;
export const AllSimpleTimeseriesFieldsFragmentDoc = gql`
    fragment AllSimpleTimeseriesFields on SimpleTimeseries {
  id
  base
  frequency
  values
}
    `;
export const CommonInitiativeFieldsFragmentDoc = gql`
    fragment CommonInitiativeFields on Initiative {
  __typename
  id
  name
  enabled
  costTimeseries {
    ...AllSimpleTimeseriesFields
  }
  description
  filters {
    expressions {
      field
      value
      operator
    }
    conjunction
  }
  scheduleType
  deletedAt
  createdAt
}
    ${AllSimpleTimeseriesFieldsFragmentDoc}`;
export const FootprintSliceInitiativeFieldsFragmentDoc = gql`
    fragment FootprintSliceInitiativeFields on FootprintSliceInitiative {
  ...CommonInitiativeFields
  reductionTimeseries {
    ...AllSimpleTimeseriesFields
  }
}
    ${CommonInitiativeFieldsFragmentDoc}
${AllSimpleTimeseriesFieldsFragmentDoc}`;
export const PrecomputedActivityBasedInitiativeFieldsFragmentDoc = gql`
    fragment PrecomputedActivityBasedInitiativeFields on PrecomputedActivityBasedInitiative {
  ...CommonInitiativeFields
  implementationTimeseries {
    ...AllSimpleTimeseriesFields
  }
  precomputedLever: lever
}
    ${CommonInitiativeFieldsFragmentDoc}
${AllSimpleTimeseriesFieldsFragmentDoc}`;
export const ReduxInitiativeFieldsFragmentDoc = gql`
    fragment ReduxInitiativeFields on Initiative {
  ... on FootprintSliceInitiative {
    ...FootprintSliceInitiativeFields
  }
  ... on PrecomputedActivityBasedInitiative {
    ...PrecomputedActivityBasedInitiativeFields
  }
}
    ${FootprintSliceInitiativeFieldsFragmentDoc}
${PrecomputedActivityBasedInitiativeFieldsFragmentDoc}`;
export const CommonPlanTargetFieldsFragmentDoc = gql`
    fragment CommonPlanTargetFields on PlanTarget {
  id
  createdAt
  description
  reportDescription
  customNotes
  emissionsTargetCustom {
    ...SimpleTimeseriesFields
  }
  baseYear
  intensityType
  customIntensityConfigId
  comparisonType
  reductionRate
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  deletedAt
  assignedTo
  parentTargetId
  interimTargetDate
  interimTargetValue
}
    ${SimpleTimeseriesFieldsFragmentDoc}`;
export const PlanTargetFieldsFragmentDoc = gql`
    fragment PlanTargetFields on PlanTarget {
  ...CommonPlanTargetFields
}
    ${CommonPlanTargetFieldsFragmentDoc}`;
export const PlanWithForecastFieldsFragmentDoc = gql`
    fragment PlanWithForecastFields on Plan {
  id
  name
  createdAt
  updatedAt
  updatedByName
  state
  baselineYearStart
  forecast {
    ...ForecastFieldsForPlan
  }
  variables {
    ...PlanVariablesFields
  }
  reduxInitiatives {
    ...ReduxInitiativeFields
  }
  targets {
    ...PlanTargetFields
  }
  reduxCarbonOffsets {
    ...SimpleTimeseriesFields
  }
  reduxCarbonOffsetsCosts {
    ...SimpleTimeseriesFields
  }
  reduxCarbonRemoval {
    ...SimpleTimeseriesFields
  }
  reduxCarbonRemovalCosts {
    ...SimpleTimeseriesFields
  }
  fiscalMonth
  isSbtValidated
  hideCarbonAvoidance
}
    ${ForecastFieldsForPlanFragmentDoc}
${PlanVariablesFieldsFragmentDoc}
${ReduxInitiativeFieldsFragmentDoc}
${PlanTargetFieldsFragmentDoc}
${SimpleTimeseriesFieldsFragmentDoc}`;
export const SbtiTargetExclusionFieldsFragmentDoc = gql`
    fragment SbtiTargetExclusionFields on SbtiTargetExclusion {
  id
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
}
    `;
export const PeerCommitmentFieldsFragmentDoc = gql`
    fragment PeerCommitmentFields on CompanyClimateCommitment {
  __typename
  id
  description
  ... on ScienceBasedTargetCommitment {
    commitment {
      id
      nearTermTargetYear
      longTermTargetYear
      baselineYear
      stage
      targetClassification
    }
  }
}
    `;
export const TargetAndInitiativeForecastFieldsFragmentDoc = gql`
    fragment TargetAndInitiativeForecastFields on Plan {
  id
  targets {
    ...PlanTargetFields
  }
  reduxInitiatives {
    ...ReduxInitiativeFields
  }
}
    ${PlanTargetFieldsFragmentDoc}
${ReduxInitiativeFieldsFragmentDoc}`;
export const FootprintVersionForReduxFragmentDoc = gql`
    fragment FootprintVersionForRedux on FootprintVersion {
  id
  createdAt
  userChangelogMd
  kind
  isGeneratedFromProduct
  isAutoGenerated
  originalFootprintSnapshotId
  generatedBy {
    id
    displayName
  }
  footprintInterval
}
    `;
export const ForecastScenarioForScenarioSelectorFragmentDoc = gql`
    fragment ForecastScenarioForScenarioSelector on ForecastScenario {
  id
  name
  isDefault
}
    `;
export const ForecastForScenarioSelectorFragmentDoc = gql`
    fragment ForecastForScenarioSelector on Forecast {
  id
  footprintSnapshotId
  acknowledgedFootprintSnapshotId
  scenarios {
    ...ForecastScenarioForScenarioSelector
  }
}
    ${ForecastScenarioForScenarioSelectorFragmentDoc}`;
export const AssigneeInfoForContainedQuestionsFragmentDoc = gql`
    fragment AssigneeInfoForContainedQuestions on ReportQuestionOrContainer {
  __typename
  ... on ReportQuestion {
    id
    assignees(reportId: $reportId) {
      id
      displayName
      name
    }
  }
  ... on ReportQuestionContainer {
    id
    items {
      __typename
      ... on ReportQuestion {
        id
        assignees(reportId: $reportId) {
          id
          displayName
          name
        }
      }
      ... on ReportQuestionContainer {
        id
        items {
          __typename
          ... on ReportQuestion {
            id
            assignees(reportId: $reportId) {
              id
              displayName
              name
            }
          }
          ... on ReportQuestionContainer {
            id
            items {
              __typename
              ... on ReportQuestion {
                id
                assignees(reportId: $reportId) {
                  id
                  displayName
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const ReportPageReportFragmentDoc = gql`
    fragment ReportPageReport on Report {
  __typename
  id
  name
  reportStatus
  reportKind
  warnings {
    id
    outputJson
    reportId
    validatorId
    acknowledgedBy {
      id
    }
  }
  config {
    id
    reportType
    shortName
    longName
    requiredPermissions
    createdAt
  }
  createdAt
  updatedAt
  deletedAt
}
    `;
export const ReportPageCustomReportFragmentDoc = gql`
    fragment ReportPageCustomReport on FootprintCustomReport {
  __typename
  id
  name
  description
  lastRefreshedAt
  updatedAt
  downloadUrl
}
    `;
export const ReportQuestionUsingSavedViewFieldsFragmentDoc = gql`
    fragment ReportQuestionUsingSavedViewFields on ReportQuestion {
  id
  componentId
  isComputed
}
    `;
export const ReportConfigQuestionForTableFragmentDoc = gql`
    fragment ReportConfigQuestionForTable on ReportQuestion {
  id
  identifier
  staticInputJson
  displayName
  materiality(reportId: $reportId)
  resolvedReportingIntention(reportId: $reportId) @include(if: $includeReportingIntention) {
    id
    intention
  }
  assignees(reportId: $reportId) {
    id
    name
    displayName
  }
  answer(reportId: $reportId) {
    latestCdpSyncEvent {
      id
      syncTime
      isSuccess
      reportQuestionId
      actor {
        name
        displayName
        id
      }
      failureKind
      failureDetails
    }
    id
    updatedAt
  }
  identifier
  componentId
  resolvedInputs(reportId: $reportId) {
    ... on ResolvedReportQuestionInputsFailure {
      errorMessage
      questionIds
    }
    ... on ResolvedReportQuestionInputsSuccess {
      inputs
    }
  }
  path {
    id
    label
  }
  resolvedStatusIncludingContainer(reportId: $reportId) {
    id
    status
    blockingMessage
  }
  parentContainer {
    label
    id
    resolvedStatus(reportId: $reportId) {
      id
      blockingMessage
      status
    }
    parentContainer {
      label
      id
      resolvedStatus(reportId: $reportId) {
        id
        blockingMessage
        status
      }
      parentContainer {
        label
        id
        resolvedStatus(reportId: $reportId) {
          id
          status
          blockingMessage
        }
      }
    }
  }
}
    `;
export const ReportWithConfigForTableFragmentDoc = gql`
    fragment ReportWithConfigForTable on ReportWithConfig {
  id
  report {
    id
    name
    cdpApiRegistration {
      id
      isReportRegistered
      cdpOrganizationId
    }
  }
  config {
    id
    reportType
    useMateriality
    useReportingIntention
    description
    status
    shortName
    longName
    allQuestions(input: $input) {
      ...ReportConfigQuestionForTable
    }
    inputs {
      id
      componentId
    }
  }
}
    ${ReportConfigQuestionForTableFragmentDoc}`;
export const PeerExternalReportAnswersForExternalAnswersListFragmentDoc = gql`
    fragment peerExternalReportAnswersForExternalAnswersList on PeerExternalReportAnswers {
  peerCompanies {
    id
    name
  }
  answers {
    id
    externalReportQuestionId
    publicDisclosure {
      id
      reportType
      publishingYear
      externalScore
      company {
        id
        name
        fullLogoUrl
      }
    }
    answerType
    answer
    watershedQualityScore
    questionPhrasing
    index
  }
  questions {
    id
    type {
      id
      type
      revision
    }
    reportQuestionIdentifier {
      id
      identifier
    }
    question
  }
}
    `;
export const ReportQuestionForBenchmarkFragmentDoc = gql`
    fragment ReportQuestionForBenchmark on ReportQuestion {
  __typename
  id
  identifier
  identifierId
  isComputed
  guidanceJson
  componentId
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
    excludingQuestionId
  }
  resolvedInputs(reportId: $reportId) {
    __typename
    ... on ResolvedReportQuestionInputsSuccess {
      inputs
      unparsedInputs
    }
    ... on ResolvedReportQuestionInputsFailure {
      errorMessage
      questionIds
    }
  }
  reportConfig {
    shortName
  }
  peerExternalReportAnswers {
    ...peerExternalReportAnswersForExternalAnswersList
  }
}
    ${PeerExternalReportAnswersForExternalAnswersListFragmentDoc}`;
export const ReportQuestionFieldsFragmentDoc = gql`
    fragment ReportQuestionFields on ReportQuestion {
  id
  identifier
  identifierId
  componentId
  isComputed
  guidanceJson
}
    `;
export const ReportAnswerWarningForCardFragmentDoc = gql`
    fragment ReportAnswerWarningForCard on ReportAnswerWarning {
  id
  outputJson
  reportId
  acknowledgedBy {
    id
  }
  answer {
    id
    question {
      id
      isComputed
      identifier
      parentContainer {
        id
        label
      }
    }
  }
  validatorId
}
    `;
export const ResolvedReportQuestionInputsFailureForReportItemFragmentDoc = gql`
    fragment ResolvedReportQuestionInputsFailureForReportItem on ResolvedReportQuestionInputsFailure {
  __typename
  errorMessage
  questionIds
}
    `;
export const ReportQuestionForReportItemFieldsFragmentDoc = gql`
    fragment ReportQuestionForReportItemFields on ReportQuestion {
  __typename
  ...ReportQuestionFields
  parentContainer {
    id
    label
  }
  resolvedWarnings(reportId: $reportId) {
    ...ReportAnswerWarningForCard
  }
  resolvedInputs(reportId: $reportId) {
    ...ResolvedReportQuestionInputsFailureForReportItem
    ... on ResolvedReportQuestionInputsSuccess {
      __typename
      inputs
      unparsedInputs
    }
  }
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
  }
  answer(reportId: $reportId) {
    id
    updatedAt
    outputJson
  }
  allowNotes
  allowedAttachmentKinds
  assignees(reportId: $reportId) {
    id
    displayName
    name
  }
}
    ${ReportQuestionFieldsFragmentDoc}
${ReportAnswerWarningForCardFragmentDoc}
${ResolvedReportQuestionInputsFailureForReportItemFragmentDoc}`;
export const CustomReportContentFragmentDoc = gql`
    fragment CustomReportContent on Report {
  id
  config {
    id
    items {
      __typename
      ... on ReportQuestion {
        ...ReportQuestionForReportItemFields
      }
      ... on ReportQuestionContainer {
        id
        label
        description
        items {
          ... on ReportQuestion {
            ...ReportQuestionForReportItemFields
          }
        }
      }
    }
  }
}
    ${ReportQuestionForReportItemFieldsFragmentDoc}`;
export const ContainerAssigneesFragmentDoc = gql`
    fragment ContainerAssignees on ReportQuestionContainer {
  id
  assignees(reportId: $reportId) {
    id
    displayName
    name
  }
}
    `;
export const ParentContainerAssigneesFragmentDoc = gql`
    fragment ParentContainerAssignees on ReportQuestionOrContainer {
  __typename
  ... on ReportQuestionContainer {
    ...ContainerAssignees
    parentContainer {
      ...ContainerAssignees
      parentContainer {
        ...ContainerAssignees
        parentContainer {
          ...ContainerAssignees
          parentContainer {
            ...ContainerAssignees
          }
        }
      }
    }
  }
}
    ${ContainerAssigneesFragmentDoc}`;
export const ReportQuestionFieldsWithReportingIntentionFragmentDoc = gql`
    fragment ReportQuestionFieldsWithReportingIntention on ReportQuestion {
  ...ReportQuestionForReportItemFields
  resolvedReportingIntention(reportId: $reportId) @include(if: $includeReportingIntention) {
    id
    intention
    event {
      createdBy {
        id
        displayName
      }
      createdAt
      reason
    }
  }
}
    ${ReportQuestionForReportItemFieldsFragmentDoc}`;
export const ReportQuestionFieldsForDebugFragmentDoc = gql`
    fragment ReportQuestionFieldsForDebug on ReportQuestion {
  ...ReportQuestionForReportItemFields
  dynamicInputJson
  staticInputJson
  statusJson
}
    ${ReportQuestionForReportItemFieldsFragmentDoc}`;
export const QuestionStatusForCdpSyncColumnFragmentDoc = gql`
    fragment QuestionStatusForCdpSyncColumn on CdpSyncQuestionStatus {
  id
  syncTime
  isSuccess
  reportQuestionId
  actor {
    name
    displayName
    id
  }
  failureKind
  failureDetails
}
    `;
export const QuestionStatusForCdpSyncDialogFragmentDoc = gql`
    fragment QuestionStatusForCdpSyncDialog on CdpSyncQuestionStatus {
  id
  reportId
  reportQuestionId
  syncTime
  isSuccess
  failureKind
  failureDetails
  reportQuestion {
    id
    staticInputJson
    parentContainer {
      id
      label
    }
    answer(reportId: $reportId) {
      id
      updatedAt
      latestCdpSyncEvent {
        id
        ...QuestionStatusForCdpSyncColumn
      }
    }
  }
}
    ${QuestionStatusForCdpSyncColumnFragmentDoc}`;
export const ReportQuestionNoteFieldsFragmentDoc = gql`
    fragment ReportQuestionNoteFields on Discussion {
  id
  title
  description
  state
  comments {
    edges {
      node {
        message
        id
        createdAt
        isPublished
        person {
          id
          displayName
          name
        }
      }
    }
  }
}
    `;
export const DrilldownSavedViewsForSelectorFragmentDoc = gql`
    fragment DrilldownSavedViewsForSelector on DrilldownSavedView {
  id
  name
  isPrivate
  biSavedViewId
  filterExpressions {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
  footprintKind
}
    `;
export const ReportingFootprintVersionFieldsFragmentDoc = gql`
    fragment ReportingFootprintVersionFields on FootprintVersion {
  id
  originalFootprintSnapshotId
  createdAt
  userChangelogMd
  publishableUserChangelogMd
  kind
  canUserPublish
  isUserVisible
  versionName
  versionIdentifier
}
    `;
export const ReductionsPlansWithNetZeroVariablesFragmentDoc = gql`
    fragment ReductionsPlansWithNetZeroVariables on Plan {
  id
  name
  state
  variables {
    commitmentsHasNetZero
    commitmentsNetZeroYear
  }
}
    `;
export const StandardTableUiFragmentDoc = gql`
    fragment StandardTableUI on ReportItemStandardTable {
  id
  columns {
    label
    alignment
    isMarkdown
    minWidthPx
  }
  rows {
    values
    explanations {
      markdownText
      url
    }
    colspans
  }
  isFullWidth
}
    `;
export const SecrStandardTableUiFragmentDoc = gql`
    fragment SecrStandardTableUI on ReportItemSecrStandardTable {
  id
  rows {
    values
    explanations {
      markdownText
      url
    }
    colspans
  }
  isFullWidth
}
    `;
export const TcfdArchetypeOpportunityFieldsFragmentDoc = gql`
    fragment TcfdArchetypeOpportunityFields on TcfdArchetypeOpportunity {
  id
  title
  userDescription
  archetypeRiskId
  geographies
  sectors
  distributionModel
}
    `;
export const ReportQuestionContainerStatusFieldsFragmentDoc = gql`
    fragment ReportQuestionContainerStatusFields on ReportQuestionContainer {
  __typename
  id
  label
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
    excludingQuestionId
  }
}
    `;
export const ReportQuestionContainerBaseFieldsFragmentDoc = gql`
    fragment ReportQuestionContainerBaseFields on ReportQuestionContainer {
  id
  label
  description
  statusJson
  guidanceJson
}
    `;
export const ReportAnswerWarningFieldsFragmentDoc = gql`
    fragment ReportAnswerWarningFields on ReportAnswerWarning {
  validatorId
  acknowledgedBy {
    id
    displayName
  }
  outputJson
  answer {
    question {
      ...ReportQuestionFields
    }
  }
}
    ${ReportQuestionFieldsFragmentDoc}`;
export const ReportAnswerFieldsFragmentDoc = gql`
    fragment ReportAnswerFields on ReportAnswer {
  id
  inputJson
  outputJson
  question {
    ...ReportQuestionFields
  }
}
    ${ReportQuestionFieldsFragmentDoc}`;
export const UserForReportQuestionAssignmentFragmentDoc = gql`
    fragment UserForReportQuestionAssignment on User {
  id
  displayName
  hasPermission(orgId: $orgId, permission: ViewFootprintDetail)
}
    `;
export const ReportExportButton_ReportFragmentDoc = gql`
    fragment ReportExportButton_report on Report {
  id
  name
  config {
    id
    reportType
    reportMetadataComputedItems: computedItems(
      filter: {componentId: "ReportMetadata"}
    ) {
      id
      resolvedStatus(reportId: $reportId) {
        id
        status
        blockingMessage
      }
      answer(reportId: $reportId) {
        id
        outputJson
      }
    }
  }
}
    `;
export const ReportConfigFieldsFragmentDoc = gql`
    fragment ReportConfigFields on ReportConfig {
  id
  shortName
  longName
  description
  brandColor
  status
  reportType
  updatedAt
  useTableDisplay
  useMateriality
  useReportingIntention
  inputs {
    ...ReportQuestionFields
    resolvedInputs(reportId: $reportId) {
      __typename
      ... on ResolvedReportQuestionInputsFailure {
        questionIds
        errorMessage
      }
      ... on ResolvedReportQuestionInputsSuccess {
        inputs
        unparsedInputs
      }
    }
    answer(reportId: $reportId) {
      id
      outputJson
    }
  }
  items {
    __typename
    ... on ReportQuestion {
      ...ReportQuestionForReportItemFields
    }
    ... on ReportQuestionContainer {
      id
      label
      description
      items {
        __typename
        ... on ReportQuestion {
          id
          ...ReportQuestionForReportItemFields
        }
        ... on ReportQuestionContainer {
          id
        }
      }
    }
  }
}
    ${ReportQuestionFieldsFragmentDoc}
${ReportQuestionForReportItemFieldsFragmentDoc}`;
export const ReportWithConfigFieldsFragmentDoc = gql`
    fragment ReportWithConfigFields on Report {
  ...ReportExportButton_report
  id
  name
  dmaId
  config {
    ...ReportConfigFields
  }
}
    ${ReportExportButton_ReportFragmentDoc}
${ReportConfigFieldsFragmentDoc}`;
export const CompanyReportingFactsFieldsFragmentDoc = gql`
    fragment CompanyReportingFactsFields on CompanyReportingFacts {
  id
  companyId
  asOfMonth
  overriddenAt
  numEmployeesGlobal
  numEmployeesEu
  numEmployeesUk
  revenueGlobalUsd
  revenueEuUsd
  revenueUkUsd
  assetsGlobalUsd
  assetsEuUsd
  assetsUkUsd
  federalContractsUsUsd
  federalContractsUkUsd
  largestContractUkUsd
  primaryJurisdiction
  legalEntityCountries
  listedExchanges
  secFilerStatus
  lseListingStatus
  nfrdFilerStatus
  isDoingBusinessCa
  isBank
  isInsurance
  isSmallCreditInsurance
  isTradedBankingLlpUk
  isLlpUk
  isNfisFilerUk
  isDirectorsFilerUk
  isPublicEu
}
    `;
export const PeerExternalReportQuestionFieldsFragmentDoc = gql`
    fragment PeerExternalReportQuestionFields on ExternalReportQuestion {
  id
  type {
    id
    type
    revision
  }
  reportQuestionIdentifier {
    id
    identifier
  }
  question
}
    `;
export const PeerExternalReportAnswerFieldsFragmentDoc = gql`
    fragment PeerExternalReportAnswerFields on ExternalReportAnswer {
  id
  externalReportQuestionId
  publicDisclosure {
    id
    reportType
    publishingYear
    externalScore
    company {
      id
      name
      fullLogoUrl
    }
  }
  answerType
  answer
  watershedQualityScore
  questionPhrasing
  index
}
    `;
export const ReportSummaryFragmentDoc = gql`
    fragment ReportSummary on Report {
  id
  name
  updatedAt
}
    `;
export const CustomReportSummaryFragmentDoc = gql`
    fragment CustomReportSummary on FootprintCustomReport {
  id
  name
  updatedAt
}
    `;
export const ReportQuestionDependentFieldsFragmentDoc = gql`
    fragment ReportQuestionDependentFields on ReportQuestion {
  id
  resolvedWarnings(reportId: $reportId) {
    ...ReportAnswerWarningForCard
  }
  answer(reportId: $reportId) {
    id
    outputJson
  }
  dependentItems(filter: {excludedComputed: true}) {
    inputs {
      id
      updatedAt
      resolvedInputs(reportId: $reportId) {
        __typename
        ... on ResolvedReportQuestionInputsFailure {
          questionIds
          errorMessage
        }
        ... on ResolvedReportQuestionInputsSuccess {
          inputs
          unparsedInputs
        }
      }
      resolvedWarnings(reportId: $reportId) {
        ...ReportAnswerWarningForCard
      }
    }
    questionStatuses {
      id
      updatedAt
      resolvedStatus(reportId: $reportId) {
        id
        status
        blockingMessage
        excludingQuestionId
      }
    }
    containerStatuses {
      id
      resolvedStatus(reportId: $reportId) {
        id
        status
        blockingMessage
        excludingQuestionId
      }
    }
    containerProgresses {
      id
      automaticProgress(reportId: $reportId)
    }
  }
}
    ${ReportAnswerWarningForCardFragmentDoc}`;
export const UserTablePermissionsFragmentDoc = gql`
    fragment UserTablePermissions on User {
  id
  name
  displayName
  userUploadTasks {
    ...UserTaskPermissions
  }
  roles(orgId: $orgId) {
    ...UserRoleFields
  }
  permissions(orgId: $orgId) {
    id
    ...PermissionFields
  }
  orgAccessId(orgId: $orgId)
}
    ${UserTaskPermissionsFragmentDoc}
${UserRoleFieldsFragmentDoc}
${PermissionFieldsFragmentDoc}`;
export const OrganizationMemberFieldsFragmentDoc = gql`
    fragment OrganizationMemberFields on User {
  id
  orgAccessCreatedAt(orgId: $orgId)
  name
  email
  loginActivated
  signupLink(orgId: $orgId)
  consecutiveLoginFailures
  ...UserTablePermissions
  userRoles: roles(orgId: $orgId) {
    ...UserRoleFields
  }
  userPermissions: permissions(orgId: $orgId) {
    ...PermissionFields
  }
  didLatestEmailBounce
  hasValidAppSessions
}
    ${UserTablePermissionsFragmentDoc}
${UserRoleFieldsFragmentDoc}
${PermissionFieldsFragmentDoc}`;
export const CompanyFieldsForEngagementTasksFragmentDoc = gql`
    fragment CompanyFieldsForEngagementTasks on Company {
  id
  name
  fullLogoUrl
  naicsCode
}
    `;
export const PublicDisclosureForBenchmarksFragmentDoc = gql`
    fragment PublicDisclosureForBenchmarks on PublicDisclosure {
  id
  reportType
  publishingYear
  reportingYears
  publicUrl
  qualityScore
  cdpVendorData {
    ...CdpVendorDataFields
  }
  externalScore
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const CompanyForBenchmarksFragmentDoc = gql`
    fragment CompanyForBenchmarks on Company {
  id
  name
  description
  sustainabilityWebsiteUrl
  disclosuresV2(opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}) {
    id
    publicDisclosure {
      ...PublicDisclosureForBenchmarks
    }
    historicalEmissionsYears {
      ...HistoricalEmissionsYearFields
    }
    climateCommitments {
      ...CompanyClimateCommitmentFieldsForCCIS
    }
  }
  naicsCode
  fullLogoUrl
}
    ${PublicDisclosureForBenchmarksFragmentDoc}
${HistoricalEmissionsYearFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsForCcisFragmentDoc}`;
export const PeerCompanyGroupForBenchmarksFragmentDoc = gql`
    fragment PeerCompanyGroupForBenchmarks on PeerCompanyGroup {
  id
  name
  createdAt
  members {
    id
    company {
      ...CompanyForBenchmarks
    }
    revenueAtRisk
  }
  shouldHideFromFootprintOverview
  revenueForRevenueAtRisk
}
    ${CompanyForBenchmarksFragmentDoc}`;
export const AccessibleOrgsAndEngagementTasksFragmentDoc = gql`
    fragment AccessibleOrgsAndEngagementTasks on ActiveOrganization {
  id
  user {
    id
    accessibleOrgs {
      id
      name
      watershedPlanLegacy
      company {
        id
        fullLogoUrl
      }
      engagementTasks {
        id
        rootCompany {
          id
          name
          fullLogoUrl
        }
      }
    }
  }
}
    `;
export const WatershedFootprintReviewRequestForReviewPageFragmentDoc = gql`
    fragment WatershedFootprintReviewRequestForReviewPage on WatershedFootprintReviewRequest {
  id
  createdAt
  user {
    id
    displayName
  }
  status
  discussion {
    id
  }
}
    `;
export const FootprintVersionForReviewFragmentDoc = gql`
    fragment FootprintVersionForReview on FootprintVersion {
  id
  kind
  canUserPublish
  versionName
  versionIdentifier
  originalFootprintSnapshotId
  userChangelogMd
  publishableUserChangelogMd
  createdAt
  generatedBy {
    id
    displayName
  }
  watershedFootprintReviewRequest {
    ...WatershedFootprintReviewRequestForReviewPage
  }
}
    ${WatershedFootprintReviewRequestForReviewPageFragmentDoc}`;
export const TimeseriesFieldsFragmentDoc = gql`
    fragment TimeseriesFields on FootprintAnalysisTimeSeries {
  id
  kind
  series {
    label
    value
    data {
      date
      value
      headcount
      revenue
      customIntensities {
        customIntensityId
        value
      }
    }
  }
}
    `;
export const BenchmarkFieldsForInsightsFragmentDoc = gql`
    fragment BenchmarkFieldsForInsights on Benchmark {
  id
  reportId
  name
  reportingYear
  publishingYear
  kind
  normalizationKind
  amountKgco2e
  kgco2eByScope {
    scope
    kgco2e
  }
  currency
  source
  qualityScore
  scope2Source
}
    `;
export const PeerCompanyGroupFieldsForInsightsFragmentDoc = gql`
    fragment PeerCompanyGroupFieldsForInsights on PeerCompanyGroup {
  id
  name
  footprintKinds
  members {
    id
    company {
      id
      name
    }
    shouldHideFromFootprintOverview
    mostRecentHighQualityBenchmark {
      ...BenchmarkFieldsForInsights
    }
  }
  selfBenchmarks(footprintSnapshotId: $footprintSnapshotId) {
    ...BenchmarkFieldsForInsights
  }
  shouldHideFromFootprintOverview
}
    ${BenchmarkFieldsForInsightsFragmentDoc}`;
export const FootprintVersionForOverviewFragmentDoc = gql`
    fragment FootprintVersionForOverview on FootprintVersion {
  id
  kind
  createdAt
  originalFootprintSnapshotId
}
    `;
export const PlanFieldsForHomepageFragmentDoc = gql`
    fragment PlanFieldsForHomepage on Plan {
  id
  name
  forecast {
    id
    interval
    customIntensityConfigs {
      ...CustomIntensityConfigFields
    }
  }
  targets {
    ...PlanTargetFields
  }
  carbonOffsets: reduxCarbonOffsets {
    ...SimpleTimeseriesFields
  }
  carbonRemoval: reduxCarbonRemoval {
    ...SimpleTimeseriesFields
  }
}
    ${CustomIntensityConfigFieldsFragmentDoc}
${PlanTargetFieldsFragmentDoc}
${SimpleTimeseriesFieldsFragmentDoc}`;
export const FootprintAnalysisTimeSeriesDataFieldsFragmentDoc = gql`
    fragment FootprintAnalysisTimeSeriesDataFields on FootprintAnalysisTimeSeriesDataPoint {
  date
  value
}
    `;
export const MeasurementProjectForHomepageFragmentDoc = gql`
    fragment MeasurementProjectForHomepage on MeasurementProject {
  id
  name
  active
  coverageStartDate
  coverageEndDate
  completedAt
}
    `;
export const MarketplaceDocumentFieldsForPurchasesPageFragmentDoc = gql`
    fragment MarketplaceDocumentFieldsForPurchasesPage on MarketplaceDocument {
  id
  name
  kind
  downloadUrl
}
    `;
export const MarketplacePurchaseLineItemFieldsForPurchasesPageFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFieldsForPurchasesPage on MarketplacePurchaseLineItem {
  id
  amount
  amountUnit
  coverageInterval
  unitCostUsdCents
  deliveryDate
  isDelivered
  archetype {
    id
    name
    bannerImageUrl
    imageUrl
    descriptionMd
    certificateBlurb
    kind
    summaryMd
  }
  project {
    id
    name
    developer {
      id
      name
      imageUrl
    }
  }
  documents {
    edges {
      node {
        ...MarketplaceDocumentFieldsForPurchasesPage
      }
    }
  }
  eacMetadata {
    id
    countryWithEmissions
    countryWithEacs
    numEacsRequested
    mwh
    marketTco2e
  }
}
    ${MarketplaceDocumentFieldsForPurchasesPageFragmentDoc}`;
export const MarketplacePurchaseFieldsForPurchasesPageFragmentDoc = gql`
    fragment MarketplacePurchaseFieldsForPurchasesPage on MarketplacePurchase {
  id
  name
  createdAt
  updatedAt
  totalCostUsdCents
  customerStatus
  offerExpiration
  lineItems {
    edges {
      node {
        ...MarketplacePurchaseLineItemFieldsForPurchasesPage
      }
    }
  }
  documents {
    edges {
      node {
        ...MarketplaceDocumentFieldsForPurchasesPage
      }
    }
  }
  portfolio {
    id
  }
  eacMetadata {
    id
    interval
    requestNotes
    ghgCategoryIds
    marketTco2e
  }
}
    ${MarketplacePurchaseLineItemFieldsForPurchasesPageFragmentDoc}
${MarketplaceDocumentFieldsForPurchasesPageFragmentDoc}`;
export const CurrencyExchangeRateForPurchasesPageFragmentDoc = gql`
    fragment CurrencyExchangeRateForPurchasesPage on CurrencyExchangeRate {
  id
  currency
  updatedAt
  value
}
    `;
export const IngestionCustomFieldForMeasurementPageFragmentDoc = gql`
    fragment IngestionCustomFieldForMeasurementPage on IngestionCustomField {
  id
  name
  type
  possibleValues
  description
  appliesToAllDatasets
  specificDatasets {
    ...DatasetFieldsForCustomFieldEditDialog
  }
}
    ${DatasetFieldsForCustomFieldEditDialogFragmentDoc}`;
export const IngestionCustomFieldDatasourceForMeasurementPageFragmentDoc = gql`
    fragment IngestionCustomFieldDatasourceForMeasurementPage on IngestionCustomFieldDatasource {
  id
  datasourceId
  ingestionCustomFieldId
  ingestionCustomFieldValue
}
    `;
export const PresetCustomTagForMeasurementPageFragmentDoc = gql`
    fragment PresetCustomTagForMeasurementPage on PresetCustomTag {
  ingestionCustomField {
    ...IngestionCustomFieldForMeasurementPage
  }
  ingestionCustomFieldDatasource {
    ...IngestionCustomFieldDatasourceForMeasurementPage
  }
}
    ${IngestionCustomFieldForMeasurementPageFragmentDoc}
${IngestionCustomFieldDatasourceForMeasurementPageFragmentDoc}`;
export const MeasurementProjectPageIngestionCustomFieldFragmentDoc = gql`
    fragment MeasurementProjectPageIngestionCustomField on IngestionCustomField {
  ...DataCollectionCommandPaletteCustomField
  id
  name
  type
  possibleValues
  description
  appliesToAllDatasets
  specificDatasets {
    ...DatasetFieldsForCustomFieldEditDialog
  }
}
    ${DataCollectionCommandPaletteCustomFieldFragmentDoc}
${DatasetFieldsForCustomFieldEditDialogFragmentDoc}`;
export const IngestionCustomFieldForDatasourcePageFragmentDoc = gql`
    fragment IngestionCustomFieldForDatasourcePage on IngestionCustomField {
  ...DataCollectionCommandPaletteCustomField
  id
  name
  type
  possibleValues
  description
  appliesToAllDatasets
  specificDatasets {
    ...DatasetFieldsForCustomFieldEditDialog
  }
}
    ${DataCollectionCommandPaletteCustomFieldFragmentDoc}
${DatasetFieldsForCustomFieldEditDialogFragmentDoc}`;
export const OrganizationCustomFieldsForDatasourcePageFragmentDoc = gql`
    fragment OrganizationCustomFieldsForDatasourcePage on Organization {
  id
  ingestionCustomFields {
    ...IngestionCustomFieldForDatasourcePage
  }
}
    ${IngestionCustomFieldForDatasourcePageFragmentDoc}`;
export const MeasurementProjectFieldsForDatasourcePageFragmentDoc = gql`
    fragment MeasurementProjectFieldsForDatasourcePage on MeasurementProject {
  id
  name
  active
  coverageInterval
  coverageStartDate
  coverageEndDate
  datasetsWithStages {
    id
    areAllTasksComplete
  }
  measurementTimeline {
    id
    deadline
  }
}
    `;
export const ClimateProgramProjectRequirementDatasetForDatasourceInstructionsFragmentDoc = gql`
    fragment ClimateProgramProjectRequirementDatasetForDatasourceInstructions on ClimateProgramProjectRequirementDataset {
  id
  additionalNotesMd
  climateProgramProject {
    ...ClimateProgramProjectMeasurementCopy
    name
  }
}
    ${ClimateProgramProjectMeasurementCopyFragmentDoc}`;
export const UserUploadForDatasourceFragmentDoc = gql`
    fragment UserUploadForDatasource on UserUpload {
  id
  name
  status
  processingMode
  userVisibleErrorMessage
  createdAt
  latestAdeIdForSDIFacilitiesFlow
  description
  revisionRoot
  processingWorkflowId
  isBeingValueMapped
  ctsVersion {
    id
  }
  hasStaleSnapshot
  userVisibleAttempt {
    id
    name
    size
    remoteWritten
    createdAt
    uploader {
      id
      name
    }
    userUploadedTables {
      ...UserUploadedTableWithDataPreview
    }
    latestUutsIfRevisionExists {
      ...UserUploadedTableWithDataPreview
    }
    userUploadSource
  }
  userUploadTask {
    id
    measurementProject {
      id
      name
    }
    facilitiesPreview {
      totalCount
    }
    datasource {
      id
      dataset {
        id
        canonicalDataset {
          id
          kind
        }
      }
    }
  }
  actualUserUploadId
  associatedUtilityCount
  transformsIncludingDeleted {
    ...CtsTransformForEditor
  }
  userUploadKind
}
    ${UserUploadedTableWithDataPreviewFragmentDoc}
${CtsTransformForEditorFragmentDoc}`;
export const ValueMappingTaskFieldsForDatasourcePageFragmentDoc = gql`
    fragment ValueMappingTaskFieldsForDatasourcePage on ValueMappingTask {
  id
  mappingRuleId
  status
}
    `;
export const CtsFormWithResultsFieldsFragmentDoc = gql`
    fragment CtsFormWithResultsFields on CtsForm {
  id
  ctsVersionId
  customerTargetSchemaId
  datasetId
  title
  description
  entries {
    id
    data
    createdBy {
      id
      displayName
    }
  }
}
    `;
export const UserUploadTaskFieldsForDatasourcePageFragmentDoc = gql`
    fragment UserUploadTaskFieldsForDatasourcePage on UserUploadTask {
  id
  createdAt
  state
  importState
  approvalStatus
  assignees {
    id
    displayName
    name
  }
  assignee {
    id
    displayName
    name
  }
  measurementTask {
    id
    status
    statusSimplified
  }
  measurementProject {
    ...MeasurementProjectFieldsForDatasourcePage
  }
  datasetRequirements {
    ...ClimateProgramProjectRequirementDatasetForDatasourceInstructions
  }
  userUploads {
    edges {
      node {
        ...UserUploadForDatasource
      }
    }
  }
  issues(isPublished: true) {
    edges {
      node {
        ...DataIssueWithCommentsFields
      }
    }
  }
  valueMappingTask {
    id
    mappingRuleId
  }
  valueMappingTasks {
    ...ValueMappingTaskFieldsForDatasourcePage
  }
  approvers {
    ...ApproverFieldsForMeasurementPage
  }
  isDatasetComplete
  ctsForm {
    ...CtsFormWithResultsFields
  }
  lockState
}
    ${MeasurementProjectFieldsForDatasourcePageFragmentDoc}
${ClimateProgramProjectRequirementDatasetForDatasourceInstructionsFragmentDoc}
${UserUploadForDatasourceFragmentDoc}
${DataIssueWithCommentsFieldsFragmentDoc}
${ValueMappingTaskFieldsForDatasourcePageFragmentDoc}
${ApproverFieldsForMeasurementPageFragmentDoc}
${CtsFormWithResultsFieldsFragmentDoc}`;
export const IntegrationPartnerForDatasourcePageFragmentDoc = gql`
    fragment IntegrationPartnerForDatasourcePage on IntegrationPartner {
  id
  name
  logoUrl
  externalId
  dataType
  isRequestOnly
  applicableDatasources {
    id
    name
    hasIntegration
    isUserConfirmed
    activeIntegrationConnection {
      id
    }
    dataset {
      id
      name
    }
  }
  activeIntegrationConnections {
    id
    additionalIdentifier
  }
}
    `;
export const CanonicalDatasourceForDatasourcePageFragmentDoc = gql`
    fragment CanonicalDatasourceForDatasourcePage on CanonicalDatasource {
  id
  name
  integrationPartner {
    ...IntegrationPartnerForDatasourcePage
  }
}
    ${IntegrationPartnerForDatasourcePageFragmentDoc}`;
export const IngestionCustomFieldDatasourceForDatasourcePageFragmentDoc = gql`
    fragment IngestionCustomFieldDatasourceForDatasourcePage on IngestionCustomFieldDatasource {
  id
  datasourceId
  ingestionCustomFieldId
  ingestionCustomFieldValue
}
    `;
export const PresetCustomTagForDatasourcePageFragmentDoc = gql`
    fragment PresetCustomTagForDatasourcePage on PresetCustomTag {
  ingestionCustomField {
    ...IngestionCustomFieldForDatasourcePage
  }
  ingestionCustomFieldDatasource {
    ...IngestionCustomFieldDatasourceForDatasourcePage
  }
}
    ${IngestionCustomFieldForDatasourcePageFragmentDoc}
${IngestionCustomFieldDatasourceForDatasourcePageFragmentDoc}`;
export const PresetOrgUnitForDatasourcePageFragmentDoc = gql`
    fragment PresetOrgUnitForDatasourcePage on PresetOrgUnit {
  orgUnit {
    id
    name
  }
  orgUnitDatasource {
    id
  }
}
    `;
export const DatasourceFieldsForDatasourcePageFragmentDoc = gql`
    fragment DatasourceFieldsForDatasourcePage on Datasource {
  id
  name
  isUserConfirmed
  hasIntegrationOption
  hasIntegration
  customerTargetSchemaIds
  activeIntegrationConnection {
    id
    additionalIdentifier
  }
  instructions {
    ...InstructionsBundleForImportInstructions
  }
  dataset {
    id
    name
    datasources {
      id
      name
      customerTargetSchemaIds
    }
    canonicalDataset {
      ...CanonicalDatasetFieldsForImport
    }
    customExampleData {
      cells
    }
  }
  canonicalDatasource {
    ...CanonicalDatasourceForDatasourcePage
  }
  importInstructionsMd
  refreshCadence
  userUploadTasks {
    edges {
      node {
        ...UserUploadTaskFieldsForDatasourcePage
      }
    }
  }
  isUserConfirmed
  hasApiAccess
  notesFromWatershedMd
  presetCustomTags {
    ...PresetCustomTagForDatasourcePage
  }
  presetOrgUnits {
    ...PresetOrgUnitForDatasourcePage
  }
}
    ${InstructionsBundleForImportInstructionsFragmentDoc}
${CanonicalDatasetFieldsForImportFragmentDoc}
${CanonicalDatasourceForDatasourcePageFragmentDoc}
${UserUploadTaskFieldsForDatasourcePageFragmentDoc}
${PresetCustomTagForDatasourcePageFragmentDoc}
${PresetOrgUnitForDatasourcePageFragmentDoc}`;
export const UserUploadTaskWithDatasourceFragmentDoc = gql`
    fragment UserUploadTaskWithDatasource on UserUploadTask {
  ...UserUploadTaskFieldsForDatasourcePage
  datasource {
    ...DatasourceFieldsForDatasourcePage
  }
  facilitiesPreview {
    totalCount
  }
  hasIncompleteBuildings
}
    ${UserUploadTaskFieldsForDatasourcePageFragmentDoc}
${DatasourceFieldsForDatasourcePageFragmentDoc}`;
export const ActivitySummaryActivityDatasetFragmentDoc = gql`
    fragment ActivitySummaryActivityDataset on ActivityDataset {
  businessActivityType
  updatedAt
  datasets {
    id
    name
  }
  lastUpdater {
    id
    displayName
  }
  uploads {
    id
  }
}
    `;
export const LegacyActivityRecordFragmentDoc = gql`
    fragment LegacyActivityRecord on ActivityRecord {
  stableId
  displayName
  datasets {
    id
    name
  }
  activityItemCreatedAt
}
    `;
export const MeasurementProjectForDataGovernanceFragmentDoc = gql`
    fragment MeasurementProjectForDataGovernance on MeasurementProject {
  id
  name
}
    `;
export const MeasurementProjectFieldsFragmentDoc = gql`
    fragment MeasurementProjectFields on MeasurementProject {
  id
  name
  active
  status
  createdAt
  completedAt
  coverageInterval
  coverageStartDate
  coverageEndDate
  deadline
  ...MeasurementProjectForSendEmailsButton
}
    ${MeasurementProjectForSendEmailsButtonFragmentDoc}`;
export const MeasurementProjectsPageProjectFragmentDoc = gql`
    fragment MeasurementProjectsPageProject on MeasurementProject {
  ...MeasurementProjectFields
  setupBy {
    id
    name
    displayName
  }
  datasetsWithStages {
    id
    areAllTasksComplete
  }
}
    ${MeasurementProjectFieldsFragmentDoc}`;
export const SharedFileForDashboardPageFragmentDoc = gql`
    fragment SharedFileForDashboardPage on SharedFile {
  id
  expiresAt
  fileMetadata {
    id
    name
    size
    createdAt
    uploader {
      id
      name
    }
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
}
    `;
export const UserForOrgStructureEditsFragmentDoc = gql`
    fragment UserForOrgStructureEdits on User {
  id
  displayName
  isWatershedEmployee
}
    `;
export const OrgStructureVersionFragmentDoc = gql`
    fragment OrgStructureVersion on OrgStructureVersion {
  id
  name
  isPublished
  updatedAt
  updatedBy {
    ...UserForOrgStructureEdits
  }
  effectiveFromDate
  effectiveToDate
}
    ${UserForOrgStructureEditsFragmentDoc}`;
export const OrgUnitTypeRelationshipFragmentDoc = gql`
    fragment OrgUnitTypeRelationship on OrgUnitTypeRelationship {
  id
  orgUnitTypeIdFrom
  orgUnitTypeIdTo
  cardinality
  isRequired
  defaultOrgUnitId
}
    `;
export const OrgUnitTypeFragmentDoc = gql`
    fragment OrgUnitType on OrgUnitType {
  id
  name
  externalId
  updatedAt
  updatedBy {
    ...UserForOrgStructureEdits
  }
  isLegalEntity
  enabledForAllDatasets
  requiredForAllDatasets
  enabledDatasets {
    id
    datasetId
    name
    isRequired
  }
  orgUnits {
    id
    name
    externalId
  }
  relationships {
    ...OrgUnitTypeRelationship
  }
}
    ${UserForOrgStructureEditsFragmentDoc}
${OrgUnitTypeRelationshipFragmentDoc}`;
export const OrgUnitFragmentDoc = gql`
    fragment OrgUnit on OrgUnit {
  id
  name
  externalId
  description
  orgUnitTypeId
  relatedOrgUnits {
    id
    name
    orgUnitTypeId
  }
  orgUnitDatasources {
    id
    datasourceId
    orgUnitTypeStableId
    orgUnitStableId
    createdAt
    updatedAt
  }
}
    `;
export const OrganizationForOrgUnitTypePageFragmentDoc = gql`
    fragment OrganizationForOrgUnitTypePage on Organization {
  id
  datasets {
    id
    name
    explainer
  }
}
    `;
export const OrgUnitTypeWithOrgUnitsFragmentDoc = gql`
    fragment OrgUnitTypeWithOrgUnits on OrgUnitType {
  id
  name
  stableId
  orgUnits {
    id
    name
    stableId
  }
}
    `;
export const OrgStructureVersionWithTypesAndUnitsFragmentDoc = gql`
    fragment OrgStructureVersionWithTypesAndUnits on OrgStructureVersion {
  id
  orgUnitTypes {
    ...OrgUnitTypeWithOrgUnits
  }
}
    ${OrgUnitTypeWithOrgUnitsFragmentDoc}`;
export const OrgUnitTypeForOrgUnitGridFragmentDoc = gql`
    fragment OrgUnitTypeForOrgUnitGrid on OrgUnitType {
  id
  name
  orgUnits {
    id
    name
    externalId
  }
  cyclesWhenFromNode(baseOrgUnitTypeId: $orgUnitTypeId)
  cyclesWhenToNode(baseOrgUnitTypeId: $orgUnitTypeId)
}
    `;
export const UserInputtedForecastPeriodFieldsFragmentDoc = gql`
    fragment UserInputtedForecastPeriodFields on UserInputtedForecastPeriodPoint {
  date
  customGrowthFactors {
    customIntensityId
    value
  }
}
    `;
export const CreateEditCustomIntensityDialog_CustomIntensityConfigFragmentDoc = gql`
    fragment CreateEditCustomIntensityDialog_customIntensityConfig on CustomIntensityConfig {
  id
  name
  description
  unit
  humanReadable
  quantityType
  isSbtValidated
}
    `;
export const ManageCustomIntensities_CustomIntensityConfigFragmentDoc = gql`
    fragment manageCustomIntensities_customIntensityConfig on CustomIntensityConfig {
  ...CreateEditCustomIntensityDialog_customIntensityConfig
  id
  name
  description
  unit
  humanReadable
  quantityType
  isSbtValidated
}
    ${CreateEditCustomIntensityDialog_CustomIntensityConfigFragmentDoc}`;
export const BusinessCategoryForForecastingFragmentDoc = gql`
    fragment BusinessCategoryForForecasting on CategorizedEmissionData {
  businessCategory
}
    `;
export const ForecastFieldsFragmentDoc = gql`
    fragment ForecastFields on Forecast {
  id
  interval
  footprintSnapshotId
  scenarios {
    ...ForecastScenarioFields
  }
  referencePeriodInterval
  referencePeriod {
    ...ForecastPeriodFields
  }
  historicalPeriod {
    ...ForecastPeriodFields
  }
  overrideFootprintKind
  validYearsForIntervalEnd
  validReferenceInterval
  planTargets {
    id
    intensityType
    customIntensityConfigId
  }
  customIntensityConfigs {
    ...manageCustomIntensities_customIntensityConfig
    ...CustomIntensityConfigFields
  }
  businessCategories {
    ...BusinessCategoryForForecasting
  }
}
    ${ForecastScenarioFieldsFragmentDoc}
${ForecastPeriodFieldsFragmentDoc}
${ManageCustomIntensities_CustomIntensityConfigFragmentDoc}
${CustomIntensityConfigFieldsFragmentDoc}
${BusinessCategoryForForecastingFragmentDoc}`;
export const ReductionsMetricsPage_ForecastFragmentDoc = gql`
    fragment ReductionsMetricsPage_forecast on Forecast {
  id
  scenarios {
    id
    name
    growthForecasts {
      id
      customIntensityConfigId
    }
  }
  customIntensityConfigs {
    ...manageCustomIntensities_customIntensityConfig
    id
    name
    quantityType
    description
    unit
    humanReadable
    isSbtValidated
  }
}
    ${ManageCustomIntensities_CustomIntensityConfigFragmentDoc}`;
export const PlanForPlanSettingsFragmentDoc = gql`
    fragment PlanForPlanSettings on Plan {
  id
  name
  baselineYearStart
  isSbtValidated
  variables {
    commitmentsSBTSubmissionDate
    commitmentsSBTTermLength
    commitmentsSBTScope12
    commitmentsSBTScope3
  }
  forecast {
    id
    interval
    referencePeriod {
      interval
    }
    historicalPeriod {
      interval
    }
    validReferenceInterval
  }
  fiscalMonth
}
    `;
export const MarketplacePurchaseLineItemFieldsForSummaryReportFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFieldsForSummaryReport on MarketplacePurchaseLineItem {
  id
  amount
  amountUnit
  deliveryDate
  isDelivered
  archetype {
    id
    name
    bannerImageUrl
    imageUrl
    descriptionMd
    certificateBlurb
    kind
    mechanism
    summaryMd
  }
  project {
    id
    name
    developer {
      id
      name
      imageUrl
    }
  }
}
    `;
export const NetZeroSummaryYearFieldsFragmentDoc = gql`
    fragment NetZeroSummaryYearFields on NetZeroSummaryYear {
  year
  kind
  emissionsWithReductions
  bauEmissionsAvoided
  carbonRemoval
}
    `;
export const PlanFieldsForReportFragmentDoc = gql`
    fragment PlanFieldsForReport on Plan {
  id
  forecast {
    id
    interval
    customIntensityConfigs {
      ...CustomIntensityConfigFields
    }
  }
  variables {
    commitmentsHasNetZero
    commitmentsNetZeroYear
  }
  targets {
    ...PlanTargetFields
  }
}
    ${CustomIntensityConfigFieldsFragmentDoc}
${PlanTargetFieldsFragmentDoc}`;
export const BenchmarkFieldsFragmentDoc = gql`
    fragment BenchmarkFields on Benchmark {
  __typename
  id
  reportId
  name
  reportingYear
  publishingYear
  kind
  normalizationKind
  amountKgco2e
  kgco2eByScope {
    scope
    kgco2e
  }
  currency
  scope2Source
}
    `;
export const PeerCompanyGroupFieldsFragmentDoc = gql`
    fragment PeerCompanyGroupFields on PeerCompanyGroup {
  __typename
  id
  name
  footprintKinds
  members {
    id
    shouldHideFromFootprintOverview
    mostRecentHighQualityBenchmark {
      ...BenchmarkFields
    }
  }
  selfBenchmarks {
    ...BenchmarkFields
  }
  shouldHideFromFootprintOverview
}
    ${BenchmarkFieldsFragmentDoc}`;
export const PublicDisclosureDisplayFragmentDoc = gql`
    fragment PublicDisclosureDisplay on PublicDisclosure {
  id
  companyId
  reportType
  publishingYear
  gsUrl
  publicUrl
  sectionUrls {
    id
    sectionKind
    urlFragment
  }
  company {
    id
    name
    fullLogoUrl
  }
}
    `;
export const PeerCompanyReportsFragmentDoc = gql`
    fragment PeerCompanyReports on PeerCompanyGroup {
  id
  members {
    id
    company {
      id
      disclosuresV2 {
        id
        publicDisclosure {
          ...PublicDisclosureDisplay
        }
      }
    }
  }
}
    ${PublicDisclosureDisplayFragmentDoc}`;
export const ActiveOrganizationFundFragmentDoc = gql`
    fragment ActiveOrganizationFund on Fund {
  id
  name
  nameSortable
  fundGroup
  fundCategory
  excludeAsSandbox
}
    `;
export const FootprintKindMetaFieldsFragmentDoc = gql`
    fragment FootprintKindMetaFields on FootprintKindMeta {
  id
  name
  description
  footprintInterval
  footprintDraftInterval
}
    `;
export const PermissionDetailsFragmentDoc = gql`
    fragment PermissionDetails on PermissionItem {
  id
  permission
  object {
    __typename
    id
  }
}
    `;
export const FundAllFieldsFragmentDoc = gql`
    fragment FundAllFields on Fund {
  __typename
  id
  name
  nameSortable
  fundGroup
  fundCategory
  excludeAsSandbox
  anonymousFundUuid
  createdAt
  orgId
  updatedAt
  tagData
  externalId
}
    `;
export const ActiveOrganizationSavedViewFragmentDoc = gql`
    fragment ActiveOrganizationSavedView on FinanceSavedView {
  id
  name
}
    `;
export const ActiveOrganizationFieldsFragmentDoc = gql`
    fragment ActiveOrganizationFields on ActiveOrganization {
  id
  name
  helpChannel
  demoOrg
  testOrg
  stagingOrg
  region
  watershedPlan
  watershedPlanLegacy
  canAccessFinance
  canAccessCorporate
  fiscalYearStartMonth
  currency
  country
  companyId
  domains
  logoUrl
  iconUrl
  sessionId
  user {
    id
    createdAt
    name
    accessibleOrgs {
      id
      name
      companyId
    }
    email
    isWatershedEmployee
    isWatershedContractor
    isE2ETester
  }
  loginAsUser {
    id
    name
    isWatershedEmployee
    isWatershedContractor
    isE2ETester
  }
  userOnboardingsCompleted
  latestPublishedFootprintVersion
  latestDraftFootprintVersion
  footprintKindsMeta {
    ...FootprintKindMetaFields
  }
  watershedEmployeePointOfContactKinds
  userRoles {
    id
    role {
      id
      permissions {
        id
        ...PermissionDetails
      }
    }
  }
  userPermissions {
    id
    ...PermissionDetails
  }
  datasourceIdsToCompletePermissions
  funds {
    id
    ...FundAllFields
  }
  financeSavedViews {
    id
    ...ActiveOrganizationSavedView
  }
}
    ${FootprintKindMetaFieldsFragmentDoc}
${PermissionDetailsFragmentDoc}
${FundAllFieldsFragmentDoc}
${ActiveOrganizationSavedViewFragmentDoc}`;
export const FlagsInitFieldsFragmentDoc = gql`
    fragment FlagsInitFields on FeatureFlag {
  id
  name
  description
  activated
}
    `;
export const MeasurementProjectForDemoPlatformFragmentDoc = gql`
    fragment MeasurementProjectForDemoPlatform on MeasurementProject {
  id
  name
  coverageStartDate
  coverageEndDate
  active
}
    `;
export const DemoPlatformRunForPageFragmentDoc = gql`
    fragment DemoPlatformRunForPage on DemoPlatformRun {
  id
  config
  statusJson
  user {
    id
    name
  }
  createdAt
}
    `;
export const DemoPlatformRunForGridFragmentDoc = gql`
    fragment DemoPlatformRunForGrid on DemoPlatformRun {
  id
  config
  statusJson
  user {
    id
    name
  }
  createdAt
}
    `;
export const FinanceSnapshotIsStaleFragmentDoc = gql`
    fragment FinanceSnapshotIsStale on FinanceSnapshot {
  id
  isStale
}
    `;
export const FinanceSnapshotForContextFragmentDoc = gql`
    fragment FinanceSnapshotForContext on FinanceSnapshot {
  id
  snapshotName
  note
  config
  createdAt
  createdBy {
    id
    name
    displayName
  }
  executionStatus
  executionStatusMessage
  completedAt
  isStale
  flagsPopulated
  financedEmissionsTonnesCurrentYear
  footprintSnapshotId
}
    `;
export const SupplierContactAllFieldsFragmentDoc = gql`
    fragment SupplierContactAllFields on SupplierContact {
  id
  name
  email
  role
  companyId
  userId
}
    `;
export const FootprintVersionInfoForStatusFragmentDoc = gql`
    fragment FootprintVersionInfoForStatus on FootprintVersion {
  id
  createdAt
  kind
  originalFootprintSnapshotId
  userVisibleFootprintSnapshotId
}
    `;
export const ReportAnswerForHistoryNodeFieldsFragmentDoc = gql`
    fragment ReportAnswerForHistoryNodeFields on ReportAnswer {
  id
  outputJson
  createdAt
  editorName
}
    `;
export const CdpSyncQuestionStatusForHistoryNodeFieldsFragmentDoc = gql`
    fragment CdpSyncQuestionStatusForHistoryNodeFields on CdpSyncQuestionStatus {
  id
  createdAt
  actorName
  isSuccess
  failureKind
  failureDetails
}
    `;
export const ReportQuestionForHistoryNodeFieldsFragmentDoc = gql`
    fragment ReportQuestionForHistoryNodeFields on ReportQuestion {
  __typename
  ...ReportQuestionFields
  staticInputJson
  resolvedInputs(reportId: $reportId) {
    __typename
    ... on ResolvedReportQuestionInputsFailure {
      errorMessage
      questionIds
    }
    ... on ResolvedReportQuestionInputsSuccess {
      inputs
      unparsedInputs
    }
  }
  resolvedStatus(reportId: $reportId) {
    id
    status
    blockingMessage
    excludingQuestionId
  }
  resolvedOutputJson(reportId: $reportId)
  history(reportId: $reportId) {
    edges {
      node {
        ...ReportAnswerForHistoryNodeFields
      }
    }
  }
  cdpSyncHistory(reportId: $reportId) {
    edges {
      node {
        ...CdpSyncQuestionStatusForHistoryNodeFields
      }
    }
  }
}
    ${ReportQuestionFieldsFragmentDoc}
${ReportAnswerForHistoryNodeFieldsFragmentDoc}
${CdpSyncQuestionStatusForHistoryNodeFieldsFragmentDoc}`;
export const CompanyAutocompleteResultFragmentDoc = gql`
    fragment CompanyAutocompleteResult on Company {
  id
  name
  countryAlpha2
  naicsCode
}
    `;
export const CompanyAutocompleteResultWithSourceFragmentDoc = gql`
    fragment CompanyAutocompleteResultWithSource on Company {
  id
  name
  countryAlpha2
  naicsCode
  source
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const PageInfoWithCountFragmentDoc = gql`
    fragment PageInfoWithCount on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
  totalRowCount
}
    `;
export const ActivityTypeSchemaFragmentDoc = gql`
    fragment ActivityTypeSchema on ActivityTypeSchema {
  id
  activityType
  schema {
    id
    jsonSchema
  }
}
    `;
export const ReviewDocumentParseAttemptFragmentDoc = gql`
    fragment ReviewDocumentParseAttempt on DocumentParseAttempt {
  id
  userUploadId
  modelType
  createdAt
  updatedAt
  utilityUsageData {
    energyConsumptionAmount
    energyConsumptionUnit
    receiverAddress
    serviceAddress
    serviceStartDate
    serviceEndDate
  }
}
    `;
export const TeamsTableRoleFragmentDoc = gql`
    fragment TeamsTableRole on Role {
  id
  name
  description
  permissions {
    id
    ...PermissionFields
  }
  users {
    id
    user {
      id
      displayName
      name
    }
  }
  apiKeys {
    id
    apiKey {
      id
      name
      description
    }
  }
}
    ${PermissionFieldsFragmentDoc}`;
export const UserUploadTaskFieldsFragmentDoc = gql`
    fragment UserUploadTaskFields on UserUploadTask {
  id
  assignee {
    id
    name
  }
  datasource {
    id
    dataset {
      id
      name
    }
  }
  measurementProject {
    id
    name
    active
    coverageStartDate
    coverageEndDate
    completedAt
  }
}
    `;
export const AssignableUserFieldsFragmentDoc = gql`
    fragment AssignableUserFields on User {
  id
  name
  displayName
}
    `;
export const ReportItemUiFragmentDoc = gql`
    fragment ReportItemUI on IReportItem {
  id
  itemNumber
  label
  sublabel
  optional
  optionalMessage
}
    `;
export const ReportFileAttachmentItemFieldsFragmentDoc = gql`
    fragment ReportFileAttachmentItemFields on ReportAttachmentItem {
  authorId
  createdAt
  fmdId
  id
  kind
  name
  orgId
  updatedAt
  url
}
    `;
export const ReportAttachmentForListFragmentDoc = gql`
    fragment ReportAttachmentForList on ReportAttachmentItem {
  id
  name
  kind
  url
  fmdId
  authorId
  createdAt
  updatedAt
}
    `;
export const EngagementTaskConfigForTaskCreationFragmentDoc = gql`
    fragment EngagementTaskConfigForTaskCreation on EngagementTaskConfig {
  id
  name
  orgId
  order
  category
  rootFacingDescription
  defaultSupplierFacingDescription
  crossOrgModelId
  engagementTaskInputs
  type
  optionalEngagementTaskInputs
  timeNeeded
  type
}
    `;
export const AllBusinessActivityTypeFieldFieldsFragmentDoc = gql`
    fragment AllBusinessActivityTypeFieldFields on BusinessActivityTypeField {
  id
  typeId
  versionId
  description
  fieldName
  fieldType
  fieldValueOneOf
  isRequired
  isProratable
  unitFieldName
}
    `;
export const AllBusinessActivityTypeFieldsFragmentDoc = gql`
    fragment AllBusinessActivityTypeFields on BusinessActivityType {
  id
  versionId
  name
  description
  fields {
    ...AllBusinessActivityTypeFieldFields
  }
}
    ${AllBusinessActivityTypeFieldFieldsFragmentDoc}`;
export const PublicDisclosureFieldsFragmentDoc = gql`
    fragment PublicDisclosureFields on PublicDisclosure {
  id
  companyId
  reportType
  publishingYear
  publicUrl
  gsUrl
  qualityScore
  cdpVendorData {
    ...CdpVendorDataFields
  }
}
    ${CdpVendorDataFieldsFragmentDoc}`;
export const CompanyRelationshipFieldsFragmentDoc = gql`
    fragment CompanyRelationshipFields on CompanyRelationship {
  id
  companyA {
    id
    name
  }
  companyB {
    id
    name
  }
  relationshipType
  relationshipStartDate
  relationshipEndDate
}
    `;
export const CompanyFieldsForCcisWithDisclosuresFragmentDoc = gql`
    fragment CompanyFieldsForCCISWithDisclosures on Company {
  ...CompanyFieldsForCCIS
  publicDisclosures: disclosuresV2(
    opts: {publicDisclosureFilters: {}, historicalYearFilters: {allScopes: true}, climateCommitmentFilters: {}}
  ) {
    id
    publicDisclosure {
      ...CompanyPublicDisclosureFields
    }
    historicalEmissionsYears {
      ...CompanyDisclosureHistoricalEmissionsYearFields
    }
    climateCommitments {
      ...CompanyClimateCommitmentFieldsForCCIS
    }
  }
}
    ${CompanyFieldsForCcisFragmentDoc}
${CompanyPublicDisclosureFieldsFragmentDoc}
${CompanyDisclosureHistoricalEmissionsYearFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsForCcisFragmentDoc}`;
export const EngagementTaskAnswerFieldsFragmentDoc = gql`
    fragment EngagementTaskAnswerFields on EngagementTaskAnswer {
  id
  questionKey
  answer
  skipped
  questionIndex
  attachments {
    originalFilename
    signedDownloadUrl
    questionKey
    fileMetadata {
      id
      createdAt
    }
  }
}
    `;
export const CompanyFootprintEstimateOutputFieldsFragmentDoc = gql`
    fragment CompanyFootprintEstimateOutputFields on FootprintEstimateOutput {
  id
  output {
    scope1
    scope2Market
    scope2Location
    scope3
    scope301
    scope302
    scope303
    scope304
    scope305
    scope306
    scope307
    scope308
    scope309
    scope310
    scope311
    scope312
    scope313
    scope314
    scope315
    scope316
    scope317
  }
}
    `;
export const EngagementTaskContentsFragmentDoc = gql`
    fragment EngagementTaskContents on EngagementTask {
  ...EngagementTaskFields
  id
  submittedAnswers {
    ...EngagementTaskAnswerFields
  }
  comments {
    id
    createdAt
    userEditorId
    userEditorName
    creatorOrgId
    comment
  }
  edits {
    id
    createdAt
    userEditorId
    userEditorName
    creatorOrgId
    columnName
    newValue
    valueType
  }
  footprintEstimate {
    ...CompanyFootprintEstimateOutputFields
  }
}
    ${EngagementTaskFieldsFragmentDoc}
${EngagementTaskAnswerFieldsFragmentDoc}
${CompanyFootprintEstimateOutputFieldsFragmentDoc}`;
export const PermissionOriginForFetchOrgUsersForPermissionFragmentDoc = gql`
    fragment PermissionOriginForFetchOrgUsersForPermission on PermissionOrigin {
  id
  permission {
    id
    permission
    object {
      id
      name
    }
  }
  role {
    id
    role {
      id
      name
    }
  }
}
    `;
export const UserForAllOrgUsersForPermissionFragmentDoc = gql`
    fragment UserForAllOrgUsersForPermission on User {
  id
  name
  orgAccessId(orgId: $orgId)
  permissionOrigin(permissions: $permissions, orgId: $orgId) {
    ...PermissionOriginForFetchOrgUsersForPermission
  }
}
    ${PermissionOriginForFetchOrgUsersForPermissionFragmentDoc}`;
export const UserForOrgUsersWithPermissionsFragmentDoc = gql`
    fragment UserForOrgUsersWithPermissions on User {
  id
  name
  permissions(orgId: $orgId) {
    id
    permission
  }
  roles(orgId: $orgId) {
    id
    role {
      id
      permissions {
        id
        permission
      }
    }
  }
}
    `;
export const OverviewReportConfigFieldsFragmentDoc = gql`
    fragment OverviewReportConfigFields on ReportConfig {
  id
  reportType
  shortName
  longName
  description
  brandColor
  status
  isHiddenFromOverview
  isCreationBlocked
  isRegulatoryExposureBlocked
  isCorporateReport
  isFinanceReport
  requiredPermissions
  reportObjectives
}
    `;
export const DeleteMarketplaceDeveloperCacheUpdateDocument = gql`
    query DeleteMarketplaceDeveloperCacheUpdate {
  marketplaceDevelopers {
    edges {
      node {
        id
      }
    }
  }
}
    `;

export function useDeleteMarketplaceDeveloperCacheUpdateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDeleteMarketplaceDeveloperCacheUpdateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDeleteMarketplaceDeveloperCacheUpdateQuery,
    GQTypes.GQDeleteMarketplaceDeveloperCacheUpdateQueryVariables
  >({ query: DeleteMarketplaceDeveloperCacheUpdateDocument, ...options });
}
export const UpdateActivityDataTableDocument = gql`
    mutation UpdateActivityDataTable($input: UpdateActivityDataTableInput!) {
  updateActivityDataTable(input: $input) {
    activityDataTable {
      ...ActivityDataTableFields
    }
  }
}
    ${ActivityDataTableFieldsFragmentDoc}`;

export function useUpdateActivityDataTableMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateActivityDataTableMutation,
    GQTypes.GQUpdateActivityDataTableMutationVariables
  >(UpdateActivityDataTableDocument);
}
export const UnpublishActivityDataTableDocument = gql`
    mutation UnpublishActivityDataTable($input: UnpublishActivityDataTableInput!) {
  unpublishActivityDataTable(input: $input) {
    workflowId
  }
}
    `;

export function useUnpublishActivityDataTableMutation() {
  return Urql.useMutation<
    GQTypes.GQUnpublishActivityDataTableMutation,
    GQTypes.GQUnpublishActivityDataTableMutationVariables
  >(UnpublishActivityDataTableDocument);
}
export const FetchIntegrationDataDocument = gql`
    mutation FetchIntegrationData($input: FetchIntegrationDataInput!) {
  fetchIntegrationData(input: $input) {
    job {
      id
    }
  }
}
    `;

export function useFetchIntegrationDataMutation() {
  return Urql.useMutation<
    GQTypes.GQFetchIntegrationDataMutation,
    GQTypes.GQFetchIntegrationDataMutationVariables
  >(FetchIntegrationDataDocument);
}
export const GetUserUploadedTableSchemaDocument = gql`
    query GetUserUploadedTableSchema($id: ID!) {
  userUploadedTable(id: $id) {
    id
    parseConfig {
      noHeader
      skipTopNRows
      skipBottomNRows
      meltIdVars
    }
    userReviewMetadata {
      columnMappings {
        watershedColumnName
        sourceFileColumnName
      }
    }
    dataPreview {
      schema {
        fields {
          name
          type
        }
      }
      dataUntyped
    }
  }
}
    `;

export function useGetUserUploadedTableSchemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTableSchemaQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTableSchemaQuery,
    GQTypes.GQGetUserUploadedTableSchemaQueryVariables
  >({ query: GetUserUploadedTableSchemaDocument, ...options });
}
export const ActivityDataTableEditorDataDocument = gql`
    query ActivityDataTableEditorData($orgId: ID!, $adtId: ID!) {
  organization(id: $orgId) {
    ...OrganizationForAdtEditor
  }
  enablePublishFileUploadFacilities: featureFlagEnabledForOrg(
    orgId: $orgId
    flag: EnablePublishFileUploadFacilities
  )
  activityDataTable(id: $adtId) {
    ...ActivityDataTableFieldsForEditor
  }
  activityTypeSchemas {
    ...ActivityTypeSchema
  }
}
    ${OrganizationForAdtEditorFragmentDoc}
${ActivityDataTableFieldsForEditorFragmentDoc}
${ActivityTypeSchemaFragmentDoc}`;

export function useActivityDataTableEditorDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDataTableEditorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDataTableEditorDataQuery,
    GQTypes.GQActivityDataTableEditorDataQueryVariables
  >({ query: ActivityDataTableEditorDataDocument, ...options });
}
export const GetUserUploadedTablesByIdDocument = gql`
    query GetUserUploadedTablesById($ids: [ID!]!) {
  userUploadedTablesById(ids: $ids) {
    ...UserUploadedTablesByIdFields
  }
}
    ${UserUploadedTablesByIdFieldsFragmentDoc}`;

export function useGetUserUploadedTablesByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTablesByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTablesByIdQuery,
    GQTypes.GQGetUserUploadedTablesByIdQueryVariables
  >({ query: GetUserUploadedTablesByIdDocument, ...options });
}
export const GetAdesByIdDocument = gql`
    query GetAdesById($ids: [ID!]!) {
  activityDataExtractorsById(ids: $ids) {
    ...ActivityDataExtractorFields
  }
}
    ${ActivityDataExtractorFieldsFragmentDoc}`;

export function useGetAdesByIdQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetAdesByIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetAdesByIdQuery,
    GQTypes.GQGetAdesByIdQueryVariables
  >({ query: GetAdesByIdDocument, ...options });
}
export const GetActivityDataExtractorDocument = gql`
    query GetActivityDataExtractor($adeId: ID!) {
  activityDataExtractor(id: $adeId) {
    ...ActivityDataExtractorFields
  }
}
    ${ActivityDataExtractorFieldsFragmentDoc}`;

export function useGetActivityDataExtractorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetActivityDataExtractorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetActivityDataExtractorQuery,
    GQTypes.GQGetActivityDataExtractorQueryVariables
  >({ query: GetActivityDataExtractorDocument, ...options });
}
export const GetAdtRunsDocument = gql`
    query GetAdtRuns($adtId: ID!, $first: Int, $after: String) {
  activityDataTable(id: $adtId) {
    id
    activityDataExtractors(first: $first, after: $after) {
      edges {
        node {
          ...ActivityDataExtractorFields
        }
      }
    }
  }
}
    ${ActivityDataExtractorFieldsFragmentDoc}`;

export function useGetAdtRunsQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetAdtRunsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetAdtRunsQuery,
    GQTypes.GQGetAdtRunsQueryVariables
  >({ query: GetAdtRunsDocument, ...options });
}
export const RunExtractorDocument = gql`
    mutation RunExtractor($input: RunExtractorInput!) {
  runExtractor(input: $input) {
    run {
      id
    }
  }
}
    `;

export function useRunExtractorMutation() {
  return Urql.useMutation<
    GQTypes.GQRunExtractorMutation,
    GQTypes.GQRunExtractorMutationVariables
  >(RunExtractorDocument);
}
export const PublishExtractorDocument = gql`
    mutation PublishExtractor($input: PublishExtractorInput!) {
  publishExtractor(input: $input) {
    jobId
  }
}
    `;

export function usePublishExtractorMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishExtractorMutation,
    GQTypes.GQPublishExtractorMutationVariables
  >(PublishExtractorDocument);
}
export const LazyBuildingsForAdtEditorDocument = gql`
    query LazyBuildingsForAdtEditor($orgId: ID!) {
  organization(id: $orgId) {
    id
    buildingsUntyped {
      id
      data
    }
  }
}
    `;

export function useLazyBuildingsForAdtEditorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLazyBuildingsForAdtEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLazyBuildingsForAdtEditorQuery,
    GQTypes.GQLazyBuildingsForAdtEditorQueryVariables
  >({ query: LazyBuildingsForAdtEditorDocument, ...options });
}
export const BuildingSurveyStatusForAdtEditorDocument = gql`
    query BuildingSurveyStatusForAdtEditor($adtId: ID!) {
  activityDataTable(id: $adtId) {
    id
    measurementProject {
      id
      buildingSurveyStatus {
        ...BuildingSurveyStatusForAdtEditor
      }
      userUploadTasks {
        edges {
          node {
            id
            datasource {
              id
              name
              dataset {
                id
                canonicalDataset {
                  id
                  kind
                }
              }
            }
            assignee {
              id
              name
            }
            state
          }
        }
      }
    }
  }
}
    ${BuildingSurveyStatusForAdtEditorFragmentDoc}`;

export function useBuildingSurveyStatusForAdtEditorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingSurveyStatusForAdtEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingSurveyStatusForAdtEditorQuery,
    GQTypes.GQBuildingSurveyStatusForAdtEditorQueryVariables
  >({ query: BuildingSurveyStatusForAdtEditorDocument, ...options });
}
export const AddActivityDataTableDialogDocument = gql`
    query AddActivityDataTableDialog($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    measurementProjects {
      ...MeasurementProjectsForMeasurementProjectSelector
    }
  }
  featureFlagEnabledForOrg(orgId: $orgId, flag: MultipleDatasourcesPerAdt)
}
    ${MeasurementProjectsForMeasurementProjectSelectorFragmentDoc}`;

export function useAddActivityDataTableDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAddActivityDataTableDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAddActivityDataTableDialogQuery,
    GQTypes.GQAddActivityDataTableDialogQueryVariables
  >({ query: AddActivityDataTableDialogDocument, ...options });
}
export const CreateActivityDataTableDocument = gql`
    mutation CreateActivityDataTable($input: CreateActivityDataTableInput!) {
  createActivityDataTable(input: $input) {
    table {
      id
      primaryDatasource {
        id
        hasIntegration
      }
    }
    activityDataTables {
      edges {
        node {
          ...ActivityDataTableFields
        }
      }
    }
  }
}
    ${ActivityDataTableFieldsFragmentDoc}`;

export function useCreateActivityDataTableMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateActivityDataTableMutation,
    GQTypes.GQCreateActivityDataTableMutationVariables
  >(CreateActivityDataTableDocument);
}
export const CreateCompanyClimateCommitmentDocument = gql`
    mutation CreateCompanyClimateCommitment($input: CreateCompanyClimateCommitmentInput!) {
  createCompanyClimateCommitment(input: $input) {
    commitment {
      id
    }
  }
}
    `;

export function useCreateCompanyClimateCommitmentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCompanyClimateCommitmentMutation,
    GQTypes.GQCreateCompanyClimateCommitmentMutationVariables
  >(CreateCompanyClimateCommitmentDocument);
}
export const CreateDatasetDocument = gql`
    mutation CreateDataset($input: CreateDatasetInput!) {
  createDataset(input: $input) {
    dataset {
      id
    }
  }
}
    `;

export function useCreateDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDatasetMutation,
    GQTypes.GQCreateDatasetMutationVariables
  >(CreateDatasetDocument);
}
export const UpdateDatasetDocument = gql`
    mutation UpdateDataset($input: UpdateDatasetInput!) {
  updateDataset(input: $input) {
    dataset {
      id
      name
      explainer
      customUsedFor
      customPotentialOverlaps
      canonicalDataset {
        id
        name
      }
      instructions {
        ...InstructionsBundleBaseFields
      }
      customExampleData {
        cells
      }
    }
  }
}
    ${InstructionsBundleBaseFieldsFragmentDoc}`;

export function useUpdateDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDatasetMutation,
    GQTypes.GQUpdateDatasetMutationVariables
  >(UpdateDatasetDocument);
}
export const DeleteDatasetDocument = gql`
    mutation DeleteDataset($input: DeleteDatasetInput!) {
  deleteDataset(input: $input) {
    id
    org {
      id
      datasets {
        id
      }
    }
  }
}
    `;

export function useDeleteDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteDatasetMutation,
    GQTypes.GQDeleteDatasetMutationVariables
  >(DeleteDatasetDocument);
}
export const GetDatasourceDialogInputsDocument = gql`
    query GetDatasourceDialogInputs($orgId: ID!) {
  organization(id: $orgId) {
    id
    datasets {
      id
      name
      canonicalDataset {
        id
        kind
      }
    }
  }
}
    `;

export function useGetDatasourceDialogInputsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDatasourceDialogInputsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDatasourceDialogInputsQuery,
    GQTypes.GQGetDatasourceDialogInputsQueryVariables
  >({ query: GetDatasourceDialogInputsDocument, ...options });
}
export const CreateDatasourceAdminDocument = gql`
    mutation CreateDatasourceAdmin($input: CreateDatasourceAdminInput!) {
  createDatasourceAdmin(input: $input) {
    datasource {
      id
      ...DatasourceForDatasetPage
    }
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${DatasourceForDatasetPageFragmentDoc}
${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useCreateDatasourceAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDatasourceAdminMutation,
    GQTypes.GQCreateDatasourceAdminMutationVariables
  >(CreateDatasourceAdminDocument);
}
export const UpdateDatasourceAdminDocument = gql`
    mutation UpdateDatasourceAdmin($input: UpdateDatasourceAdminInput!) {
  updateDatasourceAdmin(input: $input) {
    datasource {
      id
      ...DatasourceForDatasetPage
    }
  }
}
    ${DatasourceForDatasetPageFragmentDoc}`;

export function useUpdateDatasourceAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDatasourceAdminMutation,
    GQTypes.GQUpdateDatasourceAdminMutationVariables
  >(UpdateDatasourceAdminDocument);
}
export const CreateFootprintPinDocument = gql`
    mutation CreateFootprintPin($input: CreateFootprintVersionPinInput!) {
  createFootprintVersionPin(input: $input) {
    footprintVersion {
      ...FootprintPinForDialog
    }
  }
}
    ${FootprintPinForDialogFragmentDoc}`;

export function useCreateFootprintPinMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintPinMutation,
    GQTypes.GQCreateFootprintPinMutationVariables
  >(CreateFootprintPinDocument);
}
export const UpdateFootprintPinDocument = gql`
    mutation UpdateFootprintPin($input: UpdateFootprintVersionPinInput!) {
  updateFootprintVersionPin(input: $input) {
    footprintVersion {
      ...FootprintPinForDialog
    }
  }
}
    ${FootprintPinForDialogFragmentDoc}`;

export function useUpdateFootprintPinMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFootprintPinMutation,
    GQTypes.GQUpdateFootprintPinMutationVariables
  >(UpdateFootprintPinDocument);
}
export const DeleteFootprintPinDocument = gql`
    mutation DeleteFootprintPin($input: DeleteFootprintVersionPinInput!) {
  deleteFootprintVersionPin(input: $input) {
    footprintVersion {
      ...FootprintPinForDialog
    }
  }
}
    ${FootprintPinForDialogFragmentDoc}`;

export function useDeleteFootprintPinMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFootprintPinMutation,
    GQTypes.GQDeleteFootprintPinMutationVariables
  >(DeleteFootprintPinDocument);
}
export const AddOrgPointOfContactDocument = gql`
    mutation AddOrgPointOfContact($input: AddOrgPointOfContactInput!) {
  addOrgPointOfContact(input: $input) {
    org {
      id
      pointsOfContact {
        id
      }
    }
  }
}
    `;

export function useAddOrgPointOfContactMutation() {
  return Urql.useMutation<
    GQTypes.GQAddOrgPointOfContactMutation,
    GQTypes.GQAddOrgPointOfContactMutationVariables
  >(AddOrgPointOfContactDocument);
}
export const CreateUserAdminVersionDocument = gql`
    mutation CreateUserAdminVersion($input: CreateUserAdminVersionInput!) {
  createUserAdminVersion(input: $input) {
    user {
      ...AssignableUserFields
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useCreateUserAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUserAdminVersionMutation,
    GQTypes.GQCreateUserAdminVersionMutationVariables
  >(CreateUserAdminVersionDocument);
}
export const CreateUserUploadTaskDocument = gql`
    mutation CreateUserUploadTask($input: CreateUserUploadTaskInput!) {
  createUserUploadTask(input: $input) {
    measurementProject {
      id
      userUploadTasks {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    `;

export function useCreateUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUserUploadTaskMutation,
    GQTypes.GQCreateUserUploadTaskMutationVariables
  >(CreateUserUploadTaskDocument);
}
export const AdminNavDocument = gql`
    query AdminNav {
  organizations {
    edges {
      node {
        ...OrgFieldsForAdminNav
      }
    }
  }
}
    ${OrgFieldsForAdminNavFragmentDoc}`;

export function useAdminNavQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQAdminNavQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQAdminNavQuery,
    GQTypes.GQAdminNavQueryVariables
  >({ query: AdminNavDocument, ...options });
}
export const RegenUserSignupTokenAdminVersionDocument = gql`
    mutation RegenUserSignupTokenAdminVersion($orgId: ID!, $userId: ID!) {
  regenerateUserSignupTokenAdminVersion(input: {orgId: $orgId, userId: $userId}) {
    user {
      id
      signupLink(orgId: $orgId)
    }
  }
}
    `;

export function useRegenUserSignupTokenAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQRegenUserSignupTokenAdminVersionMutation,
    GQTypes.GQRegenUserSignupTokenAdminVersionMutationVariables
  >(RegenUserSignupTokenAdminVersionDocument);
}
export const ApplicableDatasourcesDocument = gql`
    query ApplicableDatasources($orgId: ID!, $integrationPartnerId: ID!) {
  applicableDatasources(
    orgId: $orgId
    integrationPartnerId: $integrationPartnerId
  ) {
    id
    name
    hasIntegration
    dataset {
      id
      name
    }
  }
}
    `;

export function useApplicableDatasourcesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQApplicableDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQApplicableDatasourcesQuery,
    GQTypes.GQApplicableDatasourcesQueryVariables
  >({ query: ApplicableDatasourcesDocument, ...options });
}
export const ApplyIntegrationConnectionDocument = gql`
    mutation ApplyIntegrationConnection($input: ApplyIntegrationConnectionInput!) {
  applyIntegrationConnection(input: $input) {
    integrationConnection {
      id
      datasources {
        edges {
          node {
            id
          }
        }
      }
    }
    datasources {
      id
      activeIntegrationConnection {
        id
      }
      hasIntegration
    }
  }
}
    `;

export function useApplyIntegrationConnectionMutation() {
  return Urql.useMutation<
    GQTypes.GQApplyIntegrationConnectionMutation,
    GQTypes.GQApplyIntegrationConnectionMutationVariables
  >(ApplyIntegrationConnectionDocument);
}
export const UpdateActivityDataTableReviewerDocument = gql`
    mutation UpdateActivityDataTableReviewer($input: UpdateActivityDataTableReviewerInput!) {
  updateActivityDataTableReviewer(input: $input) {
    activityDataTable {
      id
      reviews {
        id
        reviewer {
          id
          name
        }
      }
    }
  }
}
    `;

export function useUpdateActivityDataTableReviewerMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateActivityDataTableReviewerMutation,
    GQTypes.GQUpdateActivityDataTableReviewerMutationVariables
  >(UpdateActivityDataTableReviewerDocument);
}
export const GetCanonicalDatasetsWithOrgDocument = gql`
    query GetCanonicalDatasetsWithOrg($orgId: ID!) {
  canonicalDatasets {
    id
    name
    customerTargetSchemas {
      id
      name
      descriptionMd
    }
  }
  organization(id: $orgId) {
    id
    datasets {
      id
      canonicalDataset {
        id
      }
    }
  }
}
    `;

export function useGetCanonicalDatasetsWithOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCanonicalDatasetsWithOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCanonicalDatasetsWithOrgQuery,
    GQTypes.GQGetCanonicalDatasetsWithOrgQueryVariables
  >({ query: GetCanonicalDatasetsWithOrgDocument, ...options });
}
export const GetCanonicalDatasourcesDocument = gql`
    query GetCanonicalDatasources {
  canonicalDatasets {
    id
    name
    customerTargetSchemas {
      id
      name
    }
    canonicalDatasources {
      id
      name
    }
  }
}
    `;

export function useGetCanonicalDatasourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCanonicalDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCanonicalDatasourcesQuery,
    GQTypes.GQGetCanonicalDatasourcesQueryVariables
  >({ query: GetCanonicalDatasourcesDocument, ...options });
}
export const SetFootprintAsWatershedReviewedDocument = gql`
    mutation SetFootprintAsWatershedReviewed($input: SetFootprintAsWatershedReviewedInput!) {
  setFootprintAsWatershedReviewed(input: $input) {
    footprintVersion {
      id
    }
  }
}
    `;

export function useSetFootprintAsWatershedReviewedMutation() {
  return Urql.useMutation<
    GQTypes.GQSetFootprintAsWatershedReviewedMutation,
    GQTypes.GQSetFootprintAsWatershedReviewedMutationVariables
  >(SetFootprintAsWatershedReviewedDocument);
}
export const ConfigureAutoBartDocument = gql`
    mutation ConfigureAutoBart($input: ConfigureAutoBartInput!) {
  configureAutoBart(input: $input) {
    assignedAdt {
      id
      connectedToAutoBart
    }
    unassignedAdt {
      id
      connectedToAutoBart
    }
  }
}
    `;

export function useConfigureAutoBartMutation() {
  return Urql.useMutation<
    GQTypes.GQConfigureAutoBartMutation,
    GQTypes.GQConfigureAutoBartMutationVariables
  >(ConfigureAutoBartDocument);
}
export const CreateFootprintCustomReportDocument = gql`
    mutation CreateFootprintCustomReport($input: CreateFootprintCustomReportInput!) {
  createFootprintCustomReport(input: $input) {
    footprintCustomReport {
      id
    }
  }
}
    `;

export function useCreateFootprintCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintCustomReportMutation,
    GQTypes.GQCreateFootprintCustomReportMutationVariables
  >(CreateFootprintCustomReportDocument);
}
export const DatasetsForDatasourceSelectorDocument = gql`
    query DatasetsForDatasourceSelector($orgId: ID!) {
  organization(id: $orgId) {
    id
    datasets {
      id
      name
      datasources {
        id
        name
        hasIntegration
        userUploadTasks {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
    `;

export function useDatasetsForDatasourceSelectorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasetsForDatasourceSelectorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasetsForDatasourceSelectorQuery,
    GQTypes.GQDatasetsForDatasourceSelectorQueryVariables
  >({ query: DatasetsForDatasourceSelectorDocument, ...options });
}
export const GetIntensityQuantitiesByMonthDocument = gql`
    query GetIntensityQuantitiesByMonth($orgId: ID!, $footprintSnapshotId: ID) {
  intensityQuantitiesByMonth(
    orgId: $orgId
    footprintSnapshotId: $footprintSnapshotId
  ) {
    quantitiesPerMonth {
      intensityKind
      month
      unit
      quantity
    }
  }
}
    `;

export function useGetIntensityQuantitiesByMonthQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetIntensityQuantitiesByMonthQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetIntensityQuantitiesByMonthQuery,
    GQTypes.GQGetIntensityQuantitiesByMonthQueryVariables
  >({ query: GetIntensityQuantitiesByMonthDocument, ...options });
}
export const ShouldShowDuckDocument = gql`
    query ShouldShowDuck($duckName: String!) {
  shouldShowDuck(duckName: $duckName)
}
    `;

export function useShouldShowDuckQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQShouldShowDuckQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQShouldShowDuckQuery,
    GQTypes.GQShouldShowDuckQueryVariables
  >({ query: ShouldShowDuckDocument, ...options });
}
export const FindDuckDocument = gql`
    mutation FindDuck($input: FindDuckInput!) {
  findDuck(input: $input) {
    description
  }
}
    `;

export function useFindDuckMutation() {
  return Urql.useMutation<
    GQTypes.GQFindDuckMutation,
    GQTypes.GQFindDuckMutationVariables
  >(FindDuckDocument);
}
export const EditActivityDataTableDialogDocument = gql`
    query EditActivityDataTableDialog($orgId: ID!) {
  featureFlagEnabledForOrg(orgId: $orgId, flag: MultipleDatasourcesPerAdt)
}
    `;

export function useEditActivityDataTableDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEditActivityDataTableDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEditActivityDataTableDialogQuery,
    GQTypes.GQEditActivityDataTableDialogQueryVariables
  >({ query: EditActivityDataTableDialogDocument, ...options });
}
export const UpdateCompanyClimateCommitmentDocument = gql`
    mutation UpdateCompanyClimateCommitment($input: UpdateCompanyClimateCommitmentInput!) {
  updateCompanyClimateCommitment(input: $input) {
    commitment {
      id
    }
  }
}
    `;

export function useUpdateCompanyClimateCommitmentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCompanyClimateCommitmentMutation,
    GQTypes.GQUpdateCompanyClimateCommitmentMutationVariables
  >(UpdateCompanyClimateCommitmentDocument);
}
export const UpdateFootprintSnapshotDocument = gql`
    mutation UpdateFootprintSnapshot($input: UpdateFootprintSnapshotInput!) {
  updateFootprintSnapshot(input: $input) {
    snapshot {
      id
      descriptionMd
    }
  }
}
    `;

export function useUpdateFootprintSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFootprintSnapshotMutation,
    GQTypes.GQUpdateFootprintSnapshotMutationVariables
  >(UpdateFootprintSnapshotDocument);
}
export const UpdateMeasurementProjectDocument = gql`
    mutation UpdateMeasurementProject($input: UpdateMeasurementProjectInput!) {
  updateMeasurementProject(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useUpdateMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementProjectMutation,
    GQTypes.GQUpdateMeasurementProjectMutationVariables
  >(UpdateMeasurementProjectDocument);
}
export const GetUserUploadedTableDocument = gql`
    query GetUserUploadedTable($id: ID!) {
  userUploadedTable(id: $id) {
    id
    sqlTableName
    status
    errorType
    error
    schema {
      index
      alias
      name
      kind
      forwardFill
    }
    parseConfig {
      noHeader
      skipTopNRows
      skipBottomNRows
      meltIdVars
    }
    userReviewMetadata {
      headerRowIndex
      skipBottomNRows
      columnMappings {
        watershedColumnName
        sourceFileColumnName
      }
      currencyOverride {
        selectedCurrency
      }
      buildingAreaUnitOverride
      stepsCompleted
    }
    userIngestionReview {
      id
      ingestionSchemaId
    }
    isUsingLatestUIR
    dataPreview {
      schema {
        fields {
          name
          type
        }
      }
      dataUntyped
    }
  }
}
    `;

export function useGetUserUploadedTableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTableQuery,
    GQTypes.GQGetUserUploadedTableQueryVariables
  >({ query: GetUserUploadedTableDocument, ...options });
}
export const SetUserUploadedTableSqlTableNameDocument = gql`
    mutation SetUserUploadedTableSqlTableName($input: SetUserUploadedTableSqlTableNameInput!) {
  setUserUploadedTableSqlTableName(input: $input) {
    userUploadedTable {
      id
      sqlTableName
    }
  }
}
    `;

export function useSetUserUploadedTableSqlTableNameMutation() {
  return Urql.useMutation<
    GQTypes.GQSetUserUploadedTableSqlTableNameMutation,
    GQTypes.GQSetUserUploadedTableSqlTableNameMutationVariables
  >(SetUserUploadedTableSqlTableNameDocument);
}
export const MarkUserUploadAcknowledgedDocument = gql`
    mutation MarkUserUploadAcknowledged($input: MarkUserUploadAcknowledgedInput!) {
  markUserUploadAcknowledged(input: $input) {
    userUpload {
      id
      latestAttempt {
        id
        userUploadedTables {
          id
          shouldIgnore
        }
      }
    }
  }
}
    `;

export function useMarkUserUploadAcknowledgedMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkUserUploadAcknowledgedMutation,
    GQTypes.GQMarkUserUploadAcknowledgedMutationVariables
  >(MarkUserUploadAcknowledgedDocument);
}
export const MarkUserUploadedTablesIgnoredDocument = gql`
    mutation MarkUserUploadedTablesIgnored($input: MarkUserUploadedTablesIgnoredInput!) {
  markUserUploadedTablesIgnored(input: $input) {
    userUploadedTables {
      id
      shouldIgnore
    }
  }
}
    `;

export function useMarkUserUploadedTablesIgnoredMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkUserUploadedTablesIgnoredMutation,
    GQTypes.GQMarkUserUploadedTablesIgnoredMutationVariables
  >(MarkUserUploadedTablesIgnoredDocument);
}
export const SetUserUploadedTableNotIgnoredDocument = gql`
    mutation SetUserUploadedTableNotIgnored($userUploadedTableId: ID!) {
  setUserUploadedTableNotIgnored(
    input: {userUploadedTableId: $userUploadedTableId}
  ) {
    userUploadedTable {
      id
      shouldIgnore
    }
  }
}
    `;

export function useSetUserUploadedTableNotIgnoredMutation() {
  return Urql.useMutation<
    GQTypes.GQSetUserUploadedTableNotIgnoredMutation,
    GQTypes.GQSetUserUploadedTableNotIgnoredMutationVariables
  >(SetUserUploadedTableNotIgnoredDocument);
}
export const GetCleanPowerQueryPlaygroundLinkDocument = gql`
    query GetCleanPowerQueryPlaygroundLink($orgId: ID!, $pipelineRunConfig: String) {
  cleanPowerQueryPlaygroundLink(
    orgId: $orgId
    pipelineRunConfig: $pipelineRunConfig
  )
}
    `;

export function useGetCleanPowerQueryPlaygroundLinkQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCleanPowerQueryPlaygroundLinkQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCleanPowerQueryPlaygroundLinkQuery,
    GQTypes.GQGetCleanPowerQueryPlaygroundLinkQueryVariables
  >({ query: GetCleanPowerQueryPlaygroundLinkDocument, ...options });
}
export const SetFootprintVersionUserVisibleDocument = gql`
    mutation SetFootprintVersionUserVisible($input: SetFootprintVersionUserVisibleInput!) {
  setFootprintVersionUserVisible(input: $input) {
    footprintVersion {
      id
      isUserVisible
    }
  }
}
    `;

export function useSetFootprintVersionUserVisibleMutation() {
  return Urql.useMutation<
    GQTypes.GQSetFootprintVersionUserVisibleMutation,
    GQTypes.GQSetFootprintVersionUserVisibleMutationVariables
  >(SetFootprintVersionUserVisibleDocument);
}
export const FootprintVersionsDocument = gql`
    query FootprintVersions($orgId: ID!, $after: String, $before: String, $first: Int, $last: Int, $isUserVisible: Boolean) {
  footprintVersions(
    orgId: $orgId
    isUserVisible: $isUserVisible
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        orgId
        createdAt
        kind
        originalFootprintSnapshot {
          id
        }
        versionName
        versionIdentifier
        internalNotesMd
        userChangelogMd
        publishableUserChangelogMd
        isUserChangelogHidden
        isUserVisible
        canUserPublish
        pinInfo {
          id
          name
          description
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

export function useFootprintVersionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintVersionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintVersionsQuery,
    GQTypes.GQFootprintVersionsQueryVariables
  >({ query: FootprintVersionsDocument, ...options });
}
export const LoginAsUserDocument = gql`
    mutation LoginAsUser($input: LoginAsUserInput!) {
  loginAsUser(input: $input) {
    token
    isSuccessful
  }
}
    `;

export function useLoginAsUserMutation() {
  return Urql.useMutation<
    GQTypes.GQLoginAsUserMutation,
    GQTypes.GQLoginAsUserMutationVariables
  >(LoginAsUserDocument);
}
export const AddSupplierPointOfContactDialog_DataDocument = gql`
    query AddSupplierPointOfContactDialog_Data {
  marketplacePointsOfContact {
    edges {
      node {
        ...AddSupplierPointOfContactDialog_MarketplacePointOfContact
      }
    }
  }
}
    ${AddSupplierPointOfContactDialog_MarketplacePointOfContactFragmentDoc}`;

export function useAddSupplierPointOfContactDialog_DataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAddSupplierPointOfContactDialog_DataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAddSupplierPointOfContactDialog_DataQuery,
    GQTypes.GQAddSupplierPointOfContactDialog_DataQueryVariables
  >({ query: AddSupplierPointOfContactDialog_DataDocument, ...options });
}
export const AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionDocument = gql`
    mutation AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnection($input: AddMarketplaceSupplierPointOfContactConnectionInput!) {
  addMarketplaceSupplierPointOfContactConnection(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionMutation() {
  return Urql.useMutation<
    GQTypes.GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionMutation,
    GQTypes.GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionMutationVariables
  >(
    AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionDocument
  );
}
export const AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactDocument = gql`
    mutation AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContact($input: AddMarketplaceSupplierPointOfContactInput!) {
  addMarketplaceSupplierPointOfContact(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactMutation() {
  return Urql.useMutation<
    GQTypes.GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactMutation,
    GQTypes.GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactMutationVariables
  >(
    AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactDocument
  );
}
export const AddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeDocument = gql`
    mutation AddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployee($input: AddMarketplaceSupplierWatershedEmployeeInput!) {
  addMarketplaceSupplierWatershedEmployee(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useAddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeMutation() {
  return Urql.useMutation<
    GQTypes.GQAddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeMutation,
    GQTypes.GQAddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeMutationVariables
  >(
    AddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeDocument
  );
}
export const CreateEacMarketplaceProjectsDocument = gql`
    mutation CreateEacMarketplaceProjects($input: CreateEacMarketplaceProjectsInput!) {
  createEacMarketplaceProjects(input: $input) {
    affectedMarketplaceProjectIds
  }
}
    `;

export function useCreateEacMarketplaceProjectsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEacMarketplaceProjectsMutation,
    GQTypes.GQCreateEacMarketplaceProjectsMutationVariables
  >(CreateEacMarketplaceProjectsDocument);
}
export const MarketplaceArchetypesAndSuppliersDocument = gql`
    query MarketplaceArchetypesAndSuppliers {
  marketplaceProjectArchetypes {
    edges {
      node {
        id
        name
      }
    }
  }
  marketplaceSuppliers {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useMarketplaceArchetypesAndSuppliersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceArchetypesAndSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceArchetypesAndSuppliersQuery,
    GQTypes.GQMarketplaceArchetypesAndSuppliersQueryVariables
  >({ query: MarketplaceArchetypesAndSuppliersDocument, ...options });
}
export const CreateMarketplaceProjectDocument = gql`
    mutation CreateMarketplaceProject($input: CreateMarketplaceProjectInput!) {
  createMarketplaceProject(input: $input) {
    marketplaceProject {
      ...MarketplaceProjectFields
      supplier {
        id
        projects {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
    ${MarketplaceProjectFieldsFragmentDoc}`;

export function useCreateMarketplaceProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceProjectMutation,
    GQTypes.GQCreateMarketplaceProjectMutationVariables
  >(CreateMarketplaceProjectDocument);
}
export const UpdateMarketplaceProjectDocument = gql`
    mutation UpdateMarketplaceProject($input: UpdateMarketplaceProjectInput!) {
  updateMarketplaceProject(input: $input) {
    marketplaceProject {
      ...MarketplaceProjectFields
    }
  }
}
    ${MarketplaceProjectFieldsFragmentDoc}`;

export function useUpdateMarketplaceProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceProjectMutation,
    GQTypes.GQUpdateMarketplaceProjectMutationVariables
  >(UpdateMarketplaceProjectDocument);
}
export const MarketplaceArchetypesAndDevelopersAndSuppliersDocument = gql`
    query MarketplaceArchetypesAndDevelopersAndSuppliers {
  marketplaceProjectArchetypes {
    edges {
      node {
        ...MarketplaceProjectArchetypeListFields
      }
    }
  }
  marketplaceDevelopers {
    edges {
      node {
        id
        name
      }
    }
  }
  marketplaceSuppliers {
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${MarketplaceProjectArchetypeListFieldsFragmentDoc}`;

export function useMarketplaceArchetypesAndDevelopersAndSuppliersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceArchetypesAndDevelopersAndSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceArchetypesAndDevelopersAndSuppliersQuery,
    GQTypes.GQMarketplaceArchetypesAndDevelopersAndSuppliersQueryVariables
  >({
    query: MarketplaceArchetypesAndDevelopersAndSuppliersDocument,
    ...options,
  });
}
export const DeleteMarketplaceAllocationInstructionDocument = gql`
    mutation DeleteMarketplaceAllocationInstruction($input: DeleteMarketplaceAllocationInstructionInput!) {
  deleteMarketplaceAllocationInstruction(input: $input) {
    allocationInstruction {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useDeleteMarketplaceAllocationInstructionMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplaceAllocationInstructionMutation,
    GQTypes.GQDeleteMarketplaceAllocationInstructionMutationVariables
  >(DeleteMarketplaceAllocationInstructionDocument);
}
export const DeleteAllMarketplaceAllocationInstructionsDocument = gql`
    mutation DeleteAllMarketplaceAllocationInstructions($input: DeleteAllMarketplaceAllocationInstructionsInput!) {
  deleteAllMarketplaceAllocationInstructions(input: $input) {
    allocationInstructions {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useDeleteAllMarketplaceAllocationInstructionsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAllMarketplaceAllocationInstructionsMutation,
    GQTypes.GQDeleteAllMarketplaceAllocationInstructionsMutationVariables
  >(DeleteAllMarketplaceAllocationInstructionsDocument);
}
export const BulkImportMarketplaceAllocationInstructionsDocument = gql`
    mutation BulkImportMarketplaceAllocationInstructions($input: BulkImportMarketplaceAllocationInstructionsInput!) {
  bulkImportMarketplaceAllocationInstructions(input: $input) {
    allocationInstructions {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useBulkImportMarketplaceAllocationInstructionsMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkImportMarketplaceAllocationInstructionsMutation,
    GQTypes.GQBulkImportMarketplaceAllocationInstructionsMutationVariables
  >(BulkImportMarketplaceAllocationInstructionsDocument);
}
export const CreateMarketplaceProjectArchetypeDocument = gql`
    mutation CreateMarketplaceProjectArchetype($input: CreateMarketplaceProjectArchetypeInput!) {
  createMarketplaceProjectArchetype(input: $input) {
    marketplaceProjectArchetype {
      ...MarketplaceProjectArchetypeFields
    }
  }
}
    ${MarketplaceProjectArchetypeFieldsFragmentDoc}`;

export function useCreateMarketplaceProjectArchetypeMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceProjectArchetypeMutation,
    GQTypes.GQCreateMarketplaceProjectArchetypeMutationVariables
  >(CreateMarketplaceProjectArchetypeDocument);
}
export const UpdateMarketplaceProjectArchetypeDocument = gql`
    mutation UpdateMarketplaceProjectArchetype($input: UpdateMarketplaceProjectArchetypeInput!) {
  updateMarketplaceProjectArchetype(input: $input) {
    marketplaceProjectArchetype {
      ...MarketplaceProjectArchetypeFields
    }
  }
}
    ${MarketplaceProjectArchetypeFieldsFragmentDoc}`;

export function useUpdateMarketplaceProjectArchetypeMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceProjectArchetypeMutation,
    GQTypes.GQUpdateMarketplaceProjectArchetypeMutationVariables
  >(UpdateMarketplaceProjectArchetypeDocument);
}
export const CreateMarketplaceAllocationInstructionDocument = gql`
    mutation CreateMarketplaceAllocationInstruction($input: CreateMarketplaceAllocationInstructionInput!) {
  createMarketplaceAllocationInstruction(input: $input) {
    allocationInstruction {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useCreateMarketplaceAllocationInstructionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceAllocationInstructionMutation,
    GQTypes.GQCreateMarketplaceAllocationInstructionMutationVariables
  >(CreateMarketplaceAllocationInstructionDocument);
}
export const EditMarketplaceAllocationInstructionDocument = gql`
    mutation EditMarketplaceAllocationInstruction($input: UpdateMarketplaceAllocationInstructionInput!) {
  updateMarketplaceAllocationInstruction(input: $input) {
    allocationInstruction {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useEditMarketplaceAllocationInstructionMutation() {
  return Urql.useMutation<
    GQTypes.GQEditMarketplaceAllocationInstructionMutation,
    GQTypes.GQEditMarketplaceAllocationInstructionMutationVariables
  >(EditMarketplaceAllocationInstructionDocument);
}
export const UploadMarketplaceDocumentsDocument = gql`
    mutation UploadMarketplaceDocuments($input: UploadMarketplaceDocumentsInput!) {
  uploadMarketplaceDocuments(input: $input) {
    documents {
      ...MarketplaceDocumentFieldsForTableCard
    }
  }
}
    ${MarketplaceDocumentFieldsForTableCardFragmentDoc}`;

export function useUploadMarketplaceDocumentsMutation() {
  return Urql.useMutation<
    GQTypes.GQUploadMarketplaceDocumentsMutation,
    GQTypes.GQUploadMarketplaceDocumentsMutationVariables
  >(UploadMarketplaceDocumentsDocument);
}
export const CreateMarketplaceProjectOfferingDocument = gql`
    mutation CreateMarketplaceProjectOffering($input: CreateMarketplaceProjectOfferingInput!) {
  createMarketplaceProjectOffering(input: $input) {
    marketplaceProjectOffering {
      ...MarketplaceProjectOfferingFields
    }
    marketplaceProject {
      ...MarketplaceProjectFields
    }
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
    addedVintageOfferings {
      ...MarketplaceProjectOfferingFields
    }
  }
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}
${MarketplaceProjectFieldsFragmentDoc}
${MarketplaceSupplierFieldsFragmentDoc}`;

export function useCreateMarketplaceProjectOfferingMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceProjectOfferingMutation,
    GQTypes.GQCreateMarketplaceProjectOfferingMutationVariables
  >(CreateMarketplaceProjectOfferingDocument);
}
export const UpdateMarketplacePurchaseLineItemDocument = gql`
    mutation UpdateMarketplacePurchaseLineItem($input: UpdateMarketplacePurchaseLineItemInput!) {
  updateMarketplacePurchaseLineItem(input: $input) {
    marketplacePurchaseLineItem {
      ...MarketplacePurchaseLineItemFields
    }
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
  }
}
    ${MarketplacePurchaseLineItemFieldsFragmentDoc}
${MarketplacePurchaseFieldsFragmentDoc}`;

export function useUpdateMarketplacePurchaseLineItemMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplacePurchaseLineItemMutation,
    GQTypes.GQUpdateMarketplacePurchaseLineItemMutationVariables
  >(UpdateMarketplacePurchaseLineItemDocument);
}
export const CreateMarketplacePurchaseLineItemDocument = gql`
    mutation CreateMarketplacePurchaseLineItem($input: CreateMarketplacePurchaseLineItemInput!) {
  createMarketplacePurchaseLineItem(input: $input) {
    marketplacePurchaseLineItem {
      ...MarketplacePurchaseLineItemFields
    }
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
  }
}
    ${MarketplacePurchaseLineItemFieldsFragmentDoc}
${MarketplacePurchaseFieldsFragmentDoc}`;

export function useCreateMarketplacePurchaseLineItemMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplacePurchaseLineItemMutation,
    GQTypes.GQCreateMarketplacePurchaseLineItemMutationVariables
  >(CreateMarketplacePurchaseLineItemDocument);
}
export const DeleteMarketplacePurchaseLineItemDocument = gql`
    mutation DeleteMarketplacePurchaseLineItem($input: DeleteMarketplacePurchaseLineItemInput!) {
  deleteMarketplacePurchaseLineItem(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
  }
}
    ${MarketplacePurchaseFieldsFragmentDoc}`;

export function useDeleteMarketplacePurchaseLineItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplacePurchaseLineItemMutation,
    GQTypes.GQDeleteMarketplacePurchaseLineItemMutationVariables
  >(DeleteMarketplacePurchaseLineItemDocument);
}
export const MarketplacePurchaseLineItemFormDataDocument = gql`
    query MarketplacePurchaseLineItemFormData($orgId: ID!) {
  marketplaceProjectOfferings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
  marketplaceProjectArchetypes {
    edges {
      node {
        ...MarketplaceProjectArchetypesForLineItemDialog
      }
    }
  }
  featureFlagEnabledForOrg(orgId: $orgId, flag: CsrdAvoidedCleanPower)
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}
${MarketplaceProjectArchetypesForLineItemDialogFragmentDoc}`;

export function useMarketplacePurchaseLineItemFormDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplacePurchaseLineItemFormDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplacePurchaseLineItemFormDataQuery,
    GQTypes.GQMarketplacePurchaseLineItemFormDataQueryVariables
  >({ query: MarketplacePurchaseLineItemFormDataDocument, ...options });
}
export const BulkUpdateMarketplacePurchaseLineItemsDocument = gql`
    mutation BulkUpdateMarketplacePurchaseLineItems($input: BulkUpdateMarketplacePurchaseLineItemsInput!) {
  bulkUpdateMarketplacePurchaseLineItems(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
    createdLineItems {
      ...MarketplacePurchaseLineItemFields
    }
    deletedLineItems {
      ...MarketplacePurchaseLineItemFields
    }
    updatedLineItems {
      ...MarketplacePurchaseLineItemFields
    }
  }
}
    ${MarketplacePurchaseFieldsFragmentDoc}
${MarketplacePurchaseLineItemFieldsFragmentDoc}`;

export function useBulkUpdateMarketplacePurchaseLineItemsMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkUpdateMarketplacePurchaseLineItemsMutation,
    GQTypes.GQBulkUpdateMarketplacePurchaseLineItemsMutationVariables
  >(BulkUpdateMarketplacePurchaseLineItemsDocument);
}
export const CreateMarketplaceSupplierDocument = gql`
    mutation CreateMarketplaceSupplier($input: CreateMarketplaceSupplierInput!) {
  createMarketplaceSupplier(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useCreateMarketplaceSupplierMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceSupplierMutation,
    GQTypes.GQCreateMarketplaceSupplierMutationVariables
  >(CreateMarketplaceSupplierDocument);
}
export const UpdateMarketplaceSupplierDocument = gql`
    mutation UpdateMarketplaceSupplier($input: UpdateMarketplaceSupplierInput!) {
  updateMarketplaceSupplier(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useUpdateMarketplaceSupplierMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceSupplierMutation,
    GQTypes.GQUpdateMarketplaceSupplierMutationVariables
  >(UpdateMarketplaceSupplierDocument);
}
export const CompleteMeasurementProjectDocument = gql`
    mutation CompleteMeasurementProject($input: CompleteMeasurementProjectInput!) {
  completeMeasurementProject(input: $input) {
    measurementProject {
      id
      active
    }
  }
}
    `;

export function useCompleteMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCompleteMeasurementProjectMutation,
    GQTypes.GQCompleteMeasurementProjectMutationVariables
  >(CompleteMeasurementProjectDocument);
}
export const UncompleteMeasurementProjectDocument = gql`
    mutation UncompleteMeasurementProject($input: UncompleteMeasurementProjectInput!) {
  uncompleteMeasurementProject(input: $input) {
    measurementProject {
      id
      active
    }
  }
}
    `;

export function useUncompleteMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUncompleteMeasurementProjectMutation,
    GQTypes.GQUncompleteMeasurementProjectMutationVariables
  >(UncompleteMeasurementProjectDocument);
}
export const UpdateUserUploadTaskDocument = gql`
    mutation UpdateUserUploadTask($input: UpdateUserUploadTaskInput!) {
  updateUserUploadTask(input: $input) {
    userUploadTask {
      id
      state
      datasource {
        id
      }
    }
  }
}
    `;

export function useUpdateUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserUploadTaskMutation,
    GQTypes.GQUpdateUserUploadTaskMutationVariables
  >(UpdateUserUploadTaskDocument);
}
export const DeleteUserUploadTaskDocument = gql`
    mutation DeleteUserUploadTask($input: DeleteUserUploadTaskInput!) {
  deleteUserUploadTask(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useDeleteUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteUserUploadTaskMutation,
    GQTypes.GQDeleteUserUploadTaskMutationVariables
  >(DeleteUserUploadTaskDocument);
}
export const MarkMeasurementProjectDatasetCompletedDocument = gql`
    mutation MarkMeasurementProjectDatasetCompleted($input: MarkMeasurementProjectDatasetCompletedInput!) {
  markMeasurementProjectDatasetCompleted(input: $input) {
    measurementProjectDataset {
      ...MeasurementProjectDatasetFieldsForOrgPage
    }
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectDatasetFieldsForOrgPageFragmentDoc}
${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useMarkMeasurementProjectDatasetCompletedMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkMeasurementProjectDatasetCompletedMutation,
    GQTypes.GQMarkMeasurementProjectDatasetCompletedMutationVariables
  >(MarkMeasurementProjectDatasetCompletedDocument);
}
export const CreateFacilitiesSetupTaskDocument = gql`
    mutation CreateFacilitiesSetupTask($input: CreateFacilitiesSetupTaskInput!) {
  createFacilitiesSetupTask(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useCreateFacilitiesSetupTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFacilitiesSetupTaskMutation,
    GQTypes.GQCreateFacilitiesSetupTaskMutationVariables
  >(CreateFacilitiesSetupTaskDocument);
}
export const DeleteFacilitiesSetupTaskDocument = gql`
    mutation DeleteFacilitiesSetupTask($input: DeleteFacilitiesSetupTaskInput!) {
  deleteFacilitiesSetupTask(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useDeleteFacilitiesSetupTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFacilitiesSetupTaskMutation,
    GQTypes.GQDeleteFacilitiesSetupTaskMutationVariables
  >(DeleteFacilitiesSetupTaskDocument);
}
export const DeleteMeasurementProjectDocument = gql`
    mutation DeleteMeasurementProject($input: DeleteMeasurementProjectInput!) {
  deleteMeasurementProject(input: $input) {
    org {
      id
      measurementProjects {
        ...MeasurementProjectFieldsForOrgPage
      }
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useDeleteMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementProjectMutation,
    GQTypes.GQDeleteMeasurementProjectMutationVariables
  >(DeleteMeasurementProjectDocument);
}
export const CreateMeasurementVerificationItemDocument = gql`
    mutation CreateMeasurementVerificationItem($input: CreateMeasurementVerificationItemInput!) {
  createMeasurementVerificationItem(input: $input) {
    measurementVerificationItem {
      id
      measurementProject {
        ...MeasurementProjectFieldsForMeasurementVerificationCard
      }
    }
  }
}
    ${MeasurementProjectFieldsForMeasurementVerificationCardFragmentDoc}`;

export function useCreateMeasurementVerificationItemMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementVerificationItemMutation,
    GQTypes.GQCreateMeasurementVerificationItemMutationVariables
  >(CreateMeasurementVerificationItemDocument);
}
export const DeleteMeasurementVerificationItemForAdminDocument = gql`
    mutation DeleteMeasurementVerificationItemForAdmin($input: DeleteMeasurementVerificationItemInput!) {
  deleteMeasurementVerificationItem(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForMeasurementVerificationCard
    }
  }
}
    ${MeasurementProjectFieldsForMeasurementVerificationCardFragmentDoc}`;

export function useDeleteMeasurementVerificationItemForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementVerificationItemForAdminMutation,
    GQTypes.GQDeleteMeasurementVerificationItemForAdminMutationVariables
  >(DeleteMeasurementVerificationItemForAdminDocument);
}
export const GenerateProjectVerificationItemsForAdminDocument = gql`
    mutation GenerateProjectVerificationItemsForAdmin($input: GenerateMeasurementVerificationItemsInput!) {
  generateMeasurementVerificationItems(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForMeasurementVerificationCard
    }
  }
}
    ${MeasurementProjectFieldsForMeasurementVerificationCardFragmentDoc}`;

export function useGenerateProjectVerificationItemsForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateProjectVerificationItemsForAdminMutation,
    GQTypes.GQGenerateProjectVerificationItemsForAdminMutationVariables
  >(GenerateProjectVerificationItemsForAdminDocument);
}
export const PublishMeasurementVerificationItemsForAdminDocument = gql`
    mutation PublishMeasurementVerificationItemsForAdmin($input: PublishMeasurementVerificationItemsInput!) {
  publishMeasurementVerificationItems(input: $input) {
    updatedQuestions {
      id
      status
    }
  }
}
    `;

export function usePublishMeasurementVerificationItemsForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishMeasurementVerificationItemsForAdminMutation,
    GQTypes.GQPublishMeasurementVerificationItemsForAdminMutationVariables
  >(PublishMeasurementVerificationItemsForAdminDocument);
}
export const PreviewMeasurementVerificationItemsForAdminDocument = gql`
    mutation PreviewMeasurementVerificationItemsForAdmin($input: PreviewMeasurementVerificationItemEmailsInput!) {
  previewMeasurementVerificationItemEmails(input: $input) {
    previewEmails {
      ...EmailContentWithUserRecipientFields
    }
  }
}
    ${EmailContentWithUserRecipientFieldsFragmentDoc}`;

export function usePreviewMeasurementVerificationItemsForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQPreviewMeasurementVerificationItemsForAdminMutation,
    GQTypes.GQPreviewMeasurementVerificationItemsForAdminMutationVariables
  >(PreviewMeasurementVerificationItemsForAdminDocument);
}
export const UpdateAdminMeasurementVerificationItemDocument = gql`
    mutation UpdateAdminMeasurementVerificationItem($input: UpdateAdminMeasurementVerificationItemInput!) {
  updateAdminMeasurementVerificationItem(input: $input) {
    measurementVerificationItem {
      id
      description
    }
  }
}
    `;

export function useUpdateAdminMeasurementVerificationItemMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAdminMeasurementVerificationItemMutation,
    GQTypes.GQUpdateAdminMeasurementVerificationItemMutationVariables
  >(UpdateAdminMeasurementVerificationItemDocument);
}
export const OrgSpecificEmissionsModelsDocument = gql`
    query OrgSpecificEmissionsModels($orgId: ID!) {
  emissionsModelsForOrg(orgId: $orgId) {
    id
    title
    description
    isArchived
    kind
    scope
    matchers {
      id
      businessActivityType
    }
    latestPublishedVersion {
      id
      createdAt
      changelog
      title
      author {
        id
        displayName
      }
    }
  }
}
    `;

export function useOrgSpecificEmissionsModelsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgSpecificEmissionsModelsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgSpecificEmissionsModelsQuery,
    GQTypes.GQOrgSpecificEmissionsModelsQueryVariables
  >({ query: OrgSpecificEmissionsModelsDocument, ...options });
}
export const OrgSpecificReferenceDataDocument = gql`
    query OrgSpecificReferenceData($orgId: ID!) {
  latestReferenceDataRevisionsForOrg(orgId: $orgId) {
    id
    createdAt
    archivedAt
    state
    organization {
      id
      name
    }
    source {
      id
      name
    }
    version {
      id
      versionName
    }
    author {
      id
      name
    }
  }
}
    `;

export function useOrgSpecificReferenceDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgSpecificReferenceDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgSpecificReferenceDataQuery,
    GQTypes.GQOrgSpecificReferenceDataQueryVariables
  >({ query: OrgSpecificReferenceDataDocument, ...options });
}
export const OrganizationFilesDocument = gql`
    query OrganizationFiles($orgId: ID!, $fileFilters: FileMetadataFilterInput) {
  organization(id: $orgId) {
    id
    measurementProjects {
      id
      name
      userUploadTasks {
        edges {
          node {
            id
            datasource {
              id
              name
              dataset {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  files(orgId: $orgId, filters: $fileFilters) {
    edges {
      node {
        ...FileMetadataFieldsForOrgFilesDialog
      }
    }
  }
}
    ${FileMetadataFieldsForOrgFilesDialogFragmentDoc}`;

export function useOrganizationFilesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFilesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFilesQuery,
    GQTypes.GQOrganizationFilesQueryVariables
  >({ query: OrganizationFilesDocument, ...options });
}
export const AdtsForRunPipelineDialogDocument = gql`
    query AdtsForRunPipelineDialog($orgId: ID!) {
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...AdtFieldsForRunPipelineDialog
      }
    }
  }
  shouldAllowPipelineResultFootprintKind: featureFlagEnabledForOrg(
    orgId: $orgId
    flag: ShouldAllowPipelineResultFootprintKind
  )
}
    ${AdtFieldsForRunPipelineDialogFragmentDoc}`;

export function useAdtsForRunPipelineDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAdtsForRunPipelineDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAdtsForRunPipelineDialogQuery,
    GQTypes.GQAdtsForRunPipelineDialogQueryVariables
  >({ query: AdtsForRunPipelineDialogDocument, ...options });
}
export const GetPipelineRunsForCreateFootprintDialogDocument = gql`
    query GetPipelineRunsForCreateFootprintDialog($orgId: ID!) {
  pipelineRuns(orgId: $orgId) {
    edges {
      node {
        id
        name
        createdAt
        pipelineResults {
          ...PipelineResultForCreateFootprintSnapshotDialog
        }
        ...PipelineRunForCreateFootprintDialog
      }
    }
  }
  footprintsForOrg(orgId: $orgId) {
    edges {
      node {
        ...FootprintForCreateFootprintSnapshotDialog
      }
    }
  }
  isMultipleFootprintsEnabled: featureFlagEnabledForOrg(
    orgId: $orgId
    flag: EnableMultipleFootprints
  )
}
    ${PipelineResultForCreateFootprintSnapshotDialogFragmentDoc}
${PipelineRunForCreateFootprintDialogFragmentDoc}
${FootprintForCreateFootprintSnapshotDialogFragmentDoc}`;

export function useGetPipelineRunsForCreateFootprintDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPipelineRunsForCreateFootprintDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPipelineRunsForCreateFootprintDialogQuery,
    GQTypes.GQGetPipelineRunsForCreateFootprintDialogQueryVariables
  >({ query: GetPipelineRunsForCreateFootprintDialogDocument, ...options });
}
export const CreateFootprintSnapshotDocument = gql`
    mutation CreateFootprintSnapshot($input: CreateFootprintSnapshotInput!) {
  createFootprintSnapshot(input: $input) {
    job {
      id
      error {
        message
        details
      }
    }
  }
}
    `;

export function useCreateFootprintSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintSnapshotMutation,
    GQTypes.GQCreateFootprintSnapshotMutationVariables
  >(CreateFootprintSnapshotDocument);
}
export const GetPipelineResultsByIdDocument = gql`
    query GetPipelineResultsById($orgId: ID!, $pipelineResultIds: [ID!]!) {
  pipelineResults(orgId: $orgId, pipelineResultIds: $pipelineResultIds) {
    edges {
      node {
        ...PipelineResultForOrg
      }
    }
  }
}
    ${PipelineResultForOrgFragmentDoc}`;

export function useGetPipelineResultsByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPipelineResultsByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPipelineResultsByIdQuery,
    GQTypes.GQGetPipelineResultsByIdQueryVariables
  >({ query: GetPipelineResultsByIdDocument, ...options });
}
export const GetAdtsFromAdeIdsDocument = gql`
    query GetAdtsFromAdeIds($ids: [ID!]!) {
  activityDataExtractorsById(ids: $ids) {
    id
    activityDataTable {
      id
      name
    }
  }
}
    `;

export function useGetAdtsFromAdeIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAdtsFromAdeIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAdtsFromAdeIdsQuery,
    GQTypes.GQGetAdtsFromAdeIdsQueryVariables
  >({ query: GetAdtsFromAdeIdsDocument, ...options });
}
export const GetFootprintTagDetailsForOrgDocument = gql`
    query GetFootprintTagDetailsForOrg($orgId: ID!) {
  footprintTagsForOrg(orgId: $orgId) {
    id
    tagName
    description
    watershedEditor {
      id
      name
      email
    }
  }
}
    `;

export function useGetFootprintTagDetailsForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintTagDetailsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintTagDetailsForOrgQuery,
    GQTypes.GQGetFootprintTagDetailsForOrgQueryVariables
  >({ query: GetFootprintTagDetailsForOrgDocument, ...options });
}
export const GetFootprintExclusionAndCategoryRulesForOrgDocument = gql`
    query GetFootprintExclusionAndCategoryRulesForOrg($orgId: ID!) {
  organization(id: $orgId) {
    id
    footprintExclusionRules {
      id
    }
    footprintCategoryRules {
      id
    }
  }
}
    `;

export function useGetFootprintExclusionAndCategoryRulesForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintExclusionAndCategoryRulesForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintExclusionAndCategoryRulesForOrgQuery,
    GQTypes.GQGetFootprintExclusionAndCategoryRulesForOrgQueryVariables
  >({ query: GetFootprintExclusionAndCategoryRulesForOrgDocument, ...options });
}
export const GetMarketplaceAllocationsDocument = gql`
    query GetMarketplaceAllocations($orgId: ID!) {
  marketplaceAllocationInstructions(orgId: $orgId) {
    edges {
      node {
        id
        amountKwh
        locationCity
        locationGrid
        locationState
        locationCountry
        buildingName
        vendorEntity
        vendorSubentity
        categoryId
        ghgCategoryId
        footprintKind
        subcategoryId
        interval
        purchaseLineItem {
          id
          purchase {
            id
          }
        }
      }
    }
  }
}
    `;

export function useGetMarketplaceAllocationsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMarketplaceAllocationsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMarketplaceAllocationsQuery,
    GQTypes.GQGetMarketplaceAllocationsQueryVariables
  >({ query: GetMarketplaceAllocationsDocument, ...options });
}
export const EmissionsModelPipelineDialogDocument = gql`
    query EmissionsModelPipelineDialog($orgId: ID!) {
  availableReleases: emissionsModelReleases(orgId: $orgId) {
    ...EmissionsModelReleaseMetadata
  }
  measurementTestSuites(includeDeleted: false, criticalOnly: true) {
    ...MeasurementTestSuiteLiteListFields
  }
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...AdtFieldsForRunPipelineDialog
      }
    }
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}
${MeasurementTestSuiteLiteListFieldsFragmentDoc}
${AdtFieldsForRunPipelineDialogFragmentDoc}`;

export function useEmissionsModelPipelineDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelPipelineDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelPipelineDialogQuery,
    GQTypes.GQEmissionsModelPipelineDialogQueryVariables
  >({ query: EmissionsModelPipelineDialogDocument, ...options });
}
export const RefreshCarbonRemovalActivityDataTableDocument = gql`
    mutation RefreshCarbonRemovalActivityDataTable($input: RefreshCarbonRemovalActivityDataTableInput!) {
  refreshCarbonRemovalActivityDataTable(input: $input) {
    jobId
  }
}
    `;

export function useRefreshCarbonRemovalActivityDataTableMutation() {
  return Urql.useMutation<
    GQTypes.GQRefreshCarbonRemovalActivityDataTableMutation,
    GQTypes.GQRefreshCarbonRemovalActivityDataTableMutationVariables
  >(RefreshCarbonRemovalActivityDataTableDocument);
}
export const GetFootprintSnapshotDetailsDocument = gql`
    query GetFootprintSnapshotDetails($snapshotId: ID!) {
  footprintSnapshot(id: $snapshotId) {
    ...GetFootprintSnapshotDetailsSnapshot
  }
}
    ${GetFootprintSnapshotDetailsSnapshotFragmentDoc}`;

export function useGetFootprintSnapshotDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintSnapshotDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintSnapshotDetailsQuery,
    GQTypes.GQGetFootprintSnapshotDetailsQueryVariables
  >({ query: GetFootprintSnapshotDetailsDocument, ...options });
}
export const SaveFootprintSnapshotDocument = gql`
    mutation SaveFootprintSnapshot($id: ID!) {
  setFootprintSnapshotIsManuallySaved(input: {id: $id, isManuallySaved: true}) {
    snapshot {
      id
      isManuallySaved
    }
  }
}
    `;

export function useSaveFootprintSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveFootprintSnapshotMutation,
    GQTypes.GQSaveFootprintSnapshotMutationVariables
  >(SaveFootprintSnapshotDocument);
}
export const UnsaveFootprintSnapshotDocument = gql`
    mutation UnsaveFootprintSnapshot($id: ID!) {
  setFootprintSnapshotIsManuallySaved(input: {id: $id, isManuallySaved: false}) {
    snapshot {
      id
      isManuallySaved
    }
  }
}
    `;

export function useUnsaveFootprintSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQUnsaveFootprintSnapshotMutation,
    GQTypes.GQUnsaveFootprintSnapshotMutationVariables
  >(UnsaveFootprintSnapshotDocument);
}
export const ArchiveFootprintSnapshotDocument = gql`
    mutation ArchiveFootprintSnapshot($id: ID!) {
  archiveFootprintSnapshot(input: {id: $id}) {
    snapshot {
      id
      orgId
    }
  }
}
    `;

export function useArchiveFootprintSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveFootprintSnapshotMutation,
    GQTypes.GQArchiveFootprintSnapshotMutationVariables
  >(ArchiveFootprintSnapshotDocument);
}
export const FootprintSnapshotsDocument = gql`
    query FootprintSnapshots($orgId: ID!, $isAutoGenerated: Boolean, $after: String, $before: String, $first: Int, $last: Int) {
  footprintSnapshots(
    orgId: $orgId
    isAutoGenerated: $isAutoGenerated
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...FootprintSnapshotOrgFootprintsFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${FootprintSnapshotOrgFootprintsFieldsFragmentDoc}`;

export function useFootprintSnapshotsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintSnapshotsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintSnapshotsQuery,
    GQTypes.GQFootprintSnapshotsQueryVariables
  >({ query: FootprintSnapshotsDocument, ...options });
}
export const MultipleFootprintsEnabledForOrgDocument = gql`
    query MultipleFootprintsEnabledForOrg($orgId: ID!) {
  featureFlagEnabledForOrg(orgId: $orgId, flag: EnableMultipleFootprints)
}
    `;

export function useMultipleFootprintsEnabledForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMultipleFootprintsEnabledForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMultipleFootprintsEnabledForOrgQuery,
    GQTypes.GQMultipleFootprintsEnabledForOrgQueryVariables
  >({ query: MultipleFootprintsEnabledForOrgDocument, ...options });
}
export const GetFootprintTagsForOrgDocument = gql`
    query GetFootprintTagsForOrg($orgId: ID!) {
  footprintTagsForOrg(orgId: $orgId) {
    id
    createdAt
    tagName
    description
    rulesList
    watershedEditor {
      id
      name
      email
    }
  }
}
    `;

export function useGetFootprintTagsForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintTagsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintTagsForOrgQuery,
    GQTypes.GQGetFootprintTagsForOrgQueryVariables
  >({ query: GetFootprintTagsForOrgDocument, ...options });
}
export const DeleteFootprintTagDocument = gql`
    mutation DeleteFootprintTag($input: DeleteFootprintTagInput!) {
  deleteFootprintTag(input: $input) {
    footprintTag {
      id
    }
  }
}
    `;

export function useDeleteFootprintTagMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFootprintTagMutation,
    GQTypes.GQDeleteFootprintTagMutationVariables
  >(DeleteFootprintTagDocument);
}
export const OrganizationInboxDocument = gql`
    query OrganizationInbox($orgId: ID!) {
  organization(id: $orgId) {
    __typename
    id
    name
    measurementProjects(active: true) {
      id
    }
  }
  inboxItemsForOrg(orgId: $orgId) {
    edges {
      node {
        ...InboxItemFields
      }
    }
  }
}
    ${InboxItemFieldsFragmentDoc}`;

export function useOrganizationInboxQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationInboxQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationInboxQuery,
    GQTypes.GQOrganizationInboxQueryVariables
  >({ query: OrganizationInboxDocument, ...options });
}
export const OrgResetDialogDocument = gql`
    query OrgResetDialog($orgId: ID!) {
  employeeReport(orgId: $orgId) {
    id
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        company {
          id
          name
        }
      }
    }
  }
}
    `;

export function useOrgResetDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgResetDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgResetDialogQuery,
    GQTypes.GQOrgResetDialogQueryVariables
  >({ query: OrgResetDialogDocument, ...options });
}
export const DeleteOrgPointOfContactDocument = gql`
    mutation DeleteOrgPointOfContact($input: DeleteOrgPointOfContactInput!) {
  deleteOrgPointOfContact(input: $input) {
    org {
      id
      pointsOfContact {
        id
      }
    }
  }
}
    `;

export function useDeleteOrgPointOfContactMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgPointOfContactMutation,
    GQTypes.GQDeleteOrgPointOfContactMutationVariables
  >(DeleteOrgPointOfContactDocument);
}
export const GetOrganizationPointOfContactDocument = gql`
    query GetOrganizationPointOfContact($orgId: ID!, $pointOfContactKinds: [OrgPointOfContactKind!]) {
  organization(id: $orgId) {
    id
    name
    pointsOfContact(kinds: $pointOfContactKinds) {
      id
      watershedEmployee {
        id
        name
      }
    }
  }
}
    `;

export function useGetOrganizationPointOfContactQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationPointOfContactQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationPointOfContactQuery,
    GQTypes.GQGetOrganizationPointOfContactQueryVariables
  >({ query: GetOrganizationPointOfContactDocument, ...options });
}
export const OrganizationSelectorDataDocument = gql`
    query OrganizationSelectorData {
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
      }
    }
  }
}
    `;

export function useOrganizationSelectorDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationSelectorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationSelectorDataQuery,
    GQTypes.GQOrganizationSelectorDataQueryVariables
  >({ query: OrganizationSelectorDataDocument, ...options });
}
export const OrganizationAllUsersDocument = gql`
    query OrganizationAllUsers($orgId: ID!) {
  organization(id: $orgId) {
    id
    users(filters: {includeWatershedEmployees: true}) {
      edges {
        node {
          id
          name
          email
        }
      }
    }
    pointsOfContact {
      id
      watershedEmployee {
        id
        name
        email
      }
    }
  }
}
    `;

export function useOrganizationAllUsersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationAllUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationAllUsersQuery,
    GQTypes.GQOrganizationAllUsersQueryVariables
  >({ query: OrganizationAllUsersDocument, ...options });
}
export const ArchivePipelineResultDocument = gql`
    mutation ArchivePipelineResult($id: ID!) {
  archivePipelineResult(input: {id: $id}) {
    pipelineResult {
      id
      orgId
    }
  }
}
    `;

export function useArchivePipelineResultMutation() {
  return Urql.useMutation<
    GQTypes.GQArchivePipelineResultMutation,
    GQTypes.GQArchivePipelineResultMutationVariables
  >(ArchivePipelineResultDocument);
}
export const UpdatePipelineResultDocument = gql`
    mutation UpdatePipelineResult($input: UpdatePipelineResultInput!) {
  updatePipelineResult(input: $input) {
    pipelineResult {
      id
      name
    }
  }
}
    `;

export function useUpdatePipelineResultMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePipelineResultMutation,
    GQTypes.GQUpdatePipelineResultMutationVariables
  >(UpdatePipelineResultDocument);
}
export const GetAdeInfoByIdDocument = gql`
    query GetAdeInfoById($ids: [ID!]!) {
  activityDataExtractorsById(ids: $ids) {
    id
    name
  }
}
    `;

export function useGetAdeInfoByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAdeInfoByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAdeInfoByIdQuery,
    GQTypes.GQGetAdeInfoByIdQueryVariables
  >({ query: GetAdeInfoByIdDocument, ...options });
}
export const PreviewDataIssueEmailsDocument = gql`
    mutation PreviewDataIssueEmails($input: PreviewDataIssueEmailsInput!) {
  previewDataIssueEmails(input: $input) {
    sampleEmails {
      ...EmailContentWithUserRecipientFields
    }
    numberOfEmails
  }
}
    ${EmailContentWithUserRecipientFieldsFragmentDoc}`;

export function usePreviewDataIssueEmailsMutation() {
  return Urql.useMutation<
    GQTypes.GQPreviewDataIssueEmailsMutation,
    GQTypes.GQPreviewDataIssueEmailsMutationVariables
  >(PreviewDataIssueEmailsDocument);
}
export const PublishDataIssuesDocument = gql`
    mutation PublishDataIssues($input: PublishDataIssuesInput!) {
  publishDataIssues(input: $input) {
    issues {
      id
      state
      isPublished
    }
    comments {
      id
      isPublished
    }
  }
}
    `;

export function usePublishDataIssuesMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishDataIssuesMutation,
    GQTypes.GQPublishDataIssuesMutationVariables
  >(PublishDataIssuesDocument);
}
export const GetAddUploadToAdtDataDocument = gql`
    query GetAddUploadToAdtData($orgId: ID!) {
  organization(id: $orgId) {
    id
    datasets {
      ...DatasetFieldsForOrgPage
    }
  }
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...ActivityDataTableFields
      }
    }
  }
}
    ${DatasetFieldsForOrgPageFragmentDoc}
${ActivityDataTableFieldsFragmentDoc}`;

export function useGetAddUploadToAdtDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAddUploadToAdtDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAddUploadToAdtDataQuery,
    GQTypes.GQGetAddUploadToAdtDataQueryVariables
  >({ query: GetAddUploadToAdtDataDocument, ...options });
}
export const GetUserUploadedTableDataPreviewDocument = gql`
    query GetUserUploadedTableDataPreview($ids: [ID!]!) {
  userUploadedTablesById(ids: $ids) {
    ...UserUploadedTableDataPreviewFields
  }
}
    ${UserUploadedTableDataPreviewFieldsFragmentDoc}`;

export function useGetUserUploadedTableDataPreviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTableDataPreviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTableDataPreviewQuery,
    GQTypes.GQGetUserUploadedTableDataPreviewQueryVariables
  >({ query: GetUserUploadedTableDataPreviewDocument, ...options });
}
export const GetDetailsForFootprintSnapshotsDocument = gql`
    query GetDetailsForFootprintSnapshots($snapshotIds: [ID!]!, $orgId: ID!) {
  footprintSnapshots(orgId: $orgId, footprintSnapshotIds: $snapshotIds) {
    edges {
      node {
        id
        summary {
          emissions {
            month
            businessCategory
            pipeline
            kgCo2e
            footprintKind
          }
        }
        userUploadedTableIds
      }
    }
  }
  userUploads(orgId: $orgId) {
    edges {
      node {
        id
        latestAttempt {
          id
          userUploadedTables {
            id
            shouldIgnore
          }
        }
      }
    }
  }
  organization(id: $orgId) {
    id
    measurementProjects {
      id
      name
      active
      coverageInterval
    }
  }
}
    `;

export function useGetDetailsForFootprintSnapshotsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDetailsForFootprintSnapshotsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDetailsForFootprintSnapshotsQuery,
    GQTypes.GQGetDetailsForFootprintSnapshotsQueryVariables
  >({ query: GetDetailsForFootprintSnapshotsDocument, ...options });
}
export const RunPlaygroundSqlDocument = gql`
    mutation RunPlaygroundSql($input: RunPlaygroundSqlInput!) {
  runPlaygroundSql(input: $input) {
    run {
      id
    }
  }
}
    `;

export function useRunPlaygroundSqlMutation() {
  return Urql.useMutation<
    GQTypes.GQRunPlaygroundSqlMutation,
    GQTypes.GQRunPlaygroundSqlMutationVariables
  >(RunPlaygroundSqlDocument);
}
export const GetPlaygroundSqlRunsDocument = gql`
    query GetPlaygroundSqlRuns($orgId: ID!, $userId: ID, $first: Int, $after: String) {
  playgroundSqlRuns(orgId: $orgId, userId: $userId, first: $first, after: $after) {
    edges {
      node {
        ...PlaygroundSqlRunForPlayground
      }
    }
  }
}
    ${PlaygroundSqlRunForPlaygroundFragmentDoc}`;

export function useGetPlaygroundSqlRunsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPlaygroundSqlRunsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPlaygroundSqlRunsQuery,
    GQTypes.GQGetPlaygroundSqlRunsQueryVariables
  >({ query: GetPlaygroundSqlRunsDocument, ...options });
}
export const GetPlaygroundSqlDocument = gql`
    query GetPlaygroundSql($id: ID!) {
  playgroundSql(id: $id) {
    id
    orgId
    sql
  }
}
    `;

export function useGetPlaygroundSqlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPlaygroundSqlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPlaygroundSqlQuery,
    GQTypes.GQGetPlaygroundSqlQueryVariables
  >({ query: GetPlaygroundSqlDocument, ...options });
}
export const GetNonOrgSpecificQuickSwitcherDataDocument = gql`
    query GetNonOrgSpecificQuickSwitcherData {
  emissionsModelsStable(showArchived: false) {
    id
    title
  }
  referenceDataSources(filter: All, excludeArchived: true, last: 1000) {
    edges {
      node {
        name
        id
        orgId
      }
    }
  }
  measurementTestSuites(includeDeleted: false, criticalOnly: false) {
    id
    title
  }
  reportConfigs {
    edges {
      node {
        id
        reportType
        shortName
        longName
        description
      }
    }
  }
  emissionsModelReleases {
    id
    businessActivityTypeName
    displayName
  }
}
    `;

export function useGetNonOrgSpecificQuickSwitcherDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetNonOrgSpecificQuickSwitcherDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetNonOrgSpecificQuickSwitcherDataQuery,
    GQTypes.GQGetNonOrgSpecificQuickSwitcherDataQueryVariables
  >({ query: GetNonOrgSpecificQuickSwitcherDataDocument, ...options });
}
export const GetOrgSpecificQuickSwitcherDataDocument = gql`
    query GetOrgSpecificQuickSwitcherData($orgId: ID!) {
  footprintTagsForOrg(orgId: $orgId) {
    id
    tagName
  }
  activityDataTables(orgId: $orgId, last: 1000) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useGetOrgSpecificQuickSwitcherDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrgSpecificQuickSwitcherDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrgSpecificQuickSwitcherDataQuery,
    GQTypes.GQGetOrgSpecificQuickSwitcherDataQueryVariables
  >({ query: GetOrgSpecificQuickSwitcherDataDocument, ...options });
}
export const GetPublicDisclosureIDsDocument = gql`
    query GetPublicDisclosureIDs {
  publicDisclosureIDs
}
    `;

export function useGetPublicDisclosureIDsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPublicDisclosureIDsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPublicDisclosureIDsQuery,
    GQTypes.GQGetPublicDisclosureIDsQueryVariables
  >({ query: GetPublicDisclosureIDsDocument, ...options });
}
export const GetPrivateDisclosureIDsDocument = gql`
    query GetPrivateDisclosureIDs($orgId: ID!) {
  privateDisclosureIDs(orgId: $orgId)
}
    `;

export function useGetPrivateDisclosureIDsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPrivateDisclosureIDsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPrivateDisclosureIDsQuery,
    GQTypes.GQGetPrivateDisclosureIDsQueryVariables
  >({ query: GetPrivateDisclosureIDsDocument, ...options });
}
export const CreateReferenceDataCitationDocument = gql`
    mutation CreateReferenceDataCitation($input: CreateReferenceDataCitationInput!) {
  createReferenceDataCitation(input: $input) {
    referenceDataCitation {
      ...ReferenceDataCitationFields
    }
  }
}
    ${ReferenceDataCitationFieldsFragmentDoc}`;

export function useCreateReferenceDataCitationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReferenceDataCitationMutation,
    GQTypes.GQCreateReferenceDataCitationMutationVariables
  >(CreateReferenceDataCitationDocument);
}
export const UpdateReferenceDataCitationDocument = gql`
    mutation UpdateReferenceDataCitation($input: UpdateReferenceDataCitationInput!) {
  updateReferenceDataCitation(input: $input) {
    referenceDataCitation {
      ...ReferenceDataCitationFields
    }
  }
}
    ${ReferenceDataCitationFieldsFragmentDoc}`;

export function useUpdateReferenceDataCitationMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataCitationMutation,
    GQTypes.GQUpdateReferenceDataCitationMutationVariables
  >(UpdateReferenceDataCitationDocument);
}
export const ReferenceDataCitationsDocument = gql`
    query ReferenceDataCitations($after: String, $before: String, $first: Int, $last: Int, $orgId: ID, $searchString: String, $excludeArchived: Boolean, $includeSlugWithName: String) {
  referenceDataCitations(
    after: $after
    before: $before
    first: $first
    last: $last
    orgId: $orgId
    searchString: $searchString
    excludeArchived: $excludeArchived
    includeSlugWithName: $includeSlugWithName
  ) {
    edges {
      node {
        ...ReferenceDataCitationFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${ReferenceDataCitationFieldsFragmentDoc}`;

export function useReferenceDataCitationsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataCitationsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataCitationsQuery,
    GQTypes.GQReferenceDataCitationsQueryVariables
  >({ query: ReferenceDataCitationsDocument, ...options });
}
export const ReferenceDataCitationDocument = gql`
    query ReferenceDataCitation($id: ID!) {
  referenceDataCitation(id: $id) {
    ...ReferenceDataCitationFields
  }
}
    ${ReferenceDataCitationFieldsFragmentDoc}`;

export function useReferenceDataCitationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataCitationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataCitationQuery,
    GQTypes.GQReferenceDataCitationQueryVariables
  >({ query: ReferenceDataCitationDocument, ...options });
}
export const ReferenceDataCitationSlugsDocument = gql`
    query ReferenceDataCitationSlugs {
  referenceDataCitationSlugs
}
    `;

export function useReferenceDataCitationSlugsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataCitationSlugsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataCitationSlugsQuery,
    GQTypes.GQReferenceDataCitationSlugsQueryVariables
  >({ query: ReferenceDataCitationSlugsDocument, ...options });
}
export const ReferenceDataVersionSchemaDocument = gql`
    query ReferenceDataVersionSchema($referenceDataVersionId: ID!) {
  referenceDataVersion(referenceDataVersionId: $referenceDataVersionId) {
    id
    versionName
    latestSchema {
      id
      schemaJson
    }
  }
}
    `;

export function useReferenceDataVersionSchemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataVersionSchemaQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataVersionSchemaQuery,
    GQTypes.GQReferenceDataVersionSchemaQueryVariables
  >({ query: ReferenceDataVersionSchemaDocument, ...options });
}
export const LatestReferenceDataRevisionForCompositeDataEditorDocument = gql`
    query latestReferenceDataRevisionForCompositeDataEditor($referenceDataVersionId: ID!, $orgId: ID) {
  latestReferenceDataRevisionForVersion(
    referenceDataVersionId: $referenceDataVersionId
    orgId: $orgId
    anyOrg: false
  ) {
    id
    createdAt
    kind
  }
}
    `;

export function useLatestReferenceDataRevisionForCompositeDataEditorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestReferenceDataRevisionForCompositeDataEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestReferenceDataRevisionForCompositeDataEditorQuery,
    GQTypes.GQLatestReferenceDataRevisionForCompositeDataEditorQueryVariables
  >({
    query: LatestReferenceDataRevisionForCompositeDataEditorDocument,
    ...options,
  });
}
export const CompositeDataVersionEditorDataDocument = gql`
    query CompositeDataVersionEditorData($compositeDataVersionId: ID!, $orgId: ID, $fetchOrgDetails: Boolean!) {
  compositeDataVersion(compositeDataVersionId: $compositeDataVersionId) {
    ...ReferenceDataVersionSchema
    versionName
    source {
      id
      name
      orgId
    }
    referenceDataSqlRuns(orgId: $orgId) {
      id
      createdAt
      status
      sql
      error
      warnings
      queryResultFileId
      revisionId
      signedParquetOutputUrl
      signedParquetRawResultUrl
      revisionState
      revisionLastUpdatedDate
    }
  }
  organization(id: $orgId) @include(if: $fetchOrgDetails) {
    id
    name
  }
}
    ${ReferenceDataVersionSchemaFragmentDoc}`;

export function useCompositeDataVersionEditorDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompositeDataVersionEditorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompositeDataVersionEditorDataQuery,
    GQTypes.GQCompositeDataVersionEditorDataQueryVariables
  >({ query: CompositeDataVersionEditorDataDocument, ...options });
}
export const ReferenceDataOverlayByRevisionIdDocument = gql`
    query ReferenceDataOverlayByRevisionId($referenceDataRevision: ID!) {
  referenceDataOverlayByRevisionId(referenceDataRevision: $referenceDataRevision) {
    id
  }
}
    `;

export function useReferenceDataOverlayByRevisionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataOverlayByRevisionIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataOverlayByRevisionIdQuery,
    GQTypes.GQReferenceDataOverlayByRevisionIdQueryVariables
  >({ query: ReferenceDataOverlayByRevisionIdDocument, ...options });
}
export const ReferenceDataVersionsBySlugsIfExistsDocument = gql`
    query referenceDataVersionsBySlugsIfExists($slugs: [String!]!) {
  referenceDataVersionsBySlugsIfExists(slugs: $slugs) {
    slug
    referenceDataVersion {
      id
      latestSchema {
        id
        schemaJson
      }
      latestSignedParquetOutputUrl
    }
  }
}
    `;

export function useReferenceDataVersionsBySlugsIfExistsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataVersionsBySlugsIfExistsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataVersionsBySlugsIfExistsQuery,
    GQTypes.GQReferenceDataVersionsBySlugsIfExistsQueryVariables
  >({ query: ReferenceDataVersionsBySlugsIfExistsDocument, ...options });
}
export const ReferenceDataDifferDocument = gql`
    query ReferenceDataDiffer($sourceRevisionId: ID!, $targetRevisionId: ID!) {
  referenceDataDiffer(
    sourceRevisionId: $sourceRevisionId
    targetRevisionId: $targetRevisionId
  ) {
    fileMetadata {
      id
      signedParquetUrl
    }
    sourceReferenceDataRevision {
      id
      revisionName
      state
      createdAt
      organization {
        id
        name
      }
      version {
        id
        slug
        sourceId
      }
    }
    targetReferenceDataRevision {
      id
      revisionName
      state
      createdAt
      organization {
        id
        name
      }
      version {
        id
        slug
        sourceId
      }
    }
  }
}
    `;

export function useReferenceDataDifferQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataDifferQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataDifferQuery,
    GQTypes.GQReferenceDataDifferQueryVariables
  >({ query: ReferenceDataDifferDocument, ...options });
}
export const ReferenceDataRevisionsForSourceByVersionDocument = gql`
    query ReferenceDataRevisionsForSourceByVersion($sourceId: ID!, $excludeArchived: Boolean!, $includeDrafts: Boolean!) {
  referenceDataSource(referenceDataSourceId: $sourceId) {
    id
    referenceDataVersions(
      excludeArchived: $excludeArchived
      includeDrafts: $includeDrafts
    ) {
      id
      versionName
      referenceDataRevisions(
        excludeArchived: $excludeArchived
        includeDrafts: $includeDrafts
      ) {
        id
        revisionName
        createdAt
        state
      }
    }
  }
}
    `;

export function useReferenceDataRevisionsForSourceByVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataRevisionsForSourceByVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataRevisionsForSourceByVersionQuery,
    GQTypes.GQReferenceDataRevisionsForSourceByVersionQueryVariables
  >({ query: ReferenceDataRevisionsForSourceByVersionDocument, ...options });
}
export const UpdateReferenceDataRevisionDocument = gql`
    mutation UpdateReferenceDataRevision($input: UpdateReferenceDataRevisionInput!) {
  updateReferenceDataRevision(input: $input) {
    referenceDataRevision {
      id
      revisionName
      internalSourceNotes
    }
  }
}
    `;

export function useUpdateReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataRevisionMutation,
    GQTypes.GQUpdateReferenceDataRevisionMutationVariables
  >(UpdateReferenceDataRevisionDocument);
}
export const LatestPublishedRevisionByRevisionIdOrNullDocument = gql`
    query LatestPublishedRevisionByRevisionIdOrNull($revisionId: ID!) {
  latestPublishedRevisionForRevisionIdOrNull(revisionId: $revisionId) {
    id
  }
}
    `;

export function useLatestPublishedRevisionByRevisionIdOrNullQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestPublishedRevisionByRevisionIdOrNullQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestPublishedRevisionByRevisionIdOrNullQuery,
    GQTypes.GQLatestPublishedRevisionByRevisionIdOrNullQueryVariables
  >({ query: LatestPublishedRevisionByRevisionIdOrNullDocument, ...options });
}
export const CreateReferenceDataSourceDocument = gql`
    mutation CreateReferenceDataSource($input: CreateReferenceDataSourceInput!) {
  createReferenceDataSource(input: $input) {
    referenceDataSource {
      id
    }
  }
}
    `;

export function useCreateReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReferenceDataSourceMutation,
    GQTypes.GQCreateReferenceDataSourceMutationVariables
  >(CreateReferenceDataSourceDocument);
}
export const ArchiveReferenceDataSourceDocument = gql`
    mutation ArchiveReferenceDataSource($input: ArchiveReferenceDataSourceInput!) {
  archiveReferenceDataSource(input: $input) {
    referenceDataSource {
      ...ReferenceDataSourceSchema
    }
  }
}
    ${ReferenceDataSourceSchemaFragmentDoc}`;

export function useArchiveReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveReferenceDataSourceMutation,
    GQTypes.GQArchiveReferenceDataSourceMutationVariables
  >(ArchiveReferenceDataSourceDocument);
}
export const UpdateReferenceDataSourceDocument = gql`
    mutation UpdateReferenceDataSource($input: UpdateReferenceDataSourceInput!) {
  updateReferenceDataSource(input: $input) {
    referenceDataSource {
      id
      name
      isSyncedWithCliq
    }
  }
}
    `;

export function useUpdateReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataSourceMutation,
    GQTypes.GQUpdateReferenceDataSourceMutationVariables
  >(UpdateReferenceDataSourceDocument);
}
export const ToggleCliqSyncForReferenceDataSourceDocument = gql`
    mutation ToggleCliqSyncForReferenceDataSource($input: ToggleCliqSyncForReferenceDataSourceInput!) {
  toggleCliqSyncForReferenceDataSource(input: $input) {
    cliqReferenceDataConnection {
      id
      isActive
    }
  }
}
    `;

export function useToggleCliqSyncForReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQToggleCliqSyncForReferenceDataSourceMutation,
    GQTypes.GQToggleCliqSyncForReferenceDataSourceMutationVariables
  >(ToggleCliqSyncForReferenceDataSourceDocument);
}
export const ReferenceDataSourcesDocument = gql`
    query ReferenceDataSources($after: String, $before: String, $first: Int, $last: Int, $search: String, $filters: [String!], $excludeArchived: Boolean) {
  referenceDataSources(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    filter: All
    tagIdsFilter: $filters
    excludeArchived: $excludeArchived
  ) {
    edges {
      node {
        ...ReferenceDataSourceSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${ReferenceDataSourceSchemaFragmentDoc}`;

export function useReferenceDataSourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourcesQuery,
    GQTypes.GQReferenceDataSourcesQueryVariables
  >({ query: ReferenceDataSourcesDocument, ...options });
}
export const AddCalculationTagToReferenceDataSourceDocument = gql`
    mutation AddCalculationTagToReferenceDataSource($input: AddCalculationTagToReferenceDataInput!) {
  addCalculationTagToReferenceData(input: $input) {
    success
  }
}
    `;

export function useAddCalculationTagToReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQAddCalculationTagToReferenceDataSourceMutation,
    GQTypes.GQAddCalculationTagToReferenceDataSourceMutationVariables
  >(AddCalculationTagToReferenceDataSourceDocument);
}
export const RemoveCalculationTagFromReferenceDataSourceDocument = gql`
    mutation RemoveCalculationTagFromReferenceDataSource($input: RemoveCalculationTagFromReferenceDataInput!) {
  removeCalculationTagFromReferenceData(input: $input) {
    success
  }
}
    `;

export function useRemoveCalculationTagFromReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQRemoveCalculationTagFromReferenceDataSourceMutation,
    GQTypes.GQRemoveCalculationTagFromReferenceDataSourceMutationVariables
  >(RemoveCalculationTagFromReferenceDataSourceDocument);
}
export const CreateCalculationTagDocument = gql`
    mutation CreateCalculationTag($input: CreateCalculationTagInput!) {
  createCalculationTag(input: $input) {
    tag {
      id
      name
    }
  }
}
    `;

export function useCreateCalculationTagMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCalculationTagMutation,
    GQTypes.GQCreateCalculationTagMutationVariables
  >(CreateCalculationTagDocument);
}
export const ReferenceDataSqlForRevisionIdDocument = gql`
    query ReferenceDataSqlForRevisionId($revisionId: ID!) {
  referenceDataSqlForRevisionId(revisionId: $revisionId) {
    id
    sql
    queryResultFileId
  }
}
    `;

export function useReferenceDataSqlForRevisionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSqlForRevisionIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSqlForRevisionIdQuery,
    GQTypes.GQReferenceDataSqlForRevisionIdQueryVariables
  >({ query: ReferenceDataSqlForRevisionIdDocument, ...options });
}
export const CreateReferenceDataVersionDocument = gql`
    mutation CreateReferenceDataVersion($input: CreateReferenceDataVersionInput!) {
  createReferenceDataVersion(input: $input) {
    referenceDataVersion {
      id
      versionName
    }
  }
}
    `;

export function useCreateReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReferenceDataVersionMutation,
    GQTypes.GQCreateReferenceDataVersionMutationVariables
  >(CreateReferenceDataVersionDocument);
}
export const ReferenceDataVersionNamesForSourceDocument = gql`
    query ReferenceDataVersionNamesForSource($sourceId: ID!) {
  referenceDataSource(referenceDataSourceId: $sourceId) {
    id
    referenceDataVersions {
      id
      versionName
    }
  }
}
    `;

export function useReferenceDataVersionNamesForSourceQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataVersionNamesForSourceQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataVersionNamesForSourceQuery,
    GQTypes.GQReferenceDataVersionNamesForSourceQueryVariables
  >({ query: ReferenceDataVersionNamesForSourceDocument, ...options });
}
export const DependentEmissionsModelsForRdvDocument = gql`
    query DependentEmissionsModelsForRdv($referenceDataVersionId: ID!) {
  dependentEmissionsModelsForRdv(referenceDataVersionId: $referenceDataVersionId) {
    stableId
    stableName
    isArchived
    latestPublishedEmvId
    latestPublishedEmvUsesRdv
    latestPublishedEmvChangelog
    latestPublishedEmvCreatedAt
  }
}
    `;

export function useDependentEmissionsModelsForRdvQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDependentEmissionsModelsForRdvQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDependentEmissionsModelsForRdvQuery,
    GQTypes.GQDependentEmissionsModelsForRdvQueryVariables
  >({ query: DependentEmissionsModelsForRdvDocument, ...options });
}
export const DependentEmissionsModelReleasesForRdvDocument = gql`
    query DependentEmissionsModelReleasesForRdv($referenceDataVersionId: ID!) {
  dependentEmissionsModelReleasesForRdv(
    referenceDataVersionId: $referenceDataVersionId
  ) {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useDependentEmissionsModelReleasesForRdvQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDependentEmissionsModelReleasesForRdvQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDependentEmissionsModelReleasesForRdvQuery,
    GQTypes.GQDependentEmissionsModelReleasesForRdvQueryVariables
  >({ query: DependentEmissionsModelReleasesForRdvDocument, ...options });
}
export const DependentReferenceDataVersionsForRdvDocument = gql`
    query DependentReferenceDataVersionsForRdv($referenceDataVersionId: ID!) {
  dependentReferenceDataVersionsForRdv(
    referenceDataVersionId: $referenceDataVersionId
  ) {
    rdvId
    rdvName
    rdsId
    rdsName
    rdvCreatedAt
    rdvUpdatedAt
    usingOutdatedRevision
    rdvIsLatestPublishedVersion
  }
}
    `;

export function useDependentReferenceDataVersionsForRdvQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDependentReferenceDataVersionsForRdvQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDependentReferenceDataVersionsForRdvQuery,
    GQTypes.GQDependentReferenceDataVersionsForRdvQueryVariables
  >({ query: DependentReferenceDataVersionsForRdvDocument, ...options });
}
export const CitationsForRdvDocument = gql`
    query CitationsForRdv($versionId: ID!) {
  referenceDataVersion(referenceDataVersionId: $versionId) {
    id
    citations {
      id
      displayName
      slug
      isCustomerProvided
      org {
        id
        name
      }
    }
  }
}
    `;

export function useCitationsForRdvQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCitationsForRdvQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCitationsForRdvQuery,
    GQTypes.GQCitationsForRdvQueryVariables
  >({ query: CitationsForRdvDocument, ...options });
}
export const UpdateReferenceDataVersionDocument = gql`
    mutation UpdateReferenceDataVersion($input: UpdateReferenceDataVersionInput!) {
  updateReferenceDataVersion(input: $input) {
    referenceDataVersion {
      id
      versionName
      versionVintage
    }
  }
}
    `;

export function useUpdateReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataVersionMutation,
    GQTypes.GQUpdateReferenceDataVersionMutationVariables
  >(UpdateReferenceDataVersionDocument);
}
export const ReferenceDataRevisionsDocument = gql`
    query ReferenceDataRevisions($referenceDataVersionId: ID!, $excludeArchived: Boolean, $includeDrafts: Boolean, $filterOrgId: ID, $after: String, $before: String, $first: Int, $last: Int) {
  referenceDataRevisionsByVersionId(
    referenceDataVersionId: $referenceDataVersionId
    excludeArchived: $excludeArchived
    includeDrafts: $includeDrafts
    orgId: $filterOrgId
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...ReferenceDataRevisionSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${ReferenceDataRevisionSchemaFragmentDoc}`;

export function useReferenceDataRevisionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataRevisionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataRevisionsQuery,
    GQTypes.GQReferenceDataRevisionsQueryVariables
  >({ query: ReferenceDataRevisionsDocument, ...options });
}
export const LatestReferenceDataRevisionForVersionDocument = gql`
    query latestReferenceDataRevisionForVersion($referenceDataVersionId: ID!) {
  latestReferenceDataRevisionForVersion(
    referenceDataVersionId: $referenceDataVersionId
    anyOrg: true
  ) {
    ...ReferenceDataRevisionSchema
  }
}
    ${ReferenceDataRevisionSchemaFragmentDoc}`;

export function useLatestReferenceDataRevisionForVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestReferenceDataRevisionForVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestReferenceDataRevisionForVersionQuery,
    GQTypes.GQLatestReferenceDataRevisionForVersionQueryVariables
  >({ query: LatestReferenceDataRevisionForVersionDocument, ...options });
}
export const RunSchemaValidationForReferenceDataVersionDocument = gql`
    mutation RunSchemaValidationForReferenceDataVersion($input: RunSchemaValidationForReferenceDataVersionInput!) {
  runSchemaValidationForReferenceDataVersion(input: $input) {
    workflowId
  }
}
    `;

export function useRunSchemaValidationForReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQRunSchemaValidationForReferenceDataVersionMutation,
    GQTypes.GQRunSchemaValidationForReferenceDataVersionMutationVariables
  >(RunSchemaValidationForReferenceDataVersionDocument);
}
export const PublishReferenceDataVersionDocument = gql`
    mutation PublishReferenceDataVersion($input: PublishReferenceDataVersionInput!) {
  publishReferenceDataVersion(input: $input) {
    referenceDataVersion {
      ...ReferenceDataVersionSchema
    }
  }
}
    ${ReferenceDataVersionSchemaFragmentDoc}`;

export function usePublishReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishReferenceDataVersionMutation,
    GQTypes.GQPublishReferenceDataVersionMutationVariables
  >(PublishReferenceDataVersionDocument);
}
export const ArchiveReferenceDataVersionDocument = gql`
    mutation ArchiveReferenceDataVersion($input: ArchiveReferenceDataVersionInput!) {
  archiveReferenceDataVersion(input: $input) {
    referenceDataVersion {
      ...ReferenceDataVersionSchema
    }
  }
}
    ${ReferenceDataVersionSchemaFragmentDoc}`;

export function useArchiveReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveReferenceDataVersionMutation,
    GQTypes.GQArchiveReferenceDataVersionMutationVariables
  >(ArchiveReferenceDataVersionDocument);
}
export const ArchiveReferenceDataRevisionDocument = gql`
    mutation ArchiveReferenceDataRevision($input: ArchiveReferenceDataRevisionInput!) {
  archiveReferenceDataRevision(input: $input) {
    referenceDataRevision {
      ...ReferenceDataRevisionSchema
    }
  }
}
    ${ReferenceDataRevisionSchemaFragmentDoc}`;

export function useArchiveReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveReferenceDataRevisionMutation,
    GQTypes.GQArchiveReferenceDataRevisionMutationVariables
  >(ArchiveReferenceDataRevisionDocument);
}
export const RevisionForSafePublicationDocument = gql`
    query RevisionForSafePublication($revisionId: ID!) {
  referenceDataRevision(revisionId: $revisionId) {
    id
    signedParquetOutputUrl
    outputFileId
  }
  latestPublishedRevision: latestPublishedRevisionForRevisionIdOrNull(
    revisionId: $revisionId
  ) {
    id
  }
}
    `;

export function useRevisionForSafePublicationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQRevisionForSafePublicationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRevisionForSafePublicationQuery,
    GQTypes.GQRevisionForSafePublicationQueryVariables
  >({ query: RevisionForSafePublicationDocument, ...options });
}
export const ReviewPrepublicationTestResultsForReferenceDataRevisionDocument = gql`
    mutation ReviewPrepublicationTestResultsForReferenceDataRevision($input: ReviewPrepublicationTestResultsInput!) {
  reviewResult: reviewPrepublicationTestResultsForReferenceDataRevision(
    input: $input
  ) {
    outcome
    errorCode
    publishedReferenceDataRevision {
      id
    }
  }
}
    `;

export function useReviewPrepublicationTestResultsForReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQReviewPrepublicationTestResultsForReferenceDataRevisionMutation,
    GQTypes.GQReviewPrepublicationTestResultsForReferenceDataRevisionMutationVariables
  >(ReviewPrepublicationTestResultsForReferenceDataRevisionDocument);
}
export const SchemaUpdateValidationWarningsDocument = gql`
    query SchemaUpdateValidationWarnings($referenceDataVersionId: ID!, $schemaJson: JSONSchema!, $orgId: String) {
  validateUpdateReferenceDataVersionSchema(
    referenceDataVersionId: $referenceDataVersionId
    schemaJson: $schemaJson
    orgId: $orgId
  ) {
    warnings
    errors
  }
}
    `;

export function useSchemaUpdateValidationWarningsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaUpdateValidationWarningsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaUpdateValidationWarningsQuery,
    GQTypes.GQSchemaUpdateValidationWarningsQueryVariables
  >({ query: SchemaUpdateValidationWarningsDocument, ...options });
}
export const UpdateReferenceDataVersionSchemaDocument = gql`
    mutation UpdateReferenceDataVersionSchema($input: UpdateReferenceDataVersionSchemaInput!) {
  updateReferenceDataVersionSchema(input: $input) {
    referenceDataVersion {
      id
      latestSchema {
        id
        schemaJson
      }
    }
  }
}
    `;

export function useUpdateReferenceDataVersionSchemaMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataVersionSchemaMutation,
    GQTypes.GQUpdateReferenceDataVersionSchemaMutationVariables
  >(UpdateReferenceDataVersionSchemaDocument);
}
export const UpstreamReferenceDataForVersionDocument = gql`
    query UpstreamReferenceDataForVersion($versionId: ID!) {
  referenceDataVersion(referenceDataVersionId: $versionId) {
    id
    slug
    upstreamReferenceDataVersions {
      id
    }
    source {
      id
      name
    }
  }
}
    `;

export function useUpstreamReferenceDataForVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUpstreamReferenceDataForVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUpstreamReferenceDataForVersionQuery,
    GQTypes.GQUpstreamReferenceDataForVersionQueryVariables
  >({ query: UpstreamReferenceDataForVersionDocument, ...options });
}
export const UpstreamReferenceDataSlugsForRevisionDocument = gql`
    query UpstreamReferenceDataSlugsForRevision($revisionId: ID!) {
  referenceDataRevision(revisionId: $revisionId) {
    id
    upstreamReferenceDataVersions {
      id
    }
  }
}
    `;

export function useUpstreamReferenceDataSlugsForRevisionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUpstreamReferenceDataSlugsForRevisionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUpstreamReferenceDataSlugsForRevisionQuery,
    GQTypes.GQUpstreamReferenceDataSlugsForRevisionQueryVariables
  >({ query: UpstreamReferenceDataSlugsForRevisionDocument, ...options });
}
export const CreateReferenceDataOverlayTemplateDocument = gql`
    mutation CreateReferenceDataOverlayTemplate($input: CreateReferenceDataOverlayTemplateInput!) {
  createReferenceDataOverlayTemplate(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function useCreateReferenceDataOverlayTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReferenceDataOverlayTemplateMutation,
    GQTypes.GQCreateReferenceDataOverlayTemplateMutationVariables
  >(CreateReferenceDataOverlayTemplateDocument);
}
export const StartPrepublicationTestsForReferenceDataRevisionDocument = gql`
    mutation StartPrepublicationTestsForReferenceDataRevision($input: StartPrepublicationTestsForReferenceDataRevisionInput!) {
  startPrepublicationTestsForReferenceDataRevision(input: $input) {
    workflowId
  }
}
    `;

export function useStartPrepublicationTestsForReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQStartPrepublicationTestsForReferenceDataRevisionMutation,
    GQTypes.GQStartPrepublicationTestsForReferenceDataRevisionMutationVariables
  >(StartPrepublicationTestsForReferenceDataRevisionDocument);
}
export const RenameCustomEmissionsFactorTaskDocument = gql`
    mutation RenameCustomEmissionsFactorTask($input: RenameCustomEmissionsFactorSetupTaskInput!) {
  renameCustomEmissionsFactorSetupTask(input: $input) {
    taskId
  }
}
    `;

export function useRenameCustomEmissionsFactorTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQRenameCustomEmissionsFactorTaskMutation,
    GQTypes.GQRenameCustomEmissionsFactorTaskMutationVariables
  >(RenameCustomEmissionsFactorTaskDocument);
}
export const SetFootprintVersionKindDocument = gql`
    mutation SetFootprintVersionKind($input: SetFootprintVersionKindInput!) {
  setFootprintVersionKind(input: $input) {
    footprintVersion {
      id
    }
  }
}
    `;

export function useSetFootprintVersionKindMutation() {
  return Urql.useMutation<
    GQTypes.GQSetFootprintVersionKindMutation,
    GQTypes.GQSetFootprintVersionKindMutationVariables
  >(SetFootprintVersionKindDocument);
}
export const ShareFilesDocument = gql`
    mutation ShareFiles($input: ShareFilesInput!) {
  shareFiles(input: $input) {
    sharedFiles {
      id
    }
  }
}
    `;

export function useShareFilesMutation() {
  return Urql.useMutation<
    GQTypes.GQShareFilesMutation,
    GQTypes.GQShareFilesMutationVariables
  >(ShareFilesDocument);
}
export const MeasurementProjectForShareFilesDocument = gql`
    query MeasurementProjectForShareFiles($measurementProjectId: ID!) {
  measurementProject(id: $measurementProjectId) {
    ...MeasurementProjectForShareFiles
  }
}
    ${MeasurementProjectForShareFilesFragmentDoc}`;

export function useMeasurementProjectForShareFilesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectForShareFilesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectForShareFilesQuery,
    GQTypes.GQMeasurementProjectForShareFilesQueryVariables
  >({ query: MeasurementProjectForShareFilesDocument, ...options });
}
export const SharedFileDialogDocument = gql`
    query SharedFileDialog($orgId: ID!) {
  organization(id: $orgId) {
    id
    measurementProjects {
      id
      ...MeasurementProjectForShareFiles
    }
  }
}
    ${MeasurementProjectForShareFilesFragmentDoc}`;

export function useSharedFileDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSharedFileDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSharedFileDialogQuery,
    GQTypes.GQSharedFileDialogQueryVariables
  >({ query: SharedFileDialogDocument, ...options });
}
export const CreateSupplierChartAdminDocument = gql`
    mutation CreateSupplierChartAdmin($input: CreateSupplierChartInputAdmin!) {
  createSupplierChartAdmin(input: $input) {
    chart {
      id
    }
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFieldsAdmin
      }
    }
  }
}
    ${SupplierChartConfigFieldsAdminFragmentDoc}`;

export function useCreateSupplierChartAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierChartAdminMutation,
    GQTypes.GQCreateSupplierChartAdminMutationVariables
  >(CreateSupplierChartAdminDocument);
}
export const UpdateSupplierChartAdminDocument = gql`
    mutation UpdateSupplierChartAdmin($input: UpdateSupplierChartInputAdmin!) {
  updateSupplierChartAdmin(input: $input) {
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFieldsAdmin
      }
    }
  }
}
    ${SupplierChartConfigFieldsAdminFragmentDoc}`;

export function useUpdateSupplierChartAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierChartAdminMutation,
    GQTypes.GQUpdateSupplierChartAdminMutationVariables
  >(UpdateSupplierChartAdminDocument);
}
export const DeleteSupplierChartAdminDocument = gql`
    mutation DeleteSupplierChartAdmin($input: DeleteSupplierChartInputAdmin!) {
  deleteSupplierChartAdmin(input: $input) {
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFieldsAdmin
      }
    }
  }
}
    ${SupplierChartConfigFieldsAdminFragmentDoc}`;

export function useDeleteSupplierChartAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierChartAdminMutation,
    GQTypes.GQDeleteSupplierChartAdminMutationVariables
  >(DeleteSupplierChartAdminDocument);
}
export const GetSupplierColumnsDocument = gql`
    query GetSupplierColumns($orgId: ID!) {
  supplierTableColumns(orgId: $orgId) {
    ...SupplierColumnFieldsAdmin
  }
}
    ${SupplierColumnFieldsAdminFragmentDoc}`;

export function useGetSupplierColumnsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierColumnsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierColumnsQuery,
    GQTypes.GQGetSupplierColumnsQueryVariables
  >({ query: GetSupplierColumnsDocument, ...options });
}
export const DeleteEngagementTaskDocument = gql`
    mutation DeleteEngagementTask($input: DeleteEngagementTasksAndAnswersByCrossOrgModelIdInput!) {
  deleteEngagementTasksAndAnswersByCrossOrgModelId(input: $input) {
    engagementTasks {
      id
      deletedAt
    }
  }
}
    `;

export function useDeleteEngagementTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEngagementTaskMutation,
    GQTypes.GQDeleteEngagementTaskMutationVariables
  >(DeleteEngagementTaskDocument);
}
export const CreateTargetDocument = gql`
    mutation CreateTarget($input: CreateTargetInput!) {
  createTarget(input: $input) {
    org {
      ...OrganizationForTargetsForm
    }
  }
}
    ${OrganizationForTargetsFormFragmentDoc}`;

export function useCreateTargetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateTargetMutation,
    GQTypes.GQCreateTargetMutationVariables
  >(CreateTargetDocument);
}
export const UpdateTargetDocument = gql`
    mutation UpdateTarget($input: UpdateTargetInput!) {
  updateTarget(input: $input) {
    target {
      ...TargetForTargetsForm
    }
  }
}
    ${TargetForTargetsFormFragmentDoc}`;

export function useUpdateTargetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateTargetMutation,
    GQTypes.GQUpdateTargetMutationVariables
  >(UpdateTargetDocument);
}
export const DeleteTargetDocument = gql`
    mutation DeleteTarget($input: DeleteTargetInput!) {
  deleteTarget(input: $input) {
    org {
      ...OrganizationForTargetsForm
    }
  }
}
    ${OrganizationForTargetsFormFragmentDoc}`;

export function useDeleteTargetMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteTargetMutation,
    GQTypes.GQDeleteTargetMutationVariables
  >(DeleteTargetDocument);
}
export const GetUserUploadedTableByIdsDocument = gql`
    query GetUserUploadedTableByIds($ids: [ID!]!) {
  userUploadedTablesById(ids: $ids) {
    id
    sqlTableName
    shouldIgnore
    userVisibleAttempt {
      id
      userUpload {
        id
        name
        userUploadTask {
          id
          datasource {
            id
            name
            dataset {
              id
            }
          }
        }
      }
    }
  }
}
    `;

export function useGetUserUploadedTableByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTableByIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTableByIdsQuery,
    GQTypes.GQGetUserUploadedTableByIdsQueryVariables
  >({ query: GetUserUploadedTableByIdsDocument, ...options });
}
export const UpdateFootprintVersionChangelogDocument = gql`
    mutation UpdateFootprintVersionChangelog($input: UpdateFootprintVersionInput!) {
  updateFootprintVersion(input: $input) {
    footprintVersion {
      id
      versionName
      userChangelogMd
    }
  }
}
    `;

export function useUpdateFootprintVersionChangelogMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFootprintVersionChangelogMutation,
    GQTypes.GQUpdateFootprintVersionChangelogMutationVariables
  >(UpdateFootprintVersionChangelogDocument);
}
export const UpdateOrgPointOfContactDocument = gql`
    mutation UpdateOrgPointOfContact($input: UpdateOrgPointOfContactInput!) {
  updateOrgPointOfContact(input: $input) {
    org {
      id
      pointsOfContact {
        id
        kind
      }
    }
  }
}
    `;

export function useUpdateOrgPointOfContactMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgPointOfContactMutation,
    GQTypes.GQUpdateOrgPointOfContactMutationVariables
  >(UpdateOrgPointOfContactDocument);
}
export const UpdateUserNameAdminVersionDocument = gql`
    mutation UpdateUserNameAdminVersion($input: UpdateUserNameAdminVersionInput!) {
  updateUserNameAdminVersion(input: $input) {
    user {
      id
      name
    }
  }
}
    `;

export function useUpdateUserNameAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserNameAdminVersionMutation,
    GQTypes.GQUpdateUserNameAdminVersionMutationVariables
  >(UpdateUserNameAdminVersionDocument);
}
export const UpdateUserEmailAdminVersionDocument = gql`
    mutation UpdateUserEmailAdminVersion($input: UpdateUserEmailAdminVersionInput!) {
  updateUserEmailAdminVersion(input: $input) {
    user {
      id
      email
    }
  }
}
    `;

export function useUpdateUserEmailAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserEmailAdminVersionMutation,
    GQTypes.GQUpdateUserEmailAdminVersionMutationVariables
  >(UpdateUserEmailAdminVersionDocument);
}
export const UserUploadsForAdtSelectorDialogDocument = gql`
    query UserUploadsForAdtSelectorDialog($orgId: ID!, $datasourceIds: [ID!]) {
  userUploads(
    orgId: $orgId
    datasourceIds: $datasourceIds
    includeRevisions: false
  ) {
    edges {
      node {
        ...UserUploadForAdtSelectorDialog
      }
    }
  }
}
    ${UserUploadForAdtSelectorDialogFragmentDoc}`;

export function useUserUploadsForAdtSelectorDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadsForAdtSelectorDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadsForAdtSelectorDialogQuery,
    GQTypes.GQUserUploadsForAdtSelectorDialogQueryVariables
  >({ query: UserUploadsForAdtSelectorDialogDocument, ...options });
}
export const RemoveUserAdminVersionDocument = gql`
    mutation RemoveUserAdminVersion($input: RemoveUserAdminVersionInput!) {
  removeUserAdminVersion(input: $input) {
    user {
      id
      email
      deletedAt
    }
  }
}
    `;

export function useRemoveUserAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQRemoveUserAdminVersionMutation,
    GQTypes.GQRemoveUserAdminVersionMutationVariables
  >(RemoveUserAdminVersionDocument);
}
export const ResetLockedUserAdminVersionDocument = gql`
    mutation ResetLockedUserAdminVersion($input: ResetLockedUserAdminVersionInput!) {
  resetLockedUserAdminVersion(input: $input) {
    user {
      id
      consecutiveLoginFailures
    }
  }
}
    `;

export function useResetLockedUserAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQResetLockedUserAdminVersionMutation,
    GQTypes.GQResetLockedUserAdminVersionMutationVariables
  >(ResetLockedUserAdminVersionDocument);
}
export const WatershedEmployeeDropdownFieldDocument = gql`
    query WatershedEmployeeDropdownField($orgPointOfContactKind: OrgPointOfContactKind) {
  watershedEmployees(orgPointOfContactKind: $orgPointOfContactKind) {
    edges {
      node {
        id
        name
        user {
          id
        }
      }
    }
  }
}
    `;

export function useWatershedEmployeeDropdownFieldQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQWatershedEmployeeDropdownFieldQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQWatershedEmployeeDropdownFieldQuery,
    GQTypes.GQWatershedEmployeeDropdownFieldQueryVariables
  >({ query: WatershedEmployeeDropdownFieldDocument, ...options });
}
export const CreateCanonicalClimateProgramProjectDocument = gql`
    mutation CreateCanonicalClimateProgramProject($input: CreateCanonicalClimateProgramProjectInput!) {
  createCanonicalClimateProgramProject(input: $input) {
    canonicalClimateProgramProject {
      ...CanonicalClimateProgramProjectFields
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}`;

export function useCreateCanonicalClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalClimateProgramProjectMutation,
    GQTypes.GQCreateCanonicalClimateProgramProjectMutationVariables
  >(CreateCanonicalClimateProgramProjectDocument);
}
export const CreateCanonicalClimateProgramProjectRequirementDatasetDocument = gql`
    mutation CreateCanonicalClimateProgramProjectRequirementDataset($input: CreateCanonicalClimateProgramProjectRequirementDatasetInput!) {
  createCanonicalClimateProgramProjectRequirementDataset(input: $input) {
    canonicalClimateProgramProject {
      id
      datasetRequirements {
        ...CanonicalProjectRequirementDatasetFields
      }
    }
    canonicalClimateProgramProjectRequirementDataset {
      ...CanonicalProjectRequirementDatasetFields
    }
  }
}
    ${CanonicalProjectRequirementDatasetFieldsFragmentDoc}`;

export function useCreateCanonicalClimateProgramProjectRequirementDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalClimateProgramProjectRequirementDatasetMutation,
    GQTypes.GQCreateCanonicalClimateProgramProjectRequirementDatasetMutationVariables
  >(CreateCanonicalClimateProgramProjectRequirementDatasetDocument);
}
export const CreateCanonicalClimateProgramProjectRequirementGhgpDocument = gql`
    mutation CreateCanonicalClimateProgramProjectRequirementGhgp($input: CreateCanonicalClimateProgramProjectRequirementGhgpInput!) {
  createCanonicalClimateProgramProjectRequirementGhgp(input: $input) {
    canonicalClimateProgramProject {
      id
      ghgpRequirements {
        ...CanonicalProjectRequirementGhgpFields
      }
    }
    canonicalClimateProgramProjectRequirementGhgp {
      ...CanonicalClimateProgramProjectRequirementGhgpFields
    }
  }
}
    ${CanonicalProjectRequirementGhgpFieldsFragmentDoc}
${CanonicalClimateProgramProjectRequirementGhgpFieldsFragmentDoc}`;

export function useCreateCanonicalClimateProgramProjectRequirementGhgpMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalClimateProgramProjectRequirementGhgpMutation,
    GQTypes.GQCreateCanonicalClimateProgramProjectRequirementGhgpMutationVariables
  >(CreateCanonicalClimateProgramProjectRequirementGhgpDocument);
}
export const UpdateCanonicalClimateProgramProjectDocument = gql`
    mutation UpdateCanonicalClimateProgramProject($input: UpdateCanonicalClimateProgramProjectInput!) {
  updateCanonicalClimateProgramProject(input: $input) {
    canonicalClimateProgramProject {
      ...CanonicalClimateProgramProjectFields
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}`;

export function useUpdateCanonicalClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalClimateProgramProjectMutation,
    GQTypes.GQUpdateCanonicalClimateProgramProjectMutationVariables
  >(UpdateCanonicalClimateProgramProjectDocument);
}
export const UpdateCanonicalClimateProgramProjectRequirementDatasetDocument = gql`
    mutation UpdateCanonicalClimateProgramProjectRequirementDataset($input: UpdateCanonicalClimateProgramProjectRequirementDatasetInput!) {
  updateCanonicalClimateProgramProjectRequirementDataset(input: $input) {
    canonicalClimateProgramProjectRequirementDataset {
      ...CanonicalProjectRequirementDatasetFields
    }
  }
}
    ${CanonicalProjectRequirementDatasetFieldsFragmentDoc}`;

export function useUpdateCanonicalClimateProgramProjectRequirementDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalClimateProgramProjectRequirementDatasetMutation,
    GQTypes.GQUpdateCanonicalClimateProgramProjectRequirementDatasetMutationVariables
  >(UpdateCanonicalClimateProgramProjectRequirementDatasetDocument);
}
export const GetCanonicalDatasetsDocument = gql`
    query GetCanonicalDatasets {
  canonicalDatasets {
    id
    name
  }
}
    `;

export function useGetCanonicalDatasetsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCanonicalDatasetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCanonicalDatasetsQuery,
    GQTypes.GQGetCanonicalDatasetsQueryVariables
  >({ query: GetCanonicalDatasetsDocument, ...options });
}
export const UpdateCanonicalClimateProgramProjectRequirementGhgpDocument = gql`
    mutation UpdateCanonicalClimateProgramProjectRequirementGhgp($input: UpdateCanonicalClimateProgramProjectRequirementGhgpInput!) {
  updateCanonicalClimateProgramProjectRequirementGhgp(input: $input) {
    canonicalClimateProgramProjectRequirementGhgp {
      ...CanonicalProjectRequirementGhgpFields
    }
  }
}
    ${CanonicalProjectRequirementGhgpFieldsFragmentDoc}`;

export function useUpdateCanonicalClimateProgramProjectRequirementGhgpMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalClimateProgramProjectRequirementGhgpMutation,
    GQTypes.GQUpdateCanonicalClimateProgramProjectRequirementGhgpMutationVariables
  >(UpdateCanonicalClimateProgramProjectRequirementGhgpDocument);
}
export const GetCompanyRelationshipsDocument = gql`
    query GetCompanyRelationships($id: ID!) {
  company(id: $id) {
    ...CompanyRelationshipsCard
  }
}
    ${CompanyRelationshipsCardFragmentDoc}`;

export function useGetCompanyRelationshipsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyRelationshipsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyRelationshipsQuery,
    GQTypes.GQGetCompanyRelationshipsQueryVariables
  >({ query: GetCompanyRelationshipsDocument, ...options });
}
export const CreateCompanyRelationshipDocument = gql`
    mutation CreateCompanyRelationship($input: CreateCompanyRelationshipInput!) {
  createCompanyRelationship(input: $input) {
    companyRelationship {
      ...CompanyRelationshipFields
    }
  }
}
    ${CompanyRelationshipFieldsFragmentDoc}`;

export function useCreateCompanyRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCompanyRelationshipMutation,
    GQTypes.GQCreateCompanyRelationshipMutationVariables
  >(CreateCompanyRelationshipDocument);
}
export const DeleteCompanyRelationshipDocument = gql`
    mutation DeleteCompanyRelationship($input: DeleteCompanyRelationshipInput!) {
  deleteCompanyRelationship(input: $input) {
    id
  }
}
    `;

export function useDeleteCompanyRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCompanyRelationshipMutation,
    GQTypes.GQDeleteCompanyRelationshipMutationVariables
  >(DeleteCompanyRelationshipDocument);
}
export const UpsertCompanyDocument = gql`
    mutation UpsertCompany($input: CompanyInput!) {
  upsertCompany(input: $input) {
    company {
      ...CompanyFieldsWithMatches
    }
  }
}
    ${CompanyFieldsWithMatchesFragmentDoc}`;

export function useUpsertCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertCompanyMutation,
    GQTypes.GQUpsertCompanyMutationVariables
  >(UpsertCompanyDocument);
}
export const CompaniesPageDocument = gql`
    query CompaniesPage($q: String, $after: String, $before: String, $first: Int, $last: Int, $includeDemo: Boolean) {
  companies(
    search: $q
    after: $after
    before: $before
    first: $first
    last: $last
    includeDemo: $includeDemo
  ) {
    edges {
      node {
        ...CompanyFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${CompanyFieldsFragmentDoc}`;

export function useCompaniesPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompaniesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompaniesPageQuery,
    GQTypes.GQCompaniesPageQueryVariables
  >({ query: CompaniesPageDocument, ...options });
}
export const CompanyIngestionRecordUpdatesDocument = gql`
    query CompanyIngestionRecordUpdates($filters: CompanyIngestionRecordUpdateFilters, $sortDirection: DataGridSortDirection, $after: String, $before: String, $first: Int, $last: Int) {
  companyIngestionRecordUpdates(
    filters: $filters
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...CompanyIngestionRecordUpdateFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useCompanyIngestionRecordUpdatesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyIngestionRecordUpdatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyIngestionRecordUpdatesQuery,
    GQTypes.GQCompanyIngestionRecordUpdatesQueryVariables
  >({ query: CompanyIngestionRecordUpdatesDocument, ...options });
}
export const ApproveCompanyIngestionUpdateDocument = gql`
    mutation ApproveCompanyIngestionUpdate($input: IdInput!) {
  approveCompanyIngestionUpdate(input: $input) {
    companyIngestionRecordUpdate {
      ...CompanyIngestionRecordUpdateFields
    }
  }
}
    ${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useApproveCompanyIngestionUpdateMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveCompanyIngestionUpdateMutation,
    GQTypes.GQApproveCompanyIngestionUpdateMutationVariables
  >(ApproveCompanyIngestionUpdateDocument);
}
export const DeclineCompanyIngestionUpdateDocument = gql`
    mutation DeclineCompanyIngestionUpdate($input: IdInput!) {
  declineCompanyIngestionUpdate(input: $input) {
    companyIngestionRecordUpdate {
      ...CompanyIngestionRecordUpdateFields
    }
    company {
      ...CompanyFields
    }
  }
}
    ${CompanyIngestionRecordUpdateFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

export function useDeclineCompanyIngestionUpdateMutation() {
  return Urql.useMutation<
    GQTypes.GQDeclineCompanyIngestionUpdateMutation,
    GQTypes.GQDeclineCompanyIngestionUpdateMutationVariables
  >(DeclineCompanyIngestionUpdateDocument);
}
export const ApproveCompanyIngestionDiffDocument = gql`
    mutation ApproveCompanyIngestionDiff($input: IdInput!) {
  approveCompanyIngestionDiff(input: $input) {
    companyIngestionDiff {
      ...CompanyIngestionDiffFields
    }
    companyIngestionRecordUpdates {
      ...CompanyIngestionRecordUpdateFields
    }
  }
}
    ${CompanyIngestionDiffFieldsFragmentDoc}
${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useApproveCompanyIngestionDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveCompanyIngestionDiffMutation,
    GQTypes.GQApproveCompanyIngestionDiffMutationVariables
  >(ApproveCompanyIngestionDiffDocument);
}
export const DeclineCompanyIngestionDiffDocument = gql`
    mutation DeclineCompanyIngestionDiff($input: IdInput!) {
  declineCompanyIngestionDiff(input: $input) {
    companyIngestionDiff {
      ...CompanyIngestionDiffFields
    }
    companyIngestionRecordUpdates {
      ...CompanyIngestionRecordUpdateFields
    }
  }
}
    ${CompanyIngestionDiffFieldsFragmentDoc}
${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useDeclineCompanyIngestionDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQDeclineCompanyIngestionDiffMutation,
    GQTypes.GQDeclineCompanyIngestionDiffMutationVariables
  >(DeclineCompanyIngestionDiffDocument);
}
export const ReingestCompanyIngestionDiffsDocument = gql`
    mutation ReingestCompanyIngestionDiffs($input: ReingestCompanyIngestionDiffInput!) {
  reingestCompanyIngestionDiffs(input: $input) {
    companyIngestionDiffs {
      ...CompanyIngestionDiffFields
    }
    companyIngestionRecordUpdates {
      ...CompanyIngestionRecordUpdateFields
    }
  }
}
    ${CompanyIngestionDiffFieldsFragmentDoc}
${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useReingestCompanyIngestionDiffsMutation() {
  return Urql.useMutation<
    GQTypes.GQReingestCompanyIngestionDiffsMutation,
    GQTypes.GQReingestCompanyIngestionDiffsMutationVariables
  >(ReingestCompanyIngestionDiffsDocument);
}
export const GetPublicDisclosureForIngestionRecordDocument = gql`
    query GetPublicDisclosureForIngestionRecord($publicDisclosureId: ID, $privateDisclosureId: ID) {
  disclosure(
    publicDisclosureId: $publicDisclosureId
    privateDisclosureId: $privateDisclosureId
    opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}
  ) {
    id
    publicDisclosure {
      ...PublicDisclosureForAdminEdit
    }
    privateDisclosure {
      ...PrivateDisclosureAllFields
    }
    historicalEmissionsYears {
      ...EmissionsYearAllFields
    }
    climateCommitments {
      ...CompanyClimateCommitmentFields
    }
  }
}
    ${PublicDisclosureForAdminEditFragmentDoc}
${PrivateDisclosureAllFieldsFragmentDoc}
${EmissionsYearAllFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsFragmentDoc}`;

export function useGetPublicDisclosureForIngestionRecordQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPublicDisclosureForIngestionRecordQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPublicDisclosureForIngestionRecordQuery,
    GQTypes.GQGetPublicDisclosureForIngestionRecordQueryVariables
  >({ query: GetPublicDisclosureForIngestionRecordDocument, ...options });
}
export const CompanyIngestionDiffErrorsDocument = gql`
    query CompanyIngestionDiffErrors {
  companyIngestionDiffErrors {
    id
    createdAt
    companyIngestionDiffId
    errorCode
    errorData
    status
  }
}
    `;

export function useCompanyIngestionDiffErrorsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyIngestionDiffErrorsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyIngestionDiffErrorsQuery,
    GQTypes.GQCompanyIngestionDiffErrorsQueryVariables
  >({ query: CompanyIngestionDiffErrorsDocument, ...options });
}
export const CreateCtsDocument = gql`
    mutation CreateCts($input: CreateCtsInput!) {
  createCts(input: $input) {
    schema {
      id
      ...CustomerTargetSchemaForListView
    }
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useCreateCtsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCtsMutation,
    GQTypes.GQCreateCtsMutationVariables
  >(CreateCtsDocument);
}
export const UpdateCtsDocument = gql`
    mutation UpdateCts($input: UpdateCtsInput!) {
  updateCts(input: $input) {
    schema {
      id
      ...CustomerTargetSchemaForListView
    }
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useUpdateCtsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCtsMutation,
    GQTypes.GQUpdateCtsMutationVariables
  >(UpdateCtsDocument);
}
export const ConvertCtsToTSchemaDocument = gql`
    mutation ConvertCtsToTSchema($input: ConvertCtsToTSchemaInput!) {
  convertCtsToTSchema(input: $input) {
    cts {
      ...CtsInfoHeader
    }
  }
}
    ${CtsInfoHeaderFragmentDoc}`;

export function useConvertCtsToTSchemaMutation() {
  return Urql.useMutation<
    GQTypes.GQConvertCtsToTSchemaMutation,
    GQTypes.GQConvertCtsToTSchemaMutationVariables
  >(ConvertCtsToTSchemaDocument);
}
export const ConvertCtsBackDocument = gql`
    mutation ConvertCtsBack($input: ConvertCtsBackInput!) {
  convertCtsBack(input: $input) {
    cts {
      ...CtsInfoHeader
    }
  }
}
    ${CtsInfoHeaderFragmentDoc}`;

export function useConvertCtsBackMutation() {
  return Urql.useMutation<
    GQTypes.GQConvertCtsBackMutation,
    GQTypes.GQConvertCtsBackMutationVariables
  >(ConvertCtsBackDocument);
}
export const RunCtsTransformTestCaseDocument = gql`
    mutation RunCtsTransformTestCase($input: RunCtsTransformTestCaseInput!) {
  runCtsTransformTestCase(input: $input) {
    workflowId
  }
}
    `;

export function useRunCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQRunCtsTransformTestCaseMutation,
    GQTypes.GQRunCtsTransformTestCaseMutationVariables
  >(RunCtsTransformTestCaseDocument);
}
export const CreateCtsTransformTestCaseDocument = gql`
    mutation CreateCtsTransformTestCase($input: CreateCtsTransformTestCaseInput!) {
  createCtsTransformTestCase(input: $input) {
    ctsTransformTestCase {
      id
      transform {
        ...CtsTransformForTests
      }
    }
  }
}
    ${CtsTransformForTestsFragmentDoc}`;

export function useCreateCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCtsTransformTestCaseMutation,
    GQTypes.GQCreateCtsTransformTestCaseMutationVariables
  >(CreateCtsTransformTestCaseDocument);
}
export const UpdateCtsTransformTestCaseDocument = gql`
    mutation UpdateCtsTransformTestCase($input: UpdateCtsTransformTestCaseInput!) {
  updateCtsTransformTestCase(input: $input) {
    ctsTransformTestCase {
      ...CtsTest
    }
  }
}
    ${CtsTestFragmentDoc}`;

export function useUpdateCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCtsTransformTestCaseMutation,
    GQTypes.GQUpdateCtsTransformTestCaseMutationVariables
  >(UpdateCtsTransformTestCaseDocument);
}
export const DeleteCtsTransformTestCaseDocument = gql`
    mutation DeleteCtsTransformTestCase($input: DeleteCtsTransformTestCaseInput!) {
  deleteCtsTransformTestCase(input: $input) {
    ctsTransform {
      ...CtsTransformForTests
    }
  }
}
    ${CtsTransformForTestsFragmentDoc}`;

export function useDeleteCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCtsTransformTestCaseMutation,
    GQTypes.GQDeleteCtsTransformTestCaseMutationVariables
  >(DeleteCtsTransformTestCaseDocument);
}
export const ApproveCtsTransformTestCaseDocument = gql`
    mutation ApproveCtsTransformTestCase($input: ApproveCtsTransformTestCaseInput!) {
  approveCtsTransformTestCase(input: $input) {
    ctsTransformTestCase {
      ...CtsTest
    }
  }
}
    ${CtsTestFragmentDoc}`;

export function useApproveCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveCtsTransformTestCaseMutation,
    GQTypes.GQApproveCtsTransformTestCaseMutationVariables
  >(ApproveCtsTransformTestCaseDocument);
}
export const CustomerTargetSchemaTransformDocument = gql`
    query CustomerTargetSchemaTransform($id: ID!) {
  customerTargetSchemaTransform(id: $id) {
    id
    ...CtsTransformForTests
  }
}
    ${CtsTransformForTestsFragmentDoc}`;

export function useCustomerTargetSchemaTransformQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomerTargetSchemaTransformQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomerTargetSchemaTransformQuery,
    GQTypes.GQCustomerTargetSchemaTransformQueryVariables
  >({ query: CustomerTargetSchemaTransformDocument, ...options });
}
export const PublishCtsVersionDocument = gql`
    mutation PublishCtsVersion($input: PublishCtsVersionInput!) {
  publishCtsVersion(input: $input) {
    ctsVersion {
      id
      ...CtsVersionAdmin
    }
    customerTargetSchema {
      ...CtsInfoHeader
    }
  }
}
    ${CtsVersionAdminFragmentDoc}
${CtsInfoHeaderFragmentDoc}`;

export function usePublishCtsVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishCtsVersionMutation,
    GQTypes.GQPublishCtsVersionMutationVariables
  >(PublishCtsVersionDocument);
}
export const PublishCtsVersionForTestingDocument = gql`
    mutation PublishCtsVersionForTesting($input: PublishCtsVersionInput!) {
  publishCtsVersionForTesting(input: $input) {
    ctsVersion {
      id
      ...CtsVersionAdmin
    }
  }
}
    ${CtsVersionAdminFragmentDoc}`;

export function usePublishCtsVersionForTestingMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishCtsVersionForTestingMutation,
    GQTypes.GQPublishCtsVersionForTestingMutationVariables
  >(PublishCtsVersionForTestingDocument);
}
export const UnpublishCtsVersionDocument = gql`
    mutation UnpublishCtsVersion($input: UnpublishCtsVersionInput!) {
  unpublishCtsVersion(input: $input) {
    ctsVersion {
      id
      ...CtsVersionAdmin
    }
    customerTargetSchema {
      ...CtsInfoHeader
    }
  }
}
    ${CtsVersionAdminFragmentDoc}
${CtsInfoHeaderFragmentDoc}`;

export function useUnpublishCtsVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUnpublishCtsVersionMutation,
    GQTypes.GQUnpublishCtsVersionMutationVariables
  >(UnpublishCtsVersionDocument);
}
export const PushCtsVersionToOneSchemaDocument = gql`
    mutation PushCtsVersionToOneSchema($input: PushCtsVersionToOneSchemaInput!) {
  pushCtsVersionToOneSchema(input: $input) {
    oneSchemaTemplateKey
    signedUrl
    fileMetadata {
      ...FileMetadataForCtsVersionEditor
    }
    oneSchemaTemplateUrl
  }
}
    ${FileMetadataForCtsVersionEditorFragmentDoc}`;

export function usePushCtsVersionToOneSchemaMutation() {
  return Urql.useMutation<
    GQTypes.GQPushCtsVersionToOneSchemaMutation,
    GQTypes.GQPushCtsVersionToOneSchemaMutationVariables
  >(PushCtsVersionToOneSchemaDocument);
}
export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    comment {
      ...CommentForCommentDisplayFields
    }
  }
}
    ${CommentForCommentDisplayFieldsFragmentDoc}`;

export function useUpdateCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCommentMutation,
    GQTypes.GQUpdateCommentMutationVariables
  >(UpdateCommentDocument);
}
export const DeleteCommentDocument = gql`
    mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    question {
      id
      comments {
        edges {
          node {
            ...CommentForCommentDisplayFields
          }
        }
      }
    }
  }
}
    ${CommentForCommentDisplayFieldsFragmentDoc}`;

export function useDeleteCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCommentMutation,
    GQTypes.GQDeleteCommentMutationVariables
  >(DeleteCommentDocument);
}
export const CreateDataIssueDocument = gql`
    mutation CreateDataIssue($input: CreateDataIssueInput!, $orgId: ID!) {
  createDataIssue(input: $input) {
    userUploadTask {
      id
      issues {
        edges {
          node {
            id
          }
        }
      }
    }
    issue {
      ...DataIssueForEditDataIssuesPageFields
    }
    measurementProject {
      ...MeasurementProjectForEditDataIssuesPage
    }
  }
}
    ${DataIssueForEditDataIssuesPageFieldsFragmentDoc}
${MeasurementProjectForEditDataIssuesPageFragmentDoc}`;

export function useCreateDataIssueMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDataIssueMutation,
    GQTypes.GQCreateDataIssueMutationVariables
  >(CreateDataIssueDocument);
}
export const OrganizationProjectCreateDataIssuesFieldsDocument = gql`
    query OrganizationProjectCreateDataIssuesFields($orgId: ID!) {
  organization(id: $orgId) {
    id
    measurementProjects {
      ...MeasurementProjectFieldsForCreateDataIssue
    }
  }
}
    ${MeasurementProjectFieldsForCreateDataIssueFragmentDoc}`;

export function useOrganizationProjectCreateDataIssuesFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationProjectCreateDataIssuesFieldsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationProjectCreateDataIssuesFieldsQuery,
    GQTypes.GQOrganizationProjectCreateDataIssuesFieldsQueryVariables
  >({ query: OrganizationProjectCreateDataIssuesFieldsDocument, ...options });
}
export const UpdateDataIssueDocument = gql`
    mutation UpdateDataIssue($input: UpdateDataIssueInput!, $orgId: ID!) {
  updateDataIssue(input: $input) {
    issue {
      ...DataIssueForEditDataIssuesPageFields
    }
  }
}
    ${DataIssueForEditDataIssuesPageFieldsFragmentDoc}`;

export function useUpdateDataIssueMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDataIssueMutation,
    GQTypes.GQUpdateDataIssueMutationVariables
  >(UpdateDataIssueDocument);
}
export const CreateDataIssueCommentDocument = gql`
    mutation CreateDataIssueComment($input: CreateDataIssueCommentInput!) {
  createDataIssueComment(input: $input) {
    issue {
      id
      comments {
        edges {
          node {
            ...CommentForCommentDisplayFields
          }
        }
      }
    }
  }
}
    ${CommentForCommentDisplayFieldsFragmentDoc}`;

export function useCreateDataIssueCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDataIssueCommentMutation,
    GQTypes.GQCreateDataIssueCommentMutationVariables
  >(CreateDataIssueCommentDocument);
}
export const CreateEmissionsModelDocument = gql`
    mutation CreateEmissionsModel($input: CreateEmissionsModelInput!) {
  createEmissionsModel(input: $input) {
    __typename
    ... on CreateEmissionsModelPayloadInvalid {
      validationErrors {
        msg
        path
      }
    }
    ... on CreateEmissionsModelPayloadValid {
      emissionsModelStableRecord {
        id
      }
    }
  }
}
    `;

export function useCreateEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEmissionsModelMutation,
    GQTypes.GQCreateEmissionsModelMutationVariables
  >(CreateEmissionsModelDocument);
}
export const ValidateEmissionsModelMatcherDocument = gql`
    mutation ValidateEmissionsModelMatcher($input: ValidateEmissionsModelMatcherInput!) {
  validateEmissionsModelMatcher(input: $input) {
    isValid
    errors {
      path
      msg
      severity
    }
  }
}
    `;

export function useValidateEmissionsModelMatcherMutation() {
  return Urql.useMutation<
    GQTypes.GQValidateEmissionsModelMatcherMutation,
    GQTypes.GQValidateEmissionsModelMatcherMutationVariables
  >(ValidateEmissionsModelMatcherDocument);
}
export const GetDiffSourceEmissionsModelVersionDocument = gql`
    query GetDiffSourceEmissionsModelVersion($stableId: ID!) {
  latestEmissionsModelVersionForStableId(id: $stableId, includeDrafts: false) {
    id
    emissionsModelJson
    parameterResolverJson
  }
  emissionsModelMatchersForStableId(id: $stableId, includeDrafts: false) {
    ...EmissionsModelMatcherFields
  }
}
    ${EmissionsModelMatcherFieldsFragmentDoc}`;

export function useGetDiffSourceEmissionsModelVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDiffSourceEmissionsModelVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDiffSourceEmissionsModelVersionQuery,
    GQTypes.GQGetDiffSourceEmissionsModelVersionQueryVariables
  >({ query: GetDiffSourceEmissionsModelVersionDocument, ...options });
}
export const GetReferenceDataFromSlugsDocument = gql`
    query GetReferenceDataFromSlugs($slugs: [String!]!) {
  getReferenceDataFromRawSlugs(slugs: $slugs) {
    name
    id
  }
}
    `;

export function useGetReferenceDataFromSlugsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReferenceDataFromSlugsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReferenceDataFromSlugsQuery,
    GQTypes.GQGetReferenceDataFromSlugsQueryVariables
  >({ query: GetReferenceDataFromSlugsDocument, ...options });
}
export const ReviewPrepublicationTestResultsForEmissionsModelDocument = gql`
    mutation ReviewPrepublicationTestResultsForEmissionsModel($input: ReviewPrepublicationTestResultsInput!) {
  reviewResult: reviewPrepublicationTestResultsForEmissionsModel(input: $input) {
    outcome
    errorCode
    publishedEmissionsModelVersion {
      ...EmissionsModelVersionFieldsForEditor
    }
    publishedEmissionsModelMatchers {
      ...EmissionsModelMatcherFields
    }
  }
}
    ${EmissionsModelVersionFieldsForEditorFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}`;

export function useReviewPrepublicationTestResultsForEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQReviewPrepublicationTestResultsForEmissionsModelMutation,
    GQTypes.GQReviewPrepublicationTestResultsForEmissionsModelMutationVariables
  >(ReviewPrepublicationTestResultsForEmissionsModelDocument);
}
export const SaveEmissionsModelDraftDocument = gql`
    mutation SaveEmissionsModelDraft($input: SaveEmissionsModelDraftInput!) {
  saveEmissionsModelDraft(input: $input) {
    __typename
    ... on SaveEmissionsModelDraftPayloadInvalid {
      validationErrors {
        msg
        path
        severity
      }
    }
    ... on SaveEmissionsModelDraftPayloadValid {
      emissionsModelVersionRecord {
        ...EmissionsModelVersionFieldsForEditor
      }
      parameterResolverRecord {
        id
      }
      matchers {
        ...EmissionsModelMatcherFields
      }
    }
  }
}
    ${EmissionsModelVersionFieldsForEditorFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}`;

export function useSaveEmissionsModelDraftMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveEmissionsModelDraftMutation,
    GQTypes.GQSaveEmissionsModelDraftMutationVariables
  >(SaveEmissionsModelDraftDocument);
}
export const TypeAheadRefDataSourceDocument = gql`
    query TypeAheadRefDataSource($q: String!, $orgId: ID) {
  searchReferenceDataSourceName(q: $q, orgId: $orgId) {
    ...ReferenceDataSourceFieldsForPicker
  }
}
    ${ReferenceDataSourceFieldsForPickerFragmentDoc}`;

export function useTypeAheadRefDataSourceQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTypeAheadRefDataSourceQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTypeAheadRefDataSourceQuery,
    GQTypes.GQTypeAheadRefDataSourceQueryVariables
  >({ query: TypeAheadRefDataSourceDocument, ...options });
}
export const GetReferenceDataSourceByNameDocument = gql`
    query GetReferenceDataSourceByName($name: String!, $orgId: ID) {
  referenceDataSourceByName(name: $name) {
    ...ReferenceDataSourceFieldsForPicker
  }
}
    ${ReferenceDataSourceFieldsForPickerFragmentDoc}`;

export function useGetReferenceDataSourceByNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReferenceDataSourceByNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReferenceDataSourceByNameQuery,
    GQTypes.GQGetReferenceDataSourceByNameQueryVariables
  >({ query: GetReferenceDataSourceByNameDocument, ...options });
}
export const AutosaveEmissionsModelDocument = gql`
    mutation AutosaveEmissionsModel($input: AutosaveEmissionsModelInput!) {
  autosaveEmissionsModel(input: $input) {
    __typename
    ... on AutosaveEmissionsModelPayloadOk {
      emissionsModelActiveDraftRecord {
        id
        autosavedAt
      }
    }
    ... on AutosaveEmissionsModelPayloadBlocked {
      authorId
      authorName
      autosavedAt
    }
    ... on AutosaveEmissionsModelPayloadError {
      error {
        message
        code
        errorType
        details
      }
    }
  }
}
    `;

export function useAutosaveEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQAutosaveEmissionsModelMutation,
    GQTypes.GQAutosaveEmissionsModelMutationVariables
  >(AutosaveEmissionsModelDocument);
}
export const ReleaseEmissionsModelActiveDraftDocument = gql`
    mutation ReleaseEmissionsModelActiveDraft($input: ReleaseEmissionsModelActiveDraftInput!) {
  releaseEmissionsModelActiveDraft(input: $input) {
    __typename
    ... on ReleaseEmissionsModelActiveDraftPayloadSuccess {
      emissionsModelActiveDraftRecord {
        id
        isCheckedOut
        autosavedAt
        releasedAt
      }
    }
    ... on ReleaseEmissionsModelActiveDraftPayloadNonOp {
      message
    }
  }
}
    `;

export function useReleaseEmissionsModelActiveDraftMutation() {
  return Urql.useMutation<
    GQTypes.GQReleaseEmissionsModelActiveDraftMutation,
    GQTypes.GQReleaseEmissionsModelActiveDraftMutationVariables
  >(ReleaseEmissionsModelActiveDraftDocument);
}
export const SlugSchemasDocument = gql`
    query SlugSchemas($slugs: [String!]!) {
  referenceDataVersionsBySlugs(slugs: $slugs) {
    slug
    referenceDataVersion {
      id
      latestSchema {
        id
        schemaJson
      }
    }
  }
}
    `;

export function useSlugSchemasQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQSlugSchemasQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQSlugSchemasQuery,
    GQTypes.GQSlugSchemasQueryVariables
  >({ query: SlugSchemasDocument, ...options });
}
export const AddEmissionsModelToOrgDocument = gql`
    mutation AddEmissionsModelToOrg($input: AddEmissionsModelToOrgInput!) {
  addEmissionsModelToOrg(input: $input) {
    emissionsModelStable {
      id
      org {
        id
        name
      }
    }
  }
}
    `;

export function useAddEmissionsModelToOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQAddEmissionsModelToOrgMutation,
    GQTypes.GQAddEmissionsModelToOrgMutationVariables
  >(AddEmissionsModelToOrgDocument);
}
export const ForkEmissionsModelDocument = gql`
    mutation ForkEmissionsModel($input: ForkEmissionsModelInput!) {
  forkEmissionsModel(input: $input) {
    emissionsModelStableRecord {
      id
    }
  }
}
    `;

export function useForkEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQForkEmissionsModelMutation,
    GQTypes.GQForkEmissionsModelMutationVariables
  >(ForkEmissionsModelDocument);
}
export const SetEmissionsModelIsArchivedDocument = gql`
    mutation SetEmissionsModelIsArchived($input: SetEmissionsModelIsArchivedInput!) {
  setEmissionsModelIsArchived(input: $input) {
    emissionsModelStableRecord {
      id
      isArchived
    }
  }
}
    `;

export function useSetEmissionsModelIsArchivedMutation() {
  return Urql.useMutation<
    GQTypes.GQSetEmissionsModelIsArchivedMutation,
    GQTypes.GQSetEmissionsModelIsArchivedMutationVariables
  >(SetEmissionsModelIsArchivedDocument);
}
export const GetEmissionsModelLibraryForStableModelDocument = gql`
    query GetEmissionsModelLibraryForStableModel($id: ID!) {
  emissionsModelLibraryForStableModel(id: $id) {
    id
    description
    title
    isArchived
    kind
    org {
      id
      name
    }
    latestPublishedVersion {
      id
      createdAt
      author {
        id
        name
      }
      kind
      changelog
    }
  }
}
    `;

export function useGetEmissionsModelLibraryForStableModelQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelLibraryForStableModelQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelLibraryForStableModelQuery,
    GQTypes.GQGetEmissionsModelLibraryForStableModelQueryVariables
  >({ query: GetEmissionsModelLibraryForStableModelDocument, ...options });
}
export const BusinessActivityTypeVersionNamesForVersionIdsDocument = gql`
    query BusinessActivityTypeVersionNamesForVersionIds($ids: [ID!]!) {
  businessActivityTypeVersionsByIds(ids: $ids) {
    id
    name
  }
}
    `;

export function useBusinessActivityTypeVersionNamesForVersionIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeVersionNamesForVersionIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeVersionNamesForVersionIdsQuery,
    GQTypes.GQBusinessActivityTypeVersionNamesForVersionIdsQueryVariables
  >({
    query: BusinessActivityTypeVersionNamesForVersionIdsDocument,
    ...options,
  });
}
export const GetEmissionsModelForkRelationshipsDocument = gql`
    query GetEmissionsModelForkRelationships($stableId: ID!) {
  emissionsModelForkRelationships(stableId: $stableId) {
    upstreamEntry {
      id
      forkedStableId
      forkedStable {
        id
        title
      }
    }
    downstreamEntries {
      id
      newStableId
      newStable {
        id
        title
      }
    }
  }
}
    `;

export function useGetEmissionsModelForkRelationshipsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelForkRelationshipsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelForkRelationshipsQuery,
    GQTypes.GQGetEmissionsModelForkRelationshipsQueryVariables
  >({ query: GetEmissionsModelForkRelationshipsDocument, ...options });
}
export const StartPublishingEmissionsModelDocument = gql`
    mutation StartPublishingEmissionsModel($input: StartPublishingEmissionsModelInput!) {
  startPublishingEmissionsModel(input: $input) {
    __typename
    ... on StartPublishingEmissionsModelPayloadInvalid {
      validationErrors {
        msg
        path
      }
    }
    ... on StartPublishingEmissionsModelPayloadValid {
      emissionsModelVersionRecord {
        ...EmissionsModelVersionFieldsForEditor
      }
      parameterResolverRecord {
        id
      }
      matchers {
        ...EmissionsModelMatcherFields
      }
    }
    ... on StartPublishingEmissionsModelPayloadError {
      error {
        message
        code
        stackTrace
        errorType
        details
      }
    }
  }
}
    ${EmissionsModelVersionFieldsForEditorFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}`;

export function useStartPublishingEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQStartPublishingEmissionsModelMutation,
    GQTypes.GQStartPublishingEmissionsModelMutationVariables
  >(StartPublishingEmissionsModelDocument);
}
export const GetEmissionsModelVersionHistoryDocument = gql`
    query GetEmissionsModelVersionHistory($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  emissionsModelStableVersionHistory(
    id: $id
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        createdAt
        changelog
        kind
        author {
          id
          name
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

export function useGetEmissionsModelVersionHistoryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelVersionHistoryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelVersionHistoryQuery,
    GQTypes.GQGetEmissionsModelVersionHistoryQueryVariables
  >({ query: GetEmissionsModelVersionHistoryDocument, ...options });
}
export const GetEmissionsModelVersionJsonDocument = gql`
    query GetEmissionsModelVersionJson($id: ID!) {
  emissionsModelVersion(id: $id) {
    id
    emissionsModelJson
    parameterResolverJson
  }
}
    `;

export function useGetEmissionsModelVersionJsonQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelVersionJsonQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelVersionJsonQuery,
    GQTypes.GQGetEmissionsModelVersionJsonQueryVariables
  >({ query: GetEmissionsModelVersionJsonDocument, ...options });
}
export const GetEmissionsModelAncestryDocument = gql`
    query GetEmissionsModelAncestry($emissionsModelStableId: ID!) {
  emissionsModelStable(id: $emissionsModelStableId) {
    id
    ancestry {
      ancestors {
        ...EmissionsModelAncestorFields
      }
    }
  }
}
    ${EmissionsModelAncestorFieldsFragmentDoc}`;

export function useGetEmissionsModelAncestryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelAncestryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelAncestryQuery,
    GQTypes.GQGetEmissionsModelAncestryQueryVariables
  >({ query: GetEmissionsModelAncestryDocument, ...options });
}
export const EmissionsModelEditorEvalDocument = gql`
    query EmissionsModelEditorEval($input: EmissionsModelEditorEvalInput!) {
  emissionsModelEditorEval(input: $input) {
    resultJson
    warnings
    emissionsEquations {
      emissionsVariableName
      traceDetails {
        referenceValueDetails
        emissionsModelVariableDetails
        bartValueDetails
        emissionsModelVersionId
        emissionsModelEvaluationId
      }
      trace
    }
  }
}
    `;

export function useEmissionsModelEditorEvalQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelEditorEvalQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelEditorEvalQuery,
    GQTypes.GQEmissionsModelEditorEvalQueryVariables
  >({ query: EmissionsModelEditorEvalDocument, ...options });
}
export const StartPrepublicationTestsForEmissionsModelDocument = gql`
    mutation StartPrepublicationTestsForEmissionsModel($input: StartPrepublicationTestsForEmissionsModelInput!) {
  startPrepublicationTestsForEmissionsModel(input: $input) {
    workflowId
  }
}
    `;

export function useStartPrepublicationTestsForEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQStartPrepublicationTestsForEmissionsModelMutation,
    GQTypes.GQStartPrepublicationTestsForEmissionsModelMutationVariables
  >(StartPrepublicationTestsForEmissionsModelDocument);
}
export const EmissionsModelReleasesForPickerDocument = gql`
    query EmissionsModelReleasesForPicker($orgId: ID, $businessActivityTypeNames: [String!], $includeAllOrgs: Boolean!, $includeAlphas: Boolean) {
  emissionsModelReleases(
    orgId: $orgId
    businessActivityTypeNames: $businessActivityTypeNames
    includeAllOrgs: $includeAllOrgs
    includeAlphas: $includeAlphas
  ) {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useEmissionsModelReleasesForPickerQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleasesForPickerQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleasesForPickerQuery,
    GQTypes.GQEmissionsModelReleasesForPickerQueryVariables
  >({ query: EmissionsModelReleasesForPickerDocument, ...options });
}
export const GetAllGlobalFootprintTagNamesDocument = gql`
    query GetAllGlobalFootprintTagNames {
  globalFootprintTags(latestOfEachTag: true) {
    id
    tagName
    recordEndAt
  }
}
    `;

export function useGetAllGlobalFootprintTagNamesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllGlobalFootprintTagNamesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllGlobalFootprintTagNamesQuery,
    GQTypes.GQGetAllGlobalFootprintTagNamesQueryVariables
  >({ query: GetAllGlobalFootprintTagNamesDocument, ...options });
}
export const GetGlobalFootprintTagVersionsForTagNameDocument = gql`
    query GetGlobalFootprintTagVersionsForTagName($tagName: String) {
  globalFootprintTags(includeDeleted: true, tagName: $tagName) {
    id
    tagName
    description
    createdAt
    recordStartAt
    rulesList
    watershedEditor {
      id
      name
    }
  }
}
    `;

export function useGetGlobalFootprintTagVersionsForTagNameQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetGlobalFootprintTagVersionsForTagNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetGlobalFootprintTagVersionsForTagNameQuery,
    GQTypes.GQGetGlobalFootprintTagVersionsForTagNameQueryVariables
  >({ query: GetGlobalFootprintTagVersionsForTagNameDocument, ...options });
}
export const GetManyEmissionsModelStablesDocument = gql`
    query getManyEmissionsModelStables($ids: [ID!]!) {
  emissionsModelsStableByIds(ids: $ids) {
    id
    title
    description
  }
}
    `;

export function useGetManyEmissionsModelStablesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetManyEmissionsModelStablesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetManyEmissionsModelStablesQuery,
    GQTypes.GQGetManyEmissionsModelStablesQueryVariables
  >({ query: GetManyEmissionsModelStablesDocument, ...options });
}
export const ReviewPrepublicationTestResultsForReleaseDocument = gql`
    mutation ReviewPrepublicationTestResultsForRelease($input: ReviewPrepublicationTestResultsInput!) {
  reviewResult: reviewPrepublicationTestResultsForRelease(input: $input) {
    outcome
    errorCode
    publishedRelease {
      id
    }
  }
}
    `;

export function useReviewPrepublicationTestResultsForReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQReviewPrepublicationTestResultsForReleaseMutation,
    GQTypes.GQReviewPrepublicationTestResultsForReleaseMutationVariables
  >(ReviewPrepublicationTestResultsForReleaseDocument);
}
export const StartPrepublicationTestsForReleaseDocument = gql`
    mutation StartPrepublicationTestsForRelease($input: StartPrepublicationTestsForReleaseInput!) {
  startPrepublicationTestsForRelease(input: $input) {
    workflowId
  }
}
    `;

export function useStartPrepublicationTestsForReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQStartPrepublicationTestsForReleaseMutation,
    GQTypes.GQStartPrepublicationTestsForReleaseMutationVariables
  >(StartPrepublicationTestsForReleaseDocument);
}
export const CreateEmissionsModelReleaseDocument = gql`
    mutation CreateEmissionsModelRelease($input: CreateEmissionsModelReleaseInput!) {
  createEmissionsModelRelease(input: $input) {
    emissionsModelRelease {
      id
    }
  }
}
    `;

export function useCreateEmissionsModelReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEmissionsModelReleaseMutation,
    GQTypes.GQCreateEmissionsModelReleaseMutationVariables
  >(CreateEmissionsModelReleaseDocument);
}
export const UpdateEmissionsModelReleaseMetadataDocument = gql`
    mutation UpdateEmissionsModelReleaseMetadata($input: UpdateEmissionsModelReleaseMetadataInput!) {
  updateEmissionsModelReleaseMetadata(input: $input) {
    emissionsModelRelease {
      ...EmissionsModelReleaseMetadata
    }
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useUpdateEmissionsModelReleaseMetadataMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEmissionsModelReleaseMetadataMutation,
    GQTypes.GQUpdateEmissionsModelReleaseMetadataMutationVariables
  >(UpdateEmissionsModelReleaseMetadataDocument);
}
export const DeleteEmissionsModelReleaseDocument = gql`
    mutation DeleteEmissionsModelRelease($input: IdInput!) {
  deleteEmissionsModelRelease(input: $input) {
    id
  }
}
    `;

export function useDeleteEmissionsModelReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEmissionsModelReleaseMutation,
    GQTypes.GQDeleteEmissionsModelReleaseMutationVariables
  >(DeleteEmissionsModelReleaseDocument);
}
export const UpdateEmissionsModelReleaseLifecyclePhaseDocument = gql`
    mutation UpdateEmissionsModelReleaseLifecyclePhase($input: UpdateEmissionsModelReleaseLifecyclePhaseInput!) {
  updateEmissionsModelReleaseLifecyclePhase(input: $input) {
    emissionsModelRelease {
      ...EmissionsModelReleaseDetailsPage
    }
  }
}
    ${EmissionsModelReleaseDetailsPageFragmentDoc}`;

export function useUpdateEmissionsModelReleaseLifecyclePhaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEmissionsModelReleaseLifecyclePhaseMutation,
    GQTypes.GQUpdateEmissionsModelReleaseLifecyclePhaseMutationVariables
  >(UpdateEmissionsModelReleaseLifecyclePhaseDocument);
}
export const LinkEmissionsModelsToReleaseDocument = gql`
    mutation LinkEmissionsModelsToRelease($input: LinkEmissionsModelsToReleaseInput!) {
  linkEmissionsModelsToRelease(input: $input) {
    emissionsModelRelease {
      id
      emissionsModels {
        ...EmissionsModelStableFieldsForReleases
      }
    }
  }
}
    ${EmissionsModelStableFieldsForReleasesFragmentDoc}`;

export function useLinkEmissionsModelsToReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQLinkEmissionsModelsToReleaseMutation,
    GQTypes.GQLinkEmissionsModelsToReleaseMutationVariables
  >(LinkEmissionsModelsToReleaseDocument);
}
export const UnlinkEmissionsModelsFromReleaseDocument = gql`
    mutation UnlinkEmissionsModelsFromRelease($input: UnlinkEmissionsModelsFromReleaseInput!) {
  unlinkEmissionsModelsFromRelease(input: $input) {
    emissionsModelRelease {
      id
      emissionsModels {
        ...EmissionsModelStableFieldsForReleases
      }
    }
  }
}
    ${EmissionsModelStableFieldsForReleasesFragmentDoc}`;

export function useUnlinkEmissionsModelsFromReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUnlinkEmissionsModelsFromReleaseMutation,
    GQTypes.GQUnlinkEmissionsModelsFromReleaseMutationVariables
  >(UnlinkEmissionsModelsFromReleaseDocument);
}
export const LinkGlobalFootprintTagsToReleaseDocument = gql`
    mutation LinkGlobalFootprintTagsToRelease($input: LinkGlobalFootprintTagsToReleaseInput!) {
  linkGlobalFootprintTagsToRelease(input: $input) {
    emissionsModelRelease {
      id
      globalFootprintTags {
        ...GlobalFootprintTagFieldsForReleases
      }
    }
  }
}
    ${GlobalFootprintTagFieldsForReleasesFragmentDoc}`;

export function useLinkGlobalFootprintTagsToReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQLinkGlobalFootprintTagsToReleaseMutation,
    GQTypes.GQLinkGlobalFootprintTagsToReleaseMutationVariables
  >(LinkGlobalFootprintTagsToReleaseDocument);
}
export const UnlinkGlobalFootprintTagsFromReleaseDocument = gql`
    mutation UnlinkGlobalFootprintTagsFromRelease($input: UnlinkGlobalFootprintTagsFromReleaseInput!) {
  unlinkGlobalFootprintTagsFromRelease(input: $input) {
    emissionsModelRelease {
      id
      globalFootprintTags {
        ...GlobalFootprintTagFieldsForReleases
      }
    }
  }
}
    ${GlobalFootprintTagFieldsForReleasesFragmentDoc}`;

export function useUnlinkGlobalFootprintTagsFromReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUnlinkGlobalFootprintTagsFromReleaseMutation,
    GQTypes.GQUnlinkGlobalFootprintTagsFromReleaseMutationVariables
  >(UnlinkGlobalFootprintTagsFromReleaseDocument);
}
export const AddOrgAccessToReleaseDocument = gql`
    mutation AddOrgAccessToRelease($input: AddOrgAccessToReleaseInput!) {
  addOrgAccessToRelease(input: $input) {
    emissionsModelRelease {
      id
      orgAccess {
        ...EmissionsModelReleaseOrgAccessFields
      }
    }
  }
}
    ${EmissionsModelReleaseOrgAccessFieldsFragmentDoc}`;

export function useAddOrgAccessToReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQAddOrgAccessToReleaseMutation,
    GQTypes.GQAddOrgAccessToReleaseMutationVariables
  >(AddOrgAccessToReleaseDocument);
}
export const ConvertGlobalReleaseToOrgGatedDocument = gql`
    mutation ConvertGlobalReleaseToOrgGated($input: EmissionsModelReleaseIdInput!) {
  convertGlobalReleaseToOrgGated(input: $input) {
    emissionsModelRelease {
      id
      isOrgGated
    }
  }
}
    `;

export function useConvertGlobalReleaseToOrgGatedMutation() {
  return Urql.useMutation<
    GQTypes.GQConvertGlobalReleaseToOrgGatedMutation,
    GQTypes.GQConvertGlobalReleaseToOrgGatedMutationVariables
  >(ConvertGlobalReleaseToOrgGatedDocument);
}
export const ConvertOrgGatedReleaseToGlobalDocument = gql`
    mutation ConvertOrgGatedReleaseToGlobal($input: EmissionsModelReleaseIdInput!) {
  convertOrgGatedReleaseToGlobal(input: $input) {
    emissionsModelRelease {
      id
      isOrgGated
    }
  }
}
    `;

export function useConvertOrgGatedReleaseToGlobalMutation() {
  return Urql.useMutation<
    GQTypes.GQConvertOrgGatedReleaseToGlobalMutation,
    GQTypes.GQConvertOrgGatedReleaseToGlobalMutationVariables
  >(ConvertOrgGatedReleaseToGlobalDocument);
}
export const EmissionsModelReleaseDetailsDocument = gql`
    query EmissionsModelReleaseDetails($releaseId: ID!) {
  emissionsModelRelease(id: $releaseId) {
    ...EmissionsModelReleaseDetailsPage
  }
}
    ${EmissionsModelReleaseDetailsPageFragmentDoc}`;

export function useEmissionsModelReleaseDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleaseDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleaseDetailsQuery,
    GQTypes.GQEmissionsModelReleaseDetailsQueryVariables
  >({ query: EmissionsModelReleaseDetailsDocument, ...options });
}
export const EmissionsModelReleasesDocument = gql`
    query EmissionsModelReleases($orgId: ID, $batNames: [String!], $includeAllOrgs: Boolean) {
  emissionsModelReleases(
    orgId: $orgId
    businessActivityTypeNames: $batNames
    includeAllOrgs: $includeAllOrgs
  ) {
    ...EmissionsModelReleaseListEntry
  }
}
    ${EmissionsModelReleaseListEntryFragmentDoc}`;

export function useEmissionsModelReleasesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleasesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleasesQuery,
    GQTypes.GQEmissionsModelReleasesQueryVariables
  >({ query: EmissionsModelReleasesDocument, ...options });
}
export const EmissionsModelsStableForBusinessActivityTypeDocument = gql`
    query EmissionsModelsStableForBusinessActivityType($businessActivityTypeName: String!, $orgId: ID) {
  emissionsModelsStableForBusinessActivityType(
    businessActvityTypeName: $businessActivityTypeName
    orgId: $orgId
  ) {
    ...EmissionsModelStableFieldsForReleases
  }
}
    ${EmissionsModelStableFieldsForReleasesFragmentDoc}`;

export function useEmissionsModelsStableForBusinessActivityTypeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelsStableForBusinessActivityTypeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelsStableForBusinessActivityTypeQuery,
    GQTypes.GQEmissionsModelsStableForBusinessActivityTypeQueryVariables
  >({
    query: EmissionsModelsStableForBusinessActivityTypeDocument,
    ...options,
  });
}
export const EmissionsModelsStablesForReleasesDocument = gql`
    query EmissionsModelsStablesForReleases($releaseIds: [ID!]!, $excludeOrgSpecificModels: Boolean = true) {
  emissionsModelsStablesForReleases(
    releaseIds: $releaseIds
    excludeOrgSpecificModels: $excludeOrgSpecificModels
  ) {
    ...EmissionsModelStableFieldsForReleases
  }
}
    ${EmissionsModelStableFieldsForReleasesFragmentDoc}`;

export function useEmissionsModelsStablesForReleasesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelsStablesForReleasesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelsStablesForReleasesQuery,
    GQTypes.GQEmissionsModelsStablesForReleasesQueryVariables
  >({ query: EmissionsModelsStablesForReleasesDocument, ...options });
}
export const EmissionsModelReleasesForEmissionsModelStableDocument = gql`
    query EmissionsModelReleasesForEmissionsModelStable($stableId: ID!) {
  emissionsModelReleasesForEmissionsModelStable(stableId: $stableId) {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useEmissionsModelReleasesForEmissionsModelStableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleasesForEmissionsModelStableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleasesForEmissionsModelStableQuery,
    GQTypes.GQEmissionsModelReleasesForEmissionsModelStableQueryVariables
  >({
    query: EmissionsModelReleasesForEmissionsModelStableDocument,
    ...options,
  });
}
export const EmissionsModelReleasesForEmissionsModelsStableDocument = gql`
    query EmissionsModelReleasesForEmissionsModelsStable($stableIds: [ID!]!) {
  emissionsModelReleasesForEmissionsModelsStable(stableIds: $stableIds) {
    models {
      id
      title
      isArchived
    }
    release {
      ...EmissionsModelReleaseMetadata
    }
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useEmissionsModelReleasesForEmissionsModelsStableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleasesForEmissionsModelsStableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleasesForEmissionsModelsStableQuery,
    GQTypes.GQEmissionsModelReleasesForEmissionsModelsStableQueryVariables
  >({
    query: EmissionsModelReleasesForEmissionsModelsStableDocument,
    ...options,
  });
}
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    org {
      id
    }
  }
}
    `;

export function useCreateOrganizationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrganizationMutation,
    GQTypes.GQCreateOrganizationMutationVariables
  >(CreateOrganizationDocument);
}
export const OrganizationListDocument = gql`
    query OrganizationList($first: Int, $after: String, $last: Int, $before: String, $demoTestOnly: Boolean, $search: String) {
  organizations(
    after: $after
    before: $before
    first: $first
    last: $last
    demoTestOnly: $demoTestOnly
    search: $search
  ) {
    edges {
      node {
        __typename
        ...OrganizationListPageOrganization
      }
    }
    pageInfo {
      ...PageInfoWithCount
    }
  }
}
    ${OrganizationListPageOrganizationFragmentDoc}
${PageInfoWithCountFragmentDoc}`;

export function useOrganizationListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationListQuery,
    GQTypes.GQOrganizationListQueryVariables
  >({ query: OrganizationListDocument, ...options });
}
export const GetFootprintTagDocument = gql`
    query GetFootprintTag($id: ID!) {
  footprintTag(id: $id) {
    id
    tagName
    description
    rulesList
  }
}
    `;

export function useGetFootprintTagQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintTagQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintTagQuery,
    GQTypes.GQGetFootprintTagQueryVariables
  >({ query: GetFootprintTagDocument, ...options });
}
export const ApplyTagsToPipelineResultDocument = gql`
    mutation ApplyTagsToPipelineResult($input: ApplyTagsToPipelineResultInput!) {
  applyTagsToPipelineResult(input: $input) {
    taggedResultParquetSignedUrl
    distinctCount
  }
}
    `;

export function useApplyTagsToPipelineResultMutation() {
  return Urql.useMutation<
    GQTypes.GQApplyTagsToPipelineResultMutation,
    GQTypes.GQApplyTagsToPipelineResultMutationVariables
  >(ApplyTagsToPipelineResultDocument);
}
export const SaveFootprintTagDocument = gql`
    mutation SaveFootprintTag($input: SaveFootprintTagInput!) {
  saveFootprintTag(input: $input) {
    footprintTag {
      id
    }
  }
}
    `;

export function useSaveFootprintTagMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveFootprintTagMutation,
    GQTypes.GQSaveFootprintTagMutationVariables
  >(SaveFootprintTagDocument);
}
export const ApplyGlobalTagToTestDataDocument = gql`
    mutation ApplyGlobalTagToTestData($input: ApplyGlobalTagToTestDataInput!) {
  applyGlobalTagToTestData(input: $input) {
    taggedResultParquetSignedUrl
  }
}
    `;

export function useApplyGlobalTagToTestDataMutation() {
  return Urql.useMutation<
    GQTypes.GQApplyGlobalTagToTestDataMutation,
    GQTypes.GQApplyGlobalTagToTestDataMutationVariables
  >(ApplyGlobalTagToTestDataDocument);
}
export const SaveGlobalFootprintTagDocument = gql`
    mutation SaveGlobalFootprintTag($input: SaveGlobalFootprintTagInput!) {
  saveGlobalFootprintTag(input: $input) {
    footprintTag {
      id
    }
  }
}
    `;

export function useSaveGlobalFootprintTagMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveGlobalFootprintTagMutation,
    GQTypes.GQSaveGlobalFootprintTagMutationVariables
  >(SaveGlobalFootprintTagDocument);
}
export const GetGlobalFootprintTagsDocument = gql`
    query GetGlobalFootprintTags($tagName: String!) {
  globalFootprintTags(tagName: $tagName, includeDeleted: true) {
    ...GlobalFootprintTagsForGlobalTagsEditor
  }
}
    ${GlobalFootprintTagsForGlobalTagsEditorFragmentDoc}`;

export function useGetGlobalFootprintTagsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetGlobalFootprintTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetGlobalFootprintTagsQuery,
    GQTypes.GQGetGlobalFootprintTagsQueryVariables
  >({ query: GetGlobalFootprintTagsDocument, ...options });
}
export const GenerateTokenDocument = gql`
    mutation GenerateToken($input: GetNewDecryptedTokenInput!) {
  getNewDecryptedToken(input: $input) {
    decryptedToken
  }
}
    `;

export function useGenerateTokenMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateTokenMutation,
    GQTypes.GQGenerateTokenMutationVariables
  >(GenerateTokenDocument);
}
export const UpdateFinancialsReviewItemDocument = gql`
    mutation UpdateFinancialsReviewItem($input: UpdateFinancialsReviewItemInput!) {
  updateFinancialsReviewItem(input: $input) {
    financialsReviewItem {
      ...FinancialsReviewItemAdminDialog
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function useUpdateFinancialsReviewItemMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinancialsReviewItemMutation,
    GQTypes.GQUpdateFinancialsReviewItemMutationVariables
  >(UpdateFinancialsReviewItemDocument);
}
export const CreateFinancialsReviewItemDraftsDocument = gql`
    mutation CreateFinancialsReviewItemDrafts($input: CreateFinancialsReviewItemDraftsInput!) {
  createFinancialsReviewItemDrafts(input: $input) {
    financialsReviewItems {
      edges {
        node {
          ...FinancialsReviewItemAdminDialog
        }
      }
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function useCreateFinancialsReviewItemDraftsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinancialsReviewItemDraftsMutation,
    GQTypes.GQCreateFinancialsReviewItemDraftsMutationVariables
  >(CreateFinancialsReviewItemDraftsDocument);
}
export const ReviewItemsDialogDocument = gql`
    query ReviewItemsDialog($orgId: ID!) {
  financialsReviewItemsAdmin(orgId: $orgId) {
    edges {
      node {
        ...FinancialsReviewItemAdminDialog
      }
    }
  }
  organization(id: $orgId) {
    id
    measurementProjects {
      id
      name
      completedAt
      status
      coverageEndDate
      deadline
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function useReviewItemsDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReviewItemsDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReviewItemsDialogQuery,
    GQTypes.GQReviewItemsDialogQueryVariables
  >({ query: ReviewItemsDialogDocument, ...options });
}
export const PublishFinancialsReviewItemsDocument = gql`
    mutation PublishFinancialsReviewItems($input: PublishFinancialsReviewItemsInput!) {
  publishFinancialsReviewItems(input: $input) {
    financialsReviewItems {
      edges {
        node {
          ...FinancialsReviewItemAdminDialog
        }
      }
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function usePublishFinancialsReviewItemsMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishFinancialsReviewItemsMutation,
    GQTypes.GQPublishFinancialsReviewItemsMutationVariables
  >(PublishFinancialsReviewItemsDocument);
}
export const PreviewFinancialsReviewEmailsDocument = gql`
    mutation PreviewFinancialsReviewEmails($input: PreviewFinancialsReviewEmailsInput!) {
  previewFinancialsReviewEmails(input: $input) {
    emails {
      ...EmailContentWithUserRecipientFields
    }
  }
}
    ${EmailContentWithUserRecipientFieldsFragmentDoc}`;

export function usePreviewFinancialsReviewEmailsMutation() {
  return Urql.useMutation<
    GQTypes.GQPreviewFinancialsReviewEmailsMutation,
    GQTypes.GQPreviewFinancialsReviewEmailsMutationVariables
  >(PreviewFinancialsReviewEmailsDocument);
}
export const DeleteFinancialsReviewItemDocument = gql`
    mutation DeleteFinancialsReviewItem($input: DeleteFinancialsReviewItemInput!) {
  deleteFinancialsReviewItem(input: $input) {
    id
  }
}
    `;

export function useDeleteFinancialsReviewItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinancialsReviewItemMutation,
    GQTypes.GQDeleteFinancialsReviewItemMutationVariables
  >(DeleteFinancialsReviewItemDocument);
}
export const LogLocalMeasurementStateDocument = gql`
    mutation LogLocalMeasurementState($orgId: String!, $tag: String!, $data: String!) {
  logLocalMeasurementState(orgId: $orgId, tag: $tag, data: $data)
}
    `;

export function useLogLocalMeasurementStateMutation() {
  return Urql.useMutation<
    GQTypes.GQLogLocalMeasurementStateMutation,
    GQTypes.GQLogLocalMeasurementStateMutationVariables
  >(LogLocalMeasurementStateDocument);
}
export const MeasurementContextPageDocument = gql`
    query MeasurementContextPage($id: ID!) {
  measurementContextItems(orgId: $id) {
    edges {
      node {
        ...MeasurementContextItemFieldsForList
      }
    }
  }
}
    ${MeasurementContextItemFieldsForListFragmentDoc}`;

export function useMeasurementContextPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementContextPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementContextPageQuery,
    GQTypes.GQMeasurementContextPageQueryVariables
  >({ query: MeasurementContextPageDocument, ...options });
}
export const CreateMeasurementContextItemDocument = gql`
    mutation CreateMeasurementContextItem($input: CreateMeasurementContextItemInput!) {
  createMeasurementContextItem(input: $input) {
    measurementContextItem {
      ...MeasurementContextItemFieldsForList
    }
  }
}
    ${MeasurementContextItemFieldsForListFragmentDoc}`;

export function useCreateMeasurementContextItemMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementContextItemMutation,
    GQTypes.GQCreateMeasurementContextItemMutationVariables
  >(CreateMeasurementContextItemDocument);
}
export const UpdateMeasurementContextItemDocument = gql`
    mutation UpdateMeasurementContextItem($input: UpdateMeasurementContextItemInput!) {
  updateMeasurementContextItem(input: $input) {
    measurementContextItem {
      ...MeasurementContextItemFieldsForList
    }
  }
}
    ${MeasurementContextItemFieldsForListFragmentDoc}`;

export function useUpdateMeasurementContextItemMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementContextItemMutation,
    GQTypes.GQUpdateMeasurementContextItemMutationVariables
  >(UpdateMeasurementContextItemDocument);
}
export const DeleteMeasurementContextItemDocument = gql`
    mutation DeleteMeasurementContextItem($input: DeleteMeasurementContextItemInput!) {
  deleteMeasurementContextItem(input: $input) {
    measurementContextItem {
      ...MeasurementContextItemFieldsForList
    }
  }
}
    ${MeasurementContextItemFieldsForListFragmentDoc}`;

export function useDeleteMeasurementContextItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementContextItemMutation,
    GQTypes.GQDeleteMeasurementContextItemMutationVariables
  >(DeleteMeasurementContextItemDocument);
}
export const GetMeasurementContextRelationsForSearchDocument = gql`
    query GetMeasurementContextRelationsForSearch($orgId: ID!) {
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...ContextRelationFieldsForSearch
      }
    }
  }
  files(orgId: $orgId, filters: {categories: [User]}) {
    edges {
      node {
        ...ContextRelationFieldsForSearch
      }
    }
  }
  financialsReviewItemsAdmin(orgId: $orgId) {
    edges {
      node {
        ...ContextRelationFieldsForSearch
        initiallyIncluded
        prompt
        initialIndustryClassification {
          id
        }
      }
    }
  }
  organization(id: $orgId) {
    id
    datasets {
      ...ContextRelationFieldsForSearch
      datasources {
        ...ContextRelationFieldsForSearch
      }
    }
    dataIssues {
      ...ContextRelationFieldsForSearch
      linkedObject {
        ...DataIssueLinkedObjectFields
      }
    }
    measurementProjects {
      ...ContextRelationFieldsForSearch
      coverageStartDate
      measurementVerificationItems {
        edges {
          node {
            id
            questions {
              edges {
                node {
                  ...ContextRelationFieldsForSearch
                }
              }
            }
          }
        }
      }
      userUploadTasks {
        edges {
          node {
            id
            datasource {
              id
              name
              dataset {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    ${ContextRelationFieldsForSearchFragmentDoc}
${DataIssueLinkedObjectFieldsFragmentDoc}`;

export function useGetMeasurementContextRelationsForSearchQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMeasurementContextRelationsForSearchQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMeasurementContextRelationsForSearchQuery,
    GQTypes.GQGetMeasurementContextRelationsForSearchQueryVariables
  >({ query: GetMeasurementContextRelationsForSearchDocument, ...options });
}
export const GetPrepublicationMeasurementTestResultsDocument = gql`
    query GetPrepublicationMeasurementTestResults($workflowId: ID!) {
  prepublicationMeasurementTestResults(workflowId: $workflowId) {
    measurementTestSuiteExecutions {
      ...PrepublicationTestSuiteExecutionFields
    }
    emissionsModelValidationErrors {
      msg
      path
      severity
    }
  }
}
    ${PrepublicationTestSuiteExecutionFieldsFragmentDoc}`;

export function useGetPrepublicationMeasurementTestResultsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPrepublicationMeasurementTestResultsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPrepublicationMeasurementTestResultsQuery,
    GQTypes.GQGetPrepublicationMeasurementTestResultsQueryVariables
  >({ query: GetPrepublicationMeasurementTestResultsDocument, ...options });
}
export const FootprintTestSuiteDefaultOrgDocument = gql`
    query FootprintTestSuiteDefaultOrg {
  footprintTestSuiteConfigDefaultOrgId
}
    `;

export function useFootprintTestSuiteDefaultOrgQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintTestSuiteDefaultOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintTestSuiteDefaultOrgQuery,
    GQTypes.GQFootprintTestSuiteDefaultOrgQueryVariables
  >({ query: FootprintTestSuiteDefaultOrgDocument, ...options });
}
export const SetFootprintTestSuiteDefaultOrgDocument = gql`
    mutation SetFootprintTestSuiteDefaultOrg($input: IdInput!) {
  setFootprintTestSuiteConfigDefaultOrgId(input: $input)
}
    `;

export function useSetFootprintTestSuiteDefaultOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQSetFootprintTestSuiteDefaultOrgMutation,
    GQTypes.GQSetFootprintTestSuiteDefaultOrgMutationVariables
  >(SetFootprintTestSuiteDefaultOrgDocument);
}
export const MethodologyTestSuiteValidationResultsDocument = gql`
    query MethodologyTestSuiteValidationResults($measurementTestSuiteId: ID!) {
  methodologyTestSuiteValidationResults(
    measurementTestSuiteId: $measurementTestSuiteId
  ) {
    id
    isValid
    warningMessage
  }
}
    `;

export function useMethodologyTestSuiteValidationResultsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyTestSuiteValidationResultsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyTestSuiteValidationResultsQuery,
    GQTypes.GQMethodologyTestSuiteValidationResultsQueryVariables
  >({ query: MethodologyTestSuiteValidationResultsDocument, ...options });
}
export const CreateMeasurementTestSuiteDocument = gql`
    mutation CreateMeasurementTestSuite($input: CreateMeasurementTestSuiteInput!) {
  createMeasurementTestSuite(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteListFields
    }
  }
}
    ${MeasurementTestSuiteListFieldsFragmentDoc}`;

export function useCreateMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementTestSuiteMutation,
    GQTypes.GQCreateMeasurementTestSuiteMutationVariables
  >(CreateMeasurementTestSuiteDocument);
}
export const UpdateMeasurementTestSuiteDocument = gql`
    mutation UpdateMeasurementTestSuite($input: UpdateMeasurementTestSuiteInput!) {
  updateMeasurementTestSuite(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}`;

export function useUpdateMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementTestSuiteMutation,
    GQTypes.GQUpdateMeasurementTestSuiteMutationVariables
  >(UpdateMeasurementTestSuiteDocument);
}
export const DeleteMeasurementTestSuiteDocument = gql`
    mutation DeleteMeasurementTestSuite($input: IdInput!) {
  deleteMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useDeleteMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementTestSuiteMutation,
    GQTypes.GQDeleteMeasurementTestSuiteMutationVariables
  >(DeleteMeasurementTestSuiteDocument);
}
export const UndeleteMeasurementTestSuiteDocument = gql`
    mutation UndeleteMeasurementTestSuite($input: IdInput!) {
  undeleteMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useUndeleteMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQUndeleteMeasurementTestSuiteMutation,
    GQTypes.GQUndeleteMeasurementTestSuiteMutationVariables
  >(UndeleteMeasurementTestSuiteDocument);
}
export const CreateMeasurementTestSuiteBartDocument = gql`
    mutation CreateMeasurementTestSuiteBart($input: CreateMeasurementTestSuiteBartInput!) {
  createMeasurementTestSuiteBart(input: $input) {
    measurementTestSuite {
      id
      measurementTestSuiteBarts {
        id
      }
    }
  }
}
    `;

export function useCreateMeasurementTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementTestSuiteBartMutation,
    GQTypes.GQCreateMeasurementTestSuiteBartMutationVariables
  >(CreateMeasurementTestSuiteBartDocument);
}
export const UpdateMeasurementTestSuiteBartDocument = gql`
    mutation UpdateMeasurementTestSuiteBart($input: UpdateMeasurementTestSuiteBartInput!) {
  updateMeasurementTestSuiteBart(input: $input) {
    measurementTestSuiteBart {
      ...MeasurementTestSuiteBartMetadataFields
    }
  }
}
    ${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}`;

export function useUpdateMeasurementTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementTestSuiteBartMutation,
    GQTypes.GQUpdateMeasurementTestSuiteBartMutationVariables
  >(UpdateMeasurementTestSuiteBartDocument);
}
export const ToggleMeasurementTestSuiteCriticalDocument = gql`
    mutation ToggleMeasurementTestSuiteCritical($measurementTestSuiteId: ID!) {
  toggleMeasurementTestSuiteCritical(
    measurementTestSuiteId: $measurementTestSuiteId
  ) {
    measurementTestSuite {
      id
      kind
    }
  }
}
    `;

export function useToggleMeasurementTestSuiteCriticalMutation() {
  return Urql.useMutation<
    GQTypes.GQToggleMeasurementTestSuiteCriticalMutation,
    GQTypes.GQToggleMeasurementTestSuiteCriticalMutationVariables
  >(ToggleMeasurementTestSuiteCriticalDocument);
}
export const DeleteMeasurementTestSuiteBartDocument = gql`
    mutation DeleteMeasurementTestSuiteBart($input: IdInput!) {
  deleteMeasurementTestSuiteBart(input: $input) {
    id
  }
}
    `;

export function useDeleteMeasurementTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementTestSuiteBartMutation,
    GQTypes.GQDeleteMeasurementTestSuiteBartMutationVariables
  >(DeleteMeasurementTestSuiteBartDocument);
}
export const AddTestRowsToTestSuiteBartDocument = gql`
    mutation AddTestRowsToTestSuiteBart($input: AddTestRowsToTestSuiteBartInput!) {
  addTestRowsToTestSuiteBart(input: $input) {
    measurementTestSuiteBart {
      ...MeasurementTestSuiteBartMetadataFields
      measurementTestRows {
        ...MeasurementTestRowFields
      }
    }
  }
}
    ${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}
${MeasurementTestRowFieldsFragmentDoc}`;

export function useAddTestRowsToTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQAddTestRowsToTestSuiteBartMutation,
    GQTypes.GQAddTestRowsToTestSuiteBartMutationVariables
  >(AddTestRowsToTestSuiteBartDocument);
}
export const UpdateTestRowsInTestSuiteBartDocument = gql`
    mutation UpdateTestRowsInTestSuiteBart($input: UpdateTestRowsInTestSuiteBartInput!) {
  updateTestRowsInTestSuiteBart(input: $input) {
    measurementTestSuiteBart {
      ...MeasurementTestSuiteBartMetadataFields
      measurementTestRows {
        ...MeasurementTestRowFields
      }
    }
  }
}
    ${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}
${MeasurementTestRowFieldsFragmentDoc}`;

export function useUpdateTestRowsInTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateTestRowsInTestSuiteBartMutation,
    GQTypes.GQUpdateTestRowsInTestSuiteBartMutationVariables
  >(UpdateTestRowsInTestSuiteBartDocument);
}
export const DeleteMeasurementTestRowsDocument = gql`
    mutation DeleteMeasurementTestRows($input: DeleteMeasurementTestRowsInput!) {
  deleteMeasurementTestRows(input: $input) {
    ids
  }
}
    `;

export function useDeleteMeasurementTestRowsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementTestRowsMutation,
    GQTypes.GQDeleteMeasurementTestRowsMutationVariables
  >(DeleteMeasurementTestRowsDocument);
}
export const AcceptMethodologyTestSuiteExecutionResultsDocument = gql`
    mutation AcceptMethodologyTestSuiteExecutionResults($input: AcceptMethodologyTestSuiteExecutionResultsInput!) {
  acceptMethodologyTestSuiteExecutionResults(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      latestMethodologyTestSuiteExecution {
        id
        status
        methodologyTestRowResults {
          id
          resultAccepted
        }
      }
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}`;

export function useAcceptMethodologyTestSuiteExecutionResultsMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptMethodologyTestSuiteExecutionResultsMutation,
    GQTypes.GQAcceptMethodologyTestSuiteExecutionResultsMutationVariables
  >(AcceptMethodologyTestSuiteExecutionResultsDocument);
}
export const SignalExecuteMeasurementTestSuiteDocument = gql`
    mutation SignalExecuteMeasurementTestSuite($input: SignalExecuteMeasurementTestSuiteInput!) {
  signalExecuteMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useSignalExecuteMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQSignalExecuteMeasurementTestSuiteMutation,
    GQTypes.GQSignalExecuteMeasurementTestSuiteMutationVariables
  >(SignalExecuteMeasurementTestSuiteDocument);
}
export const InvalidateMeasurementTestSuitePagePostRunDocument = gql`
    mutation InvalidateMeasurementTestSuitePagePostRun($input: IdInput!) {
  invalidateMeasurementTestSuitePage(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      latestMethodologyTestSuiteExecution {
        ...MethodologyTestSuiteExecutionWithResultsFields
      }
      latestFootprintTestSuiteExecution {
        ...FootprintTestExecutionMetadataFields
      }
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${MethodologyTestSuiteExecutionWithResultsFieldsFragmentDoc}
${FootprintTestExecutionMetadataFieldsFragmentDoc}`;

export function useInvalidateMeasurementTestSuitePagePostRunMutation() {
  return Urql.useMutation<
    GQTypes.GQInvalidateMeasurementTestSuitePagePostRunMutation,
    GQTypes.GQInvalidateMeasurementTestSuitePagePostRunMutationVariables
  >(InvalidateMeasurementTestSuitePagePostRunDocument);
}
export const UpsertFootprintTestSuiteConfigDocument = gql`
    mutation UpsertFootprintTestSuiteConfig($input: UpsertFootprintTestSuiteConfigInput!) {
  upsertFootprintTestSuiteConfig(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      footprintTestSuiteConfig {
        ...FootprintTestSuiteConfigFields
      }
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${FootprintTestSuiteConfigFieldsFragmentDoc}`;

export function useUpsertFootprintTestSuiteConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertFootprintTestSuiteConfigMutation,
    GQTypes.GQUpsertFootprintTestSuiteConfigMutationVariables
  >(UpsertFootprintTestSuiteConfigDocument);
}
export const DeleteFootprintTestSuiteConfigDocument = gql`
    mutation DeleteFootprintTestSuiteConfig($input: DeleteFootprintTestSuiteConfigInput!) {
  deleteFootprintTestSuiteConfig(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      footprintTestSuiteConfig {
        ...FootprintTestSuiteConfigFields
      }
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${FootprintTestSuiteConfigFieldsFragmentDoc}`;

export function useDeleteFootprintTestSuiteConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFootprintTestSuiteConfigMutation,
    GQTypes.GQDeleteFootprintTestSuiteConfigMutationVariables
  >(DeleteFootprintTestSuiteConfigDocument);
}
export const AcceptFootprintTestSuiteExecutionResultsDocument = gql`
    mutation AcceptFootprintTestSuiteExecutionResults($input: AcceptFootprintTestSuiteExecutionResultsInput!) {
  acceptFootprintTestSuiteExecutionResults(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      latestFootprintTestSuiteExecution {
        ...FootprintTestExecutionMetadataFields
      }
    }
    footprintTestExecutionStepResult {
      ...FootprintTestExecutionStepResultFields
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${FootprintTestExecutionMetadataFieldsFragmentDoc}
${FootprintTestExecutionStepResultFieldsFragmentDoc}`;

export function useAcceptFootprintTestSuiteExecutionResultsMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptFootprintTestSuiteExecutionResultsMutation,
    GQTypes.GQAcceptFootprintTestSuiteExecutionResultsMutationVariables
  >(AcceptFootprintTestSuiteExecutionResultsDocument);
}
export const ImportMeasurementTestSuiteDocument = gql`
    mutation ImportMeasurementTestSuite($input: ImportMeasurementTestSuiteInput!) {
  importMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useImportMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQImportMeasurementTestSuiteMutation,
    GQTypes.GQImportMeasurementTestSuiteMutationVariables
  >(ImportMeasurementTestSuiteDocument);
}
export const ExportMeasurementTestSuiteDocument = gql`
    mutation ExportMeasurementTestSuite($input: IdInput!) {
  exportMeasurementTestSuite(input: $input) {
    downloadUrl
  }
}
    `;

export function useExportMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQExportMeasurementTestSuiteMutation,
    GQTypes.GQExportMeasurementTestSuiteMutationVariables
  >(ExportMeasurementTestSuiteDocument);
}
export const ExportMeasurementTestSuiteBundleDocument = gql`
    mutation ExportMeasurementTestSuiteBundle($input: IdInput!) {
  exportMeasurementTestSuiteBundle(input: $input) {
    downloadUrl
  }
}
    `;

export function useExportMeasurementTestSuiteBundleMutation() {
  return Urql.useMutation<
    GQTypes.GQExportMeasurementTestSuiteBundleMutation,
    GQTypes.GQExportMeasurementTestSuiteBundleMutationVariables
  >(ExportMeasurementTestSuiteBundleDocument);
}
export const DuplicateMeasurementTestSuiteDocument = gql`
    mutation DuplicateMeasurementTestSuite($input: DuplicateMeasurementTestSuiteInput!) {
  duplicateMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useDuplicateMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicateMeasurementTestSuiteMutation,
    GQTypes.GQDuplicateMeasurementTestSuiteMutationVariables
  >(DuplicateMeasurementTestSuiteDocument);
}
export const SwapEmissionsModelExpectationsForMeasurementTestRowsDocument = gql`
    mutation SwapEmissionsModelExpectationsForMeasurementTestRows($input: SwapEmissionsModelExpectationsForMeasurementTestRowsInput!) {
  swapEmissionsModelExpectationsForTestRows(input: $input) {
    ids
  }
}
    `;

export function useSwapEmissionsModelExpectationsForMeasurementTestRowsMutation() {
  return Urql.useMutation<
    GQTypes.GQSwapEmissionsModelExpectationsForMeasurementTestRowsMutation,
    GQTypes.GQSwapEmissionsModelExpectationsForMeasurementTestRowsMutationVariables
  >(SwapEmissionsModelExpectationsForMeasurementTestRowsDocument);
}
export const SwapEmissionsModelExpectationsForFootprintTestRowsDocument = gql`
    mutation SwapEmissionsModelExpectationsForFootprintTestRows($input: SwapEmissionsModelExpectationsForFootprintTestRowsInput!) {
  swapEmissionsModelExpectationsForFootprintTestRows(input: $input) {
    ids
  }
}
    `;

export function useSwapEmissionsModelExpectationsForFootprintTestRowsMutation() {
  return Urql.useMutation<
    GQTypes.GQSwapEmissionsModelExpectationsForFootprintTestRowsMutation,
    GQTypes.GQSwapEmissionsModelExpectationsForFootprintTestRowsMutationVariables
  >(SwapEmissionsModelExpectationsForFootprintTestRowsDocument);
}
export const DefaultCalculationTagsDocument = gql`
    query DefaultCalculationTags {
  defaultCalculationTags {
    id
    name
  }
}
    `;

export function useDefaultCalculationTagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDefaultCalculationTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDefaultCalculationTagsQuery,
    GQTypes.GQDefaultCalculationTagsQueryVariables
  >({ query: DefaultCalculationTagsDocument, ...options });
}
export const AvailableActivityTypeNamesDocument = gql`
    query AvailableActivityTypeNames {
  activityTypes: businessActivityTypesPrimaryForVersionWithFields(
    versionName: "current"
  ) {
    id
    activityTypeName: name
  }
}
    `;

export function useAvailableActivityTypeNamesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAvailableActivityTypeNamesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAvailableActivityTypeNamesQuery,
    GQTypes.GQAvailableActivityTypeNamesQueryVariables
  >({ query: AvailableActivityTypeNamesDocument, ...options });
}
export const MeasurementTestSuiteListDocument = gql`
    query MeasurementTestSuiteList($includeDeleted: Boolean!) {
  measurementTestSuites(includeDeleted: $includeDeleted, criticalOnly: false) {
    ...MeasurementTestSuiteListFields
  }
}
    ${MeasurementTestSuiteListFieldsFragmentDoc}`;

export function useMeasurementTestSuiteListQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementTestSuiteListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementTestSuiteListQuery,
    GQTypes.GQMeasurementTestSuiteListQueryVariables
  >({ query: MeasurementTestSuiteListDocument, ...options });
}
export const MeasurementTestSuiteDetailDocument = gql`
    query MeasurementTestSuiteDetail($id: ID!) {
  measurementTestSuite(id: $id) {
    ...MeasurementTestSuiteDetailsWithoutExecutions
    latestMethodologyTestSuiteExecution {
      ...MethodologyTestSuiteExecutionMetadataFields
    }
    latestFootprintTestSuiteExecution {
      ...FootprintTestExecutionMetadataFields
    }
  }
}
    ${MeasurementTestSuiteDetailsWithoutExecutionsFragmentDoc}
${MethodologyTestSuiteExecutionMetadataFieldsFragmentDoc}
${FootprintTestExecutionMetadataFieldsFragmentDoc}`;

export function useMeasurementTestSuiteDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementTestSuiteDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementTestSuiteDetailQuery,
    GQTypes.GQMeasurementTestSuiteDetailQueryVariables
  >({ query: MeasurementTestSuiteDetailDocument, ...options });
}
export const MethodologyTestSuiteExecutionDetailDocument = gql`
    query MethodologyTestSuiteExecutionDetail($id: ID!) {
  methodologyTestSuiteExecution(id: $id) {
    ...MethodologyTestSuiteExecutionWithResultsFields
  }
}
    ${MethodologyTestSuiteExecutionWithResultsFieldsFragmentDoc}`;

export function useMethodologyTestSuiteExecutionDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyTestSuiteExecutionDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyTestSuiteExecutionDetailQuery,
    GQTypes.GQMethodologyTestSuiteExecutionDetailQueryVariables
  >({ query: MethodologyTestSuiteExecutionDetailDocument, ...options });
}
export const MeasurementTestSuiteChangelogDocument = gql`
    query MeasurementTestSuiteChangelog($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  measurementTestSuiteChangelog(
    id: $id
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...MeasurementTestSuiteChangelogFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${MeasurementTestSuiteChangelogFieldsFragmentDoc}`;

export function useMeasurementTestSuiteChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementTestSuiteChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementTestSuiteChangelogQuery,
    GQTypes.GQMeasurementTestSuiteChangelogQueryVariables
  >({ query: MeasurementTestSuiteChangelogDocument, ...options });
}
export const MeasurementTestSuiteStatusDocument = gql`
    query MeasurementTestSuiteStatus($id: ID!) {
  measurementTestSuite(id: $id) {
    id
    status
    latestChangelogEntry {
      ...MeasurementTestSuiteChangelogFields
    }
    latestMethodologyTestSuiteExecution {
      id
      status
    }
    latestFootprintTestSuiteExecution {
      id
      status
    }
  }
}
    ${MeasurementTestSuiteChangelogFieldsFragmentDoc}`;

export function useMeasurementTestSuiteStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementTestSuiteStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementTestSuiteStatusQuery,
    GQTypes.GQMeasurementTestSuiteStatusQueryVariables
  >({ query: MeasurementTestSuiteStatusDocument, ...options });
}
export const FootprintTestSuiteConfigFieldOptionsDocument = gql`
    query FootprintTestSuiteConfigFieldOptions($orgId: ID) {
  footprintTestSuiteFootprintTags(orgId: $orgId) {
    id
    tagName
    rulesList
  }
  footprintTestSuiteMarketplaceAllocInstrs(orgId: $orgId) {
    id
    amountKwh
    isZeroedOut
    locationCountry
  }
  footprintTestSuiteExclusionRules(orgId: $orgId) {
    id
    ruleOrder
    filtersSourceOne {
      dimension
      operator
      value
    }
    outputValue
  }
  footprintTestSuiteCategoryRules(orgId: $orgId) {
    id
    filters {
      dimension
      operator
      value
    }
    outputColumn
    outputValue
    ruleOrder
  }
}
    `;

export function useFootprintTestSuiteConfigFieldOptionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintTestSuiteConfigFieldOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintTestSuiteConfigFieldOptionsQuery,
    GQTypes.GQFootprintTestSuiteConfigFieldOptionsQueryVariables
  >({ query: FootprintTestSuiteConfigFieldOptionsDocument, ...options });
}
export const FootprintTestSuiteExecutionDocument = gql`
    query FootprintTestSuiteExecution($footprintTestSuiteExecutionId: ID!, $footprintAssemblyStep: FootprintAssemblyStep!) {
  footprintTestSuiteExecutionStepResult(
    footprintTestSuiteExecutionId: $footprintTestSuiteExecutionId
    footprintAssemblyStep: $footprintAssemblyStep
  ) {
    ...FootprintTestExecutionStepResultFields
  }
}
    ${FootprintTestExecutionStepResultFieldsFragmentDoc}`;

export function useFootprintTestSuiteExecutionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintTestSuiteExecutionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintTestSuiteExecutionQuery,
    GQTypes.GQFootprintTestSuiteExecutionQueryVariables
  >({ query: FootprintTestSuiteExecutionDocument, ...options });
}
export const GetRefDataMetadataForMethodologyTreeDocument = gql`
    query getRefDataMetadataForMethodologyTree($versionId: ID!) {
  referenceDataVersion(referenceDataVersionId: $versionId) {
    id
    versionName
    latestPublishedRevisionByVersionId {
      id
      kind
      signedParquetOutputUrl
      outputFileId
    }
    latestReferenceDataSqlRun {
      id
      sql
      status
    }
    latestSchema {
      id
      schemaJson
    }
    source {
      id
      name
    }
  }
}
    `;

export function useGetRefDataMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetRefDataMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetRefDataMetadataForMethodologyTreeQuery,
    GQTypes.GQGetRefDataMetadataForMethodologyTreeQueryVariables
  >({ query: GetRefDataMetadataForMethodologyTreeDocument, ...options });
}
export const GetBusinessActivityTypeMetadataForMethodologyTreeDocument = gql`
    query getBusinessActivityTypeMetadataForMethodologyTree($versionId: ID!) {
  businessActivityTypeVersionById(id: $versionId) {
    id
    name
  }
}
    `;

export function useGetBusinessActivityTypeMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBusinessActivityTypeMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBusinessActivityTypeMetadataForMethodologyTreeQuery,
    GQTypes.GQGetBusinessActivityTypeMetadataForMethodologyTreeQueryVariables
  >({
    query: GetBusinessActivityTypeMetadataForMethodologyTreeDocument,
    ...options,
  });
}
export const GetModelMetadataForMethodologyTreeDocument = gql`
    query getModelMetadataForMethodologyTree($versionId: ID!) {
  emissionsModelVersion(id: $versionId) {
    id
    stableId
  }
}
    `;

export function useGetModelMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetModelMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetModelMetadataForMethodologyTreeQuery,
    GQTypes.GQGetModelMetadataForMethodologyTreeQueryVariables
  >({ query: GetModelMetadataForMethodologyTreeDocument, ...options });
}
export const GetReleaseMetadataForMethodologyTreeDocument = gql`
    query getReleaseMetadataForMethodologyTree($releaseId: ID!) {
  emissionsModelRelease(id: $releaseId) {
    id
    displayName
    orgId
    internalNotes
    externalNotes
    businessActivityTypeName
    isOrgGated
    emissionsModels {
      id
      title
    }
    lifecyclePhase
  }
}
    `;

export function useGetReleaseMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReleaseMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReleaseMetadataForMethodologyTreeQuery,
    GQTypes.GQGetReleaseMetadataForMethodologyTreeQueryVariables
  >({ query: GetReleaseMetadataForMethodologyTreeDocument, ...options });
}
export const GetCtsMetadataForMethodologyTreeDocument = gql`
    query getCtsMetadataForMethodologyTree($versionId: ID!) {
  rawCustomerTargetSchemaVersion(id: $versionId) {
    id
    schemaJson
    exampleData
    transforms {
      id
      sql
      businessActivityTypeName
      businessActivityTypeVersion {
        id
        name
      }
    }
    schema {
      id
      name
      descriptionMd
      utilityType
      rank
      org {
        id
        name
      }
    }
  }
}
    `;

export function useGetCtsMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCtsMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCtsMetadataForMethodologyTreeQuery,
    GQTypes.GQGetCtsMetadataForMethodologyTreeQueryVariables
  >({ query: GetCtsMetadataForMethodologyTreeDocument, ...options });
}
export const GetDatasetMetadataForMethodologyTreeDocument = gql`
    query getDatasetMetadataForMethodologyTree($datasetId: ID!) {
  canonicalDataset(id: $datasetId) {
    id
    name
    description
    kind
  }
}
    `;

export function useGetDatasetMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDatasetMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDatasetMetadataForMethodologyTreeQuery,
    GQTypes.GQGetDatasetMetadataForMethodologyTreeQueryVariables
  >({ query: GetDatasetMetadataForMethodologyTreeDocument, ...options });
}
export const GetGlobalEmissionsModelTreeFromReleasesDocument = gql`
    query GetGlobalEmissionsModelTreeFromReleases {
  emissionsModelReleases(includeAllOrgs: true, includeAlphas: true) {
    id
    emissionsModels {
      ...StableFieldsForGlobalEmissionsModelTree
    }
  }
}
    ${StableFieldsForGlobalEmissionsModelTreeFragmentDoc}`;

export function useGetGlobalEmissionsModelTreeFromReleasesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetGlobalEmissionsModelTreeFromReleasesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetGlobalEmissionsModelTreeFromReleasesQuery,
    GQTypes.GQGetGlobalEmissionsModelTreeFromReleasesQueryVariables
  >({ query: GetGlobalEmissionsModelTreeFromReleasesDocument, ...options });
}
export const GetEmissionsModelTreeDocument = gql`
    query getEmissionsModelTree($ids: [ID!]!) {
  stableModels: emissionsModelsStableByIds(ids: $ids) {
    ...StableFieldsForGlobalEmissionsModelTree
  }
}
    ${StableFieldsForGlobalEmissionsModelTreeFragmentDoc}`;

export function useGetEmissionsModelTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelTreeQuery,
    GQTypes.GQGetEmissionsModelTreeQueryVariables
  >({ query: GetEmissionsModelTreeDocument, ...options });
}
export const GetReferenceDataAncestryTreeDocument = gql`
    query getReferenceDataAncestryTree($ids: [ID!]!) {
  ancestorRelationships: referenceDataAncestryForVersionIds(versionIds: $ids) {
    versionId
    versionSlug
    upstreamVersionId
    upstreamVersionSlug
  }
}
    `;

export function useGetReferenceDataAncestryTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReferenceDataAncestryTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReferenceDataAncestryTreeQuery,
    GQTypes.GQGetReferenceDataAncestryTreeQueryVariables
  >({ query: GetReferenceDataAncestryTreeDocument, ...options });
}
export const GetCtsMethodologyTreeDocument = gql`
    query getCTSMethodologyTree {
  customerTargetSchemas {
    id
    name
    latestPublishedVersion {
      id
      transforms {
        id
        businessActivityTypeName
        businessActivityTypeVersionId
      }
    }
    canonicalDatasets {
      id
      name
    }
  }
}
    `;

export function useGetCtsMethodologyTreeQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCtsMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCtsMethodologyTreeQuery,
    GQTypes.GQGetCtsMethodologyTreeQueryVariables
  >({ query: GetCtsMethodologyTreeDocument, ...options });
}
export const SchemaRegistrySchemaSelectorDataDocument = gql`
    query SchemaRegistrySchemaSelectorData {
  schemaRegistrySchemas {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useSchemaRegistrySchemaSelectorDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaRegistrySchemaSelectorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaRegistrySchemaSelectorDataQuery,
    GQTypes.GQSchemaRegistrySchemaSelectorDataQueryVariables
  >({ query: SchemaRegistrySchemaSelectorDataDocument, ...options });
}
export const SchemaRegistrySchemaVersionSelectorDataDocument = gql`
    query SchemaRegistrySchemaVersionSelectorData($schemaId: ID!) {
  schemaRegistrySchemaVersions(schemaId: $schemaId) {
    edges {
      node {
        id
        version
      }
    }
  }
}
    `;

export function useSchemaRegistrySchemaVersionSelectorDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaRegistrySchemaVersionSelectorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaRegistrySchemaVersionSelectorDataQuery,
    GQTypes.GQSchemaRegistrySchemaVersionSelectorDataQueryVariables
  >({ query: SchemaRegistrySchemaVersionSelectorDataDocument, ...options });
}
export const PublishDataRegistryTablesDocument = gql`
    mutation PublishDataRegistryTables($input: PublishDataRegistryTablesInput!) {
  publishDataRegistryTables(input: $input) {
    publishedCount
  }
}
    `;

export function usePublishDataRegistryTablesMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishDataRegistryTablesMutation,
    GQTypes.GQPublishDataRegistryTablesMutationVariables
  >(PublishDataRegistryTablesDocument);
}
export const UnpublishDataRegistryTablesDocument = gql`
    mutation UnpublishDataRegistryTables($input: UnpublishDataRegistryTablesInput!) {
  unpublishDataRegistryTables(input: $input) {
    unpublishedCount
  }
}
    `;

export function useUnpublishDataRegistryTablesMutation() {
  return Urql.useMutation<
    GQTypes.GQUnpublishDataRegistryTablesMutation,
    GQTypes.GQUnpublishDataRegistryTablesMutationVariables
  >(UnpublishDataRegistryTablesDocument);
}
export const DeleteDataRegistryTablesDocument = gql`
    mutation DeleteDataRegistryTables($input: DeleteDataRegistryTablesInput!) {
  deleteDataRegistryTables(input: $input) {
    deletedCount
  }
}
    `;

export function useDeleteDataRegistryTablesMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteDataRegistryTablesMutation,
    GQTypes.GQDeleteDataRegistryTablesMutationVariables
  >(DeleteDataRegistryTablesDocument);
}
export const SchemaRegistrySchemasDocument = gql`
    query SchemaRegistrySchemas($after: String, $before: String, $first: Int, $last: Int, $search: String) {
  schemaRegistrySchemas(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
  ) {
    edges {
      node {
        ...SchemaRegistrySchemaSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${SchemaRegistrySchemaSchemaFragmentDoc}`;

export function useSchemaRegistrySchemasQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaRegistrySchemasQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaRegistrySchemasQuery,
    GQTypes.GQSchemaRegistrySchemasQueryVariables
  >({ query: SchemaRegistrySchemasDocument, ...options });
}
export const DataRegistryBatchMigratorRunsDocument = gql`
    query DataRegistryBatchMigratorRuns($after: String, $before: String, $first: Int, $last: Int, $searchDescription: String) {
  dataRegistryBatchMigratorRuns(
    after: $after
    before: $before
    first: $first
    last: $last
    searchDescription: $searchDescription
  ) {
    edges {
      node {
        ...DataRegistryBatchMigratorRunSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${DataRegistryBatchMigratorRunSchemaFragmentDoc}`;

export function useDataRegistryBatchMigratorRunsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataRegistryBatchMigratorRunsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataRegistryBatchMigratorRunsQuery,
    GQTypes.GQDataRegistryBatchMigratorRunsQueryVariables
  >({ query: DataRegistryBatchMigratorRunsDocument, ...options });
}
export const DataRegistryMigratorsDocument = gql`
    query DataRegistryMigrators($after: String, $before: String, $first: Int, $last: Int, $schemaId: ID) {
  dataRegistryMigrators(
    after: $after
    before: $before
    first: $first
    last: $last
    schemaId: $schemaId
  ) {
    edges {
      node {
        ...DataRegistryMigratorSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${DataRegistryMigratorSchemaFragmentDoc}`;

export function useDataRegistryMigratorsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataRegistryMigratorsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataRegistryMigratorsQuery,
    GQTypes.GQDataRegistryMigratorsQueryVariables
  >({ query: DataRegistryMigratorsDocument, ...options });
}
export const GetDataRegistryMigratorDocument = gql`
    query GetDataRegistryMigrator($migratorId: ID!) {
  dataRegistryMigrator(migratorId: $migratorId) {
    id
    createdAt
    migratorType
    migrationCode
    description
    inputSchemaVersionId
    outputSchemaVersionId
  }
}
    `;

export function useGetDataRegistryMigratorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDataRegistryMigratorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDataRegistryMigratorQuery,
    GQTypes.GQGetDataRegistryMigratorQueryVariables
  >({ query: GetDataRegistryMigratorDocument, ...options });
}
export const RunDataRegistryMigrationDocument = gql`
    mutation RunDataRegistryMigration($input: RunDataRegistryMigrationInput!) {
  runDataRegistryMigration(input: $input)
}
    `;

export function useRunDataRegistryMigrationMutation() {
  return Urql.useMutation<
    GQTypes.GQRunDataRegistryMigrationMutation,
    GQTypes.GQRunDataRegistryMigrationMutationVariables
  >(RunDataRegistryMigrationDocument);
}
export const DataRegistryMigratorBestPathDocument = gql`
    query DataRegistryMigratorBestPath($fromSchemaVersionId: ID!, $toSchemaVersionId: ID!) {
  dataRegistryMigratorBestPath(
    fromSchemaVersionId: $fromSchemaVersionId
    toSchemaVersionId: $toSchemaVersionId
  ) {
    migrator {
      id
      migratorType
      migrationCode
      description
    }
    outputSchema {
      id
      version
    }
  }
}
    `;

export function useDataRegistryMigratorBestPathQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataRegistryMigratorBestPathQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataRegistryMigratorBestPathQuery,
    GQTypes.GQDataRegistryMigratorBestPathQueryVariables
  >({ query: DataRegistryMigratorBestPathDocument, ...options });
}
export const RegionForTSchemaMigrationsDocument = gql`
    query RegionForTSchemaMigrations {
  region
}
    `;

export function useRegionForTSchemaMigrationsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQRegionForTSchemaMigrationsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRegionForTSchemaMigrationsQuery,
    GQTypes.GQRegionForTSchemaMigrationsQueryVariables
  >({ query: RegionForTSchemaMigrationsDocument, ...options });
}
export const SchemaRegistrySchemaVersionsDocument = gql`
    query SchemaRegistrySchemaVersions($after: String, $before: String, $first: Int, $last: Int, $schemaId: ID, $search: String) {
  schemaRegistrySchemaVersions(
    after: $after
    before: $before
    first: $first
    last: $last
    schemaId: $schemaId
    search: $search
  ) {
    edges {
      node {
        ...SchemaRegistrySchemaVersionSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${SchemaRegistrySchemaVersionSchemaFragmentDoc}`;

export function useSchemaRegistrySchemaVersionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaRegistrySchemaVersionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaRegistrySchemaVersionsQuery,
    GQTypes.GQSchemaRegistrySchemaVersionsQueryVariables
  >({ query: SchemaRegistrySchemaVersionsDocument, ...options });
}
export const DeleteOneSchemaTemplateDocument = gql`
    mutation DeleteOneSchemaTemplate($input: DeleteOneSchemaTemplateInput!) {
  deleteOneSchemaTemplate(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function useDeleteOneSchemaTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOneSchemaTemplateMutation,
    GQTypes.GQDeleteOneSchemaTemplateMutationVariables
  >(DeleteOneSchemaTemplateDocument);
}
export const GetEngagementTasksAdminDocument = gql`
    query GetEngagementTasksAdmin($orgId: ID!) {
  engagementTasksAdmin(orgId: $orgId) {
    edges {
      node {
        ...EngagementTaskFieldsAdmin
      }
    }
  }
}
    ${EngagementTaskFieldsAdminFragmentDoc}`;

export function useGetEngagementTasksAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTasksAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTasksAdminQuery,
    GQTypes.GQGetEngagementTasksAdminQueryVariables
  >({ query: GetEngagementTasksAdminDocument, ...options });
}
export const GetAssetsWithTasksDocument = gql`
    query GetAssetsWithTasks($orgId: ID!) {
  assetsWithTasks(orgId: $orgId) {
    id
    companyId
    name
  }
}
    `;

export function useGetAssetsWithTasksQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAssetsWithTasksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAssetsWithTasksQuery,
    GQTypes.GQGetAssetsWithTasksQueryVariables
  >({ query: GetAssetsWithTasksDocument, ...options });
}
export const ShareDisclosureDialogDocument = gql`
    query ShareDisclosureDialog($orgId: ID!) {
  orgFootprintDisclosures(orgId: $orgId) {
    edges {
      node {
        ...FootprintDisclosureFields
      }
    }
  }
}
    ${FootprintDisclosureFieldsFragmentDoc}`;

export function useShareDisclosureDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQShareDisclosureDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQShareDisclosureDialogQuery,
    GQTypes.GQShareDisclosureDialogQueryVariables
  >({ query: ShareDisclosureDialogDocument, ...options });
}
export const CreateFootprintDisclosureDocument = gql`
    mutation CreateFootprintDisclosure($input: CreateFootprintDisclosureInput!) {
  createFootprintDisclosure(input: $input) {
    footprintDisclosure {
      id
    }
  }
}
    `;

export function useCreateFootprintDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintDisclosureMutation,
    GQTypes.GQCreateFootprintDisclosureMutationVariables
  >(CreateFootprintDisclosureDocument);
}
export const GetOrgsForOrgPickerDocument = gql`
    query GetOrgsForOrgPicker {
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
      }
    }
  }
}
    `;

export function useGetOrgsForOrgPickerQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrgsForOrgPickerQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrgsForOrgPickerQuery,
    GQTypes.GQGetOrgsForOrgPickerQueryVariables
  >({ query: GetOrgsForOrgPickerDocument, ...options });
}
export const FinanceAdminAssetDifferDocument = gql`
    query FinanceAdminAssetDiffer($orgId: ID!) {
  portcos(orgId: $orgId) {
    id
    name
  }
}
    `;

export function useFinanceAdminAssetDifferQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAdminAssetDifferQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAdminAssetDifferQuery,
    GQTypes.GQFinanceAdminAssetDifferQueryVariables
  >({ query: FinanceAdminAssetDifferDocument, ...options });
}
export const FinanceAdminAssetDocument = gql`
    query FinanceAdminAsset($assetId: ID!) {
  assetCorporateAdmin(id: $assetId) {
    id
    ...AssetCorporateAllFields
    company {
      id
      name
    }
    assetYears {
      id
      ...AssetYearAllFields
    }
    assetHoldings {
      id
      ...AssetHoldingAllFields
    }
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}
${AssetYearAllFieldsFragmentDoc}
${AssetHoldingAllFieldsFragmentDoc}`;

export function useFinanceAdminAssetQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAdminAssetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAdminAssetQuery,
    GQTypes.GQFinanceAdminAssetQueryVariables
  >({ query: FinanceAdminAssetDocument, ...options });
}
export const FinanceMergeAssetsDocument = gql`
    mutation FinanceMergeAssets($input: MergeAssetsInput!) {
  mergeAssets(input: $input) {
    id
  }
}
    `;

export function useFinanceMergeAssetsMutation() {
  return Urql.useMutation<
    GQTypes.GQFinanceMergeAssetsMutation,
    GQTypes.GQFinanceMergeAssetsMutationVariables
  >(FinanceMergeAssetsDocument);
}
export const OrganizationsFinanceDisclosuresPageDocument = gql`
    query OrganizationsFinanceDisclosuresPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    company {
      id
    }
  }
  orgFootprintDisclosures(orgId: $orgId) {
    edges {
      node {
        ...FootprintDisclosureFields
      }
    }
  }
  orgFootprintDisclosureShareLogs(orgId: $orgId) {
    edges {
      node {
        ...FootprintDisclosureShareLogFields
      }
    }
  }
}
    ${FootprintDisclosureFieldsFragmentDoc}
${FootprintDisclosureShareLogFieldsFragmentDoc}`;

export function useOrganizationsFinanceDisclosuresPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationsFinanceDisclosuresPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationsFinanceDisclosuresPageQuery,
    GQTypes.GQOrganizationsFinanceDisclosuresPageQueryVariables
  >({ query: OrganizationsFinanceDisclosuresPageDocument, ...options });
}
export const FinanceAdminGetRefreshableAssetsDocument = gql`
    query FinanceAdminGetRefreshableAssets($orgId: ID!, $year: Int!) {
  refreshableAssets(orgId: $orgId, year: $year) {
    id
    name
    naicsCode
    countryAlpha2
    assetYears {
      id
    }
  }
}
    `;

export function useFinanceAdminGetRefreshableAssetsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAdminGetRefreshableAssetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAdminGetRefreshableAssetsQuery,
    GQTypes.GQFinanceAdminGetRefreshableAssetsQueryVariables
  >({ query: FinanceAdminGetRefreshableAssetsDocument, ...options });
}
export const RefreshAssetYearEstimationsDocument = gql`
    mutation RefreshAssetYearEstimations($input: RefreshAssetYearEstimationsInput!) {
  refreshAssetYearEstimations(input: $input) {
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useRefreshAssetYearEstimationsMutation() {
  return Urql.useMutation<
    GQTypes.GQRefreshAssetYearEstimationsMutation,
    GQTypes.GQRefreshAssetYearEstimationsMutationVariables
  >(RefreshAssetYearEstimationsDocument);
}
export const OrganizationsFundsPageDocument = gql`
    query OrganizationsFundsPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    company {
      id
    }
  }
  orgFunds(orgId: $orgId) {
    edges {
      node {
        id
        ...FundAllFields
      }
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useOrganizationsFundsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationsFundsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationsFundsPageQuery,
    GQTypes.GQOrganizationsFundsPageQueryVariables
  >({ query: OrganizationsFundsPageDocument, ...options });
}
export const CreateFundAdminDocument = gql`
    mutation CreateFundAdmin($input: CreateFundInputAdmin!) {
  createFundAdmin(input: $input) {
    fund {
      id
      ...FundAllFields
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useCreateFundAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFundAdminMutation,
    GQTypes.GQCreateFundAdminMutationVariables
  >(CreateFundAdminDocument);
}
export const UpdateFundDocument = gql`
    mutation UpdateFund($input: UpdateFundInput!) {
  updateFund(input: $input) {
    fund {
      id
      ...FundAllFields
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useUpdateFundMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFundMutation,
    GQTypes.GQUpdateFundMutationVariables
  >(UpdateFundDocument);
}
export const DeleteFundsDocument = gql`
    mutation DeleteFunds($input: DeleteFundsInput!) {
  deleteFunds(input: $input) {
    ids
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useDeleteFundsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFundsMutation,
    GQTypes.GQDeleteFundsMutationVariables
  >(DeleteFundsDocument);
}
export const FinanceMeasurementProgressTabAdminDocument = gql`
    query FinanceMeasurementProgressTabAdmin($orgId: ID!, $year: Int!) {
  financeMeasurementProgressRowsAdmin(orgId: $orgId, year: $year) {
    ...FinanceMeasurementProgressRowAllFields
  }
}
    ${FinanceMeasurementProgressRowAllFieldsFragmentDoc}`;

export function useFinanceMeasurementProgressTabAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceMeasurementProgressTabAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceMeasurementProgressTabAdminQuery,
    GQTypes.GQFinanceMeasurementProgressTabAdminQueryVariables
  >({ query: FinanceMeasurementProgressTabAdminDocument, ...options });
}
export const AvailableProjectsForCompanyDocument = gql`
    query AvailableProjectsForCompany($companyId: ID!, $year: Int!) {
  availableProjectsForCompany(companyId: $companyId, year: $year) {
    id
    name
    coverageStartDate
    coverageEndDate
  }
}
    `;

export function useAvailableProjectsForCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAvailableProjectsForCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAvailableProjectsForCompanyQuery,
    GQTypes.GQAvailableProjectsForCompanyQueryVariables
  >({ query: AvailableProjectsForCompanyDocument, ...options });
}
export const ShareMeasurementProjectDocument = gql`
    mutation ShareMeasurementProject($input: ShareMeasurementProjectInput!) {
  shareMeasurementProject(input: $input) {
    measurementProjectStatusShare {
      id
    }
  }
}
    `;

export function useShareMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQShareMeasurementProjectMutation,
    GQTypes.GQShareMeasurementProjectMutationVariables
  >(ShareMeasurementProjectDocument);
}
export const FinanceAdminPortcoLoginTabDocument = gql`
    query FinanceAdminPortcoLoginTab($orgId: ID!) {
  users(orgId: $orgId, includeWatershedEmployees: true) {
    ...UserFieldsForFinancePage
  }
  portcoOrgs(orgId: $orgId) {
    ...PortcoOrgFieldsForFinancePage
  }
}
    ${UserFieldsForFinancePageFragmentDoc}
${PortcoOrgFieldsForFinancePageFragmentDoc}`;

export function useFinanceAdminPortcoLoginTabQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAdminPortcoLoginTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAdminPortcoLoginTabQuery,
    GQTypes.GQFinanceAdminPortcoLoginTabQueryVariables
  >({ query: FinanceAdminPortcoLoginTabDocument, ...options });
}
export const GrantFinanceUsersPortcoAccessesDocument = gql`
    mutation GrantFinanceUsersPortcoAccesses($input: GrantFinanceUsersPortcoAccessesInput!) {
  grantFinanceUsersPortcoAccesses(input: $input) {
    backgroundJobId
  }
}
    `;

export function useGrantFinanceUsersPortcoAccessesMutation() {
  return Urql.useMutation<
    GQTypes.GQGrantFinanceUsersPortcoAccessesMutation,
    GQTypes.GQGrantFinanceUsersPortcoAccessesMutationVariables
  >(GrantFinanceUsersPortcoAccessesDocument);
}
export const DeleteCompanyClimateCommitmentDocument = gql`
    mutation DeleteCompanyClimateCommitment($input: DeleteCompanyClimateCommitmentInput!) {
  deleteCompanyClimateCommitment(input: $input) {
    disclosure {
      id
    }
  }
}
    `;

export function useDeleteCompanyClimateCommitmentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCompanyClimateCommitmentMutation,
    GQTypes.GQDeleteCompanyClimateCommitmentMutationVariables
  >(DeleteCompanyClimateCommitmentDocument);
}
export const CreatePublicDisclosureDocument = gql`
    mutation CreatePublicDisclosure($input: CreatePublicDisclosureInput!) {
  createPublicDisclosure(input: $input) {
    publicDisclosure {
      id
      ...PublicDisclosureTable
    }
  }
}
    ${PublicDisclosureTableFragmentDoc}`;

export function useCreatePublicDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePublicDisclosureMutation,
    GQTypes.GQCreatePublicDisclosureMutationVariables
  >(CreatePublicDisclosureDocument);
}
export const EditEmissionsYearDocument = gql`
    mutation EditEmissionsYear($input: EditEmissionsYearInput!) {
  editEmissionsYear(input: $input) {
    emissionsYear {
      ...EmissionsYearAllFields
    }
  }
}
    ${EmissionsYearAllFieldsFragmentDoc}`;

export function useEditEmissionsYearMutation() {
  return Urql.useMutation<
    GQTypes.GQEditEmissionsYearMutation,
    GQTypes.GQEditEmissionsYearMutationVariables
  >(EditEmissionsYearDocument);
}
export const DeleteEmissionsYearDocument = gql`
    mutation DeleteEmissionsYear($input: DeleteEmissionsYearInput!) {
  deleteEmissionsYear(input: $input) {
    id
  }
}
    `;

export function useDeleteEmissionsYearMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEmissionsYearMutation,
    GQTypes.GQDeleteEmissionsYearMutationVariables
  >(DeleteEmissionsYearDocument);
}
export const EditPublicDisclosureDocument = gql`
    mutation EditPublicDisclosure($input: EditPublicDisclosureInput!) {
  editPublicDisclosure(input: $input) {
    publicDisclosure {
      id
      ...PublicDisclosureTable
    }
  }
}
    ${PublicDisclosureTableFragmentDoc}`;

export function useEditPublicDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQEditPublicDisclosureMutation,
    GQTypes.GQEditPublicDisclosureMutationVariables
  >(EditPublicDisclosureDocument);
}
export const DeletePublicDisclosureDocument = gql`
    mutation DeletePublicDisclosure($input: DeletePublicDisclosureInput!) {
  deletePublicDisclosure(input: $input) {
    id
  }
}
    `;

export function useDeletePublicDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePublicDisclosureMutation,
    GQTypes.GQDeletePublicDisclosureMutationVariables
  >(DeletePublicDisclosureDocument);
}
export const CreatePlanWithDefaultsDocument = gql`
    mutation CreatePlanWithDefaults($input: CreatePlanInput!) {
  createPlan(input: $input) {
    plan {
      id
      name
    }
  }
}
    `;

export function useCreatePlanWithDefaultsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePlanWithDefaultsMutation,
    GQTypes.GQCreatePlanWithDefaultsMutationVariables
  >(CreatePlanWithDefaultsDocument);
}
export const UpdatePlanAdminDocument = gql`
    mutation UpdatePlanAdmin($input: UpdatePlanInput!) {
  adminUpdatePlan(input: $input) {
    plan {
      ...PlanForPlanPage
    }
  }
}
    ${PlanForPlanPageFragmentDoc}`;

export function useUpdatePlanAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanAdminMutation,
    GQTypes.GQUpdatePlanAdminMutationVariables
  >(UpdatePlanAdminDocument);
}
export const ReportQuestionIdentifiersDocument = gql`
    query ReportQuestionIdentifiers {
  reportQuestionIdentifiers {
    edges {
      node {
        id
        identifier
      }
    }
  }
}
    `;

export function useReportQuestionIdentifiersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportQuestionIdentifiersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportQuestionIdentifiersQuery,
    GQTypes.GQReportQuestionIdentifiersQueryVariables
  >({ query: ReportQuestionIdentifiersDocument, ...options });
}
export const InsertReportQuestionIdentifierDocument = gql`
    mutation InsertReportQuestionIdentifier($input: InsertReportQuestionIdentifierInput!) {
  insertReportQuestionIdentifier(input: $input) {
    id
    identifier
  }
}
    `;

export function useInsertReportQuestionIdentifierMutation() {
  return Urql.useMutation<
    GQTypes.GQInsertReportQuestionIdentifierMutation,
    GQTypes.GQInsertReportQuestionIdentifierMutationVariables
  >(InsertReportQuestionIdentifierDocument);
}
export const CreateReportWithConfigAdminDocument = gql`
    mutation CreateReportWithConfigAdmin($input: CreateReportWithConfigAdminInput!) {
  createReportWithConfigAdmin(input: $input) {
    id
  }
}
    `;

export function useCreateReportWithConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportWithConfigAdminMutation,
    GQTypes.GQCreateReportWithConfigAdminMutationVariables
  >(CreateReportWithConfigAdminDocument);
}
export const CreateReportWithCustomConfigAdminDocument = gql`
    mutation CreateReportWithCustomConfigAdmin($input: CreateReportWithCustomConfigAdminInput!) {
  createReportWithCustomConfigAdmin(input: $input) {
    report {
      id
      name
      reportKind
    }
  }
}
    `;

export function useCreateReportWithCustomConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportWithCustomConfigAdminMutation,
    GQTypes.GQCreateReportWithCustomConfigAdminMutationVariables
  >(CreateReportWithCustomConfigAdminDocument);
}
export const ExternalReportAnswersForReportDocument = gql`
    query ExternalReportAnswersForReport($publicDisclosureId: ID!) {
  externalReportAnswersForReport(publicDisclosureId: $publicDisclosureId) {
    ...ExternalReportAnswerFields
  }
}
    ${ExternalReportAnswerFieldsFragmentDoc}`;

export function useExternalReportAnswersForReportQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQExternalReportAnswersForReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQExternalReportAnswersForReportQuery,
    GQTypes.GQExternalReportAnswersForReportQueryVariables
  >({ query: ExternalReportAnswersForReportDocument, ...options });
}
export const ScoreAnswerDocument = gql`
    mutation ScoreAnswer($answerId: ID!, $score: DisclosureQualityScore) {
  scoreAnswer(answerId: $answerId, score: $score) {
    id
    watershedQualityScore
  }
}
    `;

export function useScoreAnswerMutation() {
  return Urql.useMutation<
    GQTypes.GQScoreAnswerMutation,
    GQTypes.GQScoreAnswerMutationVariables
  >(ScoreAnswerDocument);
}
export const SearchCompaniesForExternalReportAnswersDocument = gql`
    query SearchCompaniesForExternalReportAnswers($company: String!, $first: Int!) {
  companies(search: $company, first: $first) {
    edges {
      node {
        id
        name
        disclosuresV2 {
          id
          publicDisclosure {
            id
            reportType
            source
            reportingYears
          }
        }
      }
    }
  }
}
    `;

export function useSearchCompaniesForExternalReportAnswersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSearchCompaniesForExternalReportAnswersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSearchCompaniesForExternalReportAnswersQuery,
    GQTypes.GQSearchCompaniesForExternalReportAnswersQueryVariables
  >({ query: SearchCompaniesForExternalReportAnswersDocument, ...options });
}
export const GetArchetypeOpportunitiesDocument = gql`
    query GetArchetypeOpportunities {
  tcfdArchetypeOpportunities {
    ...ArchetypeOpportunityFields
  }
}
    ${ArchetypeOpportunityFieldsFragmentDoc}`;

export function useGetArchetypeOpportunitiesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetArchetypeOpportunitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetArchetypeOpportunitiesQuery,
    GQTypes.GQGetArchetypeOpportunitiesQueryVariables
  >({ query: GetArchetypeOpportunitiesDocument, ...options });
}
export const CreateTcfdArchetypeOpportunityDocument = gql`
    mutation CreateTcfdArchetypeOpportunity($input: CreateTcfdArchetypeOpportunityInput!) {
  createTcfdArchetypeOpportunity(input: $input) {
    ...ArchetypeOpportunityFields
  }
}
    ${ArchetypeOpportunityFieldsFragmentDoc}`;

export function useCreateTcfdArchetypeOpportunityMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateTcfdArchetypeOpportunityMutation,
    GQTypes.GQCreateTcfdArchetypeOpportunityMutationVariables
  >(CreateTcfdArchetypeOpportunityDocument);
}
export const UpdateTcfdArchetypeOpportunityDocument = gql`
    mutation UpdateTcfdArchetypeOpportunity($input: UpdateTcfdArchetypeOpportunityInput!) {
  updateTcfdArchetypeOpportunity(input: $input) {
    ...ArchetypeOpportunityFields
  }
}
    ${ArchetypeOpportunityFieldsFragmentDoc}`;

export function useUpdateTcfdArchetypeOpportunityMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateTcfdArchetypeOpportunityMutation,
    GQTypes.GQUpdateTcfdArchetypeOpportunityMutationVariables
  >(UpdateTcfdArchetypeOpportunityDocument);
}
export const GetArchetypeRisksDocument = gql`
    query GetArchetypeRisks {
  tcfdArchetypeRisks {
    ...RiskArchetypeFields
  }
}
    ${RiskArchetypeFieldsFragmentDoc}`;

export function useGetArchetypeRisksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetArchetypeRisksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetArchetypeRisksQuery,
    GQTypes.GQGetArchetypeRisksQueryVariables
  >({ query: GetArchetypeRisksDocument, ...options });
}
export const DeleteTcfdArchetypeRiskDocument = gql`
    mutation DeleteTcfdArchetypeRisk($input: DeleteTcfdArchetypeRiskInput!) {
  deleteTcfdArchetypeRisk(input: $input) {
    ...RiskArchetypeFields
  }
}
    ${RiskArchetypeFieldsFragmentDoc}`;

export function useDeleteTcfdArchetypeRiskMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteTcfdArchetypeRiskMutation,
    GQTypes.GQDeleteTcfdArchetypeRiskMutationVariables
  >(DeleteTcfdArchetypeRiskDocument);
}
export const CreateTcfdArchetypeRiskDocument = gql`
    mutation CreateTcfdArchetypeRisk($input: CreateTcfdArchetypeRiskInput!) {
  createTcfdArchetypeRisk(input: $input) {
    ...RiskArchetypeFields
  }
}
    ${RiskArchetypeFieldsFragmentDoc}`;

export function useCreateTcfdArchetypeRiskMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateTcfdArchetypeRiskMutation,
    GQTypes.GQCreateTcfdArchetypeRiskMutationVariables
  >(CreateTcfdArchetypeRiskDocument);
}
export const UpdateTcfdArchetypeRiskDocument = gql`
    mutation UpdateTcfdArchetypeRisk($input: UpdateTcfdArchetypeRiskInput!) {
  updateTcfdArchetypeRisk(input: $input) {
    ...RiskArchetypeFields
  }
}
    ${RiskArchetypeFieldsFragmentDoc}`;

export function useUpdateTcfdArchetypeRiskMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateTcfdArchetypeRiskMutation,
    GQTypes.GQUpdateTcfdArchetypeRiskMutationVariables
  >(UpdateTcfdArchetypeRiskDocument);
}
export const GetVendorMatchingTasksByOrgIdDocument = gql`
    query GetVendorMatchingTasksByOrgId($orgId: ID!) {
  vendorMatchingTasks(orgId: $orgId) {
    ...VendorMatchingTaskFieldsForAdmin
  }
}
    ${VendorMatchingTaskFieldsForAdminFragmentDoc}`;

export function useGetVendorMatchingTasksByOrgIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetVendorMatchingTasksByOrgIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetVendorMatchingTasksByOrgIdQuery,
    GQTypes.GQGetVendorMatchingTasksByOrgIdQueryVariables
  >({ query: GetVendorMatchingTasksByOrgIdDocument, ...options });
}
export const ToggleVendorMatchingTaskAutomappingDocument = gql`
    mutation ToggleVendorMatchingTaskAutomapping($vendorMatchingTaskId: ID!) {
  toggleVendorMatchingTaskAutomapping(vendorMatchingTaskId: $vendorMatchingTaskId)
}
    `;

export function useToggleVendorMatchingTaskAutomappingMutation() {
  return Urql.useMutation<
    GQTypes.GQToggleVendorMatchingTaskAutomappingMutation,
    GQTypes.GQToggleVendorMatchingTaskAutomappingMutationVariables
  >(ToggleVendorMatchingTaskAutomappingDocument);
}
export const InitializeDefaultSupplierViewAdminDocument = gql`
    mutation InitializeDefaultSupplierViewAdmin($input: InitializeDefaultSupplierViewAdminInput!) {
  initializeDefaultSupplierViewAdmin(input: $input) {
    view {
      ...SupplierViewFieldsAdmin
    }
  }
}
    ${SupplierViewFieldsAdminFragmentDoc}`;

export function useInitializeDefaultSupplierViewAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQInitializeDefaultSupplierViewAdminMutation,
    GQTypes.GQInitializeDefaultSupplierViewAdminMutationVariables
  >(InitializeDefaultSupplierViewAdminDocument);
}
export const ActiveWatershedEmployeeDocument = gql`
    query ActiveWatershedEmployee {
  activeWatershedEmployee {
    ...ActiveWatershedEmployeeFields
  }
}
    ${ActiveWatershedEmployeeFieldsFragmentDoc}`;

export function useActiveWatershedEmployeeQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQActiveWatershedEmployeeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActiveWatershedEmployeeQuery,
    GQTypes.GQActiveWatershedEmployeeQueryVariables
  >({ query: ActiveWatershedEmployeeDocument, ...options });
}
export const BusinessActivityTypesForTypeNameAndVersionPageDocument = gql`
    query businessActivityTypesForTypeNameAndVersionPage($versionName: String!, $typeName: String!) {
  businessActivityTypeVersions {
    id
    name
    recordStartAt
    recordEndAt
  }
  businessActivityTypeVersionByName(versionName: $versionName) {
    id
    name
    recordStartAt
    recordEndAt
  }
  businessActivityTypesForVersion(versionName: $versionName) {
    id
    versionId
    name
    description
  }
  businessActivityTypeWithFields(versionName: $versionName, typeName: $typeName) {
    ...AllBusinessActivityTypeFields
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypesForTypeNameAndVersionPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypesForTypeNameAndVersionPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypesForTypeNameAndVersionPageQuery,
    GQTypes.GQBusinessActivityTypesForTypeNameAndVersionPageQueryVariables
  >({
    query: BusinessActivityTypesForTypeNameAndVersionPageDocument,
    ...options,
  });
}
export const BusinessActivityTypesForVersionPageDocument = gql`
    query businessActivityTypesForVersionPage($versionName: String!) {
  businessActivityTypeVersions {
    id
    name
    recordStartAt
    recordEndAt
  }
  businessActivityTypeVersionByName(versionName: $versionName) {
    id
    name
    recordStartAt
    recordEndAt
  }
  businessActivityTypesForVersion(versionName: $versionName) {
    id
    versionId
    name
    description
  }
  businessActivityTypesPrimaryForVersionWithFields(versionName: $versionName) {
    ...AllBusinessActivityTypeFields
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypesForVersionPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypesForVersionPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypesForVersionPageQuery,
    GQTypes.GQBusinessActivityTypesForVersionPageQueryVariables
  >({ query: BusinessActivityTypesForVersionPageDocument, ...options });
}
export const BusinessActivityTypesPageDocument = gql`
    query businessActivityTypesPage {
  businessActivityTypeVersionLive {
    id
    name
    recordStartAt
    recordEndAt
  }
}
    `;

export function useBusinessActivityTypesPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypesPageQuery,
    GQTypes.GQBusinessActivityTypesPageQueryVariables
  >({ query: BusinessActivityTypesPageDocument, ...options });
}
export const CalculationTagsWithUsageDocument = gql`
    query CalculationTagsWithUsage {
  calculationTagsWithUsage {
    tag {
      id
      name
      createdAt
      updatedAt
    }
    referenceDataSources {
      id
      name
      updatedAt
      externalSourceNotes
    }
  }
}
    `;

export function useCalculationTagsWithUsageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCalculationTagsWithUsageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCalculationTagsWithUsageQuery,
    GQTypes.GQCalculationTagsWithUsageQueryVariables
  >({ query: CalculationTagsWithUsageDocument, ...options });
}
export const UpdateCalculationTagDocument = gql`
    mutation UpdateCalculationTag($input: UpdateCalculationTagInput!) {
  updateCalculationTag(input: $input) {
    success
  }
}
    `;

export function useUpdateCalculationTagMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCalculationTagMutation,
    GQTypes.GQUpdateCalculationTagMutationVariables
  >(UpdateCalculationTagDocument);
}
export const DeleteCalculationTagDocument = gql`
    mutation DeleteCalculationTag($input: DeleteCalculationTagInput!) {
  deleteCalculationTag(input: $input) {
    success
  }
}
    `;

export function useDeleteCalculationTagMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCalculationTagMutation,
    GQTypes.GQDeleteCalculationTagMutationVariables
  >(DeleteCalculationTagDocument);
}
export const CalculationTagsDocument = gql`
    query CalculationTags {
  calculationTags {
    name
    id
    createdAt
    updatedAt
  }
}
    `;

export function useCalculationTagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCalculationTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCalculationTagsQuery,
    GQTypes.GQCalculationTagsQueryVariables
  >({ query: CalculationTagsDocument, ...options });
}
export const GetCanonicalDatasetsWithCanonicalDatasourcesDocument = gql`
    query GetCanonicalDatasetsWithCanonicalDatasources {
  canonicalDatasets {
    ...CanonicalDatasetBaseFields
    canonicalDatasources {
      ...CanonicalDatasourceBaseFields
    }
  }
  allIntegrationPartners {
    ...IntegrationPartnerBaseFields
  }
}
    ${CanonicalDatasetBaseFieldsFragmentDoc}
${CanonicalDatasourceBaseFieldsFragmentDoc}
${IntegrationPartnerBaseFieldsFragmentDoc}`;

export function useGetCanonicalDatasetsWithCanonicalDatasourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCanonicalDatasetsWithCanonicalDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCanonicalDatasetsWithCanonicalDatasourcesQuery,
    GQTypes.GQGetCanonicalDatasetsWithCanonicalDatasourcesQueryVariables
  >({
    query: GetCanonicalDatasetsWithCanonicalDatasourcesDocument,
    ...options,
  });
}
export const CreateCanonicalDatasetDocument = gql`
    mutation CreateCanonicalDataset($input: CreateCanonicalDatasetInput!) {
  createCanonicalDataset(input: $input) {
    canonicalDataset {
      ...CanonicalDatasetBaseFields
    }
  }
}
    ${CanonicalDatasetBaseFieldsFragmentDoc}`;

export function useCreateCanonicalDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalDatasetMutation,
    GQTypes.GQCreateCanonicalDatasetMutationVariables
  >(CreateCanonicalDatasetDocument);
}
export const CreateCanonicalDatasourceDocument = gql`
    mutation CreateCanonicalDatasource($input: CreateCanonicalDatasourceInput!) {
  createCanonicalDatasource(input: $input) {
    canonicalDatasource {
      ...CanonicalDatasourceBaseFields
    }
  }
}
    ${CanonicalDatasourceBaseFieldsFragmentDoc}`;

export function useCreateCanonicalDatasourceMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalDatasourceMutation,
    GQTypes.GQCreateCanonicalDatasourceMutationVariables
  >(CreateCanonicalDatasourceDocument);
}
export const UpdateCanonicalDatasetDocument = gql`
    mutation UpdateCanonicalDataset($input: UpdateCanonicalDatasetInput!) {
  updateCanonicalDataset(input: $input) {
    canonicalDataset {
      ...CanonicalDatasetBaseFields
    }
  }
}
    ${CanonicalDatasetBaseFieldsFragmentDoc}`;

export function useUpdateCanonicalDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalDatasetMutation,
    GQTypes.GQUpdateCanonicalDatasetMutationVariables
  >(UpdateCanonicalDatasetDocument);
}
export const UpdateCanonicalDatasourceDocument = gql`
    mutation UpdateCanonicalDatasource($input: UpdateCanonicalDatasourceInput!) {
  updateCanonicalDatasource(input: $input) {
    canonicalDatasource {
      ...CanonicalDatasourceBaseFields
    }
  }
}
    ${CanonicalDatasourceBaseFieldsFragmentDoc}`;

export function useUpdateCanonicalDatasourceMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalDatasourceMutation,
    GQTypes.GQUpdateCanonicalDatasourceMutationVariables
  >(UpdateCanonicalDatasourceDocument);
}
export const CanonicalClimateProgramProjectPageDocument = gql`
    query CanonicalClimateProgramProjectPage($id: ID!) {
  canonicalClimateProgramProject(id: $id) {
    ...CanonicalClimateProgramProjectFields
    datasetRequirements {
      ...CanonicalProjectRequirementDatasetFields
    }
    ghgpRequirements {
      ...CanonicalProjectRequirementGhgpFields
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}
${CanonicalProjectRequirementDatasetFieldsFragmentDoc}
${CanonicalProjectRequirementGhgpFieldsFragmentDoc}`;

export function useCanonicalClimateProgramProjectPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanonicalClimateProgramProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanonicalClimateProgramProjectPageQuery,
    GQTypes.GQCanonicalClimateProgramProjectPageQueryVariables
  >({ query: CanonicalClimateProgramProjectPageDocument, ...options });
}
export const DeleteCanonicalClimateProgramProjectRequirementDatasetDocument = gql`
    mutation DeleteCanonicalClimateProgramProjectRequirementDataset($input: DeleteCanonicalClimateProgramProjectRequirementDatasetInput!) {
  deleteCanonicalClimateProgramProjectRequirementDataset(input: $input) {
    canonicalClimateProgramProject {
      ...CanonicalClimateProgramProjectFields
      datasetRequirements {
        ...CanonicalProjectRequirementDatasetFields
      }
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}
${CanonicalProjectRequirementDatasetFieldsFragmentDoc}`;

export function useDeleteCanonicalClimateProgramProjectRequirementDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCanonicalClimateProgramProjectRequirementDatasetMutation,
    GQTypes.GQDeleteCanonicalClimateProgramProjectRequirementDatasetMutationVariables
  >(DeleteCanonicalClimateProgramProjectRequirementDatasetDocument);
}
export const DeleteCanonicalClimateProgramProjectRequirementGhgpDocument = gql`
    mutation DeleteCanonicalClimateProgramProjectRequirementGhgp($input: DeleteCanonicalClimateProgramProjectRequirementGhgpInput!) {
  deleteCanonicalClimateProgramProjectRequirementGhgp(input: $input) {
    canonicalClimateProgramProject {
      ...CanonicalClimateProgramProjectFields
      ghgpRequirements {
        ...CanonicalProjectRequirementGhgpFields
      }
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}
${CanonicalProjectRequirementGhgpFieldsFragmentDoc}`;

export function useDeleteCanonicalClimateProgramProjectRequirementGhgpMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCanonicalClimateProgramProjectRequirementGhgpMutation,
    GQTypes.GQDeleteCanonicalClimateProgramProjectRequirementGhgpMutationVariables
  >(DeleteCanonicalClimateProgramProjectRequirementGhgpDocument);
}
export const CanonicalClimateProgramProjectsDocument = gql`
    query CanonicalClimateProgramProjects {
  canonicalClimateProgramProjects {
    ...CanonicalClimateProgramProjectFields
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}`;

export function useCanonicalClimateProgramProjectsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanonicalClimateProgramProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanonicalClimateProgramProjectsQuery,
    GQTypes.GQCanonicalClimateProgramProjectsQueryVariables
  >({ query: CanonicalClimateProgramProjectsDocument, ...options });
}
export const DeleteCanonicalClimateProgramProjectDocument = gql`
    mutation DeleteCanonicalClimateProgramProject($input: IdInput!) {
  deleteCanonicalClimateProgramProject(input: $input) {
    id
  }
}
    `;

export function useDeleteCanonicalClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCanonicalClimateProgramProjectMutation,
    GQTypes.GQDeleteCanonicalClimateProgramProjectMutationVariables
  >(DeleteCanonicalClimateProgramProjectDocument);
}
export const UnifiedPublicCompanyDisclosuresPageDocument = gql`
    query UnifiedPublicCompanyDisclosuresPage($companyId: ID!) {
  company(id: $companyId) {
    id
    name
    ...CompanyAutocompleteResult
  }
}
    ${CompanyAutocompleteResultFragmentDoc}`;

export function useUnifiedPublicCompanyDisclosuresPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUnifiedPublicCompanyDisclosuresPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUnifiedPublicCompanyDisclosuresPageQuery,
    GQTypes.GQUnifiedPublicCompanyDisclosuresPageQueryVariables
  >({ query: UnifiedPublicCompanyDisclosuresPageDocument, ...options });
}
export const GetCompanyForAdminDocument = gql`
    query GetCompanyForAdmin($id: ID!) {
  company(id: $id) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

export function useGetCompanyForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyForAdminQuery,
    GQTypes.GQGetCompanyForAdminQueryVariables
  >({ query: GetCompanyForAdminDocument, ...options });
}
export const GetCompanyMatchesDocument = gql`
    query GetCompanyMatches($id: ID!) {
  company(id: $id) {
    id
    bestMatchesForCompany {
      ...CompanyMatchFields
    }
  }
}
    ${CompanyMatchFieldsFragmentDoc}`;

export function useGetCompanyMatchesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyMatchesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyMatchesQuery,
    GQTypes.GQGetCompanyMatchesQueryVariables
  >({ query: GetCompanyMatchesDocument, ...options });
}
export const ReplaceCompanyDocument = gql`
    mutation ReplaceCompany($input: ReplaceCompanyInput!) {
  replaceCompany(input: $input) {
    success
    message
    fromCompany {
      id
    }
    toCompany {
      ...CompanyFieldsWithMatches
    }
  }
}
    ${CompanyFieldsWithMatchesFragmentDoc}`;

export function useReplaceCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQReplaceCompanyMutation,
    GQTypes.GQReplaceCompanyMutationVariables
  >(ReplaceCompanyDocument);
}
export const CompanySourceKeysPageDocument = gql`
    query CompanySourceKeysPage {
  companySourceKeys {
    ...CompanySourceKeyFields
  }
}
    ${CompanySourceKeyFieldsFragmentDoc}`;

export function useCompanySourceKeysPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanySourceKeysPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanySourceKeysPageQuery,
    GQTypes.GQCompanySourceKeysPageQueryVariables
  >({ query: CompanySourceKeysPageDocument, ...options });
}
export const CreateCompanySourceKeyDocument = gql`
    mutation CreateCompanySourceKey($input: CreateCompanySourceKeyInput!) {
  createCompanySourceKey(input: $input) {
    companySourceKey {
      ...CompanySourceKeyFields
    }
  }
}
    ${CompanySourceKeyFieldsFragmentDoc}`;

export function useCreateCompanySourceKeyMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCompanySourceKeyMutation,
    GQTypes.GQCreateCompanySourceKeyMutationVariables
  >(CreateCompanySourceKeyDocument);
}
export const UpdateCompanySourceKeyDocument = gql`
    mutation UpdateCompanySourceKey($input: UpdateCompanySourceKeyInput!) {
  updateCompanySourceKey(input: $input) {
    companySourceKey {
      ...CompanySourceKeyFields
    }
  }
}
    ${CompanySourceKeyFieldsFragmentDoc}`;

export function useUpdateCompanySourceKeyMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCompanySourceKeyMutation,
    GQTypes.GQUpdateCompanySourceKeyMutationVariables
  >(UpdateCompanySourceKeyDocument);
}
export const DeleteCompanySourceKeyDocument = gql`
    mutation DeleteCompanySourceKey($input: IdInput!) {
  deleteCompanySourceKey(input: $input) {
    id
  }
}
    `;

export function useDeleteCompanySourceKeyMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCompanySourceKeyMutation,
    GQTypes.GQDeleteCompanySourceKeyMutationVariables
  >(DeleteCompanySourceKeyDocument);
}
export const CustomerTargetSchemaDocument = gql`
    query CustomerTargetSchema($ctsId: ID!) {
  customerTargetSchema(id: $ctsId) {
    ...CtsInfoHeader
  }
  canonicalDatasets {
    id
    name
  }
}
    ${CtsInfoHeaderFragmentDoc}`;

export function useCustomerTargetSchemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomerTargetSchemaQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomerTargetSchemaQuery,
    GQTypes.GQCustomerTargetSchemaQueryVariables
  >({ query: CustomerTargetSchemaDocument, ...options });
}
export const CreateCtsVersionDocument = gql`
    mutation CreateCtsVersion($input: CreateCtsVersionInput!) {
  createCtsVersion(input: $input) {
    customerTargetSchema {
      id
      ...CtsInfoHeader
    }
  }
}
    ${CtsInfoHeaderFragmentDoc}`;

export function useCreateCtsVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCtsVersionMutation,
    GQTypes.GQCreateCtsVersionMutationVariables
  >(CreateCtsVersionDocument);
}
export const GlobalCustomerTargetSchemasDocument = gql`
    query GlobalCustomerTargetSchemas {
  customerTargetSchemas {
    ...CustomerTargetSchemaForListView
  }
  canonicalDatasets {
    id
    name
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useGlobalCustomerTargetSchemasQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGlobalCustomerTargetSchemasQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGlobalCustomerTargetSchemasQuery,
    GQTypes.GQGlobalCustomerTargetSchemasQueryVariables
  >({ query: GlobalCustomerTargetSchemasDocument, ...options });
}
export const DuplicateCtsDocument = gql`
    mutation DuplicateCts($input: DuplicateCtsInput!) {
  duplicateCts(input: $input) {
    schema {
      ...CustomerTargetSchemaForListView
    }
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useDuplicateCtsMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicateCtsMutation,
    GQTypes.GQDuplicateCtsMutationVariables
  >(DuplicateCtsDocument);
}
export const DeleteCtsDocument = gql`
    mutation DeleteCts($input: DeleteCtsInput!) {
  deleteCts(input: $input) {
    schema {
      ...CustomerTargetSchemaForListView
    }
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useDeleteCtsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCtsMutation,
    GQTypes.GQDeleteCtsMutationVariables
  >(DeleteCtsDocument);
}
export const DuckHuntPageDocument = gql`
    query DuckHuntPage {
  duckHuntLeaderboard {
    hunter {
      id
      name
    }
    numDucks
    lastFoundAt
  }
  ducksForHunter
}
    `;

export function useDuckHuntPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDuckHuntPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDuckHuntPageQuery,
    GQTypes.GQDuckHuntPageQueryVariables
  >({ query: DuckHuntPageDocument, ...options });
}
export const OrganizationEmailsSentDocument = gql`
    query OrganizationEmailsSent($orgId: ID) {
  emailsSent(orgId: $orgId) {
    ...EmailSentForAdminTable
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
      }
    }
  }
}
    ${EmailSentForAdminTableFragmentDoc}`;

export function useOrganizationEmailsSentQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationEmailsSentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationEmailsSentQuery,
    GQTypes.GQOrganizationEmailsSentQueryVariables
  >({ query: OrganizationEmailsSentDocument, ...options });
}
export const GetEmissionsModelEvaluationPageDataDocument = gql`
    query GetEmissionsModelEvaluationPageData($id: ID!) {
  emissionsModelEvaluation(id: $id) {
    id
    fingerprintCount
    missingTraceMetadata {
      ...EmeMissingTraceMetadataFields
    }
    calculationSampler {
      samples {
        rowId
        fingerprint
        equation {
          emissionsVariableName
          traceDetails {
            referenceValueDetails
            emissionsModelVariableDetails
            bartValueDetails
            emissionsModelVersionId
            emissionsModelEvaluationId
          }
          trace
        }
      }
    }
  }
}
    ${EmeMissingTraceMetadataFieldsFragmentDoc}`;

export function useGetEmissionsModelEvaluationPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelEvaluationPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelEvaluationPageDataQuery,
    GQTypes.GQGetEmissionsModelEvaluationPageDataQueryVariables
  >({ query: GetEmissionsModelEvaluationPageDataDocument, ...options });
}
export const GetEmissionsModelPageDataDocument = gql`
    query GetEmissionsModelPageData($id: ID!) {
  emissionsModelStable(id: $id) {
    ...EmissionsModelStableFields
  }
  businessActivityTypeVersionLive {
    id
  }
}
    ${EmissionsModelStableFieldsFragmentDoc}`;

export function useGetEmissionsModelPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelPageDataQuery,
    GQTypes.GQGetEmissionsModelPageDataQueryVariables
  >({ query: GetEmissionsModelPageDataDocument, ...options });
}
export const GetEmissionsModelVersionImportedDescendantsFromDirectImportIdsDocument = gql`
    query GetEmissionsModelVersionImportedDescendantsFromDirectImportIds($ids: [ID!]!) {
  emissionsModelVersionImportedDescendantsFromDirectImportIds(ids: $ids) {
    ...EmissionsModelVersionFieldsForImportedDescendants
  }
}
    ${EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc}`;

export function useGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQuery,
    GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQueryVariables
  >({
    query:
      GetEmissionsModelVersionImportedDescendantsFromDirectImportIdsDocument,
    ...options,
  });
}
export const UpdateEmissionsModelStableDocument = gql`
    mutation UpdateEmissionsModelStable($input: UpdateEmissionsModelStableInput!) {
  updateEmissionsModelStable(input: $input) {
    emissionsModelStable {
      ...EmissionsModelStableBaseFields
    }
  }
}
    ${EmissionsModelStableBaseFieldsFragmentDoc}`;

export function useUpdateEmissionsModelStableMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEmissionsModelStableMutation,
    GQTypes.GQUpdateEmissionsModelStableMutationVariables
  >(UpdateEmissionsModelStableDocument);
}
export const GetEmissionsModelTableDataDocument = gql`
    query GetEmissionsModelTableData($showArchived: Boolean!, $emissionsModelListTab: String) {
  emissionsModelsStable(
    showArchived: $showArchived
    emissionsModelListTab: $emissionsModelListTab
  ) {
    id
    title
    description
    scope
    isArchived
    org {
      id
      name
    }
    latestPublishedVersion {
      id
      createdAt
    }
    matchers {
      id
      businessActivityType
    }
    releases {
      ...EmissionsModelReleaseMetadata
    }
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useGetEmissionsModelTableDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelTableDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelTableDataQuery,
    GQTypes.GQGetEmissionsModelTableDataQueryVariables
  >({ query: GetEmissionsModelTableDataDocument, ...options });
}
export const WatershedEmployeesPageDocument = gql`
    query WatershedEmployeesPage {
  watershedEmployees {
    edges {
      node {
        id
        name
        email
      }
    }
  }
}
    `;

export function useWatershedEmployeesPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQWatershedEmployeesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQWatershedEmployeesPageQuery,
    GQTypes.GQWatershedEmployeesPageQueryVariables
  >({ query: WatershedEmployeesPageDocument, ...options });
}
export const AddWatershedEmployeeDocument = gql`
    mutation AddWatershedEmployee($input: AddWatershedEmployeeInput!) {
  addWatershedEmployee(input: $input) {
    watershedEmployee {
      id
      name
      email
    }
  }
}
    `;

export function useAddWatershedEmployeeMutation() {
  return Urql.useMutation<
    GQTypes.GQAddWatershedEmployeeMutation,
    GQTypes.GQAddWatershedEmployeeMutationVariables
  >(AddWatershedEmployeeDocument);
}
export const EngagementTaskConfigAdminDocument = gql`
    query EngagementTaskConfigAdmin($id: ID!) {
  engagementTaskConfig(engagementTaskConfigId: $id) {
    ...EngagementTaskConfigFieldsAdmin
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useEngagementTaskConfigAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskConfigAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskConfigAdminQuery,
    GQTypes.GQEngagementTaskConfigAdminQueryVariables
  >({ query: EngagementTaskConfigAdminDocument, ...options });
}
export const UpdateEngagementTaskConfigAdminDocument = gql`
    mutation UpdateEngagementTaskConfigAdmin($input: EngagementTaskConfigInputForAdmin!) {
  updateEngagementTaskConfigForAdmin(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigFieldsAdmin
    }
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useUpdateEngagementTaskConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEngagementTaskConfigAdminMutation,
    GQTypes.GQUpdateEngagementTaskConfigAdminMutationVariables
  >(UpdateEngagementTaskConfigAdminDocument);
}
export const GlobalEngagementTaskConfigsAdminDocument = gql`
    query GlobalEngagementTaskConfigsAdmin {
  globalEngagementTaskConfigs {
    ...EngagementTaskConfigFieldsAdmin
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useGlobalEngagementTaskConfigsAdminQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGlobalEngagementTaskConfigsAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGlobalEngagementTaskConfigsAdminQuery,
    GQTypes.GQGlobalEngagementTaskConfigsAdminQueryVariables
  >({ query: GlobalEngagementTaskConfigsAdminDocument, ...options });
}
export const OrgSpecificEngagementTaskConfigsAdminDocument = gql`
    query OrgSpecificEngagementTaskConfigsAdmin($orgId: ID!) {
  engagementTaskConfigs(
    orgId: $orgId
    includeGlobalConfigs: false
    includeUnpublishedOrgSpecific: true
  ) {
    ...EngagementTaskConfigFieldsAdmin
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useOrgSpecificEngagementTaskConfigsAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgSpecificEngagementTaskConfigsAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgSpecificEngagementTaskConfigsAdminQuery,
    GQTypes.GQOrgSpecificEngagementTaskConfigsAdminQueryVariables
  >({ query: OrgSpecificEngagementTaskConfigsAdminDocument, ...options });
}
export const CreateEngagementTaskConfigAdminDocument = gql`
    mutation CreateEngagementTaskConfigAdmin($input: EngagementTaskConfigCreateInputForAdmin!) {
  createEngagementTaskConfigForAdmin(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigFieldsAdmin
    }
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useCreateEngagementTaskConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEngagementTaskConfigAdminMutation,
    GQTypes.GQCreateEngagementTaskConfigAdminMutationVariables
  >(CreateEngagementTaskConfigAdminDocument);
}
export const DeleteEngagementTaskConfigAdminDocument = gql`
    mutation DeleteEngagementTaskConfigAdmin($input: IdInput!) {
  deleteEngagementTaskConfigForAdmin(input: $input) {
    id
  }
}
    `;

export function useDeleteEngagementTaskConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEngagementTaskConfigAdminMutation,
    GQTypes.GQDeleteEngagementTaskConfigAdminMutationVariables
  >(DeleteEngagementTaskConfigAdminDocument);
}
export const PublishEngagementTaskConfigAdminDocument = gql`
    mutation PublishEngagementTaskConfigAdmin($input: PublishEngagementTaskConfigForAdminInput!) {
  publishEngagementTaskConfigForAdmin(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigFieldsAdmin
    }
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function usePublishEngagementTaskConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishEngagementTaskConfigAdminMutation,
    GQTypes.GQPublishEngagementTaskConfigAdminMutationVariables
  >(PublishEngagementTaskConfigAdminDocument);
}
export const CopyEngagementTaskConfigToOrgForAdminDocument = gql`
    mutation CopyEngagementTaskConfigToOrgForAdmin($input: CopyEngagementTaskConfigToOrgForAdminInput!) {
  copyEngagementTaskConfigToOrgForAdmin(input: $input) {
    ...EngagementTaskConfigFieldsAdmin
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useCopyEngagementTaskConfigToOrgForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCopyEngagementTaskConfigToOrgForAdminMutation,
    GQTypes.GQCopyEngagementTaskConfigToOrgForAdminMutationVariables
  >(CopyEngagementTaskConfigToOrgForAdminDocument);
}
export const FeatureFlagsDocument = gql`
    query FeatureFlags {
  featureFlags {
    edges {
      node {
        ...FeatureFlagDetails
      }
    }
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
      }
    }
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

export function useFeatureFlagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFeatureFlagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFeatureFlagsQuery,
    GQTypes.GQFeatureFlagsQueryVariables
  >({ query: FeatureFlagsDocument, ...options });
}
export const SetOrgsForFeatureFlagDocument = gql`
    mutation SetOrgsForFeatureFlag($input: SetOrgsForFeatureFlagInput!) {
  setOrgsForFeatureFlag(input: $input) {
    featureFlag {
      ...FeatureFlagDetails
    }
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

export function useSetOrgsForFeatureFlagMutation() {
  return Urql.useMutation<
    GQTypes.GQSetOrgsForFeatureFlagMutation,
    GQTypes.GQSetOrgsForFeatureFlagMutationVariables
  >(SetOrgsForFeatureFlagDocument);
}
export const UpdateOrgForFeatureFlagDocument = gql`
    mutation UpdateOrgForFeatureFlag($input: UpdateOrgForFeatureFlagInput!) {
  updateOrgForFeatureFlag(input: $input) {
    featureFlag {
      ...FeatureFlagDetails
    }
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

export function useUpdateOrgForFeatureFlagMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgForFeatureFlagMutation,
    GQTypes.GQUpdateOrgForFeatureFlagMutationVariables
  >(UpdateOrgForFeatureFlagDocument);
}
export const SyncFeatureFlagsDocument = gql`
    mutation SyncFeatureFlags($input: SyncFeatureFlagsInput!) {
  syncFeatureFlags(input: $input) {
    diff {
      add
      remove
    }
    featureFlags {
      ...FeatureFlagDetails
    }
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

export function useSyncFeatureFlagsMutation() {
  return Urql.useMutation<
    GQTypes.GQSyncFeatureFlagsMutation,
    GQTypes.GQSyncFeatureFlagsMutationVariables
  >(SyncFeatureFlagsDocument);
}
export const GetFootprintDebugDocument = gql`
    query GetFootprintDebug($fpsId: ID!) {
  footprintSnapshot(id: $fpsId) {
    ...FootprintSnapshotForFootprintDebugger
  }
  footprintDebug(fpsId: $fpsId) {
    id
    appliedExclusionRules {
      ...FootprintSnapshotExclusionRuleForFootprintDebugger
    }
    cleanPowerImpact {
      ...CleanPowerImpactForFootprintDebugger
    }
  }
}
    ${FootprintSnapshotForFootprintDebuggerFragmentDoc}
${FootprintSnapshotExclusionRuleForFootprintDebuggerFragmentDoc}
${CleanPowerImpactForFootprintDebuggerFragmentDoc}`;

export function useGetFootprintDebugQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintDebugQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintDebugQuery,
    GQTypes.GQGetFootprintDebugQueryVariables
  >({ query: GetFootprintDebugDocument, ...options });
}
export const GenerateGmailXmlDocument = gql`
    mutation GenerateGmailXml($input: GenerateGmailXmlInput!) {
  generateGmailXml(input: $input) {
    xml
  }
}
    `;

export function useGenerateGmailXmlMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateGmailXmlMutation,
    GQTypes.GQGenerateGmailXmlMutationVariables
  >(GenerateGmailXmlDocument);
}
export const IntegrationConnectionDocument = gql`
    query IntegrationConnection($id: ID!) {
  integrationConnection(id: $id) {
    ...IntegrationConnectionFields
  }
}
    ${IntegrationConnectionFieldsFragmentDoc}`;

export function useIntegrationConnectionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQIntegrationConnectionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIntegrationConnectionQuery,
    GQTypes.GQIntegrationConnectionQueryVariables
  >({ query: IntegrationConnectionDocument, ...options });
}
export const IntegrationRequestsPaginatedDocument = gql`
    query IntegrationRequestsPaginated($integrationConnectionId: ID!, $first: Int, $last: Int, $after: String, $before: String) {
  integrationConnection(id: $integrationConnectionId) {
    id
    integrationRequests(first: $first, last: $last, after: $after, before: $before) {
      edges {
        node {
          ...IntegrationRequestFields
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${IntegrationRequestFieldsFragmentDoc}`;

export function useIntegrationRequestsPaginatedQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQIntegrationRequestsPaginatedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIntegrationRequestsPaginatedQuery,
    GQTypes.GQIntegrationRequestsPaginatedQueryVariables
  >({ query: IntegrationRequestsPaginatedDocument, ...options });
}
export const IntegrationDataPullsDocument = gql`
    query IntegrationDataPulls($ids: [ID!]!) {
  integrationDataPulls(ids: $ids) {
    ...IntegrationDataPullFields
  }
}
    ${IntegrationDataPullFieldsFragmentDoc}`;

export function useIntegrationDataPullsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQIntegrationDataPullsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIntegrationDataPullsQuery,
    GQTypes.GQIntegrationDataPullsQueryVariables
  >({ query: IntegrationDataPullsDocument, ...options });
}
export const UpdateIntegrationConnectionDocument = gql`
    mutation UpdateIntegrationConnection($input: UpdateIntegrationConnectionInput!) {
  updateIntegrationConnection(input: $input) {
    integrationConnection {
      id
      netsuiteIntegrationSettings {
        id
        transactionTypeFilters
        accountNamesToExclude
        usePostingPeriodForDateFilters
      }
    }
  }
}
    `;

export function useUpdateIntegrationConnectionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateIntegrationConnectionMutation,
    GQTypes.GQUpdateIntegrationConnectionMutationVariables
  >(UpdateIntegrationConnectionDocument);
}
export const UnassociateIntegrationConnectionFromDatasourceDocument = gql`
    mutation UnassociateIntegrationConnectionFromDatasource($input: UnassociateIntegrationConnectionFromDatasourceInput!) {
  unassociateIntegrationConnectionFromDatasource(input: $input) {
    integrationConnection {
      id
      datasources {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    `;

export function useUnassociateIntegrationConnectionFromDatasourceMutation() {
  return Urql.useMutation<
    GQTypes.GQUnassociateIntegrationConnectionFromDatasourceMutation,
    GQTypes.GQUnassociateIntegrationConnectionFromDatasourceMutationVariables
  >(UnassociateIntegrationConnectionFromDatasourceDocument);
}
export const IntegrationsIndexPageDocument = gql`
    query IntegrationsIndexPage {
  integrationConnections {
    edges {
      node {
        ...IntegrationConnectionListFields
      }
    }
  }
}
    ${IntegrationConnectionListFieldsFragmentDoc}`;

export function useIntegrationsIndexPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQIntegrationsIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIntegrationsIndexPageQuery,
    GQTypes.GQIntegrationsIndexPageQueryVariables
  >({ query: IntegrationsIndexPageDocument, ...options });
}
export const StartWorkflowDocument = gql`
    mutation StartWorkflow($input: StartWorkflowInput!) {
  startWorkflow(input: $input) {
    workflowExecution {
      workflowId
      runId
    }
  }
}
    `;

export function useStartWorkflowMutation() {
  return Urql.useMutation<
    GQTypes.GQStartWorkflowMutation,
    GQTypes.GQStartWorkflowMutationVariables
  >(StartWorkflowDocument);
}
export const CheckAdminAuthenticationDocument = gql`
    query CheckAdminAuthentication {
  activeWatershedEmployee {
    id
  }
}
    `;

export function useCheckAdminAuthenticationQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCheckAdminAuthenticationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCheckAdminAuthenticationQuery,
    GQTypes.GQCheckAdminAuthenticationQueryVariables
  >({ query: CheckAdminAuthenticationDocument, ...options });
}
export const MarketplaceProjectArchetypeDocument = gql`
    query MarketplaceProjectArchetype($id: ID!) {
  marketplaceProjectArchetype(id: $id) {
    ...MarketplaceProjectArchetypeFields
  }
}
    ${MarketplaceProjectArchetypeFieldsFragmentDoc}`;

export function useMarketplaceProjectArchetypeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectArchetypeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectArchetypeQuery,
    GQTypes.GQMarketplaceProjectArchetypeQueryVariables
  >({ query: MarketplaceProjectArchetypeDocument, ...options });
}
export const MarketplaceProjectArchetypesDocument = gql`
    query MarketplaceProjectArchetypes {
  marketplaceProjectArchetypes {
    edges {
      node {
        ...MarketplaceProjectArchetypeListFields
      }
    }
  }
}
    ${MarketplaceProjectArchetypeListFieldsFragmentDoc}`;

export function useMarketplaceProjectArchetypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectArchetypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectArchetypesQuery,
    GQTypes.GQMarketplaceProjectArchetypesQueryVariables
  >({ query: MarketplaceProjectArchetypesDocument, ...options });
}
export const MarketplaceDeveloperPageDocument = gql`
    query MarketplaceDeveloperPage($id: ID!) {
  marketplaceDeveloper(id: $id) {
    ...MarketplaceDeveloperWithProjectsFields
  }
}
    ${MarketplaceDeveloperWithProjectsFieldsFragmentDoc}`;

export function useMarketplaceDeveloperPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceDeveloperPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceDeveloperPageQuery,
    GQTypes.GQMarketplaceDeveloperPageQueryVariables
  >({ query: MarketplaceDeveloperPageDocument, ...options });
}
export const CreateMarketplaceDeveloperDocument = gql`
    mutation CreateMarketplaceDeveloper($input: CreateMarketplaceDeveloperInput!) {
  createMarketplaceDeveloper(input: $input) {
    marketplaceDeveloper {
      ...MarketplaceDeveloperFields
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}`;

export function useCreateMarketplaceDeveloperMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceDeveloperMutation,
    GQTypes.GQCreateMarketplaceDeveloperMutationVariables
  >(CreateMarketplaceDeveloperDocument);
}
export const UpdateMarketplaceDeveloperDocument = gql`
    mutation UpdateMarketplaceDeveloper($input: UpdateMarketplaceDeveloperInput!) {
  updateMarketplaceDeveloper(input: $input) {
    marketplaceDeveloper {
      ...MarketplaceDeveloperFields
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}`;

export function useUpdateMarketplaceDeveloperMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceDeveloperMutation,
    GQTypes.GQUpdateMarketplaceDeveloperMutationVariables
  >(UpdateMarketplaceDeveloperDocument);
}
export const DeleteMarketplaceDeveloperDocument = gql`
    mutation DeleteMarketplaceDeveloper($input: DeleteMarketplaceDeveloperInput!) {
  deleteMarketplaceDeveloper(input: $input) {
    marketplaceDeveloper {
      ...MarketplaceDeveloperFields
    }
    marketplaceProjects {
      ...MarketplaceProjectFields
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}
${MarketplaceProjectFieldsFragmentDoc}`;

export function useDeleteMarketplaceDeveloperMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplaceDeveloperMutation,
    GQTypes.GQDeleteMarketplaceDeveloperMutationVariables
  >(DeleteMarketplaceDeveloperDocument);
}
export const MarketplaceDevelopersListPageDocument = gql`
    query MarketplaceDevelopersListPage {
  marketplaceDevelopers {
    edges {
      node {
        ...MarketplaceDeveloperListFields
      }
    }
  }
}
    ${MarketplaceDeveloperListFieldsFragmentDoc}`;

export function useMarketplaceDevelopersListPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceDevelopersListPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceDevelopersListPageQuery,
    GQTypes.GQMarketplaceDevelopersListPageQueryVariables
  >({ query: MarketplaceDevelopersListPageDocument, ...options });
}
export const MarketplaceDocumentPageDocument = gql`
    query MarketplaceDocumentPage($id: ID!) {
  marketplaceDocument(id: $id) {
    ...MarketplaceDocumentForDetailPage
  }
}
    ${MarketplaceDocumentForDetailPageFragmentDoc}`;

export function useMarketplaceDocumentPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceDocumentPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceDocumentPageQuery,
    GQTypes.GQMarketplaceDocumentPageQueryVariables
  >({ query: MarketplaceDocumentPageDocument, ...options });
}
export const UpdateMarketplaceDocumentDocument = gql`
    mutation UpdateMarketplaceDocument($input: UpdateMarketplaceDocumentInput!) {
  updateMarketplaceDocument(input: $input) {
    document {
      ...MarketplaceDocumentForDetailPage
    }
  }
}
    ${MarketplaceDocumentForDetailPageFragmentDoc}`;

export function useUpdateMarketplaceDocumentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceDocumentMutation,
    GQTypes.GQUpdateMarketplaceDocumentMutationVariables
  >(UpdateMarketplaceDocumentDocument);
}
export const DeleteMarketplaceDocumentDocument = gql`
    mutation DeleteMarketplaceDocument($input: DeleteMarketplaceDocumentInput!) {
  deleteMarketplaceDocument(input: $input) {
    document {
      ...MarketplaceDocumentForDetailPage
    }
  }
}
    ${MarketplaceDocumentForDetailPageFragmentDoc}`;

export function useDeleteMarketplaceDocumentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplaceDocumentMutation,
    GQTypes.GQDeleteMarketplaceDocumentMutationVariables
  >(DeleteMarketplaceDocumentDocument);
}
export const MarketplaceDocumentsDocument = gql`
    query MarketplaceDocuments {
  marketplaceDocuments {
    edges {
      node {
        ...MarketplaceDocumentListFields
      }
    }
  }
}
    ${MarketplaceDocumentListFieldsFragmentDoc}`;

export function useMarketplaceDocumentsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceDocumentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceDocumentsQuery,
    GQTypes.GQMarketplaceDocumentsQueryVariables
  >({ query: MarketplaceDocumentsDocument, ...options });
}
export const MarketplaceIndexPageDocument = gql`
    query MarketplaceIndexPage {
  marketplacePurchases {
    edges {
      node {
        ...MarketplacePurchaseListFields
      }
    }
  }
  marketplacePurchaseLineItems {
    edges {
      node {
        ...MarketplacePurchaseLineItemFields
      }
    }
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
      }
    }
  }
}
    ${MarketplacePurchaseListFieldsFragmentDoc}
${MarketplacePurchaseLineItemFieldsFragmentDoc}`;

export function useMarketplaceIndexPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceIndexPageQuery,
    GQTypes.GQMarketplaceIndexPageQueryVariables
  >({ query: MarketplaceIndexPageDocument, ...options });
}
export const CreateMarketplacePurchaseDocument = gql`
    mutation CreateMarketplacePurchase($input: CreateMarketplacePurchaseInput!) {
  createMarketplacePurchase(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
  }
}
    ${MarketplacePurchaseFieldsFragmentDoc}`;

export function useCreateMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplacePurchaseMutation,
    GQTypes.GQCreateMarketplacePurchaseMutationVariables
  >(CreateMarketplacePurchaseDocument);
}
export const MarketplaceProjectOfferingDocument = gql`
    query MarketplaceProjectOffering($id: ID!) {
  marketplaceProjectOffering(id: $id) {
    ...MarketplaceProjectOfferingFields
  }
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}`;

export function useMarketplaceProjectOfferingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectOfferingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectOfferingQuery,
    GQTypes.GQMarketplaceProjectOfferingQueryVariables
  >({ query: MarketplaceProjectOfferingDocument, ...options });
}
export const MarketplaceProjectOfferingUpdatesDocument = gql`
    query MarketplaceProjectOfferingUpdates($baseOfferingId: ID!) {
  marketplaceProjectOfferingUpdates(baseOfferingId: $baseOfferingId) {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}`;

export function useMarketplaceProjectOfferingUpdatesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectOfferingUpdatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectOfferingUpdatesQuery,
    GQTypes.GQMarketplaceProjectOfferingUpdatesQueryVariables
  >({ query: MarketplaceProjectOfferingUpdatesDocument, ...options });
}
export const MarketplaceProjectOfferingsDocument = gql`
    query MarketplaceProjectOfferings {
  marketplaceProjectOfferings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
  marketplaceProjectArchetypes {
    edges {
      node {
        id
        name
      }
    }
  }
  marketplaceSuppliers {
    edges {
      node {
        id
        name
      }
    }
  }
  marketplaceProjects {
    edges {
      node {
        id
        name
        deletedAt
        archetype {
          id
        }
      }
    }
  }
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}`;

export function useMarketplaceProjectOfferingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectOfferingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectOfferingsQuery,
    GQTypes.GQMarketplaceProjectOfferingsQueryVariables
  >({ query: MarketplaceProjectOfferingsDocument, ...options });
}
export const GetEacPriceEstimatesDocument = gql`
    query GetEacPriceEstimates {
  eacPriceEstimates {
    ...EacPriceEstimateFields
  }
}
    ${EacPriceEstimateFieldsFragmentDoc}`;

export function useGetEacPriceEstimatesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEacPriceEstimatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEacPriceEstimatesQuery,
    GQTypes.GQGetEacPriceEstimatesQueryVariables
  >({ query: GetEacPriceEstimatesDocument, ...options });
}
export const CreateEacPriceEstimateDocument = gql`
    mutation CreateEacPriceEstimate($input: CreateEacPriceEstimateInput!) {
  createEacPriceEstimate(input: $input) {
    id
    eacPriceEstimate {
      ...EacPriceEstimateFields
    }
  }
}
    ${EacPriceEstimateFieldsFragmentDoc}`;

export function useCreateEacPriceEstimateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEacPriceEstimateMutation,
    GQTypes.GQCreateEacPriceEstimateMutationVariables
  >(CreateEacPriceEstimateDocument);
}
export const UpdateEacPriceEstimateDocument = gql`
    mutation UpdateEacPriceEstimate($input: UpdateEacPriceEstimateInput!) {
  updateEacPriceEstimate(input: $input) {
    id
    eacPriceEstimate {
      ...EacPriceEstimateFields
    }
  }
}
    ${EacPriceEstimateFieldsFragmentDoc}`;

export function useUpdateEacPriceEstimateMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEacPriceEstimateMutation,
    GQTypes.GQUpdateEacPriceEstimateMutationVariables
  >(UpdateEacPriceEstimateDocument);
}
export const UpdateEacPriceEstimatesDocument = gql`
    mutation UpdateEacPriceEstimates($input: UpdateEacPriceEstimatesInput!) {
  updateEacPriceEstimates(input: $input) {
    eacPriceEstimates {
      ...EacPriceEstimateFields
    }
  }
}
    ${EacPriceEstimateFieldsFragmentDoc}`;

export function useUpdateEacPriceEstimatesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEacPriceEstimatesMutation,
    GQTypes.GQUpdateEacPriceEstimatesMutationVariables
  >(UpdateEacPriceEstimatesDocument);
}
export const DeleteEacPriceEstimateDocument = gql`
    mutation DeleteEacPriceEstimate($input: DeleteEacPriceEstimateInput!) {
  deleteEacPriceEstimate(input: $input) {
    id
  }
}
    `;

export function useDeleteEacPriceEstimateMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEacPriceEstimateMutation,
    GQTypes.GQDeleteEacPriceEstimateMutationVariables
  >(DeleteEacPriceEstimateDocument);
}
export const MarketplaceProjectDocument = gql`
    query MarketplaceProject($id: ID!) {
  marketplaceProject(id: $id) {
    ...MarketplaceProjectFields
  }
}
    ${MarketplaceProjectFieldsFragmentDoc}`;

export function useMarketplaceProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectQuery,
    GQTypes.GQMarketplaceProjectQueryVariables
  >({ query: MarketplaceProjectDocument, ...options });
}
export const DeleteMarketplaceProjectDocument = gql`
    mutation DeleteMarketplaceProject($input: DeleteMarketplaceProjectInput!) {
  deleteMarketplaceProject(input: $input) {
    marketplaceProject {
      ...MarketplaceProjectFields
    }
  }
}
    ${MarketplaceProjectFieldsFragmentDoc}`;

export function useDeleteMarketplaceProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplaceProjectMutation,
    GQTypes.GQDeleteMarketplaceProjectMutationVariables
  >(DeleteMarketplaceProjectDocument);
}
export const MarketplaceProjectsDocument = gql`
    query MarketplaceProjects($supplierId: ID) {
  marketplaceProjects(supplierId: $supplierId) {
    edges {
      node {
        ...MarketplaceProjectListFields
      }
    }
  }
}
    ${MarketplaceProjectListFieldsFragmentDoc}`;

export function useMarketplaceProjectsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectsQuery,
    GQTypes.GQMarketplaceProjectsQueryVariables
  >({ query: MarketplaceProjectsDocument, ...options });
}
export const MarketplacePurchaseDocument = gql`
    query MarketplacePurchase($id: ID!) {
  marketplacePurchase(id: $id) {
    ...MarketplacePurchaseFieldsForDetailPage
  }
}
    ${MarketplacePurchaseFieldsForDetailPageFragmentDoc}`;

export function useMarketplacePurchaseQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplacePurchaseQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplacePurchaseQuery,
    GQTypes.GQMarketplacePurchaseQueryVariables
  >({ query: MarketplacePurchaseDocument, ...options });
}
export const UpdateMarketplacePurchaseDocument = gql`
    mutation UpdateMarketplacePurchase($input: UpdateMarketplacePurchaseInput!) {
  updateMarketplacePurchase(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFieldsForDetailPage
    }
  }
}
    ${MarketplacePurchaseFieldsForDetailPageFragmentDoc}`;

export function useUpdateMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplacePurchaseMutation,
    GQTypes.GQUpdateMarketplacePurchaseMutationVariables
  >(UpdateMarketplacePurchaseDocument);
}
export const DeleteMarketplacePurchaseDocument = gql`
    mutation DeleteMarketplacePurchase($input: DeleteMarketplacePurchaseInput!) {
  deleteMarketplacePurchase(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFieldsForDetailPage
    }
  }
}
    ${MarketplacePurchaseFieldsForDetailPageFragmentDoc}`;

export function useDeleteMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplacePurchaseMutation,
    GQTypes.GQDeleteMarketplacePurchaseMutationVariables
  >(DeleteMarketplacePurchaseDocument);
}
export const MarketplacePurchaseLineItemDocument = gql`
    query MarketplacePurchaseLineItem($id: ID!) {
  marketplacePurchaseLineItem(id: $id) {
    ...MarketplacePurchaseLineItemForPurchaseLineItemPage
  }
}
    ${MarketplacePurchaseLineItemForPurchaseLineItemPageFragmentDoc}`;

export function useMarketplacePurchaseLineItemQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplacePurchaseLineItemQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplacePurchaseLineItemQuery,
    GQTypes.GQMarketplacePurchaseLineItemQueryVariables
  >({ query: MarketplacePurchaseLineItemDocument, ...options });
}
export const MarketplaceSupplierDocument = gql`
    query MarketplaceSupplier($id: ID!) {
  marketplaceSupplier(id: $id) {
    ...MarketplaceSupplierFields
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useMarketplaceSupplierQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceSupplierQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceSupplierQuery,
    GQTypes.GQMarketplaceSupplierQueryVariables
  >({ query: MarketplaceSupplierDocument, ...options });
}
export const MarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeDocument = gql`
    mutation MarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployee($input: RemoveMarketplaceSupplierWatershedEmployeeInput!) {
  removeMarketplaceSupplierWatershedEmployee(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useMarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeMutation() {
  return Urql.useMutation<
    GQTypes.GQMarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeMutation,
    GQTypes.GQMarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeMutationVariables
  >(MarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeDocument);
}
export const MarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactDocument = gql`
    mutation MarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContact($input: RemoveMarketplaceSupplierPointOfContactInput!) {
  removeMarketplaceSupplierPointOfContact(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useMarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactMutation() {
  return Urql.useMutation<
    GQTypes.GQMarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactMutation,
    GQTypes.GQMarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactMutationVariables
  >(MarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactDocument);
}
export const MarketplaceSuppliersDocument = gql`
    query MarketplaceSuppliers {
  marketplaceSuppliers {
    edges {
      node {
        ...MarketplaceSupplierListFields
      }
    }
  }
}
    ${MarketplaceSupplierListFieldsFragmentDoc}`;

export function useMarketplaceSuppliersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceSuppliersQuery,
    GQTypes.GQMarketplaceSuppliersQueryVariables
  >({ query: MarketplaceSuppliersDocument, ...options });
}
export const GetObjectDocument = gql`
    query GetObject($id: ID!) {
  object(id: $id) {
    id
    model
    data
  }
}
    `;

export function useGetObjectQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetObjectQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetObjectQuery,
    GQTypes.GQGetObjectQueryVariables
  >({ query: GetObjectDocument, ...options });
}
export const GetDataRegistryBatchMigratorRunDocument = gql`
    query GetDataRegistryBatchMigratorRun($batchMigratorRunId: ID!) {
  dataRegistryBatchMigratorRun(batchMigratorRunId: $batchMigratorRunId) {
    id
    createdAt
    description
    status
    workflowId
    fromSchemaVersion {
      id
      schema {
        id
        name
      }
      version
      schemaJson
    }
    toSchemaVersion {
      id
      schema {
        id
        name
      }
      version
      schemaJson
    }
    filters
    error
    successCount
    failureCount
    region
  }
}
    `;

export function useGetDataRegistryBatchMigratorRunQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDataRegistryBatchMigratorRunQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDataRegistryBatchMigratorRunQuery,
    GQTypes.GQGetDataRegistryBatchMigratorRunQueryVariables
  >({ query: GetDataRegistryBatchMigratorRunDocument, ...options });
}
export const GetDataRegistryMigratorRunsDocument = gql`
    query GetDataRegistryMigratorRuns($batchMigratorRunId: ID!, $first: Int, $after: String, $filters: DataRegistryMigratorRunFilters, $searchText: String) {
  dataRegistryMigratorRuns(
    batchMigratorRunId: $batchMigratorRunId
    first: $first
    after: $after
    filters: $filters
    searchText: $searchText
  ) {
    edges {
      node {
        ...DataRegistryMigratorRunSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${DataRegistryMigratorRunSchemaFragmentDoc}`;

export function useGetDataRegistryMigratorRunsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDataRegistryMigratorRunsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDataRegistryMigratorRunsQuery,
    GQTypes.GQGetDataRegistryMigratorRunsQueryVariables
  >({ query: GetDataRegistryMigratorRunsDocument, ...options });
}
export const UpdateDataRegistryBatchMigratorRunDocument = gql`
    mutation UpdateDataRegistryBatchMigratorRun($input: UpdateDataRegistryBatchMigratorRunInput!) {
  updateDataRegistryBatchMigratorRun(input: $input) {
    id
    description
  }
}
    `;

export function useUpdateDataRegistryBatchMigratorRunMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDataRegistryBatchMigratorRunMutation,
    GQTypes.GQUpdateDataRegistryBatchMigratorRunMutationVariables
  >(UpdateDataRegistryBatchMigratorRunDocument);
}
export const GetSchemaRegistrySchemaVersionDocument = gql`
    query GetSchemaRegistrySchemaVersion($schemaVersionId: ID!) {
  schemaRegistrySchemaVersion(schemaVersionId: $schemaVersionId) {
    id
    schema {
      id
      name
    }
    version
    schemaJson
    createdAt
  }
}
    `;

export function useGetSchemaRegistrySchemaVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSchemaRegistrySchemaVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSchemaRegistrySchemaVersionQuery,
    GQTypes.GQGetSchemaRegistrySchemaVersionQueryVariables
  >({ query: GetSchemaRegistrySchemaVersionDocument, ...options });
}
export const GetSchemaRegistrySchemaDocument = gql`
    query GetSchemaRegistrySchema($schemaId: ID!) {
  schemaRegistrySchema(schemaId: $schemaId) {
    createdAt
    name
    id
  }
}
    `;

export function useGetSchemaRegistrySchemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSchemaRegistrySchemaQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSchemaRegistrySchemaQuery,
    GQTypes.GQGetSchemaRegistrySchemaQueryVariables
  >({ query: GetSchemaRegistrySchemaDocument, ...options });
}
export const GetMeasurementVerificationItemForAdminDocument = gql`
    query GetMeasurementVerificationItemForAdmin($id: ID!) {
  measurementVerificationItem(id: $id) {
    ...MeasurementVerificationItemDetailForAdmin
  }
}
    ${MeasurementVerificationItemDetailForAdminFragmentDoc}`;

export function useGetMeasurementVerificationItemForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMeasurementVerificationItemForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMeasurementVerificationItemForAdminQuery,
    GQTypes.GQGetMeasurementVerificationItemForAdminQueryVariables
  >({ query: GetMeasurementVerificationItemForAdminDocument, ...options });
}
export const UpdateMeasurementVerificationItemQuestionForAdminDocument = gql`
    mutation UpdateMeasurementVerificationItemQuestionForAdmin($input: UpdateMeasurementVerificationItemQuestionInput!) {
  updateMeasurementVerificationItemQuestion(input: $input) {
    measurementVerificationItemQuestion {
      ...MeasurementVerificationQuestionForAdmin
      ...MeasurementVerificationItemQuestionForQuestionContent
    }
  }
}
    ${MeasurementVerificationQuestionForAdminFragmentDoc}
${MeasurementVerificationItemQuestionForQuestionContentFragmentDoc}`;

export function useUpdateMeasurementVerificationItemQuestionForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementVerificationItemQuestionForAdminMutation,
    GQTypes.GQUpdateMeasurementVerificationItemQuestionForAdminMutationVariables
  >(UpdateMeasurementVerificationItemQuestionForAdminDocument);
}
export const CreateMeasurementVerificationItemQuestionForAdminDocument = gql`
    mutation CreateMeasurementVerificationItemQuestionForAdmin($input: CreateMeasurementVerificationItemQuestionInput!) {
  createMeasurementVerificationItemQuestion(input: $input) {
    measurementVerificationItemQuestion {
      ...MeasurementVerificationQuestionForAdmin
      ...MeasurementVerificationItemQuestionForQuestionContent
    }
  }
}
    ${MeasurementVerificationQuestionForAdminFragmentDoc}
${MeasurementVerificationItemQuestionForQuestionContentFragmentDoc}`;

export function useCreateMeasurementVerificationItemQuestionForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementVerificationItemQuestionForAdminMutation,
    GQTypes.GQCreateMeasurementVerificationItemQuestionForAdminMutationVariables
  >(CreateMeasurementVerificationItemQuestionForAdminDocument);
}
export const DeleteMeasurementVerificationItemQuestionForAdminDocument = gql`
    mutation DeleteMeasurementVerificationItemQuestionForAdmin($input: DeleteMeasurementVerificationItemQuestionInput!) {
  deleteMeasurementVerificationItemQuestion(input: $input) {
    measurementVerificationItem {
      ...MeasurementVerificationItemDetailForAdmin
    }
  }
}
    ${MeasurementVerificationItemDetailForAdminFragmentDoc}`;

export function useDeleteMeasurementVerificationItemQuestionForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementVerificationItemQuestionForAdminMutation,
    GQTypes.GQDeleteMeasurementVerificationItemQuestionForAdminMutationVariables
  >(DeleteMeasurementVerificationItemQuestionForAdminDocument);
}
export const UpdateMeasurementVerificationItemQuestionOrderForAdminDocument = gql`
    mutation UpdateMeasurementVerificationItemQuestionOrderForAdmin($input: OrderMeasurementVerificationItemQuestionsInput!) {
  updateMeasurementVerificationItemQuestionOrder(input: $input) {
    updatedQuestions {
      ...MeasurementVerificationQuestionForAdmin
    }
  }
}
    ${MeasurementVerificationQuestionForAdminFragmentDoc}`;

export function useUpdateMeasurementVerificationItemQuestionOrderForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementVerificationItemQuestionOrderForAdminMutation,
    GQTypes.GQUpdateMeasurementVerificationItemQuestionOrderForAdminMutationVariables
  >(UpdateMeasurementVerificationItemQuestionOrderForAdminDocument);
}
export const OrgFilesForMeasurementVerificationAdminDocument = gql`
    query OrgFilesForMeasurementVerificationAdmin($orgId: ID!, $fileIds: [ID!]!) {
  files(orgId: $orgId, filters: {ids: $fileIds}) {
    edges {
      node {
        ...FileForMeasurementVerificationQuestionContent
      }
    }
  }
}
    ${FileForMeasurementVerificationQuestionContentFragmentDoc}`;

export function useOrgFilesForMeasurementVerificationAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgFilesForMeasurementVerificationAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgFilesForMeasurementVerificationAdminQuery,
    GQTypes.GQOrgFilesForMeasurementVerificationAdminQueryVariables
  >({ query: OrgFilesForMeasurementVerificationAdminDocument, ...options });
}
export const OrganizationDataReviewPageDocument = gql`
    query OrganizationDataReviewPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    datasets {
      ...DatasetForDataReviewPage
    }
    measurementProjects {
      ...MeasurementProjectForDataReviewPage
      ...MeasurementProjectFieldsForMeasurementVerificationCard
    }
  }
}
    ${DatasetForDataReviewPageFragmentDoc}
${MeasurementProjectForDataReviewPageFragmentDoc}
${MeasurementProjectFieldsForMeasurementVerificationCardFragmentDoc}`;

export function useOrganizationDataReviewPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationDataReviewPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationDataReviewPageQuery,
    GQTypes.GQOrganizationDataReviewPageQueryVariables
  >({ query: OrganizationDataReviewPageDocument, ...options });
}
export const EditDatasetPageDocument = gql`
    query EditDatasetPage($id: ID!) {
  dataset(id: $id) {
    id
    name
    explainer
    customUsedFor
    customPotentialOverlaps
    canonicalDataset {
      id
      name
    }
    datasources {
      id
      ...DatasourceForDatasetPage
    }
    ...ContextSeedObjectFields
    instructions {
      ...InstructionsBundleBaseFields
    }
    customExampleData {
      cells
    }
  }
}
    ${DatasourceForDatasetPageFragmentDoc}
${ContextSeedObjectFieldsFragmentDoc}
${InstructionsBundleBaseFieldsFragmentDoc}`;

export function useEditDatasetPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEditDatasetPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEditDatasetPageQuery,
    GQTypes.GQEditDatasetPageQueryVariables
  >({ query: EditDatasetPageDocument, ...options });
}
export const DeleteDatasourceAdminDocument = gql`
    mutation DeleteDatasourceAdmin($input: DeleteDatasourceInput!) {
  deleteDatasourceAdmin(input: $input) {
    id
    dataset {
      id
      datasources {
        id
      }
    }
  }
}
    `;

export function useDeleteDatasourceAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteDatasourceAdminMutation,
    GQTypes.GQDeleteDatasourceAdminMutationVariables
  >(DeleteDatasourceAdminDocument);
}
export const OrganizationDatasetsPageDocument = gql`
    query OrganizationDatasetsPage($id: ID!) {
  organization(id: $id) {
    id
    name
    measurementProjects {
      ...MeasurementProjectFieldsForOrgPage
    }
    datasets {
      ...OrganizationDatasetsPageDataset
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}
${OrganizationDatasetsPageDatasetFragmentDoc}`;

export function useOrganizationDatasetsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationDatasetsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationDatasetsPageQuery,
    GQTypes.GQOrganizationDatasetsPageQueryVariables
  >({ query: OrganizationDatasetsPageDocument, ...options });
}
export const OrganizationEmployeeReportDocument = gql`
    query OrganizationEmployeeReport($orgId: ID!) {
  organization(id: $orgId) {
    __typename
    id
    name
    ...OrganizationForTargetsForm
  }
  employeeReport(orgId: $orgId) {
    ...EmployeeReportForAdmin
  }
}
    ${OrganizationForTargetsFormFragmentDoc}
${EmployeeReportForAdminFragmentDoc}`;

export function useOrganizationEmployeeReportQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationEmployeeReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationEmployeeReportQuery,
    GQTypes.GQOrganizationEmployeeReportQueryVariables
  >({ query: OrganizationEmployeeReportDocument, ...options });
}
export const CreateEmployeeReportDocument = gql`
    mutation CreateEmployeeReport($input: CreateEmployeeReportInput!) {
  createEmployeeReport(input: $input) {
    employeeReport {
      ...EmployeeReportForAdmin
    }
  }
}
    ${EmployeeReportForAdminFragmentDoc}`;

export function useCreateEmployeeReportMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEmployeeReportMutation,
    GQTypes.GQCreateEmployeeReportMutationVariables
  >(CreateEmployeeReportDocument);
}
export const UpdateEmployeeReportDocument = gql`
    mutation UpdateEmployeeReport($input: UpdateEmployeeReportInput!) {
  updateEmployeeReport(input: $input) {
    employeeReport {
      ...EmployeeReportForAdmin
    }
  }
}
    ${EmployeeReportForAdminFragmentDoc}`;

export function useUpdateEmployeeReportMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEmployeeReportMutation,
    GQTypes.GQUpdateEmployeeReportMutationVariables
  >(UpdateEmployeeReportDocument);
}
export const OrganizationFilesPageDocument = gql`
    query OrganizationFilesPage($id: ID!) {
  organization(id: $id) {
    id
    name
    sharedFiles {
      edges {
        node {
          ...SharedFileForFilesTable
        }
      }
    }
    sharedFileRequests {
      edges {
        node {
          ...SharedFileRequestForTable
        }
      }
    }
  }
}
    ${SharedFileForFilesTableFragmentDoc}
${SharedFileRequestForTableFragmentDoc}`;

export function useOrganizationFilesPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFilesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFilesPageQuery,
    GQTypes.GQOrganizationFilesPageQueryVariables
  >({ query: OrganizationFilesPageDocument, ...options });
}
export const FulfillShareFileRequestDocument = gql`
    mutation FulfillShareFileRequest($input: FulfillShareFileRequestInput!) {
  fulfillShareFileRequest(input: $input) {
    sharedFileRequests {
      ...SharedFileRequestForTable
    }
    newSharedFiles {
      ...SharedFileForFilesTable
    }
  }
}
    ${SharedFileRequestForTableFragmentDoc}
${SharedFileForFilesTableFragmentDoc}`;

export function useFulfillShareFileRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQFulfillShareFileRequestMutation,
    GQTypes.GQFulfillShareFileRequestMutationVariables
  >(FulfillShareFileRequestDocument);
}
export const OrganizationFootprintsPageDocument = gql`
    query OrganizationFootprintsPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    bigqueryTableSchema
    datasets {
      ...DatasetFieldsForOrgPage
    }
    measurementProjects {
      ...MeasurementProjectForFootprintPage
    }
    hasAnyPv2PipelineResults
  }
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...ActivityDataTableFields
      }
    }
  }
  latestUserVisibleDraftFootprintVersion(orgId: $orgId) {
    ...FootprintVersionFields
  }
  latestUserVisiblePublishedFootprintVersion(orgId: $orgId) {
    ...FootprintVersionFields
  }
}
    ${DatasetFieldsForOrgPageFragmentDoc}
${MeasurementProjectForFootprintPageFragmentDoc}
${ActivityDataTableFieldsFragmentDoc}
${FootprintVersionFieldsFragmentDoc}`;

export function useOrganizationFootprintsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFootprintsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFootprintsPageQuery,
    GQTypes.GQOrganizationFootprintsPageQueryVariables
  >({ query: OrganizationFootprintsPageDocument, ...options });
}
export const GetPipelineResultsForOrgDocument = gql`
    query GetPipelineResultsForOrg($orgId: ID!) {
  pipelineResults(orgId: $orgId) {
    edges {
      node {
        ...PipelineResultForOrg
      }
    }
  }
}
    ${PipelineResultForOrgFragmentDoc}`;

export function useGetPipelineResultsForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPipelineResultsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPipelineResultsForOrgQuery,
    GQTypes.GQGetPipelineResultsForOrgQueryVariables
  >({ query: GetPipelineResultsForOrgDocument, ...options });
}
export const PipelineDialogContentsDocument = gql`
    query PipelineDialogContents($orgId: ID!, $after: String, $before: String, $first: Int, $last: Int) {
  pipelineResults(
    orgId: $orgId
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...PipelineResultForOrg
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${PipelineResultForOrgFragmentDoc}`;

export function usePipelineDialogContentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPipelineDialogContentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPipelineDialogContentsQuery,
    GQTypes.GQPipelineDialogContentsQueryVariables
  >({ query: PipelineDialogContentsDocument, ...options });
}
export const OrganizationDocument = gql`
    query Organization($id: ID!) {
  orgFunds(orgId: $id) {
    edges {
      node {
        id
        name
      }
    }
  }
  organization(id: $id) {
    roles {
      edges {
        node {
          ...TeamsTableRole
        }
      }
    }
    datasets {
      id
      name
      datasources {
        name
        id
      }
    }
    ...OrganizationFieldsForOrgPage
    ...AdminUsersForOrgPage
  }
}
    ${TeamsTableRoleFragmentDoc}
${OrganizationFieldsForOrgPageFragmentDoc}
${AdminUsersForOrgPageFragmentDoc}`;

export function useOrganizationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationQuery,
    GQTypes.GQOrganizationQueryVariables
  >({ query: OrganizationDocument, ...options });
}
export const PlansForOrgDocument = gql`
    query PlansForOrg($id: ID!) {
  plansForOrg(id: $id) {
    id
  }
}
    `;

export function usePlansForOrgQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQPlansForOrgQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQPlansForOrgQuery,
    GQTypes.GQPlansForOrgQueryVariables
  >({ query: PlansForOrgDocument, ...options });
}
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    org {
      id
      passwordAuthDisabled
      magicLinkDisabled
      notionUrl
      domains
      currency
      fiscalYearStartMonth
      logoRemotePath
      iconRemotePath
      country
      company {
        name
        id
      }
      ...OrganizationFieldsForOrgPage
    }
  }
}
    ${OrganizationFieldsForOrgPageFragmentDoc}`;

export function useUpdateOrganizationMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrganizationMutation,
    GQTypes.GQUpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument);
}
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($input: DeleteOrganizationInput!) {
  deleteOrganization(input: $input) {
    id
  }
}
    `;

export function useDeleteOrganizationMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrganizationMutation,
    GQTypes.GQDeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument);
}
export const InitializeWorkosDocument = gql`
    mutation InitializeWorkos($input: InitializeWorkosInput!) {
  initializeWorkos(input: $input) {
    org {
      id
      workosOrgId
    }
  }
}
    `;

export function useInitializeWorkosMutation() {
  return Urql.useMutation<
    GQTypes.GQInitializeWorkosMutation,
    GQTypes.GQInitializeWorkosMutationVariables
  >(InitializeWorkosDocument);
}
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey($input: CreateApiKeyInput!) {
  createApiKey(input: $input) {
    key {
      id
      secret
    }
  }
}
    `;

export function useCreateApiKeyMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateApiKeyMutation,
    GQTypes.GQCreateApiKeyMutationVariables
  >(CreateApiKeyDocument);
}
export const UpdateOrgProfileAdminDocument = gql`
    mutation UpdateOrgProfileAdmin($input: UpdateOrgProfileInput!) {
  updateOrgProfile(input: $input) {
    organization {
      ...OrganizationFieldsForOrgPage
    }
  }
}
    ${OrganizationFieldsForOrgPageFragmentDoc}`;

export function useUpdateOrgProfileAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgProfileAdminMutation,
    GQTypes.GQUpdateOrgProfileAdminMutationVariables
  >(UpdateOrgProfileAdminDocument);
}
export const OrganizationDataIssuesPageDocument = gql`
    query OrganizationDataIssuesPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    measurementProjects {
      ...MeasurementProjectForEditDataIssuesPage
    }
  }
}
    ${MeasurementProjectForEditDataIssuesPageFragmentDoc}`;

export function useOrganizationDataIssuesPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationDataIssuesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationDataIssuesPageQuery,
    GQTypes.GQOrganizationDataIssuesPageQueryVariables
  >({ query: OrganizationDataIssuesPageDocument, ...options });
}
export const DeleteDataIssueDocument = gql`
    mutation DeleteDataIssue($input: DeleteDataIssueInput!, $orgId: ID!) {
  deleteDataIssue(input: $input) {
    userUploadTask {
      id
      issues {
        edges {
          node {
            id
          }
        }
      }
    }
    measurementProject {
      ...MeasurementProjectForEditDataIssuesPage
    }
  }
}
    ${MeasurementProjectForEditDataIssuesPageFragmentDoc}`;

export function useDeleteDataIssueMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteDataIssueMutation,
    GQTypes.GQDeleteDataIssueMutationVariables
  >(DeleteDataIssueDocument);
}
export const UpdateDataIssuesDocument = gql`
    mutation UpdateDataIssues($input: [UpdateDataIssueInput!]!, $orgId: ID!) {
  updateDataIssues(input: $input) {
    payload {
      issue {
        ...DataIssueForEditDataIssuesPageFields
      }
    }
  }
}
    ${DataIssueForEditDataIssuesPageFieldsFragmentDoc}`;

export function useUpdateDataIssuesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDataIssuesMutation,
    GQTypes.GQUpdateDataIssuesMutationVariables
  >(UpdateDataIssuesDocument);
}
export const OrganizationMappingsDocument = gql`
    query OrganizationMappings($orgId: ID!, $startYearMonth: YearMonth, $endYearMonth: YearMonth) {
  organization(id: $orgId) {
    id
    name
    measurementProjects {
      id
      name
      coverageInterval
      completedAt
      supportedValueMappingRules
    }
    demoOrg
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
      }
    }
  }
  globalMappingsUrl
  defaultMappingsUrl
  legacyMappingsCodesUrl
  measurementMappingOutputOptions {
    edges {
      node {
        id
        inputEmissionsSpecifier
        outputEmissionsSpecifier
        units
        description
        efKgCo2eDescription
        efKgCo2eAverage
        updatedAt
      }
    }
  }
  cadtRowsToMapForOrg(
    orgId: $orgId
    startYearMonth: $startYearMonth
    endYearMonth: $endYearMonth
  ) {
    cadtRowsToMap {
      edges {
        node {
          ...CadtRowsToMapFields
        }
      }
    }
  }
  measurementMappings(orgId: $orgId) {
    edges {
      node {
        id
        ...MeasurementMappingFieldsFragment
      }
    }
  }
  legacyAssumptionAsMeasurementMappings(orgId: $orgId) {
    edges {
      node {
        id
        ...MeasurementMappingFieldsFragment
      }
    }
  }
  mappingCategoryIds {
    ghgCategoryId {
      id
      name
      description
    }
    categoryId {
      id
      name
      description
    }
    subcategoryId {
      id
      name
      description
    }
  }
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${CadtRowsToMapFieldsFragmentDoc}
${MeasurementMappingFieldsFragmentFragmentDoc}`;

export function useOrganizationMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationMappingsQuery,
    GQTypes.GQOrganizationMappingsQueryVariables
  >({ query: OrganizationMappingsDocument, ...options });
}
export const OrganizationFinancialsReviewItemsDocument = gql`
    query OrganizationFinancialsReviewItems($orgId: ID!) {
  financialsReviewItemsAdmin(orgId: $orgId) {
    edges {
      node {
        ...FinancialsReviewItemAdminDialog
      }
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function useOrganizationFinancialsReviewItemsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFinancialsReviewItemsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFinancialsReviewItemsQuery,
    GQTypes.GQOrganizationFinancialsReviewItemsQueryVariables
  >({ query: OrganizationFinancialsReviewItemsDocument, ...options });
}
export const SyncMeasurementMappingsDocument = gql`
    mutation SyncMeasurementMappings($input: SyncMeasurementMappingsInput!) {
  syncMeasurementMappings(input: $input) {
    mappings {
      id
      ...MeasurementMappingFieldsFragment
    }
    jobId
  }
}
    ${MeasurementMappingFieldsFragmentFragmentDoc}`;

export function useSyncMeasurementMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSyncMeasurementMappingsMutation,
    GQTypes.GQSyncMeasurementMappingsMutationVariables
  >(SyncMeasurementMappingsDocument);
}
export const OrganizationPlanCategoriesDocument = gql`
    query OrganizationPlanCategories($orgId: ID!, $planId: ID!) {
  organization(id: $orgId) {
    id
    name
  }
  plan(id: $planId) {
    id
    name
  }
}
    `;

export function useOrganizationPlanCategoriesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationPlanCategoriesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationPlanCategoriesQuery,
    GQTypes.GQOrganizationPlanCategoriesQueryVariables
  >({ query: OrganizationPlanCategoriesDocument, ...options });
}
export const OrganizationPlansDocument = gql`
    query OrganizationPlans($id: ID!) {
  organization(id: $id) {
    id
    name
    fiscalYearStartMonth
  }
  plansForOrg(id: $id) {
    ...PlanForPlanPage
  }
  footprintAnalysisAdmin(orgId: $id) {
    id
    footprintInterval
    footprintKinds
  }
  latestUserVisiblePublishedFootprintVersion(orgId: $id) {
    id
    originalFootprintSnapshotId
  }
}
    ${PlanForPlanPageFragmentDoc}`;

export function useOrganizationPlansQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationPlansQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationPlansQuery,
    GQTypes.GQOrganizationPlansQueryVariables
  >({ query: OrganizationPlansDocument, ...options });
}
export const GetForecastWithFootprintSnapshotIdDocument = gql`
    query GetForecastWithFootprintSnapshotId($orgId: ID!) {
  forecastAdmin(orgId: $orgId) {
    id
    footprintSnapshotId
  }
}
    `;

export function useGetForecastWithFootprintSnapshotIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetForecastWithFootprintSnapshotIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetForecastWithFootprintSnapshotIdQuery,
    GQTypes.GQGetForecastWithFootprintSnapshotIdQueryVariables
  >({ query: GetForecastWithFootprintSnapshotIdDocument, ...options });
}
export const GetForecastWithReferenceIntervalDocument = gql`
    query GetForecastWithReferenceInterval($orgId: ID!) {
  forecastAdmin(orgId: $orgId) {
    id
    interval
    referencePeriodInterval
    validReferenceInterval
    overrideFootprintKind
  }
}
    `;

export function useGetForecastWithReferenceIntervalQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetForecastWithReferenceIntervalQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetForecastWithReferenceIntervalQuery,
    GQTypes.GQGetForecastWithReferenceIntervalQueryVariables
  >({ query: GetForecastWithReferenceIntervalDocument, ...options });
}
export const DeletePlanAdminDocument = gql`
    mutation DeletePlanAdmin($id: ID!) {
  deletePlanAdmin(id: $id) {
    plans {
      ...PlanForPlanPage
    }
  }
}
    ${PlanForPlanPageFragmentDoc}`;

export function useDeletePlanAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePlanAdminMutation,
    GQTypes.GQDeletePlanAdminMutationVariables
  >(DeletePlanAdminDocument);
}
export const DuplicatePlanAdminDocument = gql`
    mutation DuplicatePlanAdmin($id: ID!) {
  duplicatePlanAdmin(id: $id) {
    ...PlanForPlanPage
  }
}
    ${PlanForPlanPageFragmentDoc}`;

export function useDuplicatePlanAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicatePlanAdminMutation,
    GQTypes.GQDuplicatePlanAdminMutationVariables
  >(DuplicatePlanAdminDocument);
}
export const DeleteAllReductionsDataDocument = gql`
    mutation DeleteAllReductionsData($input: DeleteAllReductionsDataInput!) {
  deleteAllReductionsData(input: $input) {
    message
  }
}
    `;

export function useDeleteAllReductionsDataMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAllReductionsDataMutation,
    GQTypes.GQDeleteAllReductionsDataMutationVariables
  >(DeleteAllReductionsDataDocument);
}
export const UpdateForecastFootprintSnapshotIdToLatestDocument = gql`
    mutation UpdateForecastFootprintSnapshotIdToLatest($input: UpdateForecastFootprintSnapshotIdToLatestInput!) {
  updateForecastFootprintSnapshotIdToLatest(input: $input) {
    forecasts {
      id
      name
      validReferenceInterval
      footprintSnapshotId
    }
  }
}
    `;

export function useUpdateForecastFootprintSnapshotIdToLatestMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateForecastFootprintSnapshotIdToLatestMutation,
    GQTypes.GQUpdateForecastFootprintSnapshotIdToLatestMutationVariables
  >(UpdateForecastFootprintSnapshotIdToLatestDocument);
}
export const OrganizationReportsPageDocument = gql`
    query OrganizationReportsPage($id: ID!) {
  organization(id: $id) {
    id
    name
  }
  latestUserVisiblePublishedFootprintVersion(orgId: $id) {
    id
    originalFootprintSnapshotId
  }
  footprintVersions(orgId: $id) {
    edges {
      node {
        id
        kind
        originalFootprintSnapshotId
        createdAt
      }
    }
  }
  formalReportsAdmin(orgId: $id) {
    edges {
      node {
        id
        ...FormalReportFieldsAdmin
      }
    }
  }
  footprintCustomReports(orgId: $id) {
    id
    ...CustomReportFields
  }
  footprintAnalysisAdmin(orgId: $id) {
    id
    footprintInterval
  }
}
    ${FormalReportFieldsAdminFragmentDoc}
${CustomReportFieldsFragmentDoc}`;

export function useOrganizationReportsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationReportsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationReportsPageQuery,
    GQTypes.GQOrganizationReportsPageQueryVariables
  >({ query: OrganizationReportsPageDocument, ...options });
}
export const UpdateReportDocument = gql`
    mutation UpdateReport($input: UpdateReportInput!) {
  updateReport(input: $input) {
    report {
      id
      ...FormalReportFieldsAdmin
    }
  }
}
    ${FormalReportFieldsAdminFragmentDoc}`;

export function useUpdateReportMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportMutation,
    GQTypes.GQUpdateReportMutationVariables
  >(UpdateReportDocument);
}
export const ArchiveReportDocument = gql`
    mutation ArchiveReport($reportId: ID!) {
  archiveReport(input: {reportId: $reportId}) {
    report {
      id
      deletedAt
    }
  }
}
    `;

export function useArchiveReportMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveReportMutation,
    GQTypes.GQArchiveReportMutationVariables
  >(ArchiveReportDocument);
}
export const RestoreReportDocument = gql`
    mutation RestoreReport($reportId: ID!) {
  restoreReport(input: {reportId: $reportId}) {
    report {
      id
      ...FormalReportFieldsAdmin
    }
  }
}
    ${FormalReportFieldsAdminFragmentDoc}`;

export function useRestoreReportMutation() {
  return Urql.useMutation<
    GQTypes.GQRestoreReportMutation,
    GQTypes.GQRestoreReportMutationVariables
  >(RestoreReportDocument);
}
export const ArchiveFootprintCustomReportDocument = gql`
    mutation ArchiveFootprintCustomReport($input: ArchiveFootprintCustomReportInput!) {
  archiveFootprintCustomReport(input: $input) {
    footprintCustomReport {
      id
      archivedAt
    }
  }
}
    `;

export function useArchiveFootprintCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveFootprintCustomReportMutation,
    GQTypes.GQArchiveFootprintCustomReportMutationVariables
  >(ArchiveFootprintCustomReportDocument);
}
export const PublishFootprintCustomReportDocument = gql`
    mutation PublishFootprintCustomReport($input: PublishFootprintCustomReportInput!) {
  publishFootprintCustomReport(input: $input) {
    footprintCustomReport {
      id
      name
      description
      downloadUrl
      published
      footprintSnapshotId
    }
  }
}
    `;

export function usePublishFootprintCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishFootprintCustomReportMutation,
    GQTypes.GQPublishFootprintCustomReportMutationVariables
  >(PublishFootprintCustomReportDocument);
}
export const GetSupplyChainDataDocument = gql`
    query GetSupplyChainData($orgId: ID!) {
  engagementTasksAdmin(orgId: $orgId) {
    edges {
      node {
        ...EngagementTaskFieldsAdmin
      }
    }
  }
  suppliersSettingsAdmin(orgId: $orgId) {
    ...SuppliersSettingsFieldsAdmin
  }
  suppliersSettingsAdmin(orgId: $orgId) {
    ...SuppliersSettingsFieldsAdmin
  }
  organization(id: $orgId) {
    id
    name
  }
}
    ${EngagementTaskFieldsAdminFragmentDoc}
${SuppliersSettingsFieldsAdminFragmentDoc}`;

export function useGetSupplyChainDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplyChainDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplyChainDataQuery,
    GQTypes.GQGetSupplyChainDataQueryVariables
  >({ query: GetSupplyChainDataDocument, ...options });
}
export const UpdateSuppliersSettingsAdminDocument = gql`
    mutation UpdateSuppliersSettingsAdmin($input: UpdateSuppliersSettingsAdminInput!) {
  updateSuppliersSettingsAdmin(input: $input) {
    suppliersSettings {
      ...SuppliersSettingsFieldsAdmin
    }
  }
}
    ${SuppliersSettingsFieldsAdminFragmentDoc}`;

export function useUpdateSuppliersSettingsAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSuppliersSettingsAdminMutation,
    GQTypes.GQUpdateSuppliersSettingsAdminMutationVariables
  >(UpdateSuppliersSettingsAdminDocument);
}
export const UserUploadTaskPageDocument = gql`
    query UserUploadTaskPage($orgId: ID!, $userUploadTaskId: ID!) {
  organization(id: $orgId) {
    id
    name
  }
  userUploadTask(id: $userUploadTaskId) {
    id
    state
    datasource {
      id
      name
      dataset {
        id
        explainer
        canonicalDataset {
          id
          name
        }
      }
      ...ContextSeedObjectFields
    }
    ...UserUploadTaskUploadsFields
  }
}
    ${ContextSeedObjectFieldsFragmentDoc}
${UserUploadTaskUploadsFieldsFragmentDoc}`;

export function useUserUploadTaskPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadTaskPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadTaskPageQuery,
    GQTypes.GQUserUploadTaskPageQueryVariables
  >({ query: UserUploadTaskPageDocument, ...options });
}
export const OrganizationUsersDocument = gql`
    query OrganizationUsers($orgId: ID!) {
  orgFunds(orgId: $orgId) {
    edges {
      node {
        id
        name
      }
    }
  }
  organization(id: $orgId) {
    roles {
      edges {
        node {
          ...RoleInfo
        }
      }
    }
    id
    name
    domains
    watershedPlanLegacy
    canAccessCorporate
    canAccessFinance
    datasets {
      id
      name
      datasources {
        name
        id
      }
    }
    passwordAuthDisabled
    users(filters: {includeWatershedEmployees: true}) {
      edges {
        node {
          ...UserFieldsForOrgPage
        }
      }
    }
  }
}
    ${RoleInfoFragmentDoc}
${UserFieldsForOrgPageFragmentDoc}`;

export function useOrganizationUsersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationUsersQuery,
    GQTypes.GQOrganizationUsersQueryVariables
  >({ query: OrganizationUsersDocument, ...options });
}
export const GetSignedParquetUrlDocument = gql`
    query GetSignedParquetUrl($id: ID!) {
  signedParquetUrl(id: $id)
}
    `;

export function useGetSignedParquetUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSignedParquetUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSignedParquetUrlQuery,
    GQTypes.GQGetSignedParquetUrlQueryVariables
  >({ query: GetSignedParquetUrlDocument, ...options });
}
export const ReferenceDataRevisionDocument = gql`
    query ReferenceDataRevision($id: ID!) {
  referenceDataRevision(revisionId: $id) {
    id
    revisionName
  }
}
    `;

export function useReferenceDataRevisionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataRevisionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataRevisionQuery,
    GQTypes.GQReferenceDataRevisionQueryVariables
  >({ query: ReferenceDataRevisionDocument, ...options });
}
export const CompositeDataSourceDocument = gql`
    query CompositeDataSource($compositeDataSourceId: ID!, $excludeArchived: Boolean, $includeDrafts: Boolean) {
  referenceDataSource(referenceDataSourceId: $compositeDataSourceId) {
    ...ReferenceDataSourceSchema
    referenceDataVersions(
      excludeArchived: $excludeArchived
      includeDrafts: $includeDrafts
    ) {
      ...ReferenceDataVersionSchema
      compositeDataOrgDetails {
        ...CompositeDataOrgDetailSchema
      }
    }
  }
}
    ${ReferenceDataSourceSchemaFragmentDoc}
${ReferenceDataVersionSchemaFragmentDoc}
${CompositeDataOrgDetailSchemaFragmentDoc}`;

export function useCompositeDataSourceQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompositeDataSourceQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompositeDataSourceQuery,
    GQTypes.GQCompositeDataSourceQueryVariables
  >({ query: CompositeDataSourceDocument, ...options });
}
export const ReferenceDataSourceForCreateOverlayDocument = gql`
    query ReferenceDataSourceForCreateOverlay($referenceDataSourceId: ID!) {
  referenceDataSource(referenceDataSourceId: $referenceDataSourceId) {
    id
    name
  }
}
    `;

export function useReferenceDataSourceForCreateOverlayQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourceForCreateOverlayQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourceForCreateOverlayQuery,
    GQTypes.GQReferenceDataSourceForCreateOverlayQueryVariables
  >({ query: ReferenceDataSourceForCreateOverlayDocument, ...options });
}
export const ReferenceDataOverlayPreviewDocument = gql`
    query ReferenceDataOverlayPreview($overlayId: ID!) {
  referenceDataOverlay(overlayId: $overlayId) {
    id
    globalCdrId
    signedMergedDataUrl
    orgId
    source {
      id
      name
    }
  }
}
    `;

export function useReferenceDataOverlayPreviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataOverlayPreviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataOverlayPreviewQuery,
    GQTypes.GQReferenceDataOverlayPreviewQueryVariables
  >({ query: ReferenceDataOverlayPreviewDocument, ...options });
}
export const ReferenceDataSourceDocument = gql`
    query ReferenceDataSource($referenceDataSourceId: ID!, $excludeArchived: Boolean, $includeDrafts: Boolean, $filterOrgId: ID) {
  referenceDataSource(referenceDataSourceId: $referenceDataSourceId) {
    ...ReferenceDataSourceSchema
    orgsWithRevisions(includeDrafts: $includeDrafts) {
      id
      name
    }
    referenceDataVersions(
      excludeArchived: $excludeArchived
      includeDrafts: $includeDrafts
    ) {
      ...ReferenceDataVersionSchema
      latestPublishedGlobalRevision: latestPublishedRevisionByVersionId(orgId: null) {
        ...ReferenceDataRevisionSchema
      }
      latestPublishedRevision: latestPublishedRevisionByVersionId(orgId: $filterOrgId) {
        ...ReferenceDataRevisionSchema
      }
    }
  }
}
    ${ReferenceDataSourceSchemaFragmentDoc}
${ReferenceDataVersionSchemaFragmentDoc}
${ReferenceDataRevisionSchemaFragmentDoc}`;

export function useReferenceDataSourceQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourceQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourceQuery,
    GQTypes.GQReferenceDataSourceQueryVariables
  >({ query: ReferenceDataSourceDocument, ...options });
}
export const ReferenceDataSourceAndVersionDocument = gql`
    query ReferenceDataSourceAndVersion($referenceDataSourceId: ID!, $referenceDataVersionId: ID!) {
  referenceDataSource(referenceDataSourceId: $referenceDataSourceId) {
    id
    name
    organization {
      id
    }
  }
  referenceDataVersion(referenceDataVersionId: $referenceDataVersionId) {
    id
    versionName
    state
    latestSchema {
      id
      schemaJson
    }
  }
}
    `;

export function useReferenceDataSourceAndVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourceAndVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourceAndVersionQuery,
    GQTypes.GQReferenceDataSourceAndVersionQueryVariables
  >({ query: ReferenceDataSourceAndVersionDocument, ...options });
}
export const ReferenceDataSourceFindPageDocument = gql`
    query ReferenceDataSourceFindPage($query: String!) {
  findReferenceDataSource(query: $query) {
    id
  }
}
    `;

export function useReferenceDataSourceFindPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourceFindPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourceFindPageQuery,
    GQTypes.GQReferenceDataSourceFindPageQueryVariables
  >({ query: ReferenceDataSourceFindPageDocument, ...options });
}
export const ReportQuestionForAnswerCopyDocument = gql`
    query ReportQuestionForAnswerCopy($id: ID!) {
  reportQuestion(id: $id) {
    ...ReportQuestionForCopy
    questionsWithMatchingOutputSchema {
      ...ReportQuestionForCopy
    }
  }
}
    ${ReportQuestionForCopyFragmentDoc}`;

export function useReportQuestionForAnswerCopyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportQuestionForAnswerCopyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportQuestionForAnswerCopyQuery,
    GQTypes.GQReportQuestionForAnswerCopyQueryVariables
  >({ query: ReportQuestionForAnswerCopyDocument, ...options });
}
export const CopyReportQuestionAnswersDocument = gql`
    mutation CopyReportQuestionAnswers($input: CopyReportQuestionAnswersInput!) {
  copyReportQuestionAnswers(input: $input) {
    numCopied
  }
}
    `;

export function useCopyReportQuestionAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQCopyReportQuestionAnswersMutation,
    GQTypes.GQCopyReportQuestionAnswersMutationVariables
  >(CopyReportQuestionAnswersDocument);
}
export const ReportAnswersDocument = gql`
    query ReportAnswers($filters: ReportAnswerFilters, $before: String, $after: String, $first: Int, $last: Int) {
  reportAnswers(
    filters: $filters
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ReportAnswerForAdminTable
      }
    }
  }
}
    ${ReportAnswerForAdminTableFragmentDoc}`;

export function useReportAnswersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportAnswersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportAnswersQuery,
    GQTypes.GQReportAnswersQueryVariables
  >({ query: ReportAnswersDocument, ...options });
}
export const GetReportConfigQuestionsForAdminDocument = gql`
    query GetReportConfigQuestionsForAdmin($reportConfigId: ID!) {
  reportConfigQuestions(reportConfigId: $reportConfigId) {
    id
    identifier
  }
}
    `;

export function useGetReportConfigQuestionsForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportConfigQuestionsForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportConfigQuestionsForAdminQuery,
    GQTypes.GQGetReportConfigQuestionsForAdminQueryVariables
  >({ query: GetReportConfigQuestionsForAdminDocument, ...options });
}
export const DeleteReportAnswersDocument = gql`
    mutation DeleteReportAnswers($ids: [ID!]!) {
  deleteReportAnswers(ids: $ids) {
    deleteCount
  }
}
    `;

export function useDeleteReportAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportAnswersMutation,
    GQTypes.GQDeleteReportAnswersMutationVariables
  >(DeleteReportAnswersDocument);
}
export const VerifyReportAnswersDocument = gql`
    mutation VerifyReportAnswers($reportAnswerIds: [ID!]!) {
  verifyReportAnswers(reportAnswerIds: $reportAnswerIds) {
    successCount
    failureCount
  }
}
    `;

export function useVerifyReportAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQVerifyReportAnswersMutation,
    GQTypes.GQVerifyReportAnswersMutationVariables
  >(VerifyReportAnswersDocument);
}
export const GetCdpIdMappingsDocument = gql`
    query GetCdpIdMappings($orgId: ID!, $reportId: ID!, $hasOrgAndReportId: Boolean!) {
  cdpApiRegistrations {
    id
    orgId
  }
  cdpIdMappings {
    ...CdpIdMappingsFields
  }
  cdpMissingIdMappings(orgId: $orgId, reportId: $reportId) @include(if: $hasOrgAndReportId) {
    candidateQuestionMappings {
      ...CandidateQuestionMappingFields
    }
    candidateColumnMappings {
      ...CandidateColumnMappingFields
    }
    unmappedWatershedQuestions {
      ...UnmappedWatershedQuestionFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}
${CandidateQuestionMappingFieldsFragmentDoc}
${CandidateColumnMappingFieldsFragmentDoc}
${UnmappedWatershedQuestionFieldsFragmentDoc}`;

export function useGetCdpIdMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCdpIdMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCdpIdMappingsQuery,
    GQTypes.GQGetCdpIdMappingsQueryVariables
  >({ query: GetCdpIdMappingsDocument, ...options });
}
export const CreateCdpQuestionMappingsDocument = gql`
    mutation CreateCdpQuestionMappings($input: CreateCdpQuestionMappingsInput!) {
  createCdpQuestionMappings(input: $input) {
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useCreateCdpQuestionMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCdpQuestionMappingsMutation,
    GQTypes.GQCreateCdpQuestionMappingsMutationVariables
  >(CreateCdpQuestionMappingsDocument);
}
export const DeleteCdpQuestionMappingsDocument = gql`
    mutation DeleteCdpQuestionMappings($input: DeleteCdpQuestionMappingsInput!) {
  deleteCdpQuestionMappings(input: $input) {
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useDeleteCdpQuestionMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCdpQuestionMappingsMutation,
    GQTypes.GQDeleteCdpQuestionMappingsMutationVariables
  >(DeleteCdpQuestionMappingsDocument);
}
export const UpsertCdpQuestionMappingsDocument = gql`
    mutation UpsertCdpQuestionMappings($input: UpsertCdpQuestionMappingsInput!) {
  upsertCdpQuestionMappings(input: $input) {
    updatedMappings {
      ...CdpQuestionIdMapping
    }
  }
}
    ${CdpQuestionIdMappingFragmentDoc}`;

export function useUpsertCdpQuestionMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertCdpQuestionMappingsMutation,
    GQTypes.GQUpsertCdpQuestionMappingsMutationVariables
  >(UpsertCdpQuestionMappingsDocument);
}
export const CreateCdpColumnMappingsDocument = gql`
    mutation CreateCdpColumnMappings($input: CreateCdpColumnMappingsInput!) {
  createCdpColumnMappings(input: $input) {
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useCreateCdpColumnMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCdpColumnMappingsMutation,
    GQTypes.GQCreateCdpColumnMappingsMutationVariables
  >(CreateCdpColumnMappingsDocument);
}
export const DeleteCdpColumnMappingsDocument = gql`
    mutation DeleteCdpColumnMappings($input: DeleteCdpColumnMappingsInput!) {
  deleteCdpColumnMappings(input: $input) {
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useDeleteCdpColumnMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCdpColumnMappingsMutation,
    GQTypes.GQDeleteCdpColumnMappingsMutationVariables
  >(DeleteCdpColumnMappingsDocument);
}
export const UpsertCdpColumnMappingsDocument = gql`
    mutation UpsertCdpColumnMappings($input: UpsertCdpColumnMappingsInput!) {
  upsertCdpColumnMappings(input: $input) {
    updatedMappings {
      ...CdpColumnIdMapping
    }
  }
}
    ${CdpColumnIdMappingFragmentDoc}`;

export function useUpsertCdpColumnMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertCdpColumnMappingsMutation,
    GQTypes.GQUpsertCdpColumnMappingsMutationVariables
  >(UpsertCdpColumnMappingsDocument);
}
export const ReplaceReportingCdpMappingsDocument = gql`
    mutation ReplaceReportingCdpMappings($input: ReplaceReportingCdpMappingsInput!) {
  replaceReportingCdpMappings(input: $input) {
    success
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useReplaceReportingCdpMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQReplaceReportingCdpMappingsMutation,
    GQTypes.GQReplaceReportingCdpMappingsMutationVariables
  >(ReplaceReportingCdpMappingsDocument);
}
export const GetReportsForCdpIdMappingsDocument = gql`
    query GetReportsForCdpIdMappings($orgId: ID!) {
  reports(kind: "CDP", orgId: $orgId) {
    edges {
      node {
        id
        name
        updatedAt
        config {
          id
          shortName
        }
      }
    }
  }
}
    `;

export function useGetReportsForCdpIdMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportsForCdpIdMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportsForCdpIdMappingsQuery,
    GQTypes.GQGetReportsForCdpIdMappingsQueryVariables
  >({ query: GetReportsForCdpIdMappingsDocument, ...options });
}
export const UpdateReportConfigFromAdminTableDocument = gql`
    mutation UpdateReportConfigFromAdminTable($input: UpdateReportConfigInput!) {
  updateReportConfig(input: $input) {
    reportConfig {
      ...ReportConfigFieldsForAdminTable
    }
  }
}
    ${ReportConfigFieldsForAdminTableFragmentDoc}`;

export function useUpdateReportConfigFromAdminTableMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportConfigFromAdminTableMutation,
    GQTypes.GQUpdateReportConfigFromAdminTableMutationVariables
  >(UpdateReportConfigFromAdminTableDocument);
}
export const CreateReportConfigFromAdminTableDocument = gql`
    mutation CreateReportConfigFromAdminTable($input: CreateReportConfigInput!) {
  createReportConfig(input: $input) {
    reportConfig {
      ...ReportConfigFieldsForAdminTable
    }
  }
}
    ${ReportConfigFieldsForAdminTableFragmentDoc}`;

export function useCreateReportConfigFromAdminTableMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportConfigFromAdminTableMutation,
    GQTypes.GQCreateReportConfigFromAdminTableMutationVariables
  >(CreateReportConfigFromAdminTableDocument);
}
export const CreateReportQuestionContainerDocument = gql`
    mutation CreateReportQuestionContainer($input: CreateReportQuestionContainerInput!) {
  createReportQuestionContainer(input: $input) {
    container {
      ...ReportQuestionContainerFields
    }
  }
}
    ${ReportQuestionContainerFieldsFragmentDoc}`;

export function useCreateReportQuestionContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportQuestionContainerMutation,
    GQTypes.GQCreateReportQuestionContainerMutationVariables
  >(CreateReportQuestionContainerDocument);
}
export const UpdateReportQuestionContainerDocument = gql`
    mutation UpdateReportQuestionContainer($input: UpdateReportQuestionContainerInput!) {
  updateReportQuestionContainer(input: $input) {
    container {
      ...ReportQuestionContainerFields
    }
  }
}
    ${ReportQuestionContainerFieldsFragmentDoc}`;

export function useUpdateReportQuestionContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionContainerMutation,
    GQTypes.GQUpdateReportQuestionContainerMutationVariables
  >(UpdateReportQuestionContainerDocument);
}
export const DeleteReportQuestionContainerDocument = gql`
    mutation DeleteReportQuestionContainer($input: DeleteReportQuestionContainerInput!) {
  deleteReportQuestionContainer(input: $input) {
    parentId
    container {
      ...ReportQuestionContainerFields
    }
  }
}
    ${ReportQuestionContainerFieldsFragmentDoc}`;

export function useDeleteReportQuestionContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportQuestionContainerMutation,
    GQTypes.GQDeleteReportQuestionContainerMutationVariables
  >(DeleteReportQuestionContainerDocument);
}
export const ReportsToBeDeletedDocument = gql`
    query ReportsToBeDeleted($configId: ID!) {
  reports(configId: $configId) {
    edges {
      node {
        id
        name
        updatedAt
        organization {
          id
          name
          demoOrg
          testOrg
        }
      }
    }
  }
}
    `;

export function useReportsToBeDeletedQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportsToBeDeletedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportsToBeDeletedQuery,
    GQTypes.GQReportsToBeDeletedQueryVariables
  >({ query: ReportsToBeDeletedDocument, ...options });
}
export const CreateReportConfigFromSrcDocument = gql`
    mutation CreateReportConfigFromSrc($input: CreateReportConfigFromSrcInput!) {
  createReportConfigFromSrc(input: $input) {
    reportConfig {
      id
    }
  }
}
    `;

export function useCreateReportConfigFromSrcMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportConfigFromSrcMutation,
    GQTypes.GQCreateReportConfigFromSrcMutationVariables
  >(CreateReportConfigFromSrcDocument);
}
export const GetReportConfigForAdminDocument = gql`
    query GetReportConfigForAdmin($id: ID!) {
  reportConfig(id: $id) {
    id
    reportType
    shortName
    longName
    description
    brandColor
    isHiddenFromOverview
    isCreationBlocked
    isRegulatoryExposureBlocked
    requiredPermissions
    reportObjectives
    inputIds
    computedItemIds
    itemIds
    lastLintedAt
    latestLinterStatus
    latestComponentUpdatedAt(id: $id)
    allQuestions {
      id
      identifier
      componentId
      isComputed
    }
    allContainers {
      id
      label
      itemIds
    }
  }
}
    `;

export function useGetReportConfigForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportConfigForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportConfigForAdminQuery,
    GQTypes.GQGetReportConfigForAdminQueryVariables
  >({ query: GetReportConfigForAdminDocument, ...options });
}
export const GetReportQuestionContainerForAdminDocument = gql`
    query GetReportQuestionContainerForAdmin($id: ID!) {
  reportQuestionContainer(id: $id) {
    ...ReportQuestionContainerFields
  }
}
    ${ReportQuestionContainerFieldsFragmentDoc}`;

export function useGetReportQuestionContainerForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerForAdminQuery,
    GQTypes.GQGetReportQuestionContainerForAdminQueryVariables
  >({ query: GetReportQuestionContainerForAdminDocument, ...options });
}
export const GetReportQuestionForAdminDocument = gql`
    query GetReportQuestionForAdmin($id: ID!) {
  reportQuestion(id: $id) {
    ...ReportQuestionRawFields
  }
}
    ${ReportQuestionRawFieldsFragmentDoc}`;

export function useGetReportQuestionForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionForAdminQuery,
    GQTypes.GQGetReportQuestionForAdminQueryVariables
  >({ query: GetReportQuestionForAdminDocument, ...options });
}
export const LintReportConfigDocument = gql`
    mutation LintReportConfig($input: LintReportConfigInput!) {
  lintReportConfig(input: $input) {
    status
    lintErrors {
      reportObjectId
      message
      type
    }
  }
}
    `;

export function useLintReportConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQLintReportConfigMutation,
    GQTypes.GQLintReportConfigMutationVariables
  >(LintReportConfigDocument);
}
export const CreateReportQuestionDocument = gql`
    mutation CreateReportQuestion($input: CreateReportQuestionInput!) {
  createReportQuestion(input: $input) {
    question {
      ...ReportQuestionRawFields
      parentContainer {
        ...ReportQuestionContainerFields
      }
    }
  }
}
    ${ReportQuestionRawFieldsFragmentDoc}
${ReportQuestionContainerFieldsFragmentDoc}`;

export function useCreateReportQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportQuestionMutation,
    GQTypes.GQCreateReportQuestionMutationVariables
  >(CreateReportQuestionDocument);
}
export const UpdateReportQuestionDocument = gql`
    mutation UpdateReportQuestion($input: UpdateReportQuestionInput!) {
  updateReportQuestion(input: $input) {
    question {
      ...ReportQuestionRawFields
    }
  }
}
    ${ReportQuestionRawFieldsFragmentDoc}`;

export function useUpdateReportQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionMutation,
    GQTypes.GQUpdateReportQuestionMutationVariables
  >(UpdateReportQuestionDocument);
}
export const DeleteReportQuestionDocument = gql`
    mutation DeleteReportQuestion($input: DeleteReportQuestionInput!) {
  deleteReportQuestion(input: $input) {
    parentId
    question {
      ...ReportQuestionRawFields
    }
  }
}
    ${ReportQuestionRawFieldsFragmentDoc}`;

export function useDeleteReportQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportQuestionMutation,
    GQTypes.GQDeleteReportQuestionMutationVariables
  >(DeleteReportQuestionDocument);
}
export const UpdateReportEsrsMappingDocument = gql`
    mutation updateReportEsrsMapping($input: UpdateReportEsrsMappingInput!) {
  updateReportEsrsMapping(input: $input) {
    ... on UpdateReportEsrsMappingSuccess {
      __typename
      mapping {
        reportConfigId
        csv
      }
      warnings {
        msg
      }
    }
    ... on UpdateReportEsrsMappingFailure {
      __typename
      errors {
        msg
      }
    }
  }
}
    `;

export function useUpdateReportEsrsMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportEsrsMappingMutation,
    GQTypes.GQUpdateReportEsrsMappingMutationVariables
  >(UpdateReportEsrsMappingDocument);
}
export const GetReportConfigFrameworkMappingDocument = gql`
    query getReportConfigFrameworkMapping($reportConfigId: ID!) {
  reportConfigFrameworkMapping(reportConfigId: $reportConfigId) {
    reportConfigId
    csv
  }
}
    `;

export function useGetReportConfigFrameworkMappingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportConfigFrameworkMappingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportConfigFrameworkMappingQuery,
    GQTypes.GQGetReportConfigFrameworkMappingQueryVariables
  >({ query: GetReportConfigFrameworkMappingDocument, ...options });
}
export const ReportObjectConfigPathDocument = gql`
    query ReportObjectConfigPath($input: GetReportObjectConfigPathInput!) {
  getReportObjectConfigPath(input: $input) {
    path {
      id
      label
      isInput
      isComputed
    }
  }
}
    `;

export function useReportObjectConfigPathQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportObjectConfigPathQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportObjectConfigPathQuery,
    GQTypes.GQReportObjectConfigPathQueryVariables
  >({ query: ReportObjectConfigPathDocument, ...options });
}
export const GetReportQuestionWithAllItemsForAdminDocument = gql`
    query GetReportQuestionWithAllItemsForAdmin($id: ID!) {
  reportQuestion(id: $id) {
    id
    identifier
    reportConfig {
      id
      itemIds
      allContainers {
        id
        label
        itemIds
      }
      allQuestions {
        id
        identifier
        componentId
        isComputed
      }
    }
  }
}
    `;

export function useGetReportQuestionWithAllItemsForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionWithAllItemsForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionWithAllItemsForAdminQuery,
    GQTypes.GQGetReportQuestionWithAllItemsForAdminQueryVariables
  >({ query: GetReportQuestionWithAllItemsForAdminDocument, ...options });
}
export const GetReportQuestionContainerWithAllItemsForAdminDocument = gql`
    query GetReportQuestionContainerWithAllItemsForAdmin($id: ID!) {
  reportQuestionContainer(id: $id) {
    id
    label
    reportConfig {
      id
      itemIds
      allContainers {
        id
        label
        itemIds
      }
      allQuestions {
        id
        identifier
        componentId
        isComputed
      }
    }
  }
}
    `;

export function useGetReportQuestionContainerWithAllItemsForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerWithAllItemsForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerWithAllItemsForAdminQuery,
    GQTypes.GQGetReportQuestionContainerWithAllItemsForAdminQueryVariables
  >({
    query: GetReportQuestionContainerWithAllItemsForAdminDocument,
    ...options,
  });
}
export const AllReportConfigsForAdminTableDocument = gql`
    query AllReportConfigsForAdminTable {
  reportConfigs {
    edges {
      node {
        ...ReportConfigFieldsForAdminTable
      }
    }
  }
}
    ${ReportConfigFieldsForAdminTableFragmentDoc}`;

export function useAllReportConfigsForAdminTableQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllReportConfigsForAdminTableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllReportConfigsForAdminTableQuery,
    GQTypes.GQAllReportConfigsForAdminTableQueryVariables
  >({ query: AllReportConfigsForAdminTableDocument, ...options });
}
export const DumpReportConfigToSrcDocument = gql`
    query DumpReportConfigToSrc($id: ID!) {
  dumpReportConfigToSrc(id: $id) {
    src
    lintStatus
  }
}
    `;

export function useDumpReportConfigToSrcQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDumpReportConfigToSrcQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDumpReportConfigToSrcQuery,
    GQTypes.GQDumpReportConfigToSrcQueryVariables
  >({ query: DumpReportConfigToSrcDocument, ...options });
}
export const DeleteReportConfigFromAdminTableDocument = gql`
    mutation DeleteReportConfigFromAdminTable($input: DeleteReportConfigInput!) {
  deleteReportConfig(input: $input) {
    reportConfig {
      ...ReportConfigFieldsForAdminTable
    }
  }
}
    ${ReportConfigFieldsForAdminTableFragmentDoc}`;

export function useDeleteReportConfigFromAdminTableMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportConfigFromAdminTableMutation,
    GQTypes.GQDeleteReportConfigFromAdminTableMutationVariables
  >(DeleteReportConfigFromAdminTableDocument);
}
export const ReportHealthChecksDocument = gql`
    query ReportHealthChecks($orgId: ID!, $footprintSnapshotId: ID, $interval: YMInterval) {
  reportingFootprintHealthChecks(
    input: {orgId: $orgId, footprintSnapshotId: $footprintSnapshotId, interval: $interval}
  ) {
    footprintSnapshotId
    interval
    failures {
      validatorId
      outputJson
    }
  }
}
    `;

export function useReportHealthChecksQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportHealthChecksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportHealthChecksQuery,
    GQTypes.GQReportHealthChecksQueryVariables
  >({ query: ReportHealthChecksDocument, ...options });
}
export const GetReportQuestionIdentifierMappingDocument = gql`
    query GetReportQuestionIdentifierMapping($reportQuestionIdentifierId: ID!) {
  reportQuestionIdentifierMapping(
    reportQuestionIdentifierId: $reportQuestionIdentifierId
  ) {
    id
    relatedQuestionIdentifiers {
      ...ReportQuestionIdentifierFields
    }
  }
}
    ${ReportQuestionIdentifierFieldsFragmentDoc}`;

export function useGetReportQuestionIdentifierMappingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionIdentifierMappingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionIdentifierMappingQuery,
    GQTypes.GQGetReportQuestionIdentifierMappingQueryVariables
  >({ query: GetReportQuestionIdentifierMappingDocument, ...options });
}
export const CreateReportQuestionIdentifierMappingDocument = gql`
    mutation CreateReportQuestionIdentifierMapping($identifierId1: ID!, $identifierId2: ID!) {
  createReportQuestionIdentifierMapping(
    identifierId1: $identifierId1
    identifierId2: $identifierId2
  ) {
    reportQuestionIdentifierMapping1 {
      id
      relatedQuestionIdentifiers {
        ...ReportQuestionIdentifierFields
      }
    }
    reportQuestionIdentifierMapping2 {
      id
      relatedQuestionIdentifiers {
        ...ReportQuestionIdentifierFields
      }
    }
  }
}
    ${ReportQuestionIdentifierFieldsFragmentDoc}`;

export function useCreateReportQuestionIdentifierMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportQuestionIdentifierMappingMutation,
    GQTypes.GQCreateReportQuestionIdentifierMappingMutationVariables
  >(CreateReportQuestionIdentifierMappingDocument);
}
export const DeleteReportQuestionIdentifierMappingDocument = gql`
    mutation DeleteReportQuestionIdentifierMapping($identifierId1: ID!, $identifierId2: ID!) {
  deleteReportQuestionIdentifierMapping(
    identifierId1: $identifierId1
    identifierId2: $identifierId2
  ) {
    reportQuestionIdentifierMapping1 {
      id
      relatedQuestionIdentifiers {
        ...ReportQuestionIdentifierFields
      }
    }
    reportQuestionIdentifierMapping2 {
      id
      relatedQuestionIdentifiers {
        ...ReportQuestionIdentifierFields
      }
    }
  }
}
    ${ReportQuestionIdentifierFieldsFragmentDoc}`;

export function useDeleteReportQuestionIdentifierMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportQuestionIdentifierMappingMutation,
    GQTypes.GQDeleteReportQuestionIdentifierMappingMutationVariables
  >(DeleteReportQuestionIdentifierMappingDocument);
}
export const ReportAnswerVerifierFailuresDocument = gql`
    query ReportAnswerVerifierFailures($reportAnswerFilters: ReportAnswerFilterInput!, $before: String, $after: String, $first: Int, $last: Int) {
  reportAnswerVerifierFailures(
    reportAnswerFilters: $reportAnswerFilters
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        organization {
          id
          name
        }
        reportAnswerUpdatedAt
        mismatchTime
        outputJson
        errorJson
        mismatchApprovalTime
        reportAnswer {
          id
          outputJson
          updatedAt
          question {
            id
            identifier
            componentId
          }
          report {
            id
            name
            config {
              id
              reportType
            }
            isMigratedFromStaticConfig
            createdAt
          }
        }
      }
    }
  }
}
    `;

export function useReportAnswerVerifierFailuresQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportAnswerVerifierFailuresQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportAnswerVerifierFailuresQuery,
    GQTypes.GQReportAnswerVerifierFailuresQueryVariables
  >({ query: ReportAnswerVerifierFailuresDocument, ...options });
}
export const GetReportAnswerVerifierOptionsDocument = gql`
    query GetReportAnswerVerifierOptions {
  reportAnswerVerifierOptions {
    organizations {
      id
      name
    }
    componentIds
    reportTypes
  }
}
    `;

export function useGetReportAnswerVerifierOptionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportAnswerVerifierOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportAnswerVerifierOptionsQuery,
    GQTypes.GQGetReportAnswerVerifierOptionsQueryVariables
  >({ query: GetReportAnswerVerifierOptionsDocument, ...options });
}
export const ApproveReportAnswerVerifiersDocument = gql`
    mutation ApproveReportAnswerVerifiers($input: ApproveReportAnswerVerifiersInput!) {
  approveReportAnswerVerifiers(input: $input) {
    updatedAnswers {
      id
      updatedAt
      outputJson
    }
  }
}
    `;

export function useApproveReportAnswerVerifiersMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveReportAnswerVerifiersMutation,
    GQTypes.GQApproveReportAnswerVerifiersMutationVariables
  >(ApproveReportAnswerVerifiersDocument);
}
export const AcceptReportAnswerVerifierFailuresDocument = gql`
    mutation AcceptReportAnswerVerifierFailures($input: AcceptReportAnswerVerifierFailuresInput!) {
  acceptReportAnswerVerifierFailures(input: $input) {
    updatedVerifierFailures {
      id
      mismatchApprovalTime
    }
  }
}
    `;

export function useAcceptReportAnswerVerifierFailuresMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptReportAnswerVerifierFailuresMutation,
    GQTypes.GQAcceptReportAnswerVerifierFailuresMutationVariables
  >(AcceptReportAnswerVerifierFailuresDocument);
}
export const ClearReportAnswerVerifiersDocument = gql`
    mutation ClearReportAnswerVerifiers($input: ClearReportAnswerVerifierFailuresInput!) {
  clearReportAnswerVerifierFailures(input: $input) {
    num
  }
}
    `;

export function useClearReportAnswerVerifiersMutation() {
  return Urql.useMutation<
    GQTypes.GQClearReportAnswerVerifiersMutation,
    GQTypes.GQClearReportAnswerVerifiersMutationVariables
  >(ClearReportAnswerVerifiersDocument);
}
export const RunReportAnswerVerifierDocument = gql`
    mutation RunReportAnswerVerifier($input: RunReportAnswerVerifierInput!) {
  runReportAnswerVerifier(input: $input) {
    reportAnswerId
  }
}
    `;

export function useRunReportAnswerVerifierMutation() {
  return Urql.useMutation<
    GQTypes.GQRunReportAnswerVerifierMutation,
    GQTypes.GQRunReportAnswerVerifierMutationVariables
  >(RunReportAnswerVerifierDocument);
}
export const GetExtractorRunDocument = gql`
    query GetExtractorRun($id: ID!) {
  extractorRun(id: $id) {
    ...QueryRunFields
    activityDataExtractor {
      ...ActivityDataExtractorFields
    }
  }
}
    ${QueryRunFieldsFragmentDoc}
${ActivityDataExtractorFieldsFragmentDoc}`;

export function useGetExtractorRunQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetExtractorRunQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetExtractorRunQuery,
    GQTypes.GQGetExtractorRunQueryVariables
  >({ query: GetExtractorRunDocument, ...options });
}
export const GetPlaygroundSqlRunDocument = gql`
    query GetPlaygroundSqlRun($id: ID!) {
  playgroundSqlRun(id: $id) {
    ...PlaygroundSqlRunForPlayground
  }
}
    ${PlaygroundSqlRunForPlaygroundFragmentDoc}`;

export function useGetPlaygroundSqlRunQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPlaygroundSqlRunQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPlaygroundSqlRunQuery,
    GQTypes.GQGetPlaygroundSqlRunQueryVariables
  >({ query: GetPlaygroundSqlRunDocument, ...options });
}
export const EnqueueBackgroundJobDocument = gql`
    mutation EnqueueBackgroundJob($kind: String!, $args: JSONString!, $orgId: String) {
  enqueueBackgroundJob(
    input: {kind: $kind, args: $args, orgId: $orgId, queueName: null}
  ) {
    job {
      id
    }
  }
}
    `;

export function useEnqueueBackgroundJobMutation() {
  return Urql.useMutation<
    GQTypes.GQEnqueueBackgroundJobMutation,
    GQTypes.GQEnqueueBackgroundJobMutationVariables
  >(EnqueueBackgroundJobDocument);
}
export const EnqueueBackgroundJobWithQueueNameDocument = gql`
    mutation EnqueueBackgroundJobWithQueueName($kind: String!, $args: JSONString!, $orgId: String, $queueName: BackgroundJobQueueName) {
  enqueueBackgroundJob(
    input: {kind: $kind, args: $args, orgId: $orgId, queueName: $queueName}
  ) {
    job {
      id
    }
  }
}
    `;

export function useEnqueueBackgroundJobWithQueueNameMutation() {
  return Urql.useMutation<
    GQTypes.GQEnqueueBackgroundJobWithQueueNameMutation,
    GQTypes.GQEnqueueBackgroundJobWithQueueNameMutationVariables
  >(EnqueueBackgroundJobWithQueueNameDocument);
}
export const MarketplaceInternationalEacProjectsDocument = gql`
    query MarketplaceInternationalEacProjects {
  marketplaceInternationalEacProjects {
    id
    name
  }
}
    `;

export function useMarketplaceInternationalEacProjectsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceInternationalEacProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceInternationalEacProjectsQuery,
    GQTypes.GQMarketplaceInternationalEacProjectsQueryVariables
  >({ query: MarketplaceInternationalEacProjectsDocument, ...options });
}
export const AdtsForMagicSqlDocument = gql`
    query AdtsForMagicSql($orgId: ID!) {
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useAdtsForMagicSqlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAdtsForMagicSqlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAdtsForMagicSqlQuery,
    GQTypes.GQAdtsForMagicSqlQueryVariables
  >({ query: AdtsForMagicSqlDocument, ...options });
}
export const FmdsForPlaygroundDocument = gql`
    query FmdsForPlayground($orgId: ID!) {
  files(orgId: $orgId) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useFmdsForPlaygroundQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFmdsForPlaygroundQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFmdsForPlaygroundQuery,
    GQTypes.GQFmdsForPlaygroundQueryVariables
  >({ query: FmdsForPlaygroundDocument, ...options });
}
export const FpssForPlaygroundDocument = gql`
    query FpssForPlayground($orgId: ID!) {
  footprintSnapshots(orgId: $orgId) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

export function useFpssForPlaygroundQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFpssForPlaygroundQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFpssForPlaygroundQuery,
    GQTypes.GQFpssForPlaygroundQueryVariables
  >({ query: FpssForPlaygroundDocument, ...options });
}
export const UutsForPlaygroundDocument = gql`
    query UutsForPlayground($orgId: ID!) {
  userUploadedTables(orgId: $orgId) {
    edges {
      node {
        id
        sqlTableName
      }
    }
  }
}
    `;

export function useUutsForPlaygroundQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUutsForPlaygroundQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUutsForPlaygroundQuery,
    GQTypes.GQUutsForPlaygroundQueryVariables
  >({ query: UutsForPlaygroundDocument, ...options });
}
export const ReferenceOrgsDocument = gql`
    query ReferenceOrgs($ids: [ID!]!) {
  organizations(ids: $ids) {
    edges {
      node {
        __typename
        ...OrganizationListPageOrganization
      }
    }
  }
}
    ${OrganizationListPageOrganizationFragmentDoc}`;

export function useReferenceOrgsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceOrgsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceOrgsQuery,
    GQTypes.GQReferenceOrgsQueryVariables
  >({ query: ReferenceOrgsDocument, ...options });
}
export const CreateAdminUploadDocument = gql`
    mutation CreateAdminUpload($input: CreateAdminUploadInput!) {
  createAdminUpload(input: $input) {
    url
    fileId
    remotePath
  }
}
    `;

export function useCreateAdminUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAdminUploadMutation,
    GQTypes.GQCreateAdminUploadMutationVariables
  >(CreateAdminUploadDocument);
}
export const MarkAdminUploadCompletedDocument = gql`
    mutation MarkAdminUploadCompleted($input: MarkAdminUploadCompletedInput!) {
  markAdminUploadCompleted(input: $input) {
    file {
      id
    }
    parseJobId
  }
}
    `;

export function useMarkAdminUploadCompletedMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkAdminUploadCompletedMutation,
    GQTypes.GQMarkAdminUploadCompletedMutationVariables
  >(MarkAdminUploadCompletedDocument);
}
export const FeatureFlagEnabledGloballyDocument = gql`
    query FeatureFlagEnabledGlobally($flag: Flags!) {
  isEnabled: featureFlagEnabledGlobally(flag: $flag)
}
    `;

export function useFeatureFlagEnabledGloballyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFeatureFlagEnabledGloballyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFeatureFlagEnabledGloballyQuery,
    GQTypes.GQFeatureFlagEnabledGloballyQueryVariables
  >({ query: FeatureFlagEnabledGloballyDocument, ...options });
}
export const SignOneSchemaJwtDocument = gql`
    query signOneSchemaJwt($payload: JSONString!) {
  signOneSchemaJwt(payload: $payload) {
    token
  }
}
    `;

export function useSignOneSchemaJwtQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSignOneSchemaJwtQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSignOneSchemaJwtQuery,
    GQTypes.GQSignOneSchemaJwtQueryVariables
  >({ query: SignOneSchemaJwtDocument, ...options });
}
export const GetSuppliersAdminDocument = gql`
    query GetSuppliersAdmin($orgId: ID!) {
  suppliersAdmin(orgId: $orgId) {
    suppliers {
      suppliersMerged {
        id
        data
      }
    }
  }
}
    `;

export function useGetSuppliersAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSuppliersAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSuppliersAdminQuery,
    GQTypes.GQGetSuppliersAdminQueryVariables
  >({ query: GetSuppliersAdminDocument, ...options });
}
export const CreateUserDashboardVersionDocument = gql`
    mutation CreateUserDashboardVersion($input: CreateUserInput!) {
  createUser(input: $input) {
    user {
      ...AssignableUserFields
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useCreateUserDashboardVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUserDashboardVersionMutation,
    GQTypes.GQCreateUserDashboardVersionMutationVariables
  >(CreateUserDashboardVersionDocument);
}
export const GetFootprintSnapshotDescriptionDocument = gql`
    query GetFootprintSnapshotDescription($version: String!, $footprintSnapshotId: String) {
  footprintAnalysis(version: $version, footprintSnapshotId: $footprintSnapshotId) {
    id
    snapshotDescription(id: $version)
  }
}
    `;

export function useGetFootprintSnapshotDescriptionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintSnapshotDescriptionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintSnapshotDescriptionQuery,
    GQTypes.GQGetFootprintSnapshotDescriptionQueryVariables
  >({ query: GetFootprintSnapshotDescriptionDocument, ...options });
}
export const GetBetterCompanyDocument = gql`
    query getBetterCompany($companyId: ID!) {
  companyForDashboard(id: $companyId, disableBetterCompanyUpgrade: true) {
    id
    betterCompanyId
  }
}
    `;

export function useGetBetterCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBetterCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBetterCompanyQuery,
    GQTypes.GQGetBetterCompanyQueryVariables
  >({ query: GetBetterCompanyDocument, ...options });
}
export const ForbiddenPageDataDocument = gql`
    query ForbiddenPageData($missingPermission: PermissionType!) {
  adminUsers {
    ...UserContactFields
  }
  pendingUserPermissionRequestsForUser(permission: $missingPermission) {
    ...UserPermissionRequestFields
  }
}
    ${UserContactFieldsFragmentDoc}
${UserPermissionRequestFieldsFragmentDoc}`;

export function useForbiddenPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQForbiddenPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQForbiddenPageDataQuery,
    GQTypes.GQForbiddenPageDataQueryVariables
  >({ query: ForbiddenPageDataDocument, ...options });
}
export const CreateUserPermissionRequestDocument = gql`
    mutation CreateUserPermissionRequest($input: CreateUserPermissionRequestInput!) {
  createUserPermissionRequest(input: $input) {
    userPermissionRequest {
      ...UserPermissionRequestFields
    }
  }
}
    ${UserPermissionRequestFieldsFragmentDoc}`;

export function useCreateUserPermissionRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUserPermissionRequestMutation,
    GQTypes.GQCreateUserPermissionRequestMutationVariables
  >(CreateUserPermissionRequestDocument);
}
export const RequestHelpDocument = gql`
    mutation RequestHelp($input: RequestHelpInput!) {
  requestHelp(input: $input) {
    status
  }
}
    `;

export function useRequestHelpMutation() {
  return Urql.useMutation<
    GQTypes.GQRequestHelpMutation,
    GQTypes.GQRequestHelpMutationVariables
  >(RequestHelpDocument);
}
export const ActivityDatasetsForUploadsTabDocument = gql`
    query ActivityDatasetsForUploadsTab($input: BiActivityQueryContextInput!) {
  activityContextMetadata(input: $input) {
    activityDatasets {
      businessActivityType
      uploads {
        ...ActivityDataUserUpload
      }
    }
  }
  activeMeasurementProjects(limit: 1) {
    id
  }
}
    ${ActivityDataUserUploadFragmentDoc}`;

export function useActivityDatasetsForUploadsTabQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDatasetsForUploadsTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDatasetsForUploadsTabQuery,
    GQTypes.GQActivityDatasetsForUploadsTabQueryVariables
  >({ query: ActivityDatasetsForUploadsTabDocument, ...options });
}
export const GenerateTemplateDownloadUrlForOrgDocument = gql`
    mutation GenerateTemplateDownloadUrlForOrg($input: GenerateTemplateDownloadUrlForOrgDatasetsInput!) {
  generateTemplateDownloadUrlForOrgDatasets(input: $input) {
    templateDownloadUrl
  }
}
    `;

export function useGenerateTemplateDownloadUrlForOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateTemplateDownloadUrlForOrgMutation,
    GQTypes.GQGenerateTemplateDownloadUrlForOrgMutationVariables
  >(GenerateTemplateDownloadUrlForOrgDocument);
}
export const AuditReportMetadataDocument = gql`
    query AuditReportMetadata($input: AuditReportMetadataInput!) {
  auditReportMetadata(input: $input) {
    columns {
      column
      title
      description
    }
  }
}
    `;

export function useAuditReportMetadataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAuditReportMetadataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAuditReportMetadataQuery,
    GQTypes.GQAuditReportMetadataQueryVariables
  >({ query: AuditReportMetadataDocument, ...options });
}
export const UploadSchemaDetailDocument = gql`
    query UploadSchemaDetail($datasetId: ID!, $schemaId: ID!) {
  dataset(id: $datasetId) {
    id
    name
    canonicalDataset {
      id
      instructions {
        sdiInstructionsIntroMd
      }
    }
  }
  customerTargetSchema(id: $schemaId) {
    id
    name
    versions {
      id
      ...UploadSchemaDetailVersion
    }
    publishedVersions {
      id
      ...UploadSchemaDetailVersion
    }
  }
}
    ${UploadSchemaDetailVersionFragmentDoc}`;

export function useUploadSchemaDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUploadSchemaDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUploadSchemaDetailQuery,
    GQTypes.GQUploadSchemaDetailQueryVariables
  >({ query: UploadSchemaDetailDocument, ...options });
}
export const UploadSchemaExplorerDocument = gql`
    query UploadSchemaExplorer {
  datasets(sortBy: "name") {
    id
    ...UploadSchemaExplorerDataset
  }
}
    ${UploadSchemaExplorerDatasetFragmentDoc}`;

export function useUploadSchemaExplorerQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQUploadSchemaExplorerQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUploadSchemaExplorerQuery,
    GQTypes.GQUploadSchemaExplorerQueryVariables
  >({ query: UploadSchemaExplorerDocument, ...options });
}
export const CreateOneSchemaSessionForApiUploadDocument = gql`
    mutation CreateOneSchemaSessionForApiUpload($input: CreateOneSchemaSessionForApiUploadInput!) {
  createOneSchemaSessionForApiUpload(input: $input) {
    oneSchemaEmbedId
    oneSchemaSessionToken
    oneSchemaEmbedStatus
    oneSchemaEmbedUserJwt
    fileMetadataId
    fileName
    signedUrl
    userUploadTaskId
    measurementProjectId
  }
}
    `;

export function useCreateOneSchemaSessionForApiUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOneSchemaSessionForApiUploadMutation,
    GQTypes.GQCreateOneSchemaSessionForApiUploadMutationVariables
  >(CreateOneSchemaSessionForApiUploadDocument);
}
export const GroupedAuditLogsDocument = gql`
    query GroupedAuditLogs($eventKinds: [String!]!, $after: String, $before: String, $first: Int, $last: Int, $filters: UntypedData) {
  groupedAuditLogs(
    eventKinds: $eventKinds
    after: $after
    before: $before
    first: $first
    last: $last
    filters: $filters
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        ...GroupedAuditLogEntryFields
      }
    }
  }
}
    ${GroupedAuditLogEntryFieldsFragmentDoc}`;

export function useGroupedAuditLogsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGroupedAuditLogsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGroupedAuditLogsQuery,
    GQTypes.GQGroupedAuditLogsQueryVariables
  >({ query: GroupedAuditLogsDocument, ...options });
}
export const AddCompaniesToPeerCompanyGroupDashboardDocument = gql`
    mutation AddCompaniesToPeerCompanyGroupDashboard($input: AddCompaniesToPeerCompanyGroupsInput!) {
  addCompaniesToPeerCompanyGroups(input: $input) {
    peerCompanyGroups {
      ...PeerCompanyGroupForBenchmarks
    }
  }
}
    ${PeerCompanyGroupForBenchmarksFragmentDoc}`;

export function useAddCompaniesToPeerCompanyGroupDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQAddCompaniesToPeerCompanyGroupDashboardMutation,
    GQTypes.GQAddCompaniesToPeerCompanyGroupDashboardMutationVariables
  >(AddCompaniesToPeerCompanyGroupDashboardDocument);
}
export const PeerCompanyGroupsForAddMemberDocument = gql`
    query PeerCompanyGroupsForAddMember($orgId: ID!) {
  peerCompanyGroups(orgId: $orgId) {
    id
    name
  }
}
    `;

export function usePeerCompanyGroupsForAddMemberQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerCompanyGroupsForAddMemberQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerCompanyGroupsForAddMemberQuery,
    GQTypes.GQPeerCompanyGroupsForAddMemberQueryVariables
  >({ query: PeerCompanyGroupsForAddMemberDocument, ...options });
}
export const CompanyBenchmarkDocument = gql`
    query CompanyBenchmark($id: ID!) {
  company(id: $id) {
    ...CompanyForBenchmarks
  }
}
    ${CompanyForBenchmarksFragmentDoc}`;

export function useCompanyBenchmarkQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyBenchmarkQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyBenchmarkQuery,
    GQTypes.GQCompanyBenchmarkQueryVariables
  >({ query: CompanyBenchmarkDocument, ...options });
}
export const DeletePeerCompanyGroupDocument = gql`
    mutation DeletePeerCompanyGroup($input: DeletePeerCompanyGroupInput!) {
  deletePeerCompanyGroup(input: $input) {
    id
  }
}
    `;

export function useDeletePeerCompanyGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePeerCompanyGroupMutation,
    GQTypes.GQDeletePeerCompanyGroupMutationVariables
  >(DeletePeerCompanyGroupDocument);
}
export const UpdatePeerCompanyGroupDocument = gql`
    mutation UpdatePeerCompanyGroup($input: UpdatePeerCompanyGroupInput!) {
  updatePeerCompanyGroup(input: $input) {
    id
    peerCompanyGroup {
      id
      name
      shouldHideFromFootprintOverview
      revenueForRevenueAtRisk
    }
  }
}
    `;

export function useUpdatePeerCompanyGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePeerCompanyGroupMutation,
    GQTypes.GQUpdatePeerCompanyGroupMutationVariables
  >(UpdatePeerCompanyGroupDocument);
}
export const CreatePeerCompanyGroupDocument = gql`
    mutation CreatePeerCompanyGroup($input: CreatePeerCompanyGroupInput!) {
  createPeerCompanyGroup(input: $input) {
    id
    peerCompanyGroup {
      ...PeerCompanyGroupForBenchmarks
    }
  }
}
    ${PeerCompanyGroupForBenchmarksFragmentDoc}`;

export function useCreatePeerCompanyGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePeerCompanyGroupMutation,
    GQTypes.GQCreatePeerCompanyGroupMutationVariables
  >(CreatePeerCompanyGroupDocument);
}
export const ActivityDataMainPageDocument = gql`
    query ActivityDataMainPage($input: BiActivityQueryContextInput!) {
  biPostgresToActivityData(input: $input) {
    isFacilitiesDataStale
  }
  organization {
    id
    measurementProjects {
      id
      name
      createdAt
      completedAt
      coverageInterval
      active
      status
    }
  }
}
    `;

export function useActivityDataMainPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDataMainPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDataMainPageQuery,
    GQTypes.GQActivityDataMainPageQueryVariables
  >({ query: ActivityDataMainPageDocument, ...options });
}
export const GetBiMethodologyExplainerContentDocument = gql`
    query GetBiMethodologyExplainerContent($footprintSnapshotId: String!, $measureSelector: BiQuerySingleMeasureSelectorInput!, $companyId: ID) {
  companyForDashboard(id: $companyId) {
    ...CompanyForMethodologyExplainer
  }
  methodologyExplainer(footprintSnapshotId: $footprintSnapshotId) {
    id
    biGhgCategoryIds(singleMeasureSelector: $measureSelector)
    biCalculationChains(singleMeasureSelector: $measureSelector) {
      ...CalculationChainForMethodologyExplainer
    }
    biCalculationTraces(singleMeasureSelector: $measureSelector)
    biExampleTracesForFingerprints(singleMeasureSelector: $measureSelector) {
      tracesByFingerprint
      referenceValueDetails {
        refDataCellPath
        description
        citation {
          displayName
          notes
          url
          publicDisclosureId
          isCustomerProvided
          org {
            orgName
            orgId
          }
        }
      }
      emissionsModelVariableDetails {
        emissionsModelEvaluationId
        variableName
        displayName
        description
      }
      bartColumnDetails {
        emissionsModelEvaluationId
        columnName
        displayName
        description
      }
    }
  }
}
    ${CompanyForMethodologyExplainerFragmentDoc}
${CalculationChainForMethodologyExplainerFragmentDoc}`;

export function useGetBiMethodologyExplainerContentQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBiMethodologyExplainerContentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBiMethodologyExplainerContentQuery,
    GQTypes.GQGetBiMethodologyExplainerContentQueryVariables
  >({ query: GetBiMethodologyExplainerContentDocument, ...options });
}
export const ExecuteBiAiQueryDocument = gql`
    query ExecuteBiAiQuery($input: BiAiQueryInput!) {
  biAiQuery(input: $input) {
    payload {
      dimensions
      filters {
        dimension
        operator
        value
      }
      normalizedMeasureId
      timeIntervals
    }
  }
}
    `;

export function useExecuteBiAiQueryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQExecuteBiAiQueryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQExecuteBiAiQueryQuery,
    GQTypes.GQExecuteBiAiQueryQueryVariables
  >({ query: ExecuteBiAiQueryDocument, ...options });
}
export const CreateBiSavedViewAndPropagateToCustomReportsDocument = gql`
    mutation CreateBiSavedViewAndPropagateToCustomReports($input: CreateBiSavedViewAndPropagateToCustomReportsInput!) {
  createBiSavedViewAndPropagateToCustomReports(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useCreateBiSavedViewAndPropagateToCustomReportsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBiSavedViewAndPropagateToCustomReportsMutation,
    GQTypes.GQCreateBiSavedViewAndPropagateToCustomReportsMutationVariables
  >(CreateBiSavedViewAndPropagateToCustomReportsDocument);
}
export const UpdateBiSavedViewDocument = gql`
    mutation UpdateBiSavedView($input: UpdateBiSavedViewInput!) {
  updateBiSavedView(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useUpdateBiSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBiSavedViewMutation,
    GQTypes.GQUpdateBiSavedViewMutationVariables
  >(UpdateBiSavedViewDocument);
}
export const RenameBiSavedViewDocument = gql`
    mutation RenameBiSavedView($input: RenameBiSavedViewInput!) {
  renameBiSavedView(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useRenameBiSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQRenameBiSavedViewMutation,
    GQTypes.GQRenameBiSavedViewMutationVariables
  >(RenameBiSavedViewDocument);
}
export const DeleteBiSavedViewDocument = gql`
    mutation DeleteBiSavedView($input: DeleteBiSavedViewInput!) {
  deleteBiSavedView(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useDeleteBiSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBiSavedViewMutation,
    GQTypes.GQDeleteBiSavedViewMutationVariables
  >(DeleteBiSavedViewDocument);
}
export const BiMetadataDocument = gql`
    query BiMetadata($input: BiQueryMetadataInput!) {
  biQueryMetadata(input: $input) {
    id
    ...BiQueryMetadataFields
  }
}
    ${BiQueryMetadataFieldsFragmentDoc}`;

export function useBiMetadataQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQBiMetadataQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQBiMetadataQuery,
    GQTypes.GQBiMetadataQueryVariables
  >({ query: BiMetadataDocument, ...options });
}
export const BiDataDocument = gql`
    query BiData($input: BiQueryDataInputLegacy!) {
  biQueryData(input: $input) {
    id
    data
    displayValueMappings {
      dimension
      listOptionItems {
        value
        label
      }
    }
    warnings {
      learnMoreLink
      warningMessage
      warningType
    }
  }
}
    `;

export function useBiDataQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQBiDataQueryVariables>, 'query'>
) {
  return Urql.useQuery<GQTypes.GQBiDataQuery, GQTypes.GQBiDataQueryVariables>({
    query: BiDataDocument,
    ...options,
  });
}
export const BiDataMultipleDocument = gql`
    query BiDataMultiple($input: BiQueryDataMultipleInput!) {
  biQueryDataMultiple(input: $input) {
    id
    data
    displayValueMappings {
      dimension
      listOptionItems {
        value
        label
      }
    }
  }
}
    `;

export function useBiDataMultipleQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiDataMultipleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiDataMultipleQuery,
    GQTypes.GQBiDataMultipleQueryVariables
  >({ query: BiDataMultipleDocument, ...options });
}
export const BiDimensionListOptionsDocument = gql`
    query BiDimensionListOptions($input: BiDimensionListOptionsInput!) {
  biDimensionListOptions(input: $input) {
    id
    listOptionItems {
      value
      label
    }
  }
}
    `;

export function useBiDimensionListOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiDimensionListOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiDimensionListOptionsQuery,
    GQTypes.GQBiDimensionListOptionsQueryVariables
  >({ query: BiDimensionListOptionsDocument, ...options });
}
export const BiFilterDisplayValueMappingsDocument = gql`
    query BiFilterDisplayValueMappings($input: BiFilterDisplayValueMappingsInput!) {
  biFilterDisplayValueMappings(input: $input) {
    dimension
    listOptionItems {
      value
      label
    }
  }
}
    `;

export function useBiFilterDisplayValueMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiFilterDisplayValueMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiFilterDisplayValueMappingsQuery,
    GQTypes.GQBiFilterDisplayValueMappingsQueryVariables
  >({ query: BiFilterDisplayValueMappingsDocument, ...options });
}
export const GetBiSavedViewsDocument = gql`
    query GetBiSavedViews($input: BiSavedViewsInput) {
  biSavedViews(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useGetBiSavedViewsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBiSavedViewsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBiSavedViewsQuery,
    GQTypes.GQGetBiSavedViewsQueryVariables
  >({ query: GetBiSavedViewsDocument, ...options });
}
export const CustomReportsWithSavedViewDocument = gql`
    query CustomReportsWithSavedView($input: CustomReportsWithBiSavedViewInput!) {
  customReportsWithBiSavedView(input: $input) {
    id
    name
    config {
      id
      updatedAt
    }
  }
}
    `;

export function useCustomReportsWithSavedViewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomReportsWithSavedViewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomReportsWithSavedViewQuery,
    GQTypes.GQCustomReportsWithSavedViewQueryVariables
  >({ query: CustomReportsWithSavedViewDocument, ...options });
}
export const BiCustomMetricsDocument = gql`
    query BiCustomMetrics {
  biCustomMetrics {
    id
    stableId
    creator {
      id
      displayName
    }
    name
    expression
  }
}
    `;

export function useBiCustomMetricsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiCustomMetricsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiCustomMetricsQuery,
    GQTypes.GQBiCustomMetricsQueryVariables
  >({ query: BiCustomMetricsDocument, ...options });
}
export const CreateBiCustomMetricDocument = gql`
    mutation CreateBiCustomMetric($input: CreateBiCustomMetricInput!) {
  createBiCustomMetric(input: $input) {
    id
    stableId
    name
    expression
  }
}
    `;

export function useCreateBiCustomMetricMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBiCustomMetricMutation,
    GQTypes.GQCreateBiCustomMetricMutationVariables
  >(CreateBiCustomMetricDocument);
}
export const UpdateBiCustomMetricDocument = gql`
    mutation UpdateBiCustomMetric($input: UpdateBiCustomMetricInput!) {
  updateBiCustomMetric(input: $input) {
    id
    stableId
    name
    expression
  }
}
    `;

export function useUpdateBiCustomMetricMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBiCustomMetricMutation,
    GQTypes.GQUpdateBiCustomMetricMutationVariables
  >(UpdateBiCustomMetricDocument);
}
export const DeleteBiCustomMetricDocument = gql`
    mutation DeleteBiCustomMetric($input: DeleteBiCustomMetricInput!) {
  deleteBiCustomMetric(input: $input) {
    id
    stableId
    name
    expression
  }
}
    `;

export function useDeleteBiCustomMetricMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBiCustomMetricMutation,
    GQTypes.GQDeleteBiCustomMetricMutationVariables
  >(DeleteBiCustomMetricDocument);
}
export const GetFootprintKindsForSnapshotDocument = gql`
    query GetFootprintKindsForSnapshot($footprintSnapshotId: String!) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    snapshotFootprintKindsMeta {
      ...FootprintKindMetaForDrilldown
    }
  }
}
    ${FootprintKindMetaForDrilldownFragmentDoc}`;

export function useGetFootprintKindsForSnapshotQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintKindsForSnapshotQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintKindsForSnapshotQuery,
    GQTypes.GQGetFootprintKindsForSnapshotQueryVariables
  >({ query: GetFootprintKindsForSnapshotDocument, ...options });
}
export const FootprintSnapshotSelectorVersionDocument = gql`
    query FootprintSnapshotSelectorVersion($footprintSnapshotId: String!) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintVersion {
      id
      kind
      versionName
      versionIdentifier
      originalFootprintSnapshotId
    }
  }
}
    `;

export function useFootprintSnapshotSelectorVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintSnapshotSelectorVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintSnapshotSelectorVersionQuery,
    GQTypes.GQFootprintSnapshotSelectorVersionQueryVariables
  >({ query: FootprintSnapshotSelectorVersionDocument, ...options });
}
export const EmissionsModelsOverviewDocument = gql`
    query EmissionsModelsOverview($footprintSnapshotId: String!) {
  emissionsModelVersionsForFootprint(footprintSnapshotId: $footprintSnapshotId) {
    id
    emv {
      id
      title
      description
      createdAt
    }
    releaseIds
  }
}
    `;

export function useEmissionsModelsOverviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelsOverviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelsOverviewQuery,
    GQTypes.GQEmissionsModelsOverviewQueryVariables
  >({ query: EmissionsModelsOverviewDocument, ...options });
}
export const MethodologiesOverviewDocument = gql`
    query MethodologiesOverview($footprintSnapshotId: String!) {
  calculationMethodVersionsForFootprint(footprintSnapshotId: $footprintSnapshotId) {
    id
    businessActivityTypeName
    displayName
    externalNotes
    updatedAt
  }
}
    `;

export function useMethodologiesOverviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologiesOverviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologiesOverviewQuery,
    GQTypes.GQMethodologiesOverviewQueryVariables
  >({ query: MethodologiesOverviewDocument, ...options });
}
export const CreateClimateProgramProjectDocument = gql`
    mutation CreateClimateProgramProject($input: CreateClimateProgramProjectInput!) {
  createClimateProgramProject(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectFields
    }
  }
}
    ${ClimateProgramProjectFieldsFragmentDoc}`;

export function useCreateClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateClimateProgramProjectMutation,
    GQTypes.GQCreateClimateProgramProjectMutationVariables
  >(CreateClimateProgramProjectDocument);
}
export const CreateClimateProgramCalendarEventDocument = gql`
    mutation CreateClimateProgramCalendarEvent($input: CreateClimateProgramCalendarEventInput!) {
  createClimateProgramCalendarEvent(input: $input) {
    climateProgramCalendarEvent {
      ...ClimateProgramCalendarEventFields
    }
  }
}
    ${ClimateProgramCalendarEventFieldsFragmentDoc}`;

export function useCreateClimateProgramCalendarEventMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateClimateProgramCalendarEventMutation,
    GQTypes.GQCreateClimateProgramCalendarEventMutationVariables
  >(CreateClimateProgramCalendarEventDocument);
}
export const UpdateClimateProgramCalendarEventDocument = gql`
    mutation UpdateClimateProgramCalendarEvent($input: UpdateClimateProgramCalendarEventInput!) {
  updateClimateProgramCalendarEvent(input: $input) {
    climateProgramCalendarEvent {
      ...ClimateProgramCalendarEventFields
    }
  }
}
    ${ClimateProgramCalendarEventFieldsFragmentDoc}`;

export function useUpdateClimateProgramCalendarEventMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateClimateProgramCalendarEventMutation,
    GQTypes.GQUpdateClimateProgramCalendarEventMutationVariables
  >(UpdateClimateProgramCalendarEventDocument);
}
export const DeleteClimateProgramCalendarEventDocument = gql`
    mutation DeleteClimateProgramCalendarEvent($input: DeleteClimateProgramCalendarEventInput!) {
  deleteClimateProgramCalendarEvent(input: $input) {
    id
  }
}
    `;

export function useDeleteClimateProgramCalendarEventMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteClimateProgramCalendarEventMutation,
    GQTypes.GQDeleteClimateProgramCalendarEventMutationVariables
  >(DeleteClimateProgramCalendarEventDocument);
}
export const UpdateClimateProgramProjectDocument = gql`
    mutation UpdateClimateProgramProject($input: UpdateClimateProgramProjectInput!) {
  updateClimateProgramProject(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectFields
    }
  }
}
    ${ClimateProgramProjectFieldsFragmentDoc}`;

export function useUpdateClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateClimateProgramProjectMutation,
    GQTypes.GQUpdateClimateProgramProjectMutationVariables
  >(UpdateClimateProgramProjectDocument);
}
export const DeleteClimateProgramProjectDocument = gql`
    mutation DeleteClimateProgramProject($input: DeleteClimateProgramProjectInput!) {
  deleteClimateProgramProject(input: $input) {
    id
  }
}
    `;

export function useDeleteClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteClimateProgramProjectMutation,
    GQTypes.GQDeleteClimateProgramProjectMutationVariables
  >(DeleteClimateProgramProjectDocument);
}
export const UpdateClimateProgramTimelineProjectDocument = gql`
    mutation UpdateClimateProgramTimelineProject($input: UpdateClimateProgramTimelineProjectInput!) {
  updateClimateProgramTimelineProject(input: $input) {
    climateProgramTimelineProject {
      ...ClimateProgramTimelineProjectFields
    }
  }
}
    ${ClimateProgramTimelineProjectFieldsFragmentDoc}`;

export function useUpdateClimateProgramTimelineProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateClimateProgramTimelineProjectMutation,
    GQTypes.GQUpdateClimateProgramTimelineProjectMutationVariables
  >(UpdateClimateProgramTimelineProjectDocument);
}
export const DeleteClimateProgramTimelineProjectDocument = gql`
    mutation DeleteClimateProgramTimelineProject($input: DeleteClimateProgramTimelineProjectInput!) {
  deleteClimateProgramTimelineProject(input: $input) {
    id
    objectType
  }
}
    `;

export function useDeleteClimateProgramTimelineProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteClimateProgramTimelineProjectMutation,
    GQTypes.GQDeleteClimateProgramTimelineProjectMutationVariables
  >(DeleteClimateProgramTimelineProjectDocument);
}
export const GetClimateProgramProjectDocument = gql`
    query getClimateProgramProject($cppId: ID!) {
  climateProgramProject(id: $cppId) {
    ...ClimateProgramProjectFields
  }
}
    ${ClimateProgramProjectFieldsFragmentDoc}`;

export function useGetClimateProgramProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetClimateProgramProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetClimateProgramProjectQuery,
    GQTypes.GQGetClimateProgramProjectQueryVariables
  >({ query: GetClimateProgramProjectDocument, ...options });
}
export const SetPreferredLocaleDocument = gql`
    mutation SetPreferredLocale($input: SetPreferredLocaleInput!) {
  setPreferredLocale(input: $input) {
    preferredLocale
  }
}
    `;

export function useSetPreferredLocaleMutation() {
  return Urql.useMutation<
    GQTypes.GQSetPreferredLocaleMutation,
    GQTypes.GQSetPreferredLocaleMutationVariables
  >(SetPreferredLocaleDocument);
}
export const DatasetIndexPageDocument = gql`
    query DatasetIndexPage($id: ID!, $measurementProjectId: ID) {
  organization {
    id
    measurementProjects {
      ...MeasurementProjectFields
    }
  }
  dataset(id: $id) {
    ...DatasetForSpecificDatasetPage
  }
}
    ${MeasurementProjectFieldsFragmentDoc}
${DatasetForSpecificDatasetPageFragmentDoc}`;

export function useDatasetIndexPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasetIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasetIndexPageQuery,
    GQTypes.GQDatasetIndexPageQueryVariables
  >({ query: DatasetIndexPageDocument, ...options });
}
export const InputDataByExtBartIdsDocument = gql`
    query InputDataByExtBartIds($extBartIds: [ID!]!) {
  inputDataByExtBartIds(extBartIds: $extBartIds) {
    totalRows
    rows
  }
}
    `;

export function useInputDataByExtBartIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQInputDataByExtBartIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQInputDataByExtBartIdsQuery,
    GQTypes.GQInputDataByExtBartIdsQueryVariables
  >({ query: InputDataByExtBartIdsDocument, ...options });
}
export const CreateDiscussionDocument = gql`
    mutation CreateDiscussion($input: CreateDiscussionInput!) {
  createDiscussion(input: $input) {
    discussion {
      ...DiscussionFields
    }
  }
}
    ${DiscussionFieldsFragmentDoc}`;

export function useCreateDiscussionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDiscussionMutation,
    GQTypes.GQCreateDiscussionMutationVariables
  >(CreateDiscussionDocument);
}
export const RespondToDiscussionDocument = gql`
    mutation RespondToDiscussion($input: RespondToDiscussionInput!) {
  respondToDiscussion(input: $input) {
    discussion {
      ...DiscussionFields
    }
  }
}
    ${DiscussionFieldsFragmentDoc}`;

export function useRespondToDiscussionMutation() {
  return Urql.useMutation<
    GQTypes.GQRespondToDiscussionMutation,
    GQTypes.GQRespondToDiscussionMutationVariables
  >(RespondToDiscussionDocument);
}
export const ResolveDiscussionDocument = gql`
    mutation ResolveDiscussion($input: ResolveDiscussionInput!) {
  resolveDiscussion(input: $input) {
    discussion {
      ...DiscussionFields
    }
  }
}
    ${DiscussionFieldsFragmentDoc}`;

export function useResolveDiscussionMutation() {
  return Urql.useMutation<
    GQTypes.GQResolveDiscussionMutation,
    GQTypes.GQResolveDiscussionMutationVariables
  >(ResolveDiscussionDocument);
}
export const CommentDiscussionsForAnchorDocument = gql`
    query CommentDiscussionsForAnchor($input: DiscussionAnchor!) {
  commentDiscussionsForAnchor(input: $input) {
    ...CommentDiscussionsForAnchorFields
  }
}
    ${CommentDiscussionsForAnchorFieldsFragmentDoc}`;

export function useCommentDiscussionsForAnchorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCommentDiscussionsForAnchorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCommentDiscussionsForAnchorQuery,
    GQTypes.GQCommentDiscussionsForAnchorQueryVariables
  >({ query: CommentDiscussionsForAnchorDocument, ...options });
}
export const FootprintSnapshotsForYearDocument = gql`
    query FootprintSnapshotsForYear($year: Int!) {
  footprintSnapshotsForYear(year: $year) {
    id
  }
}
    `;

export function useFootprintSnapshotsForYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintSnapshotsForYearQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintSnapshotsForYearQuery,
    GQTypes.GQFootprintSnapshotsForYearQueryVariables
  >({ query: FootprintSnapshotsForYearDocument, ...options });
}
export const GetYearMeasurementProjectsDocument = gql`
    query GetYearMeasurementProjects($reportingYear: Int!) {
  yearMeasurementProjects(reportingYear: $reportingYear) {
    ...MeasurementProjectFieldsForShareStatusEngagementTask
  }
}
    ${MeasurementProjectFieldsForShareStatusEngagementTaskFragmentDoc}`;

export function useGetYearMeasurementProjectsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetYearMeasurementProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetYearMeasurementProjectsQuery,
    GQTypes.GQGetYearMeasurementProjectsQueryVariables
  >({ query: GetYearMeasurementProjectsDocument, ...options });
}
export const AssetPageSnapshotDocument = gql`
    query AssetPageSnapshot($standardView: FinanceStandardView, $filters: UntypedData, $financeSnapshotId: ID!, $assetId: ID!, $year: Int!, $isTotalEmissions: Boolean) {
  financeHoldingView(
    financeSnapshotId: $financeSnapshotId
    year: $year
    isTotalEmissions: $isTotalEmissions
    filters: $filters
    standardView: $standardView
  ) {
    charts {
      id
      ...FinanceChartAllFields
    }
    metricBar {
      id
      ...FinanceMetricBarMinimal
    }
  }
  financeSnapshotAsset(
    financeSnapshotId: $financeSnapshotId
    assetId: $assetId
    year: $year
  ) {
    asset
  }
}
    ${FinanceChartAllFieldsFragmentDoc}
${FinanceMetricBarMinimalFragmentDoc}`;

export function useAssetPageSnapshotQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetPageSnapshotQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetPageSnapshotQuery,
    GQTypes.GQAssetPageSnapshotQueryVariables
  >({ query: AssetPageSnapshotDocument, ...options });
}
export const FinanceSettingsDocument = gql`
    query FinanceSettings {
  funds {
    id
    ...FundAllFields
  }
  financeTags(tagType: FundTag) {
    id
    ...FinanceTagForGrid
  }
}
    ${FundAllFieldsFragmentDoc}
${FinanceTagForGridFragmentDoc}`;

export function useFinanceSettingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSettingsQuery,
    GQTypes.GQFinanceSettingsQueryVariables
  >({ query: FinanceSettingsDocument, ...options });
}
export const SnapshotAssemblyCreateSnapshotDocument = gql`
    mutation SnapshotAssemblyCreateSnapshot($input: CreateSnapshotInput!) {
  createSnapshot(input: $input) {
    snapshot {
      id
    }
  }
}
    `;

export function useSnapshotAssemblyCreateSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQSnapshotAssemblyCreateSnapshotMutation,
    GQTypes.GQSnapshotAssemblyCreateSnapshotMutationVariables
  >(SnapshotAssemblyCreateSnapshotDocument);
}
export const SnapshotAssemblyFlowDocument = gql`
    query SnapshotAssemblyFlow {
  financeSettings {
    id
    estimateDownstreamScope3
  }
  financeMetadata {
    anyAssetYearHasEstimationMethodology
    hasSomeAssetCorporate
  }
}
    `;

export function useSnapshotAssemblyFlowQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSnapshotAssemblyFlowQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSnapshotAssemblyFlowQuery,
    GQTypes.GQSnapshotAssemblyFlowQueryVariables
  >({ query: SnapshotAssemblyFlowDocument, ...options });
}
export const GenerateAuditReportForAssetYearDocument = gql`
    mutation GenerateAuditReportForAssetYear($input: FinanceAuditReportForAssetYearInput!) {
  generateAuditReportForAssetYear(input: $input) {
    jobId
  }
}
    `;

export function useGenerateAuditReportForAssetYearMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateAuditReportForAssetYearMutation,
    GQTypes.GQGenerateAuditReportForAssetYearMutationVariables
  >(GenerateAuditReportForAssetYearDocument);
}
export const FinanceLogEventsDocument = gql`
    query FinanceLogEvents($filters: [BiQueryFilterInput!], $after: String, $before: String, $first: Int, $last: Int) {
  financeChangelog(
    filters: $filters
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        ...FinanceChangelogEntry
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${FinanceChangelogEntryFragmentDoc}`;

export function useFinanceLogEventsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceLogEventsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceLogEventsQuery,
    GQTypes.GQFinanceLogEventsQueryVariables
  >({ query: FinanceLogEventsDocument, ...options });
}
export const UpdateAssetCorporateDocument = gql`
    mutation UpdateAssetCorporate($input: UpdateAssetCorporateInput!) {
  updateAssetCorporate(input: $input) {
    asset {
      id
      ...AssetCorporateAllFields
      company {
        id
        ...CompanyAllFields
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}
${CompanyAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetCorporateMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetCorporateMutation,
    GQTypes.GQUpdateAssetCorporateMutationVariables
  >(UpdateAssetCorporateDocument);
}
export const UpdateAssetGroupDocument = gql`
    mutation UpdateAssetGroup($input: UpdateAssetGroupInput!) {
  updateAssetGroup(input: $input) {
    asset {
      id
      ...AssetGroupAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetGroupAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetGroupMutation,
    GQTypes.GQUpdateAssetGroupMutationVariables
  >(UpdateAssetGroupDocument);
}
export const CreateAssetHoldingDocument = gql`
    mutation CreateAssetHolding($input: CreateAssetHoldingNewInput!, $year: Int) {
  createAssetHolding(input: $input) {
    assetHolding {
      id
      ...AssetHoldingAllFields
      asset {
        id
        assetHoldings(year: $year) {
          id
        }
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetHoldingAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useCreateAssetHoldingMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetHoldingMutation,
    GQTypes.GQCreateAssetHoldingMutationVariables
  >(CreateAssetHoldingDocument);
}
export const DeleteAssetHoldingDocument = gql`
    mutation DeleteAssetHolding($input: DeleteAssetHoldingInput!, $year: Int) {
  deleteAssetHolding(input: $input) {
    id
    asset {
      id
      assetHoldings(year: $year) {
        id
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useDeleteAssetHoldingMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAssetHoldingMutation,
    GQTypes.GQDeleteAssetHoldingMutationVariables
  >(DeleteAssetHoldingDocument);
}
export const UpdateAssetHoldingDocument = gql`
    mutation UpdateAssetHolding($input: UpdateAssetHoldingNewInput!) {
  updateAssetHolding(input: $input) {
    assetHolding {
      id
      ...AssetHoldingAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetHoldingAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetHoldingMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetHoldingMutation,
    GQTypes.GQUpdateAssetHoldingMutationVariables
  >(UpdateAssetHoldingDocument);
}
export const AssetInfoPageDocument = gql`
    query AssetInfoPage($assetId: ID!) {
  asset(id: $assetId) {
    ...AssetAllFields
    assetGroupAssets {
      ...AssetGroupAssetAllFields
    }
    ... on AssetCorporate {
      company {
        ...CompanyFieldsForCCIS
        parentRelationships(onlyGetActiveRelationships: true) {
          ...ParentRelationshipFields
        }
        parentRelationshipsForSidebar: parentRelationships {
          ...ParentRelationshipForSidebarFields
        }
        contacts {
          ...SupplierContactFields
        }
      }
      engagementTasks {
        ...EngagementTaskFields
      }
    }
  }
  funds {
    id
    ...FundAllFields
  }
}
    ${AssetAllFieldsFragmentDoc}
${AssetGroupAssetAllFieldsFragmentDoc}
${CompanyFieldsForCcisFragmentDoc}
${ParentRelationshipFieldsFragmentDoc}
${ParentRelationshipForSidebarFieldsFragmentDoc}
${SupplierContactFieldsFragmentDoc}
${EngagementTaskFieldsFragmentDoc}
${FundAllFieldsFragmentDoc}`;

export function useAssetInfoPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetInfoPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetInfoPageQuery,
    GQTypes.GQAssetInfoPageQueryVariables
  >({ query: AssetInfoPageDocument, ...options });
}
export const AssetInfoPageEditableDocument = gql`
    query AssetInfoPageEditable($assetId: ID!, $year: Int) {
  asset(id: $assetId) {
    ...AssetAllFields
    footprintSourceOptions(year: $year) {
      id
      label
      year
      source
      externalScore
    }
    revenueSourceOptions(year: $year) {
      id
      label
      year
      revenue
      currencyCode
    }
    assetYears(year: $year) {
      ...AssetYearAllFields
    }
    assetHoldings(year: $year) {
      ...AssetHoldingAllFields
    }
    assetGroupAssets {
      ...AssetGroupAssetAllFields
    }
    ... on AssetCorporate {
      company {
        ...CompanyFieldsForCCIS
        parentRelationships(onlyGetActiveRelationships: true) {
          ...ParentRelationshipFields
        }
        parentRelationshipsForSidebar: parentRelationships {
          ...ParentRelationshipForSidebarFields
        }
        contacts {
          ...SupplierContactFields
        }
      }
      engagementTasks {
        ...EngagementTaskFields
      }
    }
  }
  funds {
    id
    ...FundAllFields
  }
}
    ${AssetAllFieldsFragmentDoc}
${AssetYearAllFieldsFragmentDoc}
${AssetHoldingAllFieldsFragmentDoc}
${AssetGroupAssetAllFieldsFragmentDoc}
${CompanyFieldsForCcisFragmentDoc}
${ParentRelationshipFieldsFragmentDoc}
${ParentRelationshipForSidebarFieldsFragmentDoc}
${SupplierContactFieldsFragmentDoc}
${EngagementTaskFieldsFragmentDoc}
${FundAllFieldsFragmentDoc}`;

export function useAssetInfoPageEditableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetInfoPageEditableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetInfoPageEditableQuery,
    GQTypes.GQAssetInfoPageEditableQueryVariables
  >({ query: AssetInfoPageEditableDocument, ...options });
}
export const AssetInfoPageSnapshotsDocument = gql`
    query AssetInfoPageSnapshots($financeSnapshotId: ID!, $filters: UntypedData!, $year: Int!, $assetId: ID!) {
  financeSnapshotHoldings(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
  ) {
    id
    edges {
      node
    }
  }
  financeSnapshotAsset(
    financeSnapshotId: $financeSnapshotId
    assetId: $assetId
    year: $year
  ) {
    asset
  }
}
    `;

export function useAssetInfoPageSnapshotsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetInfoPageSnapshotsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetInfoPageSnapshotsQuery,
    GQTypes.GQAssetInfoPageSnapshotsQueryVariables
  >({ query: AssetInfoPageSnapshotsDocument, ...options });
}
export const UpdateAssetPersonalMotorVehicleInsuranceDocument = gql`
    mutation UpdateAssetPersonalMotorVehicleInsurance($input: UpdateAssetPersonalMotorVehicleInsuranceInput!) {
  updateAssetPersonalMotorVehicleInsurance(input: $input) {
    asset {
      id
      ...AssetPersonalMotorVehicleInsuranceAllFields
      assetYears {
        id
        currencyCode
        valueNative
        emissionsYearId
      }
      footprintSourceOptions {
        id
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetPersonalMotorVehicleInsuranceAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetPersonalMotorVehicleInsuranceMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetPersonalMotorVehicleInsuranceMutation,
    GQTypes.GQUpdateAssetPersonalMotorVehicleInsuranceMutationVariables
  >(UpdateAssetPersonalMotorVehicleInsuranceDocument);
}
export const UpdateAssetRealEstateDocument = gql`
    mutation UpdateAssetRealEstate($input: UpdateAssetRealEstateInput!) {
  updateAssetRealEstate(input: $input) {
    asset {
      id
      ...AssetRealEstateAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetRealEstateAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetRealEstateMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetRealEstateMutation,
    GQTypes.GQUpdateAssetRealEstateMutationVariables
  >(UpdateAssetRealEstateDocument);
}
export const UpdateAssetSovereignBondDocument = gql`
    mutation UpdateAssetSovereignBond($input: UpdateAssetSovereignBondInput!) {
  updateAssetSovereignBond(input: $input) {
    asset {
      id
      ...AssetSovereignBondAllFields
      assetYears {
        id
        currencyCode
        valueNative
        emissionsYearId
      }
      footprintSourceOptions {
        id
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetSovereignBondAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetSovereignBondMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetSovereignBondMutation,
    GQTypes.GQUpdateAssetSovereignBondMutationVariables
  >(UpdateAssetSovereignBondDocument);
}
export const CreateAssetYearDocument = gql`
    mutation CreateAssetYear($input: CreateAssetYearNewInput!, $year: Int) {
  createAssetYear(input: $input) {
    assetYear {
      id
      ...AssetYearAllFields
      asset {
        id
        assetYears(year: $year) {
          ...AssetYearAllFields
        }
      }
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useCreateAssetYearMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetYearMutation,
    GQTypes.GQCreateAssetYearMutationVariables
  >(CreateAssetYearDocument);
}
export const UpdateAssetYearDocument = gql`
    mutation UpdateAssetYear($input: UpdateAssetYearNewInput!) {
  updateAssetYear(input: $input) {
    assetYear {
      id
      ...AssetYearAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetYearMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetYearMutation,
    GQTypes.GQUpdateAssetYearMutationVariables
  >(UpdateAssetYearDocument);
}
export const DeleteFinanceSavedViewDocument = gql`
    mutation DeleteFinanceSavedView($input: DeleteFinanceSavedViewInput!) {
  deleteFinanceSavedView(input: $input) {
    id
  }
}
    `;

export function useDeleteFinanceSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinanceSavedViewMutation,
    GQTypes.GQDeleteFinanceSavedViewMutationVariables
  >(DeleteFinanceSavedViewDocument);
}
export const FinanceSavedViewFormDocument = gql`
    query FinanceSavedViewForm {
  financeTags {
    id
    ...FinanceTagForChartSelector
  }
}
    ${FinanceTagForChartSelectorFragmentDoc}`;

export function useFinanceSavedViewFormQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSavedViewFormQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSavedViewFormQuery,
    GQTypes.GQFinanceSavedViewFormQueryVariables
  >({ query: FinanceSavedViewFormDocument, ...options });
}
export const AssetNameDocument = gql`
    query AssetName($assetId: ID!) {
  asset(id: $assetId) {
    id
    name
  }
}
    `;

export function useAssetNameQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQAssetNameQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQAssetNameQuery,
    GQTypes.GQAssetNameQueryVariables
  >({ query: AssetNameDocument, ...options });
}
export const FinanceMetricsAndChartsDocument = gql`
    query FinanceMetricsAndCharts($financeSnapshotId: ID, $year: Int, $isTotalEmissions: Boolean, $filters: UntypedData, $standardView: FinanceStandardView, $viewId: ID) {
  financeHoldingView(
    financeSnapshotId: $financeSnapshotId
    year: $year
    isTotalEmissions: $isTotalEmissions
    filters: $filters
    standardView: $standardView
    viewId: $viewId
  ) {
    metricBar {
      id
      ...FinanceMetricBarMinimal
    }
    charts {
      id
      ...FinanceChartAllFields
    }
  }
}
    ${FinanceMetricBarMinimalFragmentDoc}
${FinanceChartAllFieldsFragmentDoc}`;

export function useFinanceMetricsAndChartsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceMetricsAndChartsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceMetricsAndChartsQuery,
    GQTypes.GQFinanceMetricsAndChartsQueryVariables
  >({ query: FinanceMetricsAndChartsDocument, ...options });
}
export const FinanceMeasurementProgressTabDocument = gql`
    query FinanceMeasurementProgressTab($year: Int!, $filters: UntypedData!) {
  financeMeasurementProgressRows(year: $year, filters: $filters) {
    ...FinanceMeasurementProgressRowAllFields
  }
  funds {
    id
    name
  }
}
    ${FinanceMeasurementProgressRowAllFieldsFragmentDoc}`;

export function useFinanceMeasurementProgressTabQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceMeasurementProgressTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceMeasurementProgressTabQuery,
    GQTypes.GQFinanceMeasurementProgressTabQueryVariables
  >({ query: FinanceMeasurementProgressTabDocument, ...options });
}
export const FinanceAutomaticRemindersDocument = gql`
    query FinanceAutomaticReminders {
  financeAutomaticReminders {
    ...FinanceAutomaticReminderAllFields
  }
}
    ${FinanceAutomaticReminderAllFieldsFragmentDoc}`;

export function useFinanceAutomaticRemindersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAutomaticRemindersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAutomaticRemindersQuery,
    GQTypes.GQFinanceAutomaticRemindersQueryVariables
  >({ query: FinanceAutomaticRemindersDocument, ...options });
}
export const UpdateFinanceAutomaticRemindersDocument = gql`
    mutation UpdateFinanceAutomaticReminders($input: UpdateFinanceAutomaticRemindersInput!) {
  updateFinanceAutomaticReminders(input: $input) {
    financeAutomaticReminders {
      ...FinanceAutomaticReminderAllFields
    }
  }
}
    ${FinanceAutomaticReminderAllFieldsFragmentDoc}`;

export function useUpdateFinanceAutomaticRemindersMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceAutomaticRemindersMutation,
    GQTypes.GQUpdateFinanceAutomaticRemindersMutationVariables
  >(UpdateFinanceAutomaticRemindersDocument);
}
export const UpdatePortalSettingsDocument = gql`
    mutation UpdatePortalSettings($portalSettingsInput: UpsertCompanyPortalSettingsInput!) {
  upsertCompanyPortalSettings(input: $portalSettingsInput) {
    companyPortalSettings {
      ...CompanyPortalSettingsFields
    }
  }
}
    ${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useUpdatePortalSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePortalSettingsMutation,
    GQTypes.GQUpdatePortalSettingsMutationVariables
  >(UpdatePortalSettingsDocument);
}
export const GetPortalSettingsDocument = gql`
    query GetPortalSettings($rootCompanyId: ID!) {
  companyPortalSettings(rootCompanyId: $rootCompanyId) {
    ...CompanyPortalSettingsFields
  }
}
    ${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useGetPortalSettingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPortalSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPortalSettingsQuery,
    GQTypes.GQGetPortalSettingsQueryVariables
  >({ query: GetPortalSettingsDocument, ...options });
}
export const FinanceAssetsForDataTasksDocument = gql`
    query FinanceAssetsForDataTasks {
  assetsCorporate {
    ...AssetCorporateDataForTasks
  }
}
    ${AssetCorporateDataForTasksFragmentDoc}`;

export function useFinanceAssetsForDataTasksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetsForDataTasksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetsForDataTasksQuery,
    GQTypes.GQFinanceAssetsForDataTasksQueryVariables
  >({ query: FinanceAssetsForDataTasksDocument, ...options });
}
export const FinanceCompanyMatchingTabDocument = gql`
    query FinanceCompanyMatchingTab($search: String, $after: String, $before: String, $first: Int, $last: Int) {
  assetsCorporatePaginated(
    search: $search
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        ...AssetCorporateForMatching
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AssetCorporateForMatchingFragmentDoc}`;

export function useFinanceCompanyMatchingTabQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceCompanyMatchingTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceCompanyMatchingTabQuery,
    GQTypes.GQFinanceCompanyMatchingTabQueryVariables
  >({ query: FinanceCompanyMatchingTabDocument, ...options });
}
export const UpdateAssetYearsDocument = gql`
    mutation UpdateAssetYears($input: UpdateAssetYearsNewInput!) {
  updateAssetYears(input: $input) {
    assetYears {
      id
      ...AssetYearAllFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateAssetYearsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetYearsMutation,
    GQTypes.GQUpdateAssetYearsMutationVariables
  >(UpdateAssetYearsDocument);
}
export const FinanceDataFlagsDocument = gql`
    query FinanceDataFlags($financeSnapshotId: ID, $fundId: ID, $assetId: ID, $year: Int) {
  financeDataFlags(
    financeSnapshotId: $financeSnapshotId
    fundId: $fundId
    assetId: $assetId
    year: $year
  ) {
    ...FinanceDataFlagForFlagView
  }
}
    ${FinanceDataFlagForFlagViewFragmentDoc}`;

export function useFinanceDataFlagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceDataFlagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceDataFlagsQuery,
    GQTypes.GQFinanceDataFlagsQueryVariables
  >({ query: FinanceDataFlagsDocument, ...options });
}
export const GetFinanceImportDiffDocument = gql`
    query GetFinanceImportDiff($financeImportId: ID!) {
  financeImport(id: $financeImportId) {
    id
    diff
  }
}
    `;

export function useGetFinanceImportDiffQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFinanceImportDiffQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFinanceImportDiffQuery,
    GQTypes.GQGetFinanceImportDiffQueryVariables
  >({ query: GetFinanceImportDiffDocument, ...options });
}
export const AcceptFinanceImportDiffDocument = gql`
    mutation AcceptFinanceImportDiff($input: AcceptFinanceImportDiffInput!) {
  acceptFinanceImportDiff(input: $input) {
    financeImport {
      id
      accepted
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useAcceptFinanceImportDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptFinanceImportDiffMutation,
    GQTypes.GQAcceptFinanceImportDiffMutationVariables
  >(AcceptFinanceImportDiffDocument);
}
export const CreateFinanceImportDocument = gql`
    mutation CreateFinanceImport($input: CreateFinanceImportInput!) {
  createFinanceImport(input: $input) {
    financeImport {
      id
    }
    backgroundJobId
  }
}
    `;

export function useCreateFinanceImportMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceImportMutation,
    GQTypes.GQCreateFinanceImportMutationVariables
  >(CreateFinanceImportDocument);
}
export const CreateFinanceUploadTemplateDocument = gql`
    mutation CreateFinanceUploadTemplate($input: CreateFinanceUploadTemplateInput!) {
  createFinanceUploadTemplate(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function useCreateFinanceUploadTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceUploadTemplateMutation,
    GQTypes.GQCreateFinanceUploadTemplateMutationVariables
  >(CreateFinanceUploadTemplateDocument);
}
export const DeleteAssetYearsDocument = gql`
    mutation DeleteAssetYears($input: DeleteAssetYearsInput!) {
  deleteAssetYears(input: $input) {
    assetYearIds
    assetHoldingIds
    privateDisclosureIds
    emissionsYearIds
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useDeleteAssetYearsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAssetYearsMutation,
    GQTypes.GQDeleteAssetYearsMutationVariables
  >(DeleteAssetYearsDocument);
}
export const DeleteAssetHoldingsDocument = gql`
    mutation DeleteAssetHoldings($input: DeleteAssetHoldingsInput!) {
  deleteAssetHoldings(input: $input) {
    ids
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useDeleteAssetHoldingsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAssetHoldingsMutation,
    GQTypes.GQDeleteAssetHoldingsMutationVariables
  >(DeleteAssetHoldingsDocument);
}
export const FinanceModelCardMetadataDocument = gql`
    query FinanceModelCardMetadata($year: Int!) {
  financeModelCardMetadata(year: $year) {
    id
    numFunds
    numAssets
    numAnnualData
    numHoldings
    numContacts
  }
}
    `;

export function useFinanceModelCardMetadataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceModelCardMetadataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceModelCardMetadataQuery,
    GQTypes.GQFinanceModelCardMetadataQueryVariables
  >({ query: FinanceModelCardMetadataDocument, ...options });
}
export const FinanceDataPreviousImportsDocument = gql`
    query FinanceDataPreviousImports {
  financeImports {
    ...financeImportsForRender
  }
}
    ${FinanceImportsForRenderFragmentDoc}`;

export function useFinanceDataPreviousImportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceDataPreviousImportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceDataPreviousImportsQuery,
    GQTypes.GQFinanceDataPreviousImportsQueryVariables
  >({ query: FinanceDataPreviousImportsDocument, ...options });
}
export const CreateCorporateSandboxEstimationDocument = gql`
    mutation CreateCorporateSandboxEstimation($input: CreateCorporateSandboxEstimationInput!) {
  createCorporateSandboxEstimation(input: $input) {
    backgroundJobId
  }
}
    `;

export function useCreateCorporateSandboxEstimationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCorporateSandboxEstimationMutation,
    GQTypes.GQCreateCorporateSandboxEstimationMutationVariables
  >(CreateCorporateSandboxEstimationDocument);
}
export const FinanceSandboxCorporateMetricsViewDocument = gql`
    query FinanceSandboxCorporateMetricsView($emissionsYearId: ID!) {
  emissionsYear(emissionsYearId: $emissionsYearId) {
    ...EmissionsYearAllFields
    emissionsByGhgCategory {
      fullIntervalGross
      topGhgCategories {
        ...GhgCategorizedEmissionDataFields
      }
      topGhgCategoriesWithSubcategories {
        ...GhgCategorizedEmissionDataFields
      }
    }
    priorEstimateInputs
  }
}
    ${EmissionsYearAllFieldsFragmentDoc}
${GhgCategorizedEmissionDataFieldsFragmentDoc}`;

export function useFinanceSandboxCorporateMetricsViewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSandboxCorporateMetricsViewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSandboxCorporateMetricsViewQuery,
    GQTypes.GQFinanceSandboxCorporateMetricsViewQueryVariables
  >({ query: FinanceSandboxCorporateMetricsViewDocument, ...options });
}
export const CreateRealEstateSandboxEstimationDocument = gql`
    mutation CreateRealEstateSandboxEstimation($input: CreateRealEstateSandboxEstimationInput!) {
  createRealEstateSandboxEstimation(input: $input) {
    backgroundJobId
  }
}
    `;

export function useCreateRealEstateSandboxEstimationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateRealEstateSandboxEstimationMutation,
    GQTypes.GQCreateRealEstateSandboxEstimationMutationVariables
  >(CreateRealEstateSandboxEstimationDocument);
}
export const FinanceSandboxRealEstateMetricsViewDocument = gql`
    query FinanceSandboxRealEstateMetricsView($emissionsYearId: ID!) {
  emissionsYear(emissionsYearId: $emissionsYearId) {
    ...EmissionsYearAllFields
  }
}
    ${EmissionsYearAllFieldsFragmentDoc}`;

export function useFinanceSandboxRealEstateMetricsViewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSandboxRealEstateMetricsViewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSandboxRealEstateMetricsViewQuery,
    GQTypes.GQFinanceSandboxRealEstateMetricsViewQueryVariables
  >({ query: FinanceSandboxRealEstateMetricsViewDocument, ...options });
}
export const GetFinanceSandboxHistoryDocument = gql`
    query GetFinanceSandboxHistory($before: String, $after: String, $first: Int, $last: Int) {
  sandboxEmissionsYears(
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...FinanceSandboxHistoryFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${FinanceSandboxHistoryFieldsFragmentDoc}`;

export function useGetFinanceSandboxHistoryQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFinanceSandboxHistoryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFinanceSandboxHistoryQuery,
    GQTypes.GQGetFinanceSandboxHistoryQueryVariables
  >({ query: GetFinanceSandboxHistoryDocument, ...options });
}
export const FinanceSettingsTabDocument = gql`
    query FinanceSettingsTab {
  financeSettings {
    ...FinanceSettingsFields
  }
}
    ${FinanceSettingsFieldsFragmentDoc}`;

export function useFinanceSettingsTabQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSettingsTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSettingsTabQuery,
    GQTypes.GQFinanceSettingsTabQueryVariables
  >({ query: FinanceSettingsTabDocument, ...options });
}
export const UpdateFinanceSettingsDocument = gql`
    mutation UpdateFinanceSettings($input: UpdateFinanceSettingsInput!) {
  updateFinanceSettings(input: $input) {
    financeSettings {
      ...FinanceSettingsFields
    }
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSettingsFieldsFragmentDoc}
${FinanceSnapshotIsStaleFragmentDoc}`;

export function useUpdateFinanceSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceSettingsMutation,
    GQTypes.GQUpdateFinanceSettingsMutationVariables
  >(UpdateFinanceSettingsDocument);
}
export const FinanceShowYourWorkDocument = gql`
    query FinanceShowYourWork($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $financeMetric: FinanceMetric!) {
  financeShowYourWork(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    financeMetric: $financeMetric
  ) {
    subtitle
    aggregateRows
    rowsWithOnlyFinancedEmissions
  }
}
    `;

export function useFinanceShowYourWorkQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceShowYourWorkQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceShowYourWorkQuery,
    GQTypes.GQFinanceShowYourWorkQueryVariables
  >({ query: FinanceShowYourWorkDocument, ...options });
}
export const AssetCorporateSummaryPageDocument = gql`
    query AssetCorporateSummaryPage($assetCorporateId: ID!, $year: Int!, $emissionsYearId: ID) {
  assetCorporate(assetCorporateId: $assetCorporateId) {
    id
    emissionsByGhgCategory(year: $year, emissionsYearId: $emissionsYearId) {
      fullIntervalGross
      topGhgCategories {
        ...GhgCategorizedEmissionDataFields
      }
      topGhgCategoriesWithSubcategories {
        ...GhgCategorizedEmissionDataFields
      }
    }
    benchmarks {
      ...BenchmarkFieldsForExplorer
    }
    selfBenchmark(year: $year) {
      ...BenchmarkFieldsForExplorer
    }
    company {
      ...CompanyFieldsForCCISAndParents
    }
    disclosures {
      ...SupplierDisclosureFields
    }
  }
}
    ${GhgCategorizedEmissionDataFieldsFragmentDoc}
${BenchmarkFieldsForExplorerFragmentDoc}
${CompanyFieldsForCcisAndParentsFragmentDoc}
${SupplierDisclosureFieldsFragmentDoc}`;

export function useAssetCorporateSummaryPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetCorporateSummaryPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetCorporateSummaryPageQuery,
    GQTypes.GQAssetCorporateSummaryPageQueryVariables
  >({ query: AssetCorporateSummaryPageDocument, ...options });
}
export const FinanceAssetsYearOverYearDocument = gql`
    query FinanceAssetsYearOverYear($financeSnapshotId: ID!, $baselineSnapshotId: ID!, $baselineYear: Int!, $comparisonYear: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) {
  financeAssetsYearOverYear(
    financeSnapshotId: $financeSnapshotId
    baselineSnapshotId: $baselineSnapshotId
    baselineYear: $baselineYear
    comparisonYear: $comparisonYear
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceAssetsYearOverYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetsYearOverYearQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetsYearOverYearQuery,
    GQTypes.GQFinanceAssetsYearOverYearQueryVariables
  >({ query: FinanceAssetsYearOverYearDocument, ...options });
}
export const FinanceFundsYearOverYearDocument = gql`
    query FinanceFundsYearOverYear($financeSnapshotId: ID!, $baselineSnapshotId: ID!, $baselineYear: Int!, $comparisonYear: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) {
  financeFundsYearOverYear(
    financeSnapshotId: $financeSnapshotId
    baselineSnapshotId: $baselineSnapshotId
    baselineYear: $baselineYear
    comparisonYear: $comparisonYear
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceFundsYearOverYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceFundsYearOverYearQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceFundsYearOverYearQuery,
    GQTypes.GQFinanceFundsYearOverYearQueryVariables
  >({ query: FinanceFundsYearOverYearDocument, ...options });
}
export const FinanceSnapshotWaterfallChartDocument = gql`
    query FinanceSnapshotWaterfallChart($financeSnapshotId: ID!, $baselineSnapshotId: ID!, $baselineYear: Int!, $comparisonYear: Int!, $filters: UntypedData!, $financeWaterfallChartType: FinanceWaterfallChartType!) {
  financeSnapshotWaterfallChart(
    financeSnapshotId: $financeSnapshotId
    baselineSnapshotId: $baselineSnapshotId
    baselineYear: $baselineYear
    comparisonYear: $comparisonYear
    filters: $filters
    financeWaterfallChartType: $financeWaterfallChartType
  ) {
    waterfallBlocks {
      id
      ...WaterfallBlockAllFields
    }
  }
}
    ${WaterfallBlockAllFieldsFragmentDoc}`;

export function useFinanceSnapshotWaterfallChartQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotWaterfallChartQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotWaterfallChartQuery,
    GQTypes.GQFinanceSnapshotWaterfallChartQueryVariables
  >({ query: FinanceSnapshotWaterfallChartDocument, ...options });
}
export const FinanceGridWorksheetDocument = gql`
    query FinanceGridWorksheet($standardView: FinanceStandardView!) {
  financeGridWorksheet(standardView: $standardView) {
    id
    ...FinanceWorksheetAssetGrid
  }
}
    ${FinanceWorksheetAssetGridFragmentDoc}`;

export function useFinanceGridWorksheetQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceGridWorksheetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceGridWorksheetQuery,
    GQTypes.GQFinanceGridWorksheetQueryVariables
  >({ query: FinanceGridWorksheetDocument, ...options });
}
export const GetFootprintBridgeChartDataDocument = gql`
    query GetFootprintBridgeChartData($footprintSnapshotId: String!, $shouldShowFootprintBridge: Boolean, $aggregateKind: AggregateKind!, $intensityKind: IntensityDenominatorKind, $groupBy: FootprintBridgeGroupByColumn!) {
  footprint: legacyFootprint(footprintSnapshotId: $footprintSnapshotId) {
    id
    bridgeWithDates(
      shouldShowFootprintBridge: $shouldShowFootprintBridge
      aggregateKind: $aggregateKind
      intensityKind: $intensityKind
      groupBy: $groupBy
    ) {
      footprintBridgeRecords {
        groupByValue
        aggregateKind
        groupBy
        emissionsOld
        activityDeltaPositive
        activityDeltaNegative
        methodologyDeltaPositive
        methodologyDeltaNegative
        emissionsNew
      }
      footprintBridgeDetails {
        groupByValue
        inputUnit
        inputQuantityOld
        kgco2eQuantityOld
        inputQuantityNew
        kgco2eQuantityNew
        emissionsOld
        emissionsCounterfactual
        emissionsNew
        methodologyDelta
        activityDelta
      }
      footprintBridgeDates {
        oldStartDate
        oldEndDate
        newStartDate
        newEndDate
      }
    }
  }
}
    `;

export function useGetFootprintBridgeChartDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintBridgeChartDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintBridgeChartDataQuery,
    GQTypes.GQGetFootprintBridgeChartDataQueryVariables
  >({ query: GetFootprintBridgeChartDataDocument, ...options });
}
export const GetFootprintMetadataForSnapshotDocument = gql`
    query GetFootprintMetadataForSnapshot($footprintSnapshotId: String!) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintInterval
    footprintVersion {
      ...FootprintVersionForDrilldown
    }
    snapshotFootprintKindsMeta {
      ...FootprintKindMetaForDrilldown
    }
  }
}
    ${FootprintVersionForDrilldownFragmentDoc}
${FootprintKindMetaForDrilldownFragmentDoc}`;

export function useGetFootprintMetadataForSnapshotQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintMetadataForSnapshotQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintMetadataForSnapshotQuery,
    GQTypes.GQGetFootprintMetadataForSnapshotQueryVariables
  >({ query: GetFootprintMetadataForSnapshotDocument, ...options });
}
export const PublishDraftFootprintDocument = gql`
    mutation PublishDraftFootprint($input: PublishDraftFootprintInput!) {
  publishDraftFootprint(input: $input) {
    footprintAnalysis {
      id
    }
  }
}
    `;

export function usePublishDraftFootprintMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishDraftFootprintMutation,
    GQTypes.GQPublishDraftFootprintMutationVariables
  >(PublishDraftFootprintDocument);
}
export const SetPeerCompanyGroupMemberFeatureStatusDocument = gql`
    mutation SetPeerCompanyGroupMemberFeatureStatus($input: SetPeerCompanyGroupMemberFeatureStatusInput!) {
  setPeerCompanyGroupMemberFeatureStatus(input: $input) {
    peerCompanyGroupMember {
      id
      shouldHideFromFootprintOverview
      revenueAtRisk
    }
  }
}
    `;

export function useSetPeerCompanyGroupMemberFeatureStatusMutation() {
  return Urql.useMutation<
    GQTypes.GQSetPeerCompanyGroupMemberFeatureStatusMutation,
    GQTypes.GQSetPeerCompanyGroupMemberFeatureStatusMutationVariables
  >(SetPeerCompanyGroupMemberFeatureStatusDocument);
}
export const CategorySubcategoriesQueryDocument = gql`
    query CategorySubcategoriesQuery($filters: FootprintAnalysisFilters!, $version: String, $footprintSnapshotId: String) {
  footprintAnalysis(version: $version, footprintSnapshotId: $footprintSnapshotId) {
    id
    timeseries(kind: Total, grouping: "businessSubcategory", filters: $filters) {
      id
      series {
        label
        value
        data {
          date
          value
        }
      }
    }
  }
}
    `;

export function useCategorySubcategoriesQueryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCategorySubcategoriesQueryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCategorySubcategoriesQueryQuery,
    GQTypes.GQCategorySubcategoriesQueryQueryVariables
  >({ query: CategorySubcategoriesQueryDocument, ...options });
}
export const GetFootprintChangelogV3EntriesDocument = gql`
    query GetFootprintChangelogV3Entries {
  userHasPermission(permission: ViewFootprintDetail)
  footprintChangelog {
    id
    latestDraftVersion: versions(
      first: 1
      filters: {footprintVersionKindFilter: Draft}
    ) {
      edges {
        node {
          ...FootprintVersionForChangelogV3
        }
      }
    }
    publishedVersions: versions(filters: {footprintVersionKindFilter: Publish}) {
      edges {
        node {
          ...FootprintVersionForChangelogV3
        }
      }
    }
  }
  latestFootprintAssemblyRun {
    ...FootprintAssemblyRunForChangelog
  }
  activeMeasurementProjects {
    id
  }
}
    ${FootprintVersionForChangelogV3FragmentDoc}
${FootprintAssemblyRunForChangelogFragmentDoc}`;

export function useGetFootprintChangelogV3EntriesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintChangelogV3EntriesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintChangelogV3EntriesQuery,
    GQTypes.GQGetFootprintChangelogV3EntriesQueryVariables
  >({ query: GetFootprintChangelogV3EntriesDocument, ...options });
}
export const DeduplicationSuggestionsDocument = gql`
    query DeduplicationSuggestions($footprintSnapshotId: ID!) {
  organization {
    id
    footprintExclusionSuggestions(footprintSnapshotId: $footprintSnapshotId) {
      id
    }
  }
}
    `;

export function useDeduplicationSuggestionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDeduplicationSuggestionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDeduplicationSuggestionsQuery,
    GQTypes.GQDeduplicationSuggestionsQueryVariables
  >({ query: DeduplicationSuggestionsDocument, ...options });
}
export const FootprintConfigurationDocument = gql`
    query FootprintConfiguration($footprintSnapshotId: String!) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintVersion {
      id
      versionName
      userChangelogMd
      publishableUserChangelogMd
      kind
      pinInfo {
        id
        name
        description
      }
      generatedBy {
        id
        displayName
      }
      createdAt
      isGeneratedFromProduct
      footprintInterval
      configuration {
        id
        methodologyConfig {
          id
          spendMethodology
        }
        marketplaceAllocationInstructions {
          edges {
            node {
              id
              amountKwh
              ghgCategoryId
              purchaseLineItem {
                id
                project {
                  id
                  name
                }
              }
            }
          }
        }
        measurementProjectDatasets {
          id
          measurementProject {
            id
            name
            createdAt
          }
          datasetsAndDatasetGroups {
            id
            datasetGroupName
            dataset {
              id
              name
              canonicalDataset {
                id
                kind
              }
            }
          }
        }
        carbonRemoval {
          id
          totalTco2e
        }
        releasesByBat {
          batName
          release {
            id
            displayName
          }
          lifecyclePhase
        }
      }
    }
  }
}
    `;

export function useFootprintConfigurationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintConfigurationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintConfigurationQuery,
    GQTypes.GQFootprintConfigurationQueryVariables
  >({ query: FootprintConfigurationDocument, ...options });
}
export const GetFootprintVersionTimelineEntriesDocument = gql`
    query GetFootprintVersionTimelineEntries($footprintVersionKindFilter: FootprintVersionKind, $footprintId: ID) {
  userHasPermission(permission: ViewFootprintDetail)
  footprintChangelog {
    id
    versions(
      filters: {footprintId: $footprintId, footprintVersionKindFilter: $footprintVersionKindFilter}
    ) {
      edges {
        node {
          ...FootprintVersionForTimeline
        }
      }
    }
  }
  latestFootprintAssemblyRun {
    ...FootprintAssemblyRunForChangelog
  }
}
    ${FootprintVersionForTimelineFragmentDoc}
${FootprintAssemblyRunForChangelogFragmentDoc}`;

export function useGetFootprintVersionTimelineEntriesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintVersionTimelineEntriesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintVersionTimelineEntriesQuery,
    GQTypes.GQGetFootprintVersionTimelineEntriesQueryVariables
  >({ query: GetFootprintVersionTimelineEntriesDocument, ...options });
}
export const UpdateFootprintRunStatusDocument = gql`
    mutation UpdateFootprintRunStatus($input: UpdateFootprintAssemblyRunStatusInput!) {
  updateFootprintAssemblyRunStatus(input: $input) {
    footprintAssemblyRun {
      ...FootprintAssemblyRunForChangelog
    }
  }
}
    ${FootprintAssemblyRunForChangelogFragmentDoc}`;

export function useUpdateFootprintRunStatusMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFootprintRunStatusMutation,
    GQTypes.GQUpdateFootprintRunStatusMutationVariables
  >(UpdateFootprintRunStatusDocument);
}
export const EditFootprintVersionDocument = gql`
    mutation EditFootprintVersion($input: RenameFootprintVersionInput!) {
  renameFootprintVersion(input: $input) {
    footprintVersion {
      id
    }
  }
}
    `;

export function useEditFootprintVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQEditFootprintVersionMutation,
    GQTypes.GQEditFootprintVersionMutationVariables
  >(EditFootprintVersionDocument);
}
export const GetFootprintVersionTimelineDrawerContentsDocument = gql`
    query GetFootprintVersionTimelineDrawerContents {
  latestFootprintAssemblyRun {
    ...FootprintAssemblyRunForChangelog
  }
  activeMeasurementProjects {
    id
  }
}
    ${FootprintAssemblyRunForChangelogFragmentDoc}`;

export function useGetFootprintVersionTimelineDrawerContentsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintVersionTimelineDrawerContentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintVersionTimelineDrawerContentsQuery,
    GQTypes.GQGetFootprintVersionTimelineDrawerContentsQueryVariables
  >({ query: GetFootprintVersionTimelineDrawerContentsDocument, ...options });
}
export const GenerateAutoFootprintDocument = gql`
    mutation GenerateAutoFootprint($input: GenerateAutoFootprintInput!) {
  generateAutoFootprint(input: $input) {
    jobId
    footprintAssemblyRun {
      ...FootprintAssemblyRunForChangelog
    }
  }
}
    ${FootprintAssemblyRunForChangelogFragmentDoc}`;

export function useGenerateAutoFootprintMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateAutoFootprintMutation,
    GQTypes.GQGenerateAutoFootprintMutationVariables
  >(GenerateAutoFootprintDocument);
}
export const GetDetailsForGenerateFootprintDocument = gql`
    query GetDetailsForGenerateFootprint {
  footprintGenerationInfo {
    totalPublishedRecordsForOrg
  }
}
    `;

export function useGetDetailsForGenerateFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDetailsForGenerateFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDetailsForGenerateFootprintQuery,
    GQTypes.GQGetDetailsForGenerateFootprintQueryVariables
  >({ query: GetDetailsForGenerateFootprintDocument, ...options });
}
export const DeduplicationEditorDocument = gql`
    query DeduplicationEditor($footprintSnapshotId: ID) {
  organization {
    id
    footprintExclusionRules(footprintSnapshotId: $footprintSnapshotId) {
      ...FootprintExclusionRuleForEditor
    }
  }
  footprintChangelog {
    id
    latestDraftVersion: versions(
      first: 1
      filters: {footprintVersionKindFilter: Draft}
    ) {
      edges {
        node {
          id
          newDataCoverageInterval
        }
      }
    }
  }
  activeMeasurementProjects {
    id
    coverageInterval
  }
}
    ${FootprintExclusionRuleForEditorFragmentDoc}`;

export function useDeduplicationEditorQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDeduplicationEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDeduplicationEditorQuery,
    GQTypes.GQDeduplicationEditorQueryVariables
  >({ query: DeduplicationEditorDocument, ...options });
}
export const DeduplicationEditorWithDefaultFootprintDocument = gql`
    query DeduplicationEditorWithDefaultFootprint($status: FootprintStatus) {
  organization {
    id
    footprintExclusionRules {
      ...FootprintExclusionRuleForEditor
    }
  }
  defaultFootprintWithFallback(status: $status) {
    ...FootprintFields
  }
  measurementProjectsInterval
}
    ${FootprintExclusionRuleForEditorFragmentDoc}
${FootprintFieldsFragmentDoc}`;

export function useDeduplicationEditorWithDefaultFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDeduplicationEditorWithDefaultFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDeduplicationEditorWithDefaultFootprintQuery,
    GQTypes.GQDeduplicationEditorWithDefaultFootprintQueryVariables
  >({ query: DeduplicationEditorWithDefaultFootprintDocument, ...options });
}
export const UpsertFootprintExclusionRulesForEditorDocument = gql`
    mutation UpsertFootprintExclusionRulesForEditor($input: UpsertFootprintExclusionRulesInput!) {
  upsertFootprintExclusionRules(input: $input) {
    organization {
      id
      footprintExclusionRules {
        ...FootprintExclusionRuleForEditor
      }
    }
  }
}
    ${FootprintExclusionRuleForEditorFragmentDoc}`;

export function useUpsertFootprintExclusionRulesForEditorMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertFootprintExclusionRulesForEditorMutation,
    GQTypes.GQUpsertFootprintExclusionRulesForEditorMutationVariables
  >(UpsertFootprintExclusionRulesForEditorDocument);
}
export const DeduplicationRulesDetailDocument = gql`
    query DeduplicationRulesDetail($footprintSnapshotId: ID) {
  organization {
    id
    footprintExclusionRules(footprintSnapshotId: $footprintSnapshotId) {
      ...FootprintExclusionRuleForEditor
    }
  }
}
    ${FootprintExclusionRuleForEditorFragmentDoc}`;

export function useDeduplicationRulesDetailQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDeduplicationRulesDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDeduplicationRulesDetailQuery,
    GQTypes.GQDeduplicationRulesDetailQueryVariables
  >({ query: DeduplicationRulesDetailDocument, ...options });
}
export const DeduplicationRuleSuggestionsForIndexPageDocument = gql`
    query DeduplicationRuleSuggestionsForIndexPage($footprintSnapshotId: ID!) {
  organization {
    id
    footprintExclusionSuggestions(footprintSnapshotId: $footprintSnapshotId) {
      id
      suggestion {
        __typename
        kind
        ... on FootprintExclusionSuggestionSuggestionConflict {
          beaCode
          beaCodeDescription
          conflictingSources {
            __typename
            source
            ... on FootprintExclusionSuggestionSuggestionConflictSpend {
              financialAccountId
              financialAccountName
              vendorEntity
              tCO2e
            }
            ... on FootprintExclusionSuggestionSuggestionConflictActivity {
              dataset {
                id
                name
              }
              tCO2e
            }
          }
        }
        ... on FootprintExclusionSuggestionSuggestionUnmappedActivity {
          dataset {
            id
            name
          }
          tCO2e
        }
      }
    }
  }
}
    `;

export function useDeduplicationRuleSuggestionsForIndexPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDeduplicationRuleSuggestionsForIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDeduplicationRuleSuggestionsForIndexPageQuery,
    GQTypes.GQDeduplicationRuleSuggestionsForIndexPageQueryVariables
  >({ query: DeduplicationRuleSuggestionsForIndexPageDocument, ...options });
}
export const DismissDeduplicationSuggestionDocument = gql`
    mutation DismissDeduplicationSuggestion($suggestionId: ID!) {
  dismissFootprintExclusionSuggestion(input: {id: $suggestionId}) {
    id
  }
}
    `;

export function useDismissDeduplicationSuggestionMutation() {
  return Urql.useMutation<
    GQTypes.GQDismissDeduplicationSuggestionMutation,
    GQTypes.GQDismissDeduplicationSuggestionMutationVariables
  >(DismissDeduplicationSuggestionDocument);
}
export const ResetDeduplicationSuggestionsDocument = gql`
    mutation ResetDeduplicationSuggestions($footprintSnapshotId: ID!) {
  resetFootprintExclusionSuggestions(
    input: {footprintSnapshotId: $footprintSnapshotId}
  ) {
    footprintSnapshotId
  }
}
    `;

export function useResetDeduplicationSuggestionsMutation() {
  return Urql.useMutation<
    GQTypes.GQResetDeduplicationSuggestionsMutation,
    GQTypes.GQResetDeduplicationSuggestionsMutationVariables
  >(ResetDeduplicationSuggestionsDocument);
}
export const CategoryRulesDetailDocument = gql`
    query CategoryRulesDetail($footprintSnapshotId: ID) {
  organization {
    id
    footprintCategoryRules(footprintSnapshotId: $footprintSnapshotId) {
      ...FootprintCategoryRuleForEditor
    }
  }
}
    ${FootprintCategoryRuleForEditorFragmentDoc}`;

export function useCategoryRulesDetailQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCategoryRulesDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCategoryRulesDetailQuery,
    GQTypes.GQCategoryRulesDetailQueryVariables
  >({ query: CategoryRulesDetailDocument, ...options });
}
export const UpsertFootprintCategoryRulesForEditorDocument = gql`
    mutation UpsertFootprintCategoryRulesForEditor($input: UpsertFootprintCategoryRulesInput!) {
  upsertFootprintCategoryRules(input: $input) {
    organization {
      id
      footprintCategoryRules {
        ...FootprintCategoryRuleForEditor
      }
    }
  }
}
    ${FootprintCategoryRuleForEditorFragmentDoc}`;

export function useUpsertFootprintCategoryRulesForEditorMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertFootprintCategoryRulesForEditorMutation,
    GQTypes.GQUpsertFootprintCategoryRulesForEditorMutationVariables
  >(UpsertFootprintCategoryRulesForEditorDocument);
}
export const SaveCategoryRuleGhgCategoryNoteDocument = gql`
    mutation SaveCategoryRuleGhgCategoryNote($input: SaveCategoryRuleGhgCategoryNoteInput!) {
  saveCategoryRuleGhgCategoryNote(input: $input) {
    note
  }
}
    `;

export function useSaveCategoryRuleGhgCategoryNoteMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveCategoryRuleGhgCategoryNoteMutation,
    GQTypes.GQSaveCategoryRuleGhgCategoryNoteMutationVariables
  >(SaveCategoryRuleGhgCategoryNoteDocument);
}
export const RecategorizationEditorDocument = gql`
    query RecategorizationEditor($footprintSnapshotId: ID) {
  organization {
    id
    footprintCategoryRules(footprintSnapshotId: $footprintSnapshotId) {
      ...FootprintCategoryRuleForEditor
    }
  }
  latestFootprintAssemblyRun {
    ...FootprintAssemblyRunForChangelog
  }
}
    ${FootprintCategoryRuleForEditorFragmentDoc}
${FootprintAssemblyRunForChangelogFragmentDoc}`;

export function useRecategorizationEditorQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQRecategorizationEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRecategorizationEditorQuery,
    GQTypes.GQRecategorizationEditorQueryVariables
  >({ query: RecategorizationEditorDocument, ...options });
}
export const RecategorizationEditorWithDefaultFootprintDocument = gql`
    query RecategorizationEditorWithDefaultFootprint($status: FootprintStatus) {
  organization {
    id
    footprintCategoryRules {
      ...FootprintCategoryRuleForEditor
    }
  }
  defaultFootprintWithFallback(status: $status) {
    ...FootprintFields
  }
  measurementProjectsInterval
}
    ${FootprintCategoryRuleForEditorFragmentDoc}
${FootprintFieldsFragmentDoc}`;

export function useRecategorizationEditorWithDefaultFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQRecategorizationEditorWithDefaultFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRecategorizationEditorWithDefaultFootprintQuery,
    GQTypes.GQRecategorizationEditorWithDefaultFootprintQueryVariables
  >({ query: RecategorizationEditorWithDefaultFootprintDocument, ...options });
}
export const FootprintConfigDrawerDocument = gql`
    query FootprintConfigDrawer($footprintSnapshotId: String!) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintVersion {
      id
      createdAt
      generatedBy {
        id
        displayName
      }
    }
  }
}
    `;

export function useFootprintConfigDrawerQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintConfigDrawerQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintConfigDrawerQuery,
    GQTypes.GQFootprintConfigDrawerQueryVariables
  >({ query: FootprintConfigDrawerDocument, ...options });
}
export const GetDrilldownSavedViewsDocument = gql`
    query GetDrilldownSavedViews {
  drilldownSavedViews {
    ...DrilldownSavedViewFields
  }
}
    ${DrilldownSavedViewFieldsFragmentDoc}`;

export function useGetDrilldownSavedViewsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDrilldownSavedViewsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDrilldownSavedViewsQuery,
    GQTypes.GQGetDrilldownSavedViewsQueryVariables
  >({ query: GetDrilldownSavedViewsDocument, ...options });
}
export const GetFootprintTagsDocument = gql`
    query GetFootprintTags {
  footprintTags {
    id
    tagName
    description
  }
}
    `;

export function useGetFootprintTagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintTagsQuery,
    GQTypes.GQGetFootprintTagsQueryVariables
  >({ query: GetFootprintTagsDocument, ...options });
}
export const GenerateAuditReportDocument = gql`
    mutation GenerateAuditReport($input: GenerateAuditReportInput!) {
  generateAuditReport(input: $input) {
    jobId
  }
}
    `;

export function useGenerateAuditReportMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateAuditReportMutation,
    GQTypes.GQGenerateAuditReportMutationVariables
  >(GenerateAuditReportDocument);
}
export const RegenerateAuditReportDocument = gql`
    mutation RegenerateAuditReport($input: RegenerateAuditReportInput!) {
  regenerateAuditReport(input: $input) {
    jobId
  }
}
    `;

export function useRegenerateAuditReportMutation() {
  return Urql.useMutation<
    GQTypes.GQRegenerateAuditReportMutation,
    GQTypes.GQRegenerateAuditReportMutationVariables
  >(RegenerateAuditReportDocument);
}
export const GetPublicDisclosureDocument = gql`
    query GetPublicDisclosure($id: ID!) {
  publicDisclosure(id: $id) {
    ...PublicDisclosureAllFields
  }
}
    ${PublicDisclosureAllFieldsFragmentDoc}`;

export function useGetPublicDisclosureQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPublicDisclosureQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPublicDisclosureQuery,
    GQTypes.GQGetPublicDisclosureQueryVariables
  >({ query: GetPublicDisclosureDocument, ...options });
}
export const UserUploadsByIdForParsedFilePreviewDocument = gql`
    query UserUploadsByIdForParsedFilePreview($ids: [ID!]!) {
  userUploadsById(ids: $ids) {
    ...UserUploadForDatasetPage
  }
}
    ${UserUploadForDatasetPageFragmentDoc}`;

export function useUserUploadsByIdForParsedFilePreviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadsByIdForParsedFilePreviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadsByIdForParsedFilePreviewQuery,
    GQTypes.GQUserUploadsByIdForParsedFilePreviewQueryVariables
  >({ query: UserUploadsByIdForParsedFilePreviewDocument, ...options });
}
export const BiCalculationDataLineageDocument = gql`
    query BiCalculationDataLineage($footprintSnapshotId: String!, $singleMeasureSelector: BiQuerySingleMeasureSelectorInput!, $fingerprint: String, $emissionsModelEvaluationId: String, $conversionFactorId: String) {
  biCalculationDataLineage(
    footprintSnapshotId: $footprintSnapshotId
    singleMeasureSelector: $singleMeasureSelector
    fingerprint: $fingerprint
    emissionsModelEvaluationId: $emissionsModelEvaluationId
    conversionFactorId: $conversionFactorId
  ) {
    ...SelectedCalculationDataLineage
  }
}
    ${SelectedCalculationDataLineageFragmentDoc}`;

export function useBiCalculationDataLineageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBiCalculationDataLineageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBiCalculationDataLineageQuery,
    GQTypes.GQBiCalculationDataLineageQueryVariables
  >({ query: BiCalculationDataLineageDocument, ...options });
}
export const ActivityDataDataLineageDocument = gql`
    query ActivityDataDataLineage($singleMeasureSelector: BiQuerySingleMeasureSelectorInput!, $queryContext: BiQueryContextInput!) {
  activityDataDataLineage(
    singleMeasureSelector: $singleMeasureSelector
    queryContext: $queryContext
  ) {
    ...SelectedCalculationDataLineage
  }
}
    ${SelectedCalculationDataLineageFragmentDoc}`;

export function useActivityDataDataLineageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDataDataLineageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDataDataLineageQuery,
    GQTypes.GQActivityDataDataLineageQueryVariables
  >({ query: ActivityDataDataLineageDocument, ...options });
}
export const CreateFootprintDocument = gql`
    mutation CreateFootprint($input: CreateFootprintInput!) {
  createFootprint(input: $input) {
    footprint {
      ...FootprintFields
    }
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useCreateFootprintMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintMutation,
    GQTypes.GQCreateFootprintMutationVariables
  >(CreateFootprintDocument);
}
export const RenameFootprintDocument = gql`
    mutation RenameFootprint($input: RenameFootprintInput!) {
  renameFootprint(input: $input) {
    footprint {
      ...FootprintFields
    }
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useRenameFootprintMutation() {
  return Urql.useMutation<
    GQTypes.GQRenameFootprintMutation,
    GQTypes.GQRenameFootprintMutationVariables
  >(RenameFootprintDocument);
}
export const ProductsAndMaterialFootprintDocument = gql`
    query ProductsAndMaterialFootprint($footprintVersion: String) {
  footprintAnalysis(version: $footprintVersion) {
    id
    footprintInterval
    footprintVersion {
      ...FootprintVersionForProducts
    }
    footprintKinds
  }
}
    ${FootprintVersionForProductsFragmentDoc}`;

export function useProductsAndMaterialFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQProductsAndMaterialFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQProductsAndMaterialFootprintQuery,
    GQTypes.GQProductsAndMaterialFootprintQueryVariables
  >({ query: ProductsAndMaterialFootprintDocument, ...options });
}
export const GetFootprintKindsWithProductsOrMaterialsDocument = gql`
    query GetFootprintKindsWithProductsOrMaterials($footprintSnapshotId: String!) {
  footprintKindsWithProductsOrMaterials(footprintSnapshotId: $footprintSnapshotId) {
    footprintKind
  }
}
    `;

export function useGetFootprintKindsWithProductsOrMaterialsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintKindsWithProductsOrMaterialsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintKindsWithProductsOrMaterialsQuery,
    GQTypes.GQGetFootprintKindsWithProductsOrMaterialsQueryVariables
  >({ query: GetFootprintKindsWithProductsOrMaterialsDocument, ...options });
}
export const MaterialsUpdatedTaxonomyDocument = gql`
    query MaterialsUpdatedTaxonomy($footprintSnapshotId: String, $orgMaterial: String, $orgRawMaterial: String, $groupByMonth: Boolean!, $dateInterval: YMInterval, $footprintKind: String) {
  materialsUpdatedTaxonomy(
    footprintSnapshotId: $footprintSnapshotId
    orgMaterial: $orgMaterial
    orgRawMaterial: $orgRawMaterial
    groupByMonth: $groupByMonth
    dateInterval: $dateInterval
    footprintKind: $footprintKind
  ) {
    id
    orgMaterial
    orgRawMaterial
    product {
      id
      product
      totalEmissions
    }
    emissionsPerKg
    inputQuantity
    inputQuantityPercentage
    totalEmissions
    totalEmissionsPercent
    vectors {
      vectorName
      vectorValues
    }
  }
}
    `;

export function useMaterialsUpdatedTaxonomyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMaterialsUpdatedTaxonomyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMaterialsUpdatedTaxonomyQuery,
    GQTypes.GQMaterialsUpdatedTaxonomyQueryVariables
  >({ query: MaterialsUpdatedTaxonomyDocument, ...options });
}
export const ProductsUpdatedTaxonomyDocument = gql`
    query ProductsUpdatedTaxonomy($footprintSnapshotId: String, $product: String, $groupByMonth: Boolean!, $dateInterval: YMInterval, $footprintKind: String) {
  productsUpdatedTaxonomy(
    footprintSnapshotId: $footprintSnapshotId
    product: $product
    groupByMonth: $groupByMonth
    dateInterval: $dateInterval
    footprintKind: $footprintKind
  ) {
    id
    product
    vectors {
      vectorName
      vectorValues
    }
    emissionsPerUnit
    unitsProduced
    unitsProducedPercentage
    totalEmissions
    totalEmissionsPercent
    categoryEmissionsPercent
    relatedMaterials {
      id
      material
    }
  }
}
    `;

export function useProductsUpdatedTaxonomyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQProductsUpdatedTaxonomyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQProductsUpdatedTaxonomyQuery,
    GQTypes.GQProductsUpdatedTaxonomyQueryVariables
  >({ query: ProductsUpdatedTaxonomyDocument, ...options });
}
export const EmissionsSliceMaterialPageDataUpdatedTaxonomyDocument = gql`
    query EmissionsSliceMaterialPageDataUpdatedTaxonomy($footprintVersion: String, $footprintKind: String, $orgMaterial: String!, $orgRawMaterial: String) {
  items: materialsUpdatedTaxonomy(
    footprintSnapshotId: $footprintVersion
    footprintKind: $footprintKind
    orgMaterial: $orgMaterial
    orgRawMaterial: $orgRawMaterial
    groupByMonth: false
  ) {
    ...EmissionsSliceMaterialFootprintRowUpdatedTaxonomy
  }
  footprintAnalysis(version: $footprintVersion) {
    id
    footprintInterval
  }
}
    ${EmissionsSliceMaterialFootprintRowUpdatedTaxonomyFragmentDoc}`;

export function useEmissionsSliceMaterialPageDataUpdatedTaxonomyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsSliceMaterialPageDataUpdatedTaxonomyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsSliceMaterialPageDataUpdatedTaxonomyQuery,
    GQTypes.GQEmissionsSliceMaterialPageDataUpdatedTaxonomyQueryVariables
  >({
    query: EmissionsSliceMaterialPageDataUpdatedTaxonomyDocument,
    ...options,
  });
}
export const EmissionsSliceProductPageDataUpdatedTaxonomyDocument = gql`
    query EmissionsSliceProductPageDataUpdatedTaxonomy($footprintVersion: String, $footprintKind: String, $productId: String!) {
  items: productsUpdatedTaxonomy(
    footprintSnapshotId: $footprintVersion
    footprintKind: $footprintKind
    product: $productId
    groupByMonth: false
  ) {
    ...EmissionsSliceProductFootprintRowUpdatedTaxonomy
  }
  footprintAnalysis(version: $footprintVersion, footprintKind: $footprintKind) {
    id
    footprintInterval
  }
}
    ${EmissionsSliceProductFootprintRowUpdatedTaxonomyFragmentDoc}`;

export function useEmissionsSliceProductPageDataUpdatedTaxonomyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsSliceProductPageDataUpdatedTaxonomyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsSliceProductPageDataUpdatedTaxonomyQuery,
    GQTypes.GQEmissionsSliceProductPageDataUpdatedTaxonomyQueryVariables
  >({
    query: EmissionsSliceProductPageDataUpdatedTaxonomyDocument,
    ...options,
  });
}
export const EvaluatedEmissionsSliceDataDocument = gql`
    query EvaluatedEmissionsSliceData($footprintVersion: String, $footprintKind: String, $orgMaterial: String, $orgRawMaterial: String, $productId: String) {
  evaluatedEmissionsModels(
    footprintVersion: $footprintVersion
    footprintKind: $footprintKind
    materialId: $orgMaterial
    orgRawMaterial: $orgRawMaterial
    productId: $productId
  ) {
    emissionsModelVersions {
      id
      description
      emissionsModelJson
      importedDescendants {
        ...EmissionsModelVersionFieldsForImportedDescendants
      }
    }
  }
}
    ${EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc}`;

export function useEvaluatedEmissionsSliceDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQEvaluatedEmissionsSliceDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEvaluatedEmissionsSliceDataQuery,
    GQTypes.GQEvaluatedEmissionsSliceDataQueryVariables
  >({ query: EvaluatedEmissionsSliceDataDocument, ...options });
}
export const FootprintDocument = gql`
    query Footprint($footprintId: String) {
  footprint(footprintId: $footprintId) {
    id
    name
    defaultFootprintSnapshot {
      id
    }
    latestPublishedFootprintSnapshot {
      id
    }
    latestDraftFootprintSnapshot {
      id
    }
  }
}
    `;

export function useFootprintQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQFootprintQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQFootprintQuery,
    GQTypes.GQFootprintQueryVariables
  >({ query: FootprintDocument, ...options });
}
export const FootprintsDocument = gql`
    query Footprints($after: String, $before: String, $first: Int, $last: Int, $search: String) {
  userHasPermission(permission: ViewFootprintDetail)
  measurementProjectsInterval
  footprints(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FootprintFields
      }
    }
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useFootprintsQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQFootprintsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQFootprintsQuery,
    GQTypes.GQFootprintsQueryVariables
  >({ query: FootprintsDocument, ...options });
}
export const FootprintsForFootprintSelectorDocument = gql`
    query FootprintsForFootprintSelector($after: String, $before: String, $first: Int, $last: Int, $search: String) {
  userHasPermission(permission: ViewFootprintDetail)
  footprints(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FootprintFieldsForFootprintSelector
      }
    }
  }
}
    ${FootprintFieldsForFootprintSelectorFragmentDoc}`;

export function useFootprintsForFootprintSelectorQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintsForFootprintSelectorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintsForFootprintSelectorQuery,
    GQTypes.GQFootprintsForFootprintSelectorQueryVariables
  >({ query: FootprintsForFootprintSelectorDocument, ...options });
}
export const DefaultFootprintWithFallbackDocument = gql`
    query DefaultFootprintWithFallback($status: FootprintStatus) {
  defaultFootprintWithFallback(status: $status) {
    ...FootprintFields
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useDefaultFootprintWithFallbackQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDefaultFootprintWithFallbackQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDefaultFootprintWithFallbackQuery,
    GQTypes.GQDefaultFootprintWithFallbackQueryVariables
  >({ query: DefaultFootprintWithFallbackDocument, ...options });
}
export const DefaultFootprintDocument = gql`
    query DefaultFootprint($status: FootprintStatus) {
  defaultFootprint(status: $status) {
    ...FootprintFields
  }
}
    ${FootprintFieldsFragmentDoc}`;

export function useDefaultFootprintQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDefaultFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDefaultFootprintQuery,
    GQTypes.GQDefaultFootprintQueryVariables
  >({ query: DefaultFootprintDocument, ...options });
}
export const CancelFootprintReviewRequestDocument = gql`
    mutation CancelFootprintReviewRequest($input: DeleteWatershedFootprintReviewRequestInput!) {
  deleteWatershedFootprintReviewRequest(input: $input) {
    footprintVersion {
      id
      watershedFootprintReviewRequest {
        id
      }
    }
    discussion {
      ...DiscussionFields
    }
  }
}
    ${DiscussionFieldsFragmentDoc}`;

export function useCancelFootprintReviewRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQCancelFootprintReviewRequestMutation,
    GQTypes.GQCancelFootprintReviewRequestMutationVariables
  >(CancelFootprintReviewRequestDocument);
}
export const CreateWatershedFootprintReviewRequestDocument = gql`
    mutation CreateWatershedFootprintReviewRequest($footprintVersionId: ID!) {
  createWatershedFootprintReviewRequest(
    input: {footprintVersionId: $footprintVersionId}
  ) {
    footprintVersion {
      id
      watershedFootprintReviewRequest {
        ...WatershedFootprintReviewRequestForReviewPage
      }
    }
    discussionThreads {
      ...CommentDiscussionsForAnchorFields
    }
  }
}
    ${WatershedFootprintReviewRequestForReviewPageFragmentDoc}
${CommentDiscussionsForAnchorFieldsFragmentDoc}`;

export function useCreateWatershedFootprintReviewRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateWatershedFootprintReviewRequestMutation,
    GQTypes.GQCreateWatershedFootprintReviewRequestMutationVariables
  >(CreateWatershedFootprintReviewRequestDocument);
}
export const UpdateFootprintReviewRequestStatusDocument = gql`
    mutation UpdateFootprintReviewRequestStatus($input: UpdateWatershedFootprintReviewRequestStatusInput!) {
  updateWatershedFootprintReviewRequestStatus(input: $input) {
    watershedFootprintReviewRequest {
      id
      status
      discussion {
        ...DiscussionFields
      }
    }
  }
}
    ${DiscussionFieldsFragmentDoc}`;

export function useUpdateFootprintReviewRequestStatusMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFootprintReviewRequestStatusMutation,
    GQTypes.GQUpdateFootprintReviewRequestStatusMutationVariables
  >(UpdateFootprintReviewRequestStatusDocument);
}
export const RequestSupplyChainHelpDocument = gql`
    mutation RequestSupplyChainHelp($input: RequestHelpInput!) {
  requestSupplyChainHelp(input: $input) {
    status
  }
}
    `;

export function useRequestSupplyChainHelpMutation() {
  return Urql.useMutation<
    GQTypes.GQRequestSupplyChainHelpMutation,
    GQTypes.GQRequestSupplyChainHelpMutationVariables
  >(RequestSupplyChainHelpDocument);
}
export const EngagementTaskQuestionsForBaseConfigIdDocument = gql`
    query EngagementTaskQuestionsForBaseConfigId($baseConfigId: ID!, $excludeComplex: Boolean) {
  engagementTaskQuestionsForBaseConfigId(
    baseConfigId: $baseConfigId
    excludeComplex: $excludeComplex
  ) {
    questionKey
    description
    engagementTaskConfigId
    engagementTaskConfigName
    taskConfigsWithQuestion
    componentId
  }
}
    `;

export function useEngagementTaskQuestionsForBaseConfigIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskQuestionsForBaseConfigIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskQuestionsForBaseConfigIdQuery,
    GQTypes.GQEngagementTaskQuestionsForBaseConfigIdQueryVariables
  >({ query: EngagementTaskQuestionsForBaseConfigIdDocument, ...options });
}
export const GetSupplierAttachmentsDocument = gql`
    query GetSupplierAttachments($companyId: ID!) {
  supplierAttachments(companyId: $companyId) {
    ...SupplierAttachmentFields
  }
}
    ${SupplierAttachmentFieldsFragmentDoc}`;

export function useGetSupplierAttachmentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierAttachmentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierAttachmentsQuery,
    GQTypes.GQGetSupplierAttachmentsQueryVariables
  >({ query: GetSupplierAttachmentsDocument, ...options });
}
export const GetSupplierAttachmentsByCrossOrgIdsDocument = gql`
    query GetSupplierAttachmentsByCrossOrgIds($crossOrgModelIds: [String!]!) {
  supplierAttachmentsByCrossOrgIds(crossOrgModelIds: $crossOrgModelIds) {
    ...SupplierAttachmentFields
  }
}
    ${SupplierAttachmentFieldsFragmentDoc}`;

export function useGetSupplierAttachmentsByCrossOrgIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierAttachmentsByCrossOrgIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierAttachmentsByCrossOrgIdsQuery,
    GQTypes.GQGetSupplierAttachmentsByCrossOrgIdsQueryVariables
  >({ query: GetSupplierAttachmentsByCrossOrgIdsDocument, ...options });
}
export const CreateSupplierAttachmentsDocument = gql`
    mutation CreateSupplierAttachments($input: CreateSupplierAttachmentsInput!) {
  createSupplierAttachments(input: $input) {
    fileUrls {
      url
      attachment {
        ...SupplierAttachmentFields
      }
    }
  }
}
    ${SupplierAttachmentFieldsFragmentDoc}`;

export function useCreateSupplierAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierAttachmentsMutation,
    GQTypes.GQCreateSupplierAttachmentsMutationVariables
  >(CreateSupplierAttachmentsDocument);
}
export const UpdateSupplierAttachmentDocument = gql`
    mutation UpdateSupplierAttachment($input: UpdateSupplierAttachmentInput!) {
  updateSupplierAttachment(input: $input) {
    attachment {
      ...SupplierAttachmentFields
    }
  }
}
    ${SupplierAttachmentFieldsFragmentDoc}`;

export function useUpdateSupplierAttachmentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierAttachmentMutation,
    GQTypes.GQUpdateSupplierAttachmentMutationVariables
  >(UpdateSupplierAttachmentDocument);
}
export const DeleteSupplierAttachmentDocument = gql`
    mutation DeleteSupplierAttachment($input: DeleteSupplierAttachmentInput!) {
  deleteSupplierAttachment(input: $input) {
    numberDeleted
  }
}
    `;

export function useDeleteSupplierAttachmentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierAttachmentMutation,
    GQTypes.GQDeleteSupplierAttachmentMutationVariables
  >(DeleteSupplierAttachmentDocument);
}
export const GetFileDownloadUrlDocument = gql`
    query GetFileDownloadUrl($fileMetadataId: ID!) {
  supplierAttachmentDownloadUrl(fileMetadataId: $fileMetadataId)
}
    `;

export function useGetFileDownloadUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFileDownloadUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFileDownloadUrlQuery,
    GQTypes.GQGetFileDownloadUrlQueryVariables
  >({ query: GetFileDownloadUrlDocument, ...options });
}
export const GetUsersWithSupplierPermissionDocument = gql`
    query GetUsersWithSupplierPermission($companyId: ID!) {
  usersWithSupplierPermission(companyId: $companyId) {
    id
    name
    displayName
    email
  }
}
    `;

export function useGetUsersWithSupplierPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUsersWithSupplierPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUsersWithSupplierPermissionQuery,
    GQTypes.GQGetUsersWithSupplierPermissionQueryVariables
  >({ query: GetUsersWithSupplierPermissionDocument, ...options });
}
export const GetDisclosureEmissionsFactorsDocument = gql`
    query GetDisclosureEmissionsFactors($publicDisclosureIds: [ID!]!, $privateDisclosureIds: [ID!]!) {
  disclosureEmissionsFactors(
    publicDisclosureIds: $publicDisclosureIds
    privateDisclosureIds: $privateDisclosureIds
  ) {
    publicDisclosureId
    privateDisclosureId
    emissionsFactorRow {
      id
      rowFields
      citationName
      citationNotes
      citationUrl
      citationSlug
      isCustomerProvided
    }
  }
}
    `;

export function useGetDisclosureEmissionsFactorsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDisclosureEmissionsFactorsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDisclosureEmissionsFactorsQuery,
    GQTypes.GQGetDisclosureEmissionsFactorsQueryVariables
  >({ query: GetDisclosureEmissionsFactorsDocument, ...options });
}
export const SupplierEmissionsFactorsTableRowsDocument = gql`
    query SupplierEmissionsFactorsTableRows($companyId: String!) {
  supplierEmissionsFactorsTableRows(companyId: $companyId) {
    referenceDataRevisionRows {
      id
      rowFields
      citationName
      citationNotes
      citationUrl
      citationSlug
      isCustomerProvided
    }
  }
}
    `;

export function useSupplierEmissionsFactorsTableRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierEmissionsFactorsTableRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierEmissionsFactorsTableRowsQuery,
    GQTypes.GQSupplierEmissionsFactorsTableRowsQueryVariables
  >({ query: SupplierEmissionsFactorsTableRowsDocument, ...options });
}
export const SupplierEmissionsFactorsTableMetadataDocument = gql`
    query SupplierEmissionsFactorsTableMetadata {
  supplierEmissionsFactorsTableMetadata {
    displayColumns {
      parquetColumnName
      displayName
      type
      description
    }
    quantityColumnName
  }
}
    `;

export function useSupplierEmissionsFactorsTableMetadataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierEmissionsFactorsTableMetadataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierEmissionsFactorsTableMetadataQuery,
    GQTypes.GQSupplierEmissionsFactorsTableMetadataQueryVariables
  >({ query: SupplierEmissionsFactorsTableMetadataDocument, ...options });
}
export const SupplierDataExportDocument = gql`
    mutation supplierDataExport($input: SupplierDataExportInput!) {
  supplierDataExport(input: $input) {
    workflowId
  }
}
    `;

export function useSupplierDataExportMutation() {
  return Urql.useMutation<
    GQTypes.GQSupplierDataExportMutation,
    GQTypes.GQSupplierDataExportMutationVariables
  >(SupplierDataExportDocument);
}
export const SupplierFilterOptionsDocument = gql`
    query SupplierFilterOptions($dimension: String!, $nestedDimensions: [String!], $footprintSnapshotId: String) {
  supplierFilterOptions(
    dimension: $dimension
    nestedDimensions: $nestedDimensions
    footprintSnapshotId: $footprintSnapshotId
  ) {
    rawValue
    formattedValue
  }
}
    `;

export function useSupplierFilterOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierFilterOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierFilterOptionsQuery,
    GQTypes.GQSupplierFilterOptionsQueryVariables
  >({ query: SupplierFilterOptionsDocument, ...options });
}
export const CreateSupplierChartDocument = gql`
    mutation CreateSupplierChart($input: CreateSupplierChartInput!) {
  createSupplierChart(input: $input) {
    views {
      id
      reductionPlan {
        ...PlanWithForecastFields
      }
      charts: chartsV2 {
        ...SupplierChartConfigFields
      }
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}
${SupplierChartConfigFieldsFragmentDoc}`;

export function useCreateSupplierChartMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierChartMutation,
    GQTypes.GQCreateSupplierChartMutationVariables
  >(CreateSupplierChartDocument);
}
export const UpdateSupplierChartDocument = gql`
    mutation UpdateSupplierChart($input: UpdateSupplierChartInput!) {
  updateSupplierChart(input: $input) {
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFields
      }
    }
  }
}
    ${SupplierChartConfigFieldsFragmentDoc}`;

export function useUpdateSupplierChartMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierChartMutation,
    GQTypes.GQUpdateSupplierChartMutationVariables
  >(UpdateSupplierChartDocument);
}
export const GetCompanyTargetDisclosuresDocument = gql`
    query GetCompanyTargetDisclosures($companyId: ID!, $opts: DisclosureInput) {
  companyDisclosures(companyId: $companyId, opts: $opts) {
    ...DisclosureForTargetsDialog
  }
}
    ${DisclosureForTargetsDialogFragmentDoc}`;

export function useGetCompanyTargetDisclosuresQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyTargetDisclosuresQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyTargetDisclosuresQuery,
    GQTypes.GQGetCompanyTargetDisclosuresQueryVariables
  >({ query: GetCompanyTargetDisclosuresDocument, ...options });
}
export const CreatePrivateDisclosureDocument = gql`
    mutation CreatePrivateDisclosure($input: CreatePrivateDisclosureInput!) {
  createPrivateDisclosure(input: $input) {
    disclosure {
      ...DisclosureForTargetsDialog
    }
  }
}
    ${DisclosureForTargetsDialogFragmentDoc}`;

export function useCreatePrivateDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePrivateDisclosureMutation,
    GQTypes.GQCreatePrivateDisclosureMutationVariables
  >(CreatePrivateDisclosureDocument);
}
export const UpdatePrivateDisclosureDocument = gql`
    mutation UpdatePrivateDisclosure($input: UpdatePrivateDisclosureInput!) {
  updatePrivateDisclosure(input: $input) {
    disclosure {
      ...DisclosureForTargetsDialog
    }
  }
}
    ${DisclosureForTargetsDialogFragmentDoc}`;

export function useUpdatePrivateDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePrivateDisclosureMutation,
    GQTypes.GQUpdatePrivateDisclosureMutationVariables
  >(UpdatePrivateDisclosureDocument);
}
export const GetCompanyPrivateDisclosuresDocument = gql`
    query GetCompanyPrivateDisclosures($companyId: ID!, $orgId: ID!) {
  companyDisclosures(
    companyId: $companyId
    opts: {privateDisclosureFilters: {orgId: $orgId}, historicalYearFilters: {}, targetFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;

export function useGetCompanyPrivateDisclosuresQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyPrivateDisclosuresQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyPrivateDisclosuresQuery,
    GQTypes.GQGetCompanyPrivateDisclosuresQueryVariables
  >({ query: GetCompanyPrivateDisclosuresDocument, ...options });
}
export const GetOrgEngagementTasksForOverviewDocument = gql`
    query GetOrgEngagementTasksForOverview($rootOnly: Boolean, $ignoreLearningTasks: Boolean) {
  engagementTasks(rootOnly: $rootOnly, ignoreLearningTasks: $ignoreLearningTasks) {
    edges {
      node {
        ...CompanyEngagementTaskFieldsForOverview
      }
    }
  }
}
    ${CompanyEngagementTaskFieldsForOverviewFragmentDoc}`;

export function useGetOrgEngagementTasksForOverviewQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrgEngagementTasksForOverviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrgEngagementTasksForOverviewQuery,
    GQTypes.GQGetOrgEngagementTasksForOverviewQueryVariables
  >({ query: GetOrgEngagementTasksForOverviewDocument, ...options });
}
export const GetCompanyAndOrgEngagementTasksForOverviewDocument = gql`
    query GetCompanyAndOrgEngagementTasksForOverview($companyId: ID!, $rootOnly: Boolean) {
  engagementTasksForCompanyAndOrgV1(companyId: $companyId, rootOnly: $rootOnly) {
    ...CompanyEngagementTaskFieldsForOverview
  }
}
    ${CompanyEngagementTaskFieldsForOverviewFragmentDoc}`;

export function useGetCompanyAndOrgEngagementTasksForOverviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyAndOrgEngagementTasksForOverviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyAndOrgEngagementTasksForOverviewQuery,
    GQTypes.GQGetCompanyAndOrgEngagementTasksForOverviewQueryVariables
  >({ query: GetCompanyAndOrgEngagementTasksForOverviewDocument, ...options });
}
export const GetCompanyAndOrgEngagementTasksForDetailsDocument = gql`
    query GetCompanyAndOrgEngagementTasksForDetails($companyId: ID!, $rootOnly: Boolean) {
  engagementTasksForCompanyAndOrgV1(companyId: $companyId, rootOnly: $rootOnly) {
    ...EngagementTaskFields
  }
}
    ${EngagementTaskFieldsFragmentDoc}`;

export function useGetCompanyAndOrgEngagementTasksForDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyAndOrgEngagementTasksForDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyAndOrgEngagementTasksForDetailsQuery,
    GQTypes.GQGetCompanyAndOrgEngagementTasksForDetailsQueryVariables
  >({ query: GetCompanyAndOrgEngagementTasksForDetailsDocument, ...options });
}
export const GetEngagementTasksAssignedTypesDocument = gql`
    query GetEngagementTasksAssignedTypes {
  userHasPermission(permission: ManageDisclosures, allowAnyObject: true)
  engagementTasksAssignedToOrg {
    ...EngagementTaskTypeAndStatusFields
  }
}
    ${EngagementTaskTypeAndStatusFieldsFragmentDoc}`;

export function useGetEngagementTasksAssignedTypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTasksAssignedTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTasksAssignedTypesQuery,
    GQTypes.GQGetEngagementTasksAssignedTypesQueryVariables
  >({ query: GetEngagementTasksAssignedTypesDocument, ...options });
}
export const GetEngagementTasksPageDataDocument = gql`
    query GetEngagementTasksPageData($companyId: ID!) {
  companyForDashboard(id: $companyId) {
    ...CompanyFieldsForEngagementTasks
  }
  engagementTasksAssignedToOrg {
    ...EngagementTaskContents
  }
  organization {
    id
    company {
      ...CompanyFieldsForEngagementTasks
    }
    users {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
  }
  companyPortalSettings(rootCompanyId: $companyId) {
    ...CompanyPortalSettingsFields
  }
}
    ${CompanyFieldsForEngagementTasksFragmentDoc}
${EngagementTaskContentsFragmentDoc}
${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useGetEngagementTasksPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTasksPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTasksPageDataQuery,
    GQTypes.GQGetEngagementTasksPageDataQueryVariables
  >({ query: GetEngagementTasksPageDataDocument, ...options });
}
export const UpsertNonFootprintSupplierDocument = gql`
    mutation UpsertNonFootprintSupplier($version: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $input: UpsertNonFootprintSupplierInput!) {
  upsertNonFootprintSupplier(
    version: $version
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    input: $input
  ) {
    supplier {
      ...SupplierFields
    }
  }
}
    ${SupplierFieldsFragmentDoc}`;

export function useUpsertNonFootprintSupplierMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertNonFootprintSupplierMutation,
    GQTypes.GQUpsertNonFootprintSupplierMutationVariables
  >(UpsertNonFootprintSupplierDocument);
}
export const DeleteNonFootprintSupplierDocument = gql`
    mutation DeleteNonFootprintSupplier($input: DeleteNonFootprintSupplierInput!, $supplierId: String!) {
  deleteNonFootprintSupplier(input: $input, supplierId: $supplierId) {
    success
  }
}
    `;

export function useDeleteNonFootprintSupplierMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteNonFootprintSupplierMutation,
    GQTypes.GQDeleteNonFootprintSupplierMutationVariables
  >(DeleteNonFootprintSupplierDocument);
}
export const GetEngagementNotificationSettingsForUserDocument = gql`
    query GetEngagementNotificationSettingsForUser($side: EngagementNotificationSide!) {
  activeOrganization {
    id
    user {
      id
      engagementNotificationSettings(side: $side) {
        ...EngagementNotificationSettingFields
      }
    }
  }
}
    ${EngagementNotificationSettingFieldsFragmentDoc}`;

export function useGetEngagementNotificationSettingsForUserQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementNotificationSettingsForUserQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementNotificationSettingsForUserQuery,
    GQTypes.GQGetEngagementNotificationSettingsForUserQueryVariables
  >({ query: GetEngagementNotificationSettingsForUserDocument, ...options });
}
export const SetEngagementNotificationSettingsForUserDocument = gql`
    mutation setEngagementNotificationSettingsForUser($input: SetEngagementNotificationSettingsForUserInput!, $side: EngagementNotificationSide!) {
  setEngagementNotificationSettingsForUser(input: $input) {
    engagementNotificationSettings(side: $side) {
      ...EngagementNotificationSettingFields
    }
  }
}
    ${EngagementNotificationSettingFieldsFragmentDoc}`;

export function useSetEngagementNotificationSettingsForUserMutation() {
  return Urql.useMutation<
    GQTypes.GQSetEngagementNotificationSettingsForUserMutation,
    GQTypes.GQSetEngagementNotificationSettingsForUserMutationVariables
  >(SetEngagementNotificationSettingsForUserDocument);
}
export const GetSupplierActivityTagsDocument = gql`
    query GetSupplierActivityTags($footprintInterval: YMInterval!, $footprintVersion: String, $footprintSnapshotId: String) {
  supplierActivityTags(
    footprintInterval: $footprintInterval
    footprintVersion: $footprintVersion
    footprintSnapshotId: $footprintSnapshotId
  ) {
    tags {
      key
      value
    }
  }
}
    `;

export function useGetSupplierActivityTagsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierActivityTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierActivityTagsQuery,
    GQTypes.GQGetSupplierActivityTagsQueryVariables
  >({ query: GetSupplierActivityTagsDocument, ...options });
}
export const CreateEngagementCohortDocument = gql`
    mutation CreateEngagementCohort($input: CreateEngagementCohortInput!) {
  createEngagementCohort(input: $input) {
    ...EngagementCohortFields
  }
}
    ${EngagementCohortFieldsFragmentDoc}`;

export function useCreateEngagementCohortMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEngagementCohortMutation,
    GQTypes.GQCreateEngagementCohortMutationVariables
  >(CreateEngagementCohortDocument);
}
export const DeleteCompanyEngagementCohortDocument = gql`
    mutation DeleteCompanyEngagementCohort($input: DeleteCompanyEngagementCohortInput!) {
  deleteCompanyEngagementCohort(input: $input) {
    affectedCompanyIds
  }
}
    `;

export function useDeleteCompanyEngagementCohortMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCompanyEngagementCohortMutation,
    GQTypes.GQDeleteCompanyEngagementCohortMutationVariables
  >(DeleteCompanyEngagementCohortDocument);
}
export const SetEngagementCohortAndMembersDocument = gql`
    mutation SetEngagementCohortAndMembers($input: SetEngagementCohortAndMembersInput!) {
  setEngagementCohortAndMembers(input: $input) {
    oldCompanyIds
    engagementCohortWithCompanyIds {
      ...EngagementCohortWithCompanyIdsFields
    }
  }
}
    ${EngagementCohortWithCompanyIdsFieldsFragmentDoc}`;

export function useSetEngagementCohortAndMembersMutation() {
  return Urql.useMutation<
    GQTypes.GQSetEngagementCohortAndMembersMutation,
    GQTypes.GQSetEngagementCohortAndMembersMutationVariables
  >(SetEngagementCohortAndMembersDocument);
}
export const PostMeasurementVendorMatchingTableRowsDocument = gql`
    query PostMeasurementVendorMatchingTableRows($search: String, $sortBy: SupplierMatchingSortOption, $version: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $after: String, $before: String, $first: Int, $last: Int) {
  postMeasurementVendorMatchingTableRows(
    search: $search
    sortBy: $sortBy
    version: $version
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    ...VendorMatchingTableRowsPayloadFields
  }
}
    ${VendorMatchingTableRowsPayloadFieldsFragmentDoc}`;

export function usePostMeasurementVendorMatchingTableRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPostMeasurementVendorMatchingTableRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPostMeasurementVendorMatchingTableRowsQuery,
    GQTypes.GQPostMeasurementVendorMatchingTableRowsQueryVariables
  >({ query: PostMeasurementVendorMatchingTableRowsDocument, ...options });
}
export const PostMeasurementSupplierMappingTableRowsDocument = gql`
    query PostMeasurementSupplierMappingTableRows($search: String, $sortModel: [DataGridSortItemInput!]!, $filters: [BiQueryFilterInput!]!, $version: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $after: String, $before: String, $first: Int, $last: Int) {
  postMeasurementSupplierMappingTableRows(
    search: $search
    sortModel: $sortModel
    filters: $filters
    version: $version
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    ...SupplierMappingTableRowsPayloadFields
  }
}
    ${SupplierMappingTableRowsPayloadFieldsFragmentDoc}`;

export function usePostMeasurementSupplierMappingTableRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPostMeasurementSupplierMappingTableRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPostMeasurementSupplierMappingTableRowsQuery,
    GQTypes.GQPostMeasurementSupplierMappingTableRowsQueryVariables
  >({ query: PostMeasurementSupplierMappingTableRowsDocument, ...options });
}
export const ValueMappingDocument = gql`
    query ValueMapping($valueMappingConfigId: String!) {
  valueMapping(valueMappingConfigId: $valueMappingConfigId) {
    ...ValueMappingPageValueMapping
  }
}
    ${ValueMappingPageValueMappingFragmentDoc}`;

export function useValueMappingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingQuery,
    GQTypes.GQValueMappingQueryVariables
  >({ query: ValueMappingDocument, ...options });
}
export const DeleteSupplierScoreCriteriaDocument = gql`
    mutation DeleteSupplierScoreCriteria($input: DeleteSupplierScoreCriteriaInput!) {
  deleteSupplierScoreCriteria(input: $input) {
    supplierScorecard {
      ...SupplierScorecardFields
    }
  }
}
    ${SupplierScorecardFieldsFragmentDoc}`;

export function useDeleteSupplierScoreCriteriaMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierScoreCriteriaMutation,
    GQTypes.GQDeleteSupplierScoreCriteriaMutationVariables
  >(DeleteSupplierScoreCriteriaDocument);
}
export const CreateSupplierScoreCriteriaDocument = gql`
    mutation CreateSupplierScoreCriteria($input: CreateSupplierScoreCriteriaInput!) {
  createSupplierScoreCriteria(input: $input) {
    supplierScoreCriteria {
      ...SupplierScoreCriteriaFields
    }
  }
}
    ${SupplierScoreCriteriaFieldsFragmentDoc}`;

export function useCreateSupplierScoreCriteriaMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierScoreCriteriaMutation,
    GQTypes.GQCreateSupplierScoreCriteriaMutationVariables
  >(CreateSupplierScoreCriteriaDocument);
}
export const UpdateSupplierScoreCriteriaDocument = gql`
    mutation UpdateSupplierScoreCriteria($input: UpdateSupplierScoreCriteriaInput!) {
  updateSupplierScoreCriteria(input: $input) {
    supplierScoreCriteria {
      ...SupplierScoreCriteriaFields
    }
  }
}
    ${SupplierScoreCriteriaFieldsFragmentDoc}`;

export function useUpdateSupplierScoreCriteriaMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierScoreCriteriaMutation,
    GQTypes.GQUpdateSupplierScoreCriteriaMutationVariables
  >(UpdateSupplierScoreCriteriaDocument);
}
export const EngagementTaskConfigsForEditingDocument = gql`
    query EngagementTaskConfigsForEditing($orgId: ID!, $includeUnpublishedGlobal: Boolean, $includeUnpublishedOrgSpecific: Boolean, $configGroup: EngagementTaskConfigGroup) {
  engagementTaskConfigs(
    orgId: $orgId
    includeUnpublishedGlobal: $includeUnpublishedGlobal
    includeUnpublishedOrgSpecific: $includeUnpublishedOrgSpecific
    configGroup: $configGroup
  ) {
    ...EngagementTaskConfigForEditing
  }
}
    ${EngagementTaskConfigForEditingFragmentDoc}`;

export function useEngagementTaskConfigsForEditingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskConfigsForEditingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskConfigsForEditingQuery,
    GQTypes.GQEngagementTaskConfigsForEditingQueryVariables
  >({ query: EngagementTaskConfigsForEditingDocument, ...options });
}
export const DeleteEngagementTaskConfigDocument = gql`
    mutation DeleteEngagementTaskConfig($input: IdInput!) {
  deleteEngagementTaskConfig(input: $input) {
    message
    success
  }
}
    `;

export function useDeleteEngagementTaskConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEngagementTaskConfigMutation,
    GQTypes.GQDeleteEngagementTaskConfigMutationVariables
  >(DeleteEngagementTaskConfigDocument);
}
export const SetPublishEngagementTaskConfigDocument = gql`
    mutation SetPublishEngagementTaskConfig($input: SetPublishEngagementTaskConfigInput!) {
  setPublishEngagementTaskConfig(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigForEditing
    }
  }
}
    ${EngagementTaskConfigForEditingFragmentDoc}`;

export function useSetPublishEngagementTaskConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQSetPublishEngagementTaskConfigMutation,
    GQTypes.GQSetPublishEngagementTaskConfigMutationVariables
  >(SetPublishEngagementTaskConfigDocument);
}
export const SetArchiveEngagementTaskConfigDocument = gql`
    mutation SetArchiveEngagementTaskConfig($input: SetArchiveEngagementTaskConfigInput!) {
  setArchiveEngagementTaskConfig(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigForEditing
    }
  }
}
    ${EngagementTaskConfigForEditingFragmentDoc}`;

export function useSetArchiveEngagementTaskConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQSetArchiveEngagementTaskConfigMutation,
    GQTypes.GQSetArchiveEngagementTaskConfigMutationVariables
  >(SetArchiveEngagementTaskConfigDocument);
}
export const SupplierSurveyResponseExportDocument = gql`
    mutation supplierSurveyResponseExport($input: SupplierSurveyResponseExportInput!) {
  supplierSurveyResponseExport(input: $input) {
    workflowId
  }
}
    `;

export function useSupplierSurveyResponseExportMutation() {
  return Urql.useMutation<
    GQTypes.GQSupplierSurveyResponseExportMutation,
    GQTypes.GQSupplierSurveyResponseExportMutationVariables
  >(SupplierSurveyResponseExportDocument);
}
export const CreateSupplierSavedViewDocument = gql`
    mutation CreateSupplierSavedView($input: CreateSupplierSavedViewInput!) {
  createSupplierSavedView(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useCreateSupplierSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierSavedViewMutation,
    GQTypes.GQCreateSupplierSavedViewMutationVariables
  >(CreateSupplierSavedViewDocument);
}
export const UpdateSupplierSavedViewDocument = gql`
    mutation UpdateSupplierSavedView($input: UpdateSupplierSavedViewInput!) {
  updateSupplierSavedView(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierSavedViewMutation,
    GQTypes.GQUpdateSupplierSavedViewMutationVariables
  >(UpdateSupplierSavedViewDocument);
}
export const DeleteSupplierSavedViewDocument = gql`
    mutation DeleteSupplierSavedView($input: DeleteSupplierSavedViewInput!) {
  deleteSupplierSavedView(input: $input) {
    id
  }
}
    `;

export function useDeleteSupplierSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierSavedViewMutation,
    GQTypes.GQDeleteSupplierSavedViewMutationVariables
  >(DeleteSupplierSavedViewDocument);
}
export const GetSupplierColumnsForCustomFieldDocument = gql`
    query GetSupplierColumnsForCustomField {
  supplierTableColumnsDashboard {
    ...SelfServeSupplierColumnFields
  }
}
    ${SelfServeSupplierColumnFieldsFragmentDoc}`;

export function useGetSupplierColumnsForCustomFieldQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierColumnsForCustomFieldQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierColumnsForCustomFieldQuery,
    GQTypes.GQGetSupplierColumnsForCustomFieldQueryVariables
  >({ query: GetSupplierColumnsForCustomFieldDocument, ...options });
}
export const GetEngagementTaskQuestionsDocument = gql`
    query GetEngagementTaskQuestions {
  engagementTaskQuestions {
    id
    questionKey
    engagementTaskConfigId
    componentId
  }
}
    `;

export function useGetEngagementTaskQuestionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskQuestionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskQuestionsQuery,
    GQTypes.GQGetEngagementTaskQuestionsQueryVariables
  >({ query: GetEngagementTaskQuestionsDocument, ...options });
}
export const DeleteSupplierChartDocument = gql`
    mutation DeleteSupplierChart($input: DeleteSupplierChartInput!) {
  deleteSupplierChart(input: $input) {
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFields
      }
    }
  }
}
    ${SupplierChartConfigFieldsFragmentDoc}`;

export function useDeleteSupplierChartMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierChartMutation,
    GQTypes.GQDeleteSupplierChartMutationVariables
  >(DeleteSupplierChartDocument);
}
export const EngagementTaskBatchForBulkDeployStatusDocument = gql`
    query EngagementTaskBatchForBulkDeployStatus($engagementTaskBatchId: ID!) {
  engagementTaskBatch(engagementTaskBatchId: $engagementTaskBatchId) {
    ...EngagementTaskBatchFieldsForBulkDeployStatus
  }
}
    ${EngagementTaskBatchFieldsForBulkDeployStatusFragmentDoc}`;

export function useEngagementTaskBatchForBulkDeployStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskBatchForBulkDeployStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskBatchForBulkDeployStatusQuery,
    GQTypes.GQEngagementTaskBatchForBulkDeployStatusQueryVariables
  >({ query: EngagementTaskBatchForBulkDeployStatusDocument, ...options });
}
export const EngagementTaskStatusesDocument = gql`
    query EngagementTaskStatuses {
  engagementTasks {
    edges {
      node {
        companyId
        status
        engagementTaskBatchId
      }
    }
  }
}
    `;

export function useEngagementTaskStatusesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskStatusesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskStatusesQuery,
    GQTypes.GQEngagementTaskStatusesQueryVariables
  >({ query: EngagementTaskStatusesDocument, ...options });
}
export const GetOrSendEmailsDocument = gql`
    mutation GetOrSendEmails($input: GetOrSendEmailsInput!) {
  getOrSendEmails(input: $input) {
    defaultSubject
    defaultBlurb
    sampleEmail {
      ...EmailContentsFields
    }
  }
}
    ${EmailContentsFieldsFragmentDoc}`;

export function useGetOrSendEmailsMutation() {
  return Urql.useMutation<
    GQTypes.GQGetOrSendEmailsMutation,
    GQTypes.GQGetOrSendEmailsMutationVariables
  >(GetOrSendEmailsDocument);
}
export const NotifyEngagementTaskCreationDocument = gql`
    mutation NotifyEngagementTaskCreation($input: NotifyEngagementTaskCreationInput!) {
  notifyEngagementTaskCreation(input: $input) {
    engagementTaskBatch {
      id
      deployStatuses {
        id
        latestEmailSent {
          id
          users {
            id
            latestEmailStatus
          }
        }
      }
    }
  }
}
    `;

export function useNotifyEngagementTaskCreationMutation() {
  return Urql.useMutation<
    GQTypes.GQNotifyEngagementTaskCreationMutation,
    GQTypes.GQNotifyEngagementTaskCreationMutationVariables
  >(NotifyEngagementTaskCreationDocument);
}
export const BulkDeployToSuppliersDocument = gql`
    mutation BulkDeployToSuppliers($input: BulkStartCompanySurveyEngagementInput!) {
  bulkStartCompanySurveyEngagement(input: $input) {
    engagementTaskBatchIds
  }
}
    `;

export function useBulkDeployToSuppliersMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkDeployToSuppliersMutation,
    GQTypes.GQBulkDeployToSuppliersMutationVariables
  >(BulkDeployToSuppliersDocument);
}
export const SetEngagementCohortsForCompanyDocument = gql`
    mutation SetEngagementCohortsForCompany($input: SetEngagementCohortsForCompanyInput!) {
  setEngagementCohortsForCompany(input: $input) {
    cohortIds
    companyId
  }
}
    `;

export function useSetEngagementCohortsForCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQSetEngagementCohortsForCompanyMutation,
    GQTypes.GQSetEngagementCohortsForCompanyMutationVariables
  >(SetEngagementCohortsForCompanyDocument);
}
export const CreateEngagementCohortsForCompanyDocument = gql`
    mutation CreateEngagementCohortsForCompany($input: CreateEngagementCohortsForCompanyInput!) {
  createEngagementCohortsForCompany(input: $input) {
    ...EngagementCohortFields
  }
}
    ${EngagementCohortFieldsFragmentDoc}`;

export function useCreateEngagementCohortsForCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEngagementCohortsForCompanyMutation,
    GQTypes.GQCreateEngagementCohortsForCompanyMutationVariables
  >(CreateEngagementCohortsForCompanyDocument);
}
export const UpdateEngagementTaskConfigDocument = gql`
    mutation UpdateEngagementTaskConfig($input: UpdateEngagementTaskConfigInput!) {
  updateEngagementTaskConfig(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigFields
    }
  }
}
    ${EngagementTaskConfigFieldsFragmentDoc}`;

export function useUpdateEngagementTaskConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEngagementTaskConfigMutation,
    GQTypes.GQUpdateEngagementTaskConfigMutationVariables
  >(UpdateEngagementTaskConfigDocument);
}
export const TaskConfigurationPageDocument = gql`
    query TaskConfigurationPage($engagementTaskConfigId: ID!, $companyId: ID!) {
  engagementTaskConfig(engagementTaskConfigId: $engagementTaskConfigId) {
    ...EngagementTaskConfigFields
  }
  companyForDashboard(id: $companyId) {
    ...CompanyFieldsForEngagementTasks
  }
}
    ${EngagementTaskConfigFieldsFragmentDoc}
${CompanyFieldsForEngagementTasksFragmentDoc}`;

export function useTaskConfigurationPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTaskConfigurationPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTaskConfigurationPageQuery,
    GQTypes.GQTaskConfigurationPageQueryVariables
  >({ query: TaskConfigurationPageDocument, ...options });
}
export const GetSupplierDocument = gql`
    query GetSupplier($companyId: ID, $vendorName: String, $version: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $viewFilters: FilterExpressionGroupWithNewFiltersInput) {
  supplier(
    version: $version
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    companyId: $companyId
    vendorName: $vendorName
    viewFilters: $viewFilters
  ) {
    ...SupplierFields
  }
  organization {
    id
    engagementFunnels {
      ...EngagementFunnelsChartFields
      ...EngagementFunnelsProgressTrackerFields
    }
  }
}
    ${SupplierFieldsFragmentDoc}
${EngagementFunnelsChartFieldsFragmentDoc}
${EngagementFunnelsProgressTrackerFieldsFragmentDoc}`;

export function useGetSupplierQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetSupplierQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierQuery,
    GQTypes.GQGetSupplierQueryVariables
  >({ query: GetSupplierDocument, ...options });
}
export const GetSupplierDetailedEmissionsDocument = gql`
    query GetSupplierDetailedEmissions($companyId: ID, $vendorName: String, $version: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $viewFilters: FilterExpressionGroupWithNewFiltersInput) {
  supplierDetailedEmissions(
    version: $version
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    companyId: $companyId
    vendorName: $vendorName
    viewFilters: $viewFilters
  ) {
    id
    rows {
      ...SupplierRowFields
    }
    emissionsByCategory {
      ...SupplierCategorizedEmissionDataFields
    }
    emissionsByMonth(groupBy: businessCategory) {
      ...SupplierEmissionsByMonthFields
    }
    emissionsFactors {
      ...SupplierEmissionsFactors
    }
    emissionsModelEmissionsFactors {
      ...SupplierEmissionsModelEmissionsFactors
    }
  }
}
    ${SupplierRowFieldsFragmentDoc}
${SupplierCategorizedEmissionDataFieldsFragmentDoc}
${SupplierEmissionsByMonthFieldsFragmentDoc}
${SupplierEmissionsFactorsFragmentDoc}
${SupplierEmissionsModelEmissionsFactorsFragmentDoc}`;

export function useGetSupplierDetailedEmissionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierDetailedEmissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierDetailedEmissionsQuery,
    GQTypes.GQGetSupplierDetailedEmissionsQueryVariables
  >({ query: GetSupplierDetailedEmissionsDocument, ...options });
}
export const GetSupplierDetailsDataDocument = gql`
    query GetSupplierDetailsData($companyId: ID!, $orgId: ID!) {
  allDisclosures: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {}, privateDisclosureFilters: {orgId: $orgId}}
  ) {
    ...SupplierDisclosureFields
  }
  historicalYears: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {excludeCdp: true}, privateDisclosureFilters: {orgId: $orgId}, historicalYearFilters: {allScopes: true}}
  ) {
    ...SupplierDisclosureFields
  }
  targets: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {excludeCdp: true}, privateDisclosureFilters: {orgId: $orgId}, targetFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
  initiatives: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {excludeCdp: true}, privateDisclosureFilters: {orgId: $orgId}, initiativeFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
  climateCommitments: companyDisclosures(
    companyId: $companyId
    opts: {publicDisclosureFilters: {excludeCdp: true}, privateDisclosureFilters: {orgId: $orgId}, climateCommitmentFilters: {}}
  ) {
    ...SupplierDisclosureFields
  }
}
    ${SupplierDisclosureFieldsFragmentDoc}`;

export function useGetSupplierDetailsDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierDetailsDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierDetailsDataQuery,
    GQTypes.GQGetSupplierDetailsDataQueryVariables
  >({ query: GetSupplierDetailsDataDocument, ...options });
}
export const GetSuppliersPageDataV2Document = gql`
    query GetSuppliersPageDataV2($version: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $includedGhgCategories: [String!], $viewFilters: FilterExpressionGroupWithNewFiltersInput, $tableFilters: [BiQueryFilterInput!], $enableExperimentalCache: Boolean) {
  suppliersV2(
    version: $version
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    includedGhgCategories: $includedGhgCategories
    viewFilters: $viewFilters
    tableFilters: $tableFilters
    enableExperimentalCache: $enableExperimentalCache
  ) {
    ...SuppliersDataFields
  }
}
    ${SuppliersDataFieldsFragmentDoc}`;

export function useGetSuppliersPageDataV2Query(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSuppliersPageDataV2QueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSuppliersPageDataV2Query,
    GQTypes.GQGetSuppliersPageDataV2QueryVariables
  >({ query: GetSuppliersPageDataV2Document, ...options });
}
export const GetEngagementCohortsDocument = gql`
    query GetEngagementCohorts {
  engagementCohorts {
    ...EngagementCohortFields
  }
}
    ${EngagementCohortFieldsFragmentDoc}`;

export function useGetEngagementCohortsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementCohortsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementCohortsQuery,
    GQTypes.GQGetEngagementCohortsQueryVariables
  >({ query: GetEngagementCohortsDocument, ...options });
}
export const UpdateSupplierCustomDataDocument = gql`
    mutation UpdateSupplierCustomData($input: UpdateSupplierCustomDataInput!) {
  updateSupplierCustomData(input: $input) {
    supplierId
    supplierCustomData {
      ...SupplierCustomDataFields
    }
  }
}
    ${SupplierCustomDataFieldsFragmentDoc}`;

export function useUpdateSupplierCustomDataMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierCustomDataMutation,
    GQTypes.GQUpdateSupplierCustomDataMutationVariables
  >(UpdateSupplierCustomDataDocument);
}
export const UpdateSupplierColumnVisibilityBatchDocument = gql`
    mutation UpdateSupplierColumnVisibilityBatch($input: UpdateSupplierColumnVisibilityBatchInput!) {
  updateSupplierColumnVisibilityBatch(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierColumnVisibilityBatchMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierColumnVisibilityBatchMutation,
    GQTypes.GQUpdateSupplierColumnVisibilityBatchMutationVariables
  >(UpdateSupplierColumnVisibilityBatchDocument);
}
export const UpdateSupplierColumnWidthDocument = gql`
    mutation UpdateSupplierColumnWidth($input: UpdateSupplierColumnWidthInput!) {
  updateSupplierColumnWidth(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierColumnWidthMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierColumnWidthMutation,
    GQTypes.GQUpdateSupplierColumnWidthMutationVariables
  >(UpdateSupplierColumnWidthDocument);
}
export const UpdateSupplierColumnOrderDocument = gql`
    mutation UpdateSupplierColumnOrder($input: UpdateSupplierColumnOrderInput!) {
  updateSupplierColumnOrder(input: $input) {
    view {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierColumnOrderMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierColumnOrderMutation,
    GQTypes.GQUpdateSupplierColumnOrderMutationVariables
  >(UpdateSupplierColumnOrderDocument);
}
export const CreateSupplierTableColumnDocument = gql`
    mutation CreateSupplierTableColumn($input: CreateSupplierTableColumnInput!) {
  createSupplierTableColumn(input: $input) {
    views {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useCreateSupplierTableColumnMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierTableColumnMutation,
    GQTypes.GQCreateSupplierTableColumnMutationVariables
  >(CreateSupplierTableColumnDocument);
}
export const UpdateSupplierTableColumnDocument = gql`
    mutation UpdateSupplierTableColumn($input: UpdateSupplierTableColumnInput!) {
  updateSupplierTableColumn(input: $input) {
    views {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useUpdateSupplierTableColumnMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierTableColumnMutation,
    GQTypes.GQUpdateSupplierTableColumnMutationVariables
  >(UpdateSupplierTableColumnDocument);
}
export const DeleteSupplierTableColumnDocument = gql`
    mutation DeleteSupplierTableColumn($input: DeleteSupplierTableColumnInput!) {
  deleteSupplierTableColumn(input: $input) {
    views {
      ...SupplierViewFields
    }
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useDeleteSupplierTableColumnMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierTableColumnMutation,
    GQTypes.GQDeleteSupplierTableColumnMutationVariables
  >(DeleteSupplierTableColumnDocument);
}
export const UpdateSuppliersSettingsDocument = gql`
    mutation UpdateSuppliersSettings($suppliersSettingsInput: UpdateSuppliersSettingsInput!, $portalSettingsInput: UpsertCompanyPortalSettingsInput!) {
  updateSuppliersSettings(input: $suppliersSettingsInput) {
    suppliersSettings {
      ...SuppliersSettingsFields
    }
  }
  upsertCompanyPortalSettings(input: $portalSettingsInput) {
    companyPortalSettings {
      ...CompanyPortalSettingsFields
    }
  }
}
    ${SuppliersSettingsFieldsFragmentDoc}
${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useUpdateSuppliersSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSuppliersSettingsMutation,
    GQTypes.GQUpdateSuppliersSettingsMutationVariables
  >(UpdateSuppliersSettingsDocument);
}
export const GetSuppliersSettingsDocument = gql`
    query GetSuppliersSettings($rootCompanyId: ID!) {
  suppliersSettings {
    ...SuppliersSettingsFields
  }
  companyPortalSettings(rootCompanyId: $rootCompanyId) {
    ...CompanyPortalSettingsFields
  }
}
    ${SuppliersSettingsFieldsFragmentDoc}
${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useGetSuppliersSettingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSuppliersSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSuppliersSettingsQuery,
    GQTypes.GQGetSuppliersSettingsQueryVariables
  >({ query: GetSuppliersSettingsDocument, ...options });
}
export const GetVariousSuppliersDocument = gql`
    query GetVariousSuppliers($footprintVersion: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $includedGhgCategories: [String!]) {
  variousSuppliers(
    footprintVersion: $footprintVersion
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    includedGhgCategories: $includedGhgCategories
  ) {
    variousSuppliers {
      vendor
      companyId
      totalKgco2e
      totalSpendUsd
    }
  }
}
    `;

export function useGetVariousSuppliersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetVariousSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetVariousSuppliersQuery,
    GQTypes.GQGetVariousSuppliersQueryVariables
  >({ query: GetVariousSuppliersDocument, ...options });
}
export const GetBulkDeployStatusDocument = gql`
    query GetBulkDeployStatus($id: ID!) {
  engagementTaskBatchDeployStatus(engagementTaskBatchId: $id) {
    id
    successfulDeployCompanies {
      ...BulkDeployStatusCompanyFields
    }
    failedDeployCompanies {
      ...BulkDeployStatusCompanyFields
    }
  }
}
    ${BulkDeployStatusCompanyFieldsFragmentDoc}`;

export function useGetBulkDeployStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBulkDeployStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBulkDeployStatusQuery,
    GQTypes.GQGetBulkDeployStatusQueryVariables
  >({ query: GetBulkDeployStatusDocument, ...options });
}
export const GetExpenseCategoriesForSuppliersDocument = gql`
    query GetExpenseCategoriesForSuppliers($version: String, $footprintSnapshotId: String, $footprintInterval: YMInterval!, $companyIds: [ID!]!, $useDuckdbPostgresConnection: Boolean) {
  expenseCategoriesForSuppliers(
    version: $version
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $footprintInterval
    companyIds: $companyIds
    useDuckdbPostgresConnection: $useDuckdbPostgresConnection
  ) {
    companyId
    expenseCategories {
      beaCode
      inputUnit
      inputQuantity
      kgco2e
    }
  }
}
    `;

export function useGetExpenseCategoriesForSuppliersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetExpenseCategoriesForSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetExpenseCategoriesForSuppliersQuery,
    GQTypes.GQGetExpenseCategoriesForSuppliersQueryVariables
  >({ query: GetExpenseCategoriesForSuppliersDocument, ...options });
}
export const GetContactsForCompanyDocument = gql`
    query GetContactsForCompany($companyId: ID!) {
  contactsForCompany(companyId: $companyId) {
    ...SupplierContactFields
  }
}
    ${SupplierContactFieldsFragmentDoc}`;

export function useGetContactsForCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContactsForCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContactsForCompanyQuery,
    GQTypes.GQGetContactsForCompanyQueryVariables
  >({ query: GetContactsForCompanyDocument, ...options });
}
export const GetContactsForOrgDocument = gql`
    query GetContactsForOrg {
  contacts {
    ...SupplierContactFields
  }
}
    ${SupplierContactFieldsFragmentDoc}`;

export function useGetContactsForOrgQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContactsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContactsForOrgQuery,
    GQTypes.GQGetContactsForOrgQueryVariables
  >({ query: GetContactsForOrgDocument, ...options });
}
export const SupplierScorecardsDocument = gql`
    query SupplierScorecards {
  supplierScorecards {
    ...SupplierScorecardFields
  }
}
    ${SupplierScorecardFieldsFragmentDoc}`;

export function useSupplierScorecardsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierScorecardsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierScorecardsQuery,
    GQTypes.GQSupplierScorecardsQueryVariables
  >({ query: SupplierScorecardsDocument, ...options });
}
export const GetSupplierViewsDocument = gql`
    query GetSupplierViews {
  supplierViews {
    ...SupplierViewFields
  }
}
    ${SupplierViewFieldsFragmentDoc}`;

export function useGetSupplierViewsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierViewsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierViewsQuery,
    GQTypes.GQGetSupplierViewsQueryVariables
  >({ query: GetSupplierViewsDocument, ...options });
}
export const SuppliersReductionsForecastDocument = gql`
    query SuppliersReductionsForecast($footprintVersion: String, $footprintSnapshotId: String, $suppliersQueryInterval: YMInterval!, $orgId: ID!, $viewFilters: FilterExpressionGroupWithNewFiltersInput) {
  suppliersWithTargetDisclosures(
    footprintVersion: $footprintVersion
    footprintSnapshotId: $footprintSnapshotId
    footprintInterval: $suppliersQueryInterval
    viewFilters: $viewFilters
  ) {
    suppliers {
      ...SupplierForReductionsForecast
    }
  }
}
    ${SupplierForReductionsForecastFragmentDoc}`;

export function useSuppliersReductionsForecastQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSuppliersReductionsForecastQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSuppliersReductionsForecastQuery,
    GQTypes.GQSuppliersReductionsForecastQueryVariables
  >({ query: SuppliersReductionsForecastDocument, ...options });
}
export const GetBiSavedViewsForLandingPageDocument = gql`
    query GetBiSavedViewsForLandingPage($input: BiSavedViewsInput) {
  biSavedViews(input: $input) {
    ...BiSavedViewForLandingPage
  }
}
    ${BiSavedViewForLandingPageFragmentDoc}`;

export function useGetBiSavedViewsForLandingPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBiSavedViewsForLandingPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBiSavedViewsForLandingPageQuery,
    GQTypes.GQGetBiSavedViewsForLandingPageQueryVariables
  >({ query: GetBiSavedViewsForLandingPageDocument, ...options });
}
export const SelectAssetBenchmarksDocument = gql`
    mutation SelectAssetBenchmarks($input: SetAssetBenchmarksInput!) {
  setAssetBenchmarks(input: $input) {
    status
  }
}
    `;

export function useSelectAssetBenchmarksMutation() {
  return Urql.useMutation<
    GQTypes.GQSelectAssetBenchmarksMutation,
    GQTypes.GQSelectAssetBenchmarksMutationVariables
  >(SelectAssetBenchmarksDocument);
}
export const UpdateFinanceWorksheetDocument = gql`
    mutation UpdateFinanceWorksheet($input: UpdateFinanceWorksheetInput!) {
  updateFinanceWorksheet(input: $input) {
    worksheet {
      id
      ...FinanceWorksheetAssetGrid
    }
  }
}
    ${FinanceWorksheetAssetGridFragmentDoc}`;

export function useUpdateFinanceWorksheetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceWorksheetMutation,
    GQTypes.GQUpdateFinanceWorksheetMutationVariables
  >(UpdateFinanceWorksheetDocument);
}
export const DeleteAssetsDocument = gql`
    mutation DeleteAssets($input: DeleteAssetsInput!) {
  deleteAssets(input: $input) {
    assetYearIds
    assetHoldingIds
    privateDisclosureIds
    emissionsYearIds
    fundHoldingBenchmarkIds
    footprintEstimateOutputIds
    assetCorporateIds
    assetRealEstateIds
    assetGroupIds
    assetSovereignBondIds
    assetPersonalMotorVehicleInsuranceIds
    invalidatedSnapshots {
      ...FinanceSnapshotIsStale
    }
  }
}
    ${FinanceSnapshotIsStaleFragmentDoc}`;

export function useDeleteAssetsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAssetsMutation,
    GQTypes.GQDeleteAssetsMutationVariables
  >(DeleteAssetsDocument);
}
export const FinanceSnapshotHoldingsDocument = gql`
    query FinanceSnapshotHoldings($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) {
  financeSnapshotHoldings(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    id
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceSnapshotHoldingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotHoldingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotHoldingsQuery,
    GQTypes.GQFinanceSnapshotHoldingsQueryVariables
  >({ query: FinanceSnapshotHoldingsDocument, ...options });
}
export const FinanceSnapshotAssetsDocument = gql`
    query FinanceSnapshotAssets($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) {
  financeSnapshotAssets(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    id
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceSnapshotAssetsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotAssetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotAssetsQuery,
    GQTypes.GQFinanceSnapshotAssetsQueryVariables
  >({ query: FinanceSnapshotAssetsDocument, ...options });
}
export const FinanceSnapshotAssetsGroupedByCompanyDocument = gql`
    query FinanceSnapshotAssetsGroupedByCompany($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) {
  financeSnapshotAssetsGroupedByCompany(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    id
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceSnapshotAssetsGroupedByCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotAssetsGroupedByCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotAssetsGroupedByCompanyQuery,
    GQTypes.GQFinanceSnapshotAssetsGroupedByCompanyQueryVariables
  >({ query: FinanceSnapshotAssetsGroupedByCompanyDocument, ...options });
}
export const FinanceSnapshotFundsDocument = gql`
    query FinanceSnapshotFunds($financeSnapshotId: ID!, $year: Int!, $filters: UntypedData!, $search: String, $sortField: String, $sortDirection: String, $after: String, $before: String, $first: Int, $last: Int) {
  financeSnapshotFunds(
    financeSnapshotId: $financeSnapshotId
    year: $year
    filters: $filters
    search: $search
    sortField: $sortField
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    id
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node
    }
  }
}
    `;

export function useFinanceSnapshotFundsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotFundsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotFundsQuery,
    GQTypes.GQFinanceSnapshotFundsQueryVariables
  >({ query: FinanceSnapshotFundsDocument, ...options });
}
export const FinanceSnapshotGridTagsDocument = gql`
    query FinanceSnapshotGridTags($financeSnapshotId: ID!) {
  financeSnapshotTags(financeSnapshotId: $financeSnapshotId) {
    id
    financeTag {
      ...FinanceTagForGrid
    }
  }
}
    ${FinanceTagForGridFragmentDoc}`;

export function useFinanceSnapshotGridTagsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotGridTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotGridTagsQuery,
    GQTypes.GQFinanceSnapshotGridTagsQueryVariables
  >({ query: FinanceSnapshotGridTagsDocument, ...options });
}
export const FinanceBenchmarkOptionsDocument = gql`
    query FinanceBenchmarkOptions($search: String) {
  publicDisclosureSearch(
    search: $search
    allScopes: true
    onlyMarketBased: true
    revenueMustExist: true
  ) {
    edges {
      node {
        id
        reportingYears
        company {
          id
          name
        }
      }
    }
  }
}
    `;

export function useFinanceBenchmarkOptionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceBenchmarkOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceBenchmarkOptionsQuery,
    GQTypes.GQFinanceBenchmarkOptionsQueryVariables
  >({ query: FinanceBenchmarkOptionsDocument, ...options });
}
export const CreateAssetCorporateDocument = gql`
    mutation CreateAssetCorporate($input: CreateAssetCorporateInput!) {
  createAssetCorporate(input: $input) {
    asset {
      id
      ...AssetCorporateAllFields
    }
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}`;

export function useCreateAssetCorporateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetCorporateMutation,
    GQTypes.GQCreateAssetCorporateMutationVariables
  >(CreateAssetCorporateDocument);
}
export const CreateAssetGroupDocument = gql`
    mutation CreateAssetGroup($input: CreateAssetGroupInput!) {
  createAssetGroup(input: $input) {
    asset {
      id
      ...AssetGroupAllFields
    }
  }
}
    ${AssetGroupAllFieldsFragmentDoc}`;

export function useCreateAssetGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetGroupMutation,
    GQTypes.GQCreateAssetGroupMutationVariables
  >(CreateAssetGroupDocument);
}
export const CreateAssetPersonalMotorVehicleInsuranceDocument = gql`
    mutation CreateAssetPersonalMotorVehicleInsurance($input: CreateAssetPersonalMotorVehicleInsuranceInput!) {
  createAssetPersonalMotorVehicleInsurance(input: $input) {
    asset {
      id
      ...AssetPersonalMotorVehicleInsuranceAllFields
    }
  }
}
    ${AssetPersonalMotorVehicleInsuranceAllFieldsFragmentDoc}`;

export function useCreateAssetPersonalMotorVehicleInsuranceMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetPersonalMotorVehicleInsuranceMutation,
    GQTypes.GQCreateAssetPersonalMotorVehicleInsuranceMutationVariables
  >(CreateAssetPersonalMotorVehicleInsuranceDocument);
}
export const CreateAssetRealEstateDocument = gql`
    mutation CreateAssetRealEstate($input: CreateAssetRealEstateInput!) {
  createAssetRealEstate(input: $input) {
    asset {
      id
      ...AssetRealEstateAllFields
    }
  }
}
    ${AssetRealEstateAllFieldsFragmentDoc}`;

export function useCreateAssetRealEstateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetRealEstateMutation,
    GQTypes.GQCreateAssetRealEstateMutationVariables
  >(CreateAssetRealEstateDocument);
}
export const CreateAssetSovereignBondDocument = gql`
    mutation CreateAssetSovereignBond($input: CreateAssetSovereignBondInput!) {
  createAssetSovereignBond(input: $input) {
    asset {
      id
      ...AssetSovereignBondAllFields
    }
  }
}
    ${AssetSovereignBondAllFieldsFragmentDoc}`;

export function useCreateAssetSovereignBondMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetSovereignBondMutation,
    GQTypes.GQCreateAssetSovereignBondMutationVariables
  >(CreateAssetSovereignBondDocument);
}
export const CreateFinanceSavedViewDocument = gql`
    mutation CreateFinanceSavedView($input: CreateFinanceSavedViewInput!) {
  createFinanceSavedView(input: $input) {
    financeSavedView {
      id
      ...FinanceSavedViewAllFields
    }
  }
}
    ${FinanceSavedViewAllFieldsFragmentDoc}`;

export function useCreateFinanceSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceSavedViewMutation,
    GQTypes.GQCreateFinanceSavedViewMutationVariables
  >(CreateFinanceSavedViewDocument);
}
export const CreateFinanceTagDocument = gql`
    mutation CreateFinanceTag($input: CreateFinanceTagInput!) {
  createFinanceTag(input: $input) {
    financeTag {
      ...FinanceTagAllFields
    }
  }
}
    ${FinanceTagAllFieldsFragmentDoc}`;

export function useCreateFinanceTagMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceTagMutation,
    GQTypes.GQCreateFinanceTagMutationVariables
  >(CreateFinanceTagDocument);
}
export const CreateFundDocument = gql`
    mutation CreateFund($input: CreateFundInput!) {
  createFund(input: $input) {
    fund {
      id
      ...FundAllFields
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useCreateFundMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFundMutation,
    GQTypes.GQCreateFundMutationVariables
  >(CreateFundDocument);
}
export const CreateFinanceReportingTagsDocument = gql`
    mutation CreateFinanceReportingTags($input: CreateFinanceReportingTagsInput!) {
  createFinanceReportingTags(input: $input) {
    financeTags {
      ...FinanceTagAllFields
    }
  }
}
    ${FinanceTagAllFieldsFragmentDoc}`;

export function useCreateFinanceReportingTagsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinanceReportingTagsMutation,
    GQTypes.GQCreateFinanceReportingTagsMutationVariables
  >(CreateFinanceReportingTagsDocument);
}
export const DeleteFinanceTagDocument = gql`
    mutation DeleteFinanceTag($input: DeleteFinanceTagInput!) {
  deleteFinanceTag(input: $input) {
    id
  }
}
    `;

export function useDeleteFinanceTagMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinanceTagMutation,
    GQTypes.GQDeleteFinanceTagMutationVariables
  >(DeleteFinanceTagDocument);
}
export const FinanceTagsDocument = gql`
    query FinanceTags {
  financeTags {
    ...FinanceTagAllFields
  }
}
    ${FinanceTagAllFieldsFragmentDoc}`;

export function useFinanceTagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceTagsQuery,
    GQTypes.GQFinanceTagsQueryVariables
  >({ query: FinanceTagsDocument, ...options });
}
export const DeleteFinanceTagsDocument = gql`
    mutation DeleteFinanceTags($input: DeleteFinanceTagsInput!) {
  deleteFinanceTags(input: $input) {
    ids
  }
}
    `;

export function useDeleteFinanceTagsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinanceTagsMutation,
    GQTypes.GQDeleteFinanceTagsMutationVariables
  >(DeleteFinanceTagsDocument);
}
export const UpdateFinanceTagDocument = gql`
    mutation UpdateFinanceTag($input: UpdateFinanceTagInput!) {
  updateFinanceTag(input: $input) {
    financeTag {
      ...FinanceTagAllFields
    }
  }
}
    ${FinanceTagAllFieldsFragmentDoc}`;

export function useUpdateFinanceTagMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceTagMutation,
    GQTypes.GQUpdateFinanceTagMutationVariables
  >(UpdateFinanceTagDocument);
}
export const CreateAssetCommentDocument = gql`
    mutation CreateAssetComment($input: CreateAssetCommentInput!) {
  createAssetComment(input: $input) {
    assetComment {
      ...AssetCommentAllFields
      asset {
        id
        assetComments {
          id
        }
      }
    }
    attachmentMetadata {
      signedUrl
      fileMetadataId
    }
  }
}
    ${AssetCommentAllFieldsFragmentDoc}`;

export function useCreateAssetCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAssetCommentMutation,
    GQTypes.GQCreateAssetCommentMutationVariables
  >(CreateAssetCommentDocument);
}
export const AssetCommentsDocument = gql`
    query AssetComments($assetId: ID!) {
  asset(id: $assetId) {
    id
    assetComments {
      ...AssetCommentAllFields
    }
  }
}
    ${AssetCommentAllFieldsFragmentDoc}`;

export function useAssetCommentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetCommentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetCommentsQuery,
    GQTypes.GQAssetCommentsQueryVariables
  >({ query: AssetCommentsDocument, ...options });
}
export const UpdateAssetCommentDocument = gql`
    mutation UpdateAssetComment($input: UpdateAssetCommentInput!) {
  updateAssetComment(input: $input) {
    assetComment {
      ...AssetCommentAllFields
    }
  }
}
    ${AssetCommentAllFieldsFragmentDoc}`;

export function useUpdateAssetCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateAssetCommentMutation,
    GQTypes.GQUpdateAssetCommentMutationVariables
  >(UpdateAssetCommentDocument);
}
export const DeleteAssetCommentDocument = gql`
    mutation DeleteAssetComment($input: DeleteAssetCommentInput!) {
  deleteAssetComment(input: $input) {
    id
    asset {
      id
      assetComments {
        id
      }
    }
  }
}
    `;

export function useDeleteAssetCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAssetCommentMutation,
    GQTypes.GQDeleteAssetCommentMutationVariables
  >(DeleteAssetCommentDocument);
}
export const FinanceSnapshotFilterOptionsDocument = gql`
    query FinanceSnapshotFilterOptions($financeSnapshotId: ID!, $filters: UntypedData!) {
  financeSnapshotFilterOptions(
    financeSnapshotId: $financeSnapshotId
    filters: $filters
  )
}
    `;

export function useFinanceSnapshotFilterOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotFilterOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotFilterOptionsQuery,
    GQTypes.GQFinanceSnapshotFilterOptionsQueryVariables
  >({ query: FinanceSnapshotFilterOptionsDocument, ...options });
}
export const OrgFundsV3PageDocument = gql`
    query OrgFundsV3Page($financeSnapshotId: ID, $year: Int, $isTotalEmissions: Boolean, $filters: UntypedData, $standardView: FinanceStandardView, $viewId: ID) {
  financeView(
    financeSnapshotId: $financeSnapshotId
    year: $year
    isTotalEmissions: $isTotalEmissions
    filters: $filters
    standardView: $standardView
    viewId: $viewId
  ) {
    standardView
    savedView {
      id
      ...FinanceSavedViewAllFields
    }
    header {
      ...FinanceHeaderCurrent
    }
    gridWorksheet {
      ...FinanceWorksheetAssetGrid
    }
  }
}
    ${FinanceSavedViewAllFieldsFragmentDoc}
${FinanceHeaderCurrentFragmentDoc}
${FinanceWorksheetAssetGridFragmentDoc}`;

export function useOrgFundsV3PageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgFundsV3PageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgFundsV3PageQuery,
    GQTypes.GQOrgFundsV3PageQueryVariables
  >({ query: OrgFundsV3PageDocument, ...options });
}
export const DeleteSnapshotDocument = gql`
    mutation DeleteSnapshot($input: DeleteSnapshotInput!) {
  deleteSnapshot(input: $input) {
    id
  }
}
    `;

export function useDeleteSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSnapshotMutation,
    GQTypes.GQDeleteSnapshotMutationVariables
  >(DeleteSnapshotDocument);
}
export const UpdateSnapshotNameDocument = gql`
    mutation UpdateSnapshotName($input: UpdateSnapshotNameInput!) {
  updateSnapshotName(input: $input) {
    snapshot {
      id
      ...FinanceSnapshotForContext
    }
  }
}
    ${FinanceSnapshotForContextFragmentDoc}`;

export function useUpdateSnapshotNameMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSnapshotNameMutation,
    GQTypes.GQUpdateSnapshotNameMutationVariables
  >(UpdateSnapshotNameDocument);
}
export const UpdateFinanceSavedViewDocument = gql`
    mutation UpdateFinanceSavedView($input: UpdateFinanceSavedViewInput!) {
  updateFinanceSavedView(input: $input) {
    financeSavedView {
      id
      ...FinanceSavedViewAllFields
    }
  }
}
    ${FinanceSavedViewAllFieldsFragmentDoc}`;

export function useUpdateFinanceSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinanceSavedViewMutation,
    GQTypes.GQUpdateFinanceSavedViewMutationVariables
  >(UpdateFinanceSavedViewDocument);
}
export const OnboardingHomePageDocument = gql`
    query OnboardingHomePage {
  climateProgramTimelineProjects {
    ...ClimateProgramTimelineProjectFields
  }
  activeMeasurementProjects {
    ...ActiveMeasurementProjectForOnboardingHomePage
  }
}
    ${ClimateProgramTimelineProjectFieldsFragmentDoc}
${ActiveMeasurementProjectForOnboardingHomePageFragmentDoc}`;

export function useOnboardingHomePageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOnboardingHomePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOnboardingHomePageQuery,
    GQTypes.GQOnboardingHomePageQueryVariables
  >({ query: OnboardingHomePageDocument, ...options });
}
export const OnboardingNavCardDocument = gql`
    query OnboardingNavCard {
  organization {
    ...OrganizationForOnboardingHomePage
  }
}
    ${OrganizationForOnboardingHomePageFragmentDoc}`;

export function useOnboardingNavCardQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOnboardingNavCardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOnboardingNavCardQuery,
    GQTypes.GQOnboardingNavCardQueryVariables
  >({ query: OnboardingNavCardDocument, ...options });
}
export const GetValueMappingOutputOptionsDocument = gql`
    query GetValueMappingOutputOptions($columnName: String!, $searchTerm: String, $selectedValue: String, $suggestedValues: [String!], $configId: String!) {
  getValueMappingOutputOptions(
    columnName: $columnName
    searchTerm: $searchTerm
    selectedValue: $selectedValue
    suggestedValues: $suggestedValues
    configId: $configId
  ) {
    ...ValueMappingOutputOptionFields
  }
}
    ${ValueMappingOutputOptionFieldsFragmentDoc}`;

export function useGetValueMappingOutputOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingOutputOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingOutputOptionsQuery,
    GQTypes.GQGetValueMappingOutputOptionsQueryVariables
  >({ query: GetValueMappingOutputOptionsDocument, ...options });
}
export const ApproveAllValueMappingRecordsDocument = gql`
    mutation ApproveAllValueMappingRecords($input: ApproveAllValueMappingRecordsInput!) {
  approveAllValueMappingRecords(input: $input) {
    success
    workflowId
  }
}
    `;

export function useApproveAllValueMappingRecordsMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveAllValueMappingRecordsMutation,
    GQTypes.GQApproveAllValueMappingRecordsMutationVariables
  >(ApproveAllValueMappingRecordsDocument);
}
export const ValueMappingRowsDocument = gql`
    query ValueMappingRows($valueMappingConfigId: ID!, $after: String, $before: String, $first: Int, $last: Int, $measurementProjectId: ID, $filter: ValueMappingFilterType, $searchText: String, $sortModel: [DataGridSortItemInput!]) {
  valueMappingRows(
    valueMappingConfigId: $valueMappingConfigId
    after: $after
    before: $before
    first: $first
    last: $last
    measurementProjectId: $measurementProjectId
    filter: $filter
    searchText: $searchText
    sortModel: $sortModel
  ) {
    edges {
      node {
        ...ValueMappingPageValueMappingRow
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    metadata {
      id
      allRowsAreFilled
      totalRowCount
      unmappedRowCount
      flaggedRowCount
      suggestionsRowCount
    }
  }
}
    ${ValueMappingPageValueMappingRowFragmentDoc}`;

export function useValueMappingRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingRowsQuery,
    GQTypes.GQValueMappingRowsQueryVariables
  >({ query: ValueMappingRowsDocument, ...options });
}
export const GetManageValueMappingPermissionDocument = gql`
    query GetManageValueMappingPermission($valueMappingRuleId: String, $orgId: ID!) {
  userHasManageValueMappingPermission(
    valueMappingRuleId: $valueMappingRuleId
    orgId: $orgId
  )
}
    `;

export function useGetManageValueMappingPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetManageValueMappingPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetManageValueMappingPermissionQuery,
    GQTypes.GQGetManageValueMappingPermissionQueryVariables
  >({ query: GetManageValueMappingPermissionDocument, ...options });
}
export const GetValueMappingCommentsDocument = gql`
    query GetValueMappingComments($orgId: ID!, $valueMappingConfigId: ID!, $subjectId: ID!) {
  valueMappingCommentsForSubject(
    orgId: $orgId
    valueMappingConfigId: $valueMappingConfigId
    subjectId: $subjectId
  ) {
    id
    message
    creator {
      id
      isWatershedEmployee
      isWatershedContractor
      name
    }
    lastEditor {
      id
      isWatershedEmployee
      isWatershedContractor
      name
    }
    createdAt
    lastEditedAt
  }
}
    `;

export function useGetValueMappingCommentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingCommentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingCommentsQuery,
    GQTypes.GQGetValueMappingCommentsQueryVariables
  >({ query: GetValueMappingCommentsDocument, ...options });
}
export const CreateValueMappingCommentDocument = gql`
    mutation CreateValueMappingComment($input: CreateValueMappingCommentInput!) {
  createValueMappingComment(input: $input) {
    id
    message
  }
}
    `;

export function useCreateValueMappingCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateValueMappingCommentMutation,
    GQTypes.GQCreateValueMappingCommentMutationVariables
  >(CreateValueMappingCommentDocument);
}
export const GetValueMappingHistoryForGroupDocument = gql`
    query GetValueMappingHistoryForGroup($orgId: ID!, $valueMappingConfigId: ID!, $groupId: ID!) {
  valueMappingHistoryForGroup(
    orgId: $orgId
    valueMappingConfigId: $valueMappingConfigId
    groupId: $groupId
  ) {
    id
    entries {
      id
      rowId
      revision {
        id
        isValueMappingLegacyMigration
      }
      columnName
      operation
      oldValue
      newValue
      changedAt
      changedBy {
        id
        name
        isWatershedEmployee
        isWatershedContractor
      }
    }
  }
}
    `;

export function useGetValueMappingHistoryForGroupQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingHistoryForGroupQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingHistoryForGroupQuery,
    GQTypes.GQGetValueMappingHistoryForGroupQueryVariables
  >({ query: GetValueMappingHistoryForGroupDocument, ...options });
}
export const ValueMappingFileRowDataDocument = gql`
    query ValueMappingFileRowData($userUploadIds: [ID!]!, $filter: [ValueMappingFileDataFilter!]!, $sortColumn: String!) {
  valueMappingFileRowData(
    userUploadIds: $userUploadIds
    filter: $filter
    sortColumn: $sortColumn
  ) {
    id
    fileData {
      id
      userVisibleAttempt {
        id
        createdAt
        name
        uploader {
          id
          name
        }
      }
    }
    matchedRows
  }
}
    `;

export function useValueMappingFileRowDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingFileRowDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingFileRowDataQuery,
    GQTypes.GQValueMappingFileRowDataQueryVariables
  >({ query: ValueMappingFileRowDataDocument, ...options });
}
export const ValueMappingTableSourcesDocument = gql`
    query ValueMappingTableSources($valueMappingConfigId: String!) {
  valueMappingTableSourceData(valueMappingConfigId: $valueMappingConfigId) {
    id
    outputOptionPickers {
      id
      columnName
      placeholder
      options {
        ...ValueMappingOutputOptionFields
      }
    }
  }
}
    ${ValueMappingOutputOptionFieldsFragmentDoc}`;

export function useValueMappingTableSourcesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingTableSourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingTableSourcesQuery,
    GQTypes.GQValueMappingTableSourcesQueryVariables
  >({ query: ValueMappingTableSourcesDocument, ...options });
}
export const DownloadValueMappingDocument = gql`
    mutation DownloadValueMapping($valueMappingConfigId: ID!, $withComments: Boolean, $measurementProjectId: ID) {
  generateValueMappingDownloadUrl(
    valueMappingConfigId: $valueMappingConfigId
    withComments: $withComments
    measurementProjectId: $measurementProjectId
  )
}
    `;

export function useDownloadValueMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQDownloadValueMappingMutation,
    GQTypes.GQDownloadValueMappingMutationVariables
  >(DownloadValueMappingDocument);
}
export const SuggestMappingsDocument = gql`
    mutation SuggestMappings($valueMappingConfigId: ID!, $measurementProjectId: ID) {
  suggestMappings(
    valueMappingConfigId: $valueMappingConfigId
    measurementProjectId: $measurementProjectId
  ) {
    jobId
  }
}
    `;

export function useSuggestMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSuggestMappingsMutation,
    GQTypes.GQSuggestMappingsMutationVariables
  >(SuggestMappingsDocument);
}
export const ValueMappingConfigDocument = gql`
    query ValueMappingConfig($id: String!) {
  valueMappingConfig(valueMappingConfigId: $id)
}
    `;

export function useValueMappingConfigQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingConfigQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingConfigQuery,
    GQTypes.GQValueMappingConfigQueryVariables
  >({ query: ValueMappingConfigDocument, ...options });
}
export const ValueMappingAggregatedQuantitiesDocument = gql`
    query ValueMappingAggregatedQuantities($configId: ID!, $orgId: ID!, $contextColumn: String!) {
  valueMappingAggregatedQuantities(
    configId: $configId
    orgId: $orgId
    contextColumn: $contextColumn
  ) {
    totalQuantity
    mappedQuantity
  }
}
    `;

export function useValueMappingAggregatedQuantitiesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQValueMappingAggregatedQuantitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQValueMappingAggregatedQuantitiesQuery,
    GQTypes.GQValueMappingAggregatedQuantitiesQueryVariables
  >({ query: ValueMappingAggregatedQuantitiesDocument, ...options });
}
export const SaveDeltaProgressValueMappingDocument = gql`
    mutation SaveDeltaProgressValueMapping($input: SaveDeltaProgressValueMappingInput!) {
  saveDeltaProgressValueMapping(input: $input) {
    revisionData {
      id
      revisionState
    }
    updatedRows {
      ...ValueMappingPageValueMappingRow
    }
    metadata {
      id
      allRowsAreFilled
    }
  }
}
    ${ValueMappingPageValueMappingRowFragmentDoc}`;

export function useSaveDeltaProgressValueMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveDeltaProgressValueMappingMutation,
    GQTypes.GQSaveDeltaProgressValueMappingMutationVariables
  >(SaveDeltaProgressValueMappingDocument);
}
export const ConfirmMarketplacePurchaseDocument = gql`
    mutation ConfirmMarketplacePurchase($input: ConfirmMarketplacePurchaseInput!) {
  confirmMarketplacePurchase(input: $input) {
    purchase {
      ...MarketplacePurchaseFieldsForPurchasesPage
    }
  }
}
    ${MarketplacePurchaseFieldsForPurchasesPageFragmentDoc}`;

export function useConfirmMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQConfirmMarketplacePurchaseMutation,
    GQTypes.GQConfirmMarketplacePurchaseMutationVariables
  >(ConfirmMarketplacePurchaseDocument);
}
export const GetVisibleMarketplaceProjectArchetypesDocument = gql`
    query GetVisibleMarketplaceProjectArchetypes {
  visibleMarketplaceProjectArchetypes {
    edges {
      node {
        ...VisibleMarketplaceProjectArchetypeFields
      }
    }
  }
}
    ${VisibleMarketplaceProjectArchetypeFieldsFragmentDoc}`;

export function useGetVisibleMarketplaceProjectArchetypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetVisibleMarketplaceProjectArchetypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetVisibleMarketplaceProjectArchetypesQuery,
    GQTypes.GQGetVisibleMarketplaceProjectArchetypesQueryVariables
  >({ query: GetVisibleMarketplaceProjectArchetypesDocument, ...options });
}
export const GetMarketplaceVoluntaryCarbonMarketDisclosuresDocument = gql`
    query GetMarketplaceVoluntaryCarbonMarketDisclosures {
  marketplaceVoluntaryCarbonMarketDisclosures {
    year
    url
  }
}
    `;

export function useGetMarketplaceVoluntaryCarbonMarketDisclosuresQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMarketplaceVoluntaryCarbonMarketDisclosuresQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMarketplaceVoluntaryCarbonMarketDisclosuresQuery,
    GQTypes.GQGetMarketplaceVoluntaryCarbonMarketDisclosuresQueryVariables
  >({
    query: GetMarketplaceVoluntaryCarbonMarketDisclosuresDocument,
    ...options,
  });
}
export const RefreshMarketplacePurchaseDocument = gql`
    mutation RefreshMarketplacePurchase($input: RefreshMarketplacePurchaseInput!) {
  refreshMarketplacePurchase(input: $input) {
    purchase {
      ...MarketplacePurchaseFieldsForPurchasesPage
    }
  }
}
    ${MarketplacePurchaseFieldsForPurchasesPageFragmentDoc}`;

export function useRefreshMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQRefreshMarketplacePurchaseMutation,
    GQTypes.GQRefreshMarketplacePurchaseMutationVariables
  >(RefreshMarketplacePurchaseDocument);
}
export const FetchDatasetsSimpleDocument = gql`
    query FetchDatasetsSimple {
  datasets(sortBy: "name") {
    id
    name
    canonicalDataset {
      id
      kind
      selfServeDisabledReason
    }
  }
}
    `;

export function useFetchDatasetsSimpleQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFetchDatasetsSimpleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFetchDatasetsSimpleQuery,
    GQTypes.GQFetchDatasetsSimpleQueryVariables
  >({ query: FetchDatasetsSimpleDocument, ...options });
}
export const FetchDatasetsForPlanDocument = gql`
    query FetchDatasetsForPlan {
  availableCanonicalDatasetsForPlan {
    id
    kind
    name
    selfServeDisabledReason
    canonicalDatasources {
      id
      name
      activeIntegrationConnection {
        id
      }
    }
  }
}
    `;

export function useFetchDatasetsForPlanQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFetchDatasetsForPlanQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFetchDatasetsForPlanQuery,
    GQTypes.GQFetchDatasetsForPlanQueryVariables
  >({ query: FetchDatasetsForPlanDocument, ...options });
}
export const CreateClimateProgramProjectDatasetRequirementDocument = gql`
    mutation CreateClimateProgramProjectDatasetRequirement($input: CreateClimateProgramProjectDatasetRequirementInput!) {
  createClimateProgramProjectDatasetRequirement(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectWithMeasurementConcernsFields
    }
  }
}
    ${ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}`;

export function useCreateClimateProgramProjectDatasetRequirementMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateClimateProgramProjectDatasetRequirementMutation,
    GQTypes.GQCreateClimateProgramProjectDatasetRequirementMutationVariables
  >(CreateClimateProgramProjectDatasetRequirementDocument);
}
export const GetOrCreateDatasetFromCanonicalDatasetKindDocument = gql`
    mutation GetOrCreateDatasetFromCanonicalDatasetKind($input: GetOrCreateDatasetFromCanonicalDatasetKindInput!) {
  getOrCreateDatasetFromCanonicalDatasetKind(input: $input) {
    dataset {
      id
      name
    }
  }
}
    `;

export function useGetOrCreateDatasetFromCanonicalDatasetKindMutation() {
  return Urql.useMutation<
    GQTypes.GQGetOrCreateDatasetFromCanonicalDatasetKindMutation,
    GQTypes.GQGetOrCreateDatasetFromCanonicalDatasetKindMutationVariables
  >(GetOrCreateDatasetFromCanonicalDatasetKindDocument);
}
export const CreateMeasurementProjectFromCanonicalClimateProgramProjectDocument = gql`
    mutation CreateMeasurementProjectFromCanonicalClimateProgramProject($input: CreateMeasurementProjectFromCanonicalClimateProgramProjectInput!) {
  createMeasurementProjectFromCanonicalClimateProgramProject(input: $input) {
    measurementProject {
      ...MeasurementProjectForMeasurementPage
      ...MeasurementProjectFields
    }
    climateProgramProjects {
      id
      measurementProject {
        id
        name
      }
    }
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${MeasurementProjectFieldsFragmentDoc}`;

export function useCreateMeasurementProjectFromCanonicalClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementProjectFromCanonicalClimateProgramProjectMutation,
    GQTypes.GQCreateMeasurementProjectFromCanonicalClimateProgramProjectMutationVariables
  >(CreateMeasurementProjectFromCanonicalClimateProgramProjectDocument);
}
export const CreateCustomerInitiatedDataIssueDocument = gql`
    mutation CreateCustomerInitiatedDataIssue($input: CreateCustomerInitiatedDataIssueInput!) {
  createCustomerInitiatedDataIssue(input: $input) {
    issue {
      ...DataIssueWithCommentsFields
    }
    measurementProject {
      ...MeasurementProjectForMeasurementPage
    }
    valueMappingTask {
      ...ValueMappingPageValueMappingTask
    }
  }
}
    ${DataIssueWithCommentsFieldsFragmentDoc}
${MeasurementProjectForMeasurementPageFragmentDoc}
${ValueMappingPageValueMappingTaskFragmentDoc}`;

export function useCreateCustomerInitiatedDataIssueMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCustomerInitiatedDataIssueMutation,
    GQTypes.GQCreateCustomerInitiatedDataIssueMutationVariables
  >(CreateCustomerInitiatedDataIssueDocument);
}
export const DeleteClimateProgramProjectDatasetRequirementDocument = gql`
    mutation DeleteClimateProgramProjectDatasetRequirement($input: IdInput!) {
  deleteClimateProgramProjectDatasetRequirement(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectWithMeasurementConcernsFields
    }
  }
}
    ${ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}`;

export function useDeleteClimateProgramProjectDatasetRequirementMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteClimateProgramProjectDatasetRequirementMutation,
    GQTypes.GQDeleteClimateProgramProjectDatasetRequirementMutationVariables
  >(DeleteClimateProgramProjectDatasetRequirementDocument);
}
export const GetUserUploadsByIdFilePreviewDialogDocument = gql`
    query GetUserUploadsByIdFilePreviewDialog($ids: [ID!]!) {
  userUploadsById(ids: $ids) {
    ...UserUploadFieldsForFilePreview
  }
}
    ${UserUploadFieldsForFilePreviewFragmentDoc}`;

export function useGetUserUploadsByIdFilePreviewDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadsByIdFilePreviewDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadsByIdFilePreviewDialogQuery,
    GQTypes.GQGetUserUploadsByIdFilePreviewDialogQueryVariables
  >({ query: GetUserUploadsByIdFilePreviewDialogDocument, ...options });
}
export const UpdateClimateProgramProjectDatasetRequirementDocument = gql`
    mutation UpdateClimateProgramProjectDatasetRequirement($input: UpdateClimateProgramProjectDatasetRequirementInput!) {
  updateClimateProgramProjectDatasetRequirement(input: $input) {
    climateProgramProjectRequirementDataset {
      ...ClimateProgramProjectRequirementDatasetFields
    }
  }
}
    ${ClimateProgramProjectRequirementDatasetFieldsFragmentDoc}`;

export function useUpdateClimateProgramProjectDatasetRequirementMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateClimateProgramProjectDatasetRequirementMutation,
    GQTypes.GQUpdateClimateProgramProjectDatasetRequirementMutationVariables
  >(UpdateClimateProgramProjectDatasetRequirementDocument);
}
export const UserUploadedTableByIdDocument = gql`
    query UserUploadedTableById($id: ID!) {
  userUploadedTableById(id: $id) {
    ...UserUploadedTableForTablePreviewDialog
  }
}
    ${UserUploadedTableForTablePreviewDialogFragmentDoc}`;

export function useUserUploadedTableByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadedTableByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadedTableByIdQuery,
    GQTypes.GQUserUploadedTableByIdQueryVariables
  >({ query: UserUploadedTableByIdDocument, ...options });
}
export const SubmitFacilitiesBulkAdditionDocument = gql`
    mutation SubmitFacilitiesBulkAddition($input: SubmitFacilitiesBulkAdditionInput!) {
  submitFacilitiesBulkAddition(input: $input) {
    numFacilitiesCreated
    org {
      ...OrganizationFacilitiesUntyped
    }
    measurementProject {
      id
      measurementTasks {
        ...MeasurementParentTaskForMeasurementPage
      }
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${OrganizationFacilitiesUntypedFragmentDoc}
${MeasurementParentTaskForMeasurementPageFragmentDoc}
${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useSubmitFacilitiesBulkAdditionMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitFacilitiesBulkAdditionMutation,
    GQTypes.GQSubmitFacilitiesBulkAdditionMutationVariables
  >(SubmitFacilitiesBulkAdditionDocument);
}
export const CreateSingleUploaderFacilitiesTaskDocument = gql`
    mutation CreateSingleUploaderFacilitiesTask($input: CreateSingleUploaderFacilitiesTaskInput!) {
  createSingleUploaderFacilitiesTask(input: $input) {
    task {
      ...FacilitiesUserUploadTask
    }
    measurementProject {
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${FacilitiesUserUploadTaskFragmentDoc}
${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useCreateSingleUploaderFacilitiesTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSingleUploaderFacilitiesTaskMutation,
    GQTypes.GQCreateSingleUploaderFacilitiesTaskMutationVariables
  >(CreateSingleUploaderFacilitiesTaskDocument);
}
export const BulkCreateUploadTasksDocument = gql`
    mutation BulkCreateUploadTasks($input: BulkCreateUploadTasksInput!) {
  bulkCreateUploadTasks(input: $input) {
    success
  }
}
    `;

export function useBulkCreateUploadTasksMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkCreateUploadTasksMutation,
    GQTypes.GQBulkCreateUploadTasksMutationVariables
  >(BulkCreateUploadTasksDocument);
}
export const AssignMeasurementTaskDocument = gql`
    mutation AssignMeasurementTask($input: AssignMeasurementTaskInput!, $permissions: [HasPermissionInput!]) {
  assignMeasurementTask(input: $input) {
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    organization {
      id
      usersWithPermissions: users(
        filters: {withSomePermissions: $permissions, includeWatershedEmployees: true}
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}`;

export function useAssignMeasurementTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQAssignMeasurementTaskMutation,
    GQTypes.GQAssignMeasurementTaskMutationVariables
  >(AssignMeasurementTaskDocument);
}
export const SetApproversDocument = gql`
    mutation SetApprovers($input: SetApproversInput!) {
  setApprovers(input: $input) {
    target {
      id
      approvers {
        ...ApproverFieldsForMeasurementPage
      }
    }
  }
}
    ${ApproverFieldsForMeasurementPageFragmentDoc}`;

export function useSetApproversMutation() {
  return Urql.useMutation<
    GQTypes.GQSetApproversMutation,
    GQTypes.GQSetApproversMutationVariables
  >(SetApproversDocument);
}
export const BatchSetCustomFieldForDatasourcesDocument = gql`
    mutation BatchSetCustomFieldForDatasources($input: BatchSetCustomFieldForDatasourcesInput!) {
  batchSetCustomFieldForDatasources(input: $input) {
    datasources {
      id
      presetCustomTags {
        ...PresetCustomTagForMeasurementPage
      }
    }
  }
}
    ${PresetCustomTagForMeasurementPageFragmentDoc}`;

export function useBatchSetCustomFieldForDatasourcesMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchSetCustomFieldForDatasourcesMutation,
    GQTypes.GQBatchSetCustomFieldForDatasourcesMutationVariables
  >(BatchSetCustomFieldForDatasourcesDocument);
}
export const BatchUnsetCustomFieldForDatasourcesDocument = gql`
    mutation BatchUnsetCustomFieldForDatasources($input: BatchUnsetCustomFieldForDatasourcesInput!) {
  batchUnsetCustomFieldForDatasources(input: $input) {
    datasources {
      id
      presetCustomTags {
        ...PresetCustomTagForMeasurementPage
      }
    }
  }
}
    ${PresetCustomTagForMeasurementPageFragmentDoc}`;

export function useBatchUnsetCustomFieldForDatasourcesMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchUnsetCustomFieldForDatasourcesMutation,
    GQTypes.GQBatchUnsetCustomFieldForDatasourcesMutationVariables
  >(BatchUnsetCustomFieldForDatasourcesDocument);
}
export const DataCollectionCommandPaletteCustomFieldUsedValuesDocument = gql`
    query DataCollectionCommandPaletteCustomFieldUsedValues($id: ID!) {
  ingestionCustomField(id: $id) {
    id
    usedValues
  }
}
    `;

export function useDataCollectionCommandPaletteCustomFieldUsedValuesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataCollectionCommandPaletteCustomFieldUsedValuesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataCollectionCommandPaletteCustomFieldUsedValuesQuery,
    GQTypes.GQDataCollectionCommandPaletteCustomFieldUsedValuesQueryVariables
  >({
    query: DataCollectionCommandPaletteCustomFieldUsedValuesDocument,
    ...options,
  });
}
export const GetCustomEmissionsFactorsTaskDocument = gql`
    query GetCustomEmissionsFactorsTask($measurementProjectId: ID!) {
  customEmissionsFactorsTask(measurementProjectId: $measurementProjectId) {
    id
    statusSimplified
  }
}
    `;

export function useGetCustomEmissionsFactorsTaskQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomEmissionsFactorsTaskQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomEmissionsFactorsTaskQuery,
    GQTypes.GQGetCustomEmissionsFactorsTaskQueryVariables
  >({ query: GetCustomEmissionsFactorsTaskDocument, ...options });
}
export const MethodologyCustomizationOneSchemaTemplateDocument = gql`
    query MethodologyCustomizationOneSchemaTemplate($customizationTypeId: String!) {
  methodologyCustomizationOneSchemaTemplateForType(
    customizationTypeId: $customizationTypeId
  )
}
    `;

export function useMethodologyCustomizationOneSchemaTemplateQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationOneSchemaTemplateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationOneSchemaTemplateQuery,
    GQTypes.GQMethodologyCustomizationOneSchemaTemplateQueryVariables
  >({ query: MethodologyCustomizationOneSchemaTemplateDocument, ...options });
}
export const GenerateCustomerUploadedReferenceDataRevisionDocument = gql`
    mutation GenerateCustomerUploadedReferenceDataRevision($input: GenerateCustomerUploadedReferenceDataRevisionInput!) {
  generateCustomerUploadedReferenceDataRevision(input: $input) {
    jobId
  }
}
    `;

export function useGenerateCustomerUploadedReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateCustomerUploadedReferenceDataRevisionMutation,
    GQTypes.GQGenerateCustomerUploadedReferenceDataRevisionMutationVariables
  >(GenerateCustomerUploadedReferenceDataRevisionDocument);
}
export const CustomEmissionsFactorForDashboardDocument = gql`
    query CustomEmissionsFactorForDashboard($measurementProjectId: ID) {
  customEmissionsFactorsForOrg(measurementProjectId: $measurementProjectId) {
    id
    startDate
    endDate
    emissionsSpecifier
    kgco2e
    unit
    description
    citationUrl
    citationTitle
    citationNotes
  }
}
    `;

export function useCustomEmissionsFactorForDashboardQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomEmissionsFactorForDashboardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomEmissionsFactorForDashboardQuery,
    GQTypes.GQCustomEmissionsFactorForDashboardQueryVariables
  >({ query: CustomEmissionsFactorForDashboardDocument, ...options });
}
export const CreateCustomEmissionsFactorsReferenceDataRevisionDocument = gql`
    mutation CreateCustomEmissionsFactorsReferenceDataRevision($input: CreateCustomEmissionsFactorsReferenceDataRevisionInput!) {
  createCustomEmissionsFactorsReferenceDataRevision(input: $input) {
    jobId
  }
}
    `;

export function useCreateCustomEmissionsFactorsReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCustomEmissionsFactorsReferenceDataRevisionMutation,
    GQTypes.GQCreateCustomEmissionsFactorsReferenceDataRevisionMutationVariables
  >(CreateCustomEmissionsFactorsReferenceDataRevisionDocument);
}
export const ActivityForCustomEmissionsFactorsDocument = gql`
    query ActivityForCustomEmissionsFactors($measurementProjectId: ID!) {
  customEmissionsFactorActivityUserUploadTasks(
    measurementProjectId: $measurementProjectId
  ) {
    ...CustomActivityUserUploadTasksFields
  }
}
    ${CustomActivityUserUploadTasksFieldsFragmentDoc}`;

export function useActivityForCustomEmissionsFactorsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityForCustomEmissionsFactorsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityForCustomEmissionsFactorsQuery,
    GQTypes.GQActivityForCustomEmissionsFactorsQueryVariables
  >({ query: ActivityForCustomEmissionsFactorsDocument, ...options });
}
export const CreateCustomFieldForEditDialogDocument = gql`
    mutation CreateCustomFieldForEditDialog($input: CreateIngestionCustomFieldInput!) {
  createIngestionCustomField(input: $input) {
    ingestionCustomField {
      ...CustomFieldForEditDialog
    }
    organization {
      ...OrganizationCustomFieldsForManagePage
      ...OrganizationCustomFieldsForImporterPage
    }
  }
}
    ${CustomFieldForEditDialogFragmentDoc}
${OrganizationCustomFieldsForManagePageFragmentDoc}
${OrganizationCustomFieldsForImporterPageFragmentDoc}`;

export function useCreateCustomFieldForEditDialogMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCustomFieldForEditDialogMutation,
    GQTypes.GQCreateCustomFieldForEditDialogMutationVariables
  >(CreateCustomFieldForEditDialogDocument);
}
export const EditCustomFieldForEditDialogDocument = gql`
    mutation EditCustomFieldForEditDialog($input: EditIngestionCustomFieldInput!) {
  editIngestionCustomField(input: $input) {
    ingestionCustomField {
      ...CustomFieldForEditDialog
    }
  }
}
    ${CustomFieldForEditDialogFragmentDoc}`;

export function useEditCustomFieldForEditDialogMutation() {
  return Urql.useMutation<
    GQTypes.GQEditCustomFieldForEditDialogMutation,
    GQTypes.GQEditCustomFieldForEditDialogMutationVariables
  >(EditCustomFieldForEditDialogDocument);
}
export const DeleteCustomFieldForEditDialogDocument = gql`
    mutation DeleteCustomFieldForEditDialog($input: DeleteIngestionCustomFieldInput!) {
  deleteIngestionCustomField(input: $input) {
    organization {
      ...OrganizationCustomFieldsForManagePage
      ...OrganizationCustomFieldsForImporterPage
    }
    ingestionCustomField {
      ...CustomFieldForEditDialog
    }
  }
}
    ${OrganizationCustomFieldsForManagePageFragmentDoc}
${OrganizationCustomFieldsForImporterPageFragmentDoc}
${CustomFieldForEditDialogFragmentDoc}`;

export function useDeleteCustomFieldForEditDialogMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCustomFieldForEditDialogMutation,
    GQTypes.GQDeleteCustomFieldForEditDialogMutationVariables
  >(DeleteCustomFieldForEditDialogDocument);
}
export const OrganizationAuditLogsDocument = gql`
    query OrganizationAuditLogs($interval: YMInterval!, $eventKind: String, $after: String, $before: String, $first: Int, $last: Int) {
  auditLogs(
    interval: $interval
    eventKind: $eventKind
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        ...AuditLogEntryFields
      }
    }
    facets {
      eventKinds
    }
  }
}
    ${AuditLogEntryFieldsFragmentDoc}`;

export function useOrganizationAuditLogsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationAuditLogsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationAuditLogsQuery,
    GQTypes.GQOrganizationAuditLogsQueryVariables
  >({ query: OrganizationAuditLogsDocument, ...options });
}
export const AddDatasourceDialogDocument = gql`
    query AddDatasourceDialog($measurementProjectId: ID!) {
  datasets(sortBy: "name") {
    id
    name
    canonicalDataset {
      id
      kind
      selfServeDisabledReason
      canonicalDatasources {
        id
        name
        activeIntegrationConnection {
          id
        }
      }
    }
  }
  measurementProject(id: $measurementProjectId) {
    id
    userUploadTasks {
      edges {
        node {
          id
          datasource {
            id
            dataset {
              id
            }
          }
        }
      }
    }
  }
}
    `;

export function useAddDatasourceDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAddDatasourceDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAddDatasourceDialogQuery,
    GQTypes.GQAddDatasourceDialogQueryVariables
  >({ query: AddDatasourceDialogDocument, ...options });
}
export const CreateDatasourceDocument = gql`
    mutation CreateDatasource($input: CreateDatasourceInput!, $measurementProjectId: ID) {
  createDatasource(input: $input) {
    datasource {
      id
      ...DatasourceFieldsForDatasourcePage
      dataset {
        id
        datasources {
          id
        }
      }
    }
  }
}
    ${DatasourceFieldsForDatasourcePageFragmentDoc}`;

export function useCreateDatasourceMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDatasourceMutation,
    GQTypes.GQCreateDatasourceMutationVariables
  >(CreateDatasourceDocument);
}
export const FacilitiesForAddFacilitiesGridDocument = gql`
    query FacilitiesForAddFacilitiesGrid($measurementProjectId: ID!, $after: String, $before: String, $first: Int, $last: Int, $filter: [FacilitiesFilterInput!], $sort: [FacilitiesSortInput!], $search: String, $canonicalDatasetKind: CanonicalDatasetKind!, $orgStructureEnabled: Boolean!) {
  facilities(
    after: $after
    before: $before
    first: $first
    last: $last
    filter: $filter
    sort: $sort
    search: $search
    measurementProjectId: $measurementProjectId
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FacilitiesForAddFacilitiesGrid
      }
    }
  }
  latestOrgUnitTypesForCanonicalDatasetKind(
    canonicalDatasetKind: $canonicalDatasetKind
  ) @include(if: $orgStructureEnabled) {
    ...OrgUnitTypeWithOrgUnitsForFacilities
  }
}
    ${FacilitiesForAddFacilitiesGridFragmentDoc}
${OrgUnitTypeWithOrgUnitsForFacilitiesFragmentDoc}`;

export function useFacilitiesForAddFacilitiesGridQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesForAddFacilitiesGridQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesForAddFacilitiesGridQuery,
    GQTypes.GQFacilitiesForAddFacilitiesGridQueryVariables
  >({ query: FacilitiesForAddFacilitiesGridDocument, ...options });
}
export const AddFacilitiesGridDocument = gql`
    query AddFacilitiesGrid($measurementProjectId: ID!) {
  organization {
    ...OrganizationForConfigureFacilitiesTasksPage
  }
  measurementProject(id: $measurementProjectId) {
    id
    ...MeasurementProjectForConfigureFacilitiesTasksPage
  }
}
    ${OrganizationForConfigureFacilitiesTasksPageFragmentDoc}
${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}`;

export function useAddFacilitiesGridQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAddFacilitiesGridQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAddFacilitiesGridQuery,
    GQTypes.GQAddFacilitiesGridQueryVariables
  >({ query: AddFacilitiesGridDocument, ...options });
}
export const UnassignFacilitiesFromTaskDocument = gql`
    mutation UnassignFacilitiesFromTask($input: UnassignFacilitiesFromTaskInput!) {
  unassignFacilitiesFromTask(input: $input) {
    measurementProject {
      ...MeasurementProjectForConfigureFacilitiesTasksPage
    }
    facilities {
      ...FacilityFragment
    }
  }
}
    ${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}
${FacilityFragmentFragmentDoc}`;

export function useUnassignFacilitiesFromTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQUnassignFacilitiesFromTaskMutation,
    GQTypes.GQUnassignFacilitiesFromTaskMutationVariables
  >(UnassignFacilitiesFromTaskDocument);
}
export const CustomTransformEditorDocument = gql`
    query CustomTransformEditor {
  activityTypeSchemas {
    ...ActivityTypeSchema
  }
  organization {
    ...OrganizationCustomFieldsForImporterPage
  }
}
    ${ActivityTypeSchemaFragmentDoc}
${OrganizationCustomFieldsForImporterPageFragmentDoc}`;

export function useCustomTransformEditorQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomTransformEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomTransformEditorQuery,
    GQTypes.GQCustomTransformEditorQueryVariables
  >({ query: CustomTransformEditorDocument, ...options });
}
export const PublishCustomTransformForUserUploadDocument = gql`
    mutation PublishCustomTransformForUserUpload($input: PublishCustomTransformForUserUploadInput!) {
  publishCustomTransformForUserUpload(input: $input) {
    jobId
  }
}
    `;

export function usePublishCustomTransformForUserUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishCustomTransformForUserUploadMutation,
    GQTypes.GQPublishCustomTransformForUserUploadMutationVariables
  >(PublishCustomTransformForUserUploadDocument);
}
export const GetCustomerTargetSchemasForCanonicalDatasetDocument = gql`
    query GetCustomerTargetSchemasForCanonicalDataset($id: ID!) {
  canonicalDataset(id: $id) {
    id
    name
    customerTargetSchemas {
      id
      name
      descriptionMd
    }
  }
}
    `;

export function useGetCustomerTargetSchemasForCanonicalDatasetQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomerTargetSchemasForCanonicalDatasetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomerTargetSchemasForCanonicalDatasetQuery,
    GQTypes.GQGetCustomerTargetSchemasForCanonicalDatasetQueryVariables
  >({ query: GetCustomerTargetSchemasForCanonicalDatasetDocument, ...options });
}
export const GetDatasourceSummaryForDatasourcePageDocument = gql`
    query GetDatasourceSummaryForDatasourcePage($datasourceId: ID!) {
  datasource(id: $datasourceId) {
    id
    name
    summaryWithMetadata {
      id
      summaries {
        id
      }
    }
  }
}
    `;

export function useGetDatasourceSummaryForDatasourcePageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDatasourceSummaryForDatasourcePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDatasourceSummaryForDatasourcePageQuery,
    GQTypes.GQGetDatasourceSummaryForDatasourcePageQueryVariables
  >({ query: GetDatasourceSummaryForDatasourcePageDocument, ...options });
}
export const UpdateMeasurementProjectDashboardDocument = gql`
    mutation UpdateMeasurementProjectDashboard($input: UpdateMeasurementProjectInput!) {
  updateMeasurementProjectDashboard(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useUpdateMeasurementProjectDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementProjectDashboardMutation,
    GQTypes.GQUpdateMeasurementProjectDashboardMutationVariables
  >(UpdateMeasurementProjectDashboardDocument);
}
export const MeasurementProjectForEditDocument = gql`
    query MeasurementProjectForEdit($measurementProjectId: ID!) {
  measurementProject(id: $measurementProjectId) {
    id
    canEditCoverageInterval
    coverageInterval
    name
    measurementTimeline {
      id
      kickoff
      deadline
    }
  }
}
    `;

export function useMeasurementProjectForEditQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectForEditQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectForEditQuery,
    GQTypes.GQMeasurementProjectForEditQueryVariables
  >({ query: MeasurementProjectForEditDocument, ...options });
}
export const DeleteFileDocument = gql`
    mutation DeleteFile($fileId: ID!) {
  deleteFile(input: {fileId: $fileId}) {
    file {
      id
    }
    datasource {
      id
      summaryWithMetadata {
        id
        summaries {
          id
          signedUrl
        }
      }
    }
    measurementProject {
      id
      datasetsWithStages {
        id
        dataset {
          id
        }
        areUploadsComplete
        isUsableInFootprint
      }
    }
  }
}
    `;

export function useDeleteFileMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFileMutation,
    GQTypes.GQDeleteFileMutationVariables
  >(DeleteFileDocument);
}
export const CreateOneSchemaSessionForUserUploadDocument = gql`
    mutation CreateOneSchemaSessionForUserUpload($input: CreateOneSchemaSessionForUserUploadInput!) {
  createOneSchemaSessionForUserUpload(input: $input) {
    oneSchemaEmbedId
    oneSchemaEmbedStatus
    oneSchemaEmbedUserJwt
    oneSchemaSessionToken
    signedUrl
    fileMetadataId
    fileName
    userUploadTaskId
    ctsVersionId
  }
}
    `;

export function useCreateOneSchemaSessionForUserUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOneSchemaSessionForUserUploadMutation,
    GQTypes.GQCreateOneSchemaSessionForUserUploadMutationVariables
  >(CreateOneSchemaSessionForUserUploadDocument);
}
export const GetValueMappingRulesForMeasurementProjectDocument = gql`
    query GetValueMappingRulesForMeasurementProject($measurementProjectId: ID!) {
  measurementProject(id: $measurementProjectId) {
    id
    supportedValueMappingRules
  }
}
    `;

export function useGetValueMappingRulesForMeasurementProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingRulesForMeasurementProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingRulesForMeasurementProjectQuery,
    GQTypes.GQGetValueMappingRulesForMeasurementProjectQueryVariables
  >({ query: GetValueMappingRulesForMeasurementProjectDocument, ...options });
}
export const MarkUserUploadTaskAsCompleteDocument = gql`
    mutation MarkUserUploadTaskAsComplete($userUploadTaskId: ID!) {
  completeUserUploadTask(input: {userUploadTaskId: $userUploadTaskId}) {
    userUploadTask {
      id
      state
      userUploads {
        edges {
          node {
            id
            status
            processingMode
          }
        }
      }
      measurementTask {
        id
        statusSimplified
      }
    }
  }
}
    `;

export function useMarkUserUploadTaskAsCompleteMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkUserUploadTaskAsCompleteMutation,
    GQTypes.GQMarkUserUploadTaskAsCompleteMutationVariables
  >(MarkUserUploadTaskAsCompleteDocument);
}
export const BuildingsTaskFacilitiesDocument = gql`
    query BuildingsTaskFacilities($after: String, $before: String, $first: Int, $last: Int, $filter: [FacilitiesFilterInput!]) {
  facilities(
    after: $after
    before: $before
    first: $first
    last: $last
    filter: $filter
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FacilityFragment
      }
    }
  }
}
    ${FacilityFragmentFragmentDoc}`;

export function useBuildingsTaskFacilitiesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingsTaskFacilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingsTaskFacilitiesQuery,
    GQTypes.GQBuildingsTaskFacilitiesQueryVariables
  >({ query: BuildingsTaskFacilitiesDocument, ...options });
}
export const GetUserUploadTaskForDataIssueDocument = gql`
    query GetUserUploadTaskForDataIssue($userUploadTaskId: ID!) {
  userUploadTask(id: $userUploadTaskId) {
    ...UserUploadTaskForDataIssue
  }
}
    ${UserUploadTaskForDataIssueFragmentDoc}`;

export function useGetUserUploadTaskForDataIssueQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadTaskForDataIssueQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadTaskForDataIssueQuery,
    GQTypes.GQGetUserUploadTaskForDataIssueQueryVariables
  >({ query: GetUserUploadTaskForDataIssueDocument, ...options });
}
export const GetValueMappingTaskForDataIssueDocument = gql`
    query GetValueMappingTaskForDataIssue($valueMappingTaskId: ID!) {
  valueMappingTask(id: $valueMappingTaskId) {
    ...ValueMappingTaskForDataIssue
  }
}
    ${ValueMappingTaskForDataIssueFragmentDoc}`;

export function useGetValueMappingTaskForDataIssueQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingTaskForDataIssueQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingTaskForDataIssueQuery,
    GQTypes.GQGetValueMappingTaskForDataIssueQueryVariables
  >({ query: GetValueMappingTaskForDataIssueDocument, ...options });
}
export const CompleteUserUploadTaskDashboardDocument = gql`
    mutation CompleteUserUploadTaskDashboard($input: CompleteUserUploadTaskInput!) {
  completeUserUploadTask(input: $input) {
    userUploadTask {
      id
      state
    }
    measurementProject {
      ...MeasurementProjectFieldsForDatasourcePage
    }
  }
}
    ${MeasurementProjectFieldsForDatasourcePageFragmentDoc}`;

export function useCompleteUserUploadTaskDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQCompleteUserUploadTaskDashboardMutation,
    GQTypes.GQCompleteUserUploadTaskDashboardMutationVariables
  >(CompleteUserUploadTaskDashboardDocument);
}
export const UncompleteUserUploadTaskDashboardDocument = gql`
    mutation UncompleteUserUploadTaskDashboard($input: UncompleteUserUploadTaskInput!) {
  uncompleteUserUploadTask(input: $input) {
    userUploadTask {
      id
      state
    }
    measurementProject {
      ...MeasurementProjectFieldsForDatasourcePage
    }
  }
}
    ${MeasurementProjectFieldsForDatasourcePageFragmentDoc}`;

export function useUncompleteUserUploadTaskDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQUncompleteUserUploadTaskDashboardMutation,
    GQTypes.GQUncompleteUserUploadTaskDashboardMutationVariables
  >(UncompleteUserUploadTaskDashboardDocument);
}
export const GetDatasourceSummaryDocument = gql`
    query GetDatasourceSummary($datasourceId: ID!, $measurementProjectId: ID!) {
  datasource(id: $datasourceId) {
    ...GetDatasourceSummaryFields
  }
  measurementProject(id: $measurementProjectId) {
    id
    coverageInterval
  }
  userHasPermission: userHasViewDatasourcePermission(datasourceId: $datasourceId)
}
    ${GetDatasourceSummaryFieldsFragmentDoc}`;

export function useGetDatasourceSummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDatasourceSummaryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDatasourceSummaryQuery,
    GQTypes.GQGetDatasourceSummaryQueryVariables
  >({ query: GetDatasourceSummaryDocument, ...options });
}
export const GetDatasourceSummaryDetailsDocument = gql`
    query GetDatasourceSummaryDetails($datasourceId: ID!) {
  datasource(id: $datasourceId) {
    id
    summaryWithMetadata {
      id
      legacyUploads {
        id
        name
      }
      unsupportedUploads {
        id
        name
      }
      summaries {
        id
        signedUrl
        title
        bartActivityType
      }
    }
  }
  userHasPermission: userHasViewDatasourcePermission(datasourceId: $datasourceId)
}
    `;

export function useGetDatasourceSummaryDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDatasourceSummaryDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDatasourceSummaryDetailsQuery,
    GQTypes.GQGetDatasourceSummaryDetailsQueryVariables
  >({ query: GetDatasourceSummaryDetailsDocument, ...options });
}
export const UserUploadsByIdDocument = gql`
    query UserUploadsById($ids: [ID!]!) {
  userUploadsById(ids: $ids) {
    id
    userVisibleAttempt {
      id
      ...UserUploadAttemptFieldsWithDataPreview
    }
  }
}
    ${UserUploadAttemptFieldsWithDataPreviewFragmentDoc}`;

export function useUserUploadsByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadsByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadsByIdQuery,
    GQTypes.GQUserUploadsByIdQueryVariables
  >({ query: UserUploadsByIdDocument, ...options });
}
export const UtilitiesTaskFacilitiesDocument = gql`
    query UtilitiesTaskFacilities($after: String, $before: String, $first: Int, $last: Int, $filter: [FacilitiesFilterInput!], $userUploadTaskId: ID!) {
  facilities(
    after: $after
    before: $before
    first: $first
    last: $last
    filter: $filter
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        id
        buildingName
        buildingWithUtilities {
          id
          completionIntervals(userUploadTaskId: $userUploadTaskId) {
            id
            data {
              utilityType
              fuelKind
              periodsWithActuals {
                start
                end
              }
              periodsWithSupportingDocs {
                start
                end
              }
            }
          }
        }
      }
    }
  }
}
    `;

export function useUtilitiesTaskFacilitiesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUtilitiesTaskFacilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUtilitiesTaskFacilitiesQuery,
    GQTypes.GQUtilitiesTaskFacilitiesQueryVariables
  >({ query: UtilitiesTaskFacilitiesDocument, ...options });
}
export const ApproveDataDocument = gql`
    mutation ApproveData($input: ApproveDataInput!) {
  approveData(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useApproveDataMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveDataMutation,
    GQTypes.GQApproveDataMutationVariables
  >(ApproveDataDocument);
}
export const RejectDataDocument = gql`
    mutation RejectData($input: RejectDataInput!) {
  rejectData(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useRejectDataMutation() {
  return Urql.useMutation<
    GQTypes.GQRejectDataMutation,
    GQTypes.GQRejectDataMutationVariables
  >(RejectDataDocument);
}
export const RequestDataApprovalDocument = gql`
    mutation RequestDataApproval($input: RequestDataApprovalInput!) {
  requestDataApproval(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useRequestDataApprovalMutation() {
  return Urql.useMutation<
    GQTypes.GQRequestDataApprovalMutation,
    GQTypes.GQRequestDataApprovalMutationVariables
  >(RequestDataApprovalDocument);
}
export const DataApprovalUserUploadTaskChangelogDocument = gql`
    query DataApprovalUserUploadTaskChangelog($input: DataApprovalUserUploadTaskChangelogInput!) {
  dataApprovalUserUploadTaskChangelog(input: $input) {
    changelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ChangelogEventPartsFragmentDoc}`;

export function useDataApprovalUserUploadTaskChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataApprovalUserUploadTaskChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataApprovalUserUploadTaskChangelogQuery,
    GQTypes.GQDataApprovalUserUploadTaskChangelogQueryVariables
  >({ query: DataApprovalUserUploadTaskChangelogDocument, ...options });
}
export const DataApprovalObjectChangelogDocument = gql`
    query DataApprovalObjectChangelog($input: DataApprovalObjectChangelogInput!) {
  dataApprovalObjectChangelog(input: $input) {
    changelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ChangelogEventPartsFragmentDoc}`;

export function useDataApprovalObjectChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataApprovalObjectChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataApprovalObjectChangelogQuery,
    GQTypes.GQDataApprovalObjectChangelogQueryVariables
  >({ query: DataApprovalObjectChangelogDocument, ...options });
}
export const DataApprovalMeasurementProjectChangelogDocument = gql`
    query DataApprovalMeasurementProjectChangelog($input: DataApprovalMeasurementProjectChangelogInput!) {
  dataApprovalMeasurementProjectChangelog(input: $input) {
    changelogEvents {
      ...ChangelogEventParts
      datasourceName
    }
  }
}
    ${ChangelogEventPartsFragmentDoc}`;

export function useDataApprovalMeasurementProjectChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataApprovalMeasurementProjectChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataApprovalMeasurementProjectChangelogQuery,
    GQTypes.GQDataApprovalMeasurementProjectChangelogQueryVariables
  >({ query: DataApprovalMeasurementProjectChangelogDocument, ...options });
}
export const AddDataApprovalCommentDocument = gql`
    mutation AddDataApprovalComment($input: AddDataApprovalCommentInput!) {
  addDataApprovalComment(input: $input) {
    dataIssue {
      id
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ChangelogEventPartsFragmentDoc}`;

export function useAddDataApprovalCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQAddDataApprovalCommentMutation,
    GQTypes.GQAddDataApprovalCommentMutationVariables
  >(AddDataApprovalCommentDocument);
}
export const UnlockDataDocument = gql`
    mutation UnlockData($input: UnlockDataInput!) {
  unlockData(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useUnlockDataMutation() {
  return Urql.useMutation<
    GQTypes.GQUnlockDataMutation,
    GQTypes.GQUnlockDataMutationVariables
  >(UnlockDataDocument);
}
export const RequestUnlockDataDocument = gql`
    mutation RequestUnlockData($input: RequestUnlockDataInput!) {
  requestUnlockData(input: $input) {
    target {
      ...ApprovalTargetFieldsForChangingStatus
    }
    newChangelogEvents {
      ...ChangelogEventParts
    }
  }
}
    ${ApprovalTargetFieldsForChangingStatusFragmentDoc}
${ChangelogEventPartsFragmentDoc}`;

export function useRequestUnlockDataMutation() {
  return Urql.useMutation<
    GQTypes.GQRequestUnlockDataMutation,
    GQTypes.GQRequestUnlockDataMutationVariables
  >(RequestUnlockDataDocument);
}
export const GetAllBuildingNamesDocument = gql`
    query GetAllBuildingNames {
  organization {
    id
    buildingNames
  }
}
    `;

export function useGetAllBuildingNamesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllBuildingNamesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllBuildingNamesQuery,
    GQTypes.GQGetAllBuildingNamesQueryVariables
  >({ query: GetAllBuildingNamesDocument, ...options });
}
export const CreateBuildingDocument = gql`
    mutation CreateBuilding($input: CreateBuildingInput!) {
  createBuilding(input: $input) {
    updatedUserUploadTasks {
      ...UserUploadTaskFieldsForBuildingMutationResponse
    }
    organization {
      ...OrganizationWithIngestionCustomFields
      ...OrganizationFacilitiesUntyped
    }
    measurementProject {
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc}
${OrganizationWithIngestionCustomFieldsFragmentDoc}
${OrganizationFacilitiesUntypedFragmentDoc}
${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useCreateBuildingMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBuildingMutation,
    GQTypes.GQCreateBuildingMutationVariables
  >(CreateBuildingDocument);
}
export const UpdateBuildingDocument = gql`
    mutation UpdateBuilding($input: UpdateBuildingInput!) {
  updateBuilding(input: $input) {
    buildingUntyped {
      id
      data
    }
    facility {
      ...FacilityFragment
    }
    updatedUserUploadTasks {
      ...UserUploadTaskFieldsForBuildingMutationResponse
    }
    organization {
      ...OrganizationWithIngestionCustomFields
    }
  }
}
    ${FacilityFragmentFragmentDoc}
${UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc}
${OrganizationWithIngestionCustomFieldsFragmentDoc}`;

export function useUpdateBuildingMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBuildingMutation,
    GQTypes.GQUpdateBuildingMutationVariables
  >(UpdateBuildingDocument);
}
export const UpdateBuildingUtilityDocument = gql`
    mutation UpdateBuildingUtility($input: UpdateBuildingUtilityInput!) {
  updateBuildingUtility(input: $input) {
    buildingUtility {
      ...BuildingUtilityFieldsForUtilitiesGridPage
    }
    buildingWithUtilities {
      id
      completionIntervals(separateOnsiteCleanElectricity: true) {
        ...CompletionIntervalFields
      }
    }
    updatedUserUploadTask {
      ...UserUploadTaskForUtilitiesMutationResponse
    }
  }
}
    ${BuildingUtilityFieldsForUtilitiesGridPageFragmentDoc}
${CompletionIntervalFieldsFragmentDoc}
${UserUploadTaskForUtilitiesMutationResponseFragmentDoc}`;

export function useUpdateBuildingUtilityMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBuildingUtilityMutation,
    GQTypes.GQUpdateBuildingUtilityMutationVariables
  >(UpdateBuildingUtilityDocument);
}
export const CreateBuildingUtilityDocument = gql`
    mutation CreateBuildingUtility($input: CreateBuildingUtilityInput!, $utilityType: BuildingUtilityType, $sortModel: [DataGridSortItemInput!], $before: String, $after: String, $first: Int, $last: Int) {
  createBuildingUtility(input: $input) {
    buildingUtility {
      id
    }
    buildingWithUtilities {
      id
      utilitiesPaginated(
        utilityType: $utilityType
        sortModel: $sortModel
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        ...BuildingUtilityConnectionFrago
      }
      completionIntervals(separateOnsiteCleanElectricity: true) {
        ...CompletionIntervalFields
      }
    }
    buildingUntyped {
      id
      data
    }
    updatedUserUploadTask {
      ...UserUploadTaskForUtilitiesMutationResponse
    }
  }
}
    ${BuildingUtilityConnectionFragoFragmentDoc}
${CompletionIntervalFieldsFragmentDoc}
${UserUploadTaskForUtilitiesMutationResponseFragmentDoc}`;

export function useCreateBuildingUtilityMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBuildingUtilityMutation,
    GQTypes.GQCreateBuildingUtilityMutationVariables
  >(CreateBuildingUtilityDocument);
}
export const DeleteBuildingUtilityDocument = gql`
    mutation DeleteBuildingUtility($input: DeleteBuildingUtilityInput!, $utilityType: BuildingUtilityType, $sortModel: [DataGridSortItemInput!], $before: String, $after: String, $first: Int, $last: Int) {
  deleteBuildingUtility(input: $input) {
    buildingWithUtilities {
      id
      utilitiesPaginated(
        utilityType: $utilityType
        sortModel: $sortModel
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        ...BuildingUtilityConnectionFrago
      }
      completionIntervals(separateOnsiteCleanElectricity: true) {
        ...CompletionIntervalFields
      }
    }
    buildingUntyped {
      id
      data
    }
    updatedUserUploadTask {
      ...UserUploadTaskForUtilitiesMutationResponse
    }
  }
}
    ${BuildingUtilityConnectionFragoFragmentDoc}
${CompletionIntervalFieldsFragmentDoc}
${UserUploadTaskForUtilitiesMutationResponseFragmentDoc}`;

export function useDeleteBuildingUtilityMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBuildingUtilityMutation,
    GQTypes.GQDeleteBuildingUtilityMutationVariables
  >(DeleteBuildingUtilityDocument);
}
export const BuildingUtilitiesDialogDocument = gql`
    query BuildingUtilitiesDialog($buildingId: ID!) {
  buildingUntyped(id: $buildingId) {
    id
    data
  }
  buildingWithUtilities(id: $buildingId) {
    id
    completionIntervals(separateOnsiteCleanElectricity: true) {
      ...CompletionIntervalFields
    }
  }
}
    ${CompletionIntervalFieldsFragmentDoc}`;

export function useBuildingUtilitiesDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingUtilitiesDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingUtilitiesDialogQuery,
    GQTypes.GQBuildingUtilitiesDialogQueryVariables
  >({ query: BuildingUtilitiesDialogDocument, ...options });
}
export const PaginatedUtilityRowsDocument = gql`
    query PaginatedUtilityRows($buildingId: ID!, $utilityType: BuildingUtilityType, $sortModel: [DataGridSortItemInput!], $before: String, $after: String, $first: Int, $last: Int, $userUploadTaskId: ID) {
  buildingWithUtilities(id: $buildingId) {
    id
    utilitiesPaginated(
      utilityType: $utilityType
      userUploadTaskId: $userUploadTaskId
      sortModel: $sortModel
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      ...BuildingUtilityConnectionFrago
    }
  }
}
    ${BuildingUtilityConnectionFragoFragmentDoc}`;

export function usePaginatedUtilityRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPaginatedUtilityRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPaginatedUtilityRowsQuery,
    GQTypes.GQPaginatedUtilityRowsQueryVariables
  >({ query: PaginatedUtilityRowsDocument, ...options });
}
export const SubmitBuildingsDocument = gql`
    mutation SubmitBuildings($input: SubmitFacilitiesInput!) {
  submitBuildings(input: $input) {
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    submittedUserUploadTask {
      ...UserUploadTaskFieldsForBuildingMutationResponse
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}
${UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc}`;

export function useSubmitBuildingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitBuildingsMutation,
    GQTypes.GQSubmitBuildingsMutationVariables
  >(SubmitBuildingsDocument);
}
export const SubmitUtilitiesDocument = gql`
    mutation SubmitUtilities($input: SubmitFacilitiesInput!) {
  submitUtilities(input: $input) {
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    submittedUserUploadTask {
      ...UserUploadTaskFieldsForBuildingMutationResponse
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}
${UserUploadTaskFieldsForBuildingMutationResponseFragmentDoc}`;

export function useSubmitUtilitiesMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitUtilitiesMutation,
    GQTypes.GQSubmitUtilitiesMutationVariables
  >(SubmitUtilitiesDocument);
}
export const GetBuildingSupportingDocumentsDocument = gql`
    query GetBuildingSupportingDocuments($id: ID!) {
  buildingUntyped(id: $id) {
    id
    data
  }
}
    `;

export function useGetBuildingSupportingDocumentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBuildingSupportingDocumentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBuildingSupportingDocumentsQuery,
    GQTypes.GQGetBuildingSupportingDocumentsQueryVariables
  >({ query: GetBuildingSupportingDocumentsDocument, ...options });
}
export const CreateBuildingSupportingDocumentsDocument = gql`
    mutation CreateBuildingSupportingDocuments($input: CreateSupportingDocumentsInput!) {
  createSupportingDocuments(input: $input) {
    supportingDocuments {
      ...BuildingFormSupportingDocument
    }
    buildingUntyped {
      id
      data
    }
    files {
      url
      fileId
      filename
    }
    buildingUtility {
      id
      supportingDocuments {
        ...BuildingFormSupportingDocument
      }
    }
  }
}
    ${BuildingFormSupportingDocumentFragmentDoc}`;

export function useCreateBuildingSupportingDocumentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateBuildingSupportingDocumentsMutation,
    GQTypes.GQCreateBuildingSupportingDocumentsMutationVariables
  >(CreateBuildingSupportingDocumentsDocument);
}
export const DeleteBuildingSupportingDocumentDocument = gql`
    mutation DeleteBuildingSupportingDocument($input: DeleteSupportingDocumentInput!) {
  deleteSupportingDocument(input: $input) {
    buildingUntyped {
      id
      data
    }
    supportingDocument {
      id
    }
  }
}
    `;

export function useDeleteBuildingSupportingDocumentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBuildingSupportingDocumentMutation,
    GQTypes.GQDeleteBuildingSupportingDocumentMutationVariables
  >(DeleteBuildingSupportingDocumentDocument);
}
export const CustomerTargetSchemaVersionForDirectEntryDocument = gql`
    query CustomerTargetSchemaVersionForDirectEntry($customerTargetSchemaId: ID!, $ctsVersionId: ID!, $datasetId: ID!, $datasourceId: ID, $measurementProjectId: ID, $buildingId: ID) {
  customerTargetSchemaVersion(
    customerTargetSchemaId: $customerTargetSchemaId
    ctsVersionId: $ctsVersionId
  ) {
    id
    orgSchemaJson(
      datasetId: $datasetId
      datasourceId: $datasourceId
      measurementProjectId: $measurementProjectId
      buildingId: $buildingId
    )
  }
}
    `;

export function useCustomerTargetSchemaVersionForDirectEntryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomerTargetSchemaVersionForDirectEntryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomerTargetSchemaVersionForDirectEntryQuery,
    GQTypes.GQCustomerTargetSchemaVersionForDirectEntryQueryVariables
  >({ query: CustomerTargetSchemaVersionForDirectEntryDocument, ...options });
}
export const CreateAndSubmitUserUploadCandidateDocument = gql`
    mutation CreateAndSubmitUserUploadCandidate($input: CreateAndSubmitUserUploadCandidateInput!) {
  createAndSubmitUserUploadCandidate(input: $input) {
    userUploadCandidate {
      id
      errorCount
      errorData
    }
    userUploadCandidateSubmission {
      id
      processFileWorkflowId
    }
  }
}
    `;

export function useCreateAndSubmitUserUploadCandidateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAndSubmitUserUploadCandidateMutation,
    GQTypes.GQCreateAndSubmitUserUploadCandidateMutationVariables
  >(CreateAndSubmitUserUploadCandidateDocument);
}
export const FacilitiesForDirectEntryDialogDocument = gql`
    query FacilitiesForDirectEntryDialog($measurementProjectId: ID!, $filter: [FacilitiesFilterInput!], $utilityType: BuildingUtilityType, $after: String, $before: String, $first: Int, $last: Int, $orgId: ID!) {
  facilities(filter: $filter, measurementProjectId: $measurementProjectId) {
    edges {
      node {
        ...FacilitiesWithUtilities
      }
    }
  }
  measurementProject(id: $measurementProjectId) {
    id
    coverageInterval
  }
  organization(id: $orgId) {
    id
    ingestionCustomFields {
      ...CustomColumnsForDirectEntry
    }
  }
}
    ${FacilitiesWithUtilitiesFragmentDoc}
${CustomColumnsForDirectEntryFragmentDoc}`;

export function useFacilitiesForDirectEntryDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesForDirectEntryDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesForDirectEntryDialogQuery,
    GQTypes.GQFacilitiesForDirectEntryDialogQueryVariables
  >({ query: FacilitiesForDirectEntryDialogDocument, ...options });
}
export const GenerateTemplateDownloadUrlDocument = gql`
    mutation GenerateTemplateDownloadUrl($input: GenerateTemplateDownloadUrlForDatasetInput!) {
  generateTemplateDownloadUrlForDataset(input: $input) {
    templateDownloadUrl
  }
}
    `;

export function useGenerateTemplateDownloadUrlMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateTemplateDownloadUrlMutation,
    GQTypes.GQGenerateTemplateDownloadUrlMutationVariables
  >(GenerateTemplateDownloadUrlDocument);
}
export const UpdateUserIngestionReviewDocument = gql`
    mutation UpdateUserIngestionReview($input: UpdateUserIngestionReviewInput!) {
  updateUserIngestionReview(input: $input) {
    userUploadedTable {
      id
      ...UserUploadedTableWithDataPreview
    }
  }
}
    ${UserUploadedTableWithDataPreviewFragmentDoc}`;

export function useUpdateUserIngestionReviewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserIngestionReviewMutation,
    GQTypes.GQUpdateUserIngestionReviewMutationVariables
  >(UpdateUserIngestionReviewDocument);
}
export const UpdateUserUploadDocument = gql`
    mutation UpdateUserUpload($fileId: ID!, $description: String) {
  updateUserUpload(input: {fileId: $fileId, description: $description}) {
    userUpload {
      id
      description
    }
  }
}
    `;

export function useUpdateUserUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserUploadMutation,
    GQTypes.GQUpdateUserUploadMutationVariables
  >(UpdateUserUploadDocument);
}
export const SubmitEarlyUploadHelpRequestDocument = gql`
    mutation SubmitEarlyUploadHelpRequest($input: SubmitEarlyUploadHelpRequestInput!, $measurementProjectId: ID) {
  submitEarlyUploadHelpRequest(input: $input) {
    userUploads {
      id
      userUploadTask {
        ...UserUploadTaskWithDatasource
      }
    }
  }
}
    ${UserUploadTaskWithDatasourceFragmentDoc}`;

export function useSubmitEarlyUploadHelpRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitEarlyUploadHelpRequestMutation,
    GQTypes.GQSubmitEarlyUploadHelpRequestMutationVariables
  >(SubmitEarlyUploadHelpRequestDocument);
}
export const ImportGetUserUploadsByIdsDocument = gql`
    query ImportGetUserUploadsByIds($userUploadIds: [ID!]!) {
  userUploadsById(ids: $userUploadIds) {
    ...ImportImport_UserUpload
    userUploadTask {
      ...UserUploadTaskStatus
    }
  }
}
    ${ImportImport_UserUploadFragmentDoc}
${UserUploadTaskStatusFragmentDoc}`;

export function useImportGetUserUploadsByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQImportGetUserUploadsByIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQImportGetUserUploadsByIdsQuery,
    GQTypes.GQImportGetUserUploadsByIdsQueryVariables
  >({ query: ImportGetUserUploadsByIdsDocument, ...options });
}
export const ImportCreateUserUploadsDocument = gql`
    mutation ImportCreateUserUploads($input: CreateUserUploadsInput!) {
  createUserUploads(input: $input) {
    files {
      url
      fileId
      filename
    }
    userUploads {
      ...ImportImport_UserUpload
    }
    failedToUpload {
      filename
      reason
    }
  }
}
    ${ImportImport_UserUploadFragmentDoc}`;

export function useImportCreateUserUploadsMutation() {
  return Urql.useMutation<
    GQTypes.GQImportCreateUserUploadsMutation,
    GQTypes.GQImportCreateUserUploadsMutationVariables
  >(ImportCreateUserUploadsDocument);
}
export const ImportDeleteFileDocument = gql`
    mutation ImportDeleteFile($fileId: ID!) {
  deleteFile(input: {fileId: $fileId}) {
    userUploadTask {
      ...UserUploadTaskStatus
    }
  }
}
    ${UserUploadTaskStatusFragmentDoc}`;

export function useImportDeleteFileMutation() {
  return Urql.useMutation<
    GQTypes.GQImportDeleteFileMutation,
    GQTypes.GQImportDeleteFileMutationVariables
  >(ImportDeleteFileDocument);
}
export const SetUserUploadedTableSheetsNotIngestedDocument = gql`
    mutation SetUserUploadedTableSheetsNotIngested($input: SetUserUploadedTableSheetsNotIngestedInput!) {
  setUserUploadedTableSheetsNotIngested(input: $input) {
    userUploadAttempt {
      id
      userUploadedTables {
        id
      }
    }
  }
}
    `;

export function useSetUserUploadedTableSheetsNotIngestedMutation() {
  return Urql.useMutation<
    GQTypes.GQSetUserUploadedTableSheetsNotIngestedMutation,
    GQTypes.GQSetUserUploadedTableSheetsNotIngestedMutationVariables
  >(SetUserUploadedTableSheetsNotIngestedDocument);
}
export const ImportSetupDocument = gql`
    query ImportSetup($datasetId: ID!) {
  dataset(id: $datasetId) {
    id
    name
    canonicalDataset {
      id
      canonicalDatasources {
        id
        name
        activeIntegrationConnection {
          id
        }
      }
    }
  }
}
    `;

export function useImportSetupQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQImportSetupQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQImportSetupQuery,
    GQTypes.GQImportSetupQueryVariables
  >({ query: ImportSetupDocument, ...options });
}
export const ImportGetSharedFilesDocument = gql`
    query ImportGetSharedFiles($datasourceId: ID!, $currentUserId: ID!) {
  datasource(id: $datasourceId) {
    id
    sharedFiles(forUserId: $currentUserId) {
      edges {
        node {
          ...SharedFileForImportImport
        }
      }
    }
  }
}
    ${SharedFileForImportImportFragmentDoc}`;

export function useImportGetSharedFilesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQImportGetSharedFilesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQImportGetSharedFilesQuery,
    GQTypes.GQImportGetSharedFilesQueryVariables
  >({ query: ImportGetSharedFilesDocument, ...options });
}
export const ImportMarkUserUploadAsProcessingDocument = gql`
    mutation ImportMarkUserUploadAsProcessing($fileId: ID!, $shouldSubmitUserUploadTask: Boolean!) {
  markUserUploadAsProcessing(
    input: {fileId: $fileId, shouldSubmitUserUploadTask: $shouldSubmitUserUploadTask}
  ) {
    userUpload {
      id
      status
    }
  }
}
    `;

export function useImportMarkUserUploadAsProcessingMutation() {
  return Urql.useMutation<
    GQTypes.GQImportMarkUserUploadAsProcessingMutation,
    GQTypes.GQImportMarkUserUploadAsProcessingMutationVariables
  >(ImportMarkUserUploadAsProcessingDocument);
}
export const GenerateBartFromFileUploadDocument = gql`
    mutation GenerateBartFromFileUpload($input: GenerateBartFromFileUploadInput!) {
  generateBartFromFileUpload(input: $input) {
    jobId
    userUploadTask {
      ...UserUploadTaskFieldsForDatasourcePage
    }
  }
}
    ${UserUploadTaskFieldsForDatasourcePageFragmentDoc}`;

export function useGenerateBartFromFileUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateBartFromFileUploadMutation,
    GQTypes.GQGenerateBartFromFileUploadMutationVariables
  >(GenerateBartFromFileUploadDocument);
}
export const SampledFinancialAccountIdsDocument = gql`
    query SampledFinancialAccountIds {
  sampledFinancialAccountIds
}
    `;

export function useSampledFinancialAccountIdsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSampledFinancialAccountIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSampledFinancialAccountIdsQuery,
    GQTypes.GQSampledFinancialAccountIdsQueryVariables
  >({ query: SampledFinancialAccountIdsDocument, ...options });
}
export const CustomEmissionsSpecifiersForDashboardDocument = gql`
    query CustomEmissionsSpecifiersForDashboard($measurementProjectId: ID!) {
  customEmissionsSpecifiersForOrg(measurementProjectId: $measurementProjectId)
}
    `;

export function useCustomEmissionsSpecifiersForDashboardQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomEmissionsSpecifiersForDashboardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomEmissionsSpecifiersForDashboardQuery,
    GQTypes.GQCustomEmissionsSpecifiersForDashboardQueryVariables
  >({ query: CustomEmissionsSpecifiersForDashboardDocument, ...options });
}
export const GetOneSchemaEmbedStatusDocument = gql`
    query GetOneSchemaEmbedStatus($embedId: String!) {
  getOneSchemaEmbedStatus(embedId: $embedId)
}
    `;

export function useGetOneSchemaEmbedStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOneSchemaEmbedStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOneSchemaEmbedStatusQuery,
    GQTypes.GQGetOneSchemaEmbedStatusQueryVariables
  >({ query: GetOneSchemaEmbedStatusDocument, ...options });
}
export const SaveIncompleteOneSchemaImportDocument = gql`
    mutation SaveIncompleteOneSchemaImport($input: SaveIncompleteOneSchemaImportInput!) {
  saveIncompleteOneSchemaImport(input: $input) {
    jobId
    userUploadTask {
      ...UserUploadTaskFieldsForDatasourcePage
    }
  }
}
    ${UserUploadTaskFieldsForDatasourcePageFragmentDoc}`;

export function useSaveIncompleteOneSchemaImportMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveIncompleteOneSchemaImportMutation,
    GQTypes.GQSaveIncompleteOneSchemaImportMutationVariables
  >(SaveIncompleteOneSchemaImportDocument);
}
export const CreateUtilityDocsDocument = gql`
    mutation CreateUtilityDocs($input: CreateUtilityDocsInput!) {
  createUtilityDocs(input: $input) {
    supportingDocuments {
      ...BuildingFormSupportingDocument
    }
    files {
      url
      fileId
      filename
    }
    failedToUpload {
      filename
      reason
    }
    candidateIds
  }
}
    ${BuildingFormSupportingDocumentFragmentDoc}`;

export function useCreateUtilityDocsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUtilityDocsMutation,
    GQTypes.GQCreateUtilityDocsMutationVariables
  >(CreateUtilityDocsDocument);
}
export const ExecuteExtractSchemasDocument = gql`
    mutation ExecuteExtractSchemas($input: ExecuteExtractSchemasInput!) {
  executeExtractSchemas(input: $input) {
    success
  }
}
    `;

export function useExecuteExtractSchemasMutation() {
  return Urql.useMutation<
    GQTypes.GQExecuteExtractSchemasMutation,
    GQTypes.GQExecuteExtractSchemasMutationVariables
  >(ExecuteExtractSchemasDocument);
}
export const GetUserUploadCandidatesDocument = gql`
    query getUserUploadCandidates($userUploadCandidateIds: [ID!]!) {
  userUploadCandidates(userUploadCandidateIds: $userUploadCandidateIds) {
    candidateEntries {
      ...UserUploadCandidateFields
    }
  }
  documentCandidates(userUploadCandidateIds: $userUploadCandidateIds) {
    documentEntries {
      ...DocumentCandidateFields
    }
  }
}
    ${UserUploadCandidateFieldsFragmentDoc}
${DocumentCandidateFieldsFragmentDoc}`;

export function useGetUserUploadCandidatesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadCandidatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadCandidatesQuery,
    GQTypes.GQGetUserUploadCandidatesQueryVariables
  >({ query: GetUserUploadCandidatesDocument, ...options });
}
export const CreateCandidateRowEntryDocument = gql`
    mutation createCandidateRowEntry($input: CreateCandidateRowInput!) {
  createCandidateRowEntry(input: $input) {
    ...UserUploadCandidateFields
  }
}
    ${UserUploadCandidateFieldsFragmentDoc}`;

export function useCreateCandidateRowEntryMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCandidateRowEntryMutation,
    GQTypes.GQCreateCandidateRowEntryMutationVariables
  >(CreateCandidateRowEntryDocument);
}
export const UpdateCandidateRowEntryDocument = gql`
    mutation updateCandidateRowEntry($input: UpdateCandidateRowInput!) {
  updateCandidateRowEntry(input: $input) {
    ...UserUploadCandidateFields
  }
}
    ${UserUploadCandidateFieldsFragmentDoc}`;

export function useUpdateCandidateRowEntryMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCandidateRowEntryMutation,
    GQTypes.GQUpdateCandidateRowEntryMutationVariables
  >(UpdateCandidateRowEntryDocument);
}
export const DeleteCandidateRowEntryDocument = gql`
    mutation deleteCandidateRowEntry($input: DeleteCandidateRowInput!) {
  deleteCandidateRowEntry(input: $input) {
    ...UserUploadCandidateFields
  }
}
    ${UserUploadCandidateFieldsFragmentDoc}`;

export function useDeleteCandidateRowEntryMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCandidateRowEntryMutation,
    GQTypes.GQDeleteCandidateRowEntryMutationVariables
  >(DeleteCandidateRowEntryDocument);
}
export const ConfirmCandidateDocument = gql`
    mutation confirmCandidate($input: ConfirmUtilitySchemaInput!) {
  confirmUtilitySchema(input: $input) {
    success
    error
  }
}
    `;

export function useConfirmCandidateMutation() {
  return Urql.useMutation<
    GQTypes.GQConfirmCandidateMutation,
    GQTypes.GQConfirmCandidateMutationVariables
  >(ConfirmCandidateDocument);
}
export const DeleteUserUploadCandidateAndRowsDocument = gql`
    mutation deleteUserUploadCandidateAndRows($input: DeleteUserUploadCandidateAndRowsInput!) {
  deleteUserUploadCandidateAndRows(input: $input) {
    id
  }
}
    `;

export function useDeleteUserUploadCandidateAndRowsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteUserUploadCandidateAndRowsMutation,
    GQTypes.GQDeleteUserUploadCandidateAndRowsMutationVariables
  >(DeleteUserUploadCandidateAndRowsDocument);
}
export const ProductPreviewBartBuildingsPushDocument = gql`
    query ProductPreviewBartBuildingsPush($activityDataExtractorId: ID!) {
  previewCadtBuildingsPush(activityDataExtractorId: $activityDataExtractorId) {
    overwriteDuplicatesDiffUntyped
    mergePeriodsDiffUntyped
    customFields {
      ...CustomFieldForBuldingDiff
    }
    orgUnitTypes {
      ...OrgUnitTypeForBuildingDiff
    }
    orgUnits {
      ...OrgUnitForBuildingDiff
    }
  }
}
    ${CustomFieldForBuldingDiffFragmentDoc}
${OrgUnitTypeForBuildingDiffFragmentDoc}
${OrgUnitForBuildingDiffFragmentDoc}`;

export function useProductPreviewBartBuildingsPushQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQProductPreviewBartBuildingsPushQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQProductPreviewBartBuildingsPushQuery,
    GQTypes.GQProductPreviewBartBuildingsPushQueryVariables
  >({ query: ProductPreviewBartBuildingsPushDocument, ...options });
}
export const PushBartBuildingDocument = gql`
    mutation PushBartBuilding($input: PushBartBuildingInput!) {
  pushBartBuilding(input: $input) {
    workflowId
  }
}
    `;

export function usePushBartBuildingMutation() {
  return Urql.useMutation<
    GQTypes.GQPushBartBuildingMutation,
    GQTypes.GQPushBartBuildingMutationVariables
  >(PushBartBuildingDocument);
}
export const GetBuildingTemplateDataForTaskDocument = gql`
    query GetBuildingTemplateDataForTask($userUploadTaskId: ID) {
  buildingTemplateDataUntyped(userUploadTaskId: $userUploadTaskId)
}
    `;

export function useGetBuildingTemplateDataForTaskQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBuildingTemplateDataForTaskQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBuildingTemplateDataForTaskQuery,
    GQTypes.GQGetBuildingTemplateDataForTaskQueryVariables
  >({ query: GetBuildingTemplateDataForTaskDocument, ...options });
}
export const PushCtsVersionToOneSchemaInProductDocument = gql`
    mutation PushCtsVersionToOneSchemaInProduct($input: PushCtsVersionToOneSchemaInProductInput!) {
  pushCtsVersionToOneSchemaInProduct(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function usePushCtsVersionToOneSchemaInProductMutation() {
  return Urql.useMutation<
    GQTypes.GQPushCtsVersionToOneSchemaInProductMutation,
    GQTypes.GQPushCtsVersionToOneSchemaInProductMutationVariables
  >(PushCtsVersionToOneSchemaInProductDocument);
}
export const PreviewUtilitiesDiffDocument = gql`
    mutation PreviewUtilitiesDiff($input: PreviewUtilitiesDiffInput!) {
  previewUtilitiesDiff(input: $input) {
    workflowId
  }
}
    `;

export function usePreviewUtilitiesDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQPreviewUtilitiesDiffMutation,
    GQTypes.GQPreviewUtilitiesDiffMutationVariables
  >(PreviewUtilitiesDiffDocument);
}
export const PushUtilitiesDiffDocument = gql`
    mutation PushUtilitiesDiff($input: PushUtilitiesDiffInput!) {
  pushUtilitiesDiff(input: $input) {
    workflowId
  }
}
    `;

export function usePushUtilitiesDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQPushUtilitiesDiffMutation,
    GQTypes.GQPushUtilitiesDiffMutationVariables
  >(PushUtilitiesDiffDocument);
}
export const GetValueMappingTaskPublishStatusDocument = gql`
    query GetValueMappingTaskPublishStatus($id: ID!) {
  valueMappingTask(id: $id) {
    id
    publishStatus
  }
}
    `;

export function useGetValueMappingTaskPublishStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetValueMappingTaskPublishStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetValueMappingTaskPublishStatusQuery,
    GQTypes.GQGetValueMappingTaskPublishStatusQueryVariables
  >({ query: GetValueMappingTaskPublishStatusDocument, ...options });
}
export const FinancialsReviewItemDetailPageDocument = gql`
    query FinancialsReviewItemDetailPage($reviewItemId: ID!, $measurementProjectId: ID!) {
  userHasPermission: userHasManageFinancialsPermission
  financialsReviewItems(measurementProjectId: $measurementProjectId) {
    edges {
      node {
        id
        status
        kind
      }
    }
  }
  financialsReviewItem(id: $reviewItemId) {
    ...FinancialsReviewItemDetails
  }
}
    ${FinancialsReviewItemDetailsFragmentDoc}`;

export function useFinancialsReviewItemDetailPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinancialsReviewItemDetailPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinancialsReviewItemDetailPageQuery,
    GQTypes.GQFinancialsReviewItemDetailPageQueryVariables
  >({ query: FinancialsReviewItemDetailPageDocument, ...options });
}
export const SubmitReviewItemReviewDocument = gql`
    mutation SubmitReviewItemReview($input: ReviewFinancialsReviewItemInput!) {
  reviewFinancialsReviewItem(input: $input) {
    reviewItem {
      ...FinancialsReviewItemDetails
    }
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    financialsUserUploadTasks {
      id
      state
    }
  }
}
    ${FinancialsReviewItemDetailsFragmentDoc}
${MeasurementParentTaskForMeasurementPageFragmentDoc}`;

export function useSubmitReviewItemReviewMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitReviewItemReviewMutation,
    GQTypes.GQSubmitReviewItemReviewMutationVariables
  >(SubmitReviewItemReviewDocument);
}
export const MappingsReviewIndexPageDocument = gql`
    query MappingsReviewIndexPage($id: ID!) {
  userHasPermission: userHasManageFinancialsPermission
  measurementProject(id: $id) {
    id
    name
  }
  financialsReviewItems(measurementProjectId: $id) {
    edges {
      node {
        ...FinancialsReviewInboxItem
      }
    }
  }
}
    ${FinancialsReviewInboxItemFragmentDoc}`;

export function useMappingsReviewIndexPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMappingsReviewIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMappingsReviewIndexPageQuery,
    GQTypes.GQMappingsReviewIndexPageQueryVariables
  >({ query: MappingsReviewIndexPageDocument, ...options });
}
export const GetUploadsForUserUploadTaskDocument = gql`
    query GetUploadsForUserUploadTask($userUploadTaskId: ID!) {
  userUploadTask(id: $userUploadTaskId) {
    id
    measurementTask {
      id
      statusSimplified
    }
    userUploads {
      edges {
        node {
          ...UserUploadForDatasource
        }
      }
    }
    isDatasetComplete
  }
}
    ${UserUploadForDatasourceFragmentDoc}`;

export function useGetUploadsForUserUploadTaskQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUploadsForUserUploadTaskQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUploadsForUserUploadTaskQuery,
    GQTypes.GQGetUploadsForUserUploadTaskQueryVariables
  >({ query: GetUploadsForUserUploadTaskDocument, ...options });
}
export const GetCustomizationTypeSelectorSectionsDocument = gql`
    query GetCustomizationTypeSelectorSections {
  methodologyCustomizationDisplaySections {
    sectionName
    sectionRows {
      customizationTypeId
      typeConfig {
        id
        displayName
        description
        iconName
      }
    }
  }
}
    `;

export function useGetCustomizationTypeSelectorSectionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomizationTypeSelectorSectionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomizationTypeSelectorSectionsQuery,
    GQTypes.GQGetCustomizationTypeSelectorSectionsQueryVariables
  >({ query: GetCustomizationTypeSelectorSectionsDocument, ...options });
}
export const MethodologyCustomizationRowsForLibraryDocument = gql`
    query MethodologyCustomizationRowsForLibrary($customizationTypeId: String!) {
  methodologyCustomizationRowsForOrg(customizationTypeId: $customizationTypeId) {
    id
    revisionCreatedAt
    orgName
    referenceDataRevisionRows {
      id
      rowFields
      citationName
      citationNotes
      citationUrl
      citationSlug
      isCustomerProvided
    }
  }
}
    `;

export function useMethodologyCustomizationRowsForLibraryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationRowsForLibraryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationRowsForLibraryQuery,
    GQTypes.GQMethodologyCustomizationRowsForLibraryQueryVariables
  >({ query: MethodologyCustomizationRowsForLibraryDocument, ...options });
}
export const MethodologyCustomizationTypeConfigDocument = gql`
    query MethodologyCustomizationTypeConfig($customizationTypeId: String!) {
  methodologyCustomizationTypeConfig(customizationTypeId: $customizationTypeId) {
    id
    displayName
    description
    iconName
    globalReferenceDataSources
    quantityColumnName
    displayColumns {
      parquetColumnName
      displayName
      description
      type
    }
    globalColumnsToCustomerUploadedColumns
  }
}
    `;

export function useMethodologyCustomizationTypeConfigQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationTypeConfigQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationTypeConfigQuery,
    GQTypes.GQMethodologyCustomizationTypeConfigQueryVariables
  >({ query: MethodologyCustomizationTypeConfigDocument, ...options });
}
export const MethodologyCustomizationValueMappingRowsDocument = gql`
    query MethodologyCustomizationValueMappingRows($valueMappingConfigId: String!) {
  valueMappingWithLatestStatus(valueMappingConfigId: $valueMappingConfigId) {
    ...ValueMappingPageValueMapping
  }
}
    ${ValueMappingPageValueMappingFragmentDoc}`;

export function useMethodologyCustomizationValueMappingRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationValueMappingRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationValueMappingRowsQuery,
    GQTypes.GQMethodologyCustomizationValueMappingRowsQueryVariables
  >({ query: MethodologyCustomizationValueMappingRowsDocument, ...options });
}
export const PublishMethodologyCustomizationValueMappingArtifactDocument = gql`
    mutation PublishMethodologyCustomizationValueMappingArtifact($valueMappingConfigId: ID!) {
  publishValueMappingArtifact(
    input: {valueMappingConfigId: $valueMappingConfigId}
  ) {
    publishedArtifactRevisionId
  }
}
    `;

export function usePublishMethodologyCustomizationValueMappingArtifactMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishMethodologyCustomizationValueMappingArtifactMutation,
    GQTypes.GQPublishMethodologyCustomizationValueMappingArtifactMutationVariables
  >(PublishMethodologyCustomizationValueMappingArtifactDocument);
}
export const GenerateOverrideReferenceDataRevisionDocument = gql`
    mutation GenerateOverrideReferenceDataRevision($input: GenerateOverrideReferenceDataRevisionInput!) {
  generateOverrideReferenceDataRevision(input: $input) {
    jobId
  }
}
    `;

export function useGenerateOverrideReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateOverrideReferenceDataRevisionMutation,
    GQTypes.GQGenerateOverrideReferenceDataRevisionMutationVariables
  >(GenerateOverrideReferenceDataRevisionDocument);
}
export const MethodologyCustomizationMissingSourceDataDocument = gql`
    query MethodologyCustomizationMissingSourceData($configId: String!) {
  methodologyCustomizationMappingConfig(configId: $configId) {
    id
    missingSourceDataConfig {
      message
      buttonLabel
      imageName
    }
  }
}
    `;

export function useMethodologyCustomizationMissingSourceDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationMissingSourceDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationMissingSourceDataQuery,
    GQTypes.GQMethodologyCustomizationMissingSourceDataQueryVariables
  >({ query: MethodologyCustomizationMissingSourceDataDocument, ...options });
}
export const GetMappingTabSidebarSectionsDocument = gql`
    query GetMappingTabSidebarSections {
  methodologyCustomizationDisplaySections {
    sectionName
    sectionRows {
      customizationTypeId
      typeConfig {
        id
        displayName
      }
      mappingConfigIds
    }
  }
}
    `;

export function useGetMappingTabSidebarSectionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMappingTabSidebarSectionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMappingTabSidebarSectionsQuery,
    GQTypes.GQGetMappingTabSidebarSectionsQueryVariables
  >({ query: GetMappingTabSidebarSectionsDocument, ...options });
}
export const MethodologyCustomizationValueMappingFootprintPreviewDocument = gql`
    query MethodologyCustomizationValueMappingFootprintPreview($mappingConfigId: String!) {
  methodologyCustomizationPreviewFootprint(mappingConfigId: $mappingConfigId) {
    id
    footprintSnapshot {
      id
      createdAt
    }
    mappingArtifactCreatedAt
  }
}
    `;

export function useMethodologyCustomizationValueMappingFootprintPreviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyCustomizationValueMappingFootprintPreviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyCustomizationValueMappingFootprintPreviewQuery,
    GQTypes.GQMethodologyCustomizationValueMappingFootprintPreviewQueryVariables
  >({
    query: MethodologyCustomizationValueMappingFootprintPreviewDocument,
    ...options,
  });
}
export const ConfigureFacilitiesTasksPageDocument = gql`
    query ConfigureFacilitiesTasksPage($measurementProjectId: ID!, $after: String, $before: String, $first: Int, $last: Int, $filter: [FacilitiesFilterInput!], $sort: [FacilitiesSortInput!], $search: String, $canonicalDatasetKind: CanonicalDatasetKind!, $orgStructureEnabled: Boolean!) {
  measurementProject(id: $measurementProjectId) {
    ...MeasurementProjectForConfigureFacilitiesTasksPage
  }
  organization {
    ...OrganizationForConfigureFacilitiesTasksPage
  }
  facilities(
    after: $after
    before: $before
    first: $first
    last: $last
    filter: $filter
    sort: $sort
    search: $search
    measurementProjectId: $measurementProjectId
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...FacilitiesForTasksPage
      }
    }
  }
  latestOrgUnitTypesForCanonicalDatasetKind(
    canonicalDatasetKind: $canonicalDatasetKind
  ) @include(if: $orgStructureEnabled) {
    ...OrgUnitTypeWithOrgUnitsForFacilities
  }
}
    ${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}
${OrganizationForConfigureFacilitiesTasksPageFragmentDoc}
${FacilitiesForTasksPageFragmentDoc}
${OrgUnitTypeWithOrgUnitsForFacilitiesFragmentDoc}`;

export function useConfigureFacilitiesTasksPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQConfigureFacilitiesTasksPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQConfigureFacilitiesTasksPageQuery,
    GQTypes.GQConfigureFacilitiesTasksPageQueryVariables
  >({ query: ConfigureFacilitiesTasksPageDocument, ...options });
}
export const AssignFacilitiesToTasksDocument = gql`
    mutation AssignFacilitiesToTasks($input: AssignFacilitiesToTasksInput!) {
  assignFacilitiesToTasks(input: $input) {
    measurementProject {
      ...MeasurementProjectForConfigureFacilitiesTasksPage
    }
    facilitiesAssigned {
      ...FacilityFragment
    }
    facilitiesRequiringConfirmation {
      id
      buildingName
    }
    lockedDestinationTasks {
      id
      datasource {
        id
        name
      }
      lockState
    }
    lockedSourceTasks {
      id
      datasource {
        id
        name
      }
      lockState
    }
  }
}
    ${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}
${FacilityFragmentFragmentDoc}`;

export function useAssignFacilitiesToTasksMutation() {
  return Urql.useMutation<
    GQTypes.GQAssignFacilitiesToTasksMutation,
    GQTypes.GQAssignFacilitiesToTasksMutationVariables
  >(AssignFacilitiesToTasksDocument);
}
export const DeleteBuildingsForConfigureFacilitiesDocument = gql`
    mutation DeleteBuildingsForConfigureFacilities($input: DeleteBuildingsInput!) {
  deleteBuildings(input: $input) {
    measurementProject {
      ...MeasurementProjectForConfigureFacilitiesTasksPage
    }
  }
}
    ${MeasurementProjectForConfigureFacilitiesTasksPageFragmentDoc}`;

export function useDeleteBuildingsForConfigureFacilitiesMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBuildingsForConfigureFacilitiesMutation,
    GQTypes.GQDeleteBuildingsForConfigureFacilitiesMutationVariables
  >(DeleteBuildingsForConfigureFacilitiesDocument);
}
export const CheckAndGenerateFacilitiesBartsDocument = gql`
    mutation checkAndGenerateFacilitiesBarts($input: CheckAndGenerateFacilitiesBartsInput!) {
  checkAndGenerateFacilitiesBarts(input: $input) {
    workflowId
  }
}
    `;

export function useCheckAndGenerateFacilitiesBartsMutation() {
  return Urql.useMutation<
    GQTypes.GQCheckAndGenerateFacilitiesBartsMutation,
    GQTypes.GQCheckAndGenerateFacilitiesBartsMutationVariables
  >(CheckAndGenerateFacilitiesBartsDocument);
}
export const FacilitiesDataSummaryDocument = gql`
    query facilitiesDataSummary($input: BiActivityQueryContextInput!) {
  biPostgresToActivityData(input: $input) {
    isFacilitiesDataStale
  }
  organization {
    id
    measurementProjects {
      id
      coverageInterval
    }
  }
}
    `;

export function useFacilitiesDataSummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesDataSummaryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesDataSummaryQuery,
    GQTypes.GQFacilitiesDataSummaryQueryVariables
  >({ query: FacilitiesDataSummaryDocument, ...options });
}
export const GetBuildingTemplateDataDocument = gql`
    query GetBuildingTemplateData {
  buildingTemplateDataUntyped
}
    `;

export function useGetBuildingTemplateDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBuildingTemplateDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBuildingTemplateDataQuery,
    GQTypes.GQGetBuildingTemplateDataQueryVariables
  >({ query: GetBuildingTemplateDataDocument, ...options });
}
export const DeleteBuildingsForFacilitiesManagementDocument = gql`
    mutation DeleteBuildingsForFacilitiesManagement($input: DeleteBuildingsInput!) {
  deleteBuildings(input: $input) {
    organization {
      ...OrganizationFacilitiesUntyped
    }
    measurementProject {
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${OrganizationFacilitiesUntypedFragmentDoc}
${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useDeleteBuildingsForFacilitiesManagementMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteBuildingsForFacilitiesManagementMutation,
    GQTypes.GQDeleteBuildingsForFacilitiesManagementMutationVariables
  >(DeleteBuildingsForFacilitiesManagementDocument);
}
export const ReassignFacilitiesTaskDocument = gql`
    mutation ReassignFacilitiesTask($input: ReassignFacilitiesTaskInput!) {
  reassignFacilitiesTask(input: $input) {
    measurementProject {
      ...MeasurementProjectForManageFacilitiesPage
    }
  }
}
    ${MeasurementProjectForManageFacilitiesPageFragmentDoc}`;

export function useReassignFacilitiesTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQReassignFacilitiesTaskMutation,
    GQTypes.GQReassignFacilitiesTaskMutationVariables
  >(ReassignFacilitiesTaskDocument);
}
export const BatchSetCustomFieldForBuildingsDocument = gql`
    mutation BatchSetCustomFieldForBuildings($input: BatchSetCustomFieldForBuildingsInput!) {
  batchSetCustomFieldForBuildings(input: $input) {
    buildingsUntyped {
      id
      data
    }
  }
}
    `;

export function useBatchSetCustomFieldForBuildingsMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchSetCustomFieldForBuildingsMutation,
    GQTypes.GQBatchSetCustomFieldForBuildingsMutationVariables
  >(BatchSetCustomFieldForBuildingsDocument);
}
export const BatchUnsetCustomFieldForBuildingsDocument = gql`
    mutation BatchUnsetCustomFieldForBuildings($input: BatchUnsetCustomFieldForBuildingsInput!) {
  batchUnsetCustomFieldForBuildings(input: $input) {
    buildingsUntyped {
      id
      data
    }
  }
}
    `;

export function useBatchUnsetCustomFieldForBuildingsMutation() {
  return Urql.useMutation<
    GQTypes.GQBatchUnsetCustomFieldForBuildingsMutation,
    GQTypes.GQBatchUnsetCustomFieldForBuildingsMutationVariables
  >(BatchUnsetCustomFieldForBuildingsDocument);
}
export const UpdateBuildingsDocument = gql`
    mutation UpdateBuildings($input: UpdateBuildingsInput!) {
  updateBuildings(input: $input) {
    buildingsUntyped {
      id
      data
    }
  }
}
    `;

export function useUpdateBuildingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateBuildingsMutation,
    GQTypes.GQUpdateBuildingsMutationVariables
  >(UpdateBuildingsDocument);
}
export const OrganizationUsersForFacilitiesImportDocument = gql`
    query OrganizationUsersForFacilitiesImport {
  organization {
    ...OrganizationFieldsForFacilitiesImport
  }
}
    ${OrganizationFieldsForFacilitiesImportFragmentDoc}`;

export function useOrganizationUsersForFacilitiesImportQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationUsersForFacilitiesImportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationUsersForFacilitiesImportQuery,
    GQTypes.GQOrganizationUsersForFacilitiesImportQueryVariables
  >({ query: OrganizationUsersForFacilitiesImportDocument, ...options });
}
export const BulkCreateUsersForFacilitiesDocument = gql`
    mutation BulkCreateUsersForFacilities($input: BulkCreateUsersForFacilitiesInput!) {
  bulkCreateUsersForFacilities(input: $input) {
    users {
      ...UserForFacilitiesImport
    }
    organization {
      ...OrganizationFieldsForFacilitiesImport
    }
  }
}
    ${UserForFacilitiesImportFragmentDoc}
${OrganizationFieldsForFacilitiesImportFragmentDoc}`;

export function useBulkCreateUsersForFacilitiesMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkCreateUsersForFacilitiesMutation,
    GQTypes.GQBulkCreateUsersForFacilitiesMutationVariables
  >(BulkCreateUsersForFacilitiesDocument);
}
export const FacilitiesManagementPageNewDocument = gql`
    query FacilitiesManagementPageNew($measurementProjectId: ID!) {
  measurementProject(id: $measurementProjectId) {
    ...MeasurementProjectForManageFacilitiesPage
  }
  organization {
    ...OrganizationCustomFieldsForManagePage
  }
}
    ${MeasurementProjectForManageFacilitiesPageFragmentDoc}
${OrganizationCustomFieldsForManagePageFragmentDoc}`;

export function useFacilitiesManagementPageNewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFacilitiesManagementPageNewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFacilitiesManagementPageNewQuery,
    GQTypes.GQFacilitiesManagementPageNewQueryVariables
  >({ query: FacilitiesManagementPageNewDocument, ...options });
}
export const BuildingsUntypedDocument = gql`
    query BuildingsUntyped {
  organization {
    ...OrganizationFacilitiesUntyped
  }
}
    ${OrganizationFacilitiesUntypedFragmentDoc}`;

export function useBuildingsUntypedQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingsUntypedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingsUntypedQuery,
    GQTypes.GQBuildingsUntypedQueryVariables
  >({ query: BuildingsUntypedDocument, ...options });
}
export const MeasurementProjectsWithFacilitiesDocument = gql`
    query MeasurementProjectsWithFacilities {
  measurementProjectsWithFacilities {
    id
  }
}
    `;

export function useMeasurementProjectsWithFacilitiesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectsWithFacilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectsWithFacilitiesQuery,
    GQTypes.GQMeasurementProjectsWithFacilitiesQueryVariables
  >({ query: MeasurementProjectsWithFacilitiesDocument, ...options });
}
export const SingleFacilityUtilitiesDocument = gql`
    query SingleFacilityUtilities($buildingId: ID!, $userUploadTaskId: ID!) {
  facility(id: $buildingId) {
    id
    startYearMonth
    endYearMonth
    buildingWithUtilities {
      id
      completionIntervals(userUploadTaskId: $userUploadTaskId) {
        id
        data {
          utilityType
          fuelKind
          periodsWithActuals {
            start
            end
          }
        }
      }
    }
  }
}
    `;

export function useSingleFacilityUtilitiesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSingleFacilityUtilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSingleFacilityUtilitiesQuery,
    GQTypes.GQSingleFacilityUtilitiesQueryVariables
  >({ query: SingleFacilityUtilitiesDocument, ...options });
}
export const BuildingCompletionIntervalsUntypedDocument = gql`
    query BuildingCompletionIntervalsUntyped {
  organization {
    id
    buildingCompletionIntervalsUntyped {
      id
      data
    }
  }
}
    `;

export function useBuildingCompletionIntervalsUntypedQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingCompletionIntervalsUntypedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingCompletionIntervalsUntypedQuery,
    GQTypes.GQBuildingCompletionIntervalsUntypedQueryVariables
  >({ query: BuildingCompletionIntervalsUntypedDocument, ...options });
}
export const BuildingUtilitiesExportDocument = gql`
    query BuildingUtilitiesExport {
  buildingUtilitiesExport {
    jobId
  }
}
    `;

export function useBuildingUtilitiesExportQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingUtilitiesExportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingUtilitiesExportQuery,
    GQTypes.GQBuildingUtilitiesExportQueryVariables
  >({ query: BuildingUtilitiesExportDocument, ...options });
}
export const BuildingSupportingDocsExportDocument = gql`
    query BuildingSupportingDocsExport {
  buildingSupportingDocsExport {
    jobId
  }
}
    `;

export function useBuildingSupportingDocsExportQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingSupportingDocsExportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingSupportingDocsExportQuery,
    GQTypes.GQBuildingSupportingDocsExportQueryVariables
  >({ query: BuildingSupportingDocsExportDocument, ...options });
}
export const GetLatestFootprintInfoAfterFootprintAssemblyRunDocument = gql`
    query GetLatestFootprintInfoAfterFootprintAssemblyRun($id: ID!) {
  footprintAssemblyRun(id: $id) {
    id
    status
    footprintSnapshotId
    footprintVersion {
      id
      newDataCoverageInterval
    }
  }
  activeOrganization {
    id
    latestDraftFootprintVersion
  }
}
    `;

export function useGetLatestFootprintInfoAfterFootprintAssemblyRunQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetLatestFootprintInfoAfterFootprintAssemblyRunQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetLatestFootprintInfoAfterFootprintAssemblyRunQuery,
    GQTypes.GQGetLatestFootprintInfoAfterFootprintAssemblyRunQueryVariables
  >({
    query: GetLatestFootprintInfoAfterFootprintAssemblyRunDocument,
    ...options,
  });
}
export const GenerateFootprintStepRendererDocument = gql`
    query GenerateFootprintStepRenderer($footprintId: String, $latestDraftFootprintSnapshotId: String, $latestPublishedFootprintSnapshotId: String) {
  userHasPermission(permission: ManageMeasurement)
  footprint(footprintId: $footprintId) {
    id
    name
    nextFootprintVersionIdentifier
    defaultFootprintSnapshot {
      id
      footprintConfiguration {
        ...FootprintConfigurationForGenerateFootprint
      }
    }
  }
  footprintChangelog {
    id
    versions {
      edges {
        node {
          ...FootprintVersionForNameGen
        }
      }
    }
  }
  activeMeasurementProjects {
    ...MeasurementProjectForGenerateFootprint
  }
  utilitiesMetadata(filterInterval: null) {
    id
    utilityTypes {
      utilityType
      containingInterval
    }
  }
  organization {
    id
    facilitiesMetadata {
      buildingsDataset {
        id
      }
      utilitiesDataset {
        id
      }
    }
    measurementProjects {
      ...MeasurementProjectForGenerateFootprint
    }
  }
  latestDraft: footprintAnalysis(
    footprintSnapshotId: $latestDraftFootprintSnapshotId
  ) {
    id
    footprintVersion {
      id
      createdAt
      configuration {
        ...FootprintConfigurationForGenerateFootprint
        ...FootprintConfigurationWithReleases
      }
    }
  }
  latestPublished: footprintAnalysis(
    footprintSnapshotId: $latestPublishedFootprintSnapshotId
  ) {
    id
    footprintVersion {
      id
      createdAt
      userVisibleFootprintSnapshot {
        ...PipelineConfigsForFootprintSnapshot
      }
      configuration {
        ...FootprintConfigurationWithReleases
      }
    }
  }
}
    ${FootprintConfigurationForGenerateFootprintFragmentDoc}
${FootprintVersionForNameGenFragmentDoc}
${MeasurementProjectForGenerateFootprintFragmentDoc}
${FootprintConfigurationWithReleasesFragmentDoc}
${PipelineConfigsForFootprintSnapshotFragmentDoc}`;

export function useGenerateFootprintStepRendererQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGenerateFootprintStepRendererQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGenerateFootprintStepRendererQuery,
    GQTypes.GQGenerateFootprintStepRendererQueryVariables
  >({ query: GenerateFootprintStepRendererDocument, ...options });
}
export const AvailableReleasesForSelectedDatasetsDocument = gql`
    query AvailableReleasesForSelectedDatasets($datasetIdsPerMeasurementProjectId: [DatasetIdsPerMeasurementProjectId!]!) {
  availableReleasesForSelectedDatasets(
    datasetIdsPerMeasurementProjectId: $datasetIdsPerMeasurementProjectId
  ) {
    businessActivityType
    displayName
    datasetIds
    releases {
      id
      displayName
      businessActivityTypeName
      externalNotes
      lifecyclePhase
      updatedAt
      orgId
      isCustomToUserOrg
      latestCompatibleBatVersion {
        id
        name
      }
      lifecyclePhaseHistory {
        id
        recordStartAt
        recordEndAt
        lifecyclePhase
      }
    }
  }
  selectedDatasetsByBatWithMetadata(
    datasetIdsPerMeasurementProjectId: $datasetIdsPerMeasurementProjectId
  ) {
    id
    datasetsByBat {
      id
      datasets {
        id
        datasetName
      }
      businessActivityType
      businessActivityTypeDisplayName
    }
    ctsUserUploads {
      id
      datasourceId
      userUploadId
      userUploadFileName
      batvName
      businessActivityType
      measurementProjectId
    }
  }
}
    `;

export function useAvailableReleasesForSelectedDatasetsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAvailableReleasesForSelectedDatasetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAvailableReleasesForSelectedDatasetsQuery,
    GQTypes.GQAvailableReleasesForSelectedDatasetsQueryVariables
  >({ query: AvailableReleasesForSelectedDatasetsDocument, ...options });
}
export const ApplyIntegrationConnectionDashboardDocument = gql`
    mutation ApplyIntegrationConnectionDashboard($input: ApplyIntegrationConnectionInput!) {
  applyIntegrationConnection(input: $input) {
    integrationConnection {
      id
      datasources {
        edges {
          node {
            id
            name
            dataset {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

export function useApplyIntegrationConnectionDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQApplyIntegrationConnectionDashboardMutation,
    GQTypes.GQApplyIntegrationConnectionDashboardMutationVariables
  >(ApplyIntegrationConnectionDashboardDocument);
}
export const MaybeCreateIntegrationConnectionDoNotLogDocument = gql`
    mutation MaybeCreateIntegrationConnectionDoNotLog($input: MaybeCreateIntegrationConnectionInput!) {
  maybeCreateIntegrationConnection(input: $input) {
    datasources {
      id
      activeIntegrationConnection {
        id
      }
      hasIntegration
    }
    integrationConnection {
      id
      integrationPartner {
        id
        activeIntegrationConnections {
          id
        }
      }
    }
  }
}
    `;

export function useMaybeCreateIntegrationConnectionDoNotLogMutation() {
  return Urql.useMutation<
    GQTypes.GQMaybeCreateIntegrationConnectionDoNotLogMutation,
    GQTypes.GQMaybeCreateIntegrationConnectionDoNotLogMutationVariables
  >(MaybeCreateIntegrationConnectionDoNotLogDocument);
}
export const DisconnectIntegrationDocument = gql`
    mutation DisconnectIntegration($input: DisconnectIntegrationConnectionInput!) {
  disconnectIntegrationConnection(input: $input) {
    integrationPartner {
      id
      activeIntegrationConnections {
        id
        additionalIdentifier
      }
    }
    revoked
  }
}
    `;

export function useDisconnectIntegrationMutation() {
  return Urql.useMutation<
    GQTypes.GQDisconnectIntegrationMutation,
    GQTypes.GQDisconnectIntegrationMutationVariables
  >(DisconnectIntegrationDocument);
}
export const GetIntegrationInviteDocument = gql`
    mutation GetIntegrationInvite($input: SendIntegrationInviteInput!) {
  sendIntegrationInvite(input: $input) {
    sampleEmail {
      ...EmailContentsFields
    }
    numberOfEmails
  }
}
    ${EmailContentsFieldsFragmentDoc}`;

export function useGetIntegrationInviteMutation() {
  return Urql.useMutation<
    GQTypes.GQGetIntegrationInviteMutation,
    GQTypes.GQGetIntegrationInviteMutationVariables
  >(GetIntegrationInviteDocument);
}
export const SendIntegrationInviteDocument = gql`
    mutation SendIntegrationInvite($input: SendIntegrationInviteInput!) {
  sendIntegrationInvite(input: $input) {
    numberOfEmails
  }
}
    `;

export function useSendIntegrationInviteMutation() {
  return Urql.useMutation<
    GQTypes.GQSendIntegrationInviteMutation,
    GQTypes.GQSendIntegrationInviteMutationVariables
  >(SendIntegrationInviteDocument);
}
export const OrganizationIntegrationPartnersDocument = gql`
    query OrganizationIntegrationPartners {
  userHasPermission(permission: Admin)
  integrationPartners {
    edges {
      node {
        id
        name
        logoUrl
        externalId
        dataType
        isRequestOnly
        activeIntegrationConnections {
          id
          additionalIdentifier
        }
      }
    }
  }
  integrationConnections {
    edges {
      node {
        id
        isActive
        integrationPartner {
          id
          name
        }
      }
    }
  }
}
    `;

export function useOrganizationIntegrationPartnersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationIntegrationPartnersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationIntegrationPartnersQuery,
    GQTypes.GQOrganizationIntegrationPartnersQueryVariables
  >({ query: OrganizationIntegrationPartnersDocument, ...options });
}
export const GetOrSendMeasurementEmailsDocument = gql`
    mutation GetOrSendMeasurementEmails($input: GetOrSendMeasurementEmailsInput!) {
  getOrSendMeasurementEmails(input: $input) {
    measurementProject {
      ...MeasurementProjectForSendEmailsButton
    }
    sampleEmail {
      ...EmailContentsFields
    }
    defaultBlurb
  }
}
    ${MeasurementProjectForSendEmailsButtonFragmentDoc}
${EmailContentsFieldsFragmentDoc}`;

export function useGetOrSendMeasurementEmailsMutation() {
  return Urql.useMutation<
    GQTypes.GQGetOrSendMeasurementEmailsMutation,
    GQTypes.GQGetOrSendMeasurementEmailsMutationVariables
  >(GetOrSendMeasurementEmailsDocument);
}
export const AutomapSuppliersDocument = gql`
    mutation AutomapSuppliers($input: AutoMapSuppliersInput!) {
  autoMapSuppliers(input: $input) {
    workflowId
  }
}
    `;

export function useAutomapSuppliersMutation() {
  return Urql.useMutation<
    GQTypes.GQAutomapSuppliersMutation,
    GQTypes.GQAutomapSuppliersMutationVariables
  >(AutomapSuppliersDocument);
}
export const CreateCompanyChangeRequestCreateDocument = gql`
    mutation CreateCompanyChangeRequestCreate($input: CreateCompanyChangeRequestCreateInput!) {
  createCompanyChangeRequestCreate(input: $input) {
    companyChangeRequest {
      ...CompanyChangeRequestForVendorMatching
    }
  }
}
    ${CompanyChangeRequestForVendorMatchingFragmentDoc}`;

export function useCreateCompanyChangeRequestCreateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCompanyChangeRequestCreateMutation,
    GQTypes.GQCreateCompanyChangeRequestCreateMutationVariables
  >(CreateCompanyChangeRequestCreateDocument);
}
export const PreMeasurementSupplierMappingTableRowsDocument = gql`
    query PreMeasurementSupplierMappingTableRows($measurementProjectId: ID!, $search: String, $sortModel: [DataGridSortItemInput!]!, $filters: [BiQueryFilterInput!]!, $after: String, $before: String, $first: Int, $last: Int) {
  preMeasurementSupplierMappingTableRows(
    measurementProjectId: $measurementProjectId
    search: $search
    sortModel: $sortModel
    filters: $filters
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    ...SupplierMappingTableRowsPayloadFields
  }
}
    ${SupplierMappingTableRowsPayloadFieldsFragmentDoc}`;

export function usePreMeasurementSupplierMappingTableRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPreMeasurementSupplierMappingTableRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPreMeasurementSupplierMappingTableRowsQuery,
    GQTypes.GQPreMeasurementSupplierMappingTableRowsQueryVariables
  >({ query: PreMeasurementSupplierMappingTableRowsDocument, ...options });
}
export const GetCompaniesWithSupplierSpecificEfDocument = gql`
    query getCompaniesWithSupplierSpecificEf {
  getCompaniesWithSupplierSpecificEf {
    companyIds
  }
}
    `;

export function useGetCompaniesWithSupplierSpecificEfQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompaniesWithSupplierSpecificEfQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompaniesWithSupplierSpecificEfQuery,
    GQTypes.GQGetCompaniesWithSupplierSpecificEfQueryVariables
  >({ query: GetCompaniesWithSupplierSpecificEfDocument, ...options });
}
export const SupplierMappingFilterOptionsDocument = gql`
    query SupplierMappingFilterOptions($dimension: String!) {
  supplierMappingFilterOptions(dimension: $dimension) {
    rawValue
    formattedValue
  }
}
    `;

export function useSupplierMappingFilterOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSupplierMappingFilterOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSupplierMappingFilterOptionsQuery,
    GQTypes.GQSupplierMappingFilterOptionsQueryVariables
  >({ query: SupplierMappingFilterOptionsDocument, ...options });
}
export const PreMeasurementVendorMatchingTableRowsDocument = gql`
    query PreMeasurementVendorMatchingTableRows($measurementProjectId: ID!, $search: String, $sortBy: SupplierMatchingSortOption, $after: String, $before: String, $first: Int, $last: Int) {
  preMeasurementVendorMatchingTableRows(
    measurementProjectId: $measurementProjectId
    search: $search
    sortBy: $sortBy
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    ...VendorMatchingTableRowsPayloadFields
  }
}
    ${VendorMatchingTableRowsPayloadFieldsFragmentDoc}`;

export function usePreMeasurementVendorMatchingTableRowsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPreMeasurementVendorMatchingTableRowsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPreMeasurementVendorMatchingTableRowsQuery,
    GQTypes.GQPreMeasurementVendorMatchingTableRowsQueryVariables
  >({ query: PreMeasurementVendorMatchingTableRowsDocument, ...options });
}
export const PreMeasurementSupplierMappingV1AnalyticsDocument = gql`
    query PreMeasurementSupplierMappingV1Analytics($measurementProjectId: ID!) {
  preMeasurementSupplierMappingV1Analytics(
    measurementProjectId: $measurementProjectId
  ) {
    ...SupplierMappingV1AnalyticsFields
  }
}
    ${SupplierMappingV1AnalyticsFieldsFragmentDoc}`;

export function usePreMeasurementSupplierMappingV1AnalyticsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPreMeasurementSupplierMappingV1AnalyticsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPreMeasurementSupplierMappingV1AnalyticsQuery,
    GQTypes.GQPreMeasurementSupplierMappingV1AnalyticsQueryVariables
  >({ query: PreMeasurementSupplierMappingV1AnalyticsDocument, ...options });
}
export const VendorMatchingTaskPageDataDocument = gql`
    query VendorMatchingTaskPageData($measurementProjectId: ID!) {
  userHasPermission(permission: ManageMeasurement)
  vendorMatchingTask(measurementProjectId: $measurementProjectId) {
    ...VendorMatchingTaskFields
  }
}
    ${VendorMatchingTaskFieldsFragmentDoc}`;

export function useVendorMatchingTaskPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQVendorMatchingTaskPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQVendorMatchingTaskPageDataQuery,
    GQTypes.GQVendorMatchingTaskPageDataQueryVariables
  >({ query: VendorMatchingTaskPageDataDocument, ...options });
}
export const SubmitVendorMatchingTaskDocument = gql`
    mutation SubmitVendorMatchingTask($input: SubmitVendorMatchingTaskInput!) {
  submitVendorMatchingTask(input: $input) {
    measurementTask {
      id
    }
  }
}
    `;

export function useSubmitVendorMatchingTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitVendorMatchingTaskMutation,
    GQTypes.GQSubmitVendorMatchingTaskMutationVariables
  >(SubmitVendorMatchingTaskDocument);
}
export const UpsertOrgCompanyForNameDocument = gql`
    mutation UpsertOrgCompanyForName($input: UpsertOrgCompanyForNameInput!) {
  upsertOrgCompanyForName(input: $input) {
    orgCompany {
      ...OrgCompanyForVendorMatching
    }
  }
}
    ${OrgCompanyForVendorMatchingFragmentDoc}`;

export function useUpsertOrgCompanyForNameMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertOrgCompanyForNameMutation,
    GQTypes.GQUpsertOrgCompanyForNameMutationVariables
  >(UpsertOrgCompanyForNameDocument);
}
export const WithdrawCompanyChangeRequestDocument = gql`
    mutation WithdrawCompanyChangeRequest($input: WithdrawCompanyChangeRequestInput!) {
  withdrawCompanyChangeRequest(input: $input) {
    id
  }
}
    `;

export function useWithdrawCompanyChangeRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQWithdrawCompanyChangeRequestMutation,
    GQTypes.GQWithdrawCompanyChangeRequestMutationVariables
  >(WithdrawCompanyChangeRequestDocument);
}
export const DeleteOrgCompanyDocument = gql`
    mutation DeleteOrgCompany($input: DeleteOrgCompanyInput!) {
  deleteOrgCompany(input: $input) {
    id
  }
}
    `;

export function useDeleteOrgCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgCompanyMutation,
    GQTypes.GQDeleteOrgCompanyMutationVariables
  >(DeleteOrgCompanyDocument);
}
export const LogUpdateVendorMatchingTaskDocument = gql`
    mutation LogUpdateVendorMatchingTask($input: LogUpdateVendorMatchingTaskInput!) {
  logUpdateVendorMatchingTask(input: $input) {
    vendorMatchingTaskId
  }
}
    `;

export function useLogUpdateVendorMatchingTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQLogUpdateVendorMatchingTaskMutation,
    GQTypes.GQLogUpdateVendorMatchingTaskMutationVariables
  >(LogUpdateVendorMatchingTaskDocument);
}
export const MeasurementVerificationDetailDocument = gql`
    query MeasurementVerificationDetail($itemId: ID!) {
  measurementVerificationItem(id: $itemId) {
    ...MeasurementVerificationItemForSummaries
    ...MeasurementVerificationItemForQuestionContent
  }
}
    ${MeasurementVerificationItemForSummariesFragmentDoc}
${MeasurementVerificationItemForQuestionContentFragmentDoc}`;

export function useMeasurementVerificationDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementVerificationDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementVerificationDetailQuery,
    GQTypes.GQMeasurementVerificationDetailQueryVariables
  >({ query: MeasurementVerificationDetailDocument, ...options });
}
export const AnswerMeasurementVerificationItemQuestionDocument = gql`
    mutation AnswerMeasurementVerificationItemQuestion($input: AnswerMeasurementVerificationItemQuestionInput!) {
  answerMeasurementVerificationItemQuestion(input: $input) {
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    question {
      ...MeasurementVerificationItemQuestionForSummaries
      item {
        id
        status
        dataset {
          id
          datasources {
            id
            userUploadTasks {
              edges {
                node {
                  id
                  state
                }
              }
            }
            firstUnansweredMeasurementVerificationQuestion {
              id
            }
          }
        }
      }
    }
    measurementProject {
      ...MeasurementProjectForMeasurementPage
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}
${MeasurementVerificationItemQuestionForSummariesFragmentDoc}
${MeasurementProjectForMeasurementPageFragmentDoc}`;

export function useAnswerMeasurementVerificationItemQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQAnswerMeasurementVerificationItemQuestionMutation,
    GQTypes.GQAnswerMeasurementVerificationItemQuestionMutationVariables
  >(AnswerMeasurementVerificationItemQuestionDocument);
}
export const ActiveMeasurementProjectsDocument = gql`
    query ActiveMeasurementProjects {
  activeMeasurementProjects {
    id
    name
  }
}
    `;

export function useActiveMeasurementProjectsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQActiveMeasurementProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActiveMeasurementProjectsQuery,
    GQTypes.GQActiveMeasurementProjectsQueryVariables
  >({ query: ActiveMeasurementProjectsDocument, ...options });
}
export const ActiveOrgProfileDocument = gql`
    query ActiveOrgProfile {
  organization {
    id
    profile {
      ...OrgProfileForOnboardingFlow
    }
  }
}
    ${OrgProfileForOnboardingFlowFragmentDoc}`;

export function useActiveOrgProfileQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQActiveOrgProfileQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActiveOrgProfileQuery,
    GQTypes.GQActiveOrgProfileQueryVariables
  >({ query: ActiveOrgProfileDocument, ...options });
}
export const UpdateOrgProfileDocument = gql`
    mutation UpdateOrgProfile($input: UpdateOrgProfileInput!) {
  updateOrgProfile(input: $input) {
    orgProfile {
      ...OrgProfileForOnboardingFlow
    }
    organization {
      ...OrganizationForOnboardingFlow
    }
  }
}
    ${OrgProfileForOnboardingFlowFragmentDoc}
${OrganizationForOnboardingFlowFragmentDoc}`;

export function useUpdateOrgProfileMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgProfileMutation,
    GQTypes.GQUpdateOrgProfileMutationVariables
  >(UpdateOrgProfileDocument);
}
export const OrgStructureVersionForPageDocument = gql`
    query OrgStructureVersionForPage($versionId: ID!) {
  orgStructureVersion(versionId: $versionId) {
    id
    name
    isPublished
    ...OrgStructureVersionForSidebar
  }
}
    ${OrgStructureVersionForSidebarFragmentDoc}`;

export function useOrgStructureVersionForPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgStructureVersionForPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgStructureVersionForPageQuery,
    GQTypes.GQOrgStructureVersionForPageQueryVariables
  >({ query: OrgStructureVersionForPageDocument, ...options });
}
export const PaginatedOrgUnitTypesDocument = gql`
    query PaginatedOrgUnitTypes($after: String, $before: String, $first: Int, $last: Int, $search: String, $sortModel: [DataGridSortItemInput!], $versionId: ID!) {
  orgUnitTypesPaginated(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    sortModel: $sortModel
    versionId: $versionId
  ) {
    edges {
      cursor
      node {
        ...OrgUnitType
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function usePaginatedOrgUnitTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPaginatedOrgUnitTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPaginatedOrgUnitTypesQuery,
    GQTypes.GQPaginatedOrgUnitTypesQueryVariables
  >({ query: PaginatedOrgUnitTypesDocument, ...options });
}
export const AllOrgUnitTypesDocument = gql`
    query AllOrgUnitTypes($versionId: ID!) {
  orgUnitTypes(versionId: $versionId) {
    ...OrgUnitType
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useAllOrgUnitTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllOrgUnitTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllOrgUnitTypesQuery,
    GQTypes.GQAllOrgUnitTypesQueryVariables
  >({ query: AllOrgUnitTypesDocument, ...options });
}
export const GetFootprintAnalysisTopVendorsDataDocument = gql`
    query GetFootprintAnalysisTopVendorsData($footprintSnapshotId: String!, $oneYearAnalysisFilters: FootprintAnalysisFilters!) {
  userHasPermission(permission: ViewFootprintDetail)
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    topVendors(filters: $oneYearAnalysisFilters, first: 10) {
      vendors {
        ...FootprintAnalysisTopVendorFields
      }
      summarySentences {
        all
        sbt
        netZero
        other
      }
    }
  }
}
    ${FootprintAnalysisTopVendorFieldsFragmentDoc}`;

export function useGetFootprintAnalysisTopVendorsDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintAnalysisTopVendorsDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintAnalysisTopVendorsDataQuery,
    GQTypes.GQGetFootprintAnalysisTopVendorsDataQueryVariables
  >({ query: GetFootprintAnalysisTopVendorsDataDocument, ...options });
}
export const GetOrganizationPermissionsDocument = gql`
    query GetOrganizationPermissions($orgId: ID!) {
  permissions(includeWatershedEmployees: false) {
    edges {
      node {
        id
        permission
        object {
          id
          name
        }
        user {
          id
          displayName
          name
          orgAccessId(orgId: $orgId)
        }
      }
    }
  }
}
    `;

export function useGetOrganizationPermissionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationPermissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationPermissionsQuery,
    GQTypes.GQGetOrganizationPermissionsQueryVariables
  >({ query: GetOrganizationPermissionsDocument, ...options });
}
export const GetOrganizationRolesAndPermissionsDocument = gql`
    query GetOrganizationRolesAndPermissions {
  roles {
    edges {
      node {
        id
        ...TeamsTableRole
      }
    }
  }
}
    ${TeamsTableRoleFragmentDoc}`;

export function useGetOrganizationRolesAndPermissionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationRolesAndPermissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationRolesAndPermissionsQuery,
    GQTypes.GQGetOrganizationRolesAndPermissionsQueryVariables
  >({ query: GetOrganizationRolesAndPermissionsDocument, ...options });
}
export const AllRolesDocument = gql`
    query AllRoles {
  allRoles {
    ...TeamsTableRole
  }
}
    ${TeamsTableRoleFragmentDoc}`;

export function useAllRolesQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQAllRolesQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQAllRolesQuery,
    GQTypes.GQAllRolesQueryVariables
  >({ query: AllRolesDocument, ...options });
}
export const GetPendingUserPermissionRequestsDocument = gql`
    query GetPendingUserPermissionRequests {
  pendingUserPermissionRequests {
    ...UserPermissionRequestFields
  }
}
    ${UserPermissionRequestFieldsFragmentDoc}`;

export function useGetPendingUserPermissionRequestsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPendingUserPermissionRequestsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPendingUserPermissionRequestsQuery,
    GQTypes.GQGetPendingUserPermissionRequestsQueryVariables
  >({ query: GetPendingUserPermissionRequestsDocument, ...options });
}
export const UpdateUserPermissionRequestDocument = gql`
    mutation UpdateUserPermissionRequest($input: UpdateUserPermissionRequestInput!) {
  updateUserPermissionRequest(input: $input) {
    userPermissionRequest {
      ...UserPermissionRequestFields
    }
  }
}
    ${UserPermissionRequestFieldsFragmentDoc}`;

export function useUpdateUserPermissionRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserPermissionRequestMutation,
    GQTypes.GQUpdateUserPermissionRequestMutationVariables
  >(UpdateUserPermissionRequestDocument);
}
export const DeletePortfolioDocument = gql`
    mutation DeletePortfolio($input: DeleteMarketplacePortfolioInput!) {
  deleteMarketplacePortfolio(input: $input) {
    success
  }
}
    `;

export function useDeletePortfolioMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePortfolioMutation,
    GQTypes.GQDeletePortfolioMutationVariables
  >(DeletePortfolioDocument);
}
export const SyncPortfolioDocument = gql`
    mutation SyncPortfolio($portfolio: UpdateMarketplacePortfolioInput!, $items: SyncMarketplacePortfolioItemsInput!) {
  syncMarketplacePortfolioItems(input: $items) {
    portfolio {
      id
    }
  }
  updateMarketplacePortfolio(input: $portfolio) {
    portfolio {
      ...PortfolioForPortfolioBuilder
    }
  }
}
    ${PortfolioForPortfolioBuilderFragmentDoc}`;

export function useSyncPortfolioMutation() {
  return Urql.useMutation<
    GQTypes.GQSyncPortfolioMutation,
    GQTypes.GQSyncPortfolioMutationVariables
  >(SyncPortfolioDocument);
}
export const DuplicatePortfolioDocument = gql`
    mutation DuplicatePortfolio($input: DuplicateMarketplacePortfolioInput!) {
  duplicateMarketplacePortfolio(input: $input) {
    portfolio {
      ...PortfolioForPortfolioBuilder
    }
  }
}
    ${PortfolioForPortfolioBuilderFragmentDoc}`;

export function useDuplicatePortfolioMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicatePortfolioMutation,
    GQTypes.GQDuplicatePortfolioMutationVariables
  >(DuplicatePortfolioDocument);
}
export const CreatePortfolioItemDocument = gql`
    mutation CreatePortfolioItem($input: CreateMarketplacePortfolioItemInput!) {
  createMarketplacePortfolioItem(input: $input) {
    portfolioItem {
      ...PortfolioItemForPortfolioBuilder
    }
  }
}
    ${PortfolioItemForPortfolioBuilderFragmentDoc}`;

export function useCreatePortfolioItemMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePortfolioItemMutation,
    GQTypes.GQCreatePortfolioItemMutationVariables
  >(CreatePortfolioItemDocument);
}
export const AllPortfoliosDocument = gql`
    query AllPortfolios {
  userHasPermission(permission: ManageMarketplacePurchases)
  marketplacePortfolios {
    edges {
      node {
        ...PortfolioForPortfolioBuilder
      }
    }
  }
  portfolioBuilderArchetypes {
    edges {
      node {
        ...ProjectArchetypeForPortfolioBuilder
      }
    }
  }
}
    ${PortfolioForPortfolioBuilderFragmentDoc}
${ProjectArchetypeForPortfolioBuilderFragmentDoc}`;

export function useAllPortfoliosQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllPortfoliosQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllPortfoliosQuery,
    GQTypes.GQAllPortfoliosQueryVariables
  >({ query: AllPortfoliosDocument, ...options });
}
export const CreatePortfolioDocument = gql`
    mutation CreatePortfolio($input: CreateMarketplacePortfolioInput!) {
  createMarketplacePortfolio(input: $input) {
    portfolio {
      ...PortfolioForPortfolioBuilder
    }
  }
}
    ${PortfolioForPortfolioBuilderFragmentDoc}`;

export function useCreatePortfolioMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePortfolioMutation,
    GQTypes.GQCreatePortfolioMutationVariables
  >(CreatePortfolioDocument);
}
export const CreateMarketplacePurchaseFromPortfolioDocument = gql`
    mutation CreateMarketplacePurchaseFromPortfolio($input: CreateMarketplacePurchaseFromPortfolioInput!) {
  createMarketplacePurchaseFromPortfolio(input: $input) {
    purchase {
      id
      customerStatus
    }
    portfolio {
      ...PortfolioForPortfolioBuilder
    }
  }
}
    ${PortfolioForPortfolioBuilderFragmentDoc}`;

export function useCreateMarketplacePurchaseFromPortfolioMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplacePurchaseFromPortfolioMutation,
    GQTypes.GQCreateMarketplacePurchaseFromPortfolioMutationVariables
  >(CreateMarketplacePurchaseFromPortfolioDocument);
}
export const PlanAndInitiativesDocument = gql`
    query PlanAndInitiatives($planId: ID!, $suppliersQueryInterval: YMInterval!, $orgId: ID!) {
  userCanViewReductions: userHasPermission(permission: ViewReductions)
  userCanManageReductions: userHasPermission(permission: ManageReductionPlans)
  plan(id: $planId) {
    ...PlanWithForecastFields
  }
  suppliersWithTargetDisclosures(footprintInterval: $suppliersQueryInterval) {
    suppliers {
      ...SupplierForReductionsForecast
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}
${SupplierForReductionsForecastFragmentDoc}`;

export function usePlanAndInitiativesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPlanAndInitiativesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPlanAndInitiativesQuery,
    GQTypes.GQPlanAndInitiativesQueryVariables
  >({ query: PlanAndInitiativesDocument, ...options });
}
export const ReduxEnterprisePlanAndInitiativesDocument = gql`
    query ReduxEnterprisePlanAndInitiatives($planId: ID!) {
  userCanViewReductions: userHasPermission(permission: ViewReductions)
  userCanManageReductions: userHasPermission(permission: ManageReductionPlans)
  organization {
    id
    sbtiTargetExclusions {
      ...SbtiTargetExclusionFields
    }
  }
  plan(id: $planId) {
    ...PlanWithForecastFields
  }
}
    ${SbtiTargetExclusionFieldsFragmentDoc}
${PlanWithForecastFieldsFragmentDoc}`;

export function useReduxEnterprisePlanAndInitiativesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReduxEnterprisePlanAndInitiativesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReduxEnterprisePlanAndInitiativesQuery,
    GQTypes.GQReduxEnterprisePlanAndInitiativesQueryVariables
  >({ query: ReduxEnterprisePlanAndInitiativesDocument, ...options });
}
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($input: UpdatePlanAndArtifactsInput!) {
  updatePlan(input: $input) {
    plan {
      ...PlanWithForecastFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}`;

export function useUpdatePlanMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanMutation,
    GQTypes.GQUpdatePlanMutationVariables
  >(UpdatePlanDocument);
}
export const DuplicatePlanDocument = gql`
    mutation DuplicatePlan($input: DuplicatePlanInput!) {
  duplicatePlan(input: $input) {
    plan {
      ...PlanWithForecastFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}`;

export function useDuplicatePlanMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicatePlanMutation,
    GQTypes.GQDuplicatePlanMutationVariables
  >(DuplicatePlanDocument);
}
export const UpdatePlanTargetCategorySetDescriptionDocument = gql`
    mutation UpdatePlanTargetCategorySetDescription($input: UpdatePlanTargetInput!) {
  updatePlanTarget(input: $input) {
    planTarget {
      ...PlanTargetFields
    }
  }
}
    ${PlanTargetFieldsFragmentDoc}`;

export function useUpdatePlanTargetCategorySetDescriptionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanTargetCategorySetDescriptionMutation,
    GQTypes.GQUpdatePlanTargetCategorySetDescriptionMutationVariables
  >(UpdatePlanTargetCategorySetDescriptionDocument);
}
export const PeerReductionTargetsDocument = gql`
    query PeerReductionTargets($orgId: ID!) {
  peerCompanyGroups(orgId: $orgId) {
    id
    name
    members {
      id
      company {
        id
        name
        fullLogoUrl
        disclosuresV2(opts: {targetFilters: {}, climateCommitmentFilters: {}}) {
          id
          targets {
            id
          }
          climateCommitments {
            ...PeerCommitmentFields
          }
        }
      }
    }
  }
}
    ${PeerCommitmentFieldsFragmentDoc}`;

export function usePeerReductionTargetsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerReductionTargetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerReductionTargetsQuery,
    GQTypes.GQPeerReductionTargetsQueryVariables
  >({ query: PeerReductionTargetsDocument, ...options });
}
export const CreatePrecomputedActivityBasedInitiativeDocument = gql`
    mutation CreatePrecomputedActivityBasedInitiative($input: CreatePrecomputedActivityBasedInitiativeInput!) {
  createPrecomputedActivityBasedInitiative(input: $input) {
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useCreatePrecomputedActivityBasedInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePrecomputedActivityBasedInitiativeMutation,
    GQTypes.GQCreatePrecomputedActivityBasedInitiativeMutationVariables
  >(CreatePrecomputedActivityBasedInitiativeDocument);
}
export const UpdatePrecomputedActivityBasedInitiativeDocument = gql`
    mutation UpdatePrecomputedActivityBasedInitiative($input: UpdatePrecomputedActivityBasedInitiativeInput!) {
  updatePrecomputedActivityBasedInitiative(input: $input) {
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useUpdatePrecomputedActivityBasedInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePrecomputedActivityBasedInitiativeMutation,
    GQTypes.GQUpdatePrecomputedActivityBasedInitiativeMutationVariables
  >(UpdatePrecomputedActivityBasedInitiativeDocument);
}
export const CreateFootprintSliceInitiativeDocument = gql`
    mutation CreateFootprintSliceInitiative($input: CreateFootprintSliceInitiativeInput!) {
  createFootprintSliceInitiative(input: $input) {
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useCreateFootprintSliceInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintSliceInitiativeMutation,
    GQTypes.GQCreateFootprintSliceInitiativeMutationVariables
  >(CreateFootprintSliceInitiativeDocument);
}
export const UpdateFootprintSliceInitiativeDocument = gql`
    mutation UpdateFootprintSliceInitiative($input: UpdateFootprintSliceInitiativeInput!) {
  updateFootprintSliceInitiative(input: $input) {
    initiative {
      ...ReduxInitiativeFields
    }
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${ReduxInitiativeFieldsFragmentDoc}
${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useUpdateFootprintSliceInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFootprintSliceInitiativeMutation,
    GQTypes.GQUpdateFootprintSliceInitiativeMutationVariables
  >(UpdateFootprintSliceInitiativeDocument);
}
export const CreatePlanTargetReduxDocument = gql`
    mutation CreatePlanTargetRedux($input: CreatePlanTargetReduxInput!) {
  createPlanTargetRedux(input: $input) {
    plan {
      id
      targets {
        ...PlanTargetFields
      }
    }
  }
}
    ${PlanTargetFieldsFragmentDoc}`;

export function useCreatePlanTargetReduxMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePlanTargetReduxMutation,
    GQTypes.GQCreatePlanTargetReduxMutationVariables
  >(CreatePlanTargetReduxDocument);
}
export const CreateRenewableElectricityTargetDocument = gql`
    mutation CreateRenewableElectricityTarget($input: CreateRenewableElectricityTargetInput!) {
  createRenewableElectricityTarget(input: $input) {
    plan {
      id
      targets {
        ...PlanTargetFields
      }
    }
  }
}
    ${PlanTargetFieldsFragmentDoc}`;

export function useCreateRenewableElectricityTargetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateRenewableElectricityTargetMutation,
    GQTypes.GQCreateRenewableElectricityTargetMutationVariables
  >(CreateRenewableElectricityTargetDocument);
}
export const GetFootprintVersionChangelogReduxDocument = gql`
    query GetFootprintVersionChangelogRedux {
  footprintChangelog {
    id
    versions(
      filters: {footprintVersionKindFilter: Publish, excludeIsUserChangelogHidden: false}
    ) {
      edges {
        node {
          ...FootprintVersionForRedux
        }
      }
    }
  }
}
    ${FootprintVersionForReduxFragmentDoc}`;

export function useGetFootprintVersionChangelogReduxQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintVersionChangelogReduxQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintVersionChangelogReduxQuery,
    GQTypes.GQGetFootprintVersionChangelogReduxQueryVariables
  >({ query: GetFootprintVersionChangelogReduxDocument, ...options });
}
export const AcknowledgeLatestFootprintSnapshotReduxDocument = gql`
    mutation AcknowledgeLatestFootprintSnapshotRedux($input: UpdateForecastInput!) {
  updateForecast(input: $input) {
    forecast {
      id
      acknowledgedFootprintSnapshotId
      footprintSnapshotId
    }
  }
}
    `;

export function useAcknowledgeLatestFootprintSnapshotReduxMutation() {
  return Urql.useMutation<
    GQTypes.GQAcknowledgeLatestFootprintSnapshotReduxMutation,
    GQTypes.GQAcknowledgeLatestFootprintSnapshotReduxMutationVariables
  >(AcknowledgeLatestFootprintSnapshotReduxDocument);
}
export const DeleteInitiativeDocument = gql`
    mutation DeleteInitiative($initiativeId: ID!) {
  deleteInitiative(input: {initiativeId: $initiativeId}) {
    initiative {
      ...ReduxInitiativeFields
    }
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${ReduxInitiativeFieldsFragmentDoc}
${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useDeleteInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteInitiativeMutation,
    GQTypes.GQDeleteInitiativeMutationVariables
  >(DeleteInitiativeDocument);
}
export const DuplicateInitiativeDocument = gql`
    mutation DuplicateInitiative($duplicatedInitiativeId: ID!, $kind: InitiativeKind!, $planId: ID!) {
  duplicateInitiative(
    input: {duplicatedInitiativeId: $duplicatedInitiativeId, kind: $kind, planId: $planId}
  ) {
    initiative {
      id
    }
    plan {
      ...TargetAndInitiativeForecastFields
    }
  }
}
    ${TargetAndInitiativeForecastFieldsFragmentDoc}`;

export function useDuplicateInitiativeMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicateInitiativeMutation,
    GQTypes.GQDuplicateInitiativeMutationVariables
  >(DuplicateInitiativeDocument);
}
export const AllPlansAndForecastScenariosDocument = gql`
    query AllPlansAndForecastScenarios {
  plans(includeHidden: true) {
    edges {
      node {
        ...PlanForPlanTab
      }
    }
  }
  forecast {
    ...ForecastForScenarioSelector
  }
}
    ${PlanForPlanTabFragmentDoc}
${ForecastForScenarioSelectorFragmentDoc}`;

export function useAllPlansAndForecastScenariosQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllPlansAndForecastScenariosQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllPlansAndForecastScenariosQuery,
    GQTypes.GQAllPlansAndForecastScenariosQueryVariables
  >({ query: AllPlansAndForecastScenariosDocument, ...options });
}
export const CreatePlanDashboardDocument = gql`
    mutation CreatePlanDashboard($termLength: SBTTermLength) {
  createPlanWithDefaults(input: {termLength: $termLength}) {
    plan {
      ...PlanWithForecastFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}`;

export function useCreatePlanDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePlanDashboardMutation,
    GQTypes.GQCreatePlanDashboardMutationVariables
  >(CreatePlanDashboardDocument);
}
export const RenamePlanFromPlanTabDocument = gql`
    mutation RenamePlanFromPlanTab($planId: ID!, $newName: String!) {
  updatePlanSettings(input: {planId: $planId, name: $newName}) {
    plan {
      id
      name
    }
  }
}
    `;

export function useRenamePlanFromPlanTabMutation() {
  return Urql.useMutation<
    GQTypes.GQRenamePlanFromPlanTabMutation,
    GQTypes.GQRenamePlanFromPlanTabMutationVariables
  >(RenamePlanFromPlanTabDocument);
}
export const DuplicatePlanFromPlanTabDocument = gql`
    mutation DuplicatePlanFromPlanTab($input: DuplicatePlanInput!) {
  duplicatePlan(input: $input) {
    plan {
      id
      name
    }
  }
}
    `;

export function useDuplicatePlanFromPlanTabMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicatePlanFromPlanTabMutation,
    GQTypes.GQDuplicatePlanFromPlanTabMutationVariables
  >(DuplicatePlanFromPlanTabDocument);
}
export const DeletePlanFromPlanTabDocument = gql`
    mutation DeletePlanFromPlanTab($input: DeletePlanInput!) {
  deletePlan(input: $input)
}
    `;

export function useDeletePlanFromPlanTabMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePlanFromPlanTabMutation,
    GQTypes.GQDeletePlanFromPlanTabMutationVariables
  >(DeletePlanFromPlanTabDocument);
}
export const ElectricityConsumptionBreakdownDocument = gql`
    query ElectricityConsumptionBreakdown($planId: ID!) {
  plan(id: $planId) {
    id
    forecast {
      id
      electricityConsumptionBreakdown {
        yearStart
        renewableEnergyFraction
      }
    }
  }
}
    `;

export function useElectricityConsumptionBreakdownQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQElectricityConsumptionBreakdownQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQElectricityConsumptionBreakdownQuery,
    GQTypes.GQElectricityConsumptionBreakdownQueryVariables
  >({ query: ElectricityConsumptionBreakdownDocument, ...options });
}
export const UpdatePlanTargetReduxDocument = gql`
    mutation UpdatePlanTargetRedux($input: UpdatePlanTargetInput!) {
  updatePlanTarget(input: $input) {
    planTarget {
      ...PlanTargetFields
    }
    forecast {
      id
      validYearsForIntervalEnd
    }
  }
}
    ${PlanTargetFieldsFragmentDoc}`;

export function useUpdatePlanTargetReduxMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanTargetReduxMutation,
    GQTypes.GQUpdatePlanTargetReduxMutationVariables
  >(UpdatePlanTargetReduxDocument);
}
export const DeletePlanTargetReduxDocument = gql`
    mutation DeletePlanTargetRedux($input: DeletePlanTargetInput!) {
  deletePlanTarget(input: $input) {
    plan {
      id
      targets {
        id
        deletedAt
      }
    }
  }
}
    `;

export function useDeletePlanTargetReduxMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePlanTargetReduxMutation,
    GQTypes.GQDeletePlanTargetReduxMutationVariables
  >(DeletePlanTargetReduxDocument);
}
export const PlansForCompareSheetDocument = gql`
    query PlansForCompareSheet {
  plans(includeHidden: true) {
    edges {
      node {
        id
        isHidden
        ...PlanWithForecastFields
      }
    }
  }
  organization {
    id
    sbtiTargetExclusions {
      ...SbtiTargetExclusionFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}
${SbtiTargetExclusionFieldsFragmentDoc}`;

export function usePlansForCompareSheetQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPlansForCompareSheetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPlansForCompareSheetQuery,
    GQTypes.GQPlansForCompareSheetQueryVariables
  >({ query: PlansForCompareSheetDocument, ...options });
}
export const GenerateCustomIntensityConfigIdDocument = gql`
    mutation GenerateCustomIntensityConfigId($input: GenerateCustomIntensityConfigIdInput!) {
  generateCustomIntensityConfigId(input: $input) {
    id
  }
}
    `;

export function useGenerateCustomIntensityConfigIdMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateCustomIntensityConfigIdMutation,
    GQTypes.GQGenerateCustomIntensityConfigIdMutationVariables
  >(GenerateCustomIntensityConfigIdDocument);
}
export const GenerateForecastScenarioIdDocument = gql`
    mutation GenerateForecastScenarioId($input: GenerateForecastScenarioIdInput!) {
  generateForecastScenarioId(input: $input) {
    id
  }
}
    `;

export function useGenerateForecastScenarioIdMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateForecastScenarioIdMutation,
    GQTypes.GQGenerateForecastScenarioIdMutationVariables
  >(GenerateForecastScenarioIdDocument);
}
export const CreateUpdateAndDeleteForecastScenariosDocument = gql`
    mutation CreateUpdateAndDeleteForecastScenarios($input: CreateUpdateAndDeleteForecastScenariosInput!) {
  createUpdateAndDeleteForecastScenarios(input: $input) {
    forecast {
      id
      scenarios {
        ...ForecastScenarioFields
      }
    }
  }
}
    ${ForecastScenarioFieldsFragmentDoc}`;

export function useCreateUpdateAndDeleteForecastScenariosMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUpdateAndDeleteForecastScenariosMutation,
    GQTypes.GQCreateUpdateAndDeleteForecastScenariosMutationVariables
  >(CreateUpdateAndDeleteForecastScenariosDocument);
}
export const GetReductionsContributionsDocument = gql`
    query GetReductionsContributions($footprintKind: String!, $footprintVersion: String, $timeseriesKind: ColumnDimension!, $columns: [String!], $enableReduxContributionsIntervalOptimization: Boolean) {
  footprint: legacyFootprint(
    shouldConvertCurrency: false
    version: $footprintVersion
  ) {
    id
    summary(footprintKind: $footprintKind) {
      id
      timeseries {
        id
        series {
          data {
            date
            value
            headcount
            revenue
            customIntensities {
              customIntensityId
              value
            }
          }
        }
      }
      removalTimeseries: timeseries(filters: {inclusionKind: Removal}) {
        id
        series {
          data {
            date
            value
            headcount
            revenue
            customIntensities {
              customIntensityId
              value
            }
          }
        }
      }
      customIntensityMetadata {
        id
      }
    }
  }
  footprintAnalysis(version: $footprintVersion) {
    id
    reductionsContributionsData(
      timeseriesKind: $timeseriesKind
      footprintKind: $footprintKind
      columns: $columns
      enableReduxContributionsIntervalOptimization: $enableReduxContributionsIntervalOptimization
    ) {
      id
      data
    }
  }
}
    `;

export function useGetReductionsContributionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReductionsContributionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReductionsContributionsQuery,
    GQTypes.GQGetReductionsContributionsQueryVariables
  >({ query: GetReductionsContributionsDocument, ...options });
}
export const GetForecastFootprintSnapshotIdDocument = gql`
    query GetForecastFootprintSnapshotId {
  forecast {
    id
    footprintSnapshotId
  }
}
    `;

export function useGetForecastFootprintSnapshotIdQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetForecastFootprintSnapshotIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetForecastFootprintSnapshotIdQuery,
    GQTypes.GQGetForecastFootprintSnapshotIdQueryVariables
  >({ query: GetForecastFootprintSnapshotIdDocument, ...options });
}
export const AssignToContainerDocument = gql`
    mutation AssignToContainer($input: AssignToContainerInput!, $reportId: ID!) {
  assignToContainer(input: $input) {
    container {
      id
      assignees(reportId: $reportId) {
        id
        displayName
        name
      }
      items {
        ...AssigneeInfoForContainedQuestions
      }
    }
  }
}
    ${AssigneeInfoForContainedQuestionsFragmentDoc}`;

export function useAssignToContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQAssignToContainerMutation,
    GQTypes.GQAssignToContainerMutationVariables
  >(AssignToContainerDocument);
}
export const GetReportQuestionContainerAssignmentEmailDocument = gql`
    query GetReportQuestionContainerAssignmentEmail($input: AssignReportQuestionContainerPreviewEmailInput!) {
  assignReportQuestionContainerPreviewEmail(input: $input) {
    ...EmailContentsFields
  }
}
    ${EmailContentsFieldsFragmentDoc}`;

export function useGetReportQuestionContainerAssignmentEmailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerAssignmentEmailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerAssignmentEmailQuery,
    GQTypes.GQGetReportQuestionContainerAssignmentEmailQueryVariables
  >({ query: GetReportQuestionContainerAssignmentEmailDocument, ...options });
}
export const CreateReportWithCustomConfigDocument = gql`
    mutation CreateReportWithCustomConfig($input: CreateReportWithCustomConfigInput) {
  createReportWithCustomConfig(input: $input) {
    report {
      id
      name
      reportKind
      reportStatus
    }
  }
}
    `;

export function useCreateReportWithCustomConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportWithCustomConfigMutation,
    GQTypes.GQCreateReportWithCustomConfigMutationVariables
  >(CreateReportWithCustomConfigDocument);
}
export const RenameReportDocument = gql`
    mutation RenameReport($input: RenameReportInput!) {
  renameReport(input: $input) {
    report {
      id
      name
    }
  }
}
    `;

export function useRenameReportMutation() {
  return Urql.useMutation<
    GQTypes.GQRenameReportMutation,
    GQTypes.GQRenameReportMutationVariables
  >(RenameReportDocument);
}
export const ReportCompletionDocument = gql`
    query ReportCompletion($input: ReportProgressInput!) {
  reportProgress(input: $input) {
    id
    percentComplete
    reasons
  }
}
    `;

export function useReportCompletionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportCompletionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportCompletionQuery,
    GQTypes.GQReportCompletionQueryVariables
  >({ query: ReportCompletionDocument, ...options });
}
export const CreateReportWithConfigDocument = gql`
    mutation CreateReportWithConfig($input: CreateReportWithConfigInput!) {
  createReportWithConfig(input: $input) {
    id
  }
}
    `;

export function useCreateReportWithConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportWithConfigMutation,
    GQTypes.GQCreateReportWithConfigMutationVariables
  >(CreateReportWithConfigDocument);
}
export const ReportsPageDocument = gql`
    query ReportsPage($isAdmin: Boolean!) {
  formalReports {
    edges {
      node {
        __typename
        id
        ...ReportPageReport
      }
    }
  }
  footprintAnalysis @include(if: $isAdmin) {
    id
    customReports {
      id
      ...ReportPageCustomReport
    }
  }
  userHasPermission(permission: Admin)
  footprintAnalysis {
    id
    footprintInterval
  }
  footprint: legacyFootprint {
    id
  }
  organization {
    id
    currency
  }
}
    ${ReportPageReportFragmentDoc}
${ReportPageCustomReportFragmentDoc}`;

export function useReportsPageQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQReportsPageQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQReportsPageQuery,
    GQTypes.GQReportsPageQueryVariables
  >({ query: ReportsPageDocument, ...options });
}
export const GetDrilldownForReportQuestionDocument = gql`
    query GetDrilldownForReportQuestion($reportId: ID!, $questionId: ID!) {
  drilldownForReportQuestion(
    input: {reportId: $reportId, questionId: $questionId}
  ) {
    report {
      ...ReportWithConfigFields
      id
      name
    }
    question {
      ...ReportQuestionUsingSavedViewFields
    }
    mergedControlsJson
    savedViewControlsJson
    reportControlsJson
    cardControlsJson
  }
}
    ${ReportWithConfigFieldsFragmentDoc}
${ReportQuestionUsingSavedViewFieldsFragmentDoc}`;

export function useGetDrilldownForReportQuestionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDrilldownForReportQuestionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDrilldownForReportQuestionQuery,
    GQTypes.GQGetDrilldownForReportQuestionQueryVariables
  >({ query: GetDrilldownForReportQuestionDocument, ...options });
}
export const CreateCsrdDmaDocument = gql`
    mutation CreateCsrdDma($input: CreateCsrdDmaInput!) {
  createCsrdDma(input: $input) {
    updatedReport {
      id
      dmaId
    }
    dmaTopics {
      topicIdentifier
      isMaterial
    }
  }
}
    `;

export function useCreateCsrdDmaMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCsrdDmaMutation,
    GQTypes.GQCreateCsrdDmaMutationVariables
  >(CreateCsrdDmaDocument);
}
export const FetchCsrdDmaValidityDocument = gql`
    query fetchCsrdDmaValidity($dmaId: ID!) {
  csrdDma(id: $dmaId) {
    validityStart
    validityEnd
  }
}
    `;

export function useFetchCsrdDmaValidityQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFetchCsrdDmaValidityQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFetchCsrdDmaValidityQuery,
    GQTypes.GQFetchCsrdDmaValidityQueryVariables
  >({ query: FetchCsrdDmaValidityDocument, ...options });
}
export const UpdateReportConfigDocument = gql`
    mutation UpdateReportConfig($reportId: ID!, $input: UpdateReportConfigDashboardInput!) {
  updateReportConfigDashboard(input: $input) {
    reportConfig {
      ...ReportConfigFields
    }
  }
}
    ${ReportConfigFieldsFragmentDoc}`;

export function useUpdateReportConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportConfigMutation,
    GQTypes.GQUpdateReportConfigMutationVariables
  >(UpdateReportConfigDocument);
}
export const GetReportQuestionsListDocument = gql`
    query GetReportQuestionsList($reportId: ID!, $input: ReportAllQuestionsInput, $includeReportingIntention: Boolean = false) {
  reportWithConfig(id: $reportId) {
    ...ReportWithConfigForTable
  }
}
    ${ReportWithConfigForTableFragmentDoc}`;

export function useGetReportQuestionsListQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionsListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionsListQuery,
    GQTypes.GQGetReportQuestionsListQueryVariables
  >({ query: GetReportQuestionsListDocument, ...options });
}
export const GetReportInputsDocument = gql`
    query GetReportInputs($reportId: ID!) {
  reportWithConfig(id: $reportId) {
    id
    config {
      id
      inputs {
        ...ReportQuestionFields
        resolvedStatus(reportId: $reportId) {
          id
          status
          blockingMessage
          excludingQuestionId
        }
        resolvedInputs(reportId: $reportId) {
          __typename
          ... on ResolvedReportQuestionInputsFailure {
            questionIds
            errorMessage
          }
          ... on ResolvedReportQuestionInputsSuccess {
            inputs
            unparsedInputs
          }
        }
        answer(reportId: $reportId) {
          id
          outputJson
        }
      }
    }
  }
}
    ${ReportQuestionFieldsFragmentDoc}`;

export function useGetReportInputsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportInputsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportInputsQuery,
    GQTypes.GQGetReportInputsQueryVariables
  >({ query: GetReportInputsDocument, ...options });
}
export const GetContainerAndQuestionsForClimateIntelligencePanelDocument = gql`
    query GetContainerAndQuestionsForClimateIntelligencePanel($containerId: ID!, $reportId: ID!) {
  reportQuestionContainer(id: $containerId) {
    id
    guidanceJson
    items {
      __typename
      ... on ReportQuestion {
        ...ReportQuestionForBenchmark
      }
    }
  }
}
    ${ReportQuestionForBenchmarkFragmentDoc}`;

export function useGetContainerAndQuestionsForClimateIntelligencePanelQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerAndQuestionsForClimateIntelligencePanelQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerAndQuestionsForClimateIntelligencePanelQuery,
    GQTypes.GQGetContainerAndQuestionsForClimateIntelligencePanelQueryVariables
  >({
    query: GetContainerAndQuestionsForClimateIntelligencePanelDocument,
    ...options,
  });
}
export const AddHeaderToCustomReportDocument = gql`
    mutation AddHeaderToCustomReport($input: AddHeaderToCustomReportInput!, $reportId: ID!) {
  addHeaderToCustomReport(input: $input) {
    report {
      ...CustomReportContent
    }
  }
}
    ${CustomReportContentFragmentDoc}`;

export function useAddHeaderToCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQAddHeaderToCustomReportMutation,
    GQTypes.GQAddHeaderToCustomReportMutationVariables
  >(AddHeaderToCustomReportDocument);
}
export const GetCustomReportControlsDocument = gql`
    query GetCustomReportControls($reportId: ID!) {
  customReportControls(input: {reportId: $reportId}) {
    customReportControlsQuestion {
      id
    }
  }
}
    `;

export function useGetCustomReportControlsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomReportControlsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomReportControlsQuery,
    GQTypes.GQGetCustomReportControlsQueryVariables
  >({ query: GetCustomReportControlsDocument, ...options });
}
export const RestoreDeletedSavedViewDocument = gql`
    mutation RestoreDeletedSavedView($input: RestoreDeletedSavedViewInput!) {
  restoreDeletedSavedView(input: $input) {
    ...BiSavedView
  }
}
    ${BiSavedViewFragmentDoc}`;

export function useRestoreDeletedSavedViewMutation() {
  return Urql.useMutation<
    GQTypes.GQRestoreDeletedSavedViewMutation,
    GQTypes.GQRestoreDeletedSavedViewMutationVariables
  >(RestoreDeletedSavedViewDocument);
}
export const NotifyReportAssigneesDocument = gql`
    mutation NotifyReportAssignees($input: NotifyReportAssigneesInput!) {
  notifyReportAssignees(input: $input) {
    notifiedUserIds
  }
}
    `;

export function useNotifyReportAssigneesMutation() {
  return Urql.useMutation<
    GQTypes.GQNotifyReportAssigneesMutation,
    GQTypes.GQNotifyReportAssigneesMutationVariables
  >(NotifyReportAssigneesDocument);
}
export const GetReportQuestionDocument = gql`
    query GetReportQuestion($id: ID!, $reportId: ID!, $includeReportingIntention: Boolean!) {
  reportQuestion(id: $id) {
    ...ReportQuestionFieldsWithReportingIntention
  }
}
    ${ReportQuestionFieldsWithReportingIntentionFragmentDoc}`;

export function useGetReportQuestionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionQuery,
    GQTypes.GQGetReportQuestionQueryVariables
  >({ query: GetReportQuestionDocument, ...options });
}
export const GetReportQuestionAssigneesDocument = gql`
    query GetReportQuestionAssignees($questionId: ID!, $reportId: ID!) {
  reportQuestion(id: $questionId) {
    id
    assignees(reportId: $reportId) {
      id
      displayName
      name
    }
  }
}
    `;

export function useGetReportQuestionAssigneesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionAssigneesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionAssigneesQuery,
    GQTypes.GQGetReportQuestionAssigneesQueryVariables
  >({ query: GetReportQuestionAssigneesDocument, ...options });
}
export const UpdateReportQuestionAssigneesDocument = gql`
    mutation UpdateReportQuestionAssignees($input: UpdateReportQuestionAssigneesInput!, $reportId: ID!) {
  updateReportQuestionAssignees(input: $input) {
    questions {
      id
      assignees(reportId: $reportId) {
        id
        displayName
        name
      }
      parentContainer {
        ...ParentContainerAssignees
      }
    }
  }
}
    ${ParentContainerAssigneesFragmentDoc}`;

export function useUpdateReportQuestionAssigneesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionAssigneesMutation,
    GQTypes.GQUpdateReportQuestionAssigneesMutationVariables
  >(UpdateReportQuestionAssigneesDocument);
}
export const GetReportQuestionForMissingInputsDocument = gql`
    query GetReportQuestionForMissingInputs($id: ID!) {
  reportQuestion(id: $id) {
    id
    parentContainer {
      id
      label
    }
  }
}
    `;

export function useGetReportQuestionForMissingInputsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionForMissingInputsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionForMissingInputsQuery,
    GQTypes.GQGetReportQuestionForMissingInputsQueryVariables
  >({ query: GetReportQuestionForMissingInputsDocument, ...options });
}
export const AcknowledgeReportAnswerWarningDocument = gql`
    mutation AcknowledgeReportAnswerWarning($input: AcknowledgeReportAnswerWarningInput!) {
  acknowledgeReportAnswerWarning(input: $input) {
    ...ReportAnswerWarningForCard
  }
}
    ${ReportAnswerWarningForCardFragmentDoc}`;

export function useAcknowledgeReportAnswerWarningMutation() {
  return Urql.useMutation<
    GQTypes.GQAcknowledgeReportAnswerWarningMutation,
    GQTypes.GQAcknowledgeReportAnswerWarningMutationVariables
  >(AcknowledgeReportAnswerWarningDocument);
}
export const GetReportQuestionFieldsForDebugDocument = gql`
    query GetReportQuestionFieldsForDebug($id: ID!, $reportId: ID!) {
  reportQuestion(id: $id) {
    ...ReportQuestionFieldsForDebug
  }
}
    ${ReportQuestionFieldsForDebugFragmentDoc}`;

export function useGetReportQuestionFieldsForDebugQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionFieldsForDebugQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionFieldsForDebugQuery,
    GQTypes.GQGetReportQuestionFieldsForDebugQueryVariables
  >({ query: GetReportQuestionFieldsForDebugDocument, ...options });
}
export const GetReportQuestionIdentifyingFieldsDocument = gql`
    query GetReportQuestionIdentifyingFields($id: ID!) {
  reportQuestion(id: $id) {
    ...ReportQuestionFields
  }
}
    ${ReportQuestionFieldsFragmentDoc}`;

export function useGetReportQuestionIdentifyingFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionIdentifyingFieldsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionIdentifyingFieldsQuery,
    GQTypes.GQGetReportQuestionIdentifyingFieldsQueryVariables
  >({ query: GetReportQuestionIdentifyingFieldsDocument, ...options });
}
export const GetReportQuestionContainerWarningsDocument = gql`
    query GetReportQuestionContainerWarnings($containerId: ID!, $reportId: ID!) {
  reportQuestionContainer(id: $containerId) {
    id
    questionsWithResolvedWarnings(reportId: $reportId) {
      id
      resolvedWarnings(reportId: $reportId) {
        ...ReportAnswerWarningForCard
      }
    }
  }
}
    ${ReportAnswerWarningForCardFragmentDoc}`;

export function useGetReportQuestionContainerWarningsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerWarningsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerWarningsQuery,
    GQTypes.GQGetReportQuestionContainerWarningsQueryVariables
  >({ query: GetReportQuestionContainerWarningsDocument, ...options });
}
export const GetReportQuestionContainerForCardDocument = gql`
    query GetReportQuestionContainerForCard($containerId: ID!, $reportId: ID!) {
  reportQuestionContainer(id: $containerId) {
    id
    label
    description
    guidanceJson
    resolvedStatus(reportId: $reportId) {
      id
      status
      blockingMessage
    }
  }
}
    `;

export function useGetReportQuestionContainerForCardQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerForCardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerForCardQuery,
    GQTypes.GQGetReportQuestionContainerForCardQueryVariables
  >({ query: GetReportQuestionContainerForCardDocument, ...options });
}
export const GetReportQuestionContainerProgressForCardDocument = gql`
    query GetReportQuestionContainerProgressForCard($containerId: ID!, $reportId: ID!) {
  reportQuestionContainer(id: $containerId) {
    id
    automaticProgress(reportId: $reportId)
    overriddenProgress(reportId: $reportId)
  }
}
    `;

export function useGetReportQuestionContainerProgressForCardQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerProgressForCardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerProgressForCardQuery,
    GQTypes.GQGetReportQuestionContainerProgressForCardQueryVariables
  >({ query: GetReportQuestionContainerProgressForCardDocument, ...options });
}
export const GetContainerAssigneesDocument = gql`
    query GetContainerAssignees($containerId: ID!, $reportId: ID!) {
  reportQuestionContainer(id: $containerId) {
    id
    assignees(reportId: $reportId) {
      id
      displayName
      name
    }
  }
}
    `;

export function useGetContainerAssigneesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerAssigneesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerAssigneesQuery,
    GQTypes.GQGetContainerAssigneesQueryVariables
  >({ query: GetContainerAssigneesDocument, ...options });
}
export const UnassignFromContainerDocument = gql`
    mutation UnassignFromContainer($input: UnassignFromContainerInput!, $reportId: ID!) {
  unassignFromContainer(input: $input) {
    container {
      id
      assignees(reportId: $reportId) {
        id
        displayName
        name
      }
      items {
        ...AssigneeInfoForContainedQuestions
      }
    }
  }
}
    ${AssigneeInfoForContainedQuestionsFragmentDoc}`;

export function useUnassignFromContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQUnassignFromContainerMutation,
    GQTypes.GQUnassignFromContainerMutationVariables
  >(UnassignFromContainerDocument);
}
export const OverrideContainerProgressDocument = gql`
    mutation OverrideContainerProgress($input: OverrideContainerProgressInput!, $reportId: ID!) {
  overrideContainerProgress(input: $input) {
    id
    overriddenProgress(reportId: $reportId)
  }
}
    `;

export function useOverrideContainerProgressMutation() {
  return Urql.useMutation<
    GQTypes.GQOverrideContainerProgressMutation,
    GQTypes.GQOverrideContainerProgressMutationVariables
  >(OverrideContainerProgressDocument);
}
export const GetContainerAndQuestionsForSheetContentsPanelDocument = gql`
    query GetContainerAndQuestionsForSheetContentsPanel($containerId: ID!, $reportId: ID!) {
  reportQuestionContainer(id: $containerId) {
    id
    guidanceJson
    fullWidth
    items {
      __typename
      ... on ReportQuestion {
        id
        identifier
        resolvedStatus(reportId: $reportId) {
          id
          status
        }
      }
    }
  }
}
    `;

export function useGetContainerAndQuestionsForSheetContentsPanelQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerAndQuestionsForSheetContentsPanelQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerAndQuestionsForSheetContentsPanelQuery,
    GQTypes.GQGetContainerAndQuestionsForSheetContentsPanelQueryVariables
  >({
    query: GetContainerAndQuestionsForSheetContentsPanelDocument,
    ...options,
  });
}
export const SetReportingIntentionForQuestionDocument = gql`
    mutation SetReportingIntentionForQuestion($reportId: ID!, $questionIds: [ID!]!, $intention: ReportingIntention!, $note: String) {
  updateReportingIntentions(
    input: {reportId: $reportId, reportQuestionIds: $questionIds, intention: $intention, reason: $note}
  ) {
    reportQuestions {
      id
      resolvedReportingIntention(reportId: $reportId) {
        id
        intention
        event {
          createdAt
          createdBy {
            id
            displayName
            name
          }
          reason
        }
      }
      assignees(reportId: $reportId) {
        id
        displayName
        name
      }
    }
  }
}
    `;

export function useSetReportingIntentionForQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQSetReportingIntentionForQuestionMutation,
    GQTypes.GQSetReportingIntentionForQuestionMutationVariables
  >(SetReportingIntentionForQuestionDocument);
}
export const AddMetricsToCustomReportDocument = gql`
    mutation AddMetricsToCustomReport($input: AddMetricsToCustomReportInput!, $reportId: ID!) {
  addMetricsToCustomReport(input: $input) {
    report {
      ...CustomReportContent
    }
  }
}
    ${CustomReportContentFragmentDoc}`;

export function useAddMetricsToCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQAddMetricsToCustomReportMutation,
    GQTypes.GQAddMetricsToCustomReportMutationVariables
  >(AddMetricsToCustomReportDocument);
}
export const GetCdpWorkflowSyncStatusDocument = gql`
    query GetCdpWorkflowSyncStatus($workflowId: String!, $reportId: ID!) {
  cdpSyncWorkflowStatus(workflowId: $workflowId) {
    id
    workflowId
    questions {
      ...QuestionStatusForCdpSyncDialog
    }
  }
}
    ${QuestionStatusForCdpSyncDialogFragmentDoc}`;

export function useGetCdpWorkflowSyncStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCdpWorkflowSyncStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCdpWorkflowSyncStatusQuery,
    GQTypes.GQGetCdpWorkflowSyncStatusQueryVariables
  >({ query: GetCdpWorkflowSyncStatusDocument, ...options });
}
export const StartCdpSyncAnswersDocument = gql`
    mutation StartCdpSyncAnswers($input: StartCdpSyncAnswersInput!) {
  startCdpSyncAnswers(input: $input) {
    id
    workflowId
  }
}
    `;

export function useStartCdpSyncAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQStartCdpSyncAnswersMutation,
    GQTypes.GQStartCdpSyncAnswersMutationVariables
  >(StartCdpSyncAnswersDocument);
}
export const GetReportQuestionNotesDocument = gql`
    query GetReportQuestionNotes($id: ID!, $reportId: ID!) {
  __typename
  reportQuestion(id: $id) {
    __typename
    id
    notes(reportId: $reportId) {
      id
      ...ReportQuestionNoteFields
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useGetReportQuestionNotesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionNotesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionNotesQuery,
    GQTypes.GQGetReportQuestionNotesQueryVariables
  >({ query: GetReportQuestionNotesDocument, ...options });
}
export const AddReportQuestionNoteDocument = gql`
    mutation AddReportQuestionNote($note: AddReportQuestionNoteInput!, $reportId: ID!) {
  addReportQuestionNote(input: $note) {
    reportQuestion {
      id
      notes(reportId: $reportId) {
        id
        ...ReportQuestionNoteFields
      }
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useAddReportQuestionNoteMutation() {
  return Urql.useMutation<
    GQTypes.GQAddReportQuestionNoteMutation,
    GQTypes.GQAddReportQuestionNoteMutationVariables
  >(AddReportQuestionNoteDocument);
}
export const UpdateReportQuestionNotesDocument = gql`
    mutation UpdateReportQuestionNotes($input: UpdateReportQuestionNoteInput!, $reportId: ID!) {
  updateReportQuestionNote(input: $input) {
    reportQuestion {
      id
      notes(reportId: $reportId) {
        id
        ...ReportQuestionNoteFields
      }
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useUpdateReportQuestionNotesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionNotesMutation,
    GQTypes.GQUpdateReportQuestionNotesMutationVariables
  >(UpdateReportQuestionNotesDocument);
}
export const UpdateReportQuestionNoteItemDocument = gql`
    mutation UpdateReportQuestionNoteItem($input: UpdateReportQuestionNoteItemInput!, $reportId: ID!) {
  updateReportQuestionNoteItem(input: $input) {
    reportQuestion {
      id
      notes(reportId: $reportId) {
        id
        ...ReportQuestionNoteFields
      }
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useUpdateReportQuestionNoteItemMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionNoteItemMutation,
    GQTypes.GQUpdateReportQuestionNoteItemMutationVariables
  >(UpdateReportQuestionNoteItemDocument);
}
export const DeleteReportQuestionNoteItemDocument = gql`
    mutation DeleteReportQuestionNoteItem($input: DeleteReportQuestionNoteItemInput!, $reportId: ID!) {
  deleteReportQuestionNoteItem(input: $input) {
    reportQuestion {
      id
      notes(reportId: $reportId) {
        id
        ...ReportQuestionNoteFields
      }
    }
  }
}
    ${ReportQuestionNoteFieldsFragmentDoc}`;

export function useDeleteReportQuestionNoteItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportQuestionNoteItemMutation,
    GQTypes.GQDeleteReportQuestionNoteItemMutationVariables
  >(DeleteReportQuestionNoteItemDocument);
}
export const DeleteMetricFromCustomReportDocument = gql`
    mutation DeleteMetricFromCustomReport($reportId: ID!, $input: DeleteMetricFromCustomReportInput!) {
  deleteMetricFromCustomReport(input: $input) {
    report {
      id
      config {
        id
        items {
          __typename
          ... on ReportQuestionContainer {
            id
            label
            description
            items {
              ... on ReportQuestion {
                ...ReportQuestionForReportItemFields
              }
            }
          }
        }
      }
    }
  }
}
    ${ReportQuestionForReportItemFieldsFragmentDoc}`;

export function useDeleteMetricFromCustomReportMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMetricFromCustomReportMutation,
    GQTypes.GQDeleteMetricFromCustomReportMutationVariables
  >(DeleteMetricFromCustomReportDocument);
}
export const DrilldownSavedViewsForReportDocument = gql`
    query DrilldownSavedViewsForReport {
  drilldownSavedViews {
    ...DrilldownSavedViewsForSelector
  }
}
    ${DrilldownSavedViewsForSelectorFragmentDoc}`;

export function useDrilldownSavedViewsForReportQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDrilldownSavedViewsForReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDrilldownSavedViewsForReportQuery,
    GQTypes.GQDrilldownSavedViewsForReportQueryVariables
  >({ query: DrilldownSavedViewsForReportDocument, ...options });
}
export const GetFootprintChangelogEntriesForReportingDocument = gql`
    query GetFootprintChangelogEntriesForReporting {
  footprintChangelog {
    id
    versions(filters: {excludeIsUserChangelogHidden: false}) {
      edges {
        node {
          ...ReportingFootprintVersionFields
        }
      }
    }
  }
}
    ${ReportingFootprintVersionFieldsFragmentDoc}`;

export function useGetFootprintChangelogEntriesForReportingQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintChangelogEntriesForReportingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintChangelogEntriesForReportingQuery,
    GQTypes.GQGetFootprintChangelogEntriesForReportingQueryVariables
  >({ query: GetFootprintChangelogEntriesForReportingDocument, ...options });
}
export const GetFundsDocument = gql`
    query GetFunds {
  funds {
    id
    ...FundAllFields
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useGetFundsQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQGetFundsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetFundsQuery,
    GQTypes.GQGetFundsQueryVariables
  >({ query: GetFundsDocument, ...options });
}
export const GetReductionPlanForIdDocument = gql`
    query GetReductionPlanForId($id: ID!) {
  plan(id: $id) {
    ...ReductionsPlansWithNetZeroVariables
  }
}
    ${ReductionsPlansWithNetZeroVariablesFragmentDoc}`;

export function useGetReductionPlanForIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReductionPlanForIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReductionPlanForIdQuery,
    GQTypes.GQGetReductionPlanForIdQueryVariables
  >({ query: GetReductionPlanForIdDocument, ...options });
}
export const GetOrganizationReductionPlansDocument = gql`
    query GetOrganizationReductionPlans {
  plans {
    edges {
      node {
        ...ReductionsPlansWithNetZeroVariables
      }
    }
  }
  organization {
    id
    sbtiTargetExclusions {
      ...SbtiTargetExclusionFields
    }
  }
}
    ${ReductionsPlansWithNetZeroVariablesFragmentDoc}
${SbtiTargetExclusionFieldsFragmentDoc}`;

export function useGetOrganizationReductionPlansQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationReductionPlansQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationReductionPlansQuery,
    GQTypes.GQGetOrganizationReductionPlansQueryVariables
  >({ query: GetOrganizationReductionPlansDocument, ...options });
}
export const ReviewSectionReportQuestionsDocument = gql`
    query ReviewSectionReportQuestions($ids: [ID!]!) {
  reportQuestions(ids: $ids) {
    ...ReportQuestionFields
  }
}
    ${ReportQuestionFieldsFragmentDoc}`;

export function useReviewSectionReportQuestionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReviewSectionReportQuestionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReviewSectionReportQuestionsQuery,
    GQTypes.GQReviewSectionReportQuestionsQueryVariables
  >({ query: ReviewSectionReportQuestionsDocument, ...options });
}
export const ReportContainerProgressForSurveyDocument = gql`
    query ReportContainerProgressForSurvey($reportId: ID!) {
  formalReport(id: $reportId) {
    id
    config {
      id
      containers {
        id
        automaticProgress(reportId: $reportId)
        overriddenProgress(reportId: $reportId)
      }
    }
  }
}
    `;

export function useReportContainerProgressForSurveyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportContainerProgressForSurveyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportContainerProgressForSurveyQuery,
    GQTypes.GQReportContainerProgressForSurveyQueryVariables
  >({ query: ReportContainerProgressForSurveyDocument, ...options });
}
export const MaterialityAssessmentsDocument = gql`
    query MaterialityAssessments {
  allMaterialityAssessments {
    id
    name
    updatedAt
  }
}
    `;

export function useMaterialityAssessmentsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMaterialityAssessmentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMaterialityAssessmentsQuery,
    GQTypes.GQMaterialityAssessmentsQueryVariables
  >({ query: MaterialityAssessmentsDocument, ...options });
}
export const GetCustomReportFootprintMetadataDocument = gql`
    query GetCustomReportFootprintMetadata($snapshotId: String!) {
  footprintAnalysis(version: $snapshotId) {
    id
    footprintInterval
  }
  footprintChangelog {
    id
    versions(filters: {excludeIsUserChangelogHidden: false}) {
      edges {
        node {
          ...ReportingFootprintVersionFields
        }
      }
    }
  }
}
    ${ReportingFootprintVersionFieldsFragmentDoc}`;

export function useGetCustomReportFootprintMetadataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomReportFootprintMetadataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomReportFootprintMetadataQuery,
    GQTypes.GQGetCustomReportFootprintMetadataQueryVariables
  >({ query: GetCustomReportFootprintMetadataDocument, ...options });
}
export const GetArchetypeOpportunitiesForTcfdDocument = gql`
    query GetArchetypeOpportunitiesForTcfd {
  tcfdArchetypeOpportunities {
    ...TcfdArchetypeOpportunityFields
  }
}
    ${TcfdArchetypeOpportunityFieldsFragmentDoc}`;

export function useGetArchetypeOpportunitiesForTcfdQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetArchetypeOpportunitiesForTcfdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetArchetypeOpportunitiesForTcfdQuery,
    GQTypes.GQGetArchetypeOpportunitiesForTcfdQueryVariables
  >({ query: GetArchetypeOpportunitiesForTcfdDocument, ...options });
}
export const GetExcludingReportQuestionDocument = gql`
    query GetExcludingReportQuestion($questionId: ID!) {
  reportQuestion(id: $questionId) {
    id
    isComputed
    parentContainer {
      id
      label
    }
  }
}
    `;

export function useGetExcludingReportQuestionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetExcludingReportQuestionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetExcludingReportQuestionQuery,
    GQTypes.GQGetExcludingReportQuestionQueryVariables
  >({ query: GetExcludingReportQuestionDocument, ...options });
}
export const GetContainerStatusDocument = gql`
    query GetContainerStatus($containerId: ID!, $reportId: ID!) {
  reportQuestionContainer(id: $containerId) {
    ...ReportQuestionContainerStatusFields
    containers {
      ...ReportQuestionContainerStatusFields
      containers {
        ...ReportQuestionContainerStatusFields
      }
    }
  }
}
    ${ReportQuestionContainerStatusFieldsFragmentDoc}`;

export function useGetContainerStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerStatusQuery,
    GQTypes.GQGetContainerStatusQueryVariables
  >({ query: GetContainerStatusDocument, ...options });
}
export const GetReportWithConfigAndOrgUsersDocument = gql`
    query GetReportWithConfigAndOrgUsers($orgId: ID!, $reportId: ID!) {
  formalReport(id: $reportId) {
    ...ReportWithConfigFields
  }
  organization {
    id
    users {
      edges {
        node {
          ...UserForReportQuestionAssignment
        }
      }
    }
  }
}
    ${ReportWithConfigFieldsFragmentDoc}
${UserForReportQuestionAssignmentFragmentDoc}`;

export function useGetReportWithConfigAndOrgUsersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportWithConfigAndOrgUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportWithConfigAndOrgUsersQuery,
    GQTypes.GQGetReportWithConfigAndOrgUsersQueryVariables
  >({ query: GetReportWithConfigAndOrgUsersDocument, ...options });
}
export const CompanyReportingFactsDocument = gql`
    query CompanyReportingFacts($companyId: ID!) {
  companyReportingFacts(companyId: $companyId) {
    ...CompanyReportingFactsFields
  }
}
    ${CompanyReportingFactsFieldsFragmentDoc}`;

export function useCompanyReportingFactsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyReportingFactsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyReportingFactsQuery,
    GQTypes.GQCompanyReportingFactsQueryVariables
  >({ query: CompanyReportingFactsDocument, ...options });
}
export const CurrencyExchangeRateFromUsdAsOfMonthDocument = gql`
    query CurrencyExchangeRateFromUsdAsOfMonth($targetCurrency: String!, $month: YearMonth!) {
  currencyExchangeRateFromUsdAsOfMonth(
    targetCurrency: $targetCurrency
    month: $month
  ) {
    id
    currency
    value
  }
}
    `;

export function useCurrencyExchangeRateFromUsdAsOfMonthQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCurrencyExchangeRateFromUsdAsOfMonthQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCurrencyExchangeRateFromUsdAsOfMonthQuery,
    GQTypes.GQCurrencyExchangeRateFromUsdAsOfMonthQueryVariables
  >({ query: CurrencyExchangeRateFromUsdAsOfMonthDocument, ...options });
}
export const UpsertCompanyReportingFactsDocument = gql`
    mutation UpsertCompanyReportingFacts($input: UpsertCompanyReportingFactsInput!) {
  upsertCompanyReportingFacts(input: $input) {
    companyReportingFacts {
      ...CompanyReportingFactsFields
    }
  }
}
    ${CompanyReportingFactsFieldsFragmentDoc}`;

export function useUpsertCompanyReportingFactsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertCompanyReportingFactsMutation,
    GQTypes.GQUpsertCompanyReportingFactsMutationVariables
  >(UpsertCompanyReportingFactsDocument);
}
export const RecommendedReportKindsDocument = gql`
    query RecommendedReportKinds($companyId: ID!) {
  recommendedReportKinds(companyId: $companyId) {
    id
    state
    required {
      id
      kind
      reason
    }
    recommended {
      id
      kind
      reason
    }
  }
}
    `;

export function useRecommendedReportKindsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQRecommendedReportKindsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRecommendedReportKindsQuery,
    GQTypes.GQRecommendedReportKindsQueryVariables
  >({ query: RecommendedReportKindsDocument, ...options });
}
export const PeerExternalReportAnswersForIdentifiersDocument = gql`
    query PeerExternalReportAnswersForIdentifiers($reportQuestionIdentifiers: [String!]!) {
  peerExternalReportAnswersForIdentifiers(
    reportQuestionIdentifiers: $reportQuestionIdentifiers
  ) {
    peerCompanies {
      id
      name
    }
    answers {
      ...PeerExternalReportAnswerFields
    }
    questions {
      ...PeerExternalReportQuestionFields
    }
  }
}
    ${PeerExternalReportAnswerFieldsFragmentDoc}
${PeerExternalReportQuestionFieldsFragmentDoc}`;

export function usePeerExternalReportAnswersForIdentifiersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerExternalReportAnswersForIdentifiersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerExternalReportAnswersForIdentifiersQuery,
    GQTypes.GQPeerExternalReportAnswersForIdentifiersQueryVariables
  >({ query: PeerExternalReportAnswersForIdentifiersDocument, ...options });
}
export const ComputeSeriesMetricDocument = gql`
    query ComputeSeriesMetric($input: ComputeReportQuestionMetricInput!) {
  computeReportQuestionSeriesMetric(input: $input) {
    series {
      label
      value
    }
  }
}
    `;

export function useComputeSeriesMetricQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQComputeSeriesMetricQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQComputeSeriesMetricQuery,
    GQTypes.GQComputeSeriesMetricQueryVariables
  >({ query: ComputeSeriesMetricDocument, ...options });
}
export const GetOrganizationReductionPlanIdsDocument = gql`
    query GetOrganizationReductionPlanIds {
  plans {
    edges {
      node {
        id
      }
    }
  }
}
    `;

export function useGetOrganizationReductionPlanIdsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationReductionPlanIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationReductionPlanIdsQuery,
    GQTypes.GQGetOrganizationReductionPlanIdsQueryVariables
  >({ query: GetOrganizationReductionPlanIdsDocument, ...options });
}
export const ComputeSingleValueMetricDocument = gql`
    query ComputeSingleValueMetric($input: ComputeReportQuestionMetricInput!) {
  computeReportQuestionSingleValueMetric(input: $input) {
    value
  }
}
    `;

export function useComputeSingleValueMetricQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQComputeSingleValueMetricQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQComputeSingleValueMetricQuery,
    GQTypes.GQComputeSingleValueMetricQueryVariables
  >({ query: ComputeSingleValueMetricDocument, ...options });
}
export const CreateMaterialityAssessmentDocument = gql`
    mutation CreateMaterialityAssessment($input: CreateMaterialityAssessmentInput!) {
  createMaterialityAssessment(input: $input) {
    materialityAssessment {
      id
    }
  }
}
    `;

export function useCreateMaterialityAssessmentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMaterialityAssessmentMutation,
    GQTypes.GQCreateMaterialityAssessmentMutationVariables
  >(CreateMaterialityAssessmentDocument);
}
export const MaterialityPageDocument = gql`
    query MaterialityPage {
  allMaterialityAssessments {
    id
    name
    createdAt
    updatedAt
    updatedBy {
      id
      displayName
    }
  }
}
    `;

export function useMaterialityPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMaterialityPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMaterialityPageQuery,
    GQTypes.GQMaterialityPageQueryVariables
  >({ query: MaterialityPageDocument, ...options });
}
export const DeleteMaterialityAssessmentDocument = gql`
    mutation deleteMaterialityAssessment($input: DeleteMaterialityAssessmentInput!) {
  deleteMaterialityAssessment(input: $input) {
    success
    materialityAssessments {
      id
      name
      createdAt
      updatedAt
      updatedBy {
        id
        displayName
      }
    }
  }
}
    `;

export function useDeleteMaterialityAssessmentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMaterialityAssessmentMutation,
    GQTypes.GQDeleteMaterialityAssessmentMutationVariables
  >(DeleteMaterialityAssessmentDocument);
}
export const UpdateMaterialityAssessmentDocument = gql`
    mutation updateMaterialityAssessment($input: UpdateMaterialityAssessmentInput!) {
  updateMaterialityAssessment(input: $input) {
    materialityAssessments {
      id
      name
      createdAt
      updatedAt
      updatedBy {
        id
        displayName
      }
    }
  }
}
    `;

export function useUpdateMaterialityAssessmentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMaterialityAssessmentMutation,
    GQTypes.GQUpdateMaterialityAssessmentMutationVariables
  >(UpdateMaterialityAssessmentDocument);
}
export const ViewMaterialityAssessmentDocument = gql`
    query ViewMaterialityAssessment($materialityAssessmentId: ID!) {
  materialityAssessment(materialityAssessmentId: $materialityAssessmentId) {
    id
    name
    dataPoints {
      id
      frameworkDataPoint {
        id
        identifier
      }
      status
    }
  }
}
    `;

export function useViewMaterialityAssessmentQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQViewMaterialityAssessmentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQViewMaterialityAssessmentQuery,
    GQTypes.GQViewMaterialityAssessmentQueryVariables
  >({ query: ViewMaterialityAssessmentDocument, ...options });
}
export const DownloadDocxReportDocument = gql`
    query DownloadDocxReport($reportId: ID!) {
  formalReport(id: $reportId) {
    id
    downloadableDocxUrl
  }
}
    `;

export function useDownloadDocxReportQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDownloadDocxReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDownloadDocxReportQuery,
    GQTypes.GQDownloadDocxReportQueryVariables
  >({ query: DownloadDocxReportDocument, ...options });
}
export const DownloadXlsxReportDocument = gql`
    query DownloadXlsxReport($reportId: ID!) {
  formalReport(id: $reportId) {
    id
    reportKind
    downloadableXlsxUrl
  }
}
    `;

export function useDownloadXlsxReportQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDownloadXlsxReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDownloadXlsxReportQuery,
    GQTypes.GQDownloadXlsxReportQueryVariables
  >({ query: DownloadXlsxReportDocument, ...options });
}
export const InProgressReportsDocument = gql`
    query InProgressReports {
  formalReports {
    edges {
      node {
        __typename
        id
        ...ReportPageReport
      }
    }
  }
}
    ${ReportPageReportFragmentDoc}`;

export function useInProgressReportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQInProgressReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQInProgressReportsQuery,
    GQTypes.GQInProgressReportsQueryVariables
  >({ query: InProgressReportsDocument, ...options });
}
export const ReportSummariesDocument = gql`
    query ReportSummaries($isAdmin: Boolean!) {
  formalReports(last: 5) {
    edges {
      node {
        ...ReportSummary
      }
    }
  }
  footprintAnalysis @include(if: $isAdmin) {
    id
    customReports {
      ...CustomReportSummary
    }
  }
}
    ${ReportSummaryFragmentDoc}
${CustomReportSummaryFragmentDoc}`;

export function useReportSummariesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportSummariesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportSummariesQuery,
    GQTypes.GQReportSummariesQueryVariables
  >({ query: ReportSummariesDocument, ...options });
}
export const ReportWaitlistTypesDocument = gql`
    query ReportWaitlistTypes {
  reportWaitlistTypes
}
    `;

export function useReportWaitlistTypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportWaitlistTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportWaitlistTypesQuery,
    GQTypes.GQReportWaitlistTypesQueryVariables
  >({ query: ReportWaitlistTypesDocument, ...options });
}
export const JoinReportWaitlistDocument = gql`
    mutation JoinReportWaitlist($kind: String!) {
  joinReportWaitlist(kind: $kind) {
    id
    kind
  }
}
    `;

export function useJoinReportWaitlistMutation() {
  return Urql.useMutation<
    GQTypes.GQJoinReportWaitlistMutation,
    GQTypes.GQJoinReportWaitlistMutationVariables
  >(JoinReportWaitlistDocument);
}
export const LeaveReportWaitlistDocument = gql`
    mutation LeaveReportWaitlist($kind: String!) {
  leaveReportWaitlist(kind: $kind) {
    kind
  }
}
    `;

export function useLeaveReportWaitlistMutation() {
  return Urql.useMutation<
    GQTypes.GQLeaveReportWaitlistMutation,
    GQTypes.GQLeaveReportWaitlistMutationVariables
  >(LeaveReportWaitlistDocument);
}
export const GetCdpApiRegistrationDocument = gql`
    query GetCdpApiRegistration($reportId: ID!) {
  cdpApiRegistrationForReport(reportId: $reportId) {
    id
    isReportRegistered
    cdpOrganizationId
    registeredReportId
  }
  cdpLatestSyncWorkflowStatus {
    workflowStatus {
      id
      workflowId
      questions {
        id
        syncTime
        isSuccess
      }
    }
  }
}
    `;

export function useGetCdpApiRegistrationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCdpApiRegistrationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCdpApiRegistrationQuery,
    GQTypes.GQGetCdpApiRegistrationQueryVariables
  >({ query: GetCdpApiRegistrationDocument, ...options });
}
export const CreateCdpApiRegistrationDocument = gql`
    mutation CreateCdpApiRegistration($input: CreateCdpApiRegistrationInput!) {
  createCdpApiRegistration(input: $input) {
    id
  }
}
    `;

export function useCreateCdpApiRegistrationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCdpApiRegistrationMutation,
    GQTypes.GQCreateCdpApiRegistrationMutationVariables
  >(CreateCdpApiRegistrationDocument);
}
export const DeleteCdpApiRegistrationDocument = gql`
    mutation DeleteCdpApiRegistration($input: DeleteCdpApiRegistrationInput!) {
  deleteCdpApiRegistration(input: $input) {
    id
  }
}
    `;

export function useDeleteCdpApiRegistrationMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCdpApiRegistrationMutation,
    GQTypes.GQDeleteCdpApiRegistrationMutationVariables
  >(DeleteCdpApiRegistrationDocument);
}
export const UpsertReportAnswerDocument = gql`
    mutation UpsertReportAnswer($input: UpsertReportAnswerInput!, $reportId: ID!) {
  upsertReportAnswerWithMetadata(input: $input) {
    doesConflict
    updatedAnswer {
      id
      question {
        ...ReportQuestionDependentFields
      }
      editorName
    }
  }
}
    ${ReportQuestionDependentFieldsFragmentDoc}`;

export function useUpsertReportAnswerMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertReportAnswerMutation,
    GQTypes.GQUpsertReportAnswerMutationVariables
  >(UpsertReportAnswerDocument);
}
export const DeleteReportAnswerDocument = gql`
    mutation DeleteReportAnswer($input: DeleteReportAnswerInput!, $reportId: ID!) {
  deleteReportAnswer(input: $input) {
    numDeleted
    question {
      ...ReportQuestionDependentFields
    }
  }
}
    ${ReportQuestionDependentFieldsFragmentDoc}`;

export function useDeleteReportAnswerMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportAnswerMutation,
    GQTypes.GQDeleteReportAnswerMutationVariables
  >(DeleteReportAnswerDocument);
}
export const GetWorkivaContributionsDocument = gql`
    query GetWorkivaContributions($footprintKind: String!, $footprintSnapshotId: String!, $reportingInterval: YMInterval!) {
  footprintAnalysis {
    id
    workivaContributions(
      footprintKind: $footprintKind
      footprintSnapshotId: $footprintSnapshotId
      reportingInterval: $reportingInterval
    ) {
      id
      interval
      data
    }
  }
}
    `;

export function useGetWorkivaContributionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetWorkivaContributionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetWorkivaContributionsQuery,
    GQTypes.GQGetWorkivaContributionsQueryVariables
  >({ query: GetWorkivaContributionsDocument, ...options });
}
export const UpdateUserNameDocument = gql`
    mutation UpdateUserName($input: UpdateUserNameInput!) {
  updateUserName(input: $input) {
    user {
      id
      name
    }
  }
}
    `;

export function useUpdateUserNameMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserNameMutation,
    GQTypes.GQUpdateUserNameMutationVariables
  >(UpdateUserNameDocument);
}
export const ResetLockedUserDocument = gql`
    mutation ResetLockedUser($input: ResetLockedUserInput!) {
  resetLockedUser(input: $input) {
    user {
      id
      consecutiveLoginFailures
    }
  }
}
    `;

export function useResetLockedUserMutation() {
  return Urql.useMutation<
    GQTypes.GQResetLockedUserMutation,
    GQTypes.GQResetLockedUserMutationVariables
  >(ResetLockedUserDocument);
}
export const RevokeUserAppSessionsDocument = gql`
    mutation RevokeUserAppSessions($input: RevokeUserAppSessionsInput!) {
  revokeUserAppSessions(input: $input) {
    user {
      id
      hasValidAppSessions
    }
  }
}
    `;

export function useRevokeUserAppSessionsMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokeUserAppSessionsMutation,
    GQTypes.GQRevokeUserAppSessionsMutationVariables
  >(RevokeUserAppSessionsDocument);
}
export const RegenerateUserSignupTokenDocument = gql`
    mutation RegenerateUserSignupToken($orgId: ID!, $userId: ID!) {
  regenerateUserSignupToken(input: {orgId: $orgId, userId: $userId}) {
    user {
      id
      signupLink(orgId: $orgId)
    }
  }
}
    `;

export function useRegenerateUserSignupTokenMutation() {
  return Urql.useMutation<
    GQTypes.GQRegenerateUserSignupTokenMutation,
    GQTypes.GQRegenerateUserSignupTokenMutationVariables
  >(RegenerateUserSignupTokenDocument);
}
export const GetOrganizationMembersDocument = gql`
    query GetOrganizationMembers($orgId: ID!) {
  organization {
    id
    workosOrgId
    domains
    passwordAuthDisabled
    users {
      edges {
        node {
          id
          ...OrganizationMemberFields
        }
      }
    }
  }
  roles {
    edges {
      node {
        ...RoleInfo
      }
    }
  }
}
    ${OrganizationMemberFieldsFragmentDoc}
${RoleInfoFragmentDoc}`;

export function useGetOrganizationMembersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationMembersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationMembersQuery,
    GQTypes.GQGetOrganizationMembersQueryVariables
  >({ query: GetOrganizationMembersDocument, ...options });
}
export const AllUsersDocument = gql`
    query allUsers($orgId: ID!) {
  allUsers {
    ...OrganizationMemberFields
  }
}
    ${OrganizationMemberFieldsFragmentDoc}`;

export function useAllUsersQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQAllUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQAllUsersQuery,
    GQTypes.GQAllUsersQueryVariables
  >({ query: AllUsersDocument, ...options });
}
export const RemoveUserDocument = gql`
    mutation RemoveUser($input: RemoveUserInput!) {
  removeUser(input: $input) {
    user {
      id
    }
  }
}
    `;

export function useRemoveUserMutation() {
  return Urql.useMutation<
    GQTypes.GQRemoveUserMutation,
    GQTypes.GQRemoveUserMutationVariables
  >(RemoveUserDocument);
}
export const ReportEmissionsOverTimeDocument = gql`
    query ReportEmissionsOverTime($reportId: ID!, $footprintKind: String!) {
  forceRedirect
  report(id: $reportId) {
    id
    footprintInterval
    timeseries(footprintKind: $footprintKind) {
      ...TimeseriesFields
    }
    intensityTimeseries(footprintKind: $footprintKind) {
      ...TimeseriesFields
    }
  }
}
    ${TimeseriesFieldsFragmentDoc}`;

export function useReportEmissionsOverTimeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportEmissionsOverTimeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportEmissionsOverTimeQuery,
    GQTypes.GQReportEmissionsOverTimeQueryVariables
  >({ query: ReportEmissionsOverTimeDocument, ...options });
}
export const GetPendingCompanyChangeRequestsDocument = gql`
    query GetPendingCompanyChangeRequests {
  pendingCompanyChangeRequests {
    ...CompanyChangeRequestForVendorMatching
  }
}
    ${CompanyChangeRequestForVendorMatchingFragmentDoc}`;

export function useGetPendingCompanyChangeRequestsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPendingCompanyChangeRequestsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPendingCompanyChangeRequestsQuery,
    GQTypes.GQGetPendingCompanyChangeRequestsQueryVariables
  >({ query: GetPendingCompanyChangeRequestsDocument, ...options });
}
export const GetCustomerHubDataDocument = gql`
    query GetCustomerHubData($companyId: ID!) {
  companyForDashboard(id: $companyId) {
    ...CompanyFieldsForEngagementTasks
  }
  engagementTasks {
    edges {
      node {
        ...EngagementTaskContents
      }
    }
  }
  organization {
    id
    company {
      ...CompanyFieldsForEngagementTasks
    }
    users {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
  }
  companyPortalSettings(rootCompanyId: $companyId) {
    ...CompanyPortalSettingsFields
  }
}
    ${CompanyFieldsForEngagementTasksFragmentDoc}
${EngagementTaskContentsFragmentDoc}
${CompanyPortalSettingsFieldsFragmentDoc}`;

export function useGetCustomerHubDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCustomerHubDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCustomerHubDataQuery,
    GQTypes.GQGetCustomerHubDataQueryVariables
  >({ query: GetCustomerHubDataDocument, ...options });
}
export const LogCustomerHubViewedDocument = gql`
    mutation LogCustomerHubViewed($input: LogCustomerHubViewedInput!) {
  logCustomerHubViewed(input: $input) {
    customerCompanyId
  }
}
    `;

export function useLogCustomerHubViewedMutation() {
  return Urql.useMutation<
    GQTypes.GQLogCustomerHubViewedMutation,
    GQTypes.GQLogCustomerHubViewedMutationVariables
  >(LogCustomerHubViewedDocument);
}
export const GetPreviewAsCompanyDocument = gql`
    query GetPreviewAsCompany($previewAsCompanyId: ID!) {
  companyForDashboard(id: $previewAsCompanyId) {
    ...CompanyFieldsForEngagementTasks
  }
}
    ${CompanyFieldsForEngagementTasksFragmentDoc}`;

export function useGetPreviewAsCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPreviewAsCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPreviewAsCompanyQuery,
    GQTypes.GQGetPreviewAsCompanyQueryVariables
  >({ query: GetPreviewAsCompanyDocument, ...options });
}
export const GetEngagementTasksPortalDataDocument = gql`
    query GetEngagementTasksPortalData {
  userHasPermission(permission: ManageDisclosures, allowAnyObject: true)
  engagementTasks {
    edges {
      node {
        ...EngagementTaskFields
      }
    }
  }
}
    ${EngagementTaskFieldsFragmentDoc}`;

export function useGetEngagementTasksPortalDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTasksPortalDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTasksPortalDataQuery,
    GQTypes.GQGetEngagementTasksPortalDataQueryVariables
  >({ query: GetEngagementTasksPortalDataDocument, ...options });
}
export const OrgUsersForSurveyDocument = gql`
    query OrgUsersForSurvey {
  organization {
    id
    name
    users {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
  }
}
    `;

export function useOrgUsersForSurveyQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUsersForSurveyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersForSurveyQuery,
    GQTypes.GQOrgUsersForSurveyQueryVariables
  >({ query: OrgUsersForSurveyDocument, ...options });
}
export const LogSupplyChainSurveyEventDocument = gql`
    mutation LogSupplyChainSurveyEvent($input: LogSupplyChainSurveyEventInput!) {
  logSupplyChainSurveyEvent(input: $input) {
    success
  }
}
    `;

export function useLogSupplyChainSurveyEventMutation() {
  return Urql.useMutation<
    GQTypes.GQLogSupplyChainSurveyEventMutation,
    GQTypes.GQLogSupplyChainSurveyEventMutationVariables
  >(LogSupplyChainSurveyEventDocument);
}
export const GetEngagementTaskByCrossOrgModelIdDocument = gql`
    query GetEngagementTaskByCrossOrgModelId($crossOrgModelId: String!) {
  engagementTaskByCrossOrgModelId(crossOrgModelId: $crossOrgModelId) {
    ...EngagementTaskContents
  }
}
    ${EngagementTaskContentsFragmentDoc}`;

export function useGetEngagementTaskByCrossOrgModelIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskByCrossOrgModelIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskByCrossOrgModelIdQuery,
    GQTypes.GQGetEngagementTaskByCrossOrgModelIdQueryVariables
  >({ query: GetEngagementTaskByCrossOrgModelIdDocument, ...options });
}
export const SubmitEngagementTaskDocument = gql`
    mutation SubmitEngagementTask($input: SubmitEngagementTaskInput!) {
  submitEngagementTask(input: $input) {
    engagementTask {
      ...EngagementTaskContents
    }
  }
}
    ${EngagementTaskContentsFragmentDoc}`;

export function useSubmitEngagementTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitEngagementTaskMutation,
    GQTypes.GQSubmitEngagementTaskMutationVariables
  >(SubmitEngagementTaskDocument);
}
export const UpsertEngagementTaskAnswersDocument = gql`
    mutation UpsertEngagementTaskAnswers($input: UpsertEngagementTaskAnswersInput!) {
  upsertEngagementTaskAnswers(input: $input) {
    engagementTask {
      ...EngagementTaskContents
    }
  }
}
    ${EngagementTaskContentsFragmentDoc}`;

export function useUpsertEngagementTaskAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertEngagementTaskAnswersMutation,
    GQTypes.GQUpsertEngagementTaskAnswersMutationVariables
  >(UpsertEngagementTaskAnswersDocument);
}
export const CreateEngagementTaskAttachmentsDocument = gql`
    mutation CreateEngagementTaskAttachments($input: CreateEngagementTaskAttachmentsInput!) {
  createEngagementTaskAttachments(input: $input) {
    attachmentMetadata {
      signedUploadUrl
      fileMetadataId
      supplierAttachmentCrossOrgId
    }
  }
}
    `;

export function useCreateEngagementTaskAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEngagementTaskAttachmentsMutation,
    GQTypes.GQCreateEngagementTaskAttachmentsMutationVariables
  >(CreateEngagementTaskAttachmentsDocument);
}
export const RemoveCompanyFromPeerCompanyGroupDocument = gql`
    mutation RemoveCompanyFromPeerCompanyGroup($input: RemoveCompanyFromPeerCompanyGroupInput!) {
  removeCompanyFromPeerCompanyGroup(input: $input) {
    peerCompanyGroup {
      ...PeerCompanyGroupForBenchmarks
    }
  }
}
    ${PeerCompanyGroupForBenchmarksFragmentDoc}`;

export function useRemoveCompanyFromPeerCompanyGroupMutation() {
  return Urql.useMutation<
    GQTypes.GQRemoveCompanyFromPeerCompanyGroupMutation,
    GQTypes.GQRemoveCompanyFromPeerCompanyGroupMutationVariables
  >(RemoveCompanyFromPeerCompanyGroupDocument);
}
export const CompanySearchForBenchmarksDocument = gql`
    query CompanySearchForBenchmarks($search: String, $naicsCode: String, $after: String, $before: String, $first: Int, $last: Int) {
  benchmarkCompanies(
    search: $search
    naicsCode: $naicsCode
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...CompanyForBenchmarks
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
  benchmarkNaicsCodes(search: $search)
}
    ${CompanyForBenchmarksFragmentDoc}`;

export function useCompanySearchForBenchmarksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanySearchForBenchmarksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanySearchForBenchmarksQuery,
    GQTypes.GQCompanySearchForBenchmarksQueryVariables
  >({ query: CompanySearchForBenchmarksDocument, ...options });
}
export const CompanyGroupsForBenchmarksDocument = gql`
    query CompanyGroupsForBenchmarks($orgId: ID!) {
  peerCompanyGroups(orgId: $orgId) {
    ...PeerCompanyGroupForBenchmarks
  }
}
    ${PeerCompanyGroupForBenchmarksFragmentDoc}`;

export function useCompanyGroupsForBenchmarksQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyGroupsForBenchmarksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyGroupsForBenchmarksQuery,
    GQTypes.GQCompanyGroupsForBenchmarksQueryVariables
  >({ query: CompanyGroupsForBenchmarksDocument, ...options });
}
export const AccessibleOrgsAndEngagementTasksDocument = gql`
    query AccessibleOrgsAndEngagementTasks {
  activeOrganization {
    ...AccessibleOrgsAndEngagementTasks
  }
}
    ${AccessibleOrgsAndEngagementTasksFragmentDoc}`;

export function useAccessibleOrgsAndEngagementTasksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAccessibleOrgsAndEngagementTasksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAccessibleOrgsAndEngagementTasksQuery,
    GQTypes.GQAccessibleOrgsAndEngagementTasksQueryVariables
  >({ query: AccessibleOrgsAndEngagementTasksDocument, ...options });
}
export const CanonicalClimateProgramProjectsForAddProjectDocument = gql`
    query CanonicalClimateProgramProjectsForAddProject($orgId: ID!) {
  canonicalClimateProgramProjects {
    ...CanonicalClimateProgramProjectForAddProject
  }
  peerCompanyGroups(orgId: $orgId) {
    ...PeerCompanyGroupFields
  }
}
    ${CanonicalClimateProgramProjectForAddProjectFragmentDoc}
${PeerCompanyGroupFieldsFragmentDoc}`;

export function useCanonicalClimateProgramProjectsForAddProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanonicalClimateProgramProjectsForAddProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanonicalClimateProgramProjectsForAddProjectQuery,
    GQTypes.GQCanonicalClimateProgramProjectsForAddProjectQueryVariables
  >({
    query: CanonicalClimateProgramProjectsForAddProjectDocument,
    ...options,
  });
}
export const ClimateProgramProjectsForHomepageDocument = gql`
    query ClimateProgramProjectsForHomepage {
  climateProgramTimelineProjects {
    ...ClimateProgramTimelineProjectFields
  }
  climateProgramCalendarEvents {
    ...ClimateProgramCalendarEventFields
  }
}
    ${ClimateProgramTimelineProjectFieldsFragmentDoc}
${ClimateProgramCalendarEventFieldsFragmentDoc}`;

export function useClimateProgramProjectsForHomepageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQClimateProgramProjectsForHomepageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQClimateProgramProjectsForHomepageQuery,
    GQTypes.GQClimateProgramProjectsForHomepageQueryVariables
  >({ query: ClimateProgramProjectsForHomepageDocument, ...options });
}
export const GetSingleRowMethodologyForEmissionsYearDocument = gql`
    query GetSingleRowMethodologyForEmissionsYear($emissionsYearId: String!, $rowId: Int!) {
  singleRowMethodologyExplainerForEmissionsYear(
    emissionsYearId: $emissionsYearId
    rowId: $rowId
  ) {
    ...SingleRowMethodologyExplainerFields
  }
}
    ${SingleRowMethodologyExplainerFieldsFragmentDoc}`;

export function useGetSingleRowMethodologyForEmissionsYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSingleRowMethodologyForEmissionsYearQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSingleRowMethodologyForEmissionsYearQuery,
    GQTypes.GQGetSingleRowMethodologyForEmissionsYearQueryVariables
  >({ query: GetSingleRowMethodologyForEmissionsYearDocument, ...options });
}
export const GetSingleRowMethodologyDocument = gql`
    query getSingleRowMethodology($bundleId: String!, $rowId: Int!) {
  singleRowMethodologyExplainer(bundleId: $bundleId, rowId: $rowId) {
    ...SingleRowMethodologyExplainerFields
  }
  footprintAuditBundle(bundleId: $bundleId) {
    id
    createdAt
    inputJson
  }
}
    ${SingleRowMethodologyExplainerFieldsFragmentDoc}`;

export function useGetSingleRowMethodologyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSingleRowMethodologyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSingleRowMethodologyQuery,
    GQTypes.GQGetSingleRowMethodologyQueryVariables
  >({ query: GetSingleRowMethodologyDocument, ...options });
}
export const GetFootprintAuditBundleDocument = gql`
    query getFootprintAuditBundle($bundleId: String!) {
  footprintAuditBundle(bundleId: $bundleId) {
    id
    createdAt
    downloadUrl
    footprintEmissionsKind
  }
  footprintAuditVerificationTemplateUrl
}
    `;

export function useGetFootprintAuditBundleQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintAuditBundleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintAuditBundleQuery,
    GQTypes.GQGetFootprintAuditBundleQueryVariables
  >({ query: GetFootprintAuditBundleDocument, ...options });
}
export const GetPinInfoDocument = gql`
    query GetPinInfo($version: String!) {
  footprintVersion(version: $version) {
    id
    createdAt
    originalFootprintSnapshotId
    pinInfo {
      id
      name
      description
    }
  }
}
    `;

export function useGetPinInfoQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetPinInfoQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetPinInfoQuery,
    GQTypes.GQGetPinInfoQueryVariables
  >({ query: GetPinInfoDocument, ...options });
}
export const GetReviewPageDataDocument = gql`
    query GetReviewPageData($footprintSnapshotId: String!) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintInterval
    footprintVersion {
      ...FootprintVersionForReview
    }
  }
  footprintChangelog {
    id
    versions {
      edges {
        node {
          ...FootprintVersionForTimeline
        }
      }
    }
  }
  footprintSnapshotInDashboard(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprint {
      id
      name
    }
  }
}
    ${FootprintVersionForReviewFragmentDoc}
${FootprintVersionForTimelineFragmentDoc}`;

export function useGetReviewPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReviewPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReviewPageDataQuery,
    GQTypes.GQGetReviewPageDataQueryVariables
  >({ query: GetReviewPageDataDocument, ...options });
}
export const GetFootprintInsightsPageDataDocument = gql`
    query GetFootprintInsightsPageData($oneYearFilters: FootprintSummaryFilters!, $oneYearScope1Filters: FootprintSummaryFilters!, $oneYearScope2Filters: FootprintSummaryFilters!, $oneYearScope3Filters: FootprintSummaryFilters!, $oneYearNetFilters: FootprintSummaryFilters!, $twoYearNetFilters: FootprintSummaryFilters!, $allYearFilters: FootprintSummaryFilters!, $allYearRemovalFilters: FootprintSummaryFilters!, $footprintSnapshotId: String!, $kind: String!) {
  ...NextStepsSection_query
  userHasPermission(permission: ViewFootprintDetail)
  organization {
    id
  }
  footprint: legacyFootprint(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintKinds
    summary(footprintKind: $kind) {
      id
      oneYearNet: total(filters: $oneYearNetFilters)
      twoYearNet: total(filters: $twoYearNetFilters)
      oneYearGross: total(filters: $oneYearFilters)
      oneYearScope1: total(filters: $oneYearScope1Filters)
      oneYearScope2: total(filters: $oneYearScope2Filters)
      oneYearScope3: total(filters: $oneYearScope3Filters)
      timeseries(filters: $allYearFilters, grouping: "businessCategory") {
        ...TimeseriesFields
      }
      removalTimeseries: timeseries(
        filters: $allYearRemovalFilters
        grouping: "businessCategory"
      ) {
        ...TimeseriesFields
      }
      heavyHitters(filters: $oneYearFilters, first: 12) {
        businessCategory
        businessSubcategory
        proportion
      }
      topCategories(filters: $oneYearFilters, first: 12) {
        businessCategory
        businessSubcategory
        proportion
      }
    }
    intensityDenominators {
      aggregateKind
      intensityKind
      data {
        yearMonth
        value
      }
    }
    footprintBridgeDates {
      oldStartDate
      oldEndDate
      newStartDate
      newEndDate
    }
  }
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    peerCompanyGroups {
      ...PeerCompanyGroupFieldsForInsights
    }
  }
  footprintChangelog {
    id
    versions {
      edges {
        node {
          ...FootprintVersionForOverview
        }
      }
    }
  }
}
    ${NextStepsSection_QueryFragmentDoc}
${TimeseriesFieldsFragmentDoc}
${PeerCompanyGroupFieldsForInsightsFragmentDoc}
${FootprintVersionForOverviewFragmentDoc}`;

export function useGetFootprintInsightsPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintInsightsPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintInsightsPageDataQuery,
    GQTypes.GQGetFootprintInsightsPageDataQueryVariables
  >({ query: GetFootprintInsightsPageDataDocument, ...options });
}
export const HomePageDocument = gql`
    query HomePage {
  organization {
    id
    profile {
      ...OrgProfileForOnboardingFlow
    }
    measurementProjects {
      ...MeasurementProjectForHomepage
    }
  }
}
    ${OrgProfileForOnboardingFlowFragmentDoc}
${MeasurementProjectForHomepageFragmentDoc}`;

export function useHomePageQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQHomePageQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQHomePageQuery,
    GQTypes.GQHomePageQueryVariables
  >({ query: HomePageDocument, ...options });
}
export const HomePageFootprintDocument = gql`
    query HomePageFootprint($oneYear: FootprintSummaryFilters, $measurementFilters: FootprintSummaryFilters, $footprintKind: String!) {
  footprint: legacyFootprint {
    id
    summary(footprintKind: $footprintKind) {
      id
      oneYearTotal: total(filters: $oneYear)
      oneYearHeavyHitters: heavyHitters(filters: $oneYear, first: 5) {
        businessCategory
        businessSubcategory
        amountKgco2e
      }
      measurementGross: total(filters: $measurementFilters)
      measurementTimeseries: timeseries(
        grouping: "businessCategory"
        filters: $measurementFilters
      ) {
        id
        series {
          label
          value
          data {
            ...FootprintAnalysisTimeSeriesDataFields
          }
        }
      }
      measurementHeavyHitters: heavyHitters(filters: $measurementFilters, first: 5) {
        businessCategory
        businessSubcategory
        proportion
        amountKgco2e
      }
    }
  }
}
    ${FootprintAnalysisTimeSeriesDataFieldsFragmentDoc}`;

export function useHomePageFootprintQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQHomePageFootprintQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQHomePageFootprintQuery,
    GQTypes.GQHomePageFootprintQueryVariables
  >({ query: HomePageFootprintDocument, ...options });
}
export const HomePageExpensiveDocument = gql`
    query HomePageExpensive($hasViewReductionsPermissions: Boolean!, $hasManageAnyDatasourcePermissions: Boolean!) {
  activeMeasurementProjects(limit: 1) @include(if: $hasManageAnyDatasourcePermissions) {
    ...NextMeasurementProjectData
  }
  organization {
    id
    lifecycle {
      completedStage
      nextStage
      kickoffBlurb
    }
    profile {
      ...OrgProfileForOnboardingFlow
    }
  }
  committedPlans: plans(state: Committed) @include(if: $hasViewReductionsPermissions) {
    edges {
      node {
        ...PlanFieldsForHomepage
      }
    }
  }
  visiblePlans: plans(state: Draft, first: 3) @include(if: $hasViewReductionsPermissions) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${NextMeasurementProjectDataFragmentDoc}
${OrgProfileForOnboardingFlowFragmentDoc}
${PlanFieldsForHomepageFragmentDoc}`;

export function useHomePageExpensiveQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQHomePageExpensiveQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQHomePageExpensiveQuery,
    GQTypes.GQHomePageExpensiveQueryVariables
  >({ query: HomePageExpensiveDocument, ...options });
}
export const I18nSandboxPageDocument = gql`
    query I18nSandboxPage {
  i18nSandbox {
    name
    name2
    name3
  }
}
    `;

export function useI18nSandboxPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQI18nSandboxPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQI18nSandboxPageQuery,
    GQTypes.GQI18nSandboxPageQueryVariables
  >({ query: I18nSandboxPageDocument, ...options });
}
export const EacPurchaseProductWaitlistDocument = gql`
    query EacPurchaseProductWaitlist {
  productWaitlistTypes
}
    `;

export function useEacPurchaseProductWaitlistQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQEacPurchaseProductWaitlistQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEacPurchaseProductWaitlistQuery,
    GQTypes.GQEacPurchaseProductWaitlistQueryVariables
  >({ query: EacPurchaseProductWaitlistDocument, ...options });
}
export const MarketplacePurchasesPageDocument = gql`
    query MarketplacePurchasesPage($targetCurrency: String!) {
  userHasPermission(permission: ManageMarketplacePurchases)
  marketplacePurchases {
    edges {
      node {
        ...MarketplacePurchaseFieldsForPurchasesPage
      }
    }
  }
  latestCurrencyExchangeRate(targetCurrency: $targetCurrency) {
    ...CurrencyExchangeRateForPurchasesPage
  }
}
    ${MarketplacePurchaseFieldsForPurchasesPageFragmentDoc}
${CurrencyExchangeRateForPurchasesPageFragmentDoc}`;

export function useMarketplacePurchasesPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplacePurchasesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplacePurchasesPageQuery,
    GQTypes.GQMarketplacePurchasesPageQueryVariables
  >({ query: MarketplacePurchasesPageDocument, ...options });
}
export const MeasurementProjectPageDocument = gql`
    query MeasurementProjectPage($id: ID!) {
  measurementProject(id: $id) {
    ...MeasurementProjectForMeasurementPage
  }
  userHasPermission: userHasViewDatasourcePermission(allowAnyObject: true)
  userHasManageMeasurement: userHasPermission(permission: ManageMeasurement)
  organization {
    id
    measurementProjects {
      ...MeasurementProjectFields
    }
    facilitiesMetadata {
      buildingsDataset {
        id
        name
      }
      utilitiesDataset {
        id
        name
      }
    }
    ingestionCustomFields {
      ...MeasurementProjectPageIngestionCustomField
    }
  }
  latestFootprintAssemblyRun {
    id
    status
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${MeasurementProjectFieldsFragmentDoc}
${MeasurementProjectPageIngestionCustomFieldFragmentDoc}`;

export function useMeasurementProjectPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectPageQuery,
    GQTypes.GQMeasurementProjectPageQueryVariables
  >({ query: MeasurementProjectPageDocument, ...options });
}
export const PresetCustomTagsForMeasurementProjectPageDocument = gql`
    query PresetCustomTagsForMeasurementProjectPage($id: ID!) {
  measurementProject(id: $id) {
    id
    measurementTasks {
      id
      object {
        __typename
        id
        ... on UserUploadTask {
          datasource {
            id
            presetCustomTags {
              ...PresetCustomTagForMeasurementPage
            }
          }
        }
      }
    }
  }
}
    ${PresetCustomTagForMeasurementPageFragmentDoc}`;

export function usePresetCustomTagsForMeasurementProjectPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPresetCustomTagsForMeasurementProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPresetCustomTagsForMeasurementProjectPageQuery,
    GQTypes.GQPresetCustomTagsForMeasurementProjectPageQueryVariables
  >({ query: PresetCustomTagsForMeasurementProjectPageDocument, ...options });
}
export const UserUploadCountsForMeasurementProjectPageDocument = gql`
    query UserUploadCountsForMeasurementProjectPage($id: ID!, $enableUserUploadCountsOnMeasurementPage: Boolean!) {
  measurementProject(id: $id) @include(if: $enableUserUploadCountsOnMeasurementPage) {
    id
    userUploadTasks {
      edges {
        node {
          id
          userUploads {
            edges {
              node {
                id
                revisionRoot
                userVisibleAttempt {
                  id
                  name
                  remoteWritten
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

export function useUserUploadCountsForMeasurementProjectPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadCountsForMeasurementProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadCountsForMeasurementProjectPageQuery,
    GQTypes.GQUserUploadCountsForMeasurementProjectPageQueryVariables
  >({ query: UserUploadCountsForMeasurementProjectPageDocument, ...options });
}
export const DatasourcePageDocument = gql`
    query DatasourcePage($datasourceId: ID!, $measurementProjectId: ID!) {
  userUploadTaskForDatasource(
    datasourceId: $datasourceId
    measurementProjectId: $measurementProjectId
  ) {
    ...UserUploadTaskWithDatasource
  }
  organization {
    ...OrganizationCustomFieldsForDatasourcePage
  }
  userHasPermission: userHasViewDatasourcePermission(datasourceId: $datasourceId)
}
    ${UserUploadTaskWithDatasourceFragmentDoc}
${OrganizationCustomFieldsForDatasourcePageFragmentDoc}`;

export function useDatasourcePageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasourcePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasourcePageQuery,
    GQTypes.GQDatasourcePageQueryVariables
  >({ query: DatasourcePageDocument, ...options });
}
export const UpdateDatasourceNewDocument = gql`
    mutation UpdateDatasourceNew($input: UpdateDatasourceInput!, $measurementProjectId: ID) {
  updateDatasource(input: $input) {
    datasource {
      ...DatasourceFieldsForDatasourcePage
    }
  }
}
    ${DatasourceFieldsForDatasourcePageFragmentDoc}`;

export function useUpdateDatasourceNewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDatasourceNewMutation,
    GQTypes.GQUpdateDatasourceNewMutationVariables
  >(UpdateDatasourceNewDocument);
}
export const SubmitUserUploadTaskDocument = gql`
    mutation SubmitUserUploadTask($input: SubmitUserUploadTaskInput!, $measurementProjectId: ID) {
  submitUserUploadTask(input: $input) {
    userUploadTask {
      ...UserUploadTaskWithDatasource
    }
  }
}
    ${UserUploadTaskWithDatasourceFragmentDoc}`;

export function useSubmitUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQSubmitUserUploadTaskMutation,
    GQTypes.GQSubmitUserUploadTaskMutationVariables
  >(SubmitUserUploadTaskDocument);
}
export const SingleFacilityPageDocument = gql`
    query SingleFacilityPage($datasourceId: ID!, $measurementProjectId: ID!, $buildingId: ID!) {
  userUploadTaskForDatasource(
    datasourceId: $datasourceId
    measurementProjectId: $measurementProjectId
  ) {
    id
    datasource {
      id
      name
      dataset {
        id
        canonicalDataset {
          id
          kind
          customerTargetSchemas {
            id
          }
        }
        ...DatasetForImporter
        instructions {
          ...InstructionsBundleForImporter
        }
      }
    }
    measurementProject {
      id
      name
      coverageInterval
      active
    }
    lockState
    buildingLockedRevisions {
      id
      revision
    }
  }
  facility(id: $buildingId) {
    id
    ...SingleFacilityBuildingProperties
  }
  userHasPermission: userHasViewDatasourcePermission(datasourceId: $datasourceId)
  organization {
    id
    ingestionCustomFields {
      ...CustomFieldForBuildingsGridPage
      bartTagName
      ingestionCustomFieldDatasources {
        id
      }
    }
  }
}
    ${DatasetForImporterFragmentDoc}
${InstructionsBundleForImporterFragmentDoc}
${SingleFacilityBuildingPropertiesFragmentDoc}
${CustomFieldForBuildingsGridPageFragmentDoc}`;

export function useSingleFacilityPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSingleFacilityPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSingleFacilityPageQuery,
    GQTypes.GQSingleFacilityPageQueryVariables
  >({ query: SingleFacilityPageDocument, ...options });
}
export const DatasourceValueMappingPageDocument = gql`
    query DatasourceValueMappingPage($datasourceId: ID, $measurementProjectId: ID!, $valueMappingRuleId: String!) {
  datasourceValueMappingRule(
    datasourceId: $datasourceId
    measurementProjectId: $measurementProjectId
    valueMappingRuleId: $valueMappingRuleId
  ) {
    id
    ...DatasourceValueMappingRule
  }
  measurementProject(id: $measurementProjectId) {
    id
    datasetsWithStages {
      id
    }
    ...DatasourceValueMappingMeasurementProject
  }
  userHasPermission: userHasViewDatasourcePermission(datasourceId: $datasourceId)
}
    ${DatasourceValueMappingRuleFragmentDoc}
${DatasourceValueMappingMeasurementProjectFragmentDoc}`;

export function useDatasourceValueMappingPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasourceValueMappingPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasourceValueMappingPageQuery,
    GQTypes.GQDatasourceValueMappingPageQueryVariables
  >({ query: DatasourceValueMappingPageDocument, ...options });
}
export const ActivitySummaryDocument = gql`
    query ActivitySummary($input: BiActivityQueryContextInput!) {
  activityContextMetadata(input: $input) {
    activityDatasets {
      ...ActivitySummaryActivityDataset
    }
  }
  legacyActivityRecords {
    stableId
  }
}
    ${ActivitySummaryActivityDatasetFragmentDoc}`;

export function useActivitySummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivitySummaryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivitySummaryQuery,
    GQTypes.GQActivitySummaryQueryVariables
  >({ query: ActivitySummaryDocument, ...options });
}
export const LegacyActivityDataTablesDocument = gql`
    query LegacyActivityDataTables {
  legacyActivityRecords {
    ...LegacyActivityRecord
  }
}
    ${LegacyActivityRecordFragmentDoc}`;

export function useLegacyActivityDataTablesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLegacyActivityDataTablesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLegacyActivityDataTablesQuery,
    GQTypes.GQLegacyActivityDataTablesQueryVariables
  >({ query: LegacyActivityDataTablesDocument, ...options });
}
export const PrepareLegacyActivityDataExportDocument = gql`
    mutation PrepareLegacyActivityDataExport($input: PrepareActivityRecordExportInput!) {
  prepareActivityRecordExport(input: $input) {
    backgroundJobId
  }
}
    `;

export function usePrepareLegacyActivityDataExportMutation() {
  return Urql.useMutation<
    GQTypes.GQPrepareLegacyActivityDataExportMutation,
    GQTypes.GQPrepareLegacyActivityDataExportMutationVariables
  >(PrepareLegacyActivityDataExportDocument);
}
export const CustomFieldsSettingsDocument = gql`
    query CustomFieldsSettings {
  organization {
    ...OrganizationCustomFieldsForManagePage
  }
  footprintTags {
    id
    tagName
    rulesList
  }
}
    ${OrganizationCustomFieldsForManagePageFragmentDoc}`;

export function useCustomFieldsSettingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomFieldsSettingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomFieldsSettingsQuery,
    GQTypes.GQCustomFieldsSettingsQueryVariables
  >({ query: CustomFieldsSettingsDocument, ...options });
}
export const UserUploadVerificationLogsDocument = gql`
    query UserUploadVerificationLogs($measurementProjectId: ID!) {
  measurementProject(id: $measurementProjectId) {
    id
    status
    userUploadVerificationLogs {
      id
      fileId
      fileName
      dataset
      datasource
      dataReviewCompleted
      sheetName
      submittedAt
      submittedBy
      transformation
    }
  }
}
    `;

export function useUserUploadVerificationLogsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadVerificationLogsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadVerificationLogsQuery,
    GQTypes.GQUserUploadVerificationLogsQueryVariables
  >({ query: UserUploadVerificationLogsDocument, ...options });
}
export const DataGovernanceDownloadAllUrlDocument = gql`
    query DataGovernanceDownloadAllUrl($measurementProjectId: ID!) {
  dataGovernanceDownloadAllUrl(measurementProjectId: $measurementProjectId)
}
    `;

export function useDataGovernanceDownloadAllUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataGovernanceDownloadAllUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataGovernanceDownloadAllUrlQuery,
    GQTypes.GQDataGovernanceDownloadAllUrlQueryVariables
  >({ query: DataGovernanceDownloadAllUrlDocument, ...options });
}
export const DataGovernancePageDocument = gql`
    query DataGovernancePage {
  organization {
    id
    measurementProjects {
      ...MeasurementProjectForDataGovernance
    }
  }
}
    ${MeasurementProjectForDataGovernanceFragmentDoc}`;

export function useDataGovernancePageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataGovernancePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataGovernancePageQuery,
    GQTypes.GQDataGovernancePageQueryVariables
  >({ query: DataGovernancePageDocument, ...options });
}
export const NewMeasurementWizardDocument = gql`
    query NewMeasurementWizard {
  climateProgramProjects(includeMeasureWhatMatters: true) {
    __typename
    ...ClimateProgramProjectWithMeasurementConcernsFields
  }
  canonicalClimateProgramProjects(isForMeasurement: true) {
    __typename
    ...CanonicalClimateProgramProjectWithMeasurementConcernsFields
  }
  organization {
    id
    fiscalYearStartMonth
    measurementProjects {
      id
      name
    }
  }
  userHasPermission(permission: ManageMeasurement)
  activeMeasurementProjects(limit: 1) {
    id
  }
}
    ${ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}
${CanonicalClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}`;

export function useNewMeasurementWizardQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQNewMeasurementWizardQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQNewMeasurementWizardQuery,
    GQTypes.GQNewMeasurementWizardQueryVariables
  >({ query: NewMeasurementWizardDocument, ...options });
}
export const CreateMeasurementProjectFromWizardDocument = gql`
    mutation CreateMeasurementProjectFromWizard($input: CreateMeasurementProjectFromWizardInput!) {
  createMeasurementProjectFromWizard(input: $input) {
    measurementProject {
      ...MeasurementProjectForMeasurementPage
      ...MeasurementProjectFields
    }
    climateProgramProjects {
      id
      measurementProject {
        id
        name
      }
    }
  }
}
    ${MeasurementProjectForMeasurementPageFragmentDoc}
${MeasurementProjectFieldsFragmentDoc}`;

export function useCreateMeasurementProjectFromWizardMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementProjectFromWizardMutation,
    GQTypes.GQCreateMeasurementProjectFromWizardMutationVariables
  >(CreateMeasurementProjectFromWizardDocument);
}
export const CreateClimateProgramProjectForMeasurementDocument = gql`
    mutation CreateClimateProgramProjectForMeasurement($input: CreateClimateProgramProjectInput!) {
  createClimateProgramProject(input: $input) {
    climateProgramProject {
      ...ClimateProgramProjectWithMeasurementConcernsFields
    }
  }
}
    ${ClimateProgramProjectWithMeasurementConcernsFieldsFragmentDoc}`;

export function useCreateClimateProgramProjectForMeasurementMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateClimateProgramProjectForMeasurementMutation,
    GQTypes.GQCreateClimateProgramProjectForMeasurementMutationVariables
  >(CreateClimateProgramProjectForMeasurementDocument);
}
export const MeasurementProjectsPageDocument = gql`
    query MeasurementProjectsPage {
  userHasManageMeasurement: userHasPermission(permission: ManageMeasurement)
  organization {
    id
    measurementProjects {
      ...MeasurementProjectsPageProject
    }
  }
}
    ${MeasurementProjectsPageProjectFragmentDoc}`;

export function useMeasurementProjectsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectsPageQuery,
    GQTypes.GQMeasurementProjectsPageQueryVariables
  >({ query: MeasurementProjectsPageDocument, ...options });
}
export const SharedFilesPageDocument = gql`
    query SharedFilesPage($currentUserId: ID!) {
  organization {
    id
    measurementProjects {
      id
      name
      sharedFiles(forUserId: $currentUserId) {
        edges {
          node {
            ...SharedFileForDashboardPage
          }
        }
      }
      status
      completedAt
      coverageEndDate
      deadline
    }
  }
}
    ${SharedFileForDashboardPageFragmentDoc}`;

export function useSharedFilesPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSharedFilesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSharedFilesPageQuery,
    GQTypes.GQSharedFilesPageQueryVariables
  >({ query: SharedFilesPageDocument, ...options });
}
export const GetMeasurementProjectsDataDocument = gql`
    query GetMeasurementProjectsData {
  organization {
    id
    measurementProjects {
      id
      name
      active
      status
      completedAt
      coverageInterval
      coverageStartDate
      coverageEndDate
      deadline
    }
  }
}
    `;

export function useGetMeasurementProjectsDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMeasurementProjectsDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMeasurementProjectsDataQuery,
    GQTypes.GQGetMeasurementProjectsDataQueryVariables
  >({ query: GetMeasurementProjectsDataDocument, ...options });
}
export const LatestOrgStructureVersionDocument = gql`
    query LatestOrgStructureVersion {
  latestOrgStructureVersion {
    ...OrgStructureVersionWithTypesAndUnits
  }
}
    ${OrgStructureVersionWithTypesAndUnitsFragmentDoc}`;

export function useLatestOrgStructureVersionQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestOrgStructureVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestOrgStructureVersionQuery,
    GQTypes.GQLatestOrgStructureVersionQueryVariables
  >({ query: LatestOrgStructureVersionDocument, ...options });
}
export const OrgStructureVersionsDocument = gql`
    query OrgStructureVersions($versionId: ID) {
  orgStructureVersions(versionId: $versionId) {
    ...OrgStructureVersion
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useOrgStructureVersionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgStructureVersionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgStructureVersionsQuery,
    GQTypes.GQOrgStructureVersionsQueryVariables
  >({ query: OrgStructureVersionsDocument, ...options });
}
export const OrgUnitTypesDocument = gql`
    query OrgUnitTypes($versionId: ID!) {
  orgUnitTypes(versionId: $versionId) {
    ...OrgUnitType
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useOrgUnitTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUnitTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitTypesQuery,
    GQTypes.GQOrgUnitTypesQueryVariables
  >({ query: OrgUnitTypesDocument, ...options });
}
export const OrgUnitTypeDocument = gql`
    query OrgUnitType($versionId: ID!, $orgUnitTypeId: ID!) {
  orgUnitTypes(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnitType
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useOrgUnitTypeQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQOrgUnitTypeQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitTypeQuery,
    GQTypes.GQOrgUnitTypeQueryVariables
  >({ query: OrgUnitTypeDocument, ...options });
}
export const OrgUnitRelationshipExplorerPageDocument = gql`
    query OrgUnitRelationshipExplorerPage($versionId: ID!, $orgUnitTypeId: ID) {
  orgUnits(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnit
  }
  orgUnitTypeRelationships(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnitTypeRelationship
  }
  orgUnitTypes(versionId: $versionId) {
    id
    name
    orgUnits {
      id
      name
      externalId
    }
  }
}
    ${OrgUnitFragmentDoc}
${OrgUnitTypeRelationshipFragmentDoc}`;

export function useOrgUnitRelationshipExplorerPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUnitRelationshipExplorerPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitRelationshipExplorerPageQuery,
    GQTypes.GQOrgUnitRelationshipExplorerPageQueryVariables
  >({ query: OrgUnitRelationshipExplorerPageDocument, ...options });
}
export const OrgUnitGridPageDocument = gql`
    query OrgUnitGridPage($versionId: ID!, $orgUnitTypeId: ID!) {
  orgUnits(versionId: $versionId, orgUnitTypeId: $orgUnitTypeId) {
    ...OrgUnit
  }
  orgUnitTypes(versionId: $versionId) {
    ...OrgUnitTypeForOrgUnitGrid
  }
}
    ${OrgUnitFragmentDoc}
${OrgUnitTypeForOrgUnitGridFragmentDoc}`;

export function useOrgUnitGridPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUnitGridPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUnitGridPageQuery,
    GQTypes.GQOrgUnitGridPageQueryVariables
  >({ query: OrgUnitGridPageDocument, ...options });
}
export const OrganizationForOrgUnitTypePageDocument = gql`
    query OrganizationForOrgUnitTypePage {
  organization {
    ...OrganizationForOrgUnitTypePage
  }
}
    ${OrganizationForOrgUnitTypePageFragmentDoc}`;

export function useOrganizationForOrgUnitTypePageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationForOrgUnitTypePageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationForOrgUnitTypePageQuery,
    GQTypes.GQOrganizationForOrgUnitTypePageQueryVariables
  >({ query: OrganizationForOrgUnitTypePageDocument, ...options });
}
export const GetOrgStructureVersionUploadDiffDocument = gql`
    query GetOrgStructureVersionUploadDiff($orgStructureVersionUploadId: ID!) {
  orgStructureVersionUpload(
    orgStructureVersionUploadId: $orgStructureVersionUploadId
  ) {
    id
    isInvalid
    diff
  }
}
    `;

export function useGetOrgStructureVersionUploadDiffQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrgStructureVersionUploadDiffQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrgStructureVersionUploadDiffQuery,
    GQTypes.GQGetOrgStructureVersionUploadDiffQueryVariables
  >({ query: GetOrgStructureVersionUploadDiffDocument, ...options });
}
export const UpsertOrgStructureVersionDocument = gql`
    mutation UpsertOrgStructureVersion($input: UpsertOrgStructureVersionInput!) {
  upsertOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useUpsertOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertOrgStructureVersionMutation,
    GQTypes.GQUpsertOrgStructureVersionMutationVariables
  >(UpsertOrgStructureVersionDocument);
}
export const PublishOrgStructureVersionDocument = gql`
    mutation PublishOrgStructureVersion($input: PublishOrgStructureVersionInput!) {
  publishOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function usePublishOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishOrgStructureVersionMutation,
    GQTypes.GQPublishOrgStructureVersionMutationVariables
  >(PublishOrgStructureVersionDocument);
}
export const UnpublishOrgStructureVersionDocument = gql`
    mutation UnpublishOrgStructureVersion($input: UnpublishOrgStructureVersionInput!) {
  unpublishOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useUnpublishOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUnpublishOrgStructureVersionMutation,
    GQTypes.GQUnpublishOrgStructureVersionMutationVariables
  >(UnpublishOrgStructureVersionDocument);
}
export const DeleteOrgStructureVersionDocument = gql`
    mutation DeleteOrgStructureVersion($input: DeleteOrgStructureVersionInput!) {
  deleteOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useDeleteOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgStructureVersionMutation,
    GQTypes.GQDeleteOrgStructureVersionMutationVariables
  >(DeleteOrgStructureVersionDocument);
}
export const DuplicateOrgStructureVersionDocument = gql`
    mutation DuplicateOrgStructureVersion($input: DuplicateOrgStructureVersionInput!) {
  duplicateOrgStructureVersion(input: $input) {
    orgStructureVersion {
      ...OrgStructureVersion
    }
  }
}
    ${OrgStructureVersionFragmentDoc}`;

export function useDuplicateOrgStructureVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicateOrgStructureVersionMutation,
    GQTypes.GQDuplicateOrgStructureVersionMutationVariables
  >(DuplicateOrgStructureVersionDocument);
}
export const CreateOrgUnitTypeDocument = gql`
    mutation CreateOrgUnitType($input: CreateOrgUnitTypeInput!) {
  createOrgUnitType(input: $input) {
    orgUnitType {
      ...OrgUnitType
    }
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useCreateOrgUnitTypeMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgUnitTypeMutation,
    GQTypes.GQCreateOrgUnitTypeMutationVariables
  >(CreateOrgUnitTypeDocument);
}
export const UpdateOrgUnitTypeDocument = gql`
    mutation UpdateOrgUnitType($input: UpdateOrgUnitTypeInput!) {
  updateOrgUnitType(input: $input) {
    orgUnitType {
      ...OrgUnitType
    }
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useUpdateOrgUnitTypeMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgUnitTypeMutation,
    GQTypes.GQUpdateOrgUnitTypeMutationVariables
  >(UpdateOrgUnitTypeDocument);
}
export const DeleteOrgUnitTypeDocument = gql`
    mutation DeleteOrgUnitType($input: DeleteOrgUnitTypeInput!) {
  deleteOrgUnitType(input: $input) {
    orgUnitType {
      ...OrgUnitType
    }
  }
}
    ${OrgUnitTypeFragmentDoc}`;

export function useDeleteOrgUnitTypeMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgUnitTypeMutation,
    GQTypes.GQDeleteOrgUnitTypeMutationVariables
  >(DeleteOrgUnitTypeDocument);
}
export const CreateOrgUnitDocument = gql`
    mutation CreateOrgUnit($input: CreateOrgUnitInput!) {
  createOrgUnit(input: $input) {
    orgUnit {
      ...OrgUnit
    }
  }
}
    ${OrgUnitFragmentDoc}`;

export function useCreateOrgUnitMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgUnitMutation,
    GQTypes.GQCreateOrgUnitMutationVariables
  >(CreateOrgUnitDocument);
}
export const UpdateOrgUnitsDocument = gql`
    mutation UpdateOrgUnits($input: UpdateOrgUnitsInput!) {
  updateOrgUnits(input: $input) {
    orgUnits {
      ...OrgUnit
    }
  }
}
    ${OrgUnitFragmentDoc}`;

export function useUpdateOrgUnitsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgUnitsMutation,
    GQTypes.GQUpdateOrgUnitsMutationVariables
  >(UpdateOrgUnitsDocument);
}
export const DeleteOrgUnitsDocument = gql`
    mutation DeleteOrgUnits($input: DeleteOrgUnitsInput!) {
  deleteOrgUnits(input: $input) {
    orgUnits {
      ...OrgUnit
    }
  }
}
    ${OrgUnitFragmentDoc}`;

export function useDeleteOrgUnitsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgUnitsMutation,
    GQTypes.GQDeleteOrgUnitsMutationVariables
  >(DeleteOrgUnitsDocument);
}
export const CreateOrgUnitTypeRelationshipDocument = gql`
    mutation CreateOrgUnitTypeRelationship($input: CreateOrgUnitTypeRelationshipInput!) {
  createOrgUnitTypeRelationship(input: $input) {
    orgUnitTypeRelationship {
      ...OrgUnitTypeRelationship
    }
    orgUnits {
      ...OrgUnit
    }
  }
}
    ${OrgUnitTypeRelationshipFragmentDoc}
${OrgUnitFragmentDoc}`;

export function useCreateOrgUnitTypeRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgUnitTypeRelationshipMutation,
    GQTypes.GQCreateOrgUnitTypeRelationshipMutationVariables
  >(CreateOrgUnitTypeRelationshipDocument);
}
export const UpdateOrgUnitTypeRelationshipDocument = gql`
    mutation UpdateOrgUnitTypeRelationship($input: UpdateOrgUnitTypeRelationshipInput!) {
  updateOrgUnitTypeRelationship(input: $input) {
    orgUnitTypeRelationship {
      ...OrgUnitTypeRelationship
    }
    orgUnits {
      ...OrgUnit
    }
  }
}
    ${OrgUnitTypeRelationshipFragmentDoc}
${OrgUnitFragmentDoc}`;

export function useUpdateOrgUnitTypeRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgUnitTypeRelationshipMutation,
    GQTypes.GQUpdateOrgUnitTypeRelationshipMutationVariables
  >(UpdateOrgUnitTypeRelationshipDocument);
}
export const DeleteOrgUnitTypeRelationshipDocument = gql`
    mutation DeleteOrgUnitTypeRelationship($input: DeleteOrgUnitTypeRelationshipInput!) {
  deleteOrgUnitTypeRelationship(input: $input) {
    orgUnitTypeRelationship {
      ...OrgUnitTypeRelationship
    }
  }
}
    ${OrgUnitTypeRelationshipFragmentDoc}`;

export function useDeleteOrgUnitTypeRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrgUnitTypeRelationshipMutation,
    GQTypes.GQDeleteOrgUnitTypeRelationshipMutationVariables
  >(DeleteOrgUnitTypeRelationshipDocument);
}
export const CreateOrgStructureVersionUploadDocument = gql`
    mutation CreateOrgStructureVersionUpload($input: CreateOrgStructureVersionUploadInput!) {
  createOrgStructureVersionUpload(input: $input) {
    orgStructureVersionUpload {
      id
    }
    backgroundJobId
  }
}
    `;

export function useCreateOrgStructureVersionUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgStructureVersionUploadMutation,
    GQTypes.GQCreateOrgStructureVersionUploadMutationVariables
  >(CreateOrgStructureVersionUploadDocument);
}
export const CreateOrgStructureImportTemplateDocument = gql`
    mutation CreateOrgStructureImportTemplate($input: CreateOrgStructureImportTemplateInput!) {
  createOrgStructureImportTemplate(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function useCreateOrgStructureImportTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgStructureImportTemplateMutation,
    GQTypes.GQCreateOrgStructureImportTemplateMutationVariables
  >(CreateOrgStructureImportTemplateDocument);
}
export const AcceptOrgStructureVersionUploadDocument = gql`
    mutation AcceptOrgStructureVersionUpload($input: AcceptOrgStructureVersionUploadInput!) {
  acceptOrgStructureVersionUpload(input: $input) {
    orgStructureVersionUpload {
      id
      uploadStats
    }
  }
}
    `;

export function useAcceptOrgStructureVersionUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptOrgStructureVersionUploadMutation,
    GQTypes.GQAcceptOrgStructureVersionUploadMutationVariables
  >(AcceptOrgStructureVersionUploadDocument);
}
export const ForecastsPageDocument = gql`
    query ForecastsPage {
  forecast {
    ...ForecastFields
  }
}
    ${ForecastFieldsFragmentDoc}`;

export function useForecastsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQForecastsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQForecastsPageQuery,
    GQTypes.GQForecastsPageQueryVariables
  >({ query: ForecastsPageDocument, ...options });
}
export const UpdateForecastDocument = gql`
    mutation UpdateForecast($input: UpdateForecastInput!) {
  updateForecast(input: $input) {
    forecast {
      ...ForecastFields
    }
    plans {
      ...PlanWithForecastFields
    }
  }
}
    ${ForecastFieldsFragmentDoc}
${PlanWithForecastFieldsFragmentDoc}`;

export function useUpdateForecastMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateForecastMutation,
    GQTypes.GQUpdateForecastMutationVariables
  >(UpdateForecastDocument);
}
export const GetFirstPlanDocument = gql`
    query GetFirstPlan {
  plansWithDefaults {
    edges {
      node {
        ...PlanForPlanTab
      }
    }
  }
}
    ${PlanForPlanTabFragmentDoc}`;

export function useGetFirstPlanQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFirstPlanQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFirstPlanQuery,
    GQTypes.GQGetFirstPlanQueryVariables
  >({ query: GetFirstPlanDocument, ...options });
}
export const ReductionsMetricsPageDocument = gql`
    query ReductionsMetricsPage {
  forecast {
    ...ReductionsMetricsPage_forecast
    id
  }
}
    ${ReductionsMetricsPage_ForecastFragmentDoc}`;

export function useReductionsMetricsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReductionsMetricsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReductionsMetricsPageQuery,
    GQTypes.GQReductionsMetricsPageQueryVariables
  >({ query: ReductionsMetricsPageDocument, ...options });
}
export const ReductionsMetricsPage_UpdateForecastDocument = gql`
    mutation ReductionsMetricsPage_updateForecast($input: UpdateForecastInput!) {
  updateForecast(input: $input) {
    forecast {
      ...ReductionsMetricsPage_forecast
      id
    }
  }
}
    ${ReductionsMetricsPage_ForecastFragmentDoc}`;

export function useReductionsMetricsPage_UpdateForecastMutation() {
  return Urql.useMutation<
    GQTypes.GQReductionsMetricsPage_UpdateForecastMutation,
    GQTypes.GQReductionsMetricsPage_UpdateForecastMutationVariables
  >(ReductionsMetricsPage_UpdateForecastDocument);
}
export const PlanForPlanSettingsPageDocument = gql`
    query PlanForPlanSettingsPage($planId: ID!) {
  plan(id: $planId) {
    ...PlanForPlanSettings
  }
}
    ${PlanForPlanSettingsFragmentDoc}`;

export function usePlanForPlanSettingsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPlanForPlanSettingsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPlanForPlanSettingsPageQuery,
    GQTypes.GQPlanForPlanSettingsPageQueryVariables
  >({ query: PlanForPlanSettingsPageDocument, ...options });
}
export const UpdatePlanSettingsDocument = gql`
    mutation UpdatePlanSettings($input: UpdatePlanSettingsInput!) {
  updatePlanSettings(input: $input) {
    plan {
      ...PlanWithForecastFields
    }
  }
}
    ${PlanWithForecastFieldsFragmentDoc}`;

export function useUpdatePlanSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanSettingsMutation,
    GQTypes.GQUpdatePlanSettingsMutationVariables
  >(UpdatePlanSettingsDocument);
}
export const SaveEmployeeFeedbackDocument = gql`
    mutation SaveEmployeeFeedback($feedback: String) {
  saveEmployeeFeedback(input: {feedback: $feedback}) {
    id
  }
}
    `;

export function useSaveEmployeeFeedbackMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveEmployeeFeedbackMutation,
    GQTypes.GQSaveEmployeeFeedbackMutationVariables
  >(SaveEmployeeFeedbackDocument);
}
export const ReportPageContentDocument = gql`
    query ReportPageContent($orgId: ID!, $reportId: ID!) {
  activeOrganization {
    ...ActiveOrganizationFields
  }
  peerCompanyGroups(orgId: $orgId) {
    ...PeerCompanyGroupFields
  }
  forceRedirect
  flags {
    id
    name
    activated
  }
  report(id: $reportId) {
    id
    footprintInterval
    marketplaceInterval
    programKind
    visibility
    plan {
      ...PlanFieldsForReport
    }
    grossEmissions
    grossEmissionsRevenueIntensity: grossEmissions(kind: RevenueIntensity)
    categorizedEmissions {
      businessCategory
      businessSubcategory
      proportion
      amountKgco2e
    }
    netZeroPlan {
      id
      descriptionMd
      summaryTotal: summary(kind: Total) {
        years {
          ...NetZeroSummaryYearFields
        }
        kind
      }
      summaryRevenue: summary(kind: Revenue) {
        years {
          ...NetZeroSummaryYearFields
        }
        kind
      }
    }
    footprintKindBreakdown {
      id
      footprintKinds {
        id
        footprintKind
        kgco2e
        percentOfTotal
      }
    }
    markdownCardInfo {
      title
      contentMd
    }
    climateProgramInfo {
      steps {
        title
        descriptionMd
      }
      targets {
        title
        amount
        descriptor
      }
    }
    reductionTargetsInfo {
      heading
      reductionTargets {
        heading
        subheading
        title
        description
      }
    }
    marketplacePurchaseLineItems {
      ...MarketplacePurchaseLineItemFieldsForSummaryReport
    }
  }
}
    ${ActiveOrganizationFieldsFragmentDoc}
${PeerCompanyGroupFieldsFragmentDoc}
${PlanFieldsForReportFragmentDoc}
${NetZeroSummaryYearFieldsFragmentDoc}
${MarketplacePurchaseLineItemFieldsForSummaryReportFragmentDoc}`;

export function useReportPageContentQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportPageContentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportPageContentQuery,
    GQTypes.GQReportPageContentQueryVariables
  >({ query: ReportPageContentDocument, ...options });
}
export const OfficialReportsPageDocument = gql`
    query OfficialReportsPage {
  ...hasAccessToSummaryReport_query
}
    ${HasAccessToSummaryReport_QueryFragmentDoc}`;

export function useOfficialReportsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOfficialReportsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOfficialReportsPageQuery,
    GQTypes.GQOfficialReportsPageQueryVariables
  >({ query: OfficialReportsPageDocument, ...options });
}
export const PeerCompanyReportsDocument = gql`
    query PeerCompanyReports($orgId: ID!) {
  peerCompanyGroups(orgId: $orgId) {
    ...PeerCompanyReports
  }
}
    ${PeerCompanyReportsFragmentDoc}`;

export function usePeerCompanyReportsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerCompanyReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerCompanyReportsQuery,
    GQTypes.GQPeerCompanyReportsQueryVariables
  >({ query: PeerCompanyReportsDocument, ...options });
}
export const PeerReportsPageDocument = gql`
    query PeerReportsPage($reportTypes: [ExternalReportType!], $publishingYears: [Int!], $companyIds: [ID!], $filterLicensed: Boolean, $after: String, $before: String, $first: Int, $last: Int) {
  publicDisclosuresPaginated(
    reportTypes: $reportTypes
    publishingYears: $publishingYears
    companyIds: $companyIds
    filterLicensed: $filterLicensed
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...PublicDisclosureDisplay
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
  uniqueExternalReportFilterValues {
    reportTypes
    publishingYears
  }
}
    ${PublicDisclosureDisplayFragmentDoc}`;

export function usePeerReportsPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerReportsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerReportsPageQuery,
    GQTypes.GQPeerReportsPageQueryVariables
  >({ query: PeerReportsPageDocument, ...options });
}
export const WatershedRecommendedPeerReportsDocument = gql`
    query WatershedRecommendedPeerReports($after: String, $before: String, $first: Int, $last: Int) {
  recommendedPublicDisclosures(
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...PublicDisclosureDisplay
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${PublicDisclosureDisplayFragmentDoc}`;

export function useWatershedRecommendedPeerReportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQWatershedRecommendedPeerReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQWatershedRecommendedPeerReportsQuery,
    GQTypes.GQWatershedRecommendedPeerReportsQueryVariables
  >({ query: WatershedRecommendedPeerReportsDocument, ...options });
}
export const CompanyFuzzyMatchForPublicDisclosureDocument = gql`
    query CompanyFuzzyMatchForPublicDisclosure($q: String!) {
  companyFuzzyMatch(q: $q) {
    id
    name
    disclosuresV2 {
      id
      publicDisclosure {
        id
      }
    }
  }
}
    `;

export function useCompanyFuzzyMatchForPublicDisclosureQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyFuzzyMatchForPublicDisclosureQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyFuzzyMatchForPublicDisclosureQuery,
    GQTypes.GQCompanyFuzzyMatchForPublicDisclosureQueryVariables
  >({ query: CompanyFuzzyMatchForPublicDisclosureDocument, ...options });
}
export const GetSsoConfigPageDocument = gql`
    query GetSsoConfigPage {
  userHasPermission(permission: ManageSingleSignOn)
  organization {
    id
    workosOrgId
    users(filters: {isAdmin: true}) {
      edges {
        node {
          id
          name
          email
        }
      }
    }
    hasActiveWorkosConnection
  }
}
    `;

export function useGetSsoConfigPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSsoConfigPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSsoConfigPageQuery,
    GQTypes.GQGetSsoConfigPageQueryVariables
  >({ query: GetSsoConfigPageDocument, ...options });
}
export const GetAllAssignableUsersWithPermissionBatchDocument = gql`
    query GetAllAssignableUsersWithPermissionBatch($permissions: [HasPermissionInput!]!, $orgId: ID!) {
  organization {
    id
    usersWithPermissions: users(
      filters: {withSomePermissions: $permissions, includeWatershedEmployees: true}
    ) {
      edges {
        node {
          id
        }
      }
    }
    users {
      edges {
        node {
          ...AssignableUserFields
          orgAccessId(orgId: $orgId)
        }
      }
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useGetAllAssignableUsersWithPermissionBatchQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllAssignableUsersWithPermissionBatchQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllAssignableUsersWithPermissionBatchQuery,
    GQTypes.GQGetAllAssignableUsersWithPermissionBatchQueryVariables
  >({ query: GetAllAssignableUsersWithPermissionBatchDocument, ...options });
}
export const GetAllAssignableUsersWithPermissionDocument = gql`
    query GetAllAssignableUsersWithPermission($permissions: [HasPermissionInput!]!, $orgId: ID!) {
  organization {
    id
    users {
      edges {
        node {
          hasPermissions(permissions: $permissions, orgId: $orgId)
          hasAnyPermissions(permissions: $permissions, orgId: $orgId)
          ...AssignableUserFields
          orgAccessId(orgId: $orgId)
        }
      }
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useGetAllAssignableUsersWithPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllAssignableUsersWithPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllAssignableUsersWithPermissionQuery,
    GQTypes.GQGetAllAssignableUsersWithPermissionQueryVariables
  >({ query: GetAllAssignableUsersWithPermissionDocument, ...options });
}
export const ActiveOrganizationDocument = gql`
    query ActiveOrganization {
  activeOrganization {
    ...ActiveOrganizationFields
  }
  flags {
    ...FlagsInitFields
  }
  preferredLocale
}
    ${ActiveOrganizationFieldsFragmentDoc}
${FlagsInitFieldsFragmentDoc}`;

export function useActiveOrganizationQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQActiveOrganizationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActiveOrganizationQuery,
    GQTypes.GQActiveOrganizationQueryVariables
  >({ query: ActiveOrganizationDocument, ...options });
}
export const ForceRedirectDocument = gql`
    query ForceRedirect($pathname: String) {
  forceRedirect(pathname: $pathname)
}
    `;

export function useForceRedirectQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQForceRedirectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQForceRedirectQuery,
    GQTypes.GQForceRedirectQueryVariables
  >({ query: ForceRedirectDocument, ...options });
}
export const CheckUserAuthenticationDocument = gql`
    query CheckUserAuthentication {
  activeOrganization {
    id
  }
}
    `;

export function useCheckUserAuthenticationQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCheckUserAuthenticationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCheckUserAuthenticationQuery,
    GQTypes.GQCheckUserAuthenticationQueryVariables
  >({ query: CheckUserAuthenticationDocument, ...options });
}
export const ThrowErrorDocument = gql`
    mutation ThrowError {
  throwError(input: {fooString: "abcd", password: "1234"}) {
    message
  }
}
    `;

export function useThrowErrorMutation() {
  return Urql.useMutation<
    GQTypes.GQThrowErrorMutation,
    GQTypes.GQThrowErrorMutationVariables
  >(ThrowErrorDocument);
}
export const CompleteOnboardingDocument = gql`
    mutation CompleteOnboarding($userId: ID!, $kind: OnboardingKind!) {
  completeOnboarding(input: {userId: $userId, kind: $kind}) {
    user {
      id
    }
    userOnboardingsCompleted
  }
}
    `;

export function useCompleteOnboardingMutation() {
  return Urql.useMutation<
    GQTypes.GQCompleteOnboardingMutation,
    GQTypes.GQCompleteOnboardingMutationVariables
  >(CompleteOnboardingDocument);
}
export const FundListDocument = gql`
    query FundList {
  funds {
    __typename
    id
  }
}
    `;

export function useFundListQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQFundListQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQFundListQuery,
    GQTypes.GQFundListQueryVariables
  >({ query: FundListDocument, ...options });
}
export const AssetCorporateListDocument = gql`
    query AssetCorporateList {
  assetsCorporate {
    id
  }
}
    `;

export function useAssetCorporateListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetCorporateListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetCorporateListQuery,
    GQTypes.GQAssetCorporateListQueryVariables
  >({ query: AssetCorporateListDocument, ...options });
}
export const AssetRealEstateListDocument = gql`
    query AssetRealEstateList {
  assetsRealEstate {
    id
  }
}
    `;

export function useAssetRealEstateListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetRealEstateListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetRealEstateListQuery,
    GQTypes.GQAssetRealEstateListQueryVariables
  >({ query: AssetRealEstateListDocument, ...options });
}
export const AssetGroupListDocument = gql`
    query AssetGroupList {
  assetGroups {
    id
  }
}
    `;

export function useAssetGroupListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetGroupListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetGroupListQuery,
    GQTypes.GQAssetGroupListQueryVariables
  >({ query: AssetGroupListDocument, ...options });
}
export const AssetSovereignBondListDocument = gql`
    query AssetSovereignBondList {
  assetSovereignBonds {
    id
  }
}
    `;

export function useAssetSovereignBondListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetSovereignBondListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetSovereignBondListQuery,
    GQTypes.GQAssetSovereignBondListQueryVariables
  >({ query: AssetSovereignBondListDocument, ...options });
}
export const AssetPersonalMotorVehicleInsuranceListDocument = gql`
    query AssetPersonalMotorVehicleInsuranceList {
  assetPersonalMotorVehicleInsurances {
    id
  }
}
    `;

export function useAssetPersonalMotorVehicleInsuranceListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetPersonalMotorVehicleInsuranceListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetPersonalMotorVehicleInsuranceListQuery,
    GQTypes.GQAssetPersonalMotorVehicleInsuranceListQueryVariables
  >({ query: AssetPersonalMotorVehicleInsuranceListDocument, ...options });
}
export const FinanceTagListDocument = gql`
    query FinanceTagList {
  financeTags {
    id
  }
}
    `;

export function useFinanceTagListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceTagListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceTagListQuery,
    GQTypes.GQFinanceTagListQueryVariables
  >({ query: FinanceTagListDocument, ...options });
}
export const AssetHoldingListDocument = gql`
    query AssetHoldingList {
  assetHoldings {
    id
  }
}
    `;

export function useAssetHoldingListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAssetHoldingListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAssetHoldingListQuery,
    GQTypes.GQAssetHoldingListQueryVariables
  >({ query: AssetHoldingListDocument, ...options });
}
export const PendingCompanyChangeRequestsDocument = gql`
    query PendingCompanyChangeRequests {
  pendingCompanyChangeRequests {
    id
  }
}
    `;

export function usePendingCompanyChangeRequestsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPendingCompanyChangeRequestsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPendingCompanyChangeRequestsQuery,
    GQTypes.GQPendingCompanyChangeRequestsQueryVariables
  >({ query: PendingCompanyChangeRequestsDocument, ...options });
}
export const OrganizationForCacheUpdateDocument = gql`
    query OrganizationForCacheUpdate {
  organization {
    id
    orgCompanies {
      id
      name
    }
  }
}
    `;

export function useOrganizationForCacheUpdateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationForCacheUpdateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationForCacheUpdateQuery,
    GQTypes.GQOrganizationForCacheUpdateQueryVariables
  >({ query: OrganizationForCacheUpdateDocument, ...options });
}
export const OrganizationForDeleteOrgCompanyCacheUpdateDocument = gql`
    query OrganizationForDeleteOrgCompanyCacheUpdate {
  organization {
    id
    orgCompanies {
      id
      name
    }
  }
}
    `;

export function useOrganizationForDeleteOrgCompanyCacheUpdateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationForDeleteOrgCompanyCacheUpdateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationForDeleteOrgCompanyCacheUpdateQuery,
    GQTypes.GQOrganizationForDeleteOrgCompanyCacheUpdateQueryVariables
  >({ query: OrganizationForDeleteOrgCompanyCacheUpdateDocument, ...options });
}
export const PendingChangeReqsForCreateCacheDocument = gql`
    query PendingChangeReqsForCreateCache {
  pendingCompanyChangeRequests {
    id
  }
}
    `;

export function usePendingChangeReqsForCreateCacheQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPendingChangeReqsForCreateCacheQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPendingChangeReqsForCreateCacheQuery,
    GQTypes.GQPendingChangeReqsForCreateCacheQueryVariables
  >({ query: PendingChangeReqsForCreateCacheDocument, ...options });
}
export const DemoPlatformInputsDocument = gql`
    query DemoPlatformInputs {
  organization {
    id
    measurementProjects {
      ...MeasurementProjectForDemoPlatform
    }
  }
}
    ${MeasurementProjectForDemoPlatformFragmentDoc}`;

export function useDemoPlatformInputsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDemoPlatformInputsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDemoPlatformInputsQuery,
    GQTypes.GQDemoPlatformInputsQueryVariables
  >({ query: DemoPlatformInputsDocument, ...options });
}
export const DemoPlatformRunPageDocument = gql`
    query DemoPlatformRunPage($id: ID!) {
  demoPlatformRun(id: $id) {
    id
    ...DemoPlatformRunForPage
  }
}
    ${DemoPlatformRunForPageFragmentDoc}`;

export function useDemoPlatformRunPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDemoPlatformRunPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDemoPlatformRunPageQuery,
    GQTypes.GQDemoPlatformRunPageQueryVariables
  >({ query: DemoPlatformRunPageDocument, ...options });
}
export const DemoPlatformRunGridDocument = gql`
    query DemoPlatformRunGrid($after: String, $before: String, $first: Int, $last: Int) {
  demoPlatformRuns(after: $after, before: $before, first: $first, last: $last) {
    edges {
      node {
        ...DemoPlatformRunForGrid
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${DemoPlatformRunForGridFragmentDoc}`;

export function useDemoPlatformRunGridQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDemoPlatformRunGridQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDemoPlatformRunGridQuery,
    GQTypes.GQDemoPlatformRunGridQueryVariables
  >({ query: DemoPlatformRunGridDocument, ...options });
}
export const RunDemoPlatformDocument = gql`
    mutation RunDemoPlatform($input: RunDemoPlatformInput!) {
  runDemoPlatform(input: $input) {
    demoPlatformRunId
    jobId
  }
}
    `;

export function useRunDemoPlatformMutation() {
  return Urql.useMutation<
    GQTypes.GQRunDemoPlatformMutation,
    GQTypes.GQRunDemoPlatformMutationVariables
  >(RunDemoPlatformDocument);
}
export const FinanceSnapshotsDocument = gql`
    query FinanceSnapshots {
  financeSnapshots {
    id
    ...FinanceSnapshotForContext
  }
}
    ${FinanceSnapshotForContextFragmentDoc}`;

export function useFinanceSnapshotsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotsQuery,
    GQTypes.GQFinanceSnapshotsQueryVariables
  >({ query: FinanceSnapshotsDocument, ...options });
}
export const FinanceSnapshotForStatusDocument = gql`
    query FinanceSnapshotForStatus($id: ID!) {
  financeSnapshot(id: $id) {
    id
    ...FinanceSnapshotForContext
  }
}
    ${FinanceSnapshotForContextFragmentDoc}`;

export function useFinanceSnapshotForStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSnapshotForStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSnapshotForStatusQuery,
    GQTypes.GQFinanceSnapshotForStatusQueryVariables
  >({ query: FinanceSnapshotForStatusDocument, ...options });
}
export const FinanceFundsDocument = gql`
    query FinanceFunds {
  funds {
    id
    ...FundAllFields
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useFinanceFundsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceFundsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceFundsQuery,
    GQTypes.GQFinanceFundsQueryVariables
  >({ query: FinanceFundsDocument, ...options });
}
export const FinanceAssetsCorporateDocument = gql`
    query FinanceAssetsCorporate {
  assetsCorporate {
    id
    ...AssetCorporateAllFields
    assetGroup {
      id
      name
    }
  }
}
    ${AssetCorporateAllFieldsFragmentDoc}`;

export function useFinanceAssetsCorporateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetsCorporateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetsCorporateQuery,
    GQTypes.GQFinanceAssetsCorporateQueryVariables
  >({ query: FinanceAssetsCorporateDocument, ...options });
}
export const FinanceAssetsRealEstateDocument = gql`
    query FinanceAssetsRealEstate {
  assetsRealEstate {
    id
    ...AssetRealEstateAllFields
    assetGroup {
      id
      name
    }
  }
}
    ${AssetRealEstateAllFieldsFragmentDoc}`;

export function useFinanceAssetsRealEstateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetsRealEstateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetsRealEstateQuery,
    GQTypes.GQFinanceAssetsRealEstateQueryVariables
  >({ query: FinanceAssetsRealEstateDocument, ...options });
}
export const FinanceAssetGroupsDocument = gql`
    query FinanceAssetGroups {
  assetGroups {
    id
    ...AssetGroupAllFields
  }
}
    ${AssetGroupAllFieldsFragmentDoc}`;

export function useFinanceAssetGroupsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetGroupsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetGroupsQuery,
    GQTypes.GQFinanceAssetGroupsQueryVariables
  >({ query: FinanceAssetGroupsDocument, ...options });
}
export const FinanceAssetSovereignBondsDocument = gql`
    query FinanceAssetSovereignBonds {
  assetSovereignBonds {
    id
    ...AssetSovereignBondAllFields
  }
}
    ${AssetSovereignBondAllFieldsFragmentDoc}`;

export function useFinanceAssetSovereignBondsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetSovereignBondsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetSovereignBondsQuery,
    GQTypes.GQFinanceAssetSovereignBondsQueryVariables
  >({ query: FinanceAssetSovereignBondsDocument, ...options });
}
export const FinanceAssetPersonalMotorVehicleInsurancesDocument = gql`
    query FinanceAssetPersonalMotorVehicleInsurances {
  assetPersonalMotorVehicleInsurances {
    id
    ...AssetPersonalMotorVehicleInsuranceAllFields
  }
}
    ${AssetPersonalMotorVehicleInsuranceAllFieldsFragmentDoc}`;

export function useFinanceAssetPersonalMotorVehicleInsurancesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetPersonalMotorVehicleInsurancesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetPersonalMotorVehicleInsurancesQuery,
    GQTypes.GQFinanceAssetPersonalMotorVehicleInsurancesQueryVariables
  >({ query: FinanceAssetPersonalMotorVehicleInsurancesDocument, ...options });
}
export const FinancePersonalMotorVehicleInsuranceAssetYearsDocument = gql`
    query FinancePersonalMotorVehicleInsuranceAssetYears {
  personalMotorVehicleInsuranceAssetYears {
    id
    ...AssetYearAllFields
    asset {
      id
      name
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}`;

export function useFinancePersonalMotorVehicleInsuranceAssetYearsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetYearsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetYearsQuery,
    GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetYearsQueryVariables
  >({
    query: FinancePersonalMotorVehicleInsuranceAssetYearsDocument,
    ...options,
  });
}
export const FinanceSovereignBondAssetYearsDocument = gql`
    query FinanceSovereignBondAssetYears {
  sovereignBondAssetYears {
    id
    ...AssetYearAllFields
    asset {
      id
      name
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}`;

export function useFinanceSovereignBondAssetYearsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceSovereignBondAssetYearsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceSovereignBondAssetYearsQuery,
    GQTypes.GQFinanceSovereignBondAssetYearsQueryVariables
  >({ query: FinanceSovereignBondAssetYearsDocument, ...options });
}
export const FinanceAssetYearsDocument = gql`
    query FinanceAssetYears {
  assetYears {
    id
    ...AssetYearAllFields
    asset {
      id
      name
    }
  }
}
    ${AssetYearAllFieldsFragmentDoc}`;

export function useFinanceAssetYearsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetYearsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetYearsQuery,
    GQTypes.GQFinanceAssetYearsQueryVariables
  >({ query: FinanceAssetYearsDocument, ...options });
}
export const FinanceAssetHoldingsDocument = gql`
    query FinanceAssetHoldings {
  assetHoldings {
    id
    ...AssetHoldingAllFields
    year
    asset {
      id
      name
    }
    fund {
      id
      name
    }
  }
}
    ${AssetHoldingAllFieldsFragmentDoc}`;

export function useFinanceAssetHoldingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAssetHoldingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAssetHoldingsQuery,
    GQTypes.GQFinanceAssetHoldingsQueryVariables
  >({ query: FinanceAssetHoldingsDocument, ...options });
}
export const FinancePersonalMotorVehicleInsuranceAssetHoldingsDocument = gql`
    query FinancePersonalMotorVehicleInsuranceAssetHoldings {
  personalMotorVehicleInsuranceAssetHoldings {
    id
    ...AssetHoldingAllFields
    year
    asset {
      id
      name
    }
    fund {
      id
      name
    }
  }
}
    ${AssetHoldingAllFieldsFragmentDoc}`;

export function useFinancePersonalMotorVehicleInsuranceAssetHoldingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetHoldingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetHoldingsQuery,
    GQTypes.GQFinancePersonalMotorVehicleInsuranceAssetHoldingsQueryVariables
  >({
    query: FinancePersonalMotorVehicleInsuranceAssetHoldingsDocument,
    ...options,
  });
}
export const FinanceContactsDocument = gql`
    query FinanceContacts {
  contacts {
    id
    ...SupplierContactAllFields
    company {
      id
      names {
        id
        name
      }
    }
  }
}
    ${SupplierContactAllFieldsFragmentDoc}`;

export function useFinanceContactsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceContactsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceContactsQuery,
    GQTypes.GQFinanceContactsQueryVariables
  >({ query: FinanceContactsDocument, ...options });
}
export const CancelWorkflowDashboardDocument = gql`
    mutation CancelWorkflowDashboard($input: CancelWorkflowInput!) {
  cancelWorkflow(input: $input) {
    success
  }
}
    `;

export function useCancelWorkflowDashboardMutation() {
  return Urql.useMutation<
    GQTypes.GQCancelWorkflowDashboardMutation,
    GQTypes.GQCancelWorkflowDashboardMutationVariables
  >(CancelWorkflowDashboardDocument);
}
export const GetAverageCurrencyExchangeRatesDocument = gql`
    query GetAverageCurrencyExchangeRates($baseCurrency: String!, $targetCurrency: String!, $intervals: [YMInterval!]!) {
  averageCurrencyExchangeRates(
    baseCurrency: $baseCurrency
    targetCurrency: $targetCurrency
    intervals: $intervals
  ) {
    id
    value
  }
}
    `;

export function useGetAverageCurrencyExchangeRatesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAverageCurrencyExchangeRatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAverageCurrencyExchangeRatesQuery,
    GQTypes.GQGetAverageCurrencyExchangeRatesQueryVariables
  >({ query: GetAverageCurrencyExchangeRatesDocument, ...options });
}
export const GetDrilldownFootprintVersionInfoDocument = gql`
    query GetDrilldownFootprintVersionInfo {
  footprintChangelog {
    id
    versions {
      edges {
        node {
          ...FootprintVersionInfoForStatus
        }
      }
    }
  }
}
    ${FootprintVersionInfoForStatusFragmentDoc}`;

export function useGetDrilldownFootprintVersionInfoQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDrilldownFootprintVersionInfoQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDrilldownFootprintVersionInfoQuery,
    GQTypes.GQGetDrilldownFootprintVersionInfoQueryVariables
  >({ query: GetDrilldownFootprintVersionInfoDocument, ...options });
}
export const GetFootprintVersionForSnapshotDocument = gql`
    query GetFootprintVersionForSnapshot($footprintSnapshotId: String!) {
  footprintAnalysis(footprintSnapshotId: $footprintSnapshotId) {
    id
    footprintVersion {
      ...FootprintVersionInfoForStatus
    }
  }
}
    ${FootprintVersionInfoForStatusFragmentDoc}`;

export function useGetFootprintVersionForSnapshotQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintVersionForSnapshotQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintVersionForSnapshotQuery,
    GQTypes.GQGetFootprintVersionForSnapshotQueryVariables
  >({ query: GetFootprintVersionForSnapshotDocument, ...options });
}
export const GetLatestCurrencyExchangeRateDocument = gql`
    query GetLatestCurrencyExchangeRate($targetCurrency: String!) {
  latestCurrencyExchangeRate(targetCurrency: $targetCurrency) {
    id
    updatedAt
    currency
    value
  }
}
    `;

export function useGetLatestCurrencyExchangeRateQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetLatestCurrencyExchangeRateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetLatestCurrencyExchangeRateQuery,
    GQTypes.GQGetLatestCurrencyExchangeRateQueryVariables
  >({ query: GetLatestCurrencyExchangeRateDocument, ...options });
}
export const ImportMarkUserUploadCompletedDocument = gql`
    mutation ImportMarkUserUploadCompleted($input: MarkUserUploadCompletedInput!) {
  markUserUploadCompleted(input: $input) {
    file {
      id
      remoteWritten
    }
    parseJobId
    userUpload {
      id
      name
      status
    }
  }
}
    `;

export function useImportMarkUserUploadCompletedMutation() {
  return Urql.useMutation<
    GQTypes.GQImportMarkUserUploadCompletedMutation,
    GQTypes.GQImportMarkUserUploadCompletedMutationVariables
  >(ImportMarkUserUploadCompletedDocument);
}
export const GetContainerAndQuestionsForHistoryDocument = gql`
    query GetContainerAndQuestionsForHistory($reportId: ID!, $id: ID!, $questionStrategy: Boolean!) {
  reportQuestion(id: $id) @include(if: $questionStrategy) {
    parentContainer {
      id
      items {
        ... on ReportQuestion {
          ...ReportQuestionForHistoryNodeFields
        }
      }
    }
  }
  reportQuestionContainer(id: $id) @skip(if: $questionStrategy) {
    id
    items {
      ... on ReportQuestion {
        ...ReportQuestionForHistoryNodeFields
      }
    }
  }
}
    ${ReportQuestionForHistoryNodeFieldsFragmentDoc}`;

export function useGetContainerAndQuestionsForHistoryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetContainerAndQuestionsForHistoryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetContainerAndQuestionsForHistoryQuery,
    GQTypes.GQGetContainerAndQuestionsForHistoryQueryVariables
  >({ query: GetContainerAndQuestionsForHistoryDocument, ...options });
}
export const TypeaheadCompanyDocument = gql`
    query TypeaheadCompany($search: String!, $allowMatchById: Boolean!, $searchDemo: Boolean!) {
  companyFuzzyMatch(
    q: $search
    allowMatchById: $allowMatchById
    searchDemo: $searchDemo
  ) {
    ...CompanyAutocompleteResultWithSource
  }
}
    ${CompanyAutocompleteResultWithSourceFragmentDoc}`;

export function useTypeaheadCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTypeaheadCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTypeaheadCompanyQuery,
    GQTypes.GQTypeaheadCompanyQueryVariables
  >({ query: TypeaheadCompanyDocument, ...options });
}
export const GetCompanyDocument = gql`
    query getCompany($id: ID) {
  companyForDashboard(id: $id) {
    ...CompanyAutocompleteResultWithSource
  }
}
    ${CompanyAutocompleteResultWithSourceFragmentDoc}`;

export function useGetCompanyQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQGetCompanyQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyQuery,
    GQTypes.GQGetCompanyQueryVariables
  >({ query: GetCompanyDocument, ...options });
}
export const RespondToDataIssueDocument = gql`
    mutation RespondToDataIssue($input: RespondToDataIssueInput!) {
  respondToDataIssue(input: $input) {
    measurementTask {
      ...MeasurementParentTaskForMeasurementPage
    }
    userUploadTask {
      id
      state
    }
    issue {
      ...DataIssueWithCommentsFields
    }
  }
}
    ${MeasurementParentTaskForMeasurementPageFragmentDoc}
${DataIssueWithCommentsFieldsFragmentDoc}`;

export function useRespondToDataIssueMutation() {
  return Urql.useMutation<
    GQTypes.GQRespondToDataIssueMutation,
    GQTypes.GQRespondToDataIssueMutationVariables
  >(RespondToDataIssueDocument);
}
export const ResolveDataIssueDocument = gql`
    mutation ResolveDataIssue($input: ResolveDataIssueInput!) {
  resolveDataIssue(input: $input) {
    issue {
      ...DataIssueWithCommentsFields
    }
  }
}
    ${DataIssueWithCommentsFieldsFragmentDoc}`;

export function useResolveDataIssueMutation() {
  return Urql.useMutation<
    GQTypes.GQResolveDataIssueMutation,
    GQTypes.GQResolveDataIssueMutationVariables
  >(ResolveDataIssueDocument);
}
export const GiveFeedbackDocument = gql`
    mutation GiveFeedback($input: GiveFeedbackInput!) {
  giveFeedback(input: $input) {
    status
  }
}
    `;

export function useGiveFeedbackMutation() {
  return Urql.useMutation<
    GQTypes.GQGiveFeedbackMutation,
    GQTypes.GQGiveFeedbackMutationVariables
  >(GiveFeedbackDocument);
}
export const RunCtsTransformEditorDocument = gql`
    mutation RunCtsTransformEditor($input: RunCtsTransformEditorDashboardInput!) {
  runCtsTransformEditor(input: $input) {
    workflowId
  }
}
    `;

export function useRunCtsTransformEditorMutation() {
  return Urql.useMutation<
    GQTypes.GQRunCtsTransformEditorMutation,
    GQTypes.GQRunCtsTransformEditorMutationVariables
  >(RunCtsTransformEditorDocument);
}
export const CreateDemoOrgDocument = gql`
    mutation CreateDemoOrg($input: CreateDemoOrgInput!) {
  createDemoOrg(input: $input) {
    backgroundJobId
  }
}
    `;

export function useCreateDemoOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDemoOrgMutation,
    GQTypes.GQCreateDemoOrgMutationVariables
  >(CreateDemoOrgDocument);
}
export const LatestDocumentParseAttemptForReviewDocument = gql`
    query LatestDocumentParseAttemptForReview($fileId: ID!, $modelType: DocumentParseAttemptModelType!) {
  latestDocumentParseAttempt(fileId: $fileId, modelType: $modelType) {
    ...ReviewDocumentParseAttempt
  }
}
    ${ReviewDocumentParseAttemptFragmentDoc}`;

export function useLatestDocumentParseAttemptForReviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestDocumentParseAttemptForReviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestDocumentParseAttemptForReviewQuery,
    GQTypes.GQLatestDocumentParseAttemptForReviewQueryVariables
  >({ query: LatestDocumentParseAttemptForReviewDocument, ...options });
}
export const ReviewUserUploadPreviewUrlDocument = gql`
    query ReviewUserUploadPreviewUrl($userUploadId: ID!) {
  userUpload(id: $userUploadId) {
    userVisibleAttempt {
      previewUrl
    }
  }
}
    `;

export function useReviewUserUploadPreviewUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReviewUserUploadPreviewUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReviewUserUploadPreviewUrlQuery,
    GQTypes.GQReviewUserUploadPreviewUrlQueryVariables
  >({ query: ReviewUserUploadPreviewUrlDocument, ...options });
}
export const UpsertDocumentParseAttemptReviewDocument = gql`
    mutation UpsertDocumentParseAttemptReview($input: UpsertDocumentParseAttemptInput!) {
  upsertDocumentParseAttempt(input: $input) {
    ...ReviewDocumentParseAttempt
  }
}
    ${ReviewDocumentParseAttemptFragmentDoc}`;

export function useUpsertDocumentParseAttemptReviewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertDocumentParseAttemptReviewMutation,
    GQTypes.GQUpsertDocumentParseAttemptReviewMutationVariables
  >(UpsertDocumentParseAttemptReviewDocument);
}
export const CreateOrgRoleDocument = gql`
    mutation CreateOrgRole($input: CreateOrgRoleInput!) {
  createOrgRole(input: $input) {
    role {
      ...TeamsTableRole
    }
  }
}
    ${TeamsTableRoleFragmentDoc}`;

export function useCreateOrgRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrgRoleMutation,
    GQTypes.GQCreateOrgRoleMutationVariables
  >(CreateOrgRoleDocument);
}
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    role {
      ...TeamsTableRole
    }
  }
}
    ${TeamsTableRoleFragmentDoc}`;

export function useCreateRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateRoleMutation,
    GQTypes.GQCreateRoleMutationVariables
  >(CreateRoleDocument);
}
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    role {
      ...TeamsTableRole
    }
  }
}
    ${TeamsTableRoleFragmentDoc}`;

export function useUpdateRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateRoleMutation,
    GQTypes.GQUpdateRoleMutationVariables
  >(UpdateRoleDocument);
}
export const OrgDatasetsDocument = gql`
    query OrgDatasets {
  organization {
    id
    datasets {
      id
      name
    }
  }
}
    `;

export function useOrgDatasetsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgDatasetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgDatasetsQuery,
    GQTypes.GQOrgDatasetsQueryVariables
  >({ query: OrgDatasetsDocument, ...options });
}
export const OrgDatasourcesDocument = gql`
    query OrgDatasources {
  organization {
    id
    datasets {
      id
      name
      datasources {
        id
        name
      }
    }
  }
}
    `;

export function useOrgDatasourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgDatasourcesQuery,
    GQTypes.GQOrgDatasourcesQueryVariables
  >({ query: OrgDatasourcesDocument, ...options });
}
export const GetPermissionsForRoleDocument = gql`
    query GetPermissionsForRole($roleId: ID!) {
  role(id: $roleId) {
    id
    ...TeamsTableRole
  }
}
    ${TeamsTableRoleFragmentDoc}`;

export function useGetPermissionsForRoleQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPermissionsForRoleQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPermissionsForRoleQuery,
    GQTypes.GQGetPermissionsForRoleQueryVariables
  >({ query: GetPermissionsForRoleDocument, ...options });
}
export const OrgUsersDocument = gql`
    query OrgUsers($orgId: ID!) {
  organization {
    id
    users {
      edges {
        node {
          id
          displayName
          name
          orgAccessId(orgId: $orgId)
        }
      }
    }
  }
}
    `;

export function useOrgUsersQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQOrgUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersQuery,
    GQTypes.GQOrgUsersQueryVariables
  >({ query: OrgUsersDocument, ...options });
}
export const DeleteRoleDocument = gql`
    mutation DeleteRole($roleId: ID!) {
  deleteRole(input: {roleId: $roleId}) {
    orgId
    roleId
  }
}
    `;

export function useDeleteRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteRoleMutation,
    GQTypes.GQDeleteRoleMutationVariables
  >(DeleteRoleDocument);
}
export const MyRolesDocument = gql`
    query MyRoles {
  roles {
    edges {
      node {
        ...RoleInfo
      }
    }
  }
}
    ${RoleInfoFragmentDoc}`;

export function useMyRolesQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQMyRolesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GQTypes.GQMyRolesQuery, GQTypes.GQMyRolesQueryVariables>(
    { query: MyRolesDocument, ...options }
  );
}
export const GetPermissionsForUserDocument = gql`
    query GetPermissionsForUser($userId: ID!, $orgId: ID!) {
  user(id: $userId) {
    id
    ...UserTablePermissions
  }
}
    ${UserTablePermissionsFragmentDoc}`;

export function useGetPermissionsForUserQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPermissionsForUserQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPermissionsForUserQuery,
    GQTypes.GQGetPermissionsForUserQueryVariables
  >({ query: GetPermissionsForUserDocument, ...options });
}
export const RevokePermissionDocument = gql`
    mutation RevokePermission($permissionId: ID!) {
  revokePermissionItem(input: {permissionItemId: $permissionId}) {
    permissionItem {
      id
      revokedAt
    }
  }
}
    `;

export function useRevokePermissionMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokePermissionMutation,
    GQTypes.GQRevokePermissionMutationVariables
  >(RevokePermissionDocument);
}
export const RevokeRoleForUserDocument = gql`
    mutation RevokeRoleForUser($roleId: ID!, $userId: ID!, $orgId: ID) {
  revokeUserRole(input: {roleId: $roleId, userId: $userId, orgId: $orgId}) {
    userRoleAssignment {
      id
      revokedAt
    }
  }
}
    `;

export function useRevokeRoleForUserMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokeRoleForUserMutation,
    GQTypes.GQRevokeRoleForUserMutationVariables
  >(RevokeRoleForUserDocument);
}
export const AddPermissionDocument = gql`
    mutation AddPermission($input: CreatePermissionItemInput!) {
  createPermissionItem(input: $input) {
    permissionItem {
      id
      user {
        id
      }
      revokedAt
    }
  }
}
    `;

export function useAddPermissionMutation() {
  return Urql.useMutation<
    GQTypes.GQAddPermissionMutation,
    GQTypes.GQAddPermissionMutationVariables
  >(AddPermissionDocument);
}
export const AddUserRoleDocument = gql`
    mutation AddUserRole($input: GrantUserRoleInput!) {
  grantUserRole(input: $input) {
    userRoleAssignment {
      id
    }
  }
}
    `;

export function useAddUserRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQAddUserRoleMutation,
    GQTypes.GQAddUserRoleMutationVariables
  >(AddUserRoleDocument);
}
export const AssignUserUploadTaskDocument = gql`
    mutation AssignUserUploadTask($input: AssignUserUploadTaskInput!) {
  assignUserUploadTask(input: $input) {
    userUploadTask {
      ...UserUploadTaskFields
    }
  }
}
    ${UserUploadTaskFieldsFragmentDoc}`;

export function useAssignUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQAssignUserUploadTaskMutation,
    GQTypes.GQAssignUserUploadTaskMutationVariables
  >(AssignUserUploadTaskDocument);
}
export const GetAllAssignableUsersDocument = gql`
    query GetAllAssignableUsers {
  organization {
    id
    users {
      edges {
        node {
          ...AssignableUserFields
        }
      }
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useGetAllAssignableUsersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllAssignableUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllAssignableUsersQuery,
    GQTypes.GQGetAllAssignableUsersQueryVariables
  >({ query: GetAllAssignableUsersDocument, ...options });
}
export const PeerReportsDocument = gql`
    query PeerReports($reportType: ExternalReportType, $limit: Int) {
  publicDisclosures(mustHaveURL: true, limit: $limit, reportType: $reportType) {
    ...PublicDisclosureDisplay
  }
  organization {
    id
    company {
      id
    }
  }
}
    ${PublicDisclosureDisplayFragmentDoc}`;

export function usePeerReportsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQPeerReportsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPeerReportsQuery,
    GQTypes.GQPeerReportsQueryVariables
  >({ query: PeerReportsDocument, ...options });
}
export const ReportAttachmentItemsDocument = gql`
    query ReportAttachmentItems($kinds: [ReportAttachmentItemKind!]!) {
  reportAttachmentItems(kinds: $kinds) {
    ...ReportFileAttachmentItemFields
  }
}
    ${ReportFileAttachmentItemFieldsFragmentDoc}`;

export function useReportAttachmentItemsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportAttachmentItemsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportAttachmentItemsQuery,
    GQTypes.GQReportAttachmentItemsQueryVariables
  >({ query: ReportAttachmentItemsDocument, ...options });
}
export const CreateReportUrlAttachmentItemDocument = gql`
    mutation CreateReportURLAttachmentItem($input: CreateReportURLAttachmentItemInput!) {
  createReportURLAttachmentItem(input: $input) {
    url
    attachment {
      ...ReportFileAttachmentItemFields
    }
  }
}
    ${ReportFileAttachmentItemFieldsFragmentDoc}`;

export function useCreateReportUrlAttachmentItemMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportUrlAttachmentItemMutation,
    GQTypes.GQCreateReportUrlAttachmentItemMutationVariables
  >(CreateReportUrlAttachmentItemDocument);
}
export const CreateReportFileAttachmentsDocument = gql`
    mutation CreateReportFileAttachments($input: CreateReportFileAttachmentsInput!) {
  createReportFileAttachments(input: $input) {
    items {
      url
      attachment {
        ...ReportFileAttachmentItemFields
      }
    }
  }
}
    ${ReportFileAttachmentItemFieldsFragmentDoc}`;

export function useCreateReportFileAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportFileAttachmentsMutation,
    GQTypes.GQCreateReportFileAttachmentsMutationVariables
  >(CreateReportFileAttachmentsDocument);
}
export const CreateReportAttachmentsDocument = gql`
    mutation CreateReportAttachments($input: CreateReportAttachmentsInput!) {
  createReportAttachments(input: $input) {
    attachmentItems {
      ...ReportAttachmentForList
    }
  }
}
    ${ReportAttachmentForListFragmentDoc}`;

export function useCreateReportAttachmentsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportAttachmentsMutation,
    GQTypes.GQCreateReportAttachmentsMutationVariables
  >(CreateReportAttachmentsDocument);
}
export const DeleteReportAttachmentItemDocument = gql`
    mutation DeleteReportAttachmentItem($input: DeleteReportAttachmentItemInput!) {
  deleteReportAttachmentItem(input: $input) {
    success
  }
}
    `;

export function useDeleteReportAttachmentItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportAttachmentItemMutation,
    GQTypes.GQDeleteReportAttachmentItemMutationVariables
  >(DeleteReportAttachmentItemDocument);
}
export const GetReportAttachmentItemsForQuestionDocument = gql`
    query GetReportAttachmentItemsForQuestion($reportId: ID!, $reportQuestionId: ID!) {
  reportAttachmentItemsForQuestion(
    reportId: $reportId
    reportQuestionId: $reportQuestionId
  ) {
    ...ReportAttachmentForList
  }
}
    ${ReportAttachmentForListFragmentDoc}`;

export function useGetReportAttachmentItemsForQuestionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportAttachmentItemsForQuestionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportAttachmentItemsForQuestionQuery,
    GQTypes.GQGetReportAttachmentItemsForQuestionQueryVariables
  >({ query: GetReportAttachmentItemsForQuestionDocument, ...options });
}
export const DeleteReportAttachmentDocument = gql`
    mutation DeleteReportAttachment($input: DeleteReportAttachmentInput!) {
  deleteReportAttachment(input: $input) {
    success
  }
}
    `;

export function useDeleteReportAttachmentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportAttachmentMutation,
    GQTypes.GQDeleteReportAttachmentMutationVariables
  >(DeleteReportAttachmentDocument);
}
export const CreateSupplierContactDocument = gql`
    mutation CreateSupplierContact($input: CreateSupplierContactInput!, $supplierId: String!) {
  createSupplierContact(input: $input, supplierId: $supplierId) {
    contact {
      ...SupplierContactFields
      company {
        id
        contacts {
          id
        }
      }
    }
  }
}
    ${SupplierContactFieldsFragmentDoc}`;

export function useCreateSupplierContactMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierContactMutation,
    GQTypes.GQCreateSupplierContactMutationVariables
  >(CreateSupplierContactDocument);
}
export const UpdateSupplierContactDocument = gql`
    mutation UpdateSupplierContact($input: UpdateSupplierContactInput!, $supplierId: String!) {
  updateSupplierContact(input: $input, supplierId: $supplierId) {
    contact {
      ...SupplierContactFields
    }
  }
}
    ${SupplierContactFieldsFragmentDoc}`;

export function useUpdateSupplierContactMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierContactMutation,
    GQTypes.GQUpdateSupplierContactMutationVariables
  >(UpdateSupplierContactDocument);
}
export const DeleteSupplierContactDocument = gql`
    mutation DeleteSupplierContact($input: DeleteSupplierContactInput!, $supplierId: String!) {
  deleteSupplierContact(input: $input, supplierId: $supplierId) {
    numberDeleted
    id
    company {
      id
      contacts {
        id
      }
    }
  }
}
    `;

export function useDeleteSupplierContactMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierContactMutation,
    GQTypes.GQDeleteSupplierContactMutationVariables
  >(DeleteSupplierContactDocument);
}
export const GetEngagementTaskConfigsForTaskCreationDocument = gql`
    query GetEngagementTaskConfigsForTaskCreation($includeGlobalConfigs: Boolean!, $orgId: ID!) {
  engagementTaskConfigs(
    includeGlobalConfigs: $includeGlobalConfigs
    orgId: $orgId
  ) {
    ...EngagementTaskConfigForTaskCreation
  }
}
    ${EngagementTaskConfigForTaskCreationFragmentDoc}`;

export function useGetEngagementTaskConfigsForTaskCreationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskConfigsForTaskCreationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskConfigsForTaskCreationQuery,
    GQTypes.GQGetEngagementTaskConfigsForTaskCreationQueryVariables
  >({ query: GetEngagementTaskConfigsForTaskCreationDocument, ...options });
}
export const BusinessActivityTypeVersionNamesDocument = gql`
    query BusinessActivityTypeVersionNames {
  businessActivityTypeVersions {
    id
    name
  }
}
    `;

export function useBusinessActivityTypeVersionNamesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeVersionNamesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeVersionNamesQuery,
    GQTypes.GQBusinessActivityTypeVersionNamesQueryVariables
  >({ query: BusinessActivityTypeVersionNamesDocument, ...options });
}
export const BusinessActivityTypeVersionByIdDocument = gql`
    query BusinessActivityTypeVersionById($id: ID!) {
  businessActivityTypeVersionById(id: $id) {
    id
    name
    businessActivityTypes {
      ...AllBusinessActivityTypeFields
    }
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypeVersionByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeVersionByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeVersionByIdQuery,
    GQTypes.GQBusinessActivityTypeVersionByIdQueryVariables
  >({ query: BusinessActivityTypeVersionByIdDocument, ...options });
}
export const VersionedBusinessActivityTypesDocument = gql`
    query VersionedBusinessActivityTypes($input: [VersionedBusinessActivityTypeInput!]!) {
  versionedBusinessActivityTypes(input: $input) {
    version {
      id
      name
    }
    ...AllBusinessActivityTypeFields
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useVersionedBusinessActivityTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQVersionedBusinessActivityTypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQVersionedBusinessActivityTypesQuery,
    GQTypes.GQVersionedBusinessActivityTypesQueryVariables
  >({ query: VersionedBusinessActivityTypesDocument, ...options });
}
export const LiveBusinessActivityTypeVersionDocument = gql`
    query LiveBusinessActivityTypeVersion {
  businessActivityTypeVersionLive {
    id
    name
    businessActivityTypes {
      ...AllBusinessActivityTypeFields
    }
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useLiveBusinessActivityTypeVersionQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLiveBusinessActivityTypeVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLiveBusinessActivityTypeVersionQuery,
    GQTypes.GQLiveBusinessActivityTypeVersionQueryVariables
  >({ query: LiveBusinessActivityTypeVersionDocument, ...options });
}
export const LiveBusinessActivityTypeVersionRecordDocument = gql`
    query LiveBusinessActivityTypeVersionRecord {
  businessActivityTypeVersionLive {
    id
    name
  }
}
    `;

export function useLiveBusinessActivityTypeVersionRecordQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLiveBusinessActivityTypeVersionRecordQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLiveBusinessActivityTypeVersionRecordQuery,
    GQTypes.GQLiveBusinessActivityTypeVersionRecordQueryVariables
  >({ query: LiveBusinessActivityTypeVersionRecordDocument, ...options });
}
export const GetEngagementTaskDocument = gql`
    query GetEngagementTask($engagementTaskId: ID!) {
  engagementTask(engagementTaskId: $engagementTaskId) {
    ...EngagementTaskContents
  }
}
    ${EngagementTaskContentsFragmentDoc}`;

export function useGetEngagementTaskQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskQuery,
    GQTypes.GQGetEngagementTaskQueryVariables
  >({ query: GetEngagementTaskDocument, ...options });
}
export const UpdateEngagementTaskDocument = gql`
    mutation updateEngagementTask($input: UpdateEngagementTaskInput!, $supplierId: ID!) {
  updateEngagementTask(input: $input, supplierId: $supplierId) {
    engagementTask {
      ...EngagementTaskFields
      ...EngagementTaskContents
    }
    runCeeBackgroundJobId
  }
}
    ${EngagementTaskFieldsFragmentDoc}
${EngagementTaskContentsFragmentDoc}`;

export function useUpdateEngagementTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEngagementTaskMutation,
    GQTypes.GQUpdateEngagementTaskMutationVariables
  >(UpdateEngagementTaskDocument);
}
export const AddEngagementTaskCommentDocument = gql`
    mutation AddEngagementTaskComment($input: AddEngagementTaskCommentInput!, $engagementTaskId: ID!) {
  addEngagementTaskComment(input: $input, engagementTaskId: $engagementTaskId) {
    comment {
      id
      createdAt
      userEditorId
      comment
    }
  }
}
    `;

export function useAddEngagementTaskCommentMutation() {
  return Urql.useMutation<
    GQTypes.GQAddEngagementTaskCommentMutation,
    GQTypes.GQAddEngagementTaskCommentMutationVariables
  >(AddEngagementTaskCommentDocument);
}
export const AllOrgUsersForPermissionDocument = gql`
    query AllOrgUsersForPermission($orgId: ID!, $permissions: [HasPermissionInput!]!) {
  organization(id: $orgId) {
    id
    users {
      edges {
        node {
          ...UserForAllOrgUsersForPermission
        }
      }
    }
  }
}
    ${UserForAllOrgUsersForPermissionFragmentDoc}`;

export function useAllOrgUsersForPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllOrgUsersForPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllOrgUsersForPermissionQuery,
    GQTypes.GQAllOrgUsersForPermissionQueryVariables
  >({ query: AllOrgUsersForPermissionDocument, ...options });
}
export const OrgUsersWithPermissionsDocument = gql`
    query OrgUsersWithPermissions($orgId: ID!, $permissionInfos: [HasPermissionInput!]!) {
  organization(id: $orgId) {
    id
    users(filters: {withSomePermissions: $permissionInfos}) {
      edges {
        node {
          ...UserForOrgUsersWithPermissions
        }
      }
    }
  }
}
    ${UserForOrgUsersWithPermissionsFragmentDoc}`;

export function useOrgUsersWithPermissionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUsersWithPermissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersWithPermissionsQuery,
    GQTypes.GQOrgUsersWithPermissionsQueryVariables
  >({ query: OrgUsersWithPermissionsDocument, ...options });
}
export const GetJobResultsDocument = gql`
    query GetJobResults($id: ID!) {
  job: backgroundJob(id: $id) {
    id
    kind
    state
    args
    result
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useGetJobResultsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetJobResultsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetJobResultsQuery,
    GQTypes.GQGetJobResultsQueryVariables
  >({ query: GetJobResultsDocument, ...options });
}
export const GetJobStatusDocument = gql`
    query GetJobStatus($id: ID!) {
  job: backgroundJobStatus(id: $id) {
    id
    orgId
    state
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useGetJobStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetJobStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetJobStatusQuery,
    GQTypes.GQGetJobStatusQueryVariables
  >({ query: GetJobStatusDocument, ...options });
}
export const ListJobsStatusDocument = gql`
    query ListJobsStatus($ids: [ID!]!) {
  backgroundJobStatuses(ids: $ids) {
    id
    orgId
    state
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useListJobsStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQListJobsStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQListJobsStatusQuery,
    GQTypes.GQListJobsStatusQueryVariables
  >({ query: ListJobsStatusDocument, ...options });
}
export const CancelWorkflowDocument = gql`
    mutation CancelWorkflow($input: CancelWorkflowInput!) {
  cancelWorkflow(input: $input) {
    success
  }
}
    `;

export function useCancelWorkflowMutation() {
  return Urql.useMutation<
    GQTypes.GQCancelWorkflowMutation,
    GQTypes.GQCancelWorkflowMutationVariables
  >(CancelWorkflowDocument);
}
export const GetAllReportConfigsDocument = gql`
    query GetAllReportConfigs($filterHidden: Boolean) {
  allReportConfigs(filterHidden: $filterHidden) {
    ...OverviewReportConfigFields
  }
}
    ${OverviewReportConfigFieldsFragmentDoc}`;

export function useGetAllReportConfigsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllReportConfigsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllReportConfigsQuery,
    GQTypes.GQGetAllReportConfigsQueryVariables
  >({ query: GetAllReportConfigsDocument, ...options });
}
