// This module defines metadata that is **version-agnostic**: the metadata are
// associated with *concepts* whose semantics should not change across versions.
//
// Why and how does this work? The BAT concept is identified by BAT name; the
// BAT field concept by BAT name plus field name. It is a key constraint of BAT
// schema management that those names must point to a semantically consistent
// concept (that is, consistent across versions): from one version to the next,
// we must never change the underlying *conceptual meaning* (to a human) of the
// BAT schema with a particular name, or the schema field with a particular
// name. (When we have a new concept to express, such that a "description" would
// no longer be consistent across versions, we express it with a new BAT or
// field, not by adjusting an existing one.)
//
// TODO: **Why do we have `quantity` fields below for BATs that don't really
// have a `quantity` field?** (Like, logistics.) The problem we have is that, as
// of 2023-08-04, the BART-EM matching process during evaluation renames any
// BART columns used as the EM's functional unit to `quantity`. For example, the
// logistics EM uses `weight_quantity` as the functional unit; during
// evaluation, that gets renamed to `quantity`. Then downstream, when we're
// trying to populate TraceDetails with metadata, we only know that the column
// was named `quantity` -- we don't know that it was *originally*
// `weight_quantity`. Obviously, we need to fix this situation: it is not only
// confusing, but also only works because of assumptions that we do not want to
// make about EMs (e.g. that every EM that uses a BAT will always use the same
// column as the functional unit).

import { BadDataError } from '../errors/BadDataError';
import { msg } from '@lingui/core/macro';
import {
  assertNoTypeDifferences,
  noTypeDifferences,
} from '../utils/assertNoTypeDifferences';
import { captureException } from '../utils/sentry';
import { i18n } from '@watershed/intl';

// Only a type import for assertions
// eslint-disable-next-line no-restricted-imports
import type { CurrentPrimaryTypeNames } from './currentBatVersion';
import { I18n, MessageDescriptor } from '@lingui/core';

// This should never be displayed to users itself!
export const GLOBAL_KEY = '__GLOBAL__';

// -----> WRITE YOUR METADATA DEFINITIONS HERE! <-----
const employeeMonthQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Employee-months',
    context:
      'The product of an employee and the number of months the employee worked.',
  }),
  externalDescription: msg({
    message:
      'The product of an employee and the number of months the employee worked.',
    context: 'Employee-months',
  }),
};

const buildingAreaMonthQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Area times months',
    context:
      'The area of the facility multiplied by the number of months it was active.',
  }),
  externalDescription: msg({
    message:
      'The area of the facility multiplied by the number of months it was active.',
    context: 'Area times months',
  }),
};

const cloudSpendCurrencyQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Cloud spend',
    context: 'The spend on cloud services.',
  }),
  externalDescription: msg({
    message: 'The spend on cloud services.',
    context: 'Cloud spend',
  }),
};

const kgco2eRemoved: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'kgCO₂e removed',
    context: 'The quantity of greenhouse gases removed.',
  }),
  externalDescription: msg({
    message: 'The quantity of greenhouse gases removed.',
    context: 'kgCO₂e removed',
  }),
};

const standardDistanceQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Distance',
    context: 'The distance traveled.',
  }),
  externalDescription: msg({
    message: 'The distance traveled.',
    context: 'Distance',
  }),
};

const transportedWeightQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Transported weight',
    context: 'The weight of goods transported.',
  }),
  externalDescription: msg({
    message: 'The weight of goods transported.',
    context: 'Transported weight',
  }),
};

const currencyQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Currency quantity',
    context: 'The expenditure amount.',
  }),
  externalDescription: msg({
    message: 'The expenditure amount.',
    context: 'Currency quantity',
  }),
};

const currencyQuantityUsd: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Currency quantity in USD',
    context: 'The expenditure amount in USD.',
  }),
  externalDescription: msg({
    message: 'The expenditure amount in USD.',
    context: 'Currency quantity in USD',
  }),
};

const vehiclesSold: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Vehicle quantity',
    context: 'The number of vehicles sold.',
  }),
  externalDescription: msg({
    message: 'The number of vehicles sold.',
    context: 'Vehicle quantity',
  }),
};

const buildingKind: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Facility kind',
    context: 'The type of facility.',
  }),
  externalDescription: msg({
    message: 'The type of facility.',
    context: 'Facility kind',
  }),
};

const buildingLeaseType: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Facility lease type',
    context: 'The lease type of the facility.',
  }),
  externalDescription: msg({
    message: 'The lease type of the facility.',
    context: 'Facility lease type',
  }),
};

const cloudVmInstanceType: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Cloud virtual machine instance type',
    context: 'The virtual machine instance type for the cloud usage.',
  }),
  externalDescription: msg({
    message: 'The virtual machine instance type for the cloud usage.',
    context: 'Cloud virtual machine instance type',
  }),
};

const cloudRegion: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Cloud region',
    context: `The identifier of the cloud provider's region. Examples: 'us-east-1' (AWS), 'asia-northeast2-a' (GCP).`,
  }),
  externalDescription: msg({
    message: `The identifier of the cloud provider's region. Examples: 'us-east-1' (AWS), 'asia-northeast2-a' (GCP).`,
    context: 'Cloud region',
  }),
};

const cloudService: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Cloud service',
    context: `An identifier for the cloud service used. Examples: AWS Lambda, Google Storage.`,
  }),
  externalDescription: msg({
    message: `An identifier for the cloud service used. Examples: AWS Lambda, Google Storage.`,
    context: 'Cloud service',
  }),
};

const currency: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Currency',
    context: 'The currency of the expenditure.',
  }),
  externalDescription: msg({
    message: 'The currency of the expenditure.',
    context: 'Currency',
  }),
};

const distanceMethod: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Distance method',
    context: 'The method by which the distance was calculated.',
  }),
  externalDescription: msg({
    message: 'The method by which the distance was calculated.',
    context: 'Distance method',
  }),
};

const excludeFirstMile: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Exclude first mile',
    context: `Excluded when the distance quantity given includes the 'first mile' the weight of goods are transported.`,
  }),
  externalDescription: msg({
    message: `Excluded when the distance quantity given includes the 'first mile' the weight of goods are transported.`,
    context: 'Exclude first mile',
  }),
};

const excludeLastMile: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Exclude last mile',
    context: `Excluded when the distance quantity includes the 'last mile' the weight of goods are transported.`,
  }),
  externalDescription: msg({
    message: `Excluded when the distance quantity includes the 'last mile' the weight of goods are transported.`,
    context: 'Exclude last mile',
  }),
};

const gasName: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Gas name',
    context: 'The greenhouse gas name.',
  }),
  externalDescription: msg({
    message: 'The greenhouse gas name.',
    context: 'Gas name',
  }),
};

const hasMatchingCloudComputeUsage: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Has matching cloud compute usage',
    context:
      'Indicator of whether the expenditure on cloud has matching usage.',
  }),
  externalDescription: msg({
    message:
      'Indicator of whether the expenditure on cloud has matching usage.',
    context: 'Has matching cloud compute usage',
  }),
};

const isBuildingEmpty: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Is building empty',
    context:
      'Indicator of whether the facility is empty during the measurement period.',
  }),
  externalDescription: msg({
    message:
      'Indicator of whether the facility is empty during the measurement period.',
    context: 'Is building empty',
  }),
};

const isBuildingWithoutNaturalGas: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'No natural gas usage',
    context: 'Indicator of whether the facility does not use natural gas.',
  }),
  externalDescription: msg({
    message: 'Indicator of whether the facility does not use natural gas.',
    context: 'No natural gas usage',
  }),
};

const isBuildingWithoutRefrigerants: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'No refrigerant usage',
    context: 'Indicator of whether the facility does not use refrigerants.',
  }),
  externalDescription: msg({
    message: 'Indicator of whether the facility does not use refrigerants.',
    context: 'No refrigerant usage',
  }),
};

const isEnergyStar: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Energy Star certified',
    context: 'Indicator of whether the good sold is an Energy Star product.',
  }),
  externalDescription: msg({
    message: 'Indicator of whether the good sold is an Energy Star product.',
    context: 'Energy Star certified',
  }),
};

const locationCity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'City',
    context: 'The city in which the business activity took place.',
  }),
  externalDescription: msg({
    message: 'The city in which the business activity took place.',
    context: 'City',
  }),
};

const locationCountry: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Country',
    context: 'The country in which the business activity took place.',
  }),
  externalDescription: msg({
    message: 'The country in which the business activity took place.',
    context: 'Country',
  }),
};

const month: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Month',
    context: 'The month the business activity took place.',
  }),
  externalDescription: msg({
    message: 'The month the business activity took place.',
    context: 'Month',
  }),
};

const transportationMode: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Transportation mode',
    context: 'The mode of transportation used.',
  }),
  externalDescription: msg({
    message: 'The mode of transportation used.',
    context: 'Transportation mode',
  }),
};

const privatePlaneModel: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Plane model',
    context: 'The private plane model',
  }),
  externalDescription: msg({
    message: 'The private plane model',
    context: 'Plane model',
  }),
};

const distanceUnit: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Distance unit',
    context: 'The unit of distance traveled.',
  }),
  externalDescription: msg({
    message: 'The unit of distance traveled.',
    context: 'Distance unit',
  }),
};

const waterRecyclingQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Water recycled',
    context: 'The quantity of water recycled.',
  }),
  externalDescription: msg({
    message: 'The quantity of water recycled.',
    context: 'Water recycled',
  }),
};

const waterStorageQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Water stored',
    context: 'The quantity of water stored.',
  }),
  externalDescription: msg({
    message: 'The quantity of water stored.',
    context: 'Water stored',
  }),
};

const waterDischargeQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Water discharged',
    context: 'The quantity of water discharged.',
  }),
  externalDescription: msg({
    message: 'The quantity of water discharged.',
    context: 'Water discharged',
  }),
};

const waterUsageQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Water used',
    context: 'The quantity of water used.',
  }),
  externalDescription: msg({
    message: 'The quantity of water used.',
    context: 'Water used',
  }),
};

const waterConsumptionQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Water consumed',
    context: 'The quantity of water consumed.',
  }),
  externalDescription: msg({
    message: 'The quantity of water consumed.',
    context: 'Water consumed',
  }),
};

const siteLandUseQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Site area',
    context: 'The area of the site.',
  }),
  externalDescription: msg({
    message: 'The area of the site.',
    context: 'Site area',
  }),
};

const materialWeight: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Material weight',
    context: 'The weight of the material',
  }),
  externalDescription: msg({
    message: 'The weight of the material',
    context: 'Material weight',
  }),
};

const productWeightQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Product weight',
    context: 'The weight of the product',
  }),
  externalDescription: msg({
    message: 'The weight of the product',
    context: 'Product weight',
  }),
};

const heatSource: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Heat source',
    context: 'The source of heat for the facility.',
  }),
  externalDescription: msg({
    message: 'The source of heat for the facility.',
    context: 'Heat source',
  }),
};

const ceeBuildingAreaQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Area',
    context: 'The area of the facility.',
  }),
  externalDescription: msg({
    message: 'The area of the facility.',
    context: 'Area',
  }),
};

const ceePercentRenewable: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Percent renewable',
    context:
      'The percentage of electricity consumption covered by clean power purchased (e.g., through EACs or a VPPA) that is not from the grid.',
  }),
  externalDescription: msg({
    message:
      'The percentage of electricity consumption covered by clean power purchased (e.g., through EACs or a VPPA) that is not from the grid.',
    context: 'Percent renewable',
  }),
};

const ceePercentOnsiteCleanPower: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Percent onsite clean power',
    context:
      'The percentage of electricity generated from renewable sources onsite.',
  }),
  externalDescription: msg({
    message:
      'The percentage of electricity generated from renewable sources onsite.',
    context: 'Percent onsite clean power',
  }),
};

const ceeEmployeeQuantity: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Employee quantity',
    context: 'The number of employees.',
  }),
  externalDescription: msg({
    message: 'The number of employees.',
    context: 'Employee quantity',
  }),
};

const ceeRemoteTimePercent: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Remote percentage',
    context: 'The percentage of time employees work remotely.',
  }),
  externalDescription: msg({
    message: 'The percentage of time employees work remotely.',
    context: 'Remote percentage',
  }),
};

const ceePrecalculatedEmissions: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Pre-calculated emissions',
    context: 'The pre-calculated kgCO2e.',
  }),
  externalDescription: msg({
    message: 'The pre-calculated emissions.',
    context: 'Pre-calculated kgCO2e.',
  }),
};

const ceeElectricityUsage: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Electricity usage',
    context: 'The electricity usage.',
  }),
  externalDescription: msg({
    message: 'The electricity usage.',
    context: 'Electricity usage',
  }),
};

const eeioModelKind: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'EEIO model kind',
    context: 'The kind of EEIO model.',
  }),
  externalDescription: msg({
    message: 'The kind of EEIO model.',
    context: 'EEIO model kind',
  }),
};

const cloudProvider: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Cloud provider',
    context: 'The cloud provider.',
  }),
  externalDescription: msg({
    message: 'The cloud provider.',
    context: 'Cloud provider',
  }),
};

const naicsCode: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Industry code',
    context: 'The industry code.',
  }),
  externalDescription: msg({
    message: 'The industry code.',
    context: 'Industry code',
  }),
};

const inflationAdjustmentNaicsCode: FieldMetadataMessageDescriptorDef = {
  displayName: msg({
    message: 'Inflation adjustment industry code',
    context: 'The industry code used for inflation adjustment.',
  }),
  externalDescription: msg({
    message: 'The industry code used for inflation adjustment.',
    context: 'Inflation adjustment industry code',
  }),
};

const definitions = {
  [GLOBAL_KEY]: {
    displayName: msg({ message: `${GLOBAL_KEY}` }), // this should never be displayed to users itself!
    fields: {
      clean_power_percent: {
        displayName: msg({
          message: 'Clean power percent',
          context: 'The clean power percent of the facility.',
        }),
        externalDescription: msg({
          message: 'The clean power percent of the facility.',
          context: 'Clean power percent',
        }),
      },
      currency_quantity: currencyQuantity,
      distance_quantity: standardDistanceQuantity,
      distance_unit: distanceUnit,
      onsite_clean_power_percent: {
        displayName: msg({
          message: 'Onsite clean power percent',
          context:
            'The percentage of energy generated from renewable sources onsite',
        }),
        externalDescription: msg({
          message:
            'The percentage of energy generated from renewable sources onsite',
          context: 'Onsite clean power percent',
        }),
      },
      passenger_quantity: {
        displayName: msg({
          message: 'Passenger quantity',
          context: 'The number of passengers.',
        }),
        externalDescription: msg({
          message: 'The number of passengers.',
          context: 'Passenger quantity',
        }),
      },
      location_city: locationCity,
      location_country: locationCountry,
      month,
    },
  },

  employees: {
    displayName: msg({
      message: 'Employees',
      context: 'Activity data schema name',
    }),
    fields: {
      employee_quantity: employeeMonthQuantity,
      quantity: employeeMonthQuantity,
      employee_remote_time_percent: {
        displayName: msg({
          message: 'Remote percentage',
          context: 'The percent of time an employee works remotely.',
        }),
        externalDescription: msg({
          message: 'The percent of time an employee works remotely.',
          context: 'Remote percentage',
        }),
      },
      employee_full_time_equivalent: {
        displayName: msg({
          message: 'Full-time equivalent',
          context:
            'The relative hours worked compared to a typical full-time role. An FTE of 1.0 equates to full-time, while 0.5 reflects half the typical hours.',
        }),
        externalDescription: msg({
          message:
            'The relative hours worked compared to a typical full-time role. An FTE of 1.0 equates to full-time, while 0.5 reflects half the typical hours.',
          context: 'Full-time equivalent',
        }),
      },
      commute_transit_percent: {
        displayName: msg({
          message: 'Percent commute by transit',
          context:
            'The percentage (between 0 and 100) of employee-days spent commuting on-site by public transit. (Typically from commute survey data averaged by office location.)',
        }),
        externalDescription: msg({
          message:
            'The percentage (between 0 and 100) of employee-days spent commuting on-site by public transit. (Typically from commute survey data averaged by office location.)',
          context: 'Percent commute by transit',
        }),
      },
      commute_car_percent: {
        displayName: msg({
          message: 'Percent commute by car',
          context:
            'The percentage (between 0 and 100) of employee-days spent commuting on-site by car. (Typically from commute survey data averaged by office location.)',
        }),
        externalDescription: msg({
          message:
            'The percentage (between 0 and 100) of employee-days spent commuting on-site by car. (Typically from commute survey data averaged by office location.)',
          context: 'Percent commute by car',
        }),
      },
      commute_bike_percent: {
        displayName: msg({
          message: 'Percent commute by bike',
          context:
            'The percentage (between 0 and 100) of employee-days spent commuting on-site by bike. (Typically from commute survey data averaged by office location.)',
        }),
        externalDescription: msg({
          message:
            'The percentage (between 0 and 100) of employee-days spent commuting on-site by bike. (Typically from commute survey data averaged by office location.)',
          context: 'Percent commute by bike',
        }),
      },
      commute_walk_percent: {
        displayName: msg({
          message: 'Percent commute by walk',
          context:
            'The percentage (between 0 and 100) of employee-days spent commuting on-site by walk. (Typically from commute survey data averaged by office location.)',
        }),
        externalDescription: msg({
          message:
            'The percentage (between 0 and 100) of employee-days spent commuting on-site by walk. (Typically from commute survey data averaged by office location.)',
          context: 'Percent commute by walk',
        }),
      },
      commute_transit_distance: {
        displayName: msg({
          message: 'Average distance commuted by transit',
          context:
            'The average distance commuted by public transit per employee-day. (Typically from commute survey data averaged by office location.)',
        }),
        externalDescription: msg({
          message:
            'The average distance commuted by public transit per employee-day. (Typically from commute survey data averaged by office location.)',
          context: 'Average distance commuted by transit',
        }),
      },
      commute_car_distance: {
        displayName: msg({
          message: 'Average distance commuted by car',
          context:
            'The average distance commuted by car per employee-day. (Typically from commute survey data averaged by office location.)',
        }),
        externalDescription: msg({
          message:
            'The average distance commuted by car per employee-day. (Typically from commute survey data averaged by office location.)',
          context: 'Average distance commuted by car',
        }),
      },
      commute_bike_distance: {
        displayName: msg({
          message: 'Average distance commuted by bike',
          context:
            'The average distance commuted by bike per employee-day. (Typically from commute survey data averaged by office location.)',
        }),
        externalDescription: msg({
          message:
            'The average distance commuted by bike per employee-day. (Typically from commute survey data averaged by office location.)',
          context: 'Average distance commuted by bike',
        }),
      },
      commute_walk_distance: {
        displayName: msg({
          message: 'Average distance commuted by walk',
          context:
            'The average distance commuted by walk per employee-day. (Typically from commute survey data averaged by office location.)',
        }),
        externalDescription: msg({
          message:
            'The average distance commuted by walk per employee-day. (Typically from commute survey data averaged by office location.)',
          context: 'Average distance commuted by walk',
        }),
      },
      commute_distance_unit: {
        displayName: msg({
          message: 'Commute distance unit',
          context:
            'Label for a group of units of employee commute. Values might be km, miles, etc.',
        }),
        externalDescription: msg({
          message: 'The unit of distance for employee commute info.',
          context: 'Label describing a unit of distance, e.g. km or miles.',
        }),
      },
    },
  },

  building_stationary_combustion: {
    displayName: msg({
      message: 'Building stationary combustion',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Fuel combustion',
          context: "The facility's direct combustion of fuels for heating.",
        }),
        externalDescription: msg({
          message: "The facility's direct combustion of fuels for heating.",
          context: 'Fuel combustion',
        }),
      },
    },
  },

  building_waste: {
    displayName: msg({
      message: 'Building waste',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Waste quantity',
          context: 'The weight of the waste material.',
        }),
        externalDescription: msg({
          message: 'The weight of the waste material.',
          context: 'Waste quantity',
        }),
      },
    },
  },

  cloud_compute_usage: {
    displayName: msg({
      message: 'Cloud compute usage',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Cloud compute usage',
          context: 'The number of instance hours for cloud compute resources.',
        }),
        externalDescription: msg({
          message: 'The number of instance hours for cloud compute resources.',
          context: 'Cloud compute usage',
        }),
      },
      cloud_vm_instance_type: cloudVmInstanceType,
      cloud_region: cloudRegion,
      cloud_service: cloudService,
    },
  },

  cloud_spend: {
    displayName: msg({
      message: 'Cloud spend',
      context: 'Activity data schema name',
    }),
    fields: {
      currency_quantity: cloudSpendCurrencyQuantity,
      quantity: cloudSpendCurrencyQuantity,
      has_matching_cloud_compute_usage: hasMatchingCloudComputeUsage,
    },
  },

  cee_building: {
    displayName: msg({
      message: 'CEE Building',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: ceeBuildingAreaQuantity,
      percent_renewable: ceePercentRenewable,
      building_kind: buildingKind,
      heat_source: heatSource,
    },
  },

  cee_building_scope_1: {
    displayName: msg({
      message: 'CEE Building Scope 1',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: ceeBuildingAreaQuantity,
    },
  },

  cee_building_scope_2: {
    displayName: msg({
      message: 'CEE Building Scope 2',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: ceeBuildingAreaQuantity,
    },
  },

  cee_building_scope_3: {
    displayName: msg({
      message: 'CEE Building Scope 3',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: ceeBuildingAreaQuantity,
    },
  },

  cee_cloud_spend: {
    displayName: msg({
      message: 'CEE Cloud Spend',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: currencyQuantityUsd,
      provider: cloudProvider,
      eeioModelKind,
    },
  },

  cee_commuting: {
    displayName: msg({
      message: 'CEE Commuting',
      context: 'Activity data schema name',
    }),
    fields: {
      employee_remote_time_percent: ceeRemoteTimePercent,
      quantity: ceeEmployeeQuantity,
    },
  },

  cee_eeio_scope_1: {
    displayName: msg({
      message: 'CEE EEIO Scope 1',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: currencyQuantityUsd,
    },
  },

  cee_electricity_usage: {
    displayName: msg({
      message: 'CEE Electricity Usage',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: ceeElectricityUsage,
      percent_renewable: ceePercentRenewable,
      percent_onsite_clean_power: ceePercentOnsiteCleanPower,
    },
  },

  cee_electricity_usage_scope_2: {
    displayName: msg({
      message: 'CEE Electricity Usage Scope 2',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  cee_electricity_usage_scope_3_3_td_loss: {
    displayName: msg({
      message: 'CEE Electricity Usage Scope 3.3 TD Loss',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  cee_electricity_usage_scope_3_3_wtt: {
    displayName: msg({
      message: 'CEE Electricity Usage Scope 3.3 WTT',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  cee_electricity_usage_scope_3_3_wtt_td_loss: {
    displayName: msg({
      message: 'CEE Electricity Usage Scope 3.3 WTT TD Loss',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  cee_flights: {
    displayName: msg({
      message: 'CEE Flights',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: standardDistanceQuantity,
    },
  },

  cee_home_office: {
    displayName: msg({
      message: 'CEE Home Office',
      context: 'Activity data schema name',
    }),
    fields: {
      employee_remote_time_percent: ceeRemoteTimePercent,
      quantity: ceeEmployeeQuantity,
    },
  },

  cee_naics_spend: {
    displayName: msg({
      message: 'CEE NAICS spend',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: currencyQuantityUsd,
      naics_code: naicsCode,
      inflation_adjustment_naics_code: inflationAdjustmentNaicsCode,
    },
  },

  cee_precalculated: {
    displayName: msg({
      message: 'CEE Precalculated',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: ceePrecalculatedEmissions,
    },
  },

  consumer_goods_ecoinvent_material: {
    displayName: msg({
      message: 'Material assessment (Ecoinvent)',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: materialWeight,
    },
  },
  consumer_goods_material_assessment: {
    displayName: msg({
      message: 'Material assessment',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: materialWeight,
    },
  },
  consumer_goods_packaging_material: {
    displayName: msg({
      message: 'Packaging material',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: materialWeight,
    },
  },
  consumer_goods_textile_product_use_phase: {
    displayName: msg({
      message: 'Textile product use phase',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Quantity',
          context: 'The quantity of product orders',
        }),
        externalDescription: msg({
          message: 'The quantity of product orders',
          context: 'Quantity',
        }),
      },
      product_weight: {
        displayName: msg({
          message: 'Product weight',
          context: 'The weight of each order of product',
        }),
        externalDescription: msg({
          message: 'The weight of each order of product',
          context: 'Product weight',
        }),
      },
      product_weight_unit: {
        displayName: msg({
          message: 'Product weight unit',
          context: 'Name of a column in table of activity data',
        }),
        externalDescription: msg({
          message: 'The unit of the weight of each order of product',
          context: 'Description of a column in table of activity data',
        }),
      },
      dry_method: {
        displayName: msg({
          message: 'Dry method',
          context: 'Name of a column in table of activity data',
        }),
        externalDescription: msg({
          message: 'How the product is dried',
          context: 'Description of a column in table of activity data',
        }),
      },
      fabric_category: {
        displayName: msg({
          message: 'Fabric category',
          context: 'Name of a column in table of activity data',
        }),
        externalDescription: msg({
          message: 'The main type of fabric for the product.',
          context: 'Description of a column in table of activity data',
        }),
      },
      product_category: {
        displayName: msg({
          message: 'Product category',
          context: 'Name of a column in table of activity data',
        }),
        externalDescription: msg({
          message: 'The type of product',
          context: 'Description of a column in table of activity data',
        }),
      },
      wash_method: {
        displayName: msg({
          message: 'Wash method',
          context: 'Name of a column in table of activity data',
        }),
        externalDescription: msg({
          message: 'How the product is washed',
          context: 'Description of a column in table of activity data',
        }),
      },
    },
  },
  corporate_315_investments: {
    displayName: msg({
      message: 'Corporate 3.15 investments',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Revenue',
          context: 'The amount invested over the measurement period',
        }),
        externalDescription: msg({
          message: 'The amount invested over the measurement period',
          context: 'Revenue',
        }),
      },
      asset_value: {
        displayName: msg({
          message: 'Asset value',
          context: 'The sum of total company equity and debt',
        }),
        externalDescription: msg({
          message: 'The sum of total company equity and debt',
          context: 'Asset value',
        }),
      },
      outstanding_amount: {
        displayName: msg({
          message: 'Outstanding amount',
          context:
            'The outstanding value of debt owed to the lender, equity held in a private company, or market price times number of shares for listed equity',
        }),
        externalDescription: msg({
          message:
            'The outstanding value of debt owed to the lender, equity held in a private company, or market price times number of shares for listed equity',
          context: 'Outstanding amount',
        }),
      },
    },
  },
  direct_ghg_emissions: {
    displayName: msg({
      message: 'Direct GHG emissions',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Direct greenhouse gas emissions',
          context:
            'The greenhouse gas emissions directly reported from input data.',
        }),
        externalDescription: msg({
          message:
            'The greenhouse gas emissions directly reported from input data.',
          context: 'Direct greenhouse gas emissions',
        }),
      },
      gas_name: gasName,
      ghg_emission_reason: {
        displayName: msg({
          message: 'Emission reason',
          context: 'The reason for the emissions.',
        }),
        externalDescription: msg({
          message: 'The reason for the emissions',
          context: 'Emissions reason',
        }),
      },
    },
  },

  data_transfer: {
    displayName: msg({
      message: 'Data transfer',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Download or streaming time',
          context: 'Data transfer or streaming time by usage hours.',
        }),
        externalDescription: msg({
          message: 'Data transfer or streaming time by usage hours.',
          context: 'Download or streaming time',
        }),
      },
    },
  },

  electricity_consumption: {
    displayName: msg({
      message: 'Building electricity consumption',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Electricity usage',
          context: 'The consumption of electricity.',
        }),
        externalDescription: msg({
          message: 'The consumption of electricity.',
          context: 'Electricity usage',
        }),
      },
    },
  },

  expenditure: {
    displayName: msg({
      message: 'Expenditure without BEA code',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: currencyQuantity,
      currency,
    },
  },

  expenditure_mapped: {
    displayName: msg({
      message: 'Expenditure',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: currencyQuantity,
      currency,
    },
  },
  non_building_electricity_consumption: {
    displayName: msg({
      message: 'Electricity consumption',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Electricity usage',
          context: 'The consumption of electricity.',
        }),
        externalDescription: msg({
          message: 'The consumption of electricity.',
          context: 'Electricity usage',
        }),
      },
      electricity_consumption_reason: {
        displayName: msg({
          message: 'Electricity consumption reason',
          context: 'What the electricity is used for.',
        }),
        externalDescription: msg({
          message: 'What the electricity is used for.',
          context: 'Electricity consumption reason',
        }),
      },
      clean_power_percent: {
        displayName: msg({
          message: 'Clean power percentage',
          context:
            'The percentage of energy attributed to clean power coming from the grid.',
        }),
        externalDescription: msg({
          message:
            'The percentage of energy attributed to clean power coming from the grid.',
          context: 'Clean power percentage',
        }),
      },
    },
  },

  electricity_consumption_datacenter: {
    displayName: msg({
      message: 'Datacenter electricity usage',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Datacenter electricity usage',
          context: 'The consumption of electricity in a datacenter.',
        }),
        externalDescription: msg({
          message: 'The consumption of electricity in a datacenter.',
          context: 'Datacenter electricity usage',
        }),
      },
      pue: {
        displayName: msg({
          message: 'Power usage effectiveness',
          context:
            'The ratio of the total energy used to the amount of energy dedicated to powering datacenter equipment',
        }),
        externalDescription: msg({
          message:
            'The ratio of the total energy used to the amount of energy dedicated to powering datacenter equipment',
          context: 'Power usage effectiveness',
        }),
      },
    },
  },
  food_mass: {
    displayName: msg({
      message: 'Food mass',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Food mass',
          context: 'The mass of the food item.',
        }),
        externalDescription: msg({
          message: 'The mass of the food item.',
          context: 'Food mass',
        }),
      },
      food_processing_sector: {
        displayName: msg({
          message: 'Food processing sector',
          context: 'The processing sector for the purchased food item',
        }),
        externalDescription: msg({
          message: 'The processing sector for the purchased food item',
          context: 'Food processing sector',
        }),
      },
      food_item: {
        displayName: msg({
          message: 'Food item',
          context: 'The purchased food item',
        }),
        externalDescription: msg({
          message: 'The purchased food item',
          context: 'Food item',
        }),
      },
      food_use_case: {
        displayName: msg({
          message: 'Food use case',
          context: 'Specifies if the food is a purchased or a sold product',
        }),
        externalDescription: msg({
          message: 'Specifies if the food is a purchased or a sold product',
        }),
      },
    },
  },
  food_spend: {
    displayName: msg({
      message: 'Food spend',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Food spend',
          context: 'Expenditure on the food item.',
        }),
        externalDescription: msg({
          message: 'Expenditure on the food item.',
          context: 'Food spend',
        }),
      },
    },
  },
  fuel_combustion: {
    displayName: msg({
      message: 'Fuel combustion',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Fuel combustion',
          context:
            'The direct consumption of fuel, both stationary and mobile.',
        }),
        externalDescription: msg({
          message:
            'The direct consumption of fuel, both stationary and mobile.',
          context: 'Fuel combustion',
        }),
      },
      is_third_party: {
        displayName: msg({
          message: 'Is third party',
          context: 'Whether purchased by the company or by a third party.',
        }),
        externalDescription: msg({
          message: 'Whether purchased by the company or by a third party.',
          context: 'Is third party',
        }),
      },
      fuel_combustion_reason: {
        displayName: msg({
          message: 'Fuel combustion reason',
          context: 'The reason for the fuel combustion.',
        }),
        externalDescription: msg({
          message: 'The reason for the fuel combustion.',
          context: 'Fuel combustion reason',
        }),
      },
    },
  },

  refrigerants: {
    displayName: msg({
      message: 'Refrigerants',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Refrigerant leakage',
          context: 'The leakage of refrigerants.',
        }),
        externalDescription: msg({
          message: 'The leakage of refrigerants.',
          context: 'Refrigerant leakage',
        }),
      },
      refrigerant_name: {
        displayName: msg({
          message: 'Refrigerant name',
          context: 'The refrigerant gas name.',
        }),
        externalDescription: msg({
          message: 'The refrigerant gas name.',
          context: 'Refrigerant name',
        }),
      },
      refrigerant_leakage_source: {
        displayName: msg({
          message: 'Refrigerant leakage source',
          context:
            'Whether the refrigerant gas leakage comes from manufacturing or installed equipment.',
        }),
        externalDescription: msg({
          message:
            'Whether the refrigerant gas leakage comes from manufacturing or installed equipment.',
          context: 'Refrigerant leakage source',
        }),
      },
    },
  },

  sold_retail_good: {
    displayName: msg({
      message: 'Sold retail goods',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Product quantity',
          context: 'The quantity of good sold.',
        }),
        externalDescription: msg({
          message: 'The quantity of good sold.',
          context: 'Product quantity',
        }),
      },
      is_energy_star: isEnergyStar,
    },
  },

  building_occupation: {
    displayName: msg({
      message: 'Building occupation',
      context: 'Activity data schema name',
    }),
    fields: {
      building_area_quantity: buildingAreaMonthQuantity,
      quantity: buildingAreaMonthQuantity,
      building_kind: buildingKind,
      building_lease_type: buildingLeaseType,
      is_building_empty: isBuildingEmpty,
      is_building_without_natural_gas: isBuildingWithoutNaturalGas,
      is_building_without_refrigerants: isBuildingWithoutRefrigerants,
    },
  },

  logistics: {
    displayName: msg({
      message: 'Logistics',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: transportedWeightQuantity,
      weight_quantity: transportedWeightQuantity,
      number_of_trips: {
        displayName: msg({
          message: 'Trip type',
          context:
            'Indicator of whether the trip is one-way (1) or round-trip (2)',
        }),
        externalDescription: msg({
          message:
            'Indicator of whether the trip is one-way (1) or round-trip (2)',
          context: 'Trip type',
        }),
      },
      distance_method: distanceMethod,
      exclude_first_mile: excludeFirstMile,
      exclude_last_mile: excludeLastMile,
      transportation_mode: transportationMode,
    },
  },

  lifetime_fuel_and_electricity_use_of_sold_products: {
    displayName: msg({
      message: 'Lifetime fuel and electricity use of sold products',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Number of sold products',
          context: 'The quantity of sold product.',
        }),
        externalDescription: msg({
          message: 'The quantity of sold product.',
          context: 'Number of sold products',
        }),
      },
      lifetime_electricity_consumption: {
        displayName: msg({
          message: 'Lifetime electricity consumption',
          context: 'The electricity usage over the lifetime of a sold product.',
        }),
        externalDescription: msg({
          message: 'The electricity usage over the lifetime of a sold product.',
          context: 'Lifetime electricity consumption',
        }),
      },
      lifetime_fuel_consumption: {
        displayName: msg({
          message: 'Lifetime fuel consumption',
          context: 'The fuel usage over the lifetime of a sold product.',
        }),
        externalDescription: msg({
          message: 'The fuel usage over the lifetime of a sold product.',
          context: 'Lifetime fuel consumption',
        }),
      },
    },
  },

  sold_refrigerant: {
    displayName: msg({
      message: 'Sold refrigerants',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Sold refrigerants',
          context: 'The quantity of refrigerant sold.',
        }),
        externalDescription: msg({
          message: 'The quantity of refrigerant sold.',
          context: 'Sold refrigerants',
        }),
      },
    },
  },

  sold_vehicle: {
    displayName: msg({
      message: 'Sold vehicles',
      context: 'Activity data schema name',
    }),
    fields: {
      vehicle_quantity: vehiclesSold,
      quantity: vehiclesSold, // TODO: Why is this here? It's not on the BAT schema
      fuel_consumed_time_of_sale: {
        displayName: msg({
          message: 'Fuel consumed at time of sale',
          context:
            'Name of a column of data describing an amount of fuel consumption',
        }),
        externalDescription: msg({
          message:
            'For used vehicles, fuel already consumed by the vehicle at time of sale.',
          context:
            'Description of a column of data describing an amount of fuel consumption',
        }),
      },
      fuel_consumption_unit: {
        displayName: msg({
          message: 'Fuel consumption unit',
          context:
            'Unit (e.g. liters) for a column of data describing an amount of fuel consumption',
        }),
        externalDescription: msg({
          message:
            'The unit of lifetime fuel consumption and fuel consumption at time of sale.',
          context:
            'Description for a unit (e.g. liters) of a column of data describing an amount of fuel consumption',
        }),
      },
      lifetime_distance_traveled: {
        displayName: msg({
          message: 'Lifetime distance traveled',
          context:
            'Name of a column of data describing the distance a vehicle traveled',
        }),
        externalDescription: msg({
          message:
            'Distance the vehicle will approximately travel in its entire lifetime.',
          context:
            'Description of a column of data describing the distance a vehicle traveled',
        }),
      },
      lifetime_distance_traveled_unit: {
        displayName: msg({
          message: 'Lifetime distance traveled unit',
          context:
            'Unit (e.g. km, miles) of a column of data describing the distance a vehicle traveled',
        }),
        externalDescription: msg({
          message: 'The unit of lifetime distance traveled',
          context:
            'Description of a unit (e.g. km, miles) for a column of data describing the distance a vehicle traveled',
        }),
      },
      lifetime_electricity_consumption: {
        displayName: msg({
          message: 'Lifetime electricity consumption',
          context:
            'Name of a column of data describing the total electricity consumption of a product in its lifetime',
        }),
        externalDescription: msg({
          message:
            'Electricity the vehicle will approximately consume in its entire lifetime.',
          context: 'Description for a column of data',
        }),
      },
      lifetime_electricity_consumption_unit: {
        displayName: msg({
          message: 'Lifetime electricity consumption unit',
          context:
            'Unit (e.g. kwh) for a column of data describing the total electricity consumption of a product in its lifetime',
        }),
        externalDescription: msg({
          message: 'The unit of lifetime electricity consumption',
          context:
            'Description for a unit (e.g. kwh) of a column of data describing the total electricity consumption of a product in its lifetime',
        }),
      },
      lifetime_fuel_consumption: {
        displayName: msg({
          message: 'Lifetime fuel consumption',
          context:
            'Name of a column of data describing the total fuel consumption of a product in its lifetime',
        }),
        externalDescription: msg({
          message:
            'Fuel the vehicle will approximately consume in its entire lifetime.',
          context: 'Description for a column of data',
        }),
      },
      odometer_reading_time_of_sale: {
        displayName: msg({
          message: 'Odometer reading at time of sale',
          context:
            'Name of a column of data describing vehicles that were sold',
        }),
        externalDescription: msg({
          message: 'Odometer reading of the vehicle at time of sale.',
          context:
            'Description fora column of data describing vehicles that were sold',
        }),
      },
      odometer_reading_time_of_sale_unit: {
        displayName: msg({
          message: 'Odometer reading unit',
          context:
            'Name of a column of data describing vehicles that were sold. Describes the unit of another column, e.g. miles.',
        }),
        externalDescription: msg({
          message: 'The unit of odometer reading at time of sale.',
          context:
            'Description for a column of data describing vehicles that were sold. Describes the unit of another column, e.g. miles.',
        }),
      },
    },
  },

  lodging: {
    displayName: msg({
      message: 'Lodging',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Nights',
          context: 'The number of nights stayed for a single lodging unit.',
        }),
        externalDescription: msg({
          message: 'The number of nights stayed per room.',
          context: 'Nights',
        }),
      },
      lodging_unit_quantity: {
        displayName: msg({
          message: 'Room count',
          context: 'The number of lodging units rented for the stay.',
        }),
        externalDescription: msg({
          message: 'The number of lodging units rented for the stay.',
          context: 'Room count',
        }),
      },
    },
  },

  waste: {
    displayName: msg({
      message: 'Waste',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Waste quantity',
          context: 'The weight of the waste material.',
        }),
        externalDescription: msg({
          message: 'The weight of the waste material.',
          context: 'Waste quantity',
        }),
      },
    },
  },

  waste_products: {
    displayName: msg({
      message: 'Waste products',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Waste quantity',
          context: 'The weight of the waste material.',
        }),
        externalDescription: msg({
          message: 'The weight of the waste material.',
          context: 'Waste quantity',
        }),
      },
      product_weight_quantity: {
        displayName: msg({
          message: 'Product weight quantity',
          context: 'Weight of an individual unit of product.',
        }),
        externalDescription: msg({
          message: 'Weight of an individual unit of product.',
          context: 'Product weight quantity',
        }),
      },
      products_sold_quantity: {
        displayName: msg({
          message: 'Products sold: end of life',
          context: 'Total number of products sold',
        }),
        externalDescription: msg({
          message: 'Total number of products sold',
          context: 'Products sold',
        }),
      },
    },
  },

  water_usage: {
    displayName: msg({
      message: 'Water withdrawal',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: waterUsageQuantity,
    },
  },

  carbon_removal: {
    displayName: msg({
      message: 'Carbon removal',
      context: 'Activity data schema name',
    }),
    fields: {
      kgco2e_removed: kgco2eRemoved,
      quantity: kgco2eRemoved,
    },
  },

  carbon_credit_retirements: {
    displayName: msg({
      message: 'Carbon credit retirements',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity_retired_kgco2e: {
        displayName: msg({
          message: 'Quantity retired kgCO2e',
          context: 'Quantity retired within the start/end date range.',
        }),
        externalDescription: msg({
          message: 'Quantity retired within the start/end date range.',
          context: 'Quantity retired kgCO2e',
        }),
      },
      quantity: {
        displayName: msg({
          message: 'Quantity retired kgCO2e',
          context: 'Quantity retired within the start/end date range.',
        }),
        externalDescription: msg({
          message: 'Quantity retired within the start/end date range.',
          context: 'Quantity retired kgCO2e',
        }),
      },
      serial_number: {
        displayName: msg({
          message: 'Credit serial number',
          context: 'Unique serial number of the credit being retired.',
        }),
        externalDescription: msg({
          message: 'Unique serial number of the credit being retired.',
          context: 'Credit serial number',
        }),
      },
      contract_id: {
        displayName: msg({
          message: 'Contract ID',
          context:
            'Unique identifier for the contract. Can be the internal or Salesforce ID or the name of the contract.',
        }),
        externalDescription: msg({
          message:
            'Unique identifier for the contract. Can be the internal or Salesforce ID or the name of the contract.',
          context: 'Contract ID',
        }),
      },
      contract_name: {
        displayName: msg({
          message: 'Contract name',
          context: 'Human-readable name for the contract.',
        }),
        externalDescription: msg({
          message: 'Human-readable name for the contract.',
          context: 'Contract name',
        }),
      },
      project_name: {
        displayName: msg({
          message: 'Project name',
          context:
            'Human-readable name for the carbon removal project (e.g. "Project Nexus").',
        }),
        externalDescription: msg({
          message:
            'Human-readable name for the carbon removal project (e.g. "Project Nexus").',
          context: 'Project name',
        }),
      },
      project_id: {
        displayName: msg({
          message: 'Project ID',
          context:
            'Unique identifier for the carbon removal project, usually referenced from the credit creator.',
        }),
        externalDescription: msg({
          message:
            'Unique identifier for the carbon removal project, usually referenced from the credit creator.',
          context: 'Project ID',
        }),
      },
      contract_execution_date: {
        displayName: msg({
          message: 'Contract execution date',
          context:
            'Effective date of contract. Should be the same for all rows sharing a contract unique ID.',
        }),
        externalDescription: msg({
          message:
            'Effective date of contract. Should be the same for all rows sharing a contract unique ID.',
          context: 'Contract execution date',
        }),
      },
      quantity_contracted_kgco2e: {
        displayName: msg({
          message: 'Quantity contracted kgCO2e',
          context:
            'Total total removal (in kgCO2e) that this contract will deliver over its lifetime.',
        }),
        externalDescription: msg({
          message:
            'Total total removal (in kgCO2e) that this contract will deliver over its lifetime.',
          context: 'Quantity contracted kgCO2e',
        }),
      },
      retirement_date: {
        displayName: msg({
          message: 'Retirement date',
          context:
            'Date of retirement for credits in contract. If start and end date are not set, we use retirement_data and retirement_date + 1 day.',
        }),
        externalDescription: msg({
          message:
            'Date of retirement for credits in contract. If start and end date are not set, we use retirement_data and retirement_date + 1 day.',
          context: 'Retirement date',
        }),
      },
      carbon_removal_type: {
        displayName: msg({
          message: 'Carbon removal type',
          context: 'Category of carbon removal activity.',
        }),
        externalDescription: msg({
          message: 'Category of carbon removal activity.',
          context: 'Carbon removal type',
        }),
      },
      registry: {
        displayName: msg({
          message: 'Registry',
          context: 'Registry on which the removals are listed.',
        }),
        externalDescription: msg({
          message: 'Registry on which the removals are listed.',
          context: 'Registry',
        }),
      },
      protocol: {
        displayName: msg({
          message: 'Protocol',
          context: 'Instruction set for which the project is answered.',
        }),
        externalDescription: msg({
          message: 'Instruction set for which the project is answered.',
          context: 'Protocol',
        }),
      },
      is_3rd_party_verified: {
        displayName: msg({
          message: 'Is 3rd-party verified',
          context: 'Whether the removals are verified by a 3rd party.',
        }),
        externalDescription: msg({
          message: 'Whether the removals are verified by a 3rd party.',
          context: 'Is 3rd-party verified',
        }),
      },
      quality_standard: {
        displayName: msg({
          message: 'Quality standard',
          context: 'Standard used to verify the removals.',
        }),
        externalDescription: msg({
          message: 'Standard used to verify the removals.',
          context: 'Quality standard',
        }),
      },
      qualifies_as_corresponding_adjustment: {
        displayName: msg({
          message: 'Qualifies as corresponding adjustment',
          context:
            'Whether the removal qualifies as a corresponding adjustment.',
        }),
        externalDescription: msg({
          message:
            'Whether the removal qualifies as a corresponding adjustment.',
          context: 'Qualifies as corresponding adjustment',
        }),
      },
      cost_per_kgco2e: {
        displayName: msg({
          message: 'Cost per kgCO2e',
          context: 'Cost per kgco2e of removal in the contract.',
        }),
        externalDescription: msg({
          message: 'Cost per kgco2e of removal in the contract.',
          context: 'Cost per kgCO2e',
        }),
      },
      cost_per_kgco2e_currency: {
        displayName: msg({
          message: 'Cost per kgCO2e currency',
          context: 'Currency used in "Cost per kgCO2e".',
        }),
        externalDescription: msg({
          message: 'Currency used in "Cost per kgCO2e".',
          context: 'Cost per kgCO2e currency',
        }),
      },
    },
  },

  vehicles: {
    displayName: msg({
      message: 'Vehicles',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: standardDistanceQuantity,
    },
  },
  tier_1_factories_activity_data: {
    displayName: msg({
      message: 'Tier 1 factories activity data',
      context: 'Activity data schema name',
    }),
    fields: {
      biomass_non_wood_mj: {
        displayName: msg({
          message: 'Biomass (non-wood) quantity',
          context: 'Energy from biomass (non-wood).',
        }),
        externalDescription: msg({
          message: 'Energy from biomass (non-wood).',
          context: 'Biomass (non-wood) quantity',
        }),
      },
      biomass_wood_mj: {
        displayName: msg({
          message: 'Biomass (wood) quantity',
          context: 'Energy from biomass (wood).',
        }),
        externalDescription: msg({
          message: 'Energy from biomass (wood).',
          context: 'Biomass (wood) quantity',
        }),
      },
      biodiesel_fuel_mj: {
        displayName: msg({
          message: 'Biodiesel fuel',
          context: 'Energy from biodiesel.',
        }),
        externalDescription: msg({
          message: 'Energy from biodiesel.',
          context: 'Biodiesel fuel',
        }),
      },
      coal_mj: {
        displayName: msg({
          message: 'Coal quantity',
          context: 'Energy from coal.',
        }),
        externalDescription: msg({
          message: 'Energy from coal.',
          context: 'Coal quantity',
        }),
      },
      diesel_fuel_mj: {
        displayName: msg({
          message: 'Diesel fuel quantity',
          context: 'Energy from diesel fuel.',
        }),
        externalDescription: msg({
          message: 'Energy from diesel fuel.',
          context: 'Diesel fuel quantity',
        }),
      },
      electricity_mj: {
        displayName: msg({
          message: 'Electricity quantity',
          context: 'Energy from electricity.',
        }),
        externalDescription: msg({
          message: 'Energy from electricity.',
          context: 'Electricity quantity',
        }),
      },
      fuel_oil_mj: {
        displayName: msg({
          message: 'Fuel oil quantity',
          context: 'Energy from fuel oil.',
        }),
        externalDescription: msg({
          message: 'Energy from fuel oil.',
          context: 'Fuel oil quantity',
        }),
      },
      gasoline_mj: {
        displayName: msg({
          message: 'Gasoline quantity',
          context: 'Energy from gasoline.',
        }),
        externalDescription: msg({
          message: 'Energy from gasoline.',
          context: 'Gasoline quantity',
        }),
      },
      liquified_natural_gas_mj: {
        displayName: msg({
          message: 'LNG quantity',
          context: 'Energy from liquified natural gas.',
        }),
        externalDescription: msg({
          message: 'Energy from liquified natural gas.',
          context: 'LNG quantity',
        }),
      },
      liquified_petroleum_gas_mj: {
        displayName: msg({
          message: 'LNG quantity',
          context: 'Energy from liquified petroleum gas.',
        }),
        externalDescription: msg({
          message: 'Energy from liquified petroleum gas.',
          context: 'LNG quantity',
        }),
      },
      natural_gas_mj: {
        displayName: msg({
          message: 'Natural gas quantity',
          context: 'Energy from natural gas.',
        }),
        externalDescription: msg({
          message: 'Energy from natural gas.',
          context: 'Natural gas quantity',
        }),
      },
      propane_mj: {
        displayName: msg({
          message: 'Propane quantity',
          context: 'Energy from propane.',
        }),
        externalDescription: msg({
          message: 'Energy from propane.',
          context: 'Propane quantity',
        }),
      },
      steam_mj: {
        displayName: msg({
          message: 'Steam quantity',
          context: 'Energy from steam.',
        }),
        externalDescription: msg({
          message: 'Energy from steam.',
          context: 'Steam quantity',
        }),
      },
      quantity: {
        displayName: msg({
          message: 'Factory count',
          context: 'Tier 1 factory for the reporting company',
        }),
        externalDescription: msg({
          message: 'Tier 1 factory for the reporting company',
          context: 'Factory count',
        }),
      },
      waste_kg: {
        displayName: msg({
          message: 'Waste quantity',
          context: 'Mass of waste.',
        }),
        externalDescription: msg({
          message: 'Mass of waste.',
          context: 'Waste quantity',
        }),
      },
      water_liters: {
        displayName: msg({
          message: 'Water quantity',
          context: 'Volume of water.',
        }),
        externalDescription: msg({
          message: 'Volume of water.',
          context: 'Water quantity',
        }),
      },
    },
  },
  tier_1_factories_spend: {
    displayName: msg({
      message: 'Tier 1 factories spend',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: currencyQuantity,
    },
  },

  travel_air: {
    displayName: msg({
      message: 'Air travel',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Flights',
          context: 'The number of flights.',
        }),
        externalDescription: msg({
          message: 'The number of flights.',
          context: 'Flights',
        }),
      },
      flight_cabin_class: {
        displayName: msg({
          message: 'Flight cabin class',
          context: 'A list of cabin classes.',
        }),
        externalDescription: msg({
          message: 'A list of cabin classes.',
          context: 'Flight cabin class',
        }),
      },
      flight_route: {
        displayName: msg({
          message: 'Flight route',
          context: 'A list of IATA airport codes.',
        }),
        externalDescription: msg({
          message: 'A list of IATA airport codes.',
          context: 'Flight route',
        }),
      },
      exclude_radiative_forcing: {
        displayName: msg({
          message: 'Exclude radiative forcing',
          context:
            'Whether to exclude radiative forcing from carbon calculation.',
        }),
        externalDescription: msg({
          message:
            'Whether to exclude radiative forcing from carbon calculation.',
          context: 'Exclude radiative forcing',
        }),
      },
    },
  },

  travel_private_jets: {
    displayName: msg({
      message: 'Private jet travel',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: standardDistanceQuantity,
      private_plane_model: privatePlaneModel,
    },
  },

  travel_rail: {
    displayName: msg({
      message: 'Rail travel',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: standardDistanceQuantity,
      transportation_mode: transportationMode,
    },
  },

  historical_emissions: {
    displayName: msg({
      message: 'Historical emissions',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  historical_emissions_pv2: {
    displayName: msg({
      message: 'Historical emissions from pipeline v2',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  water_consumption: {
    displayName: msg({
      message: 'Water consumption',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: waterConsumptionQuantity,
    },
  },

  water_recycling: {
    displayName: msg({
      message: 'Water recycling',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: waterRecyclingQuantity,
    },
  },

  water_storage: {
    displayName: msg({
      message: 'Water storage',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: waterStorageQuantity,
    },
  },

  water_discharge: {
    displayName: msg({
      message: 'Water discharge',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: waterDischargeQuantity,
    },
  },

  expenditure_unmapped: {
    displayName: msg({
      message: 'Expenditure without BEA code',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  physical_goods_purchase: {
    displayName: msg({
      message: 'Physical goods purchase',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  crypto_holdings: {
    displayName: msg({
      message: 'Crypto holdings',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  crypto_transactions: {
    displayName: msg({
      message: 'Crypto transactions',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  consumer_goods_product_assessment: {
    displayName: msg({
      message: 'Product assessment',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  consumer_goods_textile_product_manufacturing: {
    displayName: msg({
      message: 'Textile product manufacturing',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  corporate_asset_holding: {
    displayName: msg({
      message: 'Corporate asset holding',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  real_estate_asset_holding: {
    displayName: msg({
      message: 'Real Estate asset holding',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  substances: {
    displayName: msg({
      message: 'Substances',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  substance_outflow: {
    displayName: msg({
      message: 'Substances outflow',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Substance quantity',
          context: 'The quantity of the substance released.',
        }),
        externalDescription: msg({
          message: 'The quantity of the substance released.',
          context: 'Substance quantity',
        }),
      },
      unit: {
        displayName: msg({
          message: 'Substance unit',
          context: 'The unit of the substance released.',
        }),
        externalDescription: msg({
          message: 'The unit of the substance released.',
          context: 'Substance unit',
        }),
      },
      cas_number: {
        displayName: msg({
          message: 'CAS number',
          context: 'Chemical Abstracts Service registry number.',
        }),
        externalDescription: msg({
          message: 'Chemical Abstracts Service registry number.',
        }),
      },
      eu_prtr_group: {
        displayName: msg({
          message: 'E-PRTR group',
          context:
            'European Pollutant Release and Transfer Register group of the substance.',
        }),
        externalDescription: msg({
          message:
            'European Pollutant Release and Transfer Register group of the substance.',
        }),
      },
      substance_outflow_medium: {
        displayName: msg({
          message: 'Substance outflow medium',
          context: 'The medium through which the substance is released.',
        }),
        externalDescription: msg({
          message: 'The medium through which the substance is released.',
          context: 'Substance outflow medium',
        }),
      },
    },
  },

  microplastics_outflow: {
    displayName: msg({
      message: 'Microplastics outflow',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Microplastics quantity',
          context: 'The quantity of microplastics released.',
        }),
        externalDescription: msg({
          message: 'The quantity of microplastics released.',
          context: 'Microplastics quantity',
        }),
      },
      microplastics_outflow_medium: {
        displayName: msg({
          message: 'Microplastics outflow medium',
          context: 'The medium through which the microplastics are released.',
        }),
        externalDescription: msg({
          message: 'The medium through which the microplastics are released.',
          context: 'Microplastics outflow medium',
        }),
      },
    },
  },

  site_land_use: {
    displayName: msg({
      message: 'Site land use',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: siteLandUseQuantity,
      site_identifier: {
        displayName: msg({
          message: 'Site identifier',
          context: 'Unique identifier for the site.',
        }),
        externalDescription: msg({
          message: 'Unique identifier for the site.',
          context: 'Site identifier',
        }),
      },
    },
  },

  intensity_denominator_monetary: {
    displayName: msg({
      message: 'Monetary intensity denominator',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  intensity_denominator_custom: {
    displayName: msg({
      message: 'Custom intensity denominator',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  custom: {
    displayName: msg({
      message: 'Custom',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  legacy_clean_power: {
    displayName: msg({
      message: 'Clean power',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  cadt: {
    displayName: msg({
      message: 'Clean activity data table',
      context: 'Activity data schema name',
    }),
    fields: {},
  },

  em_development_bat: {
    displayName: msg({
      message: 'EM development',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Quantity',
          context: 'The quantity of the emissive activity.',
        }),
        externalDescription: msg({
          message: 'The quantity of the emissive activity.',
          context: 'Quantity',
        }),
      },
    },
  },

  activity_with_custom_ef: {
    displayName: msg({
      message: 'Activity with custom EF',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: {
        displayName: msg({
          message: 'Quantity',
          context: 'The quantity of the emissive activity.',
        }),
        externalDescription: msg({
          message: 'The quantity of the emissive activity.',
          context: 'Quantity',
        }),
      },
    },
  },

  sold_product_circularity: {
    displayName: msg({
      message: 'Sold product circularity',
      context: 'Activity data schema name',
    }),
    fields: {
      product_id: {
        displayName: msg({
          message: 'Product ID',
          context:
            'Unique way to identify the product (e.g. name, SKU, product key).',
        }),
        externalDescription: msg({
          message:
            'Unique way to identify the product (e.g. name, SKU, product key).',
          context: 'Product ID',
        }),
      },
    },
  },

  sold_product_recyclability: {
    displayName: msg({
      message: 'Sold product recyclability',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: productWeightQuantity,
      product_id: {
        displayName: msg({
          message: 'Product ID',
          context:
            'Unique way to identify the product (e.g. name, SKU, product key).',
        }),
        externalDescription: msg({
          message:
            'Unique way to identify the product (e.g. name, SKU, product key).',
          context: 'Product ID',
        }),
      },
    },
  },

  workplace_reports: {
    displayName: msg({
      message: 'Workplace incidents',
      context: 'Activity data schema name',
    }),
    fields: {
      incident_id: {
        displayName: msg({
          message: 'Worklace incident ID',
          context: 'Unique way to identify the incident.',
        }),
        externalDescription: msg({
          message: 'Unique way to identify the incident.',
          context: 'Worklace incident ID',
        }),
      },
      currency,
      monetary_remediation_quantity: currencyQuantity,
    },
  },

  health_and_safety_incidents: {
    displayName: msg({
      message: 'Health and safety incidents',
      context: 'Activity data schema name',
    }),
    fields: {
      incident_id: {
        displayName: msg({
          message: 'Health and safety incident ID',
          context: 'Unique way to identify the incident.',
        }),
        externalDescription: msg({
          message: 'Unique way to identify the incident.',
          context: 'Health and safety incident ID',
        }),
      },
    },
  },

  severe_human_rights_incidents: {
    displayName: msg({
      message: 'Severe human rights incidents',
      context: 'Activity data schema name',
    }),
    fields: {
      incident_id: {
        displayName: msg({
          message: 'Severe human rights incident ID',
          context: 'Unique way to identify the incident.',
        }),
        externalDescription: msg({
          message: 'Unique way to identify the incident.',
          context: 'Severe human rights incident ID',
        }),
      },
    },
  },

  anti_bribery_and_corruption_incidents: {
    displayName: msg({
      message: 'Anti-bribery and corruption incidents',
      context: 'Activity data schema name',
    }),
    fields: {
      incident_id: {
        displayName: msg({
          message: 'Anti-bribery and corruption incident ID',
          context: 'Unique way to identify the incident.',
        }),
        externalDescription: msg({
          message: 'Unique way to identify the incident.',
          context: 'Anti-bribery and corruption incident ID',
        }),
      },
    },
  },

  payment_orders: {
    displayName: msg({
      message: 'Payment orders',
      context: 'Activity data schema name',
    }),
    fields: {
      purchase_order_id: {
        displayName: msg({
          message: 'Payment order ID',
          context: 'Unique way to identify the payment order.',
        }),
        externalDescription: msg({
          message: 'Unique way to identify the payment order.',
          context: 'Payment order ID',
        }),
      },
    },
  },

  lobbying: {
    displayName: msg({
      message: 'Lobbying activity',
      context: 'Activity data schema name',
    }),
    fields: {
      quantity: currencyQuantity,
      currency,
    },
  },

  workers_social: {
    displayName: msg({
      message: 'Social data about the workforce',
      context: 'Activity data schema name',
    }),
    fields: {
      num_paid_below_adequate_wage: {
        displayName: msg({
          message: 'Number paid below adequate wage',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message: 'Number of workers paid below the adequate wage benchmark.',
          context: 'description of data field',
        }),
      },
      num_covered_under_collective_bargaining: {
        displayName: msg({
          message: 'Workers covered under collective bargaining count',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            'Number of workers covered by collective bargaining agreements.',
          context: 'description of data field',
        }),
      },
      num_covered_by_workers_representation: {
        displayName: msg({
          message: 'Workers covered by workers representation count',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message: 'Number of workers covered by workers representation.',
          context: 'description of data field',
        }),
      },
      num_covered_by_health_and_safety_management_system: {
        displayName: msg({
          message:
            'Workers covered by health and safety management system count',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            'Number of workers covered by the health and safety management systems based on legal requirements and/or recognised standards or guidelines, as applicable to your company. NOTE: this is a snapshot of the workforce at the time of data upload, and can be thought of as a gauge, not a counter of activity.',
          context: 'description of data field',
        }),
      },
      num_participated_in_reviews: {
        displayName: msg({
          message: 'Workers participated in performance reviews count',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            'Number of workers that participated in regular performance and career development reviews. NOTE: this is a snapshot of the workforce at the time of data upload, and can be thought of as a gauge, not a counter of activity.',
          context: 'description of data field',
        }),
      },
      average_number_training_hours: {
        displayName: msg({
          message: 'Average number of training hours',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            'Average number of training hours the workers received in the period.',
          context: 'description of data field',
        }),
      },
      num_workers: {
        displayName: msg({
          message: 'Workers count',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message: 'The quantity of workers.',
          context: 'description of data field',
        }),
      },
      quantity_hours_worked: {
        displayName: msg({
          message: 'Quantity of hours worked',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            'The quantity of hours worked by the employees of the operation during the period.',
          context: 'description of data field',
        }),
      },
      is_top_level_management: {
        displayName: msg({
          message: 'Is top-level management',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message: 'If the employee is top-level management or not.',
          context: 'description of data field',
        }),
      },
      age: {
        displayName: msg({
          message: 'Age group',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message: 'The age of employee group.',
          context: 'description of data field',
        }),
      },
      num_with_disabilities: {
        displayName: msg({
          message: 'Workers with disabilities count',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            'The number of employees with disabilities. NOTE: this is a snapshot of the workforce at the time of data upload, and can be thought of as a gauge, not a counter of activity.',
          context: 'description of data field',
        }),
      },
      num_entitled_to_take_family_related_leave: {
        displayName: msg({
          message: 'Workers entitled to take family related leave count',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            "Number of employees entitled to take family-related leave, which may include maternity leave, paternity leave, parental leave, carers' leave, etc, as applicable in your company or region of operation. NOTE: this is a snapshot of the workforce at the time of data upload, and can be thought of as a gauge, not a counter of activity.",
          context: 'description of data field',
        }),
      },
      num_taken_family_related_leave: {
        displayName: msg({
          message: 'Workers who took family related leave quantity',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            "Number of employees that took family-related leave, which may include maternity leave, paternity leave, parental leave, carers' leave, etc, as applicable in your company or region of operation, during the period.",
          context: 'description of data field',
        }),
      },
      quantity_terminated_contract: {
        displayName: msg({
          message: 'Workers with terminated contract quantity',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message:
            'The number of employees which terminated the contract during the period.',
          context: 'description of data field',
        }),
      },
      employee_category: {
        displayName: msg({
          message: 'Employee category',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message: 'The category of employee.',
          context: 'description of data field',
        }),
      },
      non_employee_type: {
        displayName: msg({
          message: 'Non-employee type',
          context: 'Activity data schema name',
        }),
        externalDescription: msg({
          message: 'The type of non-employee.',
          context: 'description of data field',
        }),
      },
    },
  },
} as const satisfies Record<
  string,
  {
    fields: Record<string, FieldMetadataMessageDescriptorDef>;
    displayName: MessageDescriptor;
  }
>;

// -----> Below is the interface implementation. We should not need to change
// this just because we're updating definition.

type FieldMetadataDef = {
  displayName: string;
  externalDescription: string;
};

type FieldMetadataMessageDescriptorDef = {
  displayName: MessageDescriptor;
  externalDescription: MessageDescriptor;
};

type BatMetadataDef = {
  /**
   * All fields, keyed by field name.
   */
  fields: Map<string, FieldMetadataMessageDescriptorDef>;

  /**
   * A human-readable display name for this BAT.
   */
  displayName: MessageDescriptor;
};

export type MetadataDefinedPrimaryTypes = Exclude<
  keyof typeof definitions,
  typeof GLOBAL_KEY
>;
// Assert that all BATs in the current schema are covered by the metadata
// We don't assert that they are identical, since we want metadata coverage
// to live on even after a BAT is removed from †he current version.
type UncoveredCurrentBATs = Exclude<
  CurrentPrimaryTypeNames,
  MetadataDefinedPrimaryTypes
>;
assertNoTypeDifferences<UncoveredCurrentBATs, never>(noTypeDifferences);

// Keyed by BAT name.
const batMetadataLookup = new Map<string, BatMetadataDef>(
  Object.entries(definitions).map(([batName, batDef]) => {
    return [
      batName,
      {
        fields: new Map(Object.entries(batDef.fields)),
        displayName: batDef.displayName,
      },
    ];
  })
);

type BatMetadataSchemas = keyof typeof definitions;

/**
 * BatMetadata exposes functions for accessing BAT metadata definitions.
 */
export class BatMetadata {
  static getFieldMetadataMessageDescriptors(
    batName: string,
    fieldName: string
  ): FieldMetadataMessageDescriptorDef | undefined {
    const batSchemaMetadata = definitions[batName as BatMetadataSchemas];
    return batSchemaMetadata?.fields[
      fieldName as keyof (typeof definitions)[BatMetadataSchemas]['fields']
    ];
  }

  /**
   * Returns the field metadata for a particular BAT field, or undefined if
   * there is no metadata defined for that field.
   */
  static getFieldMetadata(
    batName: string,
    fieldName: string,
    scopedI18n: I18n = i18n
  ): FieldMetadataDef | undefined {
    const fieldMetadata = BatMetadata.getFieldMetadataMessageDescriptors(
      batName,
      fieldName
    );
    if (!fieldMetadata) {
      return;
    }
    return {
      displayName: scopedI18n._(fieldMetadata.displayName),
      externalDescription: i18n._(fieldMetadata.externalDescription),
    };
  }

  /**
   * Our BAT names are slugs like `building_stationary_combustion`. This
   * returns a human-readable display name, like "Building stationary combustion".
   */
  static getDisplayName(batName: string, scopedI18n: I18n = i18n): string {
    BadDataError.invariant(
      batName !== GLOBAL_KEY,
      'Cannot get display name for global BAT name key'
    );
    const mappedName = batMetadataLookup.get(batName)?.displayName;
    if (!mappedName) {
      captureException(
        new Error(
          `[soft invariant] Could not fetch display name for BAT ${batName}. ` +
            'Please confirm the bat name is covered in BatMetadata.ts. Note that every BAT should ' +
            'remain covered in the metadata even if the BAT is removed from the current version.'
        ),
        { level: 'warning' }
      );
    }
    return mappedName ? scopedI18n._(mappedName) : batName;
  }
}
