import { SUPPORTED_LOCALES, SupportedLocale } from '@watershed/intl/constants';
import { useUserContext } from './UserContext';

const LAUNCHED_LOCALES: Array<SupportedLocale> = [
  'de-DE',
  'en-US',
  'fr-FR',
  'ja-JP',
];

export function useAvailableLocales(): Array<SupportedLocale> {
  const userContext = useUserContext();
  return userContext.userIsWatershedEmployee
    ? SUPPORTED_LOCALES
    : LAUNCHED_LOCALES;
}
